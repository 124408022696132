import * as actions from '../actions/actionTypes'

const initialState = {
    loading: false,
    campaignData :null,  
    clicked : null,
    campaignProcessData:null,
    availableType:null,
    availableCampaign:null,
    campaignFeatures : null,
    campaignFlow:null

}

const reducer  = (state = initialState, action) => {
    switch (action.type){
        case actions.GET_CAMPAIGN_REF:
            return {
                ...state,
                loading: false,
                campaignData : action.data
            }
        case actions.GET_APPLY_CLICKED:  
            return {
                ...state,
                loading :false,
                clicked : action.data
            }
        case actions.GET_CAMPAIGN_PROCESS:  
            return {
                ...state,
                loading :false,
                campaignProcessData : action.data
            }
        case actions.SUCCESS_AVAILABLE_CAMPAIGN_TYPE:  
            return {
                ...state,
                loading :false,
                availableType : action.data
            }
        case actions.GET_SUCCESS_AVAILABLE_CAMPAIGN:  
            return {
                ...state,
                loading :false,
                availableCampaign : action.data
            }
        case actions.GET_SUCCESS_CAMPAIGN_FEATURES:
            return{
                ...state,
                loading : false,
                campaignFeatures : action.data
            }
            case actions.SET_CAMPAIGN_FEATURES_SUCCESS:
                return{
                    ...state,
                    loading : false,
                    campaignFlow : action.data
                }       
        default:
            return state
    }
}
export default reducer