import React from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./ProfileWrapper.module.scss";
import * as actionsCreator from "../../../store/actions/index";
import Profile from "../../../components/CandidateDashboard/Profile/Profile";
import ProfileContent from "./ProfileContent";
import Animate from "react-smooth";
import moment from "moment";
import { Modal } from "semantic-ui-react";
import CVPanel from "../../../components/OnBoard/CvUpload";

class ProfileWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: localStorage.getItem("chat-token"),
    };
  }
  componentDidMount = () => {
    this.getCandidateData();
  };
  getCandidateData = () => {
    var currentTime = moment.utc().toISOString();
    console.log(currentTime, "Current time iso");
    this.props.onGetCandidateData(this.state.token, currentTime);
  };
  profileTab = () => {
    this.props.changeTabProfile();
  };
  render() {
    const modal = (
      <Modal
        open={this.props.dashboardCV}
        size="tiny"
        className="noBootstrapModal"
      >
        <CVPanel
          flag={this.props.dashboardCV}
          changeTabCareer={this.props.changeTabCareer}
          // changeTabCareer={() => console.log("gyg")}
        />
      </Modal>
    );
    let profile = (
      <Profile
        profileTabChange={this.profileTab}
        data={this.props.candidateDate}
      />
    );
    return (
      <Animate to="1" from="0" attributeName="opacity">
        {modal}
        <div className={classes.profileWrapper}>
          <div className={classes.profileContent}>
            <div className={`${classes.miniProfileWrapper} above768block`}>
              {profile}
            </div>
            <div className={classes.rightComponent}>
              <ProfileContent
                candidateDate={this.props.candidateDate.userData.profile[0]}
              />
            </div>
          </div>
        </div>
      </Animate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    candidateDate: state.CandidateData.userData,
    dashboardCV: state.profile.cvDashboard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCandidateData: (data, time) =>
      dispatch(actionsCreator.getCandidateData(data, time)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileWrapper)
);
