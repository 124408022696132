import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./FeedbackCarousel.scss";
import EachFeedback from "../EachFeedback/EachFeedback";

export default class DemoCarousel extends Component {
  state = {
    feedback: [
      {
        title: "NTPC Career fair",
        body:
          "The candidate seems to have a sound experience in business management and SWOT",
        interviewerName: "Eric Logan",
        interviewerImage: "https://picsum.photos/30",
        dateStamp: "12 days ago",
      },
      {
        title: "Mora spirit Career fair 2020 with University of Ruhuna",
        body:
          "The candidate seems to have a sound experience in business management and SWOT. He's technically strong as well.",
        interviewerName: "Regina Phalenge",
        interviewerImage: "https://picsum.photos/30",
        dateStamp: "12 days ago",
      },
      {
        title: "FSSAT Recruitment",
        body:
          "The candidate seems to have a sound experience in business management and SWOT",
        interviewerName: "Christoper Cobert",
        interviewerImage: "https://picsum.photos/30",
        dateStamp: "12 days ago",
      },
    ],
  };
  render() {
    var feedback =[]
    try{
     feedback =this.props.feedback.Data
    }catch{
     feedback =[]
    }
    return(       
    <div>
     {feedback.length !=0 ?
      <Carousel
        autoPlay
        showThumbs={false}
        showIndicators={false}
        infiniteLoop
        vertical
        >
        {feedback.map((res, index) => {
          return <EachFeedback data={res} key={index} />;
        })}
        </Carousel>
      :null
     }
    </div>
    );
  }
}
