import React, { Component } from "react";
import classes from "./LoadingChatComponent.module.scss";
import IconSia from "../../../../assets/image/sia.png";

export class LoadingChatComponent extends Component {
  render() {
    return (
      <div className={classes.ChatComponent}>
        {/* <div className={classes.ProfileData}>
          <span style={{
            backgroundImage: `url('${IconSia}')`
          }}></span>
          <strong className={classes.Name}>Sia Bot</strong>
          <small>Just now</small>
        </div> */}
        <div className={classes.Message}>
          <div className={classes.spinner}>
            <div className={classes.bounce1}></div>
            <div className={classes.bounce2}></div>
            <div className={classes.bounce3}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingChatComponent;
