import React, { Component } from "react";
import { connect } from "react-redux";
import VideoConference from '../../../../containers/Interview/VideoConference/VideoConference'
import Spinner from '../../../../components/UI/Spinner/Spinner'

class InterviewInvite extends Component{
    constructor(props){
        super(props)
    }
    render(){
        console.log("Interview invite")
        var temp = <Spinner/>
        console.log(this.props.intData, "Interview invite ref")
        if (this.props.intData !== null){
            console.log("loading to video conference")
            sessionStorage.setItem('ref', this.props.intData)
            temp = <VideoConference/>
        }
        return(
            <div>{temp}</div>
        )
    }
}

export default InterviewInvite