import React from "react";
import classes from "./VideoChatLayout.module.scss";
import SiaChat from "../../../containers/SiaChat/Chat/Chat";
// import Header from "../Header/Header";

function VideoChatLayout(props) {
  return (
    <div className={classes.VideoChatLayout}>
      {/* <Header /> */}
      <div className={classes.Container}>
        {/* <h1 className={classes.Title}>{props.title}</h1> */}
        {props.children}
      </div>
    </div>
  );
}

export default VideoChatLayout;
