import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import Modal from "react-modal";
import ChatCV from "../../../../UI/ChatModal/ChatCV/ChatCV";
import ChatVideo from "../../Modals/ChatvideoNearby";
import CoverLetter from "../../Modals/CoverLetterNearby";
import { Button, Card, Image, Icon } from "semantic-ui-react";
import axios from "../../../../../axios";
import * as actionsCreator from "../../../../../store/actions/index";
import Spinner from "../../../../UI/Spinner/Spinner";
import Swal from "sweetalert2";
import classes from "./FlowControl.module.scss";
import moment from "moment";
import defaultCompany from "../../../../../assets/image/sia-in-box.png";
import Progress from "../../../../UI/ChatModal/ChatCV/ChatCVProgress/Progress";
import description from "material-ui/svg-icons/action/description";
import { colors } from "material-ui/styles";
import defaultCamImage from "../../../../../assets/image/sia_default.png";

class FlowControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: false,
      isOpen: true,
      cvUpload: false,
      videoUpload: false,
      coverLetter: false,
      campaign: true,
      isApplyBtn: false,
      text: "aa",
      campaignName:"",
      campaignImage:"",
      currentNum:0,
      campaignId:0,
      campaignStartDate:"",
      company:"",
      campaignDes:"",
      location:"",
      companyLogo:"",
      isPublish:true,
      percentage:0
    };
  }

  componentDidMount(){
    if(this.props.share==true){
     this.setState({ campaign: false });
     this.ApplyCampign()    
    }
  }
  componentWillMount(){
    this.loadCampaginData()
  }
  closeModal = () => {
    this.setState({ isOpen: false });
    this.props.closeCampaign();
  };
  handleTextArea = (e) => {
    this.setState({
      text: e.target.value,
    });
  };
  handleApplyCVModalClose = () => {
    this.setState({ isOpen: false });
    this.props.closeCampaign();
  };
  ApplyCampign = () => {

    localStorage.setItem("APPLYDASH", true)

    this.setState({ isApplyBtn: true });
    var data = {
      candidateid: this.props.profile.id,
      campaignId: this.state.campaignId,
      ref: "",
      reminderDuration: 0,
      accessed: 0,
      status: "APPLIED",
      applied_method: "SIA",
      eventId:localStorage.getItem("eventId")
    };
    var token = localStorage.getItem("chat-token");
    this.props.onSaveApplyData(data);
    axios
      .post(process.env.REACT_APP_API_URL + "chat/apply-stand-by/", data, {
        headers: {
          Authorization: `token ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        axios
          .get(
            "get-applied-campaign-fetures/?campaignId=" +
              this.state.campaignId,
            {
              headers: {
                Authorization: `token ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.SetFeatures(res.data.Data);
            this.props.onSetCampaignFeatures(
              res.data.Data.cvUpload,
              res.data.Data.videoPitch,
              res.data.Data.coverLetter
            );
            localStorage.setItem(
              "CAMPAIGN_APPLIED_ID",
              this.state.campaignId
            );
          });
      })
      .catch((resCatch) => {});
  };
  SetFeatures = (data) => {

    this.setState({ campaign: false, isApplyBtn: false });

    if (data.cvUpload == true && data.coverLetter == true && data.videoPitch == true) {
      this.setState({
        campaign: false,
        cvUpload: true,
        coverLetter: false,
        videoUpload: false,
      });
    }
    if (data.cvUpload == false && data.coverLetter == true && data.videoPitch == true
    ) {
      this.setState({
        cvUpload: false,
        coverLetter: true,
        videoUpload: false,
      });
    }
    if (data.cvUpload == true && data.coverLetter == true && data.videoPitch == false
      ) {
        this.setState({
          cvUpload: true,
          coverLetter: false,
          videoUpload: false,
        });
      }
    if (data.cvUpload == false && data.videoPitch == false) {
      this.setState({
        cvUpload: false,
        coverLetter: true,
        videoUpload: false,
      });
    }
    if (data.cvUpload == false && data.videoPitch == true) {
      this.setState({
        cvUpload: false,
        coverLetter: true,
        videoUpload: false,
      });
    }

    if (data.coverLetter == false){
      if(data.cvUpload == false && data.videoPitch == false){
        this.setState({
          cvUpload: false,
          coverLetter: false,
          videoUpload: false
        });
      }
      if(data.cvUpload == true && data.videoPitch == false){
        this.setState({
          cvUpload: true,
          coverLetter: false,
          videoUpload: false
        });
      }
      if(data.cvUpload == false && data.videoPitch == true){
        this.setState({
          cvUpload: false,
          coverLetter: false,
          videoUpload: true
        });
      }
      //worst case scenario cv/videopitch/coverletter = false 
      if(data.cvUpload == false && data.videoPitch == false){
        this.setState({
          cvUpload: false,
          coverLetter: false,
          videoUpload: false,
          campaign :true,
          isApplyBtn: true
        });

        axios.post('apply-campaign-by-candidate/', this.props.appliedSaveData, {
          headers: {
            Authorization: `Token ${localStorage.getItem('chat-token')}`
          }
        }).then(appliedRes => {
          var currentTime = moment.utc().toISOString()
          var status = "COMPLETED"
          axios.get('update-standBy-process/?candidateId=' + this.props.appliedSaveData.candidateid + "&campaignId=" + this.props.appliedSaveData.campaignId + "&step=" + status, {
            headers: {
              Authorization: `Token ${localStorage.getItem('chat-token')}`
            }
          }).then(resUpdates => {
            Swal.fire({
              toast: true,
              position: "top-end",
              title: "Apply process completed successfully",
              showConfirmButton: false,
              timer: 2500,
              customClass: { container: "swal-success-settings" },
              imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
              imageHeight: 20,
            });
            this.setState({
              isApplyBtn: false
            })
            //this.props.onCVLProgress(false);
            // sessionStorage.removeItem("shareRef");
            // localStorage.removeItem("shareRef");
            // localStorage.removeItem("APPLYSHARECLICKED");
            window.location.replace("/")
          })
        })

      }
      if(data.cvUpload == true && data.videoPitch == true){
        this.setState({
          cvUpload: true,
          coverLetter: false,
          videoUpload: false
        });
      }
    }
  };
  confirmCvUpload = () => {
    if (this.props.originalCVStatus === true) {
      var status = "cv";
      this.props.onUpdateApplyState(this.props.appliedSaveData, status);
      localStorage.setItem("OpenSmartCV", false);
      var features = this.props.flowFeatures;
      if (features.coverLetter == true && features.video == true) {
        this.setState({
          campaign: false,
          cvUpload: false,
          coverLetter: true,
          videoUpload: false,
        });
      }
      if (features.coverLetter == true && features.video == false) {
        this.setState({
          campaign: false,
          cvUpload: false,
          coverLetter: true,
          videoUpload: false,
        });
      } 

      if (features.coverLetter == false){
        if (features.video == false){
          this.setState({
            campaign: false,
            cvUpload: false,
            coverLetter: false,
            videoUpload: false,
          });

          // axios.post('apply-campaign-by-candidate/', this.props.appliedSaveData, {
          //   headers: {
          //     Authorization: `Token ${localStorage.getItem('chat-token')}`
          //   }
          // }).then(appliedRes => {
          //   var currentTime = moment.utc().toISOString()
          //   var status = "COMPLETED"
          //   axios.get('update-standBy-process/?candidateId=' + this.props.appliedSaveData.candidateid + "&campaignId=" + this.props.appliedSaveData.campaignId + "&step=" + status, {
          //     headers: {
          //       Authorization: `Token ${localStorage.getItem('chat-token')}`
          //     }
          //   }).then(resUpdates => {
          //     Swal.fire({
          //       toast: true,
          //       position: "top-end",
          //       title: "Apply process completed successfully",
          //       showConfirmButton: false,
          //       timer: 2500,
          //       customClass: { container: "swal-success-settings" },
          //       imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          //       imageHeight: 20,
          //     });
          //     //this.props.onCVLProgress(false);
          //     sessionStorage.removeItem("shareRef");
          //     localStorage.removeItem("shareRef");
          //     localStorage.removeItem("APPLYSHARECLICKED");
          //     window.location.replace("/")
          //   })
          // })

        }
        if (features.video == true){
          this.setState({
            campaign: false,
            cvUpload: false,
            coverLetter: false,
            videoUpload: true,
          });
        } 
      }


      var data = {
        skills: this.props.skills ? this.props.skills.toString() : "",
        education: this.props.cvDataEdu == null ? [] :  this.props.cvDataEdu,
        experience: this.props.cvDataExp  == null ? [] : this.props.cvDataExp,
        onBoard_state : "UPDATE_SETTINGS_APPLY",
        upload : "",
        format_type : "",
        first_name : "sample",
        last_name  : "sample",
        career_preferences : ""
      };

      // phone_number: this.props.phoneNumber,
      // timeZone: zone[0],
      // current_location: lc[0],
      // onBoard_state: "COMPLETE",
      // uploaded_at: "2019-09-20T00:00:00Z",
      // upload: "",
      // userId: userID

      console.log(this.props.skills, "Skills")
      console.log(this.props.cvDataEdu, "education")
      console.log(this.props.cvDataExp, "Exp")

      var id = this.props.profileCandidate.userData.profile[0].id;
      this.props.onUpdateCVProfileData(data, id)

    } else if (this.props.originalCVStatus === false) {
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Please upload your original CV",
        showConfirmButton: false,
        timer: 2500,
        customClass: { container: "swal-success-settings" },
        imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        imageHeight: 20,
      });
    }
  };
  uploadCoverLetter = () => {
    var features = this.props.flowFeatures;
    if (features.video == true) {
      this.setState({
        campaign: false,
        cvUpload: false,
        coverLetter: false,
        videoUpload: true,
      });
      this.props.onCVLProgress(false);
    } else {
      this.setState({ isOpen: false });
      this.props.getAvailableCampaignType(this.props.profile.id);
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Apply process completed successfully",
        showConfirmButton: false,
        timer: 2500,
        customClass: { container: "swal-success-settings" },
        imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        imageHeight: 20,
      });
      this.props.onCVLProgress(false);
      sessionStorage.removeItem("shareRef");
      localStorage.removeItem("shareRef");

    }
  };
  handleNextButton = () => {
    if (this.state.currentNum + 1 < this.props.compaigns.length) {
      var data = this.props.compaigns[this.state.currentNum + 1]
      this.setState({
        campaignName: data.campaign.heading,
        campaignImage: data.campaign.campaignImage,
        campaignId: data.campaign.id,
        currentNum: this.state.currentNum + 1,
        campaignStartDate:data.campaign.startDate,
        company: data.company.name,
        location:data.campaign.location,
        campaignDes:data.campaign.description,
        companyLogo:data.company.companyLogo,
        isPublish:data.campaign.isPublish
    })
  }
  }

  handlePrevButton = () => {
    if (this.state.currentNum > 0) {
      var data = this.props.compaigns[this.state.currentNum - 1]
      this.setState({
        campaignName: data.campaign.heading,
        campaignImage: data.campaign.campaignImage,
        campaignId: data.campaign.id,
        currentNum: this.state.currentNum - 1,
        campaignStartDate:data.campaign.startDate,
        company: data.company.name,
        location:data.campaign.location,
        campaignDes:data.campaign.description,
        companyLogo:data.company.companyLogo,
        isPublish:data.campaign.isPublish
      })
    }
  }

  loadCampaginData = () => {
    this.setState({
      campaignName: this.props.campaign.heading,
      campaignImage: this.props.campaign.campaignImage,
      campaignId: this.props.campaign.id,
      currentNum: this.props.index,
      campaignStartDate: this.props.campaign.startDate,
      company: this.props.compaigns[this.props.index].company.name,
      location: this.props.campaign.location,
      campaignDes: this.props.campaign.description,
      companyLogo:this.props.compaigns[this.props.index].company.companyLogo,
      isPublish:this.props.campaign.isPublish
    })
  }
  percentage=(value)=>{
    this.setState({percentage:value})
  }

  render() {

    if (this.state.videoUpload==true){
      localStorage.setItem('VIDEOUPLOAD', true)
    }
    else if(this.state.videoUpload ==  false){
      localStorage.setItem('VIDEOUPLOAD', false)
    }


    if (this.props.originalCVStatus !== true) {
      this.props.onGetOriginalCVState();
    }
    var modal = "";
    if (this.state.campaign == true) {
      modal = (
        <div>
          <button
            className={classes.fixedClose}
            onClick={this.closeModal}
          >
            <i className="fas fa-times"></i>
          </button>      
          <div className="each-campaign-wrapper">
          <span className={classes.back_btn}  onClick={this.handlePrevButton}>{"<"}</span>
            <div className="each-campaign">
              <div className="images">
                <img
                  className="background"
                  src={
                     this.state.campaignImage
                      ? this.state.campaignImage
                      : defaultCamImage                      
                  }
                />
                <img
                  className="foreground"
                  src={
                    this.state.campaignImage
                    ? this.state.campaignImage
                      : defaultCamImage
                  }
                />
              </div>
              <div className="campaign-main-info">
                <div className="title">
                  {this.state.campaignName}
                </div>
                {/* <div className="category">{this.props.data.campaignType}</div> */}
                <div className="date">
                  {moment(this.state.campaignStartDate).format("DD-MM-YYYY")}
                </div>
              </div>
              <div className="campaign-bottom-description">
                <div className="company">
                  <img
                    alt="Company logo"
                    src={
                        this.state.companyLogo
                        ? this.state.companyLogo
                        : defaultCompany
                    }
                  />
                  <div>{this.state.company}</div>
                </div>
                <div className="job-location">
                  {this.state.location}
                </div>
                <div className="campaign-description-text nc-min-height">
                  {/* {this.state.campaignDes} */}
                  <div dangerouslySetInnerHTML={{ __html: this.state.campaignDes }} />
                </div>
                <div className="nc-btn-contnet nc-btn-contnet-center">
                  {this.state.isPublish == true?
                    <button onClick={this.ApplyCampign} className="sia-btn-modal">
                      <span>{this.state.isApplyBtn ? <Spinner /> : "Apply"}</span>
                    </button>
                    :
                    <button className="sia-btn-modal" style={{cursor:"not-allowed"}}>
                      <span>Applications closed</span>
                    </button>
                  }
                </div>
                <div className="nc-btn-contnet">
                  <div className="outer-text"> 
                    <button
                      onClick={this.closeModal}
                      className={classes.back_list_btn}
                    >
                      {this.props.share ?
                      "Back to share":"Back to the list"
                      }                      
                    </button>
                  </div>
                </div>
              </div>             
            </div>
            <span className={classes.back_btn} onClick={this.handleNextButton}>{">"}</span>
          </div>    
        </div>
      );
    }
    if (this.state.cvUpload == true) {
      if (this.props.progressUpload === true) {
        modal = <Progress percentage={this.state.percentage} />;
      } else if (this.props.progressUpload === false) {
        modal = (
          <ChatCV
            data={false}
            submitCV={true}
            closeCVmodal={this.handleApplyCVModalClose}
            next={this.confirmCvUpload}
            processPercentage={this.percentage}
            
          />
        );
      }
    }
    if (this.state.coverLetter == true) {
      if (this.props.progressUploadCVL === true) {
        modal = <Progress percentage={this.state.percentage} />;
      } else if (this.props.progressUploadCVL === false) {
        modal = (
          <CoverLetter
            uploadCover={this.uploadCoverLetter}
            closeModal={this.closeModal}
            videoUploadStatus={this.state.videoUpload} 
          />
        );
      }
    }
    if (this.state.videoUpload == true) {
      modal = <ChatVideo closeModal={this.closeModal} />;
    }
    return (
      <div
        className="upload-review-cv-modal"
        style={{
          textAlign: "center !important",
          textAlign: "-webkit-center !important",
        }}
      >
        <Modal
          isOpen={this.state.isOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
          className="cv-upload-modal-1 over"
          ariaHideApp={false}
        >
          <div className={
                this.state.cvUpload
                ? classes.borderedInnerWrap
                : classes.nonBorderedInnnerWrap
          }>         
          {modal}
            {/* <div className={classes.closeBtnWrapDiv}>
              <div className={classes.closeBtnDiv}>
                {this.state.cvUpload ? (
                  <button
                    className={classes.closeButtton}
                    onClick={this.confirmCvUpload}
                  >
                    Submit
                  </button>
                ) : null}
              </div>
            </div> */}
          </div>

          {/* <div className="campaign-apply-button">
                        {this.state.cvUpload ?
                            <button onClick={this.confirmCvUpload}>Submit</button> :
                            null
                        }
                    </div> */}
         
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignFeatures: state.CampaignByRef.campaignFeatures,
    profile: state.profile.userProfile,
    flowFeatures: state.CampaignByRef.campaignFlow,
    originalCVStatus: state.CandidateData.originaCVStatus,
    appliedSaveData: state.chatnew.appliedCampaignTemp,
    progressUpload: state.chatnew.uploadProgress,
    progressUploadCVL: state.chatnew.cvlProgress,
    cvDataExp : state.auth.cvDataExp,
    cvDataEdu : state.auth.cvDataEdu,
    skills : state.auth.skills,
    profileCandidate: state.CandidateData.userData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAvailableCampaignType: (data) =>
      dispatch(actions.getAvailableCampaignSlider(data)),
    getCampaignFeatures: (data) =>
      dispatch(actionsCreator.getCampaignFeatures(data)),
    onSaveApplyData: (data) => dispatch(actionsCreator.saveApplyData(data)),
    onSetCampaignFeatures: (cvUpload, videoPitch, coverLetter) =>
      dispatch(
        actionsCreator.SetCampaignFeatures(cvUpload, videoPitch, coverLetter)
      ),
    onUpdateApplyState: (data, status) =>
      dispatch(actionsCreator.updateApplySaveData(data, status)),
    onGetOriginalCVState: () => dispatch(actionsCreator.getoriginlCVStatus()),
    onUpdateCVProfileData : (data,id) => dispatch(actionsCreator.UpdateCVProfile(data,id)),
    onCVLProgress: (data) => dispatch(actionsCreator.CVLProgress(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FlowControl)
);
