import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Live from "./Live/Live";
import Past from "./Past/Past";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import ObjectChecker from '../../../hoc/objectChecker'
import "./Campaign.scss";
import PastImg from "../../../../src/assets/image/past-arrow.svg";
import LiveImg from "../../../../src/assets/image/future-arrow.svg";

class CampaignData extends Component {

  render() {  

    var liveCampaign = []
    var pastData = []
    var past = null
    console.log(this.props.data, "campaigns data#####")
    if (this.props.data != null) {
      liveCampaign = this.props.data.LiveCampaigns
      pastData = [ObjectChecker(this.props.data, ['PastCampaigns'])] 
      past = <Past data={pastData} />;
    }
   // var liveData=  [ObjectChecker(this.props.data, ['LiveCampaigns'])] 
    var liveData = liveCampaign
    
    let live = <Live data={liveData} />;
    //let past = <Past data={pastData} />;
    return (
      <div id="candidate-dashboard-campaigns-panel">
        <Tabs>
          <TabList>
            <Tab>
              <img src={LiveImg} />
              <div className="text-tab-name">
                LIVE CAMPAIGNS
              </div>
            </Tab>
            <Tab>
              <img src={PastImg} />
              <div className="text-tab-name">
                PAST CAMPAIGNS
              </div>
            </Tab>
          </TabList>
          <TabPanel>{live}</TabPanel>
          <TabPanel>{past}</TabPanel>
        </Tabs>
      </div>
    );
  }
}
export default CampaignData;
