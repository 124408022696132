import * as actions from '../actions/actionTypes'


const initialState = {
    loading: false,
    userProfile: null,
    message: null,
    cvDashboard : false,
    cvFilterData:null,
    webinar:null,
    downloadData:null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.PROFILE_INIT:
            return {
                ...state,
                loading: true,
                message: null
            }
        case actions.PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                userProfile: action.userProfile,
                message: "Profile success"
            }
        case actions.PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                message: "Profile fial"
            }
        case actions.UPLOAD_CV_DASHBOARD:
            return{
                ...state,
                loading : false,
                cvDashboard : action.data
            }
        case actions.UPLOAD_CV_EXTRACT_SUCCESS:
            return{
                ...state,
                loading : false,
                cvFilterData : action.data
            }  
        case actions.SHOW_WEBINAR_SUCCESS:
                return{
                    ...state,
                    loading : false,
                    webinar : action.data
                }
        case actions.DOWNLOAD_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadData: action.data
            }                
        default:
            return state
    }
}
export default reducer