import React, { Component } from "react";
import Classes from "./Count.scss";
import CountDown from "../CountDown";
import axios from "../../../../axios";

class App extends Component {
  state = {
    token1: localStorage.getItem("chat-token"),
    startTime: "",
    endTime: "",
    URL: process.env.REACT_APP_API_URL + "manager",
    ref: localStorage.getItem("ref_id")
  };
  componentDidMount = () => {
    this.getIntervewdata();
  };
  getIntervewdata = () => {
    console.log("Get data");
    console.log("Get data");
    console.log(this.state.token1, "token");
    try {
      axios
        .get(this.state.URL + "/interviews/", {
          headers: {
            Authorization: `token ${this.state.token1}`
          }
        })
        .then(res => {
          const jsonKey = res.data.results;
          console.log(jsonKey, "Interview data");
          const row = jsonKey.find(ref => ref.ref == this.state.ref);
          console.log(row, "Interview row data");
          //get start time
          const start_time = row["start_time"];
          console.log(start_time, "start time");
          var time = start_time.split("Z")[0].split("T")[1];
          var date = start_time.split("Z")[0].split("T")[0];

          const startTime = date + " " + time;

          //Get current time
          var today = new Date();
          var currentDate =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
          var currentTime =
            today.getHours() +
            ":" +
            today.getMinutes() +
            ":" +
            today.getSeconds();
          var currentDateTime = currentDate + " " + currentTime;

          var offset = new Date().getTimezoneOffset();
          console.log(offset, "offset");
          var start_time_local = new Date(
            Date.parse(startTime) - offset * 60000
          );
          start_time_local =
            start_time_local.getHours() +
            ":" +
            start_time_local.getMinutes() +
            ":" +
            start_time_local.getSeconds();
          start_time_local = date + " " + start_time_local;

          var endTime_local = new Date(
            Date.parse(start_time_local) + 20 * 60000
          );
          endTime_local =
            endTime_local.getHours() +
            ":" +
            endTime_local.getMinutes() +
            ":" +
            endTime_local.getSeconds() +
            "0";
          endTime_local = date + "T" + endTime_local;
          console.log(start_time_local, "local start time");
          console.log(endTime_local, "End time local");

          this.setState({
            endTime: endTime_local
          });
        });
    } catch {
      console.log("No interviews");
    }
  };

  render() {
    var endTimeCount = this.state.endTime;
    console.log(endTimeCount, "Time count");
    localStorage.setItem("End_Time_count", endTimeCount);
    return (
      <div className="countx">
        <CountDown date={`${endTimeCount}`} />
      </div>
    );
  }
}
export default App;
