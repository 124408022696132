import React, { Component } from "react";
import classes from "./ExperienceInfo.module.scss";
import XPEachData from "./XPEachData";
import ExpData from "../ExperienceInfo/ExperienceData/Data";

class ExperienceInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputExperience: false
    };
  }
  enterExperienceDetails = () => {
    this.setState({
      inputExperience: !this.state.inputExperience
    });
  };

  deleteExpData=(index)=>{
    this.props.deleteExpe(index)
  }
  editExperience=(index)=>{
    this.props.editExperience(index)
  }

  render() {
    var data = this.props.data.map((res, index) => {
      return <ExpData editExperience={this.editExperience} deleteExp={this.deleteExpData} data={res} dataIndex={index} key={index} />;
    });

    return (
  
      <div>
        <ul>{data}</ul>
      </div>
    );
  }
}

export default ExperienceInfo;
