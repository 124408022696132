import extension from "material-ui/svg-icons/action/extension";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter ,Link} from "react-router-dom";
import VideoConference from  '../../../../containers/Interview/VideoConference/VideoConference';
import axios from '../../../../axios';

class HasInterview extends Component{
    constructor(props){
        super(props)
        this.state={
            candidateId: null,
            eventId:null,
            stallId:null
        }
    }

    componentDidMount(){
        var ref = sessionStorage.getItem("ref")
        axios
        .get("ref-validation-event/?ref=" + ref, {
            headers: {
                Authorization: `token ${localStorage.getItem("chat-token")}`
            }
        })
        .then(res => {
            var data = res.data.Data
            this.setState({candidateId : res.data.Data.candidateId, eventId: res.data.Data.eventId, stallId:res.data.Data.stallId})
        });
    }

    render(){
        return(
            <div> Check has interview </div>
        )
    }
}
export default HasInterview