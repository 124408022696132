import React, { useState, Component, useEffect } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { BrowserRouter } from "react-router-dom";
import axios from "../../axios";
import classes from "./Campaign.module.scss";
import Layout from "../../components/Layout/Layout";
import showcomponent from "../../components/UI/VideoChat/VideoChat";
import Campaign from "../CampaignDasboard/CampaginBody/Campaign";
import { NavLink, Switch, Route, withRouter, Link } from "react-router-dom";
import { faTh, faListUl } from "@fortawesome/free-solid-svg-icons";
import Animate from "react-smooth";
import { List, Avatar } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Comment from "../../components/InterviewerComment/Index";

class CampaignDashBorad extends Component {
  state = {
    token: localStorage.getItem("chat-token"),
    URL: process.env.REACT_APP_API_URL + "manager",
    URLChat: process.env.REACT_APP_API_URL + "chat",
    campaing: [],
    userId: "",
    comments: []
  };

  componentDidMount = () => {
    this.getUserId();
    this.getCampaign();
    this.getDashboardData();
    this.getCandidateComments();
  };

  getUserId() {
    axios
      .get("users/me/", {
        headers: {
          Authorization: `token ${this.state.token}`
        }
      })
      .then(res1 => {
        this.setState({ userId: res1.data.id });
        console.log(res1, "Response");
      });
  }

  getCandidateComments() {
    const localToken = localStorage.getItem("chat-token");
    const campId = 0;
    const intwId = 0;
    return axios
      .get(
        this.state.URLChat +
          "/get-feedback/?campaignId=" +
          campId +
          "&interviewId=" +
          intwId +
          "&userId=" +
          this.state.userId,
        {
          headers: {
            Authorization: `Token ${localToken}`,
            "Content-Type": `application/json`
          }
        }
      )
      .then(res => {
        console.log(res, "Feedback");
        this.setState({ comments: res.data["Data "] });
      });
  }
  getDashboardData = () => {
    let newDate = new Date();
    var data = {
      token: localStorage.getItem("chat-token"),
      date: newDate.toISOString()
    };
    //${newDate.toISOString()}
    axios
      .get(this.state.URLChat + `/get-cdt-dashboard-data/ `, data)
      .then(res => {
        console.log("Details:", res);
      });
  };
  getCampaign = () => {
    axios
      .get(this.state.URL + "/campaigns-current/", {
        headers: {
          Authorization: `token ${this.state.token}`
        }
      })
      .then(res => {
        this.setState({
          campaing: res.data.results
        });
      });
  };
  routChange = e => {
    console.log("Route change");
    const campaignID = 4;
    let path = `/Video` + `/` + campaignID;
    const pathexit = window.location.href.split("/")[2];
    //console.log(pathexit)
    const url = "http://" + pathexit + path;
    console.log(url);
    window.location.assign(url);
  };

  render() {
    console.log(this.state.campaing, "campaign data");

    var CampaignData = this.state.campaing;

    const dataList = CampaignData.map(name => {
      try {
        var startTime = name.start_time.split("T")[0];
        var endTime = name.end_time.split("T")[0];
      } catch {}
      console.log(name);

      return (
        <li>
          <div className={classes.EachCampaignRow}>
            <img className={classes.Left} src={name.campaign_image} />
            <div className={classes.Right}>
              <div className={classes.CampaignHeading}>{name.heading}</div>
              <div className={classes.CampaignDates}>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  size="lg"
                  style={{ color: "grey" }}
                />
                {startTime} to {endTime}
              </div>
              {name.venue && (
                <div className={classes.Venue}>Venue : {name.venue}</div>
              )}
              {name.sponser && (
                <div className={classes.Venue}>
                  Sponsored by : {name.sponser}
                </div>
              )}
              <Link className={classes.Link} to={`/Video/${name.id}`}>
                Continue..
              </Link>
            </div>
          </div>
        </li>
      );
    });
    var commentList = null;
    var list = this.state.comments;

    commentList = list.map((res, index) => {
        return (
          <div>
            <Comment Comment={res} />
          </div>      
        );
      });
    return (
      <Layout style={{ margin: "13px" }} title="Dashboard">
        <Animate to="1" from="0" attributeName="opacity">
          <div className={classes.CamList}>
            <ul className={classes.FontStyles}>{dataList}</ul>
          </div>
          <div className={classes.campaignStats}>
            <div className={classes.statFlex}>
              <div className={classes.leftStats}>
                <div>APPLIED</div>
                <div className={classes.appliedBox}>7</div>
                <div>IN TOTAL</div>
              </div>
              <div className={classes.rightStats}>
                <div className={classes.eachChart}>
                  {/* replace =====>*/}
                  <div className={classes.roundChart} />
                  {/* <=====replace */}
                  <div className={classes.topic}>SHORTLISTED</div>
                  <div className={classes.digit}>6</div>
                </div>
                <div className={classes.eachChart}>
                  {/* replace =====>*/}
                  <div className={classes.roundChart} />
                  {/* <=====replace */}
                  <div className={classes.topic}>INTERVIEWED</div>
                  <div className={classes.digit}>3</div>
                </div>
                <div className={classes.eachChart}>
                  {/* replace =====>*/}
                  <div className={classes.roundChart} />
                  {/* <=====replace */}
                  <div className={classes.topic}>SELECTED</div>
                  <div className={classes.digit}>1</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.campaignStats}>
            <div>Feedback</div>
            {commentList}
          </div>
        </Animate>
      </Layout>
    );
  }
}
export default CampaignDashBorad;
