import React, { Component } from "react";
import { Route, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./Campaign.module.scss";
import axios from "../../../../axios";
import moment from "moment";
import swal from "sweetalert2";
import * as actionsCreator from "../../../../store/actions/index";
import SchduleTime from "./SchduleTime";
import Spinner from "../../../UI/Spinner/Spinner";

class SchduleDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schduleTime: [],
      selectTime: false,
      time: "",
      reminder: "",
      isSchdule: false,
      timeColor: "",
      SchduleDate: "",
    };
  }

  viewDates = (date) => {
    this.setState({ selectTime: false, schduleDate: date,reminder:"" });
    var date = date;
    var ref = this.props.refData;
    var token = localStorage.getItem("chat-token");

    console.log(ref,"time ref")

  // this.getSchduleTimes(ref,date)

    axios
      .get(
        process.env.REACT_APP_API_URL +
          "chat/get-available-timeslots-candidate/?ref=" +
          ref +
          "&start_time=" +
          date,
        {
          headers: {
            Authorization: `token ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        this.setState({ schduleTime: res.data });
      })
      .catch((resCatch) => {});
  };


  getSchduleTimes=(ref,date)=> {
    var host =process.env.REACT_APP_WEB_SOCKET_TIME_SCHDULE+"/?ref="+ref+"&startTime="+date
    var wsocket = new WebSocket(host);     
    wsocket.onopen=function(evt){
        console.log("Server connected,avalable times");
    };
    wsocket.onmessage = (evt) => {
      if (evt.data != "DoNotMatch") {
        var obj = JSON.parse(evt.data)
        console.log(obj.Data, "times")
        this.setState({ schduleTime: obj.Data.times });
      }
    };
  } 


  selectTime = (time) => {
    this.setState({ selectTime: true, time: time });
  };
  updataReminder = (reminder) => {
    this.setState({ reminder: reminder });
  };
  updateSchdule = () => {
    this.setState({ isSchdule: true });
    var date = this.state.time;
    var ref = this.props.refData;
    var reminder = this.state.reminder;
    var token = localStorage.getItem("chat-token");
    var eventId=localStorage.getItem("eventId")
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "chat/update-interview-time/?ref=" +
          ref +
          "&time=" +
          date +
          "&reminder=" +
          reminder+"&eventId="+eventId,
        {
          headers: {
            Authorization: `token ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        swal.fire({
          toast: true,
          position: "top-end",
          title:
            "Thank you for your interest on the campaign. You’ll be notified the next interview process very soon.",
          showConfirmButton: false,
          timer: 2500,
          customClass: { container: "swal-success-settings" },
          imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          imageHeight: 20,
        });
        this.setState({ isSchdule: false });
        this.props.closeModal();
        var currentTime = moment.utc().toISOString();
        this.props.onGetCandidateData(token, currentTime);      
        var payload = "/successfull_scheduled_by_dashboard"
        this.props.onGetChatResponseNew(payload, false)

      })
      .catch((resCatch) => {});
  };
  close = () => {
    this.props.closeModal();
  };
  render() {
    var localTime = "";

    return (
      <div>
        <div>
          <h5>Please choose a date for the interview</h5>
          {this.props.schduleDate.map((res, index) => {
            return (
              <button
                className={classes.modal_shedule_btn}
                style={{
                  border: res == this.state.schduleDate ? "1px solid #1697fa" : "",
                }}
                key={index}
                onClick={(data) => this.viewDates(res)}>
                {res}
              </button>
            );
          })}
        </div>
        {this.state.schduleTime.length > 0 ? (
          <div>
            <h5 className={classes.si_mt_10px}>
              Please choose a timeslot for the interview
            </h5>
            {this.state.schduleTime[0].available_times.map((res, index) => {
              localTime = moment(res.start_time).format("HH:mm:ss A")
              //localTime = moment(localTime).format("HH:mm:ss A");
              var currentTime =  moment.utc().toISOString();
              var slotTime = moment(res.start_time).toISOString();

              if (slotTime >= currentTime){
                return (
                  <button
                  style={{
                    border: res.start_time == this.state.time ? "1px solid #1697fa" : "",
                  }}
                    key={index}
                    className={classes.modal_shedule_btn}
                    onClick={(data) => this.selectTime(res.start_time)}
                  >
                    {localTime}
                  </button>
                  // <SchduleTime selectTime={this.selectTime} closeModal={this.close} refData={this.props.refData} key={index} data={res}></SchduleTime>
                );
              }
              else{
                console.log("abc")
              }        
            })}
          </div>
        ) : null}
        {this.state.selectTime ? (
          <div>
            <h5 className={classes.si_mt_10px}>
              You can select your notify time, prior to the interview
            </h5>
            <button
              style={{
                border: 30 == this.state.reminder ? "1px solid #1697fa" : "",
              }}
              className={classes.modal_shedule_btn}
              onClick={(data) => this.updataReminder(30)}
            >
              30 minutes
            </button>
            <button
              style={{
                border: 60 == this.state.reminder ? "1px solid #1697fa" : "",
              }}
              className={classes.modal_shedule_btn}
              onClick={(data) => this.updataReminder(60)}
            >
              One hour
            </button>
            <button
              style={{
                border: 90 == this.state.reminder ? "1px solid #1697fa" : "",
              }}
              className={classes.modal_shedule_btn}
              onClick={(data) => this.updataReminder(90)}
            >
              One and a half hour
            </button>
            <button
              style={{
                border: 0 === this.state.reminder ? "1px solid #1697fa" : "",
              }}
              className={classes.modal_shedule_btn}
              onClick={(data) => this.updataReminder(0)}
            >
             No thanks
            </button>
          </div>
        ) : null}
        {this.state.reminder !== "" ? (
          <div className={classes.si_shedule_btn_content}>
            <button onClick={this.updateSchdule} style={{fontSize:"14px"}}>
              {this.state.isSchdule ? <Spinner /> : "Schedule"}
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetCandidateData: (data, currentTime) =>
      dispatch(actionsCreator.getCandidateData(data, currentTime)),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(actionsCreator.getChatResponseNew(message, status, notification))
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SchduleDate)
);
