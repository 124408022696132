import React, { Component } from "react";
import UserPlaceholder from "../../assets/image/man.svg";
import axios from "../../axios";

class ProfileEdit extends Component {
  state = {
    CHATURL: process.env.REACT_APP_API_URL + "chat/",
    firstName: "",
    lastName: "",
    image: "",
    isError: false,
    Error: "",
  };

  componentDidMount = () => {
    var tempName = localStorage.getItem("chat-username");
    // var tempNameArray = tempName.split(/(?<=^\S+)\s/);
    var tempNameArray = [];
    for (var count = 0; count < tempNameArray.length; count++) {
      if (count == 0) this.setState({ firstName: tempNameArray[count] });
      else this.setState({ lastName: tempNameArray[count] });
    }
  };

  handleValidate = () => {
    var error = "";
    if (this.state.firstName.trim().length < 1) error = "First Name Required!";
    if (this.state.lastName.trim().length < 1) error = "Last Name Required!";
    if (this.state.image.trim().length < 1) error = "Image Required!";

    if (error.trim().length > 0) {
      this.setState({ Error: error, isError: true });
    }
  };

  handleGeneralInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  fileChangeHandler = (e) => {
    var base64;
    var that = this;
    console.log("base64", e.target.files);
    if (e.target.files && e.target.files[0]) {
      var FR = new FileReader();
      FR.addEventListener("load", function (e) {
        base64 = e.target.result;
        document.getElementById("img").src = e.target.result;
        that.setState({
          image: e.target.result,
        });
        // document.getElementById("b64").innerHTML = e.target.result;
      });
      FR.readAsDataURL(e.target.files[0]);
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    console.log("Clicked");
    this.handleValidate();
    if (!this.state.isError) {
      //  details update process
      console.log("Entered");
      var data = {
        Token: localStorage.getItem("chat-token"),
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        prof_image: this.state.image,
      };

      axios
        .post(this.state.CHATURL + "candidate-profile-edit/", data, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => {
          console.log("Results data", res.data);
          if (res.data.Result == "Updated success") {
            // if success redirect to main page
            window.location.replace("/");
          } else {
            // show unsucces message
          }
        });
    }
  };

  render() {
    return (
      <>
        <h2>Profile Edit</h2>
        <form>
          <div>
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              onChange={this.handleGeneralInputChange}
              value={this.state.firstName}
            />
          </div>

          <div>
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              onChange={this.handleGeneralInputChange}
              value={this.state.lastName}
            />
          </div>

          <div>
            <label>Profile Image</label>
            <img
              id="img"
              src={UserPlaceholder}
              style={{ height: "50px", width: "50px" }}
            />
            <input type="file" name="image" onChange={this.fileChangeHandler} />
          </div>

          <button type="submit" onClick={this.onSubmit}>
            {" "}
            Save
          </button>
        </form>
      </>
    );
  }
}

export default ProfileEdit;
