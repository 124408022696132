import React from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsCreator from "../../../store/actions/index";
import Profile from "../../../components/CandidateDashboard/Profile/Profile";
import classes from "./SettingsWrapper.module.scss";
import Preferences from "../../../components/OnBoard/CareerPreferences/PreferencesForSettings";
import FilteredData from "../../../components/OnBoard/cvFilteredData/FilteredDataForSettings";
import FeedBack from "../../../components/CandidateDashboard/FeedBack/FeedBack";
import Progress from "../../../components/CandidateDashboard/Progress/Progress";
import NearbyCampaigns from "../../../components/CandidateDashboard/NearbyCampaigns/NearbyCampaigns";
import swal from "sweetalert2";
import Animate from "react-smooth";
import CVPanel from "../../../components/OnBoard/CvUpload";
import { Modal } from "semantic-ui-react";
import moment from "moment";

class SettingsWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settingData: {
        skills: [],
        upload: "",
        education: this.props.profile.userData.education,
        experience: this.props.profile.userData.experience,
        career_preferences: [],
        format_type:""           
      },
    };
  }
  saveSettingData = () => { 
    var data = {
      phone_number: "",
      timeZone: "",
      current_location: "",
      onBoard_state: "UPDATE_SETTINGS",
      uploaded_at: "2019-09-20T00:00:00Z",
      upload: this.state.settingData.upload,
      userId: this.props.profile.userData.profile[0].user_id,
      skills: this.state.settingData.skills.toString(),
      profile_pic: "",
      first_name: "new",
      last_name: "new",
      education: this.state.settingData.education,
      experience: this.state.settingData.experience,
      career_preferences: this.state.settingData.career_preferences.toString(),
      format_type:this.state.settingData.format_type
    };
    var id = this.props.profile.userData.profile[0].id;
    this.props.onUpdateCandidateSetting(data, id,this.props.completeProfile);
    swal.fire({
      toast: true,
      position: "top-end",
      title: "Updated successfully",
      showConfirmButton: false,
      timer: 2500,
      customClass: { container: "swal-success-settings" },
      imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
      imageHeight: 20,
    });
   // var currentTime = moment.utc().toISOString();
   // this.props.onGetCandidateData(localStorage.getItem("chat-token"), currentTime);
    this.props.setChanged(false);  
    
    if(this.props.completeProfile==true){
      this.props.openChat()
    }
  };

  setPreferences = (val) => {
    // console.log("3337", val.split(","));
    var settingData = { ...this.state.settingData };
    settingData.career_preferences = val.split(",");
    this.setState({ settingData });
  };

  profileTab=()=>{
    this.props.changeTabProfile()
  }

  render() {
    let profile = <Profile profileTabChange={this.profileTab} data={this.props.profile} />;
    let progress = <Progress data={this.props.candidateDate} />;
    let feedback = <FeedBack data={this.props.candidateDate} />;
    let nearby = <NearbyCampaigns data={this.props.candidateDate} />;
    const modal = (
      <Modal
        open={this.props.dashboardCV}
        size="tiny"
        className="noBootstrapModal"
        onClose={this.closeModal}
      >
        <CVPanel
          flag={this.props.dashboardCV}
          changeTabCareer={this.props.changeTabCareer}
          // changeTabCareer={() => console.log("gyg")}
        />
      </Modal>
    );
    return (
      <Animate to="1" from="0" attributeName="opacity">
         {modal}
        <div className={classes.settingsWrapper}>
          <div className={classes.settingsContent}>
            <div className={`${classes.profileWrapper} above768block`}>
              {profile}
            </div>
            <div className={classes.centerComponent}>
              {/* <div className={classes.dataRow}>
                <div className={classes.left80}>Career preference</div>
                <Preferences
                  preferences={this.state.settingData.career_preferences}
                  setPreferences={this.setPreferences}
                />
              </div> */}
              <FilteredData
                settingData={this.state.settingData}
                cvData={this.state.cvData}
                preferences={this.state.settingData.career_preferences}
                setPreferences={this.setPreferences}
              />
              <div className={classes.saveButton}>
                <button onClick={this.saveSettingData}>Save Changes</button>
              </div>
            </div>
            {/* <div className={classes.rightSidebarWrapper}>
              <div className={classes.feedbackWrapper}>{feedback}</div>
              <div className={classes.nearbyCampaignsWrapper}>{nearby}</div>
            </div> */}
          </div>
        </div>
      </Animate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.CandidateData.userData,
    dashboardCV: state.profile.cvDashboard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCandidateSetting: (data, id,status) =>
      dispatch(actionsCreator.updateCandidateSetting(data, id,status)),
    setChanged: (data) => dispatch(actionsCreator.setSomethingChanged(data)), 
    onGetCandidateData: (data, time) =>
    dispatch(actionsCreator.getCandidateData(data, time)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingsWrapper)
);
