import * as actions from "./actionTypes";
import axios from "../../axios";

//get campaigns

export const initGetCampaignNew = () => {
  return {
    type: actions.GET_CAMPAIGN_NEW_INIT
  };
};

export const failGetCampaignNew = () => {
  return {
    type: actions.GET_CAMPAIGN_NEW_FAIL
  };
};
export const successGetCampaignNew = data => {
  return {
    type: actions.GET_CAMPAIGN_NEW_SUCCESS,
    data
  };
};
export const successCampaignRef = data => {
  return {
    type: actions.GET_CAMPAIGN_REF,
    data
  };
};

export const successClicked = data => {
  return {
    type: actions.GET_APPLY_CLICKED,
    data
  };
};


export const successCampaignProcess = data => {
  return {
    type: actions.GET_CAMPAIGN_PROCESS,
    data
  };
};

export const successAvailableCampaignType = data => {
  return {
    type: actions.SUCCESS_AVAILABLE_CAMPAIGN_TYPE,
    data
  };
};
export const successAvailableCampaign = data => {
  return {
    type: actions.GET_SUCCESS_AVAILABLE_CAMPAIGN,
    data
  };
};

export const successGetCampaignFeatures = data => {
  return {
    type: actions.GET_SUCCESS_CAMPAIGN_FEATURES,
    data
  };
};

export const SetCampaignFeaturesSuccess = (data)=>{
  return {
    type : actions.SET_CAMPAIGN_FEATURES_SUCCESS,
    data
  }
}

export const getCampaignNew = () => {
  return dispatch => {
    const token = localStorage.getItem("chat-token");

    dispatch(initGetCampaignNew());
    axios
      .get("http://127.0.0.1:8000/manager/campaigns-current/", {
        headers: {
          Authorization: `token ${token}`
        }
      })
      .then(res => {
        dispatch(successGetCampaignNew(res.data));
      })
      .catch(err => {
        dispatch(failGetCampaignNew());
      });
  };
};

export const onApplyCampaign = data => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .post("apply-campaign-by-candidate/", data, {
        headers: {
          Authorization: `token ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        //dispatch(successCampaignRef(res.data))
      });
  };
};

export const getCampaignByRef = data => {
  console.log("Get campaign data by ref");
  return dispatch => {
    axios
      .get("get-campaign-share-data/?shareRef=" + data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        dispatch(successCampaignRef(res.data));
      });
  };
};

export const getCampaignProcess =(campaignId,id) => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .get("get-candidate-campaign-process/?campaignId="+campaignId+"&candidateId="+id, {
        headers: {
           Authorization: `token ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        dispatch(successCampaignProcess(res.data));
      });
  };
};

export const applyByShare = data => {
  console.log(data, "Apply button click");
  return dispatch => {
    dispatch(successClicked(data));
  };
};

export const getAvailableCampaignType =(id) => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .get("get-available-campaign-type-candidate/?senderId="+id, {
        headers: {
           Authorization: `token ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        dispatch(successAvailableCampaignType(res.data));
      });
  };
};

export const getAvailableCampaignSlider =(id,eventId) => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .get("get-available-campaigns-by-type/?candidateId="+id+"&eventId="+eventId, {
        headers: {
           Authorization: `token ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        dispatch(successAvailableCampaign(res.data));
      });
  };
};

export const getCampaignFeatures =(id) => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .get("get-applied-campaign-fetures/?campaignId="+id, {
        headers: {
           Authorization: `token ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        dispatch(successGetCampaignFeatures(res.data));
      });
  };
};


//campaign apply viadashoard
export const SetCampaignFeatures =(cvUpload, videoPitch,coverLetter)=>{
  return dispatch =>{
    var data ={
      cv : cvUpload,
      video : videoPitch,
      coverLetter: coverLetter
    }
    dispatch(SetCampaignFeaturesSuccess(data))

  }
}