import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
// import VideoConference from '../components/VideoConference/VideoConference'
import VideoConference from "../containers/Interview/VideoConference/VideoConference";
import Chat from "./SiaChat/Chat/Chat";
//import Webinnar from '../components/Webinnar/webinnar'
import PassWordLess from "../components/PasswordLessLogin/PaswrdLesLogin";
import Webinnar from "../containers/Webinnar/WebinnarPrew";
import CampaingDashBoard from "../components/CampaignDasboard/CampaginDashboard";
import VideoChat from "../containers/VideoChat/videoChat";
import PassworlessLogin from "../components/PasswordLessLogin/PaswrdLesLogin";
import PasswordLoginSignup from "../components/PasswordLessLogin/RegisterUser";
import ProfileEdit from "../components/CandidateProfile/Edit";
import Page404 from "../containers/NotFound";
import CandidateDashBorad from "../containers/Dashboard/Dashboard";
import Share from "../components/CampaignShare/CampaignShare";
import OnBoard from "../containers/Dashboard/OnBoard";
// import SettingsContent from "../containers/Dashboard/Settings/SettingsContent";

export class Dashbord extends Component {
  state = {
    freshUser: false
  };
  componentDidMount = () => {
    // this.props.history.push('/')
  };
  render() {
    const NoMatchPage = () => {
      return <h3>404 - Not found</h3>;
    };
    return (
      <Switch>
        {/* <Route  path="/video-conference-groupChat" component={VideoConference} /> */}
        {/* <Route  path="/video-conference-groupChat" component={CampaingDashBoard} /> */}
        {/* <Route exact path="/Login" component={PassworlessLogin} /> 
                <Route exact path="/Register" component={PasswordLoginSignup} /> */}
        <Route
          exact
          path="/Campaings"
          component={
            localStorage.getItem("OnBoard_State") == "COMPLETE"
              ? CampaingDashBoard
              : OnBoard
          }
        />
        
        <Route exact path="/Video/:id" component={VideoConference} />
        <Route exact path="/videochat" component={VideoChat} />
        {/* <Route exact path="/settings" component={SettingsContent} /> */}
        <Route exact path="/ProfileEdit" component={ProfileEdit} />
        <Route
          exact
          path="/"
          component={
            localStorage.getItem("OnBoard_State") == "COMPLETE"
              ? Chat
              : OnBoard
          }
        />

        <Route exact path="/candidate" component={CandidateDashBorad} />
        <Route exact path="/err" component={Page404} />
        <Route exact path="/Share" component={Share} />
        <Route exact path="/OnBoard" component={OnBoard} />
        {/* <Route exact path="/settings" component={SettingsContent} /> */}
        <Route component={Page404} />
      </Switch>
    );
  }
}
export default withRouter(Dashbord);
