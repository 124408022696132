import React, { Component } from "react";
import classes from "./FAQSection.module.scss";
import { Accordion, Icon } from "semantic-ui-react";

export default class FAQSection extends Component {
  state = {
    activeIndex: -1,
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  render() {
    const { activeIndex } = this.state;
    return (
      <div className={classes.faqType}>
        <h2>{this.props.data.title}</h2>
        <Accordion styled className="faq-accordion">
          {this.props.data.content.map((eachQ, index) => {
            return (
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === index}
                  index={index}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  {eachQ.q}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index}>
                  <p>{eachQ.a}</p>
                </Accordion.Content>
              </React.Fragment>
            );
          })}
        </Accordion>
      </div>
    );
  }
}
