import React, { Component } from 'react'
import Layout from '../../components/Layout/Layout'
import classes from './WebinnarPrew.scss'
//import "./Styles/styles.css";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { BrowserRouter } from "react-router-dom";
//import VideoChatNew from '../VideoChat/videoChat'
import Webinnar from '../../components/Webinnar/webinnar'

class VideoConference extends Component {
  render() {
      return (
        <Layout title="Fast Track Summer Internship programe Webinnar">
                <div className={classes.VideoLayout}>
                    <div className={classes.LeftComponent}>
                        <div className={classes.VideoConference}>
                        <MuiThemeProvider>
                            <BrowserRouter>
                                <div>
                                    <Webinnar />
                                </div>
                            </BrowserRouter>
                        </MuiThemeProvider>   
                        </div>
                    </div>

                    {/* <div className={classes.TabContent}>
                      <ChatTabs />
                    </div> */}
                </div>
        </Layout>
    )
  }
}
export default VideoConference