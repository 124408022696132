import * as actions from '../actions/actionTypes'


const initialState = {
    loading: false,
    responseMessage: null,
    message: null,
    messages: null,
    numberOfpages: null,
    questions:null,
    careerSuggetions:null,
    supportUsers:null,
    webSocketData:null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actions.CHAT_INIT:
        return {
          ...state,
          loading: true,
          message: null
        };
      case actions.CHAT_SUCCESS:
        return {
          ...state,
          loading: false,
          responseMessage: action.message,
          message: "Profile success"
        };
      case actions.CHAT_FAIL:
        return {
          ...state,
          loading: false,
          message: "Profile fial"
        };
      case actions.HISTORY_FAIL:
        return {
          ...state,
          loading: false
        };
      case actions.HISTORY_INIT:
        return {
          ...state,
          loading: true
        };
      case actions.HISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
          messages: action.messages,
          numberOfpages: action.numberOfpages
        };
      case actions.HOLLENCODE_QUESTIONS_SUCCESS:
        return {
          ...state,
          loading: true,
          questions: action.data
        };
      case actions.CAREER_SUGGETION_SUCCESS:
        return {
          ...state,
          loading: true,
          careerSuggetions: action.data
        };
      case actions.SUCCESS_WHATSAPP_SUPPORT_USERS:
        return {
          ...state,
          loading: true,
          supportUsers: action.data
        };
      case actions.SUCCESS_WEBSOCKET:   
        return {
          ...state,
          loading: true,
          webSocketData: action.data          
        };
      default:
        return state;
    }
}
export default reducer