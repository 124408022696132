import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import App from "../src/App";
import axios from '../src/axios';

class ErrorPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            returnCom : null
        };
    }

    render(){
        return(
            <div>
                {/* <App/> */}
                Error page
            </div>
        )  
    }
}

export default ErrorPage