import React, { Component, isValidElement } from "react";
import classes from "./Onboard.module.scss";
import "./Onboard.scss";
import SpinnerGrey from "../UI/SpinnerGrey/SpinnerGrey";
import { Dimmer, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import * as actionsCreator from "../../../src/store/actions/index";
import moment from "moment";
import axios from "../../axios";
import Document from "../../assets/image/paper.svg";
import swal from "sweetalert2";
import Popup from "reactjs-popup";
import FilteredDataOnboard from "./cvFilteredData/FilteredDataOnboard/FilteredDataOnboard";
import {Modal} from "semantic-ui-react";
import ProgressBar from "@ramonak/react-progress-bar";

class CvUploadPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      imageString: null,
      flagData: this.props.flagData,
      isValidFile: "",
      fileUploadSpinner: false,
      linkedinUploaded: false,
      fileName: "",
      isManuallyFilled: false,
      isPDFModalOpen: false,
      cvFile: "",
      percentage: 0
    };
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  manuallyFilled = () => {
    this.setState({
      isManuallyFilled: true,
    });
  };

  btnSaveProceed = (e) => {
    e.preventDefault();
    this.props.onDataSave(this.state.imageString);
    this.props.onSkip();
    //this.props.onSavedSteps(this.props.stepNo)
  };

  btnSkipForNow = (e) => {
    e.preventDefault();
    localStorage.setItem("candidateCV", "");
    this.props.onSkip();
    //this.props.onSkipSteps(this.props.stepNo)
  };

  handleFile_dashboard_original = (e) => {
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      this.setState({
        fileUploadSpinner: true,
      });
      var file = e.target.files[0];
      var extensions = e.target.files[0].name.split(".").pop();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("candidateCV", preview);
        this.cvFiltering_dashboard(preview, 1, extensions);
      };
      this.setState({
        imageString: localStorage.getItem("candidateCV"),
      });
      // this.props.onDataSave(this.state.imageString);
      // this.setState({ isValidFile: "" });
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });
      swal.fire({
        position: "center",
        // icon: "warning",
        imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
        title: "Warning!",
        text: "Only PDF/DOC/DOCX formats allowed",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  handleFile_dashboard_linkedIn = (e) => {
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      this.setState({
        fileUploadSpinner: true,
      });
      var file = e.target.files[0];
      var extensions = e.target.files[0].name.split(".").pop();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("candidateCV", preview);
        this.cvFiltering_dashboard(preview, 1, extensions);
      };
      this.setState({
        imageString: localStorage.getItem("candidateCV"),
      });
      // this.props.onDataSave(this.state.imageString);
      // this.setState({ isValidFile: "" });
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });
      swal.fire({
        position: "center",
        // icon: "warning",
        imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
        title: "Warning!",
        text: "Only PDF/DOC/DOCX formats allowed",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  cvFiltering_dashboard = (fileString, value, extensions) => {
    const token = localStorage.getItem("chat-token");
    var id = sessionStorage.getItem("CandidateProfile_id02");
    var data = {
      uploaded_at: moment().toISOString(),
      upload: fileString,
      user_id: parseInt(id),
      cv_type: value,
      format_type: extensions,
    };
    axios
      .post("cv/", data, {
        onUploadProgress: (pregresEvent) => {
          const {loaded, total} =  pregresEvent
                    let percent = Math.floor(loaded* 100/ total)
                    console.log(percent, "Percentage")
                    this.setState({
                        percentage : percent
            })
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        this.closeHandler();
        this.props.getSystemLevelData();
      })
      .catch((err) => {});
  };

  closeHandler = () => {
    var flag = false;
    this.props.onUploadCV(flag);
  };

  closeHandler_skip = () => {
    var flag = false;
    this.props.onUploadCV(flag);
  };

  handleFileChange_original = (e) => {
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      this.setState({
        fileUploadSpinner: true,
      });
      var file = e.target.files[0];
      var extensions = e.target.files[0].name.split(".").pop();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("candidateCV", preview);
        this.cvFiltering(preview, 1, extensions);
      };
      this.setState({
        imageString: localStorage.getItem("candidateCV"),
      });
      this.props.onDataSave(this.state.imageString);
      this.setState({ isValidFile: "" });
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });
      swal.fire({
        position: "center",
        // icon: "warning",
        imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
        title: "Warning!",
        text: "Only PDF/DOC/DOCX formats allowed",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  checker = (object, array) => {
    return array.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      object
    );
  };

  handleFileChange = (e) => {
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      this.setState({
        fileUploadSpinner: true,
      });
      var file = e.target.files[0];
      this.setState({
        fileName: e.target.files[0].name,
      });
      var extensions = e.target.files[0].name.split(".").pop();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("candidateCV", preview);
        this.cvFiltering(preview, 0, extensions);
      };
      this.setState({
        imageString: localStorage.getItem("candidateCV"),
      });
      this.props.onDataSave(this.state.imageString);
      this.setState({ isValidFile: "" });
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });
      swal.fire({
        position: "center",
        // icon: "warning",
        imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
        title: "Warning!",
        text: "Only PDF/DOC/DOCX formats allowed",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  cvFiltering = (fileString, value, extensions) => {
    this.setState({percentage: 0});
    const token = localStorage.getItem("chat-token");
    var id = sessionStorage.getItem("CandidateProfile_id02");
    var data = {
      uploaded_at: moment().toISOString(),
      upload: fileString,
      user_id: parseInt(id),
      cv_type: 1,
      format_type: extensions,
    };

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(percent, "Percentage function 123");
        this.setState({
          percentage: percent
        });
      },
    };
    axios
      .post("cv/", data, {
        onUploadProgress: (pregresEvent) => {
          const { loaded, total } = pregresEvent
          let percent = Math.floor(loaded * 100 / total)
          console.log(percent, "Percentage")
          this.setState({
            percentage: percent
          })
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        this.setState({ cvFile: res.data.upload });
        axios
          .get("upload-cv-extract/?candidate_user_id=" + id, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((res) => {
            this.props.onCvFiltering(res.data);
            // this.props.onSkip();
            this.setState({ linkedinUploaded: true, fileUploadSpinner: false });
            this.props.getSystemLevelData();
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  viewCv = () => {
    this.setState({ isPDFModalOpen: true });
  };

  render() {
    let text_one = "";
    let text_two = "";
    let defaultUpload = "";
    let defaultUpload_original = "";
    let defaultSkip = "";

    if (this.props.dashboardCV === true) {     
      text_one = "";
      text_two = "";
      defaultUpload = this.handleFile_dashboard_linkedIn;
      defaultUpload_original = this.handleFile_dashboard_original;
      defaultSkip = this.closeHandler_skip;
    } else {   
      text_one = "You are";
      text_two = "  almost there";
      defaultUpload = this.handleFileChange;
      defaultUpload_original = this.handleFileChange_original;
      defaultSkip = this.btnSkipForNow;
    }
    var cv = "";
    var cvFileType;
    try {
      cv = this.props.candidateDate.userData.cv[0].upload;
      cvFileType = cv.substr(cv.length - 3, 3);
    } catch {
      cv = "";
    }
    const improvingText = (
      <div className={classes.improvingText}>
        <div>
          We are improving our algorithm to extract your info based on your
          above upload accurately.
        </div>
        <div>You may edit and confirm your profile sections below.</div>
      </div>
    );
    const { fileUploadSpinner, linkedinUploaded } = this.state;
    return (
      <div className={classes.cvStepWrapper}>
        <div
          className={`${classes.cvStep} ${
            this.props.dashboardCV && classes.fillAvailable
          }`}
        >
          {!this.props.dashboardCV && (
            <div className={classes.topRowWrapper}>
              <h3>
                {text_one}
                <span className={classes.orange}>{text_two}</span>
              </h3>
            </div>
          )}
          <div className={classes.sectionWrap}>
            <div
              className={`${classes.section} ${
                !this.props.dashboardCV && classes.sectionDash
              }`}
            >
              {!linkedinUploaded && (
                <div className={classes.topInputArea}>
                  <div className={classes.tipText1CV}>
                    Hundreds of companies are waiting for the right candidate's
                    CV
                  </div>
                  {this.state.isUploading ? (
                    <div className={classes.tipText2}>
                      Your CV is{" "}
                      <span className={classes.blue}>getting uploaded</span>
                    </div>
                  ) : (
                    <div className={classes.tipText2cv}>
                      Why not <span className={classes.blue}>upload&nbsp;</span>
                      your CV?
                    </div>
                  )}
                  <input
                    type="file"
                    name="Phone"
                    onChange={this.handleInputChange}
                  ></input>
                </div>
              )}
              {this.state.isUploading ? (
                <div className={classes.spinnerWrapper}>
                  <SpinnerGrey />
                </div>
              ) : (
                ""
              )}
              <div className={classes.flexbetween}>
                {linkedinUploaded ? (
                  <div className={classes.pdfNameImage}>
                    <img src={Document} />
                    <span className={classes.name}>{this.state.fileName}</span>
                  </div>
                ) : (
                  <div />
                )}
                <div className={`${classes.buttonWrap} dimmer-in-pdf-upload`}>            
                  {/* <Dimmer active={fileUploadSpinner}>
                    <Loader size="small">Uploading File</Loader>
                  </Dimmer> */}
                  {linkedinUploaded && (
                    <button
                      onClick={this.viewCv}
                      className={classes.viewButton}
                    >
                      View 
                    </button>
                  )}              
                  <Popup
                    className="linkedinTip"
                    trigger={
                      <input
                        type="file"
                        name="uploadedCV"
                        id="uploadedCV"
                        title="Upload your CV"
                        className={classes.inputFile1}
                        onChange={defaultUpload}
                      ></input>
                    }
                    position="left center"
                    on="hover"
                  >     
                
                    <div>
                      <div className="topdiv">
                        <div className="section1">
                          In order to <span>download</span> your LinkedIn
                          profile
                        </div>
                        <div className="section2">
                          <ul>
                            <li>Login to your LinkedIn in a seperate tab</li>
                            <li>Click on the "More" button</li>
                            <li>
                              Click "Save to PDF" and download your profile
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="bottomDiv">
                        Now you are ready to upload your profile to Sia
                      </div>
                    </div>
                  </Popup> 

         
                  {/* <input
                    type="file"
                    name="uploadedCV"
                    id="uploadedCV"
                    title="Upload your CV"
                    className={classes.inputFile2}
                    onChange={defaultUpload_original}
                  ></input> */}
                  {/* <button
                    style={{ marginTop: "20px" }}
                    onClick={defaultSkip}s
                    className={classes.skipButton}
                  >
                    I'll upload later
                  </button> */}
                </div>      
                   
              </div>
              <div style={{marginTop:"10px"}}>
              <ProgressBar bgColor="#1697FA" completed={this.state.percentage} />  
              </div>
          

              {this.state.isValidFile && (
                <div className={classes.fileformatError}>
                  {this.state.isValidFile}
                </div>
              )}
              {linkedinUploaded && improvingText}
              <FilteredDataOnboard
                cvUploaded={linkedinUploaded}
                onskip={this.props.onSkip}
                stepNo={this.props.stepNo}
                defaultSkip={defaultSkip}
                handleManuallyFilled={this.manuallyFilled}
                isManuallyFilled={this.state.isManuallyFilled}
              />
            </div>
          </div>
        </div>
        <Modal
          open={this.state.isPDFModalOpen}
          size="small"
          className="noBootstrapModal"
          onClose={this.closeModal}
        >
          <div className={classes.insideViewModal}>
            <div className={classes.titlebar}>
              <h3>CV view</h3>
              <button
                onClick={() =>
                  this.setState({
                    isPDFModalOpen: false,
                  })
                }
                className={classes.closeX}
              >
                <i className="fas fa-times" />
              </button>
            </div>
            <embed
              src={this.state.cvFile}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardCV: state.profile.cvDashboard,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUploadCV: (data) => dispatch(actionsCreator.UploadCV(data)),
    onCvFiltering: (data) => dispatch(actionsCreator.cvFiltering(data)),
    getSystemLevelData: () => dispatch(actionsCreator.getSystemLevelData()),
  };
};

//export default CvUploadPanel;
export default connect(mapStateToProps, mapDispatchToProps)(CvUploadPanel);
