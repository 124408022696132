import React, { Component } from "react";
import { connect } from "react-redux";
import Questions from "../ChatVideo/Questions/Questions";
import videoCamera from "../../../../assets/image/video-camera.svg";
import Video from "./Video/Video";
import VideoPitch from "../../VideoPitch/VideoPitch";
import classNames from "./ChatVideo.module.scss";
import * as actions from "../../../../store/actions/index";

class ChatVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      firstName: localStorage.getItem("chat-username"),
      openVideo: false,
    };
  }

  handleExit = () => {
    console.log("handle close exit")
    var exitVideo = true;
    //this.props.onGetChatResponseNew("/exit_video_pitch", false)
    this.props.OnexitVideoPitch(exitVideo);
    this.props.closeModal();
  };

  handleOnClick = () => {
    console.log("Handle click");
    this.setState({
      openVideo: true,
    });
  };

  render() {
    console.log(this.props.videoQuestions, "video questions");
    let userName = this.state.firstName.split(" ")[0];
    let question = "";
    let component = (
      <div className={classNames.VPwrap}>
        <div className={classNames.innerVP}>
          <div className={classNames.left}>
            <img src={videoCamera} />
          </div>
          <div className={classNames.right}>
            <div className={classNames.addressing}>
              <span>Hi</span>
              {/* <span className={classNames.yellow}>Adreon,</span> */}
              <span className={classNames.yellow}>{userName}</span>
            </div>
            {/* <div className={classNames.addressing}>Hi {userName}</div> */}
            <div className={classNames.description}>
              <div>
                A set of questions will be displayed on the screen and your
                response will be recorded. Hit "Start" when you're set.
              </div>
              {/* <div>
                You can abort/retake anytime. Successful submission will proceed
                you to the next step.
              </div> */}
              {/* <div>
                Hit "Start" when you're set to go. You may click "Exit" if you
                want to quit.
              </div> */}
              <div>
                <span>Disclaimer: </span>Please note that we require your{" "}
                <span>Microphone</span> and&nbsp;
                <span>Camera</span> access in order to perform the video pitch.
                You may click "Exit" to decline and go back to the chat.
              </div>
            </div>
            <button
              className={classNames.startButton}
              onClick={this.handleOnClick}
            >
              <span>Start</span>
            </button>
            <button onClick={this.handleExit} className={classNames.exitButton}>
              Exit
            </button>
          </div>
        </div>
      </div>
    );

    if (this.state.openVideo === true) {
      if (this.props.videoQuestions !== null) {
        question = this.props.videoQuestions.map((res, index) => {
          return <Questions data={res} />;
        });
      } else {
        // component = <Video closeModal={this.props.closeModal} />;
        component = <VideoPitch closeModal={this.props.closeModal} />;
      }
    }

    return (
      <div>
        {component}
        <div>{question}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    videoQuestions: state.chatnew.videoQuestions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    OnexitVideoPitch: (data) => dispatch(actions.exitVideoPitch(data)),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actions.getChatResponseNew(message, status, notification)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatVideo);
