import React, { Component } from "react";
import { Route, withRouter,Link } from "react-router-dom";
import { connect } from "react-redux";
import NearbyData from "./NearbyData/Data";
import classes from "./NearbyCampaigns.module.scss";
import NearbyCarousel from "../NearbyCampaigns/NearbyCarousel";
import * as actions from "../../../store/actions/index";
import sia from "../../../assets/image/sia.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Campaigns from "../../../components/CandidateDashboard/CampaignData/CampaignData";
import axios from "../../../axios";

class Nearby extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isAPICall : false,
        campaign : []
    };
  }
  componentDidMount() {
    var id = localStorage.getItem("CandidateProfile_id02");
    var eventId = localStorage.getItem("eventId")
    setInterval(() => this.getStallCampaigns(), 1000)
  }

  getStallCampaigns = ()=>{

    console.log(this.props.stallData, "Stall data nar by")
    
    if(this.props.stallData !== null){

      if (this.props.stallData.length > 0){

        var token = localStorage.getItem("chat-token");
        var id = localStorage.getItem("CandidateProfile_id02");
        var eventId = localStorage.getItem("eventId")
        var stallId = this.props.stallData[0]["id"]
        console.log(this.state.isAPICall, "is apicall####")
        if (this.state.isAPICall === false){
            axios
            .get("get-available-campaigns-by-stall/?candidateId="+id+"&eventId="+eventId+"&stallId="+stallId, {
                headers: {
                Authorization: `token ${token}`,
                "Content-Type": "application/json"
                }
            })
            .then(res => {
                console.log(res.data.Data, "Result data #######")
                this.setState({
                    isAPICall: true,
                    campaign: res.data.Data
                })
            });
        }

      }
    }
    else{
      console.log("abc")
    }
  }

  render() {
    var campaign = [];
    // if (this.props.compaign != null) {
    //   campaign = this.props.compaign.Data;
    // }
    console.log(this.state.campaign)
    if (this.state.campaign != []) {
        campaign = this.state.campaign;
    }
    var applyCampaignsshowStatus = 0

    if (this.props.tabStatus === 1){
        applyCampaignsshowStatus = 1
    }

    if (this.props.tabStatus === 2){
        applyCampaignsshowStatus = 2
    }

    console.log(this.props.candidateDate, "user data stall")

    return (
      <div className={classes.dashboardNearby}>
        {/* <div className={classes.title}>Campaigns near you</div> */}
      
        <Tabs
          className={classes.tabs}
          selectedIndex={this.state.tabIndex}
          // onSelect={(tabIndex) => this.tabNavigate(tabIndex)}
        >
          <div className={classes.menubarWrapper}>
            <div className={classes.menubar}>         
              <TabList className={classes.tabList}>
                {
                    applyCampaignsshowStatus == 1?
                    <Tab disabled={this.state.tabIndex === 0}>     
                        <div style={{borderBottom:"2px solid #ffffff"}}>Campaigns</div>           
                    </Tab>:null
                }  
                {
                  applyCampaignsshowStatus == 2?
                  <Tab disabled={this.state.tabIndex === 1}>
                    <div style={{borderBottom:"2px solid #ffffff"}}>Applied</div>       
                  </Tab>:null
                }     

                {/* <Tab disabled={this.state.tabIndex === 1}>
                  <div style={{borderBottom:"2px solid #ffffff"}}>Applied</div>       
                </Tab> */}

                <Tab disabled={this.state.tabIndex === 2}>    
                  {/* <div>Shortlisted</div>       */}
                </Tab>
              </TabList>            
            </div>         
          </div>
          {
              applyCampaignsshowStatus === 1?
              <TabPanel>
              <>
                  {campaign.length > 0 ?
              <div className={classes.slider_content}>
                  <NearbyCarousel key={0} compaign={campaign} />
              </div>
              : <div className={classes.instructionWrapper}>
              <div className={classes.instructions}>
                  <div className={classes.heading}>
                  Welcome to <span>Ufairs</span>:
                  </div>
                  <div className={classes.descr}>
                  {/* Click the <img src={sia} /> icon and chat with <b>Sia</b>  to */}
                  You don't have any campaign right now
                  </div>
              </div>
              </div>}
              </>
            </TabPanel>:
            null
          }
          {
            applyCampaignsshowStatus === 2?
            <TabPanel>
            <div style={{marginLeft:"30px",marginTop:"30px"}}>
            {/* <Campaigns data={this.props.candidateDate} /> */}
            <Campaigns data={this.props.candidateDate} />
            </div>
            </TabPanel>:null
          }
          
          {/* <TabPanel>
          <div style={{marginLeft:"30px",marginTop:"30px"}}>
          
          <Campaigns data={this.props.candidateDate} />
          </div>
          </TabPanel> */}

          {/* <TabPanel></TabPanel> */}
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    compaign: state.CampaignByRef.availableCampaign,
    profile: state.profile.userProfile,
    event:state.auth.eventData,
    candidateDate: state.CandidateData.userDataStall,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAvailableCampaignType: (data,id) =>
      dispatch(actions.getAvailableCampaignSlider(data,id)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nearby));
