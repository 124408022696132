import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "./CoverLetter.module.scss";
import * as actionsCreator from "../../../../store/actions/index";
import resumeIcon from "../../../../assets/image/resume.svg";
import Swal from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";

class CoverLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false,
      openWriteMode: false,
      text: "",
      firstName: localStorage.getItem("chat-username"),
    };
    this.validator = new SimpleReactValidator();
  }

  handleSubmit = (e) => {
    var _validFileExtensions = ["application/pdf",
     "application/msword",
     "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      var file = e.target.files[0];
      var extensions= e.target.files[0].name.split('.').pop()
      localStorage.setItem("coverLetterName", file.name);
      var reader = new FileReader();
      reader.readAsDataURL(file);
      var b64String = "";
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("coverLetter", preview);
        this.updateCoverLetter(preview,extensions);
      };
    } else {
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Plese upload pdf or doc",
        showConfirmButton: false,
        timer: 2500,
        customClass: { container: "swal-success-settings" },
        imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        imageHeight: 20,
      });
    }
  };

  updateCoverLetter = (fileString,extensions) => {
    var id_campaign = "";
    if (this.props.appliedSaveData === null) {
      id_campaign = localStorage.getItem("Campaign_ID");
    } else if (this.props.appliedSaveData !== null) {
      id_campaign = this.props.appliedSaveData.campaignId;
    }

    var candidateID = this.props.candidateDate.userData.profile[0].user_id;
    var dataProgress = true;
    var dataLetter = {
      candidate_id: candidateID,
      coverLetter: "",
      coverLetter_url: fileString,
      campaign_id: parseInt(id_campaign),
      extention:extensions
    };
    this.props.onCVLProgress(dataProgress);
    this.props.onUploadLetter(
      dataLetter,
      this.props.appliedSaveData,
      id_campaign
    );
  };

  closeModal = () => {
    var data = null;
    this.props.onResetState(data);
    this.props.onGetChatResponseNew("/Submit_cover_letter", false);
    var status = "coverLetter";
    if (localStorage.getItem("NO_VIDEO") === "true") {
      status = "COMPLETED";
      localStorage.removeItem("NO_VIDEO");
    } else {
      status = "coverLetter";
    }
    this.props.onUpdateApplyState(this.props.appliedSaveData, status);
    localStorage.setItem("OpenCoverLtrModal", false);
  };

  openWriteMode = () => {
    this.setState({
      openWriteMode: true,
    });
  };

  handleTextArea = (e) => {
    console.log(e.target.value, "Input value text area");
    this.setState({
      text: e.target.value,
    });
  };

  handleSubmitLetter = () => {
    if (this.validator.allValid()) { 
    var id_campaign = "";
    if (this.props.appliedSaveData === null) {
      id_campaign = localStorage.getItem("Campaign_ID");
    } else if (this.props.appliedSaveData !== null) {
      id_campaign = this.props.appliedSaveData.campaignId;
    }

    var candidateID = this.props.candidateDate.userData.profile[0].user_id;
    var dataProgress = true;
    var campaign_id = id_campaign;
    var dataLetter = {
      candidate_id: candidateID,
      coverLetter: this.state.text,
      coverLetter_url: "",
      campaign_id: parseInt(campaign_id),
    };

    this.props.onCVLProgress(dataProgress);
    this.props.onUploadLetter(
      dataLetter,
      this.props.appliedSaveData,
      id_campaign
    );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  routeBack = () => {
    this.setState({
      openWriteMode: false,
    });
  };

  handleCloseCoverLetter = () => {
    console.log("Close modal cover letter");
    this.props.onGetChatResponseNew("Skip_cover_letter", false);
    localStorage.setItem("OpenCoverLtrModal", false);
  };

  render() {
    let userName = this.state.firstName.split(" ")[0];
    console.log(this.props.coverLetterStatus, "Openwrite mode");
    let showData = "";

    if (
      this.props.coverLetterStatus !== null &&
      this.state.openWriteMode === false
    ) {
      this.closeModal();
    }

    // if (
    //   this.props.coverLetterStatus !== null &&
    //   this.state.openWriteMode === false
    // ) {
    //   console.log("if condition 1");
    //   showData = (
    //     <div className={classNames.CLwrap}>
    //       <div className={classNames.innerCLUploaded}>
    //         <div>Cover letter successfully uploaded.</div>
    //         <button className={classNames.closeModal} onClick={this.closeModal}>
    //           <span>Close</span>
    //         </button>
    //       </div>
    //     </div>
    //   );
    // }
    else if (
      this.props.coverLetterStatus === null &&
      this.state.openWriteMode === false
    ) {
      // showData = (
      //   <>
      //     <div>
      //       <input
      //         type="file"
      //         name="uploadedCV"
      //         id="uploadedCV"
      //         onChange={this.handleSubmit}
      //       ></input>
      //       <label>Upload</label>
      //     </div>
      //     <div>
      //       <button onClick={this.openWriteMode}>Write</button>
      //     </div>
      //   </>
      // );
      showData = (
        <div className={classNames.textWriteWrapper}>
        <button
            className={classNames.absoluteClose}
            onClick={this.handleCloseCoverLetter}
          >
            <i className="fas fa-times" />
          </button>
          <div className={classNames.textWriteInner}>
            <div className={classNames.textGuide}>
              Type in your cover letter below.
            </div>
            <textarea
              autofocus
              rows="10"
              onChange={this.handleTextArea}
            ></textarea>
             <span>
                {this.validator.message(
                  "Cover letter",
                  this.state.text,
                  "required"
                )}
              </span>
            {/* <button onClick={this.routeBack}>Back</button> */}
            <button onClick={this.handleSubmitLetter}>Submit</button>
          </div>
        </div>
        // <div className={classNames.CLwrap}>
        //   <div className={classNames.innerCL}>
        //     <button
        //       className={classNames.absoluteClose}
        //       onClick={this.handleCloseCoverLetter}
        //     >
        //       <i className="fas fa-times" />
        //     </button>
        //     <div className={classNames.left}>
        //       <img src={resumeIcon} />
        //     </div>
        //     <div className={classNames.right}>
        //       <div className={classNames.addressing}>
        //         <span>Hi</span>
        //         <span className={classNames.yellow}>{userName}</span>
        //       </div>
        //       {/* <div className={classNames.addressing}>Hi {userName}</div> */}
        //       <div className={classNames.description}>
        //         <div>Now let's submit a cover letter for the campaign.</div>
        //         <div>
        //           You can either write a cover letter now or you can upload an
        //           existing pdf/doc file.
        //         </div>
        //         <div>Please choose your option below.</div>
        //       </div>
        //       <div style={{ display: "flex" }}>
        //         <div className={classNames.writeCL}>
        //           <button onClick={this.openWriteMode}>Write</button>
        //         </div>
        //         <div className={classNames.uploadCL}>
        //           <input
        //             type="file"
        //             name="uploadedCV"
        //             id="uploadedCV"
        //             onChange={this.handleSubmit}
        //           ></input>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      );
    } else if (this.state.openWriteMode === true) {
      console.log("if condition 3");
      showData = (
        <>
          <div className={classNames.textWriteWrapper}>
          <button
              className={classNames.absoluteClose}
              onClick={this.handleCloseCoverLetter}
            >
              <i className="fas fa-times" />
            </button>
            <div className={classNames.textWriteInner}>
              <div className={classNames.textGuide}>
                Type in your cover letter below.
              </div>
              <textarea
                autofocus
                rows="10"
                onChange={this.handleTextArea}
              ></textarea>
              {/* <button onClick={this.routeBack}>Back</button> */}             
              <button onClick={this.handleSubmitLetter}>Submit</button>
            </div>
          </div>
        </>
      );
    }

    return <div>{showData}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    candidateDate: state.CandidateData.userData,
    coverLetterStatus: state.chatnew.uploadCoverLetter,
    appliedSaveData: state.chatnew.appliedCampaignTemp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onCVLProgress: (data) => dispatch(actionsCreator.CVLProgress(data)),
    onUploadLetter: (data, appliedData, campaignId) =>
      dispatch(actionsCreator.UploadLetter(data, appliedData, campaignId)),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
    onResetState: (data) => dispatch(actionsCreator.ResetState(data)),
    onUpdateApplyState: (data, status) =>
      dispatch(actionsCreator.updateApplySaveData(data, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoverLetter);
