import React from "react";
import classes from "./Question.module.scss";
import "./Question.scss";
import { connect } from "react-redux";
import * as actionCreater from "../../../store/actions/index";
import SpinnerGrey from "../../UI/SpinnerGrey/SpinnerGrey";
import {
  CSSTransition,
  Transition,
  TransitionGroup,
} from "react-transition-group";
import opinion from "../../../assets/image/opinion.png";

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }
  questionAnswer = (val) => {
    var questionCount = this.state.count++;
    if (questionCount == 53) {
      var data = [];
      data.push({ questoin: val });
      var id = this.props.userProfile.id;
      this.props.onGetCareerSuggetions(data, id);
    }

    this.props.handleNext();
  };

  render() {
    var questionText = "";
    var buttonYes = "";
    var buttonNo = "";
    var buttonSome = "";
    try {
      questionText = this.props.question.text;
      buttonYes = this.props.question.answer1;
      buttonNo = this.props.question.answer2;
      buttonSome = this.props.question.answer3;
    } catch {
      questionText = "";
      buttonYes = "";
      buttonNo = "";
      buttonSome = "";
    }
    return (
      <div className={classes.questionWrapper}>
        <div className={classes.questionInner}>
          {!questionText && <SpinnerGrey />}
          {/* <img src={opinion} /> */}
          <div
            className={`${classes.questionAnswerInner} profiling-modal-text`}
          >
            <TransitionGroup>
              <CSSTransition
                key={questionText}
                timeout={{ enter: 300, exit: 300 }}
                classNames="decr"
              >
                <div className={classes.imageAndText}>
                  <img src={opinion} />
                  <h1>{questionText}</h1>
                </div>
              </CSSTransition>
            </TransitionGroup>
            <div className={classes.buttonsWrap}>
              <button onClick={() => this.questionAnswer("yes")}>
                {buttonYes}
              </button>
              <button onClick={() => this.questionAnswer("no")}>
                {buttonNo}
              </button>
              <button onClick={() => this.questionAnswer("sometimes")}>
                {buttonSome}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userProfile: state.profile.userProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(actionCreater.getChatResponseNew(message, status, notification)),
    onGetCareerSuggetions: (data, id) =>
      dispatch(actionCreater.getCareerSuggetions(data, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);
