import React, { Component } from "react";
import SendInput from "../UI/SendInput/SendInput";
import classes from "./SupportChat.module.scss";
import { connect } from "react-redux";
import axios from "../../../src/axios";
import IconSia from "../../assets/image/whatsapp.png";
import moment from "moment";
import * as actions from '../../../src/store/actions/index'

class SupportMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("chat-token"),
            connectUser: false,
            userName: "",
            connectionRef : null 
        };
    }
    componentDidMount = () => {

    };

    onStartConnection = (id) => {
        var data = {
            candidateId: this.props.profile.id,
            supportId: id
        }
        axios
            .post("support-candidate-start-connection/", data, {
                headers: {
                    Authorization: `Token ${this.state.token}`,
                },
            }
            )
            .then((res) => {
                this.setState({ connectUser: true, userName: res.data.supportName, connectionRef :res.data.ref })
                this.props.setSupportRef(res.data.ref, res.data.supportId)
            });
    }

    render() {
        var users = []
        if (this.props.supportUsers != null) {
            users = this.props.supportUsers.Data
        }
        return (
            <>
                <div className={classes.welcomeBackMessage}>
                     <div className={classes.ProfileData}>
                        <strong className={classes.Name}>                                            {}
                            <img src={IconSia} />
                        </strong>
                    </div>

                     {users.length > 0 ?
                    <div className={classes.wtext}>
                     Hi ! You can contact our technical staff using this 'live chat' option.
                     Select a staff member to continue
                     </div>
                     :
                     <div className={classes.wtext}>
                      Hi ! All our technical staff are busy right now. Please send an email to <b>support@siabot.co.uk</b> and they will respond shortly.
                     </div>
                     }
           
                    <div className={classes.buttonComponent}>
                        {users.map((res, index) => {
                            return (
                                <button key={index}
                                    className={classes.yesNoButton}
                                    onClick={(val) => this.onStartConnection(res.id)}
                                >
                                    {res.userName}
                                </button>
                            )
                        })
                        }
                    </div>
                    {this.state.connectUser ?
                        <>
                           <div className={classes.ProfileData}>
                                <strong className={classes.Name}>
                                    <img src={IconSia} />
                                </strong>
                            </div>
                            <div className={classes.wtext}>                              
                                Please type your message for {this.state.userName} and he will reply promptly.
                        </div>
                        </>
                        : null
                    }
                    {this.props.chatMeassage.map((res, index) => {
                        return (
                            res.message != "" && res.ref === this.state.connectionRef ?
                                <>
                                    <div className={classes.ProfileData}>
                                        {res.msgType == "REPLY" ?
                                            <strong className={classes.Name}>
                                                <img src={IconSia} />
                                            </strong> : null
                                        }
                                    </div>
                                    <div key={index} className={res.msgType == "REPLY" ? classes.wtext : classes.Message}>
                                        {res.message}
                                    </div>
                                </>
                                : null
                        )
                    })
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile.userProfile,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        OnResetConnectionWithGoBack : (supId)=>dispatch(actions.ResetConnectionWithGoBack(supId))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportMessage);
