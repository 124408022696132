import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import classes from "../AllEvents.module.scss";
import atendee2 from "../../../../assets/image/imagetestatend1.png";
import { Button, Card, Image, Icon } from "semantic-ui-react";

class Event extends Component {

    constructor(props) {
        super(props)
        this.state = {
            attendes: null
        }
    }

    navigateToLink(param){
        //window.location.href = "/"+param;
        var url = process.env.REACT_APP_DOMAIN_PREFIX + param +  process.env.REACT_APP_DOMAIN_BASE
        window.location.replace(url, "__blanc")
    }

    render() {
        console.log(this.props.data, "attendees individual")
        var subDomain =  null
        var companyName = null
        var companyImage = null
        
        if (this.props.data !== null){
            subDomain = this.props.data.subDomain.subDomain
            companyName = this.props.data.company[0]["name"]
            companyImage = this.props.data.company[0]["companyLogo"]
        }

        return (
            <div >
            <Card className={classes.sizeC} onClick={()=>this.navigateToLink(subDomain)}>
              {/* <Image src={this.props.data.event[0].picture_url==""?atendee2:this.props.data.event[0].picture_url} className={classes.atendOne} wrapped ui={true} /> */}
              <Card.Content className={classes.cusContent}>
                <Card.Header className={classes.ComTitle}>{companyName + " "}</Card.Header>
                 </Card.Content>   
            </Card>
            </div>

            // <div className={classes.atendOne}>
            //     <div className={classes.imgGrid}>
            //         <img src={atendee2} className={classes.imgPersonal} />
            //     </div>
            //     <div className={classes.content}>
            //         <div className={classes.LabelsFull}>
            //             <label>{this.props.data.profile[0].first_name+" "+this.props.data.profile[0].last_name}</label>
            //             <label className={classes.LabelsEadd}>{this.props.data.candidate[0].email}</label>
            //         </div>
            //         <div className={classes.footerContent}>
            //             <label className={classes.footerContent1}><i class="fa fa-phone-square" aria-hidden="true"></i></label>
            //             <label className={classes.footerContent2}>{this.props.data.profile[0].phone_number}</label>
            //         </div>
            //     </div>
            // </div>
        )
    }
}

export default (Event)