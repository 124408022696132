import React, { useState,useEffect } from "react";
import Popup200px from "../../UI/Popups/Popup200px/Popup200px";
import classes from "./ChatTabs.module.scss";
import { Icon } from "semantic-ui-react";
import Chat from "../Chat/Chat";
import ChatInterview from "../../ChatTextNew/ChatInterview/ChatInterview";
import { connect } from "react-redux";

function ChatTabs(props) {
  const [groupChat, setGroupChat] = useState(true);
  const [interviewChat, setInterviewChat] = useState(false);
  const [intwChatNotification, setIntwChatNotification] = useState(false);
  const [groupChatNotification, setgroupChatNotification] = useState(false);

  const handleTabGroup = () => {
    setGroupChat(true);
    setInterviewChat(false);
    props.groupNotification()
   
  };
  const handleTabInterview = () => {
    setInterviewChat(true);
    setGroupChat(false);
    props.interviewNotification() 
  
  };

  const interviewChatNotfy=()=>{ 
    //setIntwChatNotification(true)
  } 

  let componentItem = null;
  let interviewComponent = null;
  let label = "";
  var flagStatus = props.flagStatus;
  let newMessage = "";

  newMessage = props.newMessage;


  if (flagStatus == 0) {
    if (localStorage.getItem("quickInvite") !== null) {   
      if (props.intwData.recruiteStatus === true){
        interviewComponent = (
          <ChatInterview notiy={interviewChatNotfy} intData={props.intData} appliedId={props.appliedId} intwData={props.intwData} />
      );
        label = "Interview chat";
      }
    } else if (localStorage.getItem("quickInvite") === null) {
      interviewComponent = null;
      label = "No interviews";
    }
  } else if (flagStatus == 1) {
     // console.log(props.recruiterStatus.recruiteStatus, "Recruiter status")
     // if (props.intwData.recruiteStatus === true){
       if(props.interviewData.interviewChat===true){
        interviewComponent = (
          <ChatInterview notiy={interviewChatNotfy} intData={props.intData} appliedId={props.appliedId} intwData={props.intwData} />
        );
        componentItem = interviewComponent
        //interviewComponent = null;
        label = "Interview chat";     
       }else{       
         interviewComponent = null
       }
     // }
  }

  console.log(props.interviewData.groupChat, "group chat #################")
  console.log(props.interviewData.interviewChat, "Interviewer chat #################")

  if (groupChat) {
    if (props.interviewData.groupChat === true) {
    if(flagStatus == 0){
      componentItem = <Chat intData={props.intData} intwData={props.intwData} />;
    }else{
      componentItem = interviewComponent   
    }
   }
  }

  if (interviewChat) {
    if (props.interviewData.interviewChat === true) {     
      // componentItem = interviewComponent;
      componentItem = <ChatInterview notiy={interviewChatNotfy} intData={props.intData} appliedId={props.appliedId} intwData={props.intwData} />
    }
  }

  const NoInterview = (
    <div className={classes.noInterviewWrapper}>
      <div className={classes.row1}>No messages to show..</div>
      <div className={classes.row2}>
        {/* This chat will be activated once your interview starts. */}
        Chat feature is not available. Please enjoy the webinar.
      </div>
    </div>
  );

  return (
    <div className={classes.ChatTabs}>
    {props.is_interview == true ?
    <div style={{height:'100%'}}>
      <div className={classes.TabHeade}>
        {props.interviewData.groupChat === true && flagStatus===0 ? (
          <span
            onClick={handleTabGroup}
            className={groupChat ? classes.Active : null}
          >
            Group Chat
            {props.groupChatNotification ? (
              <Icon
                name="circle"
                className={classes.messageArrived}
                size="mini"
              />
            ) : (
              ""
            )}
          </span>
        ) : null}

        {props.interviewData.interviewChat === true && flagStatus===0 ? (
          <span
            onClick={handleTabInterview}
            className={interviewChat ? classes.Active : classes.Active }
          >          
           {/* {props.intData.isWebinarPnl==false ? "Interview chat":"Group1 chat"} */}
           {props.intData.isWebinarPnl==false ? "Interview chat":"Interview chat"}
            {props.intwChatNotification ? (
              <Icon
                name="circle"
                className={classes.messageArrived}
                size="mini"
              />
            ) : (
              ""
            )}
          </span>
        ) : null}
      </div>
      <div className={classes.Container}>
        {componentItem === null ? NoInterview : componentItem}
        {/* {componentItem} */}
      </div>
     </div>
        : <div className={classes.Container}>
          <div className={classes.noInterviewWrapper}>
            <div className={classes.row2}>
              Chat feature is not available, Please enjoy the webinar.
         </div>
          </div>
        </div>
     }
    </div>
  );
}

const mapStateToProps= state=>{
  return{
    recruiterStatus: state.auth.recruiterState
  }
}

export default connect(mapStateToProps,null)(ChatTabs);
