import React, { Component, useCallback } from "react";
import MediaCapturer from "react-multimedia-capture";
import axios from "axios";
import classes from "../../../UI/VideoPitch/VideoPitch.module.scss";
import HrQuetions from "../../../UI/VideoPitch/HrQuetions/HrQuetions";
import SpinnerGrey from "../../../UI/SpinnerGrey/SpinnerGrey";
import Spinner from "../../../UI/Spinner/Spinner";
import playvideo from "../../../../assets/image/playvideo.png";
import moment from "moment";
import VideoProgress from "../../../../components/UI/VideoPitch/VideouploadProgress/VideoUploadProgress";
import * as actions from "../../../../store/actions/index";
import { connect } from "react-redux";
import VideoThumbnail from "simple-react-video-thumbnail";
import Swal from "sweetalert2";
import CountdownTimer from "react-component-countdown-timer";
import { detect } from "detect-browser";
import { black } from "material-ui/styles/colors";
import Logo from "../../../../../src/assets/image/sia-logo.png";
import ProgressBar from "@ramonak/react-progress-bar";
import { Timer } from 'react-countdown-clock-timer';

class VideoPitchNearby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      granted: true,
      rejectedReason: "",
      recording: false,
      paused: false,
      localCamera: null,
      hideAll: false,
      progress: null,
      videoPitchRecord: false,
      playVideo: null,
      editVideo: null,
      videPlayer: "",
      uploadFinish: false,
      videoString: "https://media.w3.org/2010/05/sintel/trailer_hd.mp4",
      videoSource: "",
      buttonState: false,
      uploadServer: false,
      str: null,
      uploadStatus: false,
      submitVideo: false,
      videoStringURL: null,
      timeStart: false,
      fileData: null,
      percentage: 0,
      isTimerPaused:false
    };

    this.handleGranted = this.handleGranted.bind(this);
    this.handleDenied = this.handleDenied.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleResume = this.handleResume.bind(this);
    this.handleStreamClose = this.handleStreamClose.bind(this);
    this.uploadVideo = this.uploadVideo.bind(this);
  }

  componentMount = () => {
    var video = document.querySelector("#videoElement");
    var that = this;
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          video.srcObject = stream;
          this.setState({ localCamera: stream, str: stream });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  handleGranted() {
    this.setState({ granted: true });
    console.log("Permission Granted!");
  }
  handleDenied(err) {
    this.setState({ rejectedReason: err.name });
  }
  handleStart(stream) {
    this.componentMount();
    this.setState({
      recording: true,
      timeStart: true,
    });
  }

  removeLocalCam = () => {
    var video = document.querySelector("#videoElement");
    if (video != null) video.srcObject.stop();
  };

  hadleEdit = () => {
    this.props.onsaveVideoRecord(null);
    this.setState({ granted: true });
    this.setState({ localCamera: false });
    this.setState({ progress: null });
    this.setState({ uploadFinish: false });
  };

  playPauseVideo = () => {
    const player = document.getElementById("replayVideoPitch");
    const btnPlayPause = document.getElementById("btnPlayPause");
    if (player.paused === true) {
      player.play();
    } else {
      player.pause();
    }
  };

  playVideo = (videoRecord) => {
    console.log(videoRecord, "Video record play");
    let playVideo = "";
    playVideo = (
      <video
        id="replayVideoPitch"
        style={{
          height: "300px",
          width: "500px",
          objectFit: "cover",
        }}
      >
        <source type="video/webm" src={videoRecord} />
      </video>
    );

    return playVideo;
  };

  getbase64 = (file, cb) => {
    let reader = new FileReader();

    reader.onload = function () {
      //localStorage.setItem("VideoPitch", reader.result);
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    reader.readAsDataURL(file);
  };

  uploadVideo = (blob) => {
    var reader = new FileReader();

    let b64String = "";

    const token = localStorage.getItem("chat-token");
    let campaignID = localStorage.getItem("Campaign_ID");

    const myFile = new File([blob], "video.mp4");
    this.setState({ fileData: myFile });
    console.log(myFile, "fileX");
    //reader.readAsDataURL(blob);

    this.getbase64(blob, (result) => {
      this.props.onsaveVideoRecord(result);
    });
    // reader.onloadend = function () {
    //   b64String = reader.result;
    //   localStorage.setItem("VideoPitch", b64String);
    // };

    this.setState({
      uploadFinish: true,
      buttonState: true,
    });
    this.removeLocalCam();
  };

  blobToFile(theBlob) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = "video.mp4";
    theBlob.type = "video/mp4";
    return theBlob;
  }

  submitVideSuccess = () => {
    console.log("Submit success");
    //this.props.onGetChatResponseNew("/Proceed_with_this_video", false);
    //this.props.onGetChatResponseNew("/Submit_video_stream", false);
  };

  uploadVideoServer = (videoRecord) => {

    localStorage.setItem("SHOWLOADER", "true")

    //this.props.onShowLoader(true)
    
    console.log("upload video to server");

    const token = localStorage.getItem("chat-token");
    let campaignID = localStorage.getItem("CAMPAIGN_APPLIED_ID");
    this.setState({
      uploadStatus: true,
    });

    console.log(videoRecord, "zse");

    var video_source = "";

    var jsonString = {
      upload: videoRecord,
      campaignId: parseInt(campaignID),
      uploaded_at: "2019-07-13T00:00:00Z",
    };

    console.log(this.state.fileData.name, "namex");

    var userId = localStorage.getItem("CandidateProfile_id02");
    console.log(userId, "aaa");

    var formdata = new FormData();
    formdata.append("upload", this.state.fileData, this.state.fileData.name);
    formdata.append("campaignId", parseInt(campaignID));
    formdata.append("fileType", ".mp4");
    formdata.append("userId", parseInt(userId));

    var fileSize = this.state.fileData.size / 1024 / 1024;
    console.log(fileSize, "size");
    if (parseInt(fileSize) <= 100) {
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);  
          this.setState({
            percentage: percent
          });
        },
      };
       // onUploadProgress: (pregresEvent) => {
        //   this.setState({
        //     progress: Math.round(
        //       (pregresEvent.loaded / pregresEvent.total) * 100
        //     ),
        //   });
        // },
 
      axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL + "chat/video-pitch-upload/",
        onUploadProgress: (pregresEvent) => {
          const {loaded, total} =  pregresEvent
                    let percent = Math.floor(loaded* 100/ total)               
                    this.setState({
                        percentage : percent
            })
        },
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data:formdata,
      }
      )
        .then((res) => {
          localStorage.setItem("UPLOAD_VIDEO", true);
          // this.props.finishVideo('/video_upload_success')
          this.setState({
            uploadServer: true,
            submitVideo: true,
          });
          this.removeLocalCam();
          window.location.replace("/")

          //this.props.onSubmitVideo(true);

          //this.props.onApplyCampaign(this.props.appliedSaveData);

          var userId = localStorage.getItem("CandidateProfile_id02")

        var data = {
            campRef: sessionStorage.getItem("CAMPINVITE"),
            userId: userId
        }

        //uploadCv step
        // axios.post(process.env.REACT_APP_API_URL +'chat/update-candidate-added-by-staff/',data, {
        //     headers: {
        //         Authorization: `Token ${localStorage.getItem('chat-token')}`
        //     }
        // }).then(res=>{
        //     console.log(res,  "Results")
        //     if (res.data.Data.flag === true){
        //         Swal.fire({
        //             toast: true,
        //             position: "top-end",
        //             title: "Process Completed",
        //             showConfirmButton: false,
        //             timer: 2500,
        //             customClass: { container: "swal-success-settings" },
        //             imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        //             imageHeight: 20,
        //         });
        //         //sessionStorage.removeItem("shareRef");
        //         ///localStorage.removeItem("shareRef");
        //         sessionStorage.removeItem("PROCEEDCLICKED");
        //         sessionStorage.removeItem("CAMPINVITE");
        //         window.location.replace("/")
        //     }
        // })


          //this.submitVideSuccess()

          //apply for uconnect
          //this.props.onApplyCampaignUconnect(this.props.appliedSaveData)

          //this.props.closeModal();

          // this.props.onGetChatResponseNew("/Submit_video_stream", false);
          // this.props.onSetChatOpen(true)

          //Proceed_with_this_video
        //   Swal.fire({
        //     toast: true,
        //     position: "top-end",
        //     title: "You have applied successfully !",
        //     showConfirmButton: false,
        //     timer: 2500,
        //     customClass: { container: "swal-success-settings" },
        //     imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        //     imageHeight: 20,
        //   });

        //   sessionStorage.removeItem("shareRef");
        //   localStorage.removeItem("shareRef");

          // localStorage.setItem("SHOWLOADER", "true")
          // localStorage.removeItem("APPLYSHARECLICKED");
          ///window.location.replace("/")
        })
        .catch((err) => {
          console.log("alrady applied ##")
          // this.props.finishVideo('/video_upload_success')
          this.setState({
            uploadServer: true,
          });
          this.removeLocalCam();
          //window.location.reload()
        });
      //this.submitVideSuccess()
    } else {
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Video is too large. Please try again !",
        showConfirmButton: false,
        timer: 2500,
        customClass: { container: "swal-success-settings" },
        imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        imageHeight: 20,
      });
      this.setState({
        uploadStatus: false,
      });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.appliedSaveData !== null) {
      var currentTime = moment.utc().toISOString();   
    }
  }

  handleEditStop(blob) {
    this.setState({
      recording: false,
    });
    this.uploadVideo(blob);
  }

  handleStop(blob) {
    this.setState({
      recording: false,
      timeStart:false
    });

    this.removeLocalCam();

    this.setState({
      videoPitchRecord: true,
      progress: "recorded",
    });

    this.uploadVideo(blob);
  }

  handlePause() {
    this.setState({
      paused: true,
      isTimerPaused:true
    });
  }
  handleResume(stream) {
    this.setState({
      paused: false,
      isTimerPaused:false
    });
  }

  handleStreamClose() {
    this.setState({
      granted: false,
    });
  }

  handleError(err) {
    console.log(err);
  }

  stopCamera = () => {
    console.log("stop camera");
  };

  CloseModal = () => {
    this.props.onsaveVideoRecord(null);
    //this.props.onApplyCampaign(this.props.appliedSaveData);
    var status = "COMPLETED";
    this.props.onUpdateApplyState(this.props.appliedSaveData, status);
    localStorage.setItem("OpenVideoModal", false);
    this.props.onSubmitVideo(false);
    localStorage.removeItem("shareRef");
  };

  render() {

    console.log("video pitch appy ##########")

    //this.props.onsaveVideoRecord("abc")

    console.log(this.state.percentage,"Upload percentage near by")

    const granted = this.state.granted;
    const rejectedReason = this.state.rejectedReason;
    const recording = this.state.recording;
    const paused = this.state.paused;

    let comp = null;

    if (this.state.hideAll) {
      comp = <h2>{this.state.progress}</h2>;
    }

    var playVideo = "";

    localStorage.setItem("video play sucess", this.state.uploadFinish);
    let uploadVideo = "Upload video";
    let editVideo = "Edit video";
    let text1 = "We are processing your video..";

    if (this.state.uploadFinish === true) {
      if (this.props.videoRecordState !== null) {
        playVideo = this.playVideo(this.props.videoRecordState);
        uploadVideo = "Upload video";
        editVideo = "Edit video";
        text1 = "Review your Video Pitch";
      }
    }
    if (this.state.uploadServer === true) {
      text1 = "Your video uploading";
    }
    let buttonActions = "";
    if (playVideo !== "" && this.state.uploadStatus === false) {
      buttonActions = (
        <div id="buttonClass" className={classes.playButtons}>
          <button
            id="btnPlayPause"
            className={classes.playButton}
            title="Play/Pause"
            accesskey="P"
            onClick={this.playPauseVideo}
          >
            <img src={playvideo} />
          </button>
          <button
            className={classes.redoButton}
            title="Redo"
            onClick={this.hadleEdit}
          >
            <i className="fas fa-redo-alt"></i>
          </button>
          {this.props.videoRecordState !== null ? (
            <button
              title="Submit"
              className={classes.proceedButton}
              disabled={!this.state.buttonState}
              onClick={() =>
                this.uploadVideoServer(this.props.videoRecordState)
              }
            >
              <i class="far fa-check-circle"></i>
            </button>
          ) : null}
        </div>
      );
    } else if (
      this.state.uploadStatus === true &&
      this.state.submitVideo === false
    ) {
      text1 = "";
      playVideo = <VideoProgress />;
    } else if (this.state.submitVideo === true) {
      this.CloseModal();
    }

    const browser = detect();

    return (
      <>
        {/* {browser.name == "chrome" ? ( */}
          <div ref="app" className={classes.VideoPitch}>
            {/* {granted && ( */}
            <button
              className={classes.closeVideoPitch}
              onClick={this.props.closeModal}
            >
              <i className="fas fa-times" />
            </button>
            {this.state.uploadFinish === true &&
            this.props.videoRecordState === null ? (
              <React.Fragment>
                <h1 className={classes.videoPitchHeading}>
                  <span className={classes.blue}>Video</span> Pitch
                </h1>
                <div className={classes.blackSpacer}>
                  <Spinner />
                </div>
              </React.Fragment>
            ) : (
              <h1 className={classes.videoPitchHeading}>
                <span className={classes.blue}>Video</span> Pitch
              </h1>
            )}
            {/* )} */}
            {this.state.progress ? (
              <div className={classes.Uploading}>
                {/* {!this.state.submitVideo && (
              <div
                className={classes.larlarge}
              >
                {text1}
              </div>
            )} */}
                {!this.state.uploadFinish && !this.state.submitVideo && (
                  <div>
                    <VideoThumbnail
                      videoUrl={this.props.videoRecord}
                      snapshotAt={1}
                    />
                    <SpinnerGrey />
                  </div>
                )}
                {/* <div className={classes.Progress}>
              <span style={{ width: `${this.state.progress}%` }}></span>
            </div> */}
              </div>
            ) : (
              <MediaCapturer
                constraints={{ audio: true, video: true }}
                mimeType="audio/webm"
                onGranted={this.handleGranted}
                onDenied={this.handleDenied}
                onStart={this.handleStart}
                onStop={this.handleStop}
                onPause={this.handlePause}
                onResume={this.handleResume}
                onError={this.handleError}
                onStreamClosed={this.handleStreamClose}
                render={({ request, start, stop, pause, resume }) => (
                  <HrQuetions
                    handleFinish={stop}
                    startRecode={start}
                    recodeinStart={recording}
                    granted={granted}
                    handlePause={pause}
                    handlePlay={resume}
                    paused={paused}
                  >
                    {/* {granted && (
                  <h1 className={classes.videoPitchHeading}>
                    <span className={classes.blue}>Video</span> Pitch
                  </h1>
                )} */}
                    {granted && !this.state.localCamera ? (
                      <React.Fragment className={classes.buttonWrapper} key={0}>
                        {!recording ? (
                          <button
                            onClick={start}
                            className={classes.ButtonStart}
                          >
                            <div className={classes.recIcon} />
                            <div className={classes.text}>Start</div>
                          </button>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    ) : // <div className={classes.Warningg}>
                    //   Microphone and Webcam is reqired to start this.
                    // </div>
                    null}
                    {granted && (
                      <div>
                        <video
                          style={{
                            height: "300px",
                            width: "500px",
                            objectFit: "cover",
                          }}
                          className={granted ? classes.On : null}
                          autoPlay
                          id="videoElement"
                        ></video>
                      </div>
                    )}
                  </HrQuetions>
                )}
              />
            )}
            <div className={classes.replaySection}>
              {playVideo}
              {buttonActions}
            </div>
            <div style={{marginTop:"10px"}}>
              <ProgressBar bgColor="#1697FA" completed={this.state.percentage} />
            </div>
            {this.state.timeStart ? (
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                {/* <CountdownTimer
                  count={60}
                  border
                  backgroundColor="#00000000"
                  color="unset"
                  hideDay={true}
                  responsive={true}
                ></CountdownTimer> */}
                <Timer
                  durationInSeconds={60}
                  formatted={false}
                  isPaused={this.state.isTimerPaused}              
                />
              </div>
            ) : null}
            <br></br>
            <p style={{ textAlign: "center" }}>
              Please note this is a one minute video pitch.<br></br>You will not
              be able to submit a video longer than that.
            </p>
          </div>
        {/* ) : (
          <div>
            <button
              style={{ align: "right" }}
              className={classes.closeVideoPitch}
              onClick={this.props.closeModal}
            >
              <i className="fas fa-times" />
            </button>
            <h2 style={{ textAlign: "center" }}>
              <img src={Logo} alt="Sia bot" style={{ width: "200px" }} />
              <br></br>
              The video pitch works best on google{" "}
              <span style={{ paddingLeft: "0px" }}>chrome</span> browser for
              now.{" "}
            </h2>
            <h3 style={{ textAlign: "center" }}>
              Please switch to google chrome and continue.
            </h3>
          </div>
        )} */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appliedSaveData: state.chatnew.appliedCampaignTemp,
    videoRecordState: state.chatnew.videoRecord,
    candidateDate: state.CandidateData.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(actions.getChatResponseNew(message, status, notification)),
    onSubmitVideo: (data) => dispatch(actions.SubmitVideo(data)),
    onApplyCampaign: (data) => dispatch(actions.ApplycampaignAction(data)),
    onsaveVideoRecord: (data) => dispatch(actions.saveVideoRecord(data)),
    onUpdateApplyState: (data, status) =>
      dispatch(actions.updateApplySaveData(data, status)),
    onGetCandidateData: (data, time) =>
      dispatch(actions.getCandidateData(data, time)),
    onSetChatOpen: (vrb) => dispatch(actions.SetChatOpen(vrb)),
    // onShowLoader : (data)=> dispatch(actions.ShowLoader(data))
    // onApplyCampaignUconnect: (data)=> dispatch(actions.ApplyCampaignUconnect(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPitchNearby);
