import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + 'chat/',
    headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin' : '*',
    }
});

export default instance;