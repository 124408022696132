import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import * as actionsCreator from "../../../store/actions/index";
import Participent from './Participent/Participent';
import classes from "./Atends.module.scss";
import atendee1 from "../../../assets/image/imagetestatend.jpg";

import black1 from "../../../assets/image/black.jpg";
class Attendes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            attendes: null,
            isSent: false
        }
    }
    componentDidMount() {
        this.props.onGetEventParticipents(localStorage.getItem("eventId"))
    }

    render() {
        let participents = (
            <div className={classes.instructionWrapper}>
                  <div className={classes.instructions}>
                    <div className={classes.heading}>
                      <span>Attendees will be updated soon!</span>
                    </div>
                    {/* <div className={classes.descr}>
                      Wanna know more about the ufairs?
                    </div>
                    <a href="#">Yes</a> */}
                  </div>
                </div>)
        if (this.props.attendes !== null) {
            participents = this.props.attendes.attendees.map((res, idx) => {
                return (
                    <>
                        <Participent data={res} key={idx} />
                    </>
                )
            })
        }
        return (
            <div>
                <div className={
                classes.atendFlexouter
            }>
                {/* <div>
                     <button onClick={this.prev} className={classes.back_btn1} > <i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                </div>
                 */}
                <div className={classes.atendFlex}>
                    {participents}
                </div>
                 {/* <div>
                     <button onClick={this.next} className={classes.back_btn1}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                </div> */}
            </div>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        attendes: state.auth.successEvtParticipents
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEventParticipents: (data) => dispatch(actionsCreator.GetEventParticipents(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Attendes)