import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import * as actionsCreator from "../../../store/actions/index";
import axios from '../../../axios';
import Jitsi from 'react-jitsi'
import classes from "./Auditorium.module.scss";
import moment from "moment";
import ReactPlayer from "react-player";
import Logo from "../../../assets/image/ufairs.svg";
import audi_middle from "../../../assets/image/dynmc/audi_middle.png"
import audi_side from "../../../assets/image/dynmc/audi_side.png"
import Pusher from 'pusher-js';

class Auditorium extends Component {

    constructor(props) {
        super(props)
        this.state = {
            attendes: null,
            isSent: false,
            webinarData:null,
            videoIndex:0
        }
    }
    componentWillMount() {
        this.getWebinar()
         this.initializationPusherLiveStream()
    }

  initializationPusherLiveStream = () => {
    var webinarId = ""
    if (this.props.webinar.auditorium.length > 0) {
      webinarId = this.props.webinar.auditorium[0].latestWebinar
    } else {
      webinarId = ""
    }
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    var channelName = webinarId.toString()
    var channel = pusher.subscribe(channelName);
    channel.bind('is-message', (data) => {
      console.log(data, "dataY")
      this.state.webinarData = data.message
      this.setState({ data: true })
    });
  }

    getWebinar = () => {
      var webinarId = ""
      if (this.props.webinar.auditorium.length > 0) {
        webinarId = this.props.webinar.auditorium[0].latestWebinar
      } else {
        webinarId = ""
      }
        var time = moment().toISOString()
        var webinarId = webinarId
        var token = localStorage.getItem("chat-token");
        axios.get("show-public-webinar/?ref=&time=" + time + "&webinarId=" + webinarId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        }).then(res => {
            this.state.webinarData = res.data
            this.setState({data:true})
        });
    }

    handleEnded = () => { 
        if (this.state.webinarData.Data.videoUrl.length-1 == this.state.videoIndex) {
          this.setState({ videoIndex: 0 });
        } else {
          this.setState({ videoIndex: this.state.videoIndex + 1 });
        }
      };

    render() {
        var webinnar = "";
        var webMessage=""
        if (this.state.webinarData !== null) {      
            if (this.state.webinarData.Data.priority === "PENDING") {   
              webMessage = "Webinar not started, Please wait"
            }      
            else if (this.state.webinarData.Data.priority === "LIVESTREAM") { 
      
                webinnar = 
                <Jitsi
                containerStyle={{ width: '730px', height: '320px' }}
                config={{
                  prejoinPageEnabled: false,
                  disableDeepLinking:true,
                  //  startAudioOnly: true,
                  startWithAudioMuted: true,
                  startWithVideoMuted: true,
                  disableProfile: true,
                  startScreenSharing: false
                }}
                interfaceConfig={{
                  SHOW_JITSI_WATERMARK: false,
                  SHOW_WATERMARK_FOR_GUESTS: false,
                  filmStripOnly: true,
               // LANG_DETECTION: true,
                  // TOOLBAR_BUTTONS: [
                  //    'desktop', 'fullscreen',
                  //   'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
                  //   'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
                  //   'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
                  //   'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone',
                  //   'e2ee', 'security'
                  // ],
                  TOOLBAR_BUTTONS: ['fullscreen','recording'],
          
                }} roomName={this.state.webinarData.Data.videoUrl[0].url} displayName={this.props.profile.candidate_profile.first_name} />
      
           }
      
            else if (this.state.webinarData.Data.priority === "PLAYLIST") {
              if (this.state.webinarData.Data.stream === false) {
                webinnar =  <div className={classes.YtBox}>      
                    <ReactPlayer
                      url={this.state.webinarData.Data.videoUrl[this.state.videoIndex].url}
                      playing={true}
                      width="600px"
                      height="300px"
                      controls={true}
                      pip={true}
                      onEnded={this.handleEnded}                      
                    />    
                    </div>          
              }
            }
          }

        var centerImage = ""
        var leftImage = null
        var rightImage = null

        if (this.props.webinar.auditoriumImages.length > 0) {
            centerImage = this.props.webinar.auditoriumImages.find(x => x.imagePlace === "CENTER").image
            leftImage = this.props.webinar.auditoriumImages.find(x => x.imagePlace === "LEFT").image
            rightImage = this.props.webinar.auditoriumImages.find(x => x.imagePlace === "RIGHT").image
        }

        return (
          <div className={classes.mainbox}>
            <div className={classes.left}>
              <img src={leftImage} />
              {/* <img src={audi_side}/> */}
            </div>
            <div className={classes.livebox}>
              {webinnar == "" && webMessage=="" ? <img src={centerImage==""?audi_middle:centerImage} /> : webinnar}
              <h2 style={{ color: "white" }}>{webMessage}</h2>
              {/* <img src={audi_middle}/> */}
            </div>
            <div className={classes.right}>
              <img src={rightImage} />
              {/* <img src={audi_side}/> */}
            </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      profile: state.profile.userProfile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auditorium)