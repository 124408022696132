import React from 'react'
import logo from "../../../assets/image/ufairs.svg";
import classes from './Splash.module.scss'


function Splash() {
    return (
        <div className={classes.Splash}>
            <img src={logo} alt="Sia bot" />
            
            <div className={classes.Loading}>Loading...</div>
        </div>
    )
}

export default Splash
