import React, { Component } from 'react';
import classes from './PasslessLogin.module.scss';
import Spinner from '../UI/Spinner/Spinner';
import Splash from '../UI/Splash/Splash';
import axios from '../../axios';
import wbIncandescent from 'material-ui/svg-icons/image/wb-incandescent';
import RegisterNewUser from '../PasswordLessLogin/RegisterUser';
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from '../../store/actions/index'

const spinnerStyles = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'none',
}

class PasslessLoginss extends Component{

    constructor(props){
        super(props)
        this.state={
            CHATURL : process.env.REACT_APP_API_URL + 'chat/',
            urlStatus : ''
        }
        this.checkUrlReferenceStatus = this.checkUrlReferenceStatus.bind(this)
        this.checkUrlState = this.checkUrlState.bind(this)
        this.getAuthTokenUser = this.getAuthTokenUser.bind(this)
        this.getNewAuthUser = this.getNewAuthUser.bind(this)
        this.checkUrlStateAPI = this.checkUrlStateAPI.bind(this)

    }
    componentDidMount=()=>{
        localStorage.setItem('OPENCHATWINDOW', true)
        this.checkUrlReferenceStatus()
        //this.checkUrlState()
    }

    checkUrlReferenceStatus =()=>{
        var logStatus = sessionStorage.getItem("loginStatus")
        var logref = sessionStorage.getItem("LogingReferecne")
        this.props.OnGetStatus(logref, logStatus)
    }

    checkUrlStateAPI=(params)=>{
        var status = params.status
        var email = params.email
        var linkedId = params.linkedIn

        if (status === false){
            this.getAuthTokenUser()
        }

        else if(status === true){
            console.log("linked  in login")
            this.getAuthTokenUser()
        }      
    }

    checkUrlState=()=>{
        var logStatus = sessionStorage.getItem("loginStatus")
        var logref = sessionStorage.getItem("LogingReferecne")
        if(logStatus == "True"){
            this.getAuthTokenUser()
        }
        else if(logStatus == "False"){

            var data={
                "ref" : logref
            }
            axios.post(this.state.CHATURL + 'get-sign-up-state/',data,{
                headers: {
                    'content-type': 'application/json'
                }
            }).then(res=>{
                    if(res.data.link === "Expired"){
                        console.log("expired link")
                        sessionStorage.removeItem("loginStatus")
                        sessionStorage.removeItem("LogingReferecne")
                        sessionStorage.setItem("linkExpired",true)
                        window.location.replace("/")
                    }
                    else{
                        this.getNewAuthUser()
                    }
            })
            //this.getNewAuthUser()
        }
    }

    getNewAuthUser =() => { 
        var logStatus = sessionStorage.getItem("loginStatus")
        var logref = sessionStorage.getItem("LogingReferecne")

        var data = {
            ref : logref,
            firstName : "",
            lastName : "",
            email : "",
            profile_pic : "",
            status : logStatus
        }
        axios.post(this.state.CHATURL + 'passless-get-token/',data,{
            headers: {
                'content-type': 'application/json'
            }
        }).then(res=>{
            console.log(res, 'res')
            sessionStorage.removeItem("loginStatus")
            window.location.replace("/Register")
        }).catch(err => {
            console.log(err, 'err')
            sessionStorage.removeItem("loginStatus")
            sessionStorage.removeItem("LogingReferecne")
            window.location.replace("/");
        })
    }
    
    getAuthTokenUser =()=>{

        var logStatus = sessionStorage.getItem("loginStatus")
        var logref = sessionStorage.getItem("LogingReferecne")   

        var data = {
        ref : logref,
        firstName : "",
        lastName : "",
        email : "",
        profile_pic : "",
        status : logStatus
        }
        axios.post(this.state.CHATURL + 'passless-get-token/',data, {
        headers: {
            'content-type': 'application/json'
        }
        }).then(res => {    

          
            // localStorage.setItem('chat-token', res.data.Key)
            // sessionStorage.setItem('chat-token', res.data.Key)
            localStorage.setItem("chat-token", res.data.Key);
            localStorage.setItem('OnBoard_State', res.data.CandidateProfile.onBoard_state);
            sessionStorage.setItem('chat-token', res.data.Key)
            sessionStorage.setItem('CandidateProfile_id', res.data.CandidateProfile.id)
            sessionStorage.setItem('CandidateProfile_id02', res.data.CandidateProfile.user_id)
            localStorage.setItem('CandidateProfile_id', res.data.CandidateProfile.id)
            localStorage.setItem('CandidateProfile_id02', res.data.CandidateProfile.user_id)
            sessionStorage.setItem('OnBoard_State', res.data.CandidateProfile.onBoard_state)
            sessionStorage.setItem('firstName' , res.data.CandidateProfile.first_name)
            //sessionStorage.setItem('lastName', res.data.Candidate)
            sessionStorage.removeItem('loginStatus')
            sessionStorage.removeItem('LogingReferecne')       
            localStorage.setItem("REFRESHSTATUS",true)        
            window.location.replace("/")
        }).catch(err=>{
            console.log(err,'error')
            localStorage.removeItem("loginStatus")
            localStorage.removeItem("LogingReferecne")
            window.location.replace("/")
        })
    }
    render(){

        if (this.props.urlStateValues !==null){
            this.checkUrlStateAPI(this.props.urlStateValues)
        }

        return(
            <div style={spinnerStyles}>
                <Splash />
            </div>
        )
    }
}

const mapStateToProps =(state)=>{
    return{
        urlStateValues : state.auth.urlStateValues
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        OnGetStatus : (ref, urlState)=> dispatch(actions.GetStatus(ref, urlState))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PasslessLoginss)