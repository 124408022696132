import React, { Component, useCallback } from "react";
import { connect } from "react-redux";
import MediaCapturer from "react-multimedia-capture";
import ReactMicRecord from "react-mic-record";
import classes from "./AudioTest.module.scss";

class AudioTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
    };
  }
  startRecording = () => {
    this.setState({
      record: true,
    });
  };

  stopRecording = () => {
    this.setState({
      record: false,
    });
  };

  onData(recordedBlob) {
    console.log("chunk of real-time data is: ", recordedBlob);
  }

  onStop(recordedBlob) {
    console.log("recordedBlob is: ", recordedBlob);
  }

  render() {
    const { record } = this.state;
    return (
      <div className={classes.audioTestWrapper}>
        <button onClick={this.props.closeModal} className={classes.micClose}>
          <i className="fas fa-times" />
        </button>
        <div className={classes.heading}>
          <img src="https://image.flaticon.com/icons/svg/2958/2958602.svg" />
          <h2>Microphone test</h2>
        </div>
        <div className={classes.descr}>
          Click <span>Start</span> and check the waveform movement as you speak
          into the mic.
        </div>

        {/* <ReactMic
          record={this.state.record}
          className="sound-wave"
          onStop={this.onStop}
          onData={this.onData}
          strokeColor="#1697fa"
          backgroundColor="#eee"
        /> */}
        <ReactMicRecord
          record={this.state.record}
          className="sound-wave"
          onStop={this.onStop}
          strokeColor="#1697fa"
          backgroundColor="#eee"
        />
        <div className={classes.buttonsWrapa}>
          <button
            className={classes.exit}
            onClick={this.props.closeModal}
            type="button"
          >
            Exit
          </button>
          <button
            onClick={!record ? this.startRecording : this.stopRecording}
            className={classes.action}
          >
            {!record ? "Start" : "Stop"}
          </button>
        </div>
        {/* <div className={classes.buttonsWrapa}>
          <button className={classes.exit} onClick={this.props.closeModal}>
            Close
          </button>
          <button
            onTouchTap={!record ? this.startRecording : this.stopRecording}
            type="button"
            className={classes.action}
          >
            {!record ? "Start" : "Stop"}
          </button>
        </div> */}
      </div>
    );
  }
}
export default AudioTest;
