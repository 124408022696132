import axios from "../../axios";
import * as actions from "./actionTypes";
import objectChecker from "../../hoc/objectChecker";
import { ActionSchedule } from "material-ui/svg-icons";
import dashboard from "material-ui/svg-icons/action/dashboard";
import NotificationNetworkCheck from "material-ui/svg-icons/notification/network-check";

export const authInit = () => {
  return {
    type: actions.AUTH_INIT,
  };
};
export const authSuccess = () => {
  return {
    type: actions.AUTH_SUCCESS,
  };
};
export const authFail = () => {
  return {
    type: actions.AUTH_FAIL,
  };
};

export const successOnboardData = (data) => {
  return {
    type: actions.SEND_ONBOARD_DATA,
    data,
  };
};

export const successLocationData = (data) => {
  return {
    type: actions.SEND_ONBOARD_DATA_LOCATION,
    data,
  };
};

export const successCvString = (data) => {
  return {
    type: actions.CV_STRING,
    data,
  };
};

export const successSkills = (data) => {
  return {
    type: actions.SKILLS_TAGS,
    data,
  };
};

export const successExp = (data) => {
  return {
    type: actions.SUCCESS_EXP,
    data,
  };
};

export const successEdu = (data) => {
  return {
    type: actions.SUCCESS_EDU,
    data,
  };
};

export const getAuthontication = (response) => {
  return (dispatch) => {
    dispatch(authInit());
    const ref = localStorage.getItem("chat-ref-id");
    localStorage.setItem("chat-ref-id", ref);
    const authtoken = response;

    console.log(response, "oauth token response");

    axios
      .post(
        "social-auth/",
        ref
          ? {
              provider: "linkedin",
              oauth_token: authtoken,
              ref: ref ? ref : "",
              eventId:localStorage.getItem("eventId")
            }
          : {
              provider: "linkedin",
              oauth_token: authtoken,
              eventId:localStorage.getItem("eventId")
            }
      )
      .then((res) => {     
        const token = objectChecker(res, ["data", "token"]);
        if (token) {
          localStorage.setItem("chat-token", token);
          sessionStorage.setItem("chat-token", token);
          sessionStorage.setItem(
            "OnBoard_State",
            res.data.candidateProfile[0].onBoard_state
          );
          localStorage.setItem(
            "OnBoard_State",
            res.data.candidateProfile[0].onBoard_state
          );
          sessionStorage.setItem(
            "CandidateProfile_id",
            res.data.candidateProfile[0].id
          );
          sessionStorage.setItem(
            "CandidateProfile_id02",
            res.data.candidateProfile[0].user_id
          );
          sessionStorage.setItem(
            "firstName",
            res.data.candidateProfile[0].first_name
          );
          localStorage.setItem(
            "firstName",
            res.data.candidateProfile[0].first_name
          );
          localStorage.setItem(
            "OnBoard_State",
            res.data.candidateProfile[0].onBoard_state
          );
          localStorage.setItem(
            "CandidateProfile_id",
            res.data.candidateProfile[0].id
          );
          localStorage.setItem(
            "CandidateProfile_id02",
            res.data.candidateProfile[0].user_id
          );
        }       
        dispatch(authSuccess());
        // dispatch(getMe(res.data.token))
      })
      .catch((err) => {
        dispatch(authFail());

        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("chat-username");
            localStorage.removeItem("chat-userimage");
            localStorage.removeItem("chat-token");
            window.location.reload();
          }
        }
      });
  };
};

export const authRedirect = () => {
  return {
    type: actions.AUTH_REDIRECT,
  };
};
export const authCheck = () => {
  const token = localStorage.getItem("chat-token");

  return (dispatch) => {
    if (token) {
      dispatch(authRedirect());
    }
  };
};

////New methods for user login wth sia//////
export const loginSuccess = (token) => {
  return {
    type: actions.USER_LOGIN,
    token: token,
  };
};

export const GetAuthRef=(ref)=>{

  return (dispatch)=>{
    axios.get('candidate-get-campaign-login/?ref='+ref,{
      headers: {
          'content-type': 'application/json'
      }
      }).then(res=>{
          localStorage.setItem("chat-token", res.data.Key);        
          dispatch(loginSuccess(res.data.Key));
          dispatch(authSuccess());
      }).catch(err => {
          console.log(err, 'err')
      })
  }
}

export const userLogin = (user) => {
  const ref = localStorage.getItem("chat-ref-id");
  localStorage.setItem("chat-ref-id", ref);

  return (dispatch) => {
    //const authtoken = objectChecker(response, ['oauthToken'])
    dispatch(authInit());

    axios
      .post("social-auth/", user)
      .then((res) => {
        dispatch(loginSuccess(res.data.token));
        dispatch(authSuccess());
        localStorage.setItem("chat-token", res.data.token);
      })
      .catch((err) => {
        if (err.response) {
          //dispatch(authFail(err.response.data))
        }
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("chat-token");
            window.location.reload();
          }
        }
      });
  };
};

export const getMeSuccess = (data) => {
  return {
    type: actions.GET_ME_SUCCESS,
    data,
  };
};

export const getMeFail = () => {
  return {
    type: actions.GET_ME_FAIL,
  };
};

// --- Onboard user

export const OnboardInit = () => {
  return {
    type: actions.ONBOARD_INIT,
  };
};
export const OnboardSuccess = (data) => {
  return {
    type: actions.ONBOARD_SUCCESS,
    data,
  };
};
export const OnboardFail = () => {
  return {
    type: actions.ONBOARD_FAIL,
  };
};

export const onboard = (id, details) => {
  var token = localStorage.getItem("chat-token");

  return (dispatch) => {
    dispatch(OnboardInit());

    axios
      .put("update-candidate-profile/" + id + "/", details, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        dispatch(OnboardSuccess(res.data));
        sessionStorage.setItem("OnBoard_State", "Completed");
      })
      .catch((err) => {
        dispatch(OnboardFail());
      });
  };
};

export const sendData = (data) => {
  return (dispatch) => {
    dispatch(successOnboardData(data));
  };
};
export const sendLocationData = (data) => {
  return (dispatch) => {
    dispatch(successLocationData(data));
  };
};

export const cvDataString = (data) => {
  return (dispatch) => {
    dispatch(successCvString(data));
  };
};
export const skillTags = (data) => {
  return (dispatch) => {
    dispatch(successSkills(data));
  };
};

export const sendExp = (data) => {
  return (dispatch) => {
    dispatch(successExp(data));
  };
};

export const sendEdu = (data) => {
  return (dispatch) => {
    dispatch(successEdu(data));
  };
};

export const updataSuccess = (data) => {
  return {
    type: actions.ONBOARD_SUCCESS,
    data,
  };
};

export const deleteItemList = (data) => {
  return {
    type: actions.DELETE_ITEM_LIST,
    data,
  };
};

export const onGetSkippedStatus = (data) => {
  return {
    type: actions.GET_CAMPAIGN_SKIPPED_DATA,
    data,
  };
};

export const newExpList = (data) => {
  return {
    type: actions.EXP_LIST,
    data,
  };
};

export const updateProfile = (idData, data) => {  
  var token = localStorage.getItem("chat-token");
  return (dispatch) => {
    axios
      .put("update-candidate-profile/" + idData + "/", data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {      
        sessionStorage.setItem("OnBoard_State", "COMPLETE");
        localStorage.setItem("OnBoard_State", "COMPLETE");
        dispatch(updataSuccess(res.data));
        window.location.replace("/");
      });
  };
};

export const UpdateCVProfile =(data,id)=>{
  var token = localStorage.getItem("chat-token");
  return (dispatch) => {  
    axios
      .put("update-candidate-profile/" + id + "/", data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        console.log("update success");
      });
  };
}

export const deleteItem = (data) => {
  return (dispatch) => {
    dispatch(deleteItemList(data));
  };
};

export const arrayListExp = (data) => {
  return (dispatch) => {
    dispatch(newExpList(data));
  };
};

export const SuccessInterviewInvite = (data) => {
  return {
    type: actions.ON_SUCCESS_INTERVIEW_INVITE,
    data,
  };
};

export const InviteInterview = (data) => {
  return (dispatch) => {
    dispatch(SuccessInterviewInvite(data));
  };
};

export const systemLevelDataSuccess = (data) => {
  return {
    type: actions.SUCCESS_SYSTEM_LEVEL_DATA,
    data,
  };
};

export const latestAvailableInterview =(data)=>{
  return{
    type: actions.GET_AVAILABLE_INTERVIEW,
    data   
  }
}

export const successGetUrlState=(data)=>{
  return{
    type: actions.GET_SUCCESS_URL_STATE,
    data
  }
}

export const SuccessRecruiteeStatus=(data)=>{
  return{
    type: actions.GET_RECRUITER_STATUS_SUCCESS,
    data
  }
}
export const SuccessEventData=(data)=>{
  return{
    type: actions.GET_EVENT_DATA_SUCCESS,
    data
  }
}

export const updateLogState = () => {
  const token = localStorage.getItem("chat-token");
  return (dispatch) => {
    axios
      .get("update-login-state/?token=" + token, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
       // console.log(res, "updatd");
      })
      .catch((err) => {     
        localStorage.clear()
        window.location.replace("/");
      });;
  };
};

export const getSkippedCampaigns = () => {
  const token = localStorage.getItem("chat-token");
  return (dispatch) => {
    axios
      .get("get-applied-skip-campaigns/?token=" + token, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {  
        dispatch(onGetSkippedStatus(res.data["Data"]));
      });
  };
};

export const getSystemLevelData = () => {
  const token = localStorage.getItem("manage-token");
  return (dispatch) => {
    axios
      .get("get-system-level-data/", {
        headers: {
          "Content-Type": `application/json`,
        },
      })
      .then((res) => {
        dispatch(systemLevelDataSuccess(res.data));
      });
  };
};

export const setSomethingChanged = (data) => {
  return {
    type: actions.SET_SOMETHING_CHANGED,
    data,
  };
};

export const getAvailableInterview =()=>{
  var token = localStorage.getItem("chat-token");
  return dispatch=>{
    axios.get('get-latest-available-inw-dashbaord/?senderId='+token,{
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": `application/json`
      },
    }).then(res=>{
      dispatch(latestAvailableInterview(res.data))
    })
    //dispatch(latestAvailableInterview(data))
  } 
}

export const GetStatus = (ref, urlState)=>{
  return dispatch =>{
    axios.get('candidate-check-login-status/?loginStatus='+urlState+"&ref="+ref,{
      headers: {
        "Content-Type": `application/json`
      },
    }).then(res=>{
      dispatch(successGetUrlState(res.data.Data))
    })
  }
}

export const JoinCall =(data)=>{
  var token = localStorage.getItem("chat-token");
  return dispatch=>{
    axios.post('candidate-video-call-join/',data,{
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": `application/json`,
      }
    }).then(res=>{
      console.log("call joined")
    })
  }
}

export const GetRecruiterStatus=(ref)=>{
  var token = localStorage.getItem("chat-token");
  return dispatch=>{
    axios.get('candidate-video-call-join/?ref='+ref,{
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": `application/json`,
      }
    }).then(res=>{     
      dispatch(SuccessRecruiteeStatus(res.data.Data))
    })
  }
}

//----- event actions ----//

export const GetEventDataBefroLgin=(data)=>{
  return dispatch=>{
    dispatch(SuccessEventData(data))
  }
}

export const SuccessGetEventParticipents=(data)=>{
  console.log(data,"Actio event function")
  return {
    type: actions.SUCCESS_EVENT_PARTICIPENTS,
    data
  }
}

export const ErrorGetEventParticipents=(data)=>{
  return {
    type: actions.ERROR_EVENT_PARTICIPENTS,
    data
  }
}

export const SuccessGetCandidateDashboardData=(data)=>{
  return {
    type: actions.SUCCESS_CANDIDATE_DASHBOARD_DATA,
    data
  }
}

export const ErrorGetCandidateDashboardData=(data)=>{
  return {
    type: actions.ERROR_CANDIDATE_DASHBOARD_DATA,
    data
  }
}

export const GetEventParticipents=(data)=>{
  var token = localStorage.getItem("chat-token"); 
  console.log(data,"get event attendees")
  return dispatch=>{
    axios.get('get-attendees/?eventId='+data,{
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": `application/json`,
      }
    }).then(res=>{     
      console.log(res.data.Data, "Result data events")
      dispatch(SuccessGetEventParticipents(res.data.Data))
    })
    .catch(err=>{
      dispatch(ErrorGetEventParticipents(null))
    })
  }
}

export const GetCandidateDashBoardData=(id,logDate, eventId)=>{
  var token = localStorage.getItem("chat-token"); 
  return dispatch=>{
    axios.get('event-candidate-dashboard-data/?id='+id+'&logDate='+logDate+"&eventId="+eventId,{
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": `application/json`,
      }
    }).then(res=>{     
      dispatch(SuccessGetCandidateDashboardData(res.data.Data))
    })
    .catch(err=>{
      dispatch(ErrorGetCandidateDashboardData(null))
    })
  }
}

export const SuccessEventTitlebar=(data)=>{
  return {
    type: actions.SUCCESS_EVENT_TITLE_BAR,
    data
  }
}

export const ErrorEventTitlebar=(data)=>{
  return {
    type: actions.ERROR_EVENT_TITLE_BAR,
    data
  }
}

export const SuccessEventStallData=(data)=>{
  return {
    type: actions.SUCCESS_EVENT_STALL_DATA,
    data
  }
}

export const ErrorEventStallData=(data)=>{
  return {
    type: actions.ERROR_EVENT_STALL_DATA,
    data
  }
}

export const SuccessEventNotifications=(data)=>{
  return {
    type: actions.SUCCESS_EVENT_NOTIFICATIONS,
    data
  }
}

export const ErrorEventNotifications=(data)=>{
  return {
    type: actions.ERROR_EVENT_NOTIFICATIONS,
    data
  }
}

export const GetEventTitleBar=(eventId,option, instanceId)=>{
  var token = localStorage.getItem("chat-token"); 
  return dispatch=>{
    axios.get('event-title-bar/?eventId='+eventId+'&option='+option+"&instanceId="+instanceId,{
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": `application/json`,
      }
    }).then(res=>{     
      dispatch(SuccessEventTitlebar(res.data.Data))
    })
    .catch(err=>{
      dispatch(ErrorEventTitlebar(null))
    })
  }
}

export const GetEventStallData=(stallId, eventId, senderId)=>{
  var token = localStorage.getItem("chat-token"); 
  return dispatch=>{
    axios.get('get-event-stall-data/?stallId='+stallId+"&eventId="+eventId+"&senderId="+senderId,{
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": `application/json`,
      }
    }).then(res=>{     
      dispatch(SuccessEventStallData(res.data.Data))
    })
    .catch(err=>{
      dispatch(ErrorEventStallData(null))
    })
  }
}

export const GetEventNotifications=(eventId, candidateId)=>{
  var token = localStorage.getItem("chat-token"); 
  return dispatch=>{
    axios.get('event-get-notifications/?eventId='+eventId+'&candidateId='+candidateId,{
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": `application/json`,
      }
    }).then(res=>{     
      dispatch(SuccessEventNotifications(res.data.Data))
    })
    .catch(err=>{
      dispatch(ErrorEventNotifications(null))
    })
  }
}

export const GetAllEventsData=(data)=>{
  var token = localStorage.getItem("chat-token"); 
  console.log(data,"get event attendees")
  // return dispatch=>{
  //   axios.get('get-live-events/logDate='+data.currentTime+'&timeZone='+(new Date()).getTimezoneOffset() ,{
  //     headers: {
  //       Authorization: `Token ${token}`,
  //       "Content-Type": `application/json`,
  //     }
  //   }).then(res=>{     
  //     console.log(res.data.Data, "Result data events")
  //     dispatch(SuccessGetEventParticipents(res.data.Data))
  //   })
  //   .catch(err=>{
  //     dispatch(ErrorGetEventParticipents(null))
  //   })
  // }
  return dispatch=>{
    axios.get('companies-list-events/' ,{
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": `application/json`,
      }
    }).then(res=>{     
      console.log(res.data.Data, "Result data events")
      dispatch(SuccessGetEventParticipents(res.data.Data))
    })
    .catch(err=>{
      dispatch(ErrorGetEventParticipents(null))
    })
  }
}

export const SuccessGetAllEventsData=(data)=>{
  console.log(data,"get all events function")
  return {
    type: actions.SUCCESS_GET_ALL_EVENT_DATA,
    data
  }
}

export const ErrorGetAllEventsData=(data)=>{
  return {
    type: actions.ERROR_GET_ALL_EVENT_DATA,
    data
  }
}

export const successAccessStall=(data)=>{
  return {
    type: actions.SUCCESS_ACCESS_STALL,
    data
  }
}

export const successBtnDisable=(data)=>{
  return {
    type: actions.SUCCESS_BTN_DISABLE,
    data
  }
}

export const AccessStall=(data)=>{
  return dispatch=>{  
    dispatch(successAccessStall(data))
  }
}

export const BtnDisable=(data)=>{
  return dispatch=>{
    dispatch(successBtnDisable(data))
  }
}

// export const successChangeTime=(data)=>{
//   return {
//     type: actions.SUCCESS_CHANGE_TIME,
//     data
//   }
// }

// export const successChangeCount =(data)=>{
//   console.log(data, "Change count timer")
//   return {
//     type: actions.SUCCESS_CHANGE_COUNT,
//     data
//   }
// }

// export const ChangeTimer =(count, data)=>{
//   console.log(count, "####count")
//   console.log(data, "#####data")
//   return dispatch=>{
//     dispatch(successChangeTime(data))
//     dispatch(successChangeCount(count))
//   }
// }
