import React, { Component } from "react";
import iframe from "react-iframe";
import { connect } from "react-redux";
import CountdownTimer from "react-component-countdown-timer";
import objectChecket from "../../../hoc/objectChecker";
import "./Timer.scss";
import moment from "moment";

//import "~react-component-countdown-timer/lib/styles.css";

class Time extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countTime : this.props.intCount
    }
  }

  render() {
    var countData = "";
    var component = "";
    console.log(this.state.countTime, "Count timer seconds");
    try {

      
      countData = parseInt(this.props.intCount) / 1000;

      // var minutes = Math.floor(this.props.intCount / 60000);      
      // var currentTime = moment();
      // var  localTimeEnd = moment.utc(this.props.intwData.InterviewData[0].startTime).toDate();
      // localTimeEnd = moment(localTimeEnd).add(minutes,"minutes")
      // var differnt = localTimeEnd.diff(currentTime,'seconds') 

  
      console.log(countData, "Try count data");
      if (this.props.intCount === null) {
        component = "Loading";
      } else if (this.props.intCount !== null && this.props.timeChange === false) {
        component = (
          <CountdownTimer
            count={countData}
            border
            backgroundColor="#00000000"
            color="unset"
            hideDay={this.props.hideDays}
          ></CountdownTimer>
        );
      }
        else if(this.props.timeChange === true && this.props.intCount !== null){
          component = "Waiting for your time"
        }
    } catch {
      console.log("Pending time count");
      component = "Loading";
    }
    //console.log(this.props.intCount.data, "interview count");
    console.log(typeof countData, "Count data type");
    console.log(countData, "Count data");
    return <div className="chat-webinar-timer" style={{textAlign:'center', color:'#ffffff78', padding:'10px' }}>{component}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    intwData: state.CandidateData.interviewData,
  };
};
const mapDispatchToProps = (dispatch) => {
  // return{
  //     onGetIntData : (data) => dispatch(actionCreater.getInterviewData(data))
  // }
};

export default connect(mapStateToProps, mapDispatchToProps)(Time);
