import React, { Component } from "react";
import { connect } from "react-redux";
import Logo from "../../assets/image/ufairs.svg";
import classes from "./Landing.module.scss";
import Styles from "./UfLanding.module.scss";
import * as actionsCreator from "../../store/actions/index";
import { Link } from "react-router-dom";
import axios from "../../axios";
import moment from "moment";
import AllEvents from "../../../src/CompanyLanding";

class Landing extends Component {
  state = {
    code: "",
    errorMessage: "",
    loading: false,
    linkExpired: false,
    showLogin: false,
    agenda: false,
    aboutUs: false,
    infoImage: "",
    agendaPdf: "",
    ishide: true,
    events: [],
    isData: false,
    eventId: 0,
    eventName: "",
    eventDataSet: false,
    showPassLess: false,
    isGoAllEvents: false,
    hasNameEvent : 0,
    eventUrl : null
  };

  componentDidMount() {
    // console.log('this.props', this.props.location.pathname)
    // if (this.props.location.pathname === "/") {
    //   console.log('this.props', ' called')
    // window.location.href = "/test123";
    this.state.isGoAllEvents = true
    //this.hasNameEvent()
    //}
  }
  componentWillMount = () => {
    // if (this.props.location.pathname === "/") {
    //   console.log('this.props', ' called')
    // window.location.href = "/test123";
    this.state.isGoAllEvents = true
    //}
  };

  hasNameEvent = () => {

    var logStat = null
    var logRef = null 
    console.log(sessionStorage.getItem("LogingReferencePublic"), "has name event")

    if (sessionStorage.getItem("LogingReferencePublic") === null){
      this.setState({
          hasNameEvent : 2 //redirect to landing page
      })
    } 
    else if (sessionStorage.getItem("LogingReferencePublic") !== null){
      axios.get('access-event/?loginRef='+sessionStorage.getItem("LogingReferencePublic"),{
          headers: {
              'Content-type': `application/json`,
          },
      }).then(res=>{
          console.log(res, "Result")
          // this.setState({
          //     hasNameEvent : 1, //redirect to landing page
          // })
          if (res.data.Data.hasEvent === true){
              this.setState({
                  hasNameEvent : 1,
                  eventUrl: res.data.Data.results[0]["shareUrl"]
              })
          }
      }) 
    }
  }




  colseAllEvents = () => {
    this.setState({ isGoAllEvents: false })
  }
  render() {
    let events = <AllEvents  subDomain={this.props.subDomain}/>

    var infoDeskImageShow = null
    if (this.state.eventDataSet === true) {
      if (this.state.infoImage == "") {
        infoDeskImageShow = Logo
      }
      else if (this.state.infoImage != "") {
        infoDeskImageShow = this.state.infoImage
      }
    }
    else {
      infoDeskImageShow = null
    }

    const { code, errorMessage } = this.state;

    if (this.state.hasNameEvent === 1){
      window.location.replace(window.location.href+this.state.eventUrl)
    }


    return (
      <div className={Styles.LoginContainer}>
        <div className={Styles.Nav}>
          <div className={Styles.NContainer}>
            <Link to={""} className={Styles.Brand}><img src={Logo} /></Link>
            <span className={Styles.CareerfairName}>{this.state.eventName}</span>
          </div>
        </div>
        <div className={Styles.LoginBodyContainer}>
          <div className={Styles.LBodyContent}>

            {this.state.isGoAllEvents == true ?
              <div className={classes.LoginBox}>
                <div className={classes.aboutTop}>
                  <h3><span className={classes.orange}>EVENTS</span></h3> 
                </div>
                {events}
              </div>
              : null
            }
          </div>
        </div>

      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetAuthontication: (response) =>
      dispatch(actionsCreator.getAuthontication(response)),
    onGetEventDataBefroLgin: (data) => dispatch(actionsCreator.GetEventDataBefroLgin(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Landing);
