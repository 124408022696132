import React, { Fragment, useState, Component } from "react";
import hasDate from "../../../hoc/hasDate";
import isResponse from "../../../hoc/isResponse";
import classes from "./ChatComponent.module.scss";
import IconSia from "../../../assets/image/sia.png";
import IconUser from "../../../assets/image/profile-user.png";
import siaSmall from "../../../assets/image/sia.png";
import isXml from "is-xml";
import XMLReader from "xml-reader";
import xmlQuery from "xml-query";
import { connect } from "react-redux";
import * as actionCreater from "../../../store/actions/index";
import moment from "moment";
import axios from "../../../../src/axios";
import swal from "sweetalert2";
import CampaignsList from "./CampaignsList";
import ObjectChecker from "../../../hoc/objectChecker";
import ViewVideo from "../../../../src/components/UI/VideoPitch/ViewVideo";
import ViewCoverLetter from "../../../../src/components/UI/VideoPitch/ViewCoverLetter";
import Moment from 'moment'

const chatComponent = (props) => {
  const [video, setVideo] = useState(true);
  const [recordVideo, setRecordVideo] = useState(false);

  const showVideo = (status) => {
    setVideo(status);
  };
  const stopCam = () => {};
  const hideVideoPitch = () => {
    setRecordVideo(false);
    //window.localstream.getTracks().forEach(track => track.stop())
  };
  const handleRecordVideo = () => {
    setRecordVideo(true);
  };
  const classList = [
    classes.ChatComponent,
    props.type === "query" ? classes.User : null,
  ];

  const onApplyCampaign = (value) => {
    var data = {
      candidateid: props.profile.id,
      campaignId: parseInt(value),
      ref: "",
      reminderDuration: 0,
      accessed: 0,
      status: "APPLIED",
      eventId:localStorage.getItem("eventId")
    };
    //props.onApplyCampaign(data);
    var token = localStorage.getItem("chat-token");
    axios
      .post(
        process.env.REACT_APP_API_URL + "chat/apply-campaign-by-candidate/",
        data,
        {
          headers: {
            Authorization: `token ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        props.applyCampaing();
      })
      .catch((resCatch) => {
        console.log("Already applied");
        console.log("Al");
        swal.fire({
          toast: true,
          position: "top-end",
          title: "Already applied , pleaese try another campaign",
          showConfirmButton: false,
          timer: 2500,
          customClass: { container: "swal-success-settings" },
          imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          imageHeight: 20,
        });
      });
  };

  const onInitialChat = (value) => {
    props.initialChat(value);
  };

  const onWelcomeBack = (value) => {
    if (value == 0) {
      //localStorage.setItem("OpenQuestionModal",true)
      props.welcomeBack(value);
      // setOpenModel(true)
    } else {
      props.welcomeBack(value);
    }
  };

  const onStartCareerTest = (value)=>{
    if (value === 0){
      props.startCareerTest(value)
    }  
    else{
      localStorage.setItem("startCareerTestInit", false)
      props.startCareerTest(value)
    }
  }

  const onAvailableCampaign = (value) =>{
    if (value === 0){
      props.AvailableCampaign(value)
    }
    else{
      localStorage.setItem("applyAvailableCam", false)
      props.AvailableCampaign(value)
    }
  }

  const onSkippedApplyCampaigns = (value) => {
    props.SkippedApplycampaigns(value);
  };

  const onScheduleInterview = (value) => {
    if (value === 1) {
      localStorage.setItem("manageExistingcampaigns", true);
    }
    props.scheduleInterview(value);
  };
  const onChatResponseNull = (value) => {   
    props.responseNull(value);
  };
  let message = null;
  let payType = null;
  let campaignData = [];
  let question = "";
  let payValue = "";

  const openChatPopUp = (value) => { 
    props.OpenChatPopUp(value);
  };

  const sendchatCompoentClose = () => {
    props.onCloseChat(true);
  };

  // localStorage.getItem('submitCV')

  const handleGobackFromCampaign = () => {
    props.onGetChatResponseNew("go_back_from_available_campaigns", false);
  };

  if (props.type === "query") {
    const chunck = isXml(props.message);
    let payloadValue = props.message;
    if (chunck) {
      //   // console.log("xml");
      let xml = XMLReader.parseSync(props.message);
      payloadValue = xmlQuery(xml).find("value").text();
      //   // console.log("nma", xmlQuery(xml).find('value').text());
    }

    message = (
      <div
        className={classes.Message}
        dangerouslySetInnerHTML={{
          __html: isResponse(payloadValue, classes.IsDate, props.message),
        }}
      />
    );
  }

  if (props.type === "response") {

    var message_job = ""
    if (props.loggedTime === true){
      message_job = "Are you actively searching for a new job?"
    }

    let messageLength = 0;
    if (props.message_response === true) {
      console.log(props.message,"mesg")
      messageLength = JSON.parse(props.message).length;
    } else if (props.message_response === false) {
      messageLength = props.message.length;
    }

    if (messageLength === 0) {
     
      if (props.initialStartCnvsation === true) {
        var progressValue = [
          ObjectChecker(props.candidateDate, ["Progress", "value"]),
        ];       
        var chatPopUp = true;

        message = (
          <div className={classes.welcomeBackMessage}>
            <div className={classes.wtext}>
              Hello {sessionStorage.getItem("firstName")}. I am Sia, Your
              Digital Career companion eager to help you with your career
              adventure. {message_job}
            </div>
            <div className={classes.buttonComponent}>
              <button
                className={classes.yesNoButton}
                onClick={(val) => onInitialChat(0)}
              >
                Yes
              </button>
              <button
                className={classes.yesNoButton}
                onClick={(val) => onInitialChat(1)}
              >
                No
              </button>
            </div>
          </div>
        );
      } else if (props.welcomeback === true) {
        console.log(props.welcomeBack, "Welcome back true");
        var progressValue = [
          ObjectChecker(props.candidateDate, ["Progress", "value"]),
        ];

        console.log(progressValue[0], "Progress value");

        //interviewTime

        if (props.interviewTime !== null) {
          var chatPopUp_welcome = true;
          console.log(props.interviewTime["Campaign"], "props interview time");
          message = (
            <div className={classes.welcomeBackMessage}>
              <div className={classes.wtext}>
                Hello {sessionStorage.getItem("firstName")} Welcome back! I am
                sure you’re looking forward to join{" "}
                {props.interviewTime["Campaign"]} which is due to start in {20}{" "}
                minutes. I will prompt you to login 10 minutes before the start
                time. Suggest that you do a system to prepare for the virtual
                campaign
              </div>
              <div className={classes.buttonComponent}>
                <button
                  className={classes.yesNoButton}
                  onClick={(val) => onWelcomeBack(8)}
                >
                  Check my system
                </button>
                <button
                  className={classes.yesNoButton}
                  onClick={(val) => onWelcomeBack(9)}
                >
                  I have completed a system check
                </button>
              </div>
            </div>
          );
        } else {
          console.log(props.unScheduled_campaigns, "unscheduled campaigns");
          console.log(
            props.skippedCampaigns,
            "Skipped campaign chaat components"
          );
          // console.log(props.skippedCampaigns.flag, "Skipped campaign chaat components falg status")
          var campaigns = null;
          if (props.unScheduled_campaigns.flag === true) {
            console.log("Skipped campaigns");

            var message_multiple = "";
            if (props.unScheduled_campaigns.isSingle_campaign === true) {
              message_multiple =
                " You have been shortlisted for the below campaign. Please schedule an interview slot that works best for you. ";
            } else {
              message_multiple =
                " You have been shortlisted for the below campaigns. Please schedule interview slots that works best for you. ";
            }

            campaigns = props.unScheduled_campaigns.Campaings;
            message = (
              <div className={classes.welcomeBackMessage}>
                <div className={classes.wtext}>
                  Hello {sessionStorage.getItem("firstName")} Welcome back and
                  Congratulations! 
                  {message_multiple}
                </div>
                <div className={classes.buttonComponent}>
                  {campaigns.map((res, index) => {
                    return (
                      <>
                        <button
                          className={classes.yesNoButton}
                          onClick={(val) => onScheduleInterview(res)}
                        >
                          {res.heading}
                        </button>
                      </>
                    );
                  })}
                  <button
                    className={classes.yesNoButton}
                    onClick={(val) => onScheduleInterview(1)}
                  >
                    I'll do it later
                  </button>
                </div>
              </div>
            );
          } else if (props.unScheduled_campaigns.flag === false) {
            console.log("No unscheduled campaigns");

            // var messages = [
            //   "Welcome back! I am ready to help you with your career adventure. ",
            //   " I am glad you’re back! I am ready to help you with your career adventure. ",
            //   "Nice to see you back! I am ready to help you with your career adventure. ",
            // ];

            var messages = [
              "Welcome back! I am ready to help you with your career adventure."
            ];

            var message_display =
              messages[Math.floor(Math.random() * messages.length)];

            console.log(message_display, "Messages");

            if (parseInt(progressValue[0]) === 100) {
              if (props.skippedCampaigns === false) {
                var chatPopUp_welcome_2 = true;
                message = (
                  <div className={classes.welcomeBackMessage}>
                    <div className={classes.wtext}>
                      Hello {sessionStorage.getItem("firstName")}{" "}
                      {message_display}
                      {message_job}
                    </div>
                    {
                      message_job == ""?
                      <div className={classes.welcomeBackMessage}>
                        {
                          props.available_campaign === true?
                          <div className={classes.welcomeBackMessage}>
                            <div className={classes.wtext}>
                              There are career campaigns near you that you could apply. Do you want to explore them?
                            </div>
                            <div className={classes.buttonComponent}>
                            <button
                              className={classes.yesNoButton}
                              onClick={(val) => onAvailableCampaign(0)}
                            >
                              Yes
                            </button>
                            <button
                              className={classes.yesNoButton}
                              onClick={(val) => onAvailableCampaign(1)}
                            >
                              No
                            </button>
                            </div>
                          </div>
                        :
                        <div className={classes.wtext}>
                          I can help you with a Career interest assessment to help you understand your best career options, Press YES to continue
                          <div className={classes.buttonComponent}>
                            <button
                              className={classes.yesNoButton}
                              onClick={(val) => onStartCareerTest(0)}
                            >
                              Yes
                            </button>
                            <button
                              className={classes.yesNoButton}
                              onClick={(val) => onStartCareerTest(1)}
                            >
                              No
                            </button>
                          </div>
                        </div>
                      }
                      </div>:
                      <div className={classes.buttonComponent}>
                        <button
                          className={classes.yesNoButton}
                          onClick={(val) => onWelcomeBack(6)}
                        >
                          Yes
                        </button>
                        <button
                          className={classes.yesNoButton}
                          onClick={(val) => onWelcomeBack(7)}
                        >
                          No
                        </button>
                      </div>
                    }
                  </div>
                );
              }
              //applied skipped campaigns reply is temporary
              else if (props.skippedCampaigns.flag === true) {
                console.log("Skipped campaigns true");

                var message_display_new = "";

                if (props.skippedCampaigns.isMultiple === true) {
                  message_display_new =
                    "Welcome back! You have skipped the appling process of the follwing campaigns, Do you want to conitnue? ";
                } else if (props.skippedCampaigns.isMultiple === false) {
                  message_display_new =
                    "Welcome back! You have skipped the appling process of the follwing campaign, Do you want to continue? ";
                }

                var campaigns = props.skippedCampaigns.campaigns;

                message = (
                  <div className={classes.welcomeBackMessage}>
                    <div className={classes.wtext}>
                      Hello {sessionStorage.getItem("firstName")}{" "}
                      {message_display_new}
                    </div>
                    <div className={classes.buttonComponent}>
                      {campaigns.map((res, index) => {
                        return (
                          <>
                            <button
                              className={classes.yesNoButton}
                              onClick={(val) => onSkippedApplyCampaigns(res)}
                            >
                              {res.heading}
                            </button>
                          </>
                        );
                      })}
                      <button
                        className={classes.yesNoButton}
                        onClick={(val) => onWelcomeBack(7)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                );
              } else if (props.skippedCampaigns.flag === false) {

                // var messagesNew = [
                //   "Welcome back! I am ready to help you with your career adventure. ",
                //   "I am glad you’re back! I am ready to help you with your career adventure. ",
                //   "Nice to see you back! I am ready to help you with your career adventure. ",
                // ];

                var messagesNew = [
                  "Welcome back! I am ready to help you with your career adventure."
                ];

                var message_display_new =
                  messagesNew[Math.floor(Math.random() * messagesNew.length)];
                var chatPopUp_welcome_3 = true;
                message = (
                  <div className={classes.welcomeBackMessage}>
                    <div className={classes.wtext}>
                      Hello {localStorage.getItem("firstName")}{" "}
                      {message_display_new}
                      {message_job}
                    </div>
                    {
                      message_job == ""?
                      <div className={classes.welcomeBackMessage}>
                        {
                          props.available_campaign === true?
                          <div className={classes.welcomeBackMessage}>
                            <div className={classes.wtext}>
                              There are career campaigns near you that you could apply. Do you want to explore them?
                            </div>
                            <div className={classes.buttonComponent}>
                            <button
                              className={classes.yesNoButton}
                              onClick={(val) => onAvailableCampaign(0)}
                            >
                              Yes
                            </button>
                            <button
                              className={classes.yesNoButton}
                              onClick={(val) => onAvailableCampaign(1)}
                            >
                              No
                            </button>
                            </div>
                          </div>
                        :
                        <div className={classes.wtext}>
                          I can help you with a Career interest assessment to help you understand your best career options, Press YES to continue
                          <div className={classes.buttonComponent}>
                            <button
                              className={classes.yesNoButton}
                              onClick={(val) => onStartCareerTest(0)}
                            >
                              Yes
                            </button>
                            <button
                              className={classes.yesNoButton}
                              onClick={(val) => onStartCareerTest(1)}
                            >
                              No
                            </button>
                          </div>
                        </div>
                      }
                      </div>:
                      <div className={classes.buttonComponent}>
                        <button
                          className={classes.yesNoButton}
                          onClick={(val) => onWelcomeBack(6)}
                        >
                          Yes
                        </button>
                        <button
                          className={classes.yesNoButton}
                          onClick={(val) => onWelcomeBack(7)}
                        >
                          No
                        </button>
                      </div>
                    }
                    {/* <div className={classes.buttonComponent}>
                      <button
                        className={classes.yesNoButton}
                        onClick={(val) => onWelcomeBack(6)}
                      >
                        Yes
                      </button>
                      <button
                        className={classes.yesNoButton}
                        onClick={(val) => onWelcomeBack(7)}
                      >
                        No
                      </button>
                    </div> */}
                  </div>
                );
              }
            } else {
              console.log("Data default welcome");

              // var messagesNew = [
              //   "Welcome back! I am ready to help you with your career adventure. ",
              //   "I am glad you’re back! I am ready to help you with your career adventure. ",
              //   "Nice to see you back! I am ready to help you with your career adventure. ",
              // ];

              var messagesNew = [
                "Welcome back! I am ready to help you with your career adventure."
              ];

              var message_display_new =
                messagesNew[Math.floor(Math.random() * messagesNew.length)];
              var chatPopUp_welcome_3 = true;
              message = (
                <div className={classes.welcomeBackMessage}>
                  <div className={classes.wtext}>
                    Hello {localStorage.getItem("firstName")}{" "}
                    {message_display_new}
                    {message_job}
                  </div>
                  {
                    message_job == ""?
                    <div className={classes.welcomeBackMessage}>
                      {
                        props.available_campaign === true?
                        <div className={classes.welcomeBackMessage}>
                          <div className={classes.wtext}>
                            There are career campaigns near you that you could apply. Do you want to explore them?
                          </div>
                          <div className={classes.buttonComponent}>
                          <button
                            className={classes.yesNoButton}
                            onClick={(val) => onAvailableCampaign(0)}
                          >
                            Yes
                          </button>
                          <button
                            className={classes.yesNoButton}
                            onClick={(val) => onAvailableCampaign(1)}
                          >
                            No
                          </button>
                          </div>
                        </div>
                      :
                      <div className={classes.wtext}>
                        I can help you with a Career interest assessment to help you understand your best career options, Press YES to continue
                        <div className={classes.buttonComponent}>
                          <button
                            className={classes.yesNoButton}
                            onClick={(val) => onStartCareerTest(0)}
                          >
                            Yes
                          </button>
                          <button
                            className={classes.yesNoButton}
                            onClick={(val) => onStartCareerTest(1)}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    }
                    </div>:
                    <div className={classes.buttonComponent}>
                      <button
                        className={classes.yesNoButton}
                        onClick={(val) => onWelcomeBack(6)}
                      >
                        Yes
                      </button>
                      <button
                        className={classes.yesNoButton}
                        onClick={(val) => onWelcomeBack(7)}
                      >
                        No
                      </button>
                    </div>
                  }
                  {/* <div className={classes.buttonComponent}>
                    <button
                      className={classes.yesNoButton}
                      onClick={(val) => onWelcomeBack(6)}
                    >
                      Yes
                    </button>
                    <button
                      className={classes.yesNoButton}
                      onClick={(val) => onWelcomeBack(7)}
                    >
                      No
                    </button>
                  </div> */}
                </div>
              );
            }
          }
          //new one for error fixes
          else {

            // var messagesNew = [
            //   "Welcome back! I am ready to help you with your career adventure. ",
            //   "I am glad you’re back! I am ready to help you with your career adventure. ",
            //   "Nice to see you back! I am ready to help you with your career adventure. ",
            // ];

            var messagesNew = [
              "Welcome back! I am ready to help you with your career adventure."
            ];

            var message_display_new =
              messagesNew[Math.floor(Math.random() * messagesNew.length)];
            message = (
              <div className={classes.welcomeBackMessage}>
                <div className={classes.wtext}>
                  Hello {localStorage.getItem("firstName")}{" "}
                  {message_display_new}
                  {message_job}
                </div>
                {
                  message_job == ""?
                  <div className={classes.welcomeBackMessage}>
                    {
                      props.available_campaign === true?
                      <div className={classes.welcomeBackMessage}>
                        <div className={classes.wtext}>
                          There are career campaigns near you that you could apply. Do you want to explore them?
                        </div>
                        <div className={classes.buttonComponent}>
                        <button
                          className={classes.yesNoButton}
                          onClick={(val) => onAvailableCampaign(0)}
                        >
                          {/* //apply_for_campaign */}
                          Yes 
                        </button>
                        <button
                          className={classes.yesNoButton}
                          onClick={(val) => onAvailableCampaign(1)}
                        >
                          {/* No exit */}
                          No
                        </button>
                        </div>
                      </div>
                    :
                    <div className={classes.wtext}>
                      I can help you with a Career interest assessment to help you understand your best career options, Press YES to continue
                      <div className={classes.buttonComponent}>
                        <button
                          className={classes.yesNoButton}
                          onClick={(val) => onStartCareerTest(0)}
                        >
                          Yes
                        </button>
                        <button
                          className={classes.yesNoButton}
                          onClick={(val) => onStartCareerTest(1)}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  }
                  </div>:
                  <div className={classes.buttonComponent}>
                    <button
                      className={classes.yesNoButton}
                      onClick={(val) => onWelcomeBack(6)}
                    >
                      Yes
                    </button>
                    <button
                      className={classes.yesNoButton}
                      onClick={(val) => onWelcomeBack(7)}
                    >
                      No
                    </button>
                  </div>
                }
                {/* <div className={classes.buttonComponent}>
                  <button
                    className={classes.yesNoButton}
                    onClick={(val) => onWelcomeBack(6)}
                  >
                    Yes
                  </button>
                  <button
                    className={classes.yesNoButton}
                    onClick={(val) => onWelcomeBack(7)}
                  >
                    No
                  </button>
                </div> */}
              </div>
            );
          }
        }
      } else {
        message = (
          <div className={classes.Message}>
            Sorry, I'm not sure I've understood you correctly, Do you need
            anything else?
            <div>
              <button
                className={classes.yesNoButton}
                onClick={(val) => onChatResponseNull(1)}
              >
                Yes
              </button>
              <button
                className={classes.yesNoButton}
                onClick={(val) => onChatResponseNull(0)}
              >
                No
              </button>
            </div>
          </div>
        );
      }
      //  message = (
      //   <div className={classes.Message}>
      //     I'm not feeling great today, will be back soon !
      //      {/* <div>
      //       <button
      //         className={classes.yesNoButton}
      //         onClick={(val) => onChatResponseNull(1)}
      //       >
      //         Yes
      //       </button>
      //       <button
      //         className={classes.yesNoButton}
      //         onClick={(val) => onChatResponseNull(0)}
      //       >
      //         No
      //       </button>
      //     </div>  */}
      //   </div>
      //  );      
    }

    if (messageLength > 0) {
      console.log(props.time, "Props time chat")
      console.log(props.welcomeback, "Props welcome > 0");
      var json = props.message;
      json = json["text"];
      if (
        props.welcomeback === true &&
        localStorage.getItem("CALL_STATUS") === false
      ) {
        var progressValue = [
          ObjectChecker(props.candidateDate, ["Progress", "value"]),
        ];

        console.log(progressValue[0], "Progress value");

        if (props.interviewTime !== null) {
          var chatPopUp_welcome = true;
          console.log(props.interviewTime["Campaign"], "props interview time");
          message = (
            <div className={classes.welcomeBackMessage}>
              <div className={classes.wtext}>
                Hello {sessionStorage.getItem("firstName")} Welcome back! I am
                sure you’re excited to join {props.interviewTime["Campaign"]}{" "}
                which is due to start in {20} minutes. I will prompt you to
                login 15 minutes early. Could I please help you with anything
                else?
              </div>
              <div className={classes.buttonComponent}>
                <button
                  className={classes.yesNoButton}
                  onClick={(val) => onWelcomeBack(0)}
                >
                  Yes
                </button>
                <button
                  className={classes.yesNoButton}
                  onClick={(val) => onWelcomeBack(1)}
                >
                  No
                </button>
              </div>
            </div>
          );
        } else {
          console.log(props.unScheduled_campaigns, "unscheduled campaigns");
          if (props.unScheduled_campaigns === true) {
            message = (
              <div className={classes.welcomeBackMessage}>
                <div className={classes.wtext}>
                  Hello {sessionStorage.getItem("firstName")} Welcome back! You
                  have unscheduled campaigns, Do you want to look at your
                  campaings to schedule
                </div>
                <div className={classes.buttonComponent}>
                  <button
                    className={classes.yesNoButton}
                    onClick={(val) => onScheduleInterview(0)}
                  >
                    Yes
                  </button>
                  <button
                    className={classes.yesNoButton}
                    onClick={(val) => onScheduleInterview(1)}
                  >
                    No
                  </button>
                </div>
              </div>
            );
          } else if (props.unScheduled_campaigns === false) {

            // var messages = [
            //   "Welcome back! I am ready to help you with your career adventure. ",
            //   " I am glad you’re back! I am ready to help you with your career adventure. ",
            //   "Nice to see you back! I am ready to help you with your career adventure. ",
            // ];

            var messages = [
              "Welcome back! I am ready to help you with your career adventure."
            ];

            var message_display =
              messages[Math.floor(Math.random() * messages.length)];

            if (parseInt(progressValue[0]) === 100) {
              var chatPopUp_welcome_2 = true;
              message = (
                <div className={classes.welcomeBackMessage}>
                  <div className={classes.wtext}>
                    Hello {sessionStorage.getItem("firstName")}{" "}
                    {message_display}
                    {message_job}
                  </div>
                  <div className={classes.buttonComponent}>
                    <button
                      className={classes.yesNoButton}
                      onClick={(val) => onWelcomeBack(0)}
                    >
                      Yes
                    </button>
                    <button
                      className={classes.yesNoButton}
                      onClick={(val) => onWelcomeBack(1)}
                    >
                      No
                    </button>
                  </div>
                </div>
              );
            } else {
              var chatPopUp_welcome_3 = true;
              message = (
                <div className={classes.welcomeBackMessage}>
                  <div className={classes.wtext}>
                    Hello {sessionStorage.getItem("firstName")}I can see your
                    profile completeness is {progressValue[0]}% and it’s
                    important that you complete it. Can I please request you to
                    complete your profile now?
                  </div>
                  <div className={classes.buttonComponent}>
                    <button
                      className={classes.yesNoButton}
                      onClick={(val) => onWelcomeBack(2)}
                    >
                      Yes, Take me to my profile
                    </button>
                    <button
                      className={classes.yesNoButton}
                      onClick={(val) => onWelcomeBack(3)}
                    >
                      No, remind me again later
                    </button>
                  </div>
                </div>
              );
            }
          }
        }
      } else {
        console.log("Default messages");

        message = JSON.parse(props.message).map((res, i) => {
          var recipientId = res.recipient_id;
          var msg = res.text;
          var CampaignPoster = false;
          var styleClass = classes.Message;
          question = res.text;

          let messages = (
            <Fragment key={i}>
              {res.text.length !== 0 ? (
                <div>
                  <div
                    className={styleClass}
                    dangerouslySetInnerHTML={{
                      __html: hasDate(msg, classes.IsDate),
                    }}
                  />
                  {CampaignPoster === true ? (
                    <img
                      style={{ paddingLeft: "50px" }}
                      width="500px"
                      src="https://csuite-hr-bucket.s3.ap-south-1.amazonaws.com/hbSpuizDqN7aFDCzIz3qHctqqOakhpOu.png"
                    ></img>
                  ) : null}
                </div>
              ) : null}

              {res.buttons && props.buttonStatus ? (
                <div className={classes.buttonComponent}>
                  {res.buttons.map((button, i) => {
                    let payloadValue = button.payload;
                    console.log(payloadValue, "Payload value");

                    payValue = payloadValue;

                    if (payValue === "campaign_reference") {
                      localStorage.setItem("CAMPAIGN_FOR_MANAGE", payValue);
                    }

                    var dataLabel = "";
                    var dataValue = "";
                    var payloadLable = button.title;

                    let buttonType = (
                      <button
                        disabled={props.onDisabled}
                        key={i}
                        onClick={() =>
                          props.handlePayload(payloadValue, payloadLable)
                        }
                        dangerouslySetInnerHTML={{
                          __html: hasDate(button.title),
                        }}
                      />
                    );

                    if (isXml(payloadValue)) {
                      let xml = XMLReader.parseSync(payloadValue);
                      const payloadType = xmlQuery(xml).find("type").text();

                      payType = payloadType;
                      if (payloadType === "campaign_id_pre") {
                        console.log("if condition payload type");
                        var payloadData = xmlQuery(
                          XMLReader.parseSync(payloadValue)
                        )
                          .find("key")
                          .text();
                        console.log(payloadData, "campaign data");
                        campaignData.push(payloadData);
                        buttonType = <Fragment key={i}></Fragment>;
                      }

                      if (payloadType === "applied_ref_data") {
                        console.log("applied ref");
                        var ref = xmlQuery(XMLReader.parseSync(payloadValue))
                          .find("key")
                          .text();
                        console.log(ref, "Reference");
                        localStorage.setItem("interview_ref", ref);
                      }

                      var value = xmlQuery(XMLReader.parseSync(payloadValue))
                        .find("value")
                        .text();

                      if (value === "Started_video_interview") {
                        var interviewRef = localStorage.getItem(
                          "interview_ref"
                        );
                        //window.open(process.env.REACT_APP_URL+"?ref="+interviewRef, "_blank")
                        dataValue = true;
                        localStorage.setItem("Open_video_conf_sia", true);
                        sessionStorage.setItem("ref", ref);
                      }
                    }
                    return buttonType;
                  })}
                </div>
              ) : null}
            </Fragment>
          );

          console.log(messages, "messages chat reply");

          return messages;
        });
      }
    }
  }

  const userImage = localStorage.getItem("chat-userimage")
    ? localStorage.getItem("chat-userimage")
    : IconUser;

  //Temperary until linkedIn issue fixed
  const userName = localStorage.getItem("chat-username-temp")
    ? localStorage.getItem("chat-username-temp")
    : null;
  var campData = "";
  var siaImage = <img src={siaSmall} />;

  var message_show = message;

  return (
    <div className={classList.join(" ")}>
      <div className={classes.ProfileData}>
        <span
          style={{
            backgroundImage: `url('${
              props.type === "query" ? userImage : IconSia
            }')`,
          }}
        ></span>
        <strong className={classes.Name}>
          {props.type === "query" ? userName : siaImage}
        </strong>
        {/* <small>
          {props.type === 'query' ?
          moment(props.time).fromNow() : null
          }
        </small> */}
        {/* <small>{props.time}</small> */}
        <small>{
          props.type == "query"?
          moment(props.time).fromNow(): null
          }</small>
      </div>

      {payValue == "Proceed_with_this_video" ? <ViewVideo /> : null}
      {payValue == "go_back_from_campaign_video_pitch" ? (
        <ViewCoverLetter />
      ) : null}  
      {payType == "campaign_id_pre" ? (
        <div>
          <CampaignsList campaignData={campaignData} />
          <button
            className={classes.campListBack}
            onClick={handleGobackFromCampaign}
          >
            Go back
          </button>
        </div>
      ) : (
        message_show
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    campaignFinder: state,
    profile: state.profile.userProfile,
    welcomeback: state.chatnew.welcomeback,
    interviewTime: state.chatnew.interviewTime,
    initialStartCnvsation: state.chatnew.initialStartCnvsation,
    unScheduled_campaigns: state.chatnew.unscheduled_campaigns,
    candidateDate: state.CandidateData.userData,
    closeChatComState: state.chatnew.closeChatComponent,
    skippedCampaigns: state.auth.skipppedCampaigns,
    loggedTime : state.chatnew.loggedTime,
    available_campaign : state.chatnew.available_campaign
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenChatModal: (data) => dispatch(actionCreater.OpenModalChat(data)),
    onOpenCampaignFinder: (data) =>
      dispatch(actionCreater.OpenCampaignFinder(data)),
    onApplyCampaign: (data) => dispatch(actionCreater.onApplyCampaign(data)),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(actionCreater.getChatResponseNew(message, status, notification)),
    onCloseChat: (data) => dispatch(actionCreater.CloseChatComponent(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(chatComponent);
