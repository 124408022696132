import React, { Component } from "react";
import Progressbar from "react-progressbar";
import "./Progress.scss";
import ObjectChecker from '../../../hoc/objectChecker'

class Progress extends Component {
  render() {  
    var progressValue = [ObjectChecker(this.props.data, ['Progress', 'value'])]
    var progressData = [ObjectChecker(this.props.data, ['Progress', 'NextStep'])]
    let value = 75;
    let nextValue = "Upload image";
    return (
      <div className="profile-completeness">
        <div className="progress-title">Profile completeness</div>
        <div className="bar">
          <Progressbar completed={progressValue} />
        </div>
        <div className="text-below-bar">
          <div>{progressValue} % completed</div>
          <div>
            <span className="next">Next: </span>
            {progressData}
          </div>
        </div>
      </div>
    );
  }
}
export default Progress;
