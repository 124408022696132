import React, { Component } from "react";
import classes from "./EducationInfo.module.scss";
import EduData from "../EducationInfo/EducationData/Data";
import { connect } from "react-redux";
import * as actionsCreator from "../../../../store/actions/index";

class EduEachData extends Component {
  constructor() {
    super();
  }

  deleteItem=(index)=>{
    this.props.deleteItemEduc(index)
  }
  editEducation=(index)=>{
    this.props.editEducation(index)
  }
  render() {
    var aray = this.props.data;
    let eduData = aray.map((res, index) => {
      return <EduData editEducation={this.editEducation} deleteItemEdu={this.deleteItem} data={res} dataIndex={index} key={index} />;
    });
    return (  
      <div>
        <ul>{eduData}</ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    phoneNumber: state.auth.phoneNumber,
    location: state.auth.location,
    cvString: state.auth.cvString,
    cvDataExp: state.auth.cvDataExp,
    cvDataEdu: state.auth.cvDataEdu,
    deleteID: state.auth.itemListId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteItem: (data) => dispatch(actionsCreator.deleteItem(data)),
    arrayList: (data) => dispatch(actionsCreator.arrayListExp(data)),
  };
};

// export default EduEachData;
export default connect(mapStateToProps, mapDispatchToProps)(EduEachData);
