import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoginForm from "../PasswordLessLogin/LoginForm";
import * as actions from "../../store/actions/index";
import classes from "./Login.module.scss";
import wallImg from "../../assets/image/wall.png";
import Logo from "../../assets/image/sia-logo.png";
import moment from "moment";
import axios from "../../axios";


export class Login extends Component {
  constructor() {
    super();
    this.state = {
      events:[]
    };
  }
componentDidMount(){
 // this.getGeoInfo()
}
getGeoInfo = () => {
  axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      let data = response.data;
      this.getEventLiveEvents(data.timezone)
    })
    .catch((error) => {
      console.log(error);
    });
};

  getEventLiveEvents = (timeZone) => {
    var date = moment.utc().toISOString();
    axios.get("get-live-events/?logDate=" + date + "&timeZone=" + timeZone, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        this.state.events = res.data.Data.events;
        this.setState({ isData: true })
      })
      .catch((err) => {
      });
  }
  render() {
    return (
      <div className={classes.Login}>         
        <div className={classes.LoginBox}>
          {/* <img src={Logo} alt="Sia Logo" /> */}
          <div className={classes.borderBox}>
            <LoginForm
              errorMessages={this.props.errorMessages}
              loading={this.props.loading}
              userLogin={loginData => this.props.onUserLogin(loginData)}
              className={classes.form}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    loginStatus: state.auth.loginStatus,
    loading: state.auth.loading,
    errorMessages: state.auth.errorMessages,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUserLogin: data => dispatch(actions.userLogin(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
