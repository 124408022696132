import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Faq.module.scss";
import * as actions from "../../../../store/actions/index";
import FaqPic from "../../../../assets/image/faq.svg";
import { Accordion, Icon } from "semantic-ui-react";
import FAQSection from "./FAQSection";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   flag: true,
      //   question: "",
      activeIndex: -1,
      faq: [
        {
          title: "Sia FAQ's",
          content: [
            {
              q: "What is Sia?",
              a: `Sia is an AI-powered digital career assistant designed to provide career enriching everyday engagements to young people and to help them achieve their full potential.
                Sia helps young career seekers to explore career options and to apply for a variety of career campaigns offered by affiliated organizations. Sia has the ambition to provide personalised career guidance to every young person on this planet one day.`,
            },
            {
              q: "What is a smart CV?",
              a:
                "Smart CV is the career profile that is maintained by Sia and used by organisations to screen the best applicants to ongoing career campaigns. It uses the LinkedIn profile uploaded during user profile creation and consists of three key areas skill, education qualifications, and work experiences. User has the complete responsibility to update, maintain and improve the smart CV which will help them be ahead of other candidates applying for campaigns.",
            },
            {
              q:
                "Why am I asked to upload the CV even I am maintaining the smart CV?",
              a:
                "We prefer campaigns to only use smart CVs which eliminates the times taken to create a CV. However, certain Organisations prefer to request CVs as part of their screening process. It is entirely the Organisations’s choice to ask for a CV as well as Video pitch the cover letter. ",
            },
            {
              q: "Is it mandatory to upload a video pitch?",
              a:
                "The campaign application process is completely customised by organisations, and it is mandatory to follow the defined application process to apply for every ongoing campaign.",
            },
            {
              q: "How can I track the status of my campaign applications?",
              a:
                "All your ongoing campaigns can be found in the dashboard, and you can simply track the progress with a single click. Campaign details, applications status, and the activity log provide users complete transparency of every enrolled campaign.",
            },
            {
              q: "Can candidates fix the time for their interviews?",
              a:
                "Yes, once you get shortlisted from the campaign which you have applied, you will be able to fix the time according to your preference. If candidates face any inconvenience with the scheduled time, they can reschedule their interview before the due date.",
            },
            {
              q: "How does an onsite campaign work with Sia?",
              a:
                "With the use of advanced technology Sia will promptly handle the application process, coordination, and the communication of any traditional offline campaign.",
            },
            {
              q: "What is special about Sia Virtual campaigns?",
              a:
                "Sia Virtual campaigns are designed to provide a near-physical user experience to some of the most complex career engagements. It has waiting rooms with switchable screen, webinar features whilst waiting, Queue management, group chat and interviewer chat to provide the most advanced user experience to you as users. ",
            },
            {
              q:
                "What happens if I am early or late for a scheduled virtual interview?",
              a:
                "Sia is loaded with all smart features to provide a seamless experience to all candidates in a virtual recruitment set-up. If you are early to an interview, you will have the comfort of a waiting room, and if webinar features enabled, you would have adequate entertainment to occupy you. If you are late, sia will try to contact you through e- mails, SMS, or using the chat.If everything fails, you will be rescheduled with the Recruiter's consent and will place you in the queue. Most importantly, you will have complete visibility on where you are in the candidate queue, and Sia will always be there to help you every step of the way.",
            },
            {
              q:
                "If I am interviewed through the platform is it guaranteed that I get a feedback?",
              a:
                "We believe feedback is a critical part of your career development. If the career campaign you have applied includes a screening or selection step, it is guaranteed that you receive feedback from the affiliated organisation.  ",
            },
            {
              q: "Who has access to my personal data?",
              a:
                "Your personal data are secured with us, and only the organisations you apply will have access to your data submitted using your knowledge. It is important to us that you have full control of your personal data.",
            },
            {
              q: "Can Sia predict my career path?",
              a:
                "Today, Sia chat provides access to a career test based on the ‘Hollond code’ which refers to the theory of careers and vocational choice that was initially developed by American psychologist John L. Hollond. This provides a visibility to potential career options based on standard list of questions answered through the platform. However, Sia is equipped with machine learning that improves the accuracy of the career prediction over time.",
            },
          ],
        },
      ],
    };
  }
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  onClose = () => {
    this.props.closeModal();
    this.props.closeModalFAQ(true);
    this.props.onGetChatResponseNew("/ask_faq_later", false);
    localStorage.setItem("faq_question_modal", false);
    //onClick={this.props.closeModal}
  };

  //   onSend = () => {
  //     if (this.props.candidateDate !== null) {
  //       var candidateId = this.props.candidateDate.userData.profile[0].user_id;
  //       this.props.closeModal();
  //       this.props.closeModalFAQ(false);
  //       var data = {
  //         question: this.state.question,
  //         Candidate_id: candidateId,
  //         submitDate: "2011-04-11T10:20:30Z",
  //       };
  //       this.props.onSubmitFAQ(data);
  //       this.props.onGetChatResponseNew("/send_faq_now", false);
  //       localStorage.setItem("faq_question_modal", false);
  //     }
  //   };
  //   handelOnChange = (evt) => {
  //     var value = evt.target.value;
  //     this.setState({
  //       [evt.target.name]: value,
  //     });
  //   };

  render() {
    const { activeIndex } = this.state;
    // return(
    //     <div>
    //         FAQ
    //         <div><textarea onClick={this.handelOnChange} name={this.state.question}>Question</textarea></div>
    //         <div><button onClick={this.onSend}>send</button></div>
    //         <div><button onClick={this.onClose}>Ask later</button></div>
    //     </div>
    // )
    return (
      <div className={classes.faqWrapper}>
        <button className={classes.closeModal} onClick={this.onClose}>
          <i className="fas fa-times" />
        </button>
        <div className={classes.faqInner}>
          <h1>
            <img src={FaqPic} />
            <span>FAQ&apos;s</span>
          </h1>
          {this.state.faq.map((res, index) => (
            <FAQSection key={index} data={res} />
          ))}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    candidateDate: state.CandidateData.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    closeModalFAQ: (data) => dispatch(actions.cloaseModalFAQ(data)),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(actions.getChatResponseNew(message, status, notification)),
    onSubmitFAQ: (data) => dispatch(actions.SubmitFQA(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Faq);
