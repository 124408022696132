import React from 'react'
import { Link } from 'react-router-dom'
import classes from './Menu.module.scss'

const Menu = (props) => {
    return (
        <span className={classes.Logout} >
            <Link to="/auth" onClick={props.logout}  >Logout</Link>
        </span>
    )
}


export default Menu

