import React from "react";
import magnifierIcon from "../../../assets/image/magnifier.svg";
import CampaignsListModal from "../../Modals/CampaignsListModal/CampaignsListModal";
import EachCampaignModal from "../../Modals/EachCampaignModal/EachCampaignModal";
import moment from "moment";
import classes from "./ChatComponent.module.scss";
import defaultCamImage from "../../../assets/image/sia_default.png";


class CampaignsList extends React.Component {
  state = {
    listModalIsOpen: false,
    currentNum: 0,
    eachModalIsOpen: false,
    fadeLeftNav: false,
    fadeRightNav: false,
    modalData: {
      id: "",
      heading: "",
      campaignImage: "",
      startDate: "",
      location: "",
      description: "",
      campaignsListLength: "",
      company: "",
      companyLogo: "",
      campaignType: "",
    },
    allCampaignsData: "",
  };

  handleOpenListModal = () => {
    this.setState(
      {
        eachModalIsOpen: false,
        listModalIsOpen: true,
      },
      () =>
        console.log(
          "HADLE",
          this.state.listModalIsOpen,
          this.state.eachModalIsOpen
        )
    );
  };
  handleCloseListModal = () => {
    this.setState({ listModalIsOpen: false });
  };
  handleOpenEachModal = (num) => {
    var data = this.props.campaignData[num].split("_");
    console.log(data, "123x");
    var modalDataa = {
      id: data[0],
      heading: data[6],
      campaignImage: data[4],
      startDate: moment(data[1]).format("YYYY-MM-DD"),
      location: data[5],
      description: data[3],
      campaignsListLength: this.props.campaignData.length,
      company: data[8],
      companyLogo: data[7],
      campaignType: data[9],
    };
    this.setState(
      {
        modalData: modalDataa,
        currentNum: num,
      },
      () => {
        this.setState({
          eachModalIsOpen: true,
        });
        console.log("CAMPAIGNSlist", this.props.campaignData);
      }
    );
  };

  handleNextButton = () => {
    if (this.state.currentNum + 1 < this.props.campaignData.length) {
      var newNum = this.state.currentNum + 1;
      var data = this.props.campaignData[newNum].split("_");
      var modalDataa = {
        id: data[0],
        heading: data[6],
        campaignImage: data[4],
        startDate: moment(data[1]).format("YYYY-MM-DD"),
        location: data[5],
        description: data[3],
        campaignsListLength: this.props.campaignData.length,
        company: data[8],
        companyLogo: data[7],
        campaignType: data[9],
      };
      this.setState(
        {
          modalData: modalDataa,
          currentNum: this.state.currentNum + 1,
          fadeRightNav: false,
          fadeLeftNav: false,
        },
        () => {
          if (this.state.currentNum + 1 === this.props.campaignData.length) {
            this.setState({
              fadeRightNav: true,
            });
          }
        }
      );
    }
  };

  handlePrevButton = () => {
    if (this.state.currentNum > 0) {
      var data = this.props.campaignData[this.state.currentNum - 1].split("_");
      var modalDataa = {
        id: data[0],
        heading: data[6],
        campaignImage: data[4],
        startDate: moment(data[1]).format("YYYY-MM-DD"),
        location: data[5],
        description: data[3],
        campaignsListLength: this.props.campaignData.length,
        company: data[8],
        companyLogo: data[7],
        campaignType: data[9],
      };
      this.setState(
        {
          modalData: modalDataa,
          currentNum: this.state.currentNum - 1,
          fadeLeftNav: false,
          fadeRightNav: false,
        },
        () => {
          if (this.state.currentNum === 0) {
            this.setState({
              fadeLeftNav: true,
            });
          }
        }
      );
    }
  };

  handleCloseEachModal = () => {
    this.setState({ eachModalIsOpen: false });
  };

  render() {
    const noOfSlides = 2;
    return (
      <div className={classes.campaignsSlideWrap}>
        <div className={classes.campaignsSlide}>
          {this.props.campaignData.slice(0, noOfSlides).map((res, i) => {
            var data = res.split("_");

            localStorage.setItem("Campaign_ID", data[0]);
            return (
              <div
                key={i}
                className={classes.eachCampaign}
                // onClick={val => onApplyCampaign(data[0])}
                onClick={() => this.handleOpenEachModal(i)}
              >
                <img
                  className={classes.campImagee}
                  src={data[4] ? data[4] : defaultCamImage}
                />
                <div className={classes.campName}>
                  <div className={classes.text}>{data[6]}</div>
                </div>
                <div className={classes.campData}>
                  <div className={classes.data}>
                    {moment(data[1]).format("YYYY-MM-DD")}
                  </div>
                  <div className={classes.location}>{data[5]}</div>
                </div>
              </div>
            );
          })}
          <EachCampaignModal
            data={this.state.modalData}
            applyButton={true}
            handleNextButton={this.handleNextButton}
            handlePrevButton={this.handlePrevButton}
            fadeLeftNav={this.state.fadeLeftNav}
            fadeRightNav={this.state.fadeRightNav}
            handleOpenEachModal={this.handleOpenEachModal}
            handleCloseEachModal={this.handleCloseEachModal}
            eachModalIsOpen={this.state.eachModalIsOpen}
            campaignsData={this.props.campaignData}
            currentNum={this.state.currentNum}
            // listModalIsOpen={this.state.listModalIsOpen}
            // handleOpenListModal={this.handleOpenListModal}
            // handleCloseListModal={this.handleCloseListModal}
          />
          {this.props.campaignData.length >= 3 && (
            <div
              className={classes.eachCampaign}
              // onClick={this.handleOpenListModal}
            >
              <div className={classes.magnifierDiv}>
                <img className={classes.magnifier} src={magnifierIcon} />
              </div>

              <div className={classes.campCount}>
                {this.props.campaignData.length} campaigns found
              </div>
              <a
                className={classes.showAllLink}
                onClick={this.handleOpenListModal}
              >
                SHOW ALL
              </a>
              <CampaignsListModal
                closeParentModal={false}
                classes={classes.showAllLinkButton}
                campaignData={this.props.campaignData}
                isOpen={this.state.listModalIsOpen}
                handleOpenListModal={this.handleOpenListModal}
                handleCloseListModal={this.handleCloseListModal}
                // props below are used by the eachCampaignModal inside this
                data={this.state.modalData}
                applyButton={true}
                handleNextButton={this.handleNextButton}
                handlePrevButton={this.handlePrevButton}
                handleOpenEachModal={this.handleOpenEachModal}
                handleCloseEachModal={this.handleCloseEachModal}
                eachModalIsOpen={this.state.eachModalIsOpen}
                allCampaignsData={this.props.campaignData}
                currentNum={this.state.currentNum}
                fadeLeftNav={this.state.fadeLeftNav}
                fadeRightNav={this.state.fadeRightNav}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CampaignsList;
