import React from "react";
import Modal from "react-modal";
import Stepper from "react-stepper-horizontal";
import "./CampaignDashboard.scss";
import moment from "moment";
import EachActivity from "./EachActivity";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import wall from "../../../assets/image/placeholderWall.png";
import defaultCompany from "../../../assets/image/sia-in-box.png";
import defaultCamImage from "../../../assets/image/sia_default.png";

class CampaignDashboard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      completedSteps: [
        "Applied for the campaign",
        "Shortlisted",
        "Interview 2",
        "Interview 2",
        "Interview 2",
        "Interview 2",
      ],
    };
    this.xtext = React.createRef();
  }
  render() {
    var processStep = [];
    var activityLog = [];
    var heading=null;
    var startDate=null;
    var campaignImage=null;
    var description=null
    
    try {
      processStep = this.props.processData.Data.candidate_process
      activityLog = this.props.processData.Data.activity_log
      heading=this.props.processData.Data.CampaignData.heading
      startDate= this.props.processData.Data.CampaignData.startDate
      campaignImage= this.props.processData.Data.CampaignData.campaignImage
      description= this.props.processData.Data.CampaignData.description


    } catch {
      processStep = [];
      activityLog = [];
      heading= null
      startDate=null
      campaignImage=null
      description=null

    }
    var imgURL = "";
    imgURL =
      this.props.data.type === 5
        ? wall
        : campaignImage
        ? campaignImage
        : defaultCamImage;
    return (
      <div className="Campaign-dash-modalInChat">
        <Modal
          isOpen={this.props.eachModalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.props.handleCloseEachModal}
          contentLabel="Example Modal"
          className="full-screen-modal-1"
          ariaHideApp={false}
        >
          <div className="close-div">
            <button onClick={this.props.handleCloseEachModal}>
              <i className="fas fa-times" />
            </button>
          </div>
          <div className="campaign-dash-wrapper">
            <div className="campaign-dash-inner">
              <div className="row1">
                <img
                  src={
                    this.props.data.companyLogo
                      ? this.props.data.companyLogo
                      : defaultCompany
                  }
                />
                <div>
                  <div className="camp-title">{heading}</div>
                  <div className="camp-function">
                    Information Technology |{" "}
                    {moment(startDate).format("DD-MM-YYYY")}
                  </div>
                </div>
              </div>
              <div className="progress-row">
                <div className="progressRowInner">
                  {processStep.filter((e) => e.current_state === false).length >
                  0 ? (
                    // when process ongoing (has false)
                    processStep.map((res, index) => {
                      // console.log("RRR", processStep, " ", index);
                      return processStep.filter(
                        (e) => e.current_state === false
                      ).length > 0 ? (
                        <div
                          className="storyEach ee1"
                          style={{ flexBasis: `${100 / processStep.length}%` }}
                        >
                          <div className="storyTop">
                            <div
                              className={
                                res.current_state
                                  ? `storyNumberBlue`
                                  : `storyNumberGrey`
                              }
                            >
                              {index + 1}
                            </div>
                            <div
                              className={
                                res.current_state
                                  ? `storyLine`
                                  : `storyLineDashes`
                              }
                            ></div>
                          </div>
                          <span className="storyText">{res.message}</span>
                        </div>
                      ) : (
                        <div
                          className="storyEach ee2"
                          style={{
                            flexBasis: `calc(100% / ${processStep.length} )`,
                          }}
                        >
                          <div className="storyTop">
                            <div className="storyNumberBlue">{index + 1}</div>
                            {index < processStep.length - 1 ? (
                              <div className="storyLine" />
                            ) : (
                              ""
                            )}
                          </div>
                          <span className="storyText">{res.message}</span>
                        </div>
                      );
                    })
                  ) : (
                    // when all steps are complete (has no false)
                    <Stepper
                      steps={processStep.map((e) => {
                        return { title: `${e.message}` };
                      })}
                      titleFontSize={processStep.length > 3 ? 13 : 14}
                      circleFontSize={13}
                      size={28}
                      completeTitleColor="#575757"
                      activeTitleColor="#575757"
                      defaultBarColor="#ccc"
                      completeBarColor="#ccc"
                      activeBorderStyle="dashed"
                      activeStep={
                        processStep.filter((e) => e.current_state === false)
                          .length > 0
                          ? processStep.length - 2
                          : processStep.length - 1
                      }
                    />
                  )}
                </div>
              </div>
              <div className="row3">
                <div className="left-image">
                  <div className="image-div">
                    <img className="background" src={imgURL} />
                    {/* <img className="foreground" src={imgURL} /> */}
                  </div>
                  {/* <textarea
                    ref={this.xtext}
                    id="candidate-descr-textareac"
                    className="descr-text"
                    readOnly
                    defaultValue={this.props.data.description}
                    className="descr-div"
                  /> */}                  
                  <div style={{marginTop:"5px"}} dangerouslySetInnerHTML={{ __html:description }} />
                </div>
                <div className="right-activity">
                  <div className="activityTitle">Activity log</div>
                  <div className="activityList">
                    {activityLog.map((res, index) => {
                      return <EachActivity key={index} data={res} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    processData: state.CampaignByRef.campaignProcessData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignDashboard)
);
