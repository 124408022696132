import React, { Component } from "react";
import { connect } from "react-redux";
import $ from 'jquery';
import classes from "./VideoChat.module.scss";
import SyncClient from 'twilio-sync';

class WhiteBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stream: null,
      selectColor:'white'
    }
    this.canvasRef = React.createRef();
  }

  current = {
    color: 'black'
  };

  drawing = false;
  colorBtn = $('#color-btn');
  syncStream = ""

  componentDidMount() {
    this.loadCanvas()
    this.createSyncClient()
  }
  createSyncClient = () => {
    var syncClient = new SyncClient(this.props.token, { logLevel: 'info' });
    syncClient.stream('drawingData').then(this.syncStreamClient, (error) => {

    });
  }

  syncStreamClient = (stream) => {
    this.syncStream = stream
    this.syncStream.on('messagePublished', this.syncDrawingData)
  }

  syncDrawingData = (message) => { 

    var data = message.message.value
    console.log(data,"drow line")

    const canvas = this.canvasRef.current;
    var w = canvas.width;
    var h = canvas.height;
    this.drawLine(data.x0 * w, data.y0 * h, data.x1 * w, data.y1 * h, data.color);
    this.props.openModal()
  }

  loadCanvas = () => {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
   // context.fillRect(0, 0, canvas.width, canvas.height);

    canvas.addEventListener('mousedown',this.onMouseDown);
    canvas.addEventListener('mouseup', this.onMouseUp);
    canvas.addEventListener('mouseout', this.onMouseUp);
    canvas.addEventListener('mousemove',this.throttle(this.onMouseMove,10));
  //  window.addEventListener('resize', this.onResize);
  
  }

  throttle = (callback, delay) => {
    var previousCall = new Date().getTime();
    return function () {
      var time = new Date().getTime();

      if ((time - previousCall) >= delay) {
        previousCall = time;
        callback.apply(null, arguments);
      }
    };
  }

  drawLine = (x0, y0, x1, y1, color, syncStream) => {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    context.beginPath();
    // context.moveTo(x0, y0);
    //context.lineTo(x1, y1);
    context.arc(
      x0,
      y0,
      10,
      0,
      2 * Math.PI,
      false);
    context.fillStyle = color;
    context.fill();
    context.strokeStyle = color;
    context.lineWidth = 2;
    context.stroke();
    context.closePath();

    if (syncStream) {  
      var w = canvas.width;
      var h = canvas.height;

      syncStream.publishMessage({  // publish the drawing data to Twilio Sync server
        x0: x0 / w,
        y0: y0 / h,
        x1: x1 / w,
        y1: y1 / h,
        color: color
      });
    }

  }

  onMouseDown = (e) => {
    this.drawing = true;
    this.current.x = e.clientX;
    this.current.y = e.clientY;
  }

  onMouseUp = (e) => {
    if (!this.drawing) { return; }
    this.drawing = false;
    this.drawLine(this.current.x, this.current.y, e.clientX, e.clientY, this.current.color, this.syncStream);
  }

  onMouseMove = (e) => {
    if (!this.drawing) { return; }
    this.drawLine(this.current.x, this.current.y, e.clientX, e.clientY, this.current.color, this.syncStream);
    this.current.x = e.clientX;
    this.current.y = e.clientY;
  }

  onResize = () => {
    const canvas = this.canvasRef.current; 
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  };

  changeColor = () => {
    this.current.color = '#' + Math.floor(Math.random() * 16777215).toString(16);  // change line color
    this.setState({selectColor:this.current.color})
  };
  clearBoard = () => {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  render() {
    return (
      <div>
        <div className={classes.buttons}>
          <button style={{border: '3px solid ' + this.current.color}} id="color-btn" className={classes.btn} onClick={this.changeColor}>Change Color</button>
          <button id="clear-btn" className={classes.btn} onClick={this.clearBoard}>Clear</button>
        </div>
        <canvas style={{ border: '1px solid black'}}  width="1200" height="550" ref={this.canvasRef} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhiteBoard);