import Moment from 'moment'
import isISODate from 'is-iso-date'

const isDate = (text, dateClass) => {
  
    
    const itDate = Date.parse(text);

    var isDate = Moment(text,"YYYY-MM-DD",true).isValid();
    var isDateTime = Moment(text,"YYYY-MM-DDTHH:mm:ss",true).isValid();   
    
    if (isDate && !isNaN(itDate)) {       
        //const date = new Moment(text).format("DD-MM-YYYY")
        const date = new Moment(text).format("YYYY-MM-DD")
        const time = new Moment(text).format("hh:mm A")
        //return `<b class=${dateClass}>${date} <span>at</span> ${time}</b>`
        return `<b class=${dateClass}>${date}</b>`
    }
    if (isDateTime && !isNaN(itDate)) {
        //const date = new Moment(text).format("DD-MM-YYYY")
        const date = Moment.utc(text).toISOString(true).split("+")[0]

        // var localTime  = Moment.utc(text).toDate();
        // //localTime = Moment(localTime).format('Do HH:mm:ss A');
        // localTime = Moment(localTime).format('HH:mm:ss A');

        //var localTime  = Moment(text).format('HH:mm:ss A');

        var localTime  = Moment(text).format('hh:mm A');

        const time = new Moment(text).format("hh:mm A")
        return `<b class=${dateClass}>${localTime}</b>`
    }
    else {
        return text.replace(/\[(.*?)\]/g, (a, b) => {
            const date = new Moment(b).format('DD-MM-YYYY')
            const time = new Moment(b).format('hh:mm A')
            const dateTime = `<b class=${dateClass}>${date} <span>at</span> ${time}</b>`

            return dateTime;
        });
    }
}
export default isDate