import React, { Component } from "react";
import SendInput from "../UI/SendInput/SendInput";
import classes from "../SiaChat/ChatMessage/ChatMessage.module.scss";
import ScrollableFeed from "react-scrollable-feed";
import { connect } from "react-redux";
import whatsapp from "../../assets/image/whatsapp.png";
import SupportMessage from "./SupportMessage"
import * as actionsCreator from "../../store/actions/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import Loading from "../UI/Loading/LoadingChatComponent/LoadingChatComponent";
import moment from "moment";

import axios from "../../../src/axios";

class SupportChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message:"",
            supportRef:"",
            supportId:"",
            token:localStorage.getItem("chat-token"),
            chatMeassage:[]
        };
    }
    componentDidMount = () => { 
        this.props.getSupportUsers()
    };
    handleSubmitMessage=(e)=>{
        e.preventDefault()
        var data = {
            candidateId:this.props.profile.id,
            message:this.state.message,
            supportId:this.state.supportId,
            ref:this.state.supportRef,
        }
        axios
        .post("support-candidate-send-support-message/", data, {
                headers: {
                    Authorization: `Token ${this.state.token}`,
                },
          }
        )
         .then((res) => { 
            var data = {
                message: this.state.message,
                msgType: "ME",
                ref: this.state.supportRef,
                replyTime: moment().toISOString()
            }
            this.setState({ chatMeassage: [...this.state.chatMeassage, data],message:"" })                             
         });
    }
    handleMesageOnchange=(e)=>{
        this.setState({ message: e.target.value })
    }
    setSupportRef=(ref,id)=>{
        this.setState({supportRef:ref,supportId:id})
        this.startChatServer()
    }
    startChatServer = () => {
        var host = process.env.REACT_APP_WEB_SOCKET_URL_SUPPORT_CHAT;
        var wsocket = new WebSocket(host);

        wsocket.onopen = function (evt) {
            console.log("Server connected ,Success");
        };
        wsocket.onmessage = (evt) => {
            console.log(evt.data, "wtmsg")
            var obj = JSON.parse(evt.data)            
            this.setState({ chatMeassage: [...this.state.chatMeassage, obj] })
        };
    }
    render() {
        var messages = <SupportMessage
                         supportUsers={this.props.supportUsers}
                         setSupportRef={this.setSupportRef}
                         chatMeassage={this.state.chatMeassage}
                       ></SupportMessage>  
        return (
            <div className={this.props.show ? classes.siaChatWindow : classes.hiddenSiaChatWindow}>
                <div className={classes.siaChatHeader}>
                    <div className={classes.left}>          
                        <img src={whatsapp} />
                    </div>
                    <div className={classes.right}>Sia Live Support</div>
                </div>
                <div className={classes.ChatMessage}>
                    <ScrollableFeed forceScroll={true} className={classes.messagesTogether}>
                        {messages}
                    </ScrollableFeed>
                    {this.state.supportRef == ""?  <Loading /> :null}              
                    <form onSubmit={this.handleSubmitMessage} >
                        <div className={classes.Notebox}>
                            <input type="text" value={this.state.message} placeholder="Type your message" onChange={this.handleMesageOnchange} />
                            <button>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        supportUsers:state.chat.supportUsers,
        profile: state.profile.userProfile

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSupportUsers:()=>dispatch(actionsCreator.getSupportUsers()),  
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportChat);
