import * as actions from './actionTypes'
import axios from '../../axios';


export const initGetNotification = () => {
    return {
        type: actions.GET_NOTIFICATION_INIT
    }
}

export const failGetNotification = () => {
    return {
        type: actions.GET_NOTIFICATION_FAIL
    }
}
export const successGetNotification = (data) => {
    return {
        type: actions.GET_NOTIFICATION_SUCESS,
        data
    }
}
export const getNotification = () => {
    return dispatch => {
        const token=localStorage.getItem('chat-token')

        dispatch(initGetNotification())
        
        axios.get(process.env.REACT_APP_API_URL+'chat/group_chat_notifications/', {
            headers: {
                Authorization: `token ${token}`
            }
        }).then(res=>{
            dispatch(successGetNotification(res.data))
           
            
        }).catch(err=>{
            dispatch(failGetNotification())
           
        })
    }
}
export const getChatNotification = (data) => {
    return dispatch => {
        const token= localStorage.getItem('chat-token')   

        axios.post('/notification-candidate/',data, {
            headers: {
                Authorization: `token ${token}`
            }
        }).then(res=>{
            //dispatch(successGetNotification(res.data))           
            
        }).catch(err=>{
            dispatch(failGetNotification())           
        })
    }
}