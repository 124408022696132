import React, { Component } from 'react';
import classes from '../NotificationPanel/NotificationPanel.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

class Panel extends Component {

    constructor(props){
    super();
      this.state = { 
       Isclosed : props.showPop
      }
    }

    closePanel=()=>
    {
        //this.setState({Isclosed : !this.state.Isclosed})
        this.props.onhandlePopM(!this.props.showPop);
        console.log(this.props.showPop,'pop2')
    }

    onclickNotif = () => e => {
      e.preventDefault();
      console.log(this.props.showPop,'pop2')
      this.props.onhandlePopM(!this.props.showPop);
    }
    render() { 
        var show = this.props.showPop
        return ( 
        <> 
         <div className ={`${show ?  classes.overlayClosed : classes.overlay  }`} >
         <span className="pull-right" style={{width:"35px"}} onClick={this.closePanel} ><FontAwesomeIcon icon={faWindowClose} /></span>
        {this.props.notif !== undefined ? <> {this.props.notif.map(res => {
          return(
            <div className={classes.chat_people}>
            <div className={classes.chat_ib}>
              <h5>Sia Bot<span className={classes.chat_date}>{res.time}</span></h5>
              <p>{res.message}</p>
            </div>
           </div>
          )
        }
        )} </> : null  }
       </div>       
        </>
        );
    }
}
 
export default Panel;