import React, { Component } from "react";
import { connect } from "react-redux";
//import FilteredData from "../../../OnBoard/cvFilteredData/FilteredDataByDashBoardStaffAdd";
import FilteredData from "../../../../components/OnBoard/cvFilteredData/FilteredDataByDashBoardStaffAdd";
import classes from "./ChatCV.module.scss";
import * as actions from "../../../../store/actions/index";
import Splash from "../../../UI/Splash/Splash";
import Progress from "../ChatCV/ChatCVProgress/Progress";

class ChatCV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cv: false,
    };
  }

  render() {
    var data = true;
    this.props.onOpenCVModal(data);
    console.log(this.props.cvURl,"CvURL")
    let compoennt = (
      <FilteredData processPercentage={this.props.processPercentage} next={this.props.next} data={this.props.data} submitCV={this.props.submitCV} cvUploaded={this.props.cvUploaded} cvURl={this.props.cvURl} />
    );
    return (
      <div style={{ background: " #000000;" }}>
        <button
          className={classes.fixedClose}
          onClick={this.props.closeCVmodal}
        >
          <i className="fas fa-times"></i>
        </button>
        {compoennt}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenCVModal: (data) => dispatch(actions.OpenCVModal(data)),
  };
};

export default connect(null, mapDispatchToProps)(ChatCV);
