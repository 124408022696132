import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import * as actionsCreator from "../../../store/actions/index";
import Question from "../../OnBoard/CareerPreferences/Queston";
import moment from "moment";
import axios from "../../../axios";
import Spinner from "../../UI/Spinner/Spinner";
import classes from "../../OnBoard/CareerPreferences/Preferences.module.scss";
import swal from "sweetalert2";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      eventCampaignId: 0,
      isApiCall: false,
    };
  }
  componentDidMount() {
    setInterval(() => this.getEventData(), 10000);
    //this.getEventData()
  }
  getEventData = () => {
    if (this.state.isApiCall === false) {
      var id = localStorage.getItem("eventId");
      var candidateId = localStorage.getItem("CandidateProfile_id02");
      var curr_time = moment().toISOString();
      axios
        .get(
          "event/?eventId=" +
            id +
            "&inwRef=&candidateId=" +
            candidateId +
            "&logTime=" +
            curr_time,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          var data = res.data.Data.content;
          this.state.questions = data.registration;
          this.setState({
            eventCampaignId: data.campaignEvent.id,
            isApiCall: true,
          });
        })
        .catch((err) => {});
    } else {
      console.log("api call");
    }
  };
  candidateRegisterEvent = () => {
    this.setState({
      submitted: true,
    });
    const token = localStorage.getItem("chat-token");
    var data = {
      answers: this.state.questions,
      eventId: localStorage.getItem("eventId"),
      eventCampaignId: this.state.eventCampaignId,
      candidateId: localStorage.getItem("CandidateProfile_id02"),
    };
    axios
      .post("register-event/", data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        this.props.registrationCompalte();

        swal.fire({
          toast: true,
          position: "top-end",
          title: "Registration successful !",
          showConfirmButton: false,
          timer: 2500,
          customClass: { container: "swal-success-settings" },
          imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          imageHeight: 20,
        });
      });
  };

  render() {
    return (
      <div
        className={classes.careeerPreferWrapper}
        style={{ marginTop: "60px" }}
      >
        <div className={classes.careerPrefer}>
          <div className={classes.whiteBox}>
            {/* <div className={classes.textIntro}>
                        Register
                    </div> */}
            {/* {this.state.isRegister == false ?                  */}
            {
              this.state.questions.length > 0?
            <>
              {/* <div> Fill Register Qustion and Go</div> */}
              <div className={classes.textIntro}>
                <h3> Fill the questions to continue</h3>
              </div>
              <div className={classes.wrapperRegister}>
                {
                  this.state.questions.length > 0?

                  <div className={classes.row}>
                    {this.state.questions.map((res, index) => {
                      return (
                        <>
                          <div className={classes.column}>
                            <Question key={index} question={res} />
                          </div>
                        </>
                      );
                    })}
                </div>
              //   <></>
              //   <div class="form-check">
              //   <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
              //   <label class="form-check-label" for="defaultCheck1">
              //       Default checkbox
              //   </label>
              // </div>
                :
                null}

              </div>
              {/* {this.state.questions.map((res, index) => {
                                return (
                                    <Question key={index}
                                        question={res}
                                    />
                                )
                            })
                            } */}
              <div className={classes.listAndInput}>
                {
                  this.state.questions.length >0?
                  <div className={classes.okButtonDiv}>
                    <button onClick={this.candidateRegisterEvent}>
                      {this.state.submitted ? <Spinner /> : "Register"}
                    </button>
                  </div>
                :
                <div className={classes.textIntro}>
                  <h3> Waiting </h3>
                </div>
                }
              </div>
            </>
            :
            <div className={classes.textIntro}>
              <h3>Loading...</h3>
            </div>
            }
            {/* : null} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
