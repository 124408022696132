import React from "react";
import PhoneInput from "react-phone-input-2";
import { CountryDropdown } from "react-country-region-selector";
import TimezonePicker from "react-timezone";
// import "react-phone-number-input/style.css";
import classes from "./ProfileWrapper.module.scss";
import "./ProfileWrapper.scss";
import { Route, withRouter } from "react-router-dom";
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import resumeIcon from "../../../assets/image/resume.svg";
import { StyledDropZone } from "react-drop-zone";
import swal from "sweetalert2"
import Spinner from "../../../components/UI/Spinner/Spinner";
import axios from "../../../axios";
import moment from 'moment'

class ProfileContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: this.props.candidateDate.phone_number,
      country: this.props.candidateDate.current_location,
      timeZone: this.props.candidateDate.timeZone,
      profileImage: this.props.candidateDate.picture_url,
      email: "",
      firstName: this.props.candidateDate.first_name,
      lastName: this.props.candidateDate.last_name,
      profilePicUploaded: false,
      viewDeactivate: false,
      deleteType: "",
      deleteBtn:false
    };
    this.validator = new SimpleReactValidator({
      messages: {
        phone: "The phone number you entered is not valid",
      },
    });
    this.validatorAccDelete = new SimpleReactValidator();
  }  

  
  selectPhone = (value) => {
    this.props.setChanged(true);
    this.setState({
      phone: value.replace(/-|\s/g, ""),
    });
  };
  selectCountry = (value) => {
    this.props.setChanged(true);
    this.setState({
      country: value,
    });
  };
  selectTimeZone = (zone) => {
    this.props.setChanged(true);
    this.setState({
      timeZone: zone,
    });
  };
  handleInputChange = (evt) => {
    this.props.setChanged(true);
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  previewFile = (file, event) => {
    var _validFileExtensions = [
      "image/png",
      "image/jpeg",
      "image/bmp",
      "image/webp",
    ];
    var imgObj = _validFileExtensions.filter((obj) => obj == file.type);
    if (imgObj.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("IndividualImage", preview);
        this.setState({
          profileImage: preview,
          profilePicUploaded: true,
        });
      };
      this.props.setChanged(true);
    } else {
      this.setState({
        image: null,
        previewFile: false,
      });
    }
  };
  saveProfile = () => {
    if (this.validator.allValid()) {
      this.props.setChanged(false);
      var data = {
        phone_number: this.state.phone,
        timeZone: this.state.timeZone,
        current_location: this.state.country,
        onBoard_state: "UPDATE",
        skills: "",
        profile_pic: this.state.profilePicUploaded
          ? this.state.profileImage
          : "",
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        education: [],
        experience: [],
        career_preferences: "",
        format_type: ""
      };
      var id = this.props.candidateDate.id;
      this.props.onUpdateProfile(data, id);
      swal.fire({
        toast: true,
        position: "top-end",
        title: "Updated successfully",
        showConfirmButton: false,
        timer: 2500,
        customClass: { container: "swal-success-settings" },
        imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        imageHeight: 20,
      });
      var currentTime = moment.utc().toISOString();
      this.props.onGetCandidateData(localStorage.getItem("chat-token"), currentTime);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  viewDeactivate = () => {
    this.setState({ viewDeactivate: !this.state.viewDeactivate })
  }
  DeactivationAccount = () => {
    if (this.validatorAccDelete.allValid()) {
      this.setState({ deleteBtn: true })
      const token = localStorage.getItem('chat-token')
      var id = this.props.candidateDate.user_id;
      axios.delete("delete-user-accounts/?userId=" + id + "&isPermenentDelete=" + this.state.deleteType, {
        headers: {
          Authorization: `token ${token}`
        }
      }).then(res => {
        this.setState({ deleteBtn: false })
      }).catch(err => {
      })
    } else {
      this.validatorAccDelete.showMessages();
      this.forceUpdate();
    }
  }
  changeDeleteType = (e) => {
    console.log(e.target.value, "zxc")
    this.setState({ deleteType: this.str2bool(e.target.value) })
  }
  str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  }

  downloadData = () => {
    this.props.downloadUserData(this.props.candidateDate.user_id)
  }
  render() {
    var file = ""
    if (this.props.downloadData != null) {
      file = this.props.downloadData.Data.fileName
      console.log(file)
      window.location.href = file;
    }
    return (
      <div className={classes.profileR}>
        <div className={classes.skillsRow}>
          <div className={classes.left}>
            <img className={classes.resumeIcon} src={resumeIcon} />
            <div className={classes.title}>Personal information</div>
          </div>
          <div className={classes.right}>
            <div className={classes.profileImage}>
              <div className={classes.styledLabel}>Profile image</div>
              <div className="candidate-register-filedrop-wrapper">
                <div
                  className={
                    this.state.profileImage
                      ? "drop-zone-register-c"
                      : "drop-zone-register-c-default"
                  }
                >
                  <StyledDropZone
                    onDrop={this.previewFile}
                    label="Drop an image here or click to upload an image"
                  />

                  {this.state.profileImage ? (
                    <img src={this.state.profileImage} />
                  ) : (
                      ""
                    )}
                </div>
                <span className={classes.validateSpan}>
                  {this.validator.message(
                    "Image",
                    this.state.profileImage,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className={classes.fullName}>
              <div className={classes.firstName}>
                <div className={classes.styledLabel}>First name</div>
                <input
                  type="text"
                  defaultValue={this.state.firstName}
                  className={classes.styledInput}
                  onChange={this.handleInputChange}
                  name="firstName"
                />
                <span className={classes.validateSpan}>
                  {this.validator.message(
                    "First name",
                    this.state.firstName,
                    "required"
                  )}
                </span>
              </div>

              <div className={classes.lastName}>
                <div className={classes.styledLabel}>Last name</div>
                <input
                  type="text"
                  defaultValue={this.state.lastName}
                  className={classes.styledInput}
                  onChange={this.handleInputChange}
                  name="lastName"
                />
                <span className={classes.validateSpan}>
                  {this.validator.message(
                    "Last name",
                    this.state.lastName,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className={classes.emailID}>
              <div className={classes.styledLabel}>Email ID</div>
              <input
                type="text"
                defaultValue={this.props.profile.email}
                className={classes.styledInput}
                disabled
              />
            </div>
            <div className={`${classes.phone} phoneInputInChatSettings`}>
              <div className={classes.styledLabel}>Phone</div>
              <PhoneInput
                displayInitialValueAsLocalNumber
                value={this.state.phone}
                onChange={this.selectPhone}
                name="Phone"
              />
              <span className={classes.validateSpan}>
                {this.validator.message("Mobile", this.state.phone, "required")}
              </span>
            </div>
            <div className={classes.location}>
              <div className={classes.styledLabel}>Current location</div>
              <CountryDropdown
                className={classes.inputCountry}
                value={this.state.country}
                onChange={this.selectCountry}
                name="country"
                placeholder="Select your current location"
              />
              <span className={classes.validateSpan}>
                {this.validator.message(
                  "Country",
                  this.state.country,
                  "required"
                )}
              </span>
            </div>
            <div className={`${classes.timezone} timezoneInputInChatSettings`}>
              <div className={classes.styledLabel}>Time zone</div>
              <TimezonePicker
                value={this.state.timeZone}
                className="timezone-input-wizard"
                onChange={this.selectTimeZone}
                inputProps={{
                  placeholder: "Select your time zone",
                  name: "zone",
                }}
              />
              <span>
                {this.validator.message(
                  "Time Zone",
                  this.state.timeZone,
                  "required"
                )}
              </span>
            </div>
            <div className={`${classes.timezone} timezoneInputInChatSettings`}>
              <div className={classes.styledLabel} onClick={this.viewDeactivate}><p className={classes.link_color}>Deactivation and deletion</p></div>
              {this.state.viewDeactivate ?
                <div className={classes.deactive}>
                  <input type="radio" onChange={this.changeDeleteType} name="account" value={false} /> Deactivate account
                <span ><p className={classes.deactive_text} >Deactivating your account can be temporary.</p></span>
                  <input type="radio" onChange={this.changeDeleteType} name="account" value={true} /> Permanently delete account
                <span ><p className={classes.deactive_text}>Deleting your account is permanent. </p></span>
                  {this.state.deleteType ?
                    <span ><p className={classes.deactive_text}>Download your information.<a href="#" onClick={this.downloadData}>Download Info</a></p></span>
                    : null}
                  <span className={classes.validateSpan}>
                    {this.validatorAccDelete.message(
                      "delete type",
                      this.state.deleteType,
                      "required"
                    )}
                  </span>
                  <div className={classes.deactiveButtonRow}>
                    <button onClick={this.DeactivationAccount} type="button" id="find-this-save">
                       {this.state.deleteBtn ? <Spinner />:"Continue to Account Deactivation"} 
                    </button>
                  </div>
                </div> : null}
            </div>
          </div>
        </div>
        {/* DO NOT DELETE */}
        {/* <div className={classes.socialProfilesRow}>
          <div className={classes.left}>
            <img className={classes.behaviorIcon} src={behaviorIcon} />
            <div className={classes.title}>Social profiles</div>
          </div>
          <div className={classes.right}>
            <div className={classes.linkedin}>
              <div className={classes.styledLabel}>Linkedin</div>
              <input
                type="text"
                value=""
                className={classes.styledInput}
                readOnly
              />
            </div>
          </div>
        </div> */}
        {/* DO NOT DELETE */}
        <div className={classes.saveButtonRow}>
          <button onClick={this.saveProfile} type="button" id="find-this-save">
            Save Changes
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    downloadData: state.profile.downloadData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateProfile: (data, id) => dispatch(actions.onUpdateProfile(data, id)),
    setChanged: (data) => dispatch(actions.setSomethingChanged(data)),
    downloadUserData: (data) => dispatch(actions.downloadUserData(data)),
    onGetCandidateData: (data, time) =>
    dispatch(actions.getCandidateData(data, time)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileContent)
);
