import React from "react";
import Modal from "react-modal";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classNames from "./ProfilingModal.module.scss";
import "./ProfilingModal.scss";
// import "./Question/Question.scss";
import Question from "./Question/Question";
import { Progress, Segment, Message } from "semantic-ui-react";
import User from "../../assets/image/sia.png";
import AddCircle from "../../assets/image/add.svg";
import { connect } from "react-redux";
import * as actionsCreator from "../../store/actions/index";
import swal from "sweetalert2";

class CareerProfiling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listFull: false,
      existingPreferences: "",
      suggestedPreferences: ["Suggestion 1", "Suggestion 2", "Suggestion 3"],

      quotes: [
        {
          text: "The secret of getting ahead is getting started",
          by: "Mark Twain",
        },
        {
          text: "The future depends on what you do today.",
          by: "Mahatma Gandhi",
        },
        {
          text:
            "All our dreams can come true – if we have the courage to pursue them.",
          by: " Walt Disney",
        },
        {
          text: "The best way to predict the future is to create it.",
          by: "Abraham Lincoln",
        },
        {
          text: "Set impossible challenges. Then catch up with them.",
          by: "Richard Branson",
        },
        {
          text:
            "Opportunity may knock only once but temptation leans on the door bell.",
          by: "Opera Winfrey",
        },
      ],
    };
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.careerSuggetions != null) {
      console.log(nextprops, "sug");
      this.setState({
        suggestedPreferences: nextprops.careerSuggetions.Data,
      });
    }
  }
  componentWillMount() {
    var preferences = [];
    if (this.props.profile != null){
      if (this.props.profile.userData.profile[0].career_preferences != "") {
        preferences = this.props.profile.userData.profile[0].career_preferences.split(
          ","
        );
        this.setState({ existingPreferences: preferences });
      }
    }
    else{
      console.log("abc")
    }
    
  }

  addItem = (num) => {
    if (this.state.existingPreferences.length < 5) {
      const existing = [...this.state.existingPreferences];
      const suggested = [...this.state.suggestedPreferences];
      existing.push(this.state.suggestedPreferences[num]);
      suggested.splice(num, 1);
      this.setState({
        existingPreferences: existing,
        suggestedPreferences: suggested,
        listFull: false,
      });
    } else
      this.setState({
        listFull: true,
      });
  };

  removePreference = (num) => {
    console.log("ReMoVe", this.state.existingPreferences[num]);
    const arr = [...this.state.existingPreferences];
    arr.splice(num, 1);
    this.setState({ existingPreferences: arr, listFull: false });
  };

  addCareerSuggetions = () => {
    var data = this.state.existingPreferences.toString();
    var id = this.props.userProfile.id;
    this.props.onAddSuggetions(data, id);
    localStorage.setItem("OpenQuestionModal", false);
    this.props.closeProfilingModal();
    this.props.onGetChatResponseNew("complete_career_test", false)

    swal.fire({
      toast: true,
      position: "top-end",
      title: "Saved successfully",
      showConfirmButton: false,
      timer: 2500,
      customClass: { container: "swal-success-settings" },
      imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
      imageHeight: 20,
    });
  };

  closeModel=()=>{
    this.props.onGetChatResponseNew("close_from_career_test", false)
    this.props.closeProfilingModal(); 
  }

  render() {
    const { existingPreferences, suggestedPreferences, listFull } = this.state;
    var careerSuggetions = [];
    try {
      careerSuggetions = this.props.careerSuggetions.Data;
    } catch {
      careerSuggetions = [];
    }
    let userName = sessionStorage.getItem("firstName");
    let startPage = (
      <div className={classNames.IntroductionWrap}>
        <div className={classNames.IntroductionInner}>
          <div className={classNames.left}>
            <img src="https://image.flaticon.com/icons/svg/1535/1535019.svg" />
          </div>
          <div className={classNames.right}>
            <div className={classNames.addressing}>
              <span>Hi</span>
              <span className={classNames.yellow}>{userName}</span>
            </div>
            <div className={classNames.description}>
              <div>
                A set of multiple choice questions will be displayed on the
                screen and you are supposed to answer them all.
              </div>
              <div>
                Based on your responses Sia will suggest you the best career
                options that you can opt to.
              </div>
              <div>Hit "Start" when you're set to go.</div>
            </div>
            <button
              className={classNames.startButton}
              onClick={this.props.handleStartTest}
            >
              <i className="fas fa-check-circle" />
              <span>Start</span>
            </button>
            <button
              className={classNames.startButton}
              onClick={this.props.closeProfilingModal}
            >
              <i className="fas fa-times-circle" />
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
    );
    let Questions = (
      <div className={classNames.QuestionsSection}>
        <button
          className={classNames.closeButton}
          // onClick={this.props.closeProfilingModal}
          onClick={this.closeModel}
        >
          <i className="fas fa-times" />
        </button>
        <h4 className={classNames.lapIndicator}>
          Lap {this.props.currentLap} of 3
        </h4>
        <div className={classNames.lapIndicatorProgres}>
          <Progress percent={this.props.progress} indicating />
        </div>
        <Question
          question={this.props.question}
          handleStartTest={this.props.handleStartTest}
          handleNext={() => this.props.handleNext()}
          // numb={this.state.QuestionNumb}
        />
        <div className={`${classNames.footerSec} profiling-modal-quote`}>
          <TransitionGroup>
            <CSSTransition
              key={this.props.quoteNumber}
              timeout={{ enter: 300, exit: 300 }}
              classNames="decr"
            >
              <div className={`${classNames.footerInner}`}>
                <img src={User} />
                <div className={classNames.right}>
                  <h5>
                    &apos;{this.state.quotes[this.props.quoteNumber].text}
                    &apos;
                  </h5>
                  <div>-{this.state.quotes[this.props.quoteNumber].by}</div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    );
    // let testResultsOld = (
    //   <div className={classNames.finishedTest}>
    //     <div className={classNames.innerContent}>
    //       <h2>Your career profiling test is completed..</h2>
    //       <h3 className={classNames.para}>
    //         Most suitable career options for you are,
    //       </h3>
    //       <h3 className={classNames.careerOptions}>
    //         {/* {careerSuggetions.map((res) => {
    //           return <div>{res}</div>;
    //         })} */}
    //         <div>Corrections Officer</div>
    //         <div>In Service inspector</div>
    //         <div>Forest officer</div>
    //         <div>DevOps Engineer</div>
    //         <div>Accountant</div>
    //       </h3>
    //       <div className={classNames.buttons}>
    //         <button onClick={this.addCareerSuggetions}>Add to Profile</button>
    //         <button onClick={this.props.closeProfilingModal}>Exit</button>
    //       </div>
    //     </div>
    //   </div>
    // );

    let testResults = (
      <div className={classNames.resultsWrap}>
        <div className={classNames.resultsInner}>
          {/* <div className={classNames.left}>
            <img src="https://image.flaticon.com/icons/svg/1535/1535019.svg" />
          </div> */}
          <div className={classNames.right}>
            <div className={classNames.addressing}>
              <span className={classNames.blue}>Career profiling</span>
              <span>complete</span>
            </div>
            <div className={classNames.description}>
              {/* <div className={classNames.lilBig}>
                Based on your responses, the most suitable career options for
                you are,
              </div> */}
              <div className={classNames.lilBig}>
                According to your answers Sia has generated the best{" "}
                <span className={classNames.yellow}> career suggestions</span>{" "}
                for you.
              </div>
              <div className={classNames.lilBig}>
                You can click <img src={AddCircle} /> to add them to your list.
              </div>
              {/* <ul>
                {careerSuggetions.map((res) => {
                  return <li>{res}</li>;
                })}
              </ul> */}
              {suggestedPreferences.length > 0 && (
                <Segment.Group className="profiling-results">
                  {this.state.suggestedPreferences.map((res, index) => {
                    const numx = index;
                    return (
                      <Segment key={index}>
                        <div className="eachSuggestion">
                          {res}
                          <button onClick={() => this.addItem(numx)}>
                            <img src={AddCircle} />
                          </button>
                        </div>
                      </Segment>
                    );
                  })}
                </Segment.Group>
              )}
              {/* <Segment.Group className="profiling-results">
                <Segment>Top</Segment>
                <Segment>Middle</Segment>
                <Segment>Middle</Segment>
                <Segment>Middle</Segment>
                <Segment>Bottom</Segment>
              </Segment.Group> */}
              <div className={classNames.lilBig}>
                Your current list is{" "}
                {existingPreferences.length > 0 ? "as follows." : "empty."}
              </div>
              {listFull && (
                <Message className="profiling-removeMessage" color="red">
                  Please remove one or more items to add more
                </Message>
              )}
              {existingPreferences.length > 0 && (
                <Segment.Group className="profiling-results">
                  {existingPreferences.map((res, index) => {
                    return (
                      <Segment key={index}>
                        <div className="eachSuggestion">
                          {res}
                          <button onClick={() => this.removePreference(index)}>
                            <i className="far fa-trash-alt" />
                          </button>
                        </div>
                      </Segment>
                    );
                  })}
                </Segment.Group>
              )}
            </div>
            <div>
              <button
                className={classNames.exitButton}
                onClick={this.closeModel}
              >
                {/* <i className="fas fa-times-circle" /> */}
                <span>Exit</span>
              </button>
              <button
                className={classNames.startButton}
                onClick={this.addCareerSuggetions}
              >
                {/* <i className="fas fa-check-circle" /> */}
                <span>Add to Profile</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <div className="upload-review-cv-mod">
        <Modal
          isOpen={this.props.profilingModalIsOpen}
          onRequestClose={this.props.closeProfilingModal}
          contentLabel="Example Modal"
          className="cv-upload-modal-1"
        >
          {this.props.testStatus === "ongoing" && Questions}
          {/* {this.props.testStatus === "ongoing" && testResults} */}
          {this.props.testStatus === "finished" && testResults}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    careerSuggetions: state.chat.careerSuggetions,
    userProfile: state.profile.userProfile,
    profile: state.CandidateData.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAddSuggetions: (data, id) =>
      dispatch(actionsCreator.saveSuggetions(data, id)),
      onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CareerProfiling);
