import Moment from 'moment'
import isISODate from 'is-iso-date'
import axios from '../../src/axios'
import isXml from "is-xml";
import XMLReader from "xml-reader";
import xmlQuery from "xml-query";
// const getCampaignData = (camid)=>{
//     var campaignName = "abc"
//     return campaignName
// }


const isResponse = (text, textClass, payloadMessage) => {

    console.log(text, "Text Is response payload")
    console.log(payloadMessage, "text payload message")

    var response_return = "Response under development"
    
    
    if (text === "Yes start conversation" ){
        console.log(localStorage.getItem('manageExistingcampaigns'), "start conversation")
        if(localStorage.getItem('manageExistingcampaigns') === "true"){
            response_return = "I'll do it later"
        }
        else{
            response_return = "Yes"
        }
        localStorage.setItem("manageExistingcampaigns", false)
    }
    else if (text === "Campaigns"){
        response_return = "Apply for a career campaign"
    }
    else if (text === "help on campaigns"){
        response_return = "Manage my existing campaigns"
    }
    else if(text === "help my system"){
        response_return = "I need technical help"
    }
    else if(text === "Career matching test"){
        response_return = "Help me explore Career choices"
    }
    else if(text === "No exit"){

        console.log(localStorage.getItem('applyAvailableCam'), "Flag no exit")

        if (localStorage.getItem('applyAvailableCam') === "false"){
            response_return = "No"
        } 
        else{   
            response_return = "NO, Exit the chat"
        }       
    }


    else if (text === "No, Exit the chat"){
        response_return = "No"
    }

    else if (text === "edit my time"){
        response_return = "Edit my scheduled times"
    }
    else if (text === 'select_my_time'){
        response_return = 'Schedule new campaign'
    }
    else if(text === "Go_back_from_help_existing"){
        response_return = "Go back"
    }
    else if(text === "exit_campaign_from_edit_time"){
        response_return = "Go back"
    }
    else if (text === "Career Fair"){
        response_return = "Career Fair"
    }
    else if(text === "Internship"){
        response_return = "Internship"
    }
    else if(text === "Recruitment Drives"){
        response_return = "Recruitment Drives"
    }
    else if(text === "Other"){
        response_return = "Other"
    }
    else if(text === "Project Based Engagements"){
        response_return = "Project Based Engagements"
    }
    else if(text === "Ambassadorship Programs"){
        response_return = "Ambassadorship Programs"
    }
    else if(text === "Experimental Sharing"){
        response_return = "Ambassadorship Programs"
    }
    else if(text === "Recruitment Program"){
        response_return = "Recruitment Program"
    }
    else if(text === "Other Programmes"){
        response_return = "Other Programmes"
    }
    else if(text === "Internship Programmes"){
        response_return = "Internship Programmes"
    }
    else if(text === "One off Interview"){
        response_return = "One off Interview"
    }
    else if(text === "VR/AR Campaign"){
        response_return = "VR/AR Campaign"
    }
    else if(text === "Gamification"){
        response_return = "Gamification"
    }
    else if(text === "successfully applied"){
        response_return = "Apply"
    }
    else if(text === "View your smart CV"){
        response_return = "View your smart CV"
    }
    else if(text === "Submit cv data"){
        response_return = "Submit"
    }
    else if(text === "Write cover letter"){
        response_return = "Type text"
    }
    else if(text === "Submit cover letter"){
        response_return = "Submit"
    }
    else if(text === "Start video pitch"){
        response_return = "Start video pitch"
    }
    else if(text === "Submit video stream"){
        response_return = "Proceed"
    }
    else if(text === "video_pitch_upload"){
        response_return = "Redo video pitch"
    }
    else if(text === "go_back_from_available_campaigns"){
        response_return = "Go back"
    }
    else if(text === "Exit video pitch"){
        response_return = "Exit"
    }
    else if(text === "Proceed_with_this_video"){
        response_return = "No proceed with this"
    }
    else if(text === "no_exit_from_chat"){
        response_return = "No"
    }
    else if(text === "closeChat"){
        response_return = "Thank you"
    }
    else if(text === "go_back_from_support"){
        response_return = "Go back"
    }
    // else if(text === "Send as email"){
    //     response_return = "E-mail us"
    // }

    else if(text === "Send_as_email"){
        response_return = "E-mail us"
    }

    else if(text === "file_upload_cover_letter"){
        response_return = "Attach file"
    }
    else if(text === "go_back_from_system_check"){
        response_return = "Go back"
    }
    else if(text === "go_back_from_live_support"){
        response_return = "Go back"
    }
    else if(text === "Exit support"){
        response_return = "I'll send later"
    }
    else if(text === "Submit support email"){
        response_return = "Submit"
    }
    else if(text === "Close cam test"){
        response_return = "Close"
    }
    else if(text === "Close mic test"){
        response_return = "Close"
    }
    else if(text === "make_call"){
        response_return = "Start call"
    }

    //check if its campaigns refenrence
    else if(text === "campaign_reference"){
        var responseName = localStorage.getItem('SCHEDULE_CAMPAIGN_NAME')
        response_return = responseName
        if (response_return === ""){
            response_return = "Campaign name"
        }
    }

    else if(text=== "start_date_selected"){
        let xml = XMLReader.parseSync(payloadMessage);
        const xmlKey = xmlQuery(xml).find("key").text();
        var date = xmlKey.split("_")[0]
        response_return= date
    }

    else if(text === "Time_slot_selected"){
        let xml = XMLReader.parseSync(payloadMessage);
        const xmlKey = xmlQuery(xml).find("key").text();
        var time = xmlKey.split("_")[0]
        const date =  Moment.utc(time).toISOString(true).split("+")[0]
        //var time_string = Moment.utc(time).toDate();
        var time_string = Moment(date).format('HH:mm:ss A');
        response_return= time_string
    }

    else if(text === "Reminder_time"){
        let xml = XMLReader.parseSync(payloadMessage);
        const xmlKey = xmlQuery(xml).find("key").text();
        var duration = xmlKey.split("_")[1]
        var duration_name = ""
        if (duration  === "30"){
            duration_name = "30 minutes"
        }
        else if(duration === "60"){
            duration_name = "One hour"
        }
        else if(duration === "120"){
            duration_name = "One and half hour"
        }
        response_return= duration_name
    }

    else if(text === "select_my_time"){
        response_return = "Go back"
    }

    else if(text === "exit_campaign_from_schedule_time"){
        response_return = "Go back"
    }

    else if(text === "No stop conversation"){
        response_return = "No"
    }

    else if(text === "Yes,go for career test"){
        response_return = "Yes"
    }

    else if(text === "not_save_profile_data"){
        response_return = "Yes,Exit" 
    }

    else if(text === "Live_chat_support"){
        response_return = "Live chat"
    }

    else if(text === "faq_questions"){
        response_return = "Frequantly Asked Questions"
    }
    else if(text === "question_ask"){
        response_return = "Send your question"
    }

    else if(text === "Ask faq later"){
        response_return = "Ask later"
    }

    else if(text === "Send faq now"){
        response_return = "Send"
    }

    else if(text === "begin_call"){
        response_return = "Make call"
    }

    else if(text === "Started_video_interview"){
        response_return = "Start video call"
    }
    else if(text === "Career test"){
        response_return = "Help me explore career choices"
    }
    else if(text === "I want to complete my profile"){
        response_return = "Yes, Take me to my profile "
    }
    else if(text === "I want to exit from profile"){
        response_return = "No, remind me again later"
    }
    else if(text === "start_cam_test"){
        response_return = "Yes start testing" 
    }
    else if(text === "start_mic_test"){
        response_return = "Yes start testing"
    }
    else if(text === "exit_from_campaign_apply"){
        response_return = "Close"
    }
    else if(text === "Skip_cover_letter"){
        response_return = "Close"
    }
    else if(text === "go_back_from_campaign_apply_submit_cv"){
        response_return = "Go back"
    }
    else if(text === "stay_on_cv_submit_campaign"){
        response_return = "No"
    }
    else if(text === "go_back_from_campaign_cover_letter"){
        response_return = "Go back"
    }
    else if(text === "stay_on_cover_letter_submit_campaign"){
        response_return = "No"
    }
    else if(text === "go_back_from_campaign_video_pitch"){
        response_return = "Go back"
    }
    else if(text === "stay_on_video_pitch_submit_campaign"){
        response_return = "No"
    }
    else if(text === "exit_from_campaign_apply_cv"){
        response_return = "Close"
    }
    else if(text === "start_normal_chat_flow"){
        response_return = "Yes"
    }
    else if(text === "welcome_back_career_test"){
        response_return = "Yes"
    }
    else if(text === "exit_welcome_back_test"){
        response_return = "No"
    }
    else if(text === "review_profile_welcome"){
        response_return = "Yes"
    }
    else if(text === "exit_profile_welcome"){
        response_return = "No"
    }
    else if(text === "go_back_from_career_test"){
        response_return = "Go back"
    }
    else if(text === "welcome_message_campaigns"){
        response_return = "Yes"
    }
    else if(text === "start_normal_chat_flow"){
        response_return = "Yes"
    }
    else if(text === "no_exit_from_chat"){
        response_return = "No"
    }
    else if(text === "exlpore_campaigns_welcome"){
        response_return = "Yes"
    }
    else if(text === "exit_to_main_menu"){
        response_return = "No"
    }
    else if(text === "redirect_career_test"){
        response_return = "No"
    }
    else if(text === "start_career_test"){
        response_return = "Yes"
    }
    else if(text === "exit_from_career_test"){
        response_return = "No"
    }
    else if(text === "schedule_campaign_later"){
        response_return = "I'll do it later" 
    }
    else if(text === "welcome_back_log_user"){
        response_return = "Yes"
    }
    else if(text === "Exit chat"){
        response_return = "No"
    }
    else if(text === "apply_for_campaign"){
        response_return = "Yes"
    }
    else if(text === "yes_back_to_main_menu"){
        response_return = "Yes"
    }
    else if(text === "campaign_skipped"){
        let xml = XMLReader.parseSync(payloadMessage);
        const xmlKey = xmlQuery(xml).find("key").text();
        var split = xmlKey.split("_")[1]
        var campaignName = ""
        // if (split === "STEP"){
        //     campaignName = xmlKey.split("_")[3]
        // }
        // else {
        //     campaignName = xmlKey.split("_")[2]
        // }
        campaignName = xmlKey.split("_")[2]
        console.log(campaignName, "campaign name")
        campaignName = localStorage.getItem("SKIPPER_CAMPAIGN")
        response_return = campaignName
    }
    else if(text=== "exit_to_available_campaigns"){
        response_return = "No"
    }
    else if(text === "saved_information_profile_update"){
        response_return = "Save changes"
    }
    else if(text === "continue_without_complete_profile"){
        response_return = "Yes"
    }
    else if(text === "review_to_complete_profile_again"){
        response_return = "No"
    }

    else if(text == "complete_career_test"){
        response_return = "Add to profile" 
    }

    else if (text == "close_from_career_test"){
        response_return = "Exit"
    }

    else if(text === "exit_chat"){
        if (localStorage.getItem("startCareerTestInit") === "false"){
            response_return = "No"
        }
        else{
            response_return = "No"
        }
    }

    else if (text === "save_change_later"){
        response_return = "I'll do it later"
    }
    else if(text === "Successfull scheduled by dashboard"){
        response_return = "Schedule"
    } 

    else {
        response_return = text
    }
    
    return response_return
   
}
export default isResponse