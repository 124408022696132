import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./App.module.scss";
import * as actionsCreator from "./store/actions/index";
import Auth from "./containers/Auth/Auth";
import VideoConference from "../src/containers/Interview/VideoConference/VideoConference";
import CampaignShare from "../src/components/CampaignShare/CampaignShare";
import Splash from "./components/UI/Splash/Splash";
import axios from "../src/axios";
import RegPassLeseUser from "../src/components/PasswordLessLogin/RegisterUser";
import PasslessLoginUser from "../src/components/PassLessLogin/PasslessLogin";
import OnBoard from "../src/containers/Dashboard/OnBoard";
//import CandidateDashboard from '../src/containers/Dashboard/Dashboard'
import CandidateDashboard from "../src/containers/Dashboard/DashboardContainer/Console";
import Landing from "./containers/Landing/Landing";
import { OnboardSuccess } from "./store/actions/Auth";
import LoadingQuickInterview from "../src/containers/QuickInterview/TempComponent/Loading";
import InterviewInvite from "../src/containers/QuickInterview/TempComponent/InterviewInvite/InterviewInvite";
import CheckVideoConference from "../src/containers/Interview/VideoConference/CheckValidateVideo";
import VirtualAssessmentConference from "../src/containers/Interview/VirtualAssessmentConference/VideoConference"
import GetTokenInterview from '../src/containers/Interview/GetToken/GetToken'
import CampaignShareGroup from "../src/components/CampaignShare/CampaignShareGroup";
import HasInterview from '../src/components/Event/Stalls/IndvStall/HasInerview';
import Swal from 'sweetalert2'
import Logo from "./assets/image/ufairs.svg";
import RoteIcon  from "./assets/image/Rotate.png"

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref_id: "null",
      shareRef: null,
      campaignId: "",
      campaignData: "",
      loginStatus: "",
      interviewId: "",
      userId: "",
      interviewTime: "",
      statusReject: 3,
      chatToken: localStorage.getItem("chat-token"),
      URL: process.env.REACT_APP_API_URL + "manager/",
      CHATURL: process.env.REACT_APP_API_URL + "chat/",
    };
  }

  componentWillMount = () => {
    var campaignId = new URLSearchParams(
      this.props.location.search.substring(2)
    ).get("camId");
    var interviewId = new URLSearchParams(
      this.props.location.search.substring(3)
    ).get("indID");
    var userId = new URLSearchParams(
      this.props.location.search.substring(4)
    ).get("uId");

    if (
      new URLSearchParams(this.props.location.search.substring(1)).get("ref")
    ) {
      const ref = new URLSearchParams(
        this.props.location.search.substring(1)
      ).get("ref");
      sessionStorage.setItem("ref", ref);
    }

    //url to quick campaign
    if (
      new URLSearchParams(this.props.location.search.substring(1)).get(
        "quickInvite"
      )
    ) {
      const resQuick = new URLSearchParams(
        this.props.location.search.substring(1)
      ).get("quickInvite");
      localStorage.setItem("quickInvite", resQuick);
    }

    //url to interviewInvitation
    if (
      new URLSearchParams(this.props.location.search.substring(1)).get(
        "inviteRef"
      )
    ) {
      const resInvite = new URLSearchParams(
        this.props.location.search.substring(1)
      ).get("inviteRef");
      //localStorage.setItem("inviteRef", resInvite)
      this.props.onInviteInterview(resInvite);
    }

    //url from share ref  
    if (
      new URLSearchParams(this.props.location.search.substring(1)).get(
        "ShareRef"
      )
    ) {
      //share ref details
      const shareRef = new URLSearchParams(
        this.props.location.search.substring(1)
      ).get("ShareRef");
     // sessionStorage.setItem("shareRef", shareRef);
      localStorage.setItem("shareRef", shareRef);
    }  
    //Condition to check passowrdlssurl
    if (
      new URLSearchParams(this.props.location.search.substring(2)).get(
        "loginStatus"
      )
    ) {  
      var logStat = new URLSearchParams(
        this.props.location.search.substring(2)
      ).get("loginStatus");
      var logRef = new URLSearchParams(
        this.props.location.search.substring(1)
      ).get("loginRef");
      sessionStorage.setItem("loginStatus", logStat);
      sessionStorage.setItem("LogingReferecne", logRef);
      sessionStorage.setItem("LogingReferencePublic", logRef);
    }
  };

  statusUpdateReject = () => {
    var camid = localStorage.getItem("campaign-id");
    var intId = localStorage.getItem("interview-id");
    var userId = localStorage.getItem("useriD");
    var token = localStorage.getItem("chat-token");
    axios({
      method: "put",
      url:
        this.state.URL +
        "manager-queue-final/?campaignId=" +
        camid +
        "&interviewId=" +
        intId +
        "&userId=" +
        userId +
        "&responseStatus=" +
        3,
      headers: { Authorization: `Token ${token}` },
    })
      .then(function (response) {
        console.log(response, "Success");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
 

  statusUpdateAccept = () => {
    var camid = localStorage.getItem("campaign-id");
    var intId = localStorage.getItem("interview-id");
    var userId = localStorage.getItem("useriD");
    var token = localStorage.getItem("chat-token");

    axios({
      method: "put",
      url:
        this.state.URL +
        "manager-queue-final/?campaignId=" +
        camid +
        "&interviewId=" +
        intId +
        "&userId=" +
        userId +
        "&responseStatus=" +
        2,
      headers: { Authorization: `Token ${token}` },
    })
      .then(function (response) {
        console.log(response, "Success");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  componentDidMount = () => {
    // document.onreadystatechange = () => {
    //   if (document.readyState === 'complete') {
    //     setTimeout(() => {
    //       this.setState({ splash: false })
    //     }, 2000)
    //   }
    // };
    this.calculateVHValue();
    var logStat = localStorage.getItem("loginStatus");
    var logRef = localStorage.getItem("LogingReferecne");
    this.props.getSystemLevelData();

    this.props.onCheckAuth();
    const token = localStorage.getItem("chat-token") !== null;

    if (!token) {
      //this.props.history.push('/auth')
      const ref = localStorage.getItem("chat-ref-id");
      this.setState({
        ref_id: ref,
      });
    }
    if (window.innerHeight > window.innerWidth) {
      Swal.fire({
        imageUrl:RoteIcon,
        imageWidth: 48,
        imageHeight: 48,
        title: '',
        text: "This website is optimized for a landscape orientation. Please turn your device",
     //   icon: 'warning',
        confirmButtonColor: '#fa9d1c',
      })
    }  
  };


  calculateVHValue = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;    
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  };

  render() {
    
    const { ref_id, splash } = this.state;
    const values = this.state.ref_id;
    localStorage.setItem("ref_id", values);

    let formIs = <Splash />;
    let routes = <Auth location={this.props.location} />;
    let queryparms = window.location.href.split("/")[3]

    // let eventName = (queryparms.split("=")[0]).split("?")[1]
    // let eventId = queryparms.split("=")[1]

    // localStorage.setItem("EVENTNAME", eventName)
    // localStorage.setItem("EVENTID", eventId)

    let routStatus = "";
   
    var ref = false;
    const quickInvite = new URLSearchParams(
      this.props.location.search.substring(1)
    ).get("quickInvite");
    const inviteRef = new URLSearchParams(
      this.props.location.search.substring(1)
    ).get("ref");
    const vasRef = new URLSearchParams(
      this.props.location.search.substring(1)
    ).get("vasRef");
    const shareComRef = new URLSearchParams(
      this.props.location.search.substring(1)
    ).get("shareCom");

    if (quickInvite) {
      ref = true;
    } else if (inviteRef) {
      ref = true;
    }else if(vasRef){
      ref=true
    }else if(shareComRef){
      ref= true
    }

    if (this.props.auth && sessionStorage.getItem("OnBoard_State") === "") {
      localStorage.removeItem("quickInvite");
      routes = <OnBoard />;
    } else if (
      this.props.auth &&
      localStorage.getItem("OnBoard_State") === "COMPLETE" &&
      ref === false
    ) {   
      localStorage.removeItem("OpenQuestionModal");
      localStorage.removeItem("OpenSmartCV");
      localStorage.removeItem("submitCV");
      localStorage.removeItem("OpenCoverLtrModal");
      localStorage.removeItem("OpenVideoModal");
      localStorage.removeItem("video play sucess");
      localStorage.removeItem("UPLOAD_VIDEO");
      localStorage.removeItem("CAMPAIGN_APPLIED_ID");
      localStorage.removeItem("SupportAsEmail");
      localStorage.removeItem("UPLOADING_STATUS");
      localStorage.removeItem("OpenCamTest");
      localStorage.removeItem("OpenMicTest");
      localStorage.removeItem("manageExistingcampaigns");
      localStorage.removeItem("faq_question_modal");
      localStorage.removeItem("quickInvite");
      localStorage.removeItem("Video_chat_complete");
      this.props.updateLogState();
      this.props.getSkippedCampaigns();
      localStorage.setItem("NOTIFICATION", false)
      routes = (
        <CandidateDashboard
          location={this.props.location}
          values={this.props.values}
        />
      );
    } else if (
      !this.props.auth &&
      sessionStorage.getItem("loginStatus") === "False"
    ) {
      var shareRef = localStorage.getItem("shareRef")
      var eventId = localStorage.getItem("eventId")
      localStorage.clear();
      if (shareRef != null) {
        localStorage.setItem("shareRef", shareRef)
      }
      localStorage.setItem("eventId", eventId)

      routStatus = "False";
      routes = (
        <RegPassLeseUser
          location={this.props.location}
          values={this.props.values}
        />
      );
    } else if (
      !this.props.auth &&
      sessionStorage.getItem("loginStatus") === "True"
    ) {      
      var shareRef = localStorage.getItem("shareRef")
      var eventId = localStorage.getItem("eventId")
      localStorage.clear();
      if (shareRef != null) {
        localStorage.setItem("shareRef", shareRef)
      }
      localStorage.setItem("eventId", eventId)
      routStatus = "True";
      routes = (
        <PasslessLoginUser
          location={this.props.location}
          values={this.props.values}
        />
      );
    }
    else if (new URLSearchParams(this.props.location.search.substring(1)).get("shareCom")) {
      console.log("Url with share")
      routes = <CampaignShareGroup />;
    }

    else if (new URLSearchParams(this.props.location.search.substring(1)).get("ShareRef")){
      console.log("share ref is not null")
      routes = <CampaignShare/>
    }
    else if (!this.props.auth && sessionStorage.getItem("ref") !== null){
      routes = <GetTokenInterview />;
    }

    else if (this.props.auth && sessionStorage.getItem("ref") !== null) {
      console.log("login with interview reference");
      localStorage.removeItem("OpenQuestionModal");
      localStorage.removeItem("OpenSmartCV");
      localStorage.removeItem("submitCV");
      localStorage.removeItem("OpenCoverLtrModal");
      localStorage.removeItem("OpenVideoModal");
      localStorage.removeItem("video play sucess");
      localStorage.removeItem("UPLOAD_VIDEO");
      localStorage.removeItem("CAMPAIGN_APPLIED_ID");
      localStorage.removeItem("SupportAsEmail");
      localStorage.removeItem("UPLOADING_STATUS");
      localStorage.removeItem("OpenCamTest");
      localStorage.removeItem("OpenMicTest");
      localStorage.removeItem("manageExistingcampaigns");
      localStorage.removeItem("faq_question_modal");
      localStorage.removeItem("Video_chat_complete");
      this.props.updateLogState();
      this.props.getSkippedCampaigns();

      //routes = <CheckVideoConference />;

      routes = <CandidateDashboard
                  location={this.props.location}
                  values={this.props.values}
                />

    } else if (
      this.props.auth && quickInvite !== null
    ) {
      localStorage.removeItem("Video_chat_complete");
      routes = <LoadingQuickInterview />;
    } else if (this.props.auth && this.props.inviteInteview !== null) {
      routes = <InterviewInvite intData={this.props.inviteInteview} />;
    }else if(this.props.auth && vasRef !=null){
      routes= <VirtualAssessmentConference />
    }

    if (this.props.loading === true) {
      formIs = (
        <div className={classes.Loading}>
          <Splash />
        </div>
      );
      return formIs;
    } else {
   
    }

    return <div className={classes.App}>{routes}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    loading: state.auth.loading,
    applyClicked: state.CampaignByRef.clicked,
    inviteInteview: state.auth.interviewInvite,   
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onInviteInterview: (data) => dispatch(actionsCreator.InviteInterview(data)),
    onCheckAuth: () => dispatch(actionsCreator.authCheck()),
    updateLogState: () => dispatch(actionsCreator.updateLogState()),
    getSkippedCampaigns: () => dispatch(actionsCreator.getSkippedCampaigns()),
    //  onGetCampaignByRef: data => dispatch(actionsCreator.getCampaignByRef(data))
    getSystemLevelData: () => dispatch(actionsCreator.getSystemLevelData()),
    storeWebSockert: (data) => dispatch(actionsCreator.storeWebSockert(data)),

  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
