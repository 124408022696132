import * as actions from '../actions/actionTypes'
import axios from '../../axios'

export const chatInit = () => {
    return {
        type: actions.CHAT_INIT
    }
}
export const chatSuccess = (message) => {
    return {
        type: actions.CHAT_SUCCESS,
        message
    }
}
export const chatFail = () => {
    return {
        type: actions.CHAT_FAIL
    }
}

export const getChatResponse = (message) => {
    return dispatch => {
        const token = localStorage.getItem('chat-token')
        dispatch(chatInit())

        axios.post('conversations/', {
            "query": message,
        }, {
                headers: {
                    Authorization: `Token ${token}`
                }
            })
            .then(res => {
                // console.log(res);
                dispatch(chatSuccess(res.data.response))
            })
            .catch(err => {
                // console.log("oops");
                dispatch(chatFail())
                if(err.response){

                if (err.response.status === 401) {
                    localStorage.removeItem('chat-username')
                    localStorage.removeItem('chat-userimage')
                    localStorage.removeItem('chat-token')

                    window.location.reload();
                }
            }
            })
    }
}

export const historyInit = () => {
    return {
        type: actions.HISTORY_INIT
    }
}
export const historyFail = () => {
    return {
        type: actions.HISTORY_FAIL
    }
}
export const historySuccess = (messages) => {
    return {
        type: actions.HISTORY_SUCCESS,
        messages
    }
}
export const hollencodeQuestionsSuccess = (data) => {
    return {
        type: actions.HOLLENCODE_QUESTIONS_SUCCESS,
        data
    }
}
export const careerSuggetionsSuccess = (data) => {
    return {
        type: actions.CAREER_SUGGETION_SUCCESS,
        data
    }
}

export const saveSuggetionsSuccess = (data) => {
    return {
        type: actions.SUCCESS_SAVE_CAREER_SUGGETION,
        data
    }
}
export const SupportUsersSuccess = (data) => {
    return {
        type: actions.SUCCESS_WHATSAPP_SUPPORT_USERS,
        data
    }
}

export const WebSocketSuccess = (data) => {
    return {
        type: actions.SUCCESS_WEBSOCKET,
        data
    }
}
export const getChatHistory = () => {
    return dispatch => {
        const token = localStorage.getItem('chat-token')
        dispatch(historyInit())
        axios.get(`conversations/`, {
            headers: {
                Authorization: `Token ${token}`
            }
        })
            .then(res => {
                // console.log(res);
                dispatch(historySuccess(res.data))
            })
            .catch(err => {
                // console.log(err.response);
                dispatch(historyFail())
                if(err.response){

                if (err.response.status === 401) {
                    localStorage.removeItem('chat-username')
                    localStorage.removeItem('chat-userimage')
                    localStorage.removeItem('chat-token')
                    window.location.reload();
                }
            }
        })
    }
}
export const getHollencodeQuestions = (message) => {
  return (dispatch) => {
    const token = localStorage.getItem("chat-token");
    axios
      .get("get-hollencode-questions/", {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then((res) => {
        dispatch(hollencodeQuestionsSuccess(res.data));
      })
      .catch((err) => {});
  };  
};
export const getCareerSuggetions = (data,id) => {
    return (dispatch) => {
      const token = localStorage.getItem("chat-token");
      axios
        .get("get-career-suggetions/?result="+data+"&senderId="+id, {
          headers: {
            Authorization: `Token ${token}`
          }
        })
        .then((res) => {
          dispatch(careerSuggetionsSuccess(res.data));
        })
        .catch((err) => {});
    };  
  };
  export const saveSuggetions = (data,id) => {
    return (dispatch) => {
      const token = localStorage.getItem("chat-token");
      axios
        .get("update-career-matching-test/?senderId="+id+"&results="+data, {
          headers: {
            Authorization: `Token ${token}`
          }
        })
        .then((res) => {
          dispatch(saveSuggetionsSuccess(res.data));
        })
        .catch((err) => {});
    };  
  };

export const getSupportUsers = () => {
    return (dispatch) => {
        const token = localStorage.getItem("chat-token");
        axios
            .get("support-candidate-get-support-users", {
                headers: {
                    Authorization: `Token ${token}`
                }
            })
            .then((res) => {
                dispatch(SupportUsersSuccess(res.data));
            })
            .catch((err) => { });
    };
};
export const storeWebSockert = (data) => {
    return (dispatch) => {     
        dispatch(WebSocketSuccess(data));
    };
};