import React, { Component } from "react";
import classes from "../CampaignShare/CampaignShare.module.scss";
import "react-sweet-progress/lib/style.css";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Axios from "axios";
import { Route, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsCreator from "../../store/actions/index";
import axios from "../../axios";
import Swal from "sweetalert2";
import { Redirect } from "react-router";
import Spinner from "../UI/Spinner/Spinner";
import defaultCompany from "../../assets/image/sia-in-box.png";
import FlowControl from "../../components/CandidateDashboard/NearbyCampaigns/Controller/FlowControl/FlowControl";
import moment from "moment";
import { Modal } from 'semantic-ui-react'
import defaultCamImage from "../../assets/image/sia_default.png";


class CampaignShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: "",
      startTime: "",
      endTime: "",
      applied: "",
      timeElapsed: "",
      availableTimes: "",
      URL: process.env.REACT_APP_API_URL + "chat",
      chatToken: localStorage.getItem("chat-token"),
      isApplied: false,
      applyLoding: false,
      isZoom:false
    };
    this.timeSlots = this.timeSlots.bind(this);
  }

  componentDidMount = () => {
    //this.campaignData();
    //this.timeSlots()
    // this.getCampaignData();
    var id = localStorage.getItem("CandidateProfile_id02");
    this.props.getAvailableCampaignType(id);
    localStorage.removeItem("OPENCHATWINDOW");
    localStorage.setItem("REFRESHSTATUS", false);
    this.props.onGetCampaignByRef(localStorage.getItem("shareRef"))
  };

  timeSlots = () => {
    Axios.get({}).then((res) => {
      this.setState({
        availableTimes: res.data,
      });
    });
  };

  formatDate(string) {
    var options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  // getDaysCount(startDate, endDate) {
  //   var day1 = startDate;
  //   var day2 = endDate;
  //   var date1 = new Date(day1);
  //   var date2 = new Date(day2);
  //   // To calculate the time difference of two dates
  //   var Difference_In_Time = date2.getTime() - date1.getTime();
  //   // To calculate the no. of days between two dates
  //   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  //   return Difference_In_Days;
  // }

  getDaysCount(startDate, endDate) {  
      var day1 = startDate;
      var day2 = endDate;
      var date1 = new Date(day1);
      var date2 = new Date(day2);
      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();
      // To calculate the no. of days between two dates
      var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24)) + 1;
      var text=""
      if(Difference_In_Days > 1){
         text = Difference_In_Days +" Days"
      }else{
        text = Difference_In_Days +" Day"
      }
      return text;  
  }

  closeCampaign = () => {
    this.setState({ isOpen: false,applyLoding:false });
  };
  goDashoard=()=>{
    this.setState({ isApplied: true});
   // sessionStorage.removeItem("shareRef");
    localStorage.removeItem("shareRef");
    this.props.openChat()  
  }

  onButtonClick = () => {
    if (this.props.auth == true) {
      this.setState({ applyLoding: true });
    }else{
      this.setState({isApplied:true})
    }
  };

  zoomImage=()=>{
    this.setState({isZoom:true})   
  }
  colseImage=()=>{
    this.setState({isZoom:false})  
  }

  render() {

    const { isApplied } = this.state;
    if (isApplied === true) {
      return <Redirect to={"/"} />;
    }
    var campaignImage = "";
    var companyLogo = "";
    var heading = "";
    var startDate = "";
    var shareUrlFb = "";
    var shareUrlLinklind = "";
    var shareUrltiwiter = "";
    var description = "";
    var endDate = "";
    var companyName = "";
    var shareEditName=""
    var shareEditDescription=""
    var campaignInstructions=null
    var shareImage=""
    var ispublish=true

    if (this.props.campaignData != null) {
      campaignImage = this.props.campaignData.Data[0].Campaign.campaignImage;
      ispublish = this.props.campaignData.Data[0].Campaign.isPublish;
      companyLogo = this.props.campaignData.Data[0].Company.logo;
      heading = this.props.campaignData.Data[0].Campaign.heading;
      startDate = this.props.campaignData.Data[0].Campaign.startDate;
      endDate = this.props.campaignData.Data[0].Campaign.endDate;
      shareUrlFb = this.props.campaignData.Data[0].ShareCampaignUrl[0]
        .campaignShareUrl;
      shareUrlLinklind = this.props.campaignData.Data[0].ShareCampaignUrl[1]
        .campaignShareUrl;
      shareUrltiwiter = this.props.campaignData.Data[0].ShareCampaignUrl[2]
        .campaignShareUrl;
      description = this.props.campaignData.Data[0].Campaign.description;
      companyName = this.props.campaignData.Data[0].Company.CompanyName;
      shareEditName= this.props.campaignData.Data[0].ShareEdit[0].campaign_heading
      shareEditDescription=this.props.campaignData.Data[0].ShareEdit[0].campaign_Description
      campaignInstructions=this.props.campaignData.Data[0].ShareEdit[0].campaignInstructions
      shareImage = this.props.campaignData.Data[0].ShareEdit[0].campaignImage
    }
    return (
      <div className={classes.outerWrapper}>
        <div className={classes.innerWrapper}>
          <div className={classes.topDiv} onClick={this.zoomImage}style={{cursor:"pointer"}}>
            <Modal
              basic
              onClose={this.colseImage}
              onOpen={this.zoomImage}
              open={this.state.isZoom}
              size='large'            
            >  
              <Modal.Content>  
              <img src={shareImage} />     
              </Modal.Content>    
            </Modal>
            {/* <img className={classes.background} src={shareImage} /> */}
            <img className={classes.foreground} src={shareImage?shareImage:defaultCamImage} />
          </div>
          <div className={classes.bottomDiv}>
            <div className={classes.row1}>
              <div className={classes.inner}>
                <img src={companyLogo ? companyLogo : defaultCompany} />
                <div className={classes.right}>
                  <div className={classes.title}>{shareEditName}</div>
                  <div className={classes.type}>{companyName}</div>
                </div>
              </div>
            </div>
            <div className={classes.row2}>
              <div className={classes.left}>
                <div className={classes.dateAndDuration}>
                  <div className={classes.start}>
                    <div className={classes.title}>START DATE</div>
                    <div className={classes.date}>
                      {moment(startDate).format("DD-MMMM-YYYY")}
                    </div>
                  </div>
                  <div className={classes.duration}>
                    <div className={classes.title}>DURATION</div>
                    <div className={classes.value}>
                      {this.getDaysCount(startDate, endDate)}
                    </div>
                  </div>
                </div>
                <div className={classes.images}>
                  {/* <FacebookShareButton url={shareUrlFb}>
                    <img src="https://i.imgur.com/Dmq6kQH.png" />
                  </FacebookShareButton>
                  <LinkedinShareButton url={shareUrlLinklind}>
                    <img src="https://i.imgur.com/yuQSEcc.png" />
                  </LinkedinShareButton>
                  <TwitterShareButton url={shareUrltiwiter}>
                    <img src="https://i.imgur.com/RTyPUtu.png" />
                  </TwitterShareButton> */}
                  {/* {
                  this.props.Loading
                    ? null
                    : this.props.campaignData.Data[0].ShareCampaign.map(res => (
                        <>
                          <a href={res.campaignShareUrl}>
                            {res.socialMedia.trim() == "FACEBOOK" ? (
                              <img src="https://i.imgur.com/Dmq6kQH.png" />
                            ) : null}
                            {res.socialMedia.trim() == "TWITTER" ? (
                              <img src="https://i.imgur.com/RTyPUtu.png" />
                            ) : null}
                            {res.socialMedia.trim() == "LINKEDIN" ? (
                              <img src="https://i.imgur.com/yuQSEcc.png" />
                            ) : null}
                          </a>
                       </>
                  ))} */}
                </div>
              </div>
              <div className={classes.right}>
                {ispublish ?
                  <button
                    className={classes.btnStyle}
                    onClick={this.onButtonClick}
                  > Apply
                  </button>
                 :
                  <div
                    className={classes.btnClose}
                  > Applications closed
                </div>                
                }
              </div>          
            </div>
            <div className={classes.row3}>
              <div className={classes.descriptionText}>
                {/* {shareEditDescription} */}
                <div dangerouslySetInnerHTML={{ __html: shareEditDescription }} />
                </div>
            </div>
            <div className={classes.row4}>
              <h4 className={classes.title}>Instructions</h4>

               {campaignInstructions == null ?
              <ul className={classes.cs_ul}>
                <li>
                  <div className={classes.each}>
                    Please upload your latest CV
                  </div>
                </li>
                <li>
                  <div className={classes.each}>
                    Ensure that you have a stable internet connetion for the
                    video pitch
                  </div>
                </li>
                <li>
                  <div className={classes.each}>
                    Check your web cam and Mic.
                  </div>
                </li>
              </ul> : <div dangerouslySetInnerHTML={{ __html: campaignInstructions }} />
              }

            </div>
            <div className={classes.row5}>
            <div className={classes.row2}>
                {this.props.auth == true ?
                  <button onClick={this.goDashoard} style={{ width: '180px' }}>View dashboard</button>
                  : null}
              </div>
              {/* <h4 className={classes.title}>Useful links</h4>
              <ul className={classes.cs_ul}>
                <li>
                  <div className={classes.each}>Our mission</div>
                </li>
                <li>
                  <div className={classes.each}>Sales Analytics</div>
                </li>
                <li>
                  <div className={classes.each}>CSR</div>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        {this.state.applyLoding ? (
          <FlowControl
            closeCampaign={this.closeCampaign}
            campaign={this.props.campaignData.Data[0].Campaign}
            compaigns={this.props.compaign.Data}
            index={0}
            share={true}
          ></FlowControl>
        ) : null}
      </div>
      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    campaignData: state.CampaignByRef.campaignData,
    candidateDate: state.CandidateData.userData,
    compaign: state.CampaignByRef.availableCampaign,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onApplyClick: (data) => dispatch(actionsCreator.applyByShare(data)),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
      getAvailableCampaignType: (data) =>
      dispatch(actionsCreator.getAvailableCampaignSlider(data)),
      onGetCampaignByRef: data => dispatch(actionsCreator.getCampaignByRef(data))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignShare)
);
