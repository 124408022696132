import React, { Component } from "react";
import PastCampaign from "../Campaign/PastCampaign";
import ReactPaginate from "react-paginate";

class Past extends Component {
  constructor(props){
    super(props)
    this.state={
      offset: 0,
      data: [],
      more: false,
      elements: [],
      perPage: 5,
      pageCount: 0,
      currentPage: 0,
    }
  }

  handlePageClick = (data) => {
    if (data != null){
      const selectedPage = data.selected;
      const offset = selectedPage * this.state.perPage;
      this.setState({ currentPage: selectedPage, offset: offset }, () => {
        this.setElementsForCurrentPage();
      });
    }
  };

  componentDidMount(){
    this.setElementsForCurrentPage();
  }

  setElementsForCurrentPage = () => {

    let pastCampaign = ''
    console.log(this.props.data, "past campaigns stall")
    if (this.props.data != null){
      if (this.props.data[0].length > 0){
        this.setState({pageCount:Math.ceil(this.props.data[0].length / this.state.perPage)})
        pastCampaign = this.props.data[0]
        .slice(this.state.offset, this.state.offset + this.state.perPage)
        .map((res, index) => {
          return (
            <PastCampaign data={res} key={index} />
          )
        })
        this.setState({ elements: pastCampaign });
      }
    }
  }

  render() {
    // let pastCampaign = ''
    // pastCampaign = this.props.data[0].map((res, index)=>{
    //   return(
    //     <PastCampaign data={res} key={index}/>
    //   )
    // })
    return (
      <div style={{ marginTop: "30px" }}>
        <div>
          <ul>{this.state.elements}</ul>
          {this.state.pageCount > 1 ?
          <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={<span className="gap">...</span>}
                pageCount={this.state.pageCount}
                onPageChange={this.handlePageClick}
                forcePage={this.state.currentPage}
                containerClassName={"pagination manage-dash-pagination"}
                previousLinkClassName={"previous_page"}
                nextLinkClassName={"next_page"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
              />:null
          }
        </div>
      </div>
    );
  }
}
export default Past;
