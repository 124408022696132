import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Login.module.scss";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import "./index.css";
import Dashbord from "../../containers/Dashbord";
import dashboard from "material-ui/svg-icons/action/dashboard";
import { createButton } from "react-social-login-buttons";
import { LinkedInLoginButton } from "react-social-login-buttons";

export class LinkedIn extends Component {
  static propTypes = {
    className: PropTypes.string,
    onFailure: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
    redirectUri: PropTypes.string.isRequired
  };

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage, false);
    if (this.popup && !this.popup.closed) this.popup.close();
  }

  getUrl = () => {
    //redirectUri = "http://localhost:3001"
    let { redirectUri, clientId, state, scope } = this.props;
    console.log(redirectUri, "Redirect url");
    console.log(clientId, "Client id")
    console.log(state, "State")
    console.log(scope, "Scopes")
    // TODO: Support IE 11
    scope = "r_emailaddress r_liteprofile "
    clientId = "81uqx1p2ufxx57"
    state = "34232423"
    const scopeParam = scope ? `&scope=${encodeURI(scope)}` : "";
    console.log(scopeParam, "Scope params")

    const linkedInAuthenLink = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${scopeParam}&state=${state}`;
    console.log(linkedInAuthenLink, "Auth link");    

    return linkedInAuthenLink;
    
  };

  receiveMessage = event => {
    console.log(event, "will recieve message")
    let { redirectUri, clientId, state, scope } = this.props;
    
    clientId = "81uqx1p2ufxx57"

    console.log(event.origin,"Event origin ###########")
    console.log(window.location.origin, "Event window location #########")
    
    if (event.origin === window.location.origin) {
      console.log("event data:", event.data);

      if (event.data.errorMessage && event.data.from === "Linked In"){
        alert("You are cancelled LinkedIn login");
        this.props.onFailure(event.data);
        this.popup && this.popup.close();
      } else if (event.data.code && event.data.from === "Linked In") {
        console.log("Successfully connected");
        console.log(event.data);

        localStorage.setItem("Linkedin_authLink", event.data.code);

        var redirectUri_new = "http://localhost:3002/linkedin"

        const linkedInAuthenLink = `https://www.linkedin.com/oauth/v2/accessToken?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri_new}&grant_type=authorization_code&client_secret=XjFqkNl5XgfQQqpn&code=${event.data.code}`;
        console.log(linkedInAuthenLink, "Auth  access token");

        this.props.onSuccess({ code: event.data.code });
        this.popup && this.popup.close();

      }
    }
  };

  handleConnectLinkedInClick = e => {
    console.log("handleConnectLinkedInClick");

    if (e) {
      e.preventDefault();
    }

    this.props.onClick && this.props.onClick();

    this.popup = window.open(this.getUrl(), "_blank", "width=600,height=600");

    console.log(this.popup, "pop up")
    window.removeEventListener("message", this.receiveMessage, false);
    window.addEventListener("message", this.receiveMessage, false);

  };

  render() {
    const { className, disabled, children } = this.props;
    return (
      <button
        style={{
         // background: "linear-gradient(to right, #ffffff 0%, #ffffff 100%)",
          margin: "0px !important",
          boxShadow: "0 0 0px 0px #0009"
        }}
        type="button"
        onClick={this.handleConnectLinkedInClick}
        className={className}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
}
LinkedIn.defaultProps = {
  className: "btn-linkedin",
  Text: "Login with Linkedin",
  disabled: false,
  //childre'n:<FontAwesomeIcon icon={faLinkedinIn} className={classes.LinkedIn} Text={'Login with Linkedin'} />,
  children: <LinkedInLoginButton />,
  state: "fdsf78fyds7fm"
};

export default LinkedIn;
