import React, { Component } from 'react'
import Logo from '../../components/Navbar/Logo/Logo'
import Menu from '../../components/Navbar/Menu/Menu'
import LogoImage from '../../assets/image/sia-logo.png'
import classes from './Navbar.module.scss'
// import VideoPitch from '../../components/UI/VideoPitch/VideoPitch'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {NavLink,Link} from 'react-router-dom'
import {faUser as farUser, faBell as farBell} from '@fortawesome/free-regular-svg-icons'
import { faUserCog, faTachometerAlt,faBell, faPlus, faPlusCircle, faVideo } from '@fortawesome/free-solid-svg-icons';

export class Navbar extends Component {
    
    logoutHandler = () => {
        localStorage.removeItem('chat-token')
        localStorage.removeItem('profile-image')
        this.props.history.push('/auth')
    }

    render() {
        return (
            <header className={classes.Header}>
                <div className={classes.Container}>
                    <Logo image={LogoImage} />
                    {/*<VideoPitch />*/}
                    <Menu logout={this.logoutHandler} />
                </div>
            </header>
        )
    }
}


export default Navbar
