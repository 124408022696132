import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import App from "../src/App";
import axios from '../src/axios';
import Companylanding from '../src/containers/Landing/CompanylandingAlt';
import ErrorPage from '../src/ErrorPage';
import PublicLanding from '../src/PublicLanding';
import Landing from '../src/containers/Landing/Landing';

class MiddleWare extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            returnCom: null
        };
    }

    componentDidMount = () => {

        console.log(window.location.href,'abc#####')

        //CHECK HAS SUB DOMAIN
        var hasSubDomain = this.hasSubDomain(window.location.href)

        console.log(hasSubDomain, "Has sub domain")

        if (hasSubDomain === true) {
            var isSubdomain = window.location.hostname.split('.')[0]
            console.log(isSubdomain, "is Sub domain")
            console.log(process.env.REACT_APP_ENV, "react app env")
            if (isSubdomain === process.env.REACT_APP_ENV) {
                console.log("no Sub domain")
                var hasParams = window.location.href.split("/")
                if (hasParams.reverse()[0] !== "") {
                    this.setState({
                        returnCom: <App />
                    })
                }
                else {
                    this.setState({
                        // returnCom: <PublicLanding/>
                        returnCom: <Landing />
                        //returnCom: <App/>
                    })
                }

            }
            else if (isSubdomain !== process.env.REACT_APP_ENV) {
                console.log(isSubdomain, "has Sub domain company")
                axios.get('verify-sub-domain/?subDomain=' + isSubdomain + "&product=UFAIRS", {
                    headers: {
                        'Content-type': `application/json`,
                    },
                }).then(res => {
                    console.log(res.data.Data.flag, 'result domain')
                    var hasParams = window.location.href.split("/")
                    console.log(hasParams.reverse(), "has params array")
                    console.log(hasParams.reverse()[0], "has params #########")
                    if (hasParams.reverse()[0] === "") {
                        if (res.data.Data.flag === true) {
                            this.setState({
                                returnCom: <Companylanding subDomain={isSubdomain} />
                            })
                        }
                        else {
                            if (isSubdomain === "ufairs"){
                                this.setState({
                                    //returnCom: <ErrorPage />
                                    returnCom: <Landing />
                                })
                            }
                            else{
                                this.setState({
                                    //returnCom: <ErrorPage />
                                    returnCom: <Landing />
                                })
                            }
                            
                        }
                    }
                    else if (hasParams.reverse()[0] !== "") {
                        if (res.data.Data.flag === true) {

                            this.setState({
                                returnCom: <App />
                            })
                        }
                        else {
                            this.setState({
                                //returnCom: <ErrorPage />
                                returnCom: <Landing />
                            })
                        }

                    }

                })
            }
        }
        else if (hasSubDomain === false) { 
            var hasParams = window.location.href.split("/")
            if (hasParams.reverse()[0] !== "") {
                this.setState({
                    returnCom: <App />
                })

            }
            else {
                this.setState({
                    returnCom: <Landing />
                })
                // this.setState({
                //     returnCom: <PublicLanding />
                // })
            }
        }
    }

    hasSubDomain = (url) => {

        // IF THERE, REMOVE WHITE SPACE FROM BOTH ENDS
        url = url.replace(new RegExp(/^\s+/), ""); // START
        url = url.replace(new RegExp(/\s+$/), ""); // END

        console.log(url, "Url 1")

        // IF FOUND, CONVERT BACK SLASHES TO FORWARD SLASHES
        url = url.replace(new RegExp(/\\/g), "/");

        console.log(url, "Url 2")

        // IF THERE, REMOVES 'http://', 'https://' or 'ftp://' FROM THE START
        url = url.replace(new RegExp(/^http\:\/\/|^https\:\/\/|^ftp\:\/\//i), "");

        console.log(url, "Url 3")

        // IF THERE, REMOVES 'www.' FROM THE START OF THE STRING
        url = url.replace(new RegExp(/^www\./i), "");

        console.log(url, "Url 4")

        // REMOVE COMPLETE STRING FROM FIRST FORWARD SLASH ON
        url = url.replace(new RegExp(/\/(.*)/), "");

        console.log(url, "Url 5")

        // REMOVES '.??.??' OR '.???.??' FROM END - e.g. '.CO.UK', '.COM.AU'
        if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
            url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), "");

            // REMOVES '.??' or '.???' or '.????' FROM END - e.g. '.US', '.COM', '.INFO'
        } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
            url = url.replace(new RegExp(/\.[a-z]{2,4}$/i), "");
        }

        console.log(url, "Url 6")

        // CHECK TO SEE IF THERE IS A DOT '.' LEFT IN THE STRING
        var subDomain = (url.match(new RegExp(/\./g))) ? true : false;

        return(subDomain);
    }

    render() {
        return (
            <div>
                {/* <App/> */}
                {this.state.returnCom}
            </div>
        )
    }
}

export default MiddleWare