import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Progress.module.scss";
import * as actions from "../../../../../store/actions/index";
import Spinner from "../../../../UI/SpinnerGrey/SpinnerGrey";
import ProgressBar from "@ramonak/react-progress-bar";

class Progress extends Component {
  render() {
    var data = "";
    var lable = "";

    if (this.props.uploadedStatus !== null) {
      data = false;
      this.props.onUpdateProgress(data);
    }

    return (
      <div className={classes.wrapper}>
        <div className={classes.inner}>
          <h4
            style={{ textAlign: "center", marginBottom: "40px", color: "grey" }}
          >
            Your CV is getting uploaded
          </h4>
          <Spinner />
          <ProgressBar bgColor="#1697FA" completed={this.props.percentage} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadedStatus: state.chatnew.cvUploaded,
    uploadStatusCoverLetter: state.chatnew.uploadCoverLetter,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateProgress: (data) => dispatch(actions.updateProgress(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
