const isPayload = (text) => {


    if (text[0] === "/") {
        const replaced = text.replace(/[/_]/g, (a, b) => {

            if (a === "/") {
                return a = ""
            }
            if (a === "_") {
                return a = " "
            }
        })
        return replaced.replace(replaced[0],replaced[0].toUpperCase())
    }
    return text
}
export default isPayload