import React, { Component } from "react";
import Video from "twilio-video";
import axios from "../../axios";
import classes from "./VideoChat.module.scss";
import "./videoChat.scss";
import ChatQueue from "../../components/ChatQueue/ChatQueue";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { Card, CardHeader, CardText } from "material-ui/Card";
import { identity } from "react-smooth/lib/util";
import Actions from "../../store/actions/Auth";
import Comment from "../../components/InterviewerComment/Index";
import {
  faVideo,
  faVideoSlash,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountDown from "./CountDownTimer/Index/Count";
import { connect } from "react-redux";
import ObjectChecker from "../../hoc/objectChecker";
import * as actionCreater from "../../store/actions/index";
import Timer from "../../components/Webinnar/CloclTime/Time";
import Spinner from "../../components/UI/Loading/Spinner/Spinner";
import swal from "sweetalert2";
import moment from "moment";
import CountdownTimer from "react-component-countdown-timer";
import { isEmpty, first } from "lodash";
import Modal from "react-modal";
import WhiteBoard from "./WhiteBoard"
//import Timer from '../../components/Webinnar/CloclTime/Time'

class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.activeRoom = "";
    this.previewTracks = "";
    this.identity = "";
    this.roomName = "chatRoom";
    // this.roomJoined = this.roomJoined.bind(this);
    // this.detachTracks = this.detachTracks.bind(this);
    // this.getDomSpeaker = this.getDomSpeaker.bind(this);
    // this.detachParticipantTracks = this.detachParticipantTracks.bind(this);
    this.state = {
      twilio_token: null,
      callStarted: false,
      callStarting: false,
      videoEnabled: true,
      audioEnabled: true,
      URL: process.env.REACT_APP_API_URL + "manager",
      buttondisable: true,
      dominentSpeaker_id: "",
      participents_chanel: [],
      participentTrack: [],
      trackDOM: "",
      trackNew: "",
      tracksPart: "",
      idPart: [],
      domSID: "",
      chanelTracks: [],
      partTracks: [],
      partIds: [],
      comments: [],
      domienentParticipents: [],
      interviewData: this.props.intData,
      token : localStorage.getItem("chat-token"),
      roomName:"",
      roomStartTime : null,
      roomEndTime: null,
      countTime : null,
      scheduled_interviewTime : null,
      state_timeChange : false
    };
    this.updateCurrentTime = this.updateCurrentTime.bind(this);
    this.getcurrentRef = this.getcurrentRef.bind(this); 
  }
  room = null;
  currentSpeaker = null;
  identity = "";

  getcurrentRef=()=>{
    var id = localStorage.getItem("CandidateProfile_id02")
    var ref = sessionStorage.getItem("vasRef")
    axios
    .get("vas-current-call-ref/?candidateId="+id+"&currentRef="+ref+"&iscallStarted=1", {
      headers: {
        Authorization: `token ${this.state.token}`,
      },
    })
    .then((res) => { 
      var refNew = res.data.Data.refData.roomRef 
      var status = res.data.Data.refData.status
      this.props.updateRoomData(res.data.Data)
      var isNewRoom = false;
      if (ref != refNew) {
        if (status == true) {
          isNewRoom = true
        } else {
          this.room.disconnect(); 
        }
      }      
      sessionStorage.setItem("vasRef",refNew)
      this.setToken(res.data.Data.refData.roomRef,isNewRoom)
    });
  }

  componentDidMount() {
    const interval = setInterval(() => {
      this.getcurrentRef()
      this.updateCurrentTime()
    }, 1000);
    return () => clearInterval(interval);
  } 


  setToken = (ref,isNewRoom) => { 
    var ref= ref;
    axios
      .get("/vas-get-group-data/?ref=" + ref, {
        headers: {
          Authorization: `token ${this.state.token}`,
        },
      })
      .then((res) => { 
        var id = res.data.Data.interviewRoom
        var isGroup = res.data.Data.isSmallRoom
        var groupId = 0
        if (isGroup == true) {
          groupId = res.data.Data.activityRoom
        } 
        var data = {
          interviewId: id,
          smallGroupId: groupId,
          status: 0,
        } 
         axios
          .post("vas-call/",data, {
            headers: {
              Authorization: `token ${this.state.token}`,
            },
          })
          .then((res) => {
            var token= res.data.twilio_token
            this.setState({twilio_token:token,roomName:groupId},()=>{
              console.log(isNewRoom,"room")
               if(isNewRoom==true){
                 this.switchToGroupCall()
                 this.getRoomData(data)
               }
            }) 
          });
      });
  };

  getInterviewData = () => {
    this.setState(
      {
        interviewData: this.props.intwData,
      },
      () => console.log(this.props.intwData, "Interview data 777")
    );
  };
  getRealTime = () => {
    setInterval(() => this.getCandidateComments(), 10000);
  };

  updateCurrentTime = () => {

    console.log("update time");

    try {

      var startTime = this.state.roomStartTime
      var endTime = this.state.roomEndTime
      var currentTime = moment().toISOString(true).split("+")[0];

      var intTime = moment(startTime[0]).toISOString(true).split("+")[0];

      console.log(currentTime, "current time pre");
      console.log(intTime, "Int time pre");

      console.log(moment(currentTime), "current time momen");
      console.log(moment(intTime), "interview time moments");

      var ms = moment(intTime).diff(moment(currentTime));
      var d = moment.duration(ms);
      var s = moment.duration(d);

      this.setState({
        countTime: ms,
      });

      if (parseInt(ms) > parseInt(this.state.scheduled_interviewTime)) {
        this.setState({
          scheduled_interviewTime: ms,
          state_timeChange: true,
        });
      } else {
        this.setState({
          state_timeChange: false,
          scheduled_interviewTime: 0
        });
      }
    } 
    catch {
      console.log("pending");
    }
  };

  getRoomData =(data)=>{

    var isSmallRoom = 0
    var requestId = 0

    if (data.smallGroupId === 0){
      isSmallRoom = 0
      requestId = data.interviewId
    }

    else{
      isSmallRoom = 1
      requestId = data.smallGroupId
    }

    axios.get('vas-get-activity-room-data/?roomID='+requestId+"&isSmallRoom="+isSmallRoom,{
      headers: {
        Authorization: `token ${this.state.token}`,
      },
    }).then(res=>{
      console.log("get group data")
      this.setState({
        roomStartTime : res.data.Data.startTime,
        roomEndTime : res.data.Data.endTime
      })

    })
  }

  switchToGroupCall=()=>{
    if (this.room) {
    this.room.disconnect(); 
    }
    this.startCall()
  }

  leaveRoomIfJoined() {
    if (this.room) {
      this.room.disconnect();
    }
  }
  toggleCall = () => {
    this.state.callStarted ? this.endCall() : this.startCall();
  };
  startCall= async ()=> {

    const localVideoTrack = await Video.createLocalVideoTrack();
    this.setState({ localVideoTrack });

    const localAudioTrack = await Video.createLocalAudioTrack();
    this.setState({ localAudioTrack });

    var connectOptions = {
      dominantSpeaker: true,
      name: this.state.roomName,
      tracks: [localVideoTrack, localAudioTrack],
      insights: false
    }; 
    this.setState({ callStarting: true });
    Video.connect(this.state.twilio_token, connectOptions).then(
      this.roomJoined,
      (error) => {
        // this.log("Could not connect to Twilio: " + error.message);
        this.setState({
          callStarting: false,
        });
        if (error.message === "Permission denied") {
          // alert(error.message);
          swal.fire({
            position: "top-end",
            type: "warning",
            title: "Error: Camera/Mic permission denied",
            text: "Please re-check your browser settings",
            showConfirmButton: false,
            timer: 7000,
            customClass: { container: "swal-container-flow-warnings" },
            imageHeight: 20,
          });
        }
      }
    );
  }
  endCall() {
    this.room.disconnect();
    // localStorage.setItem("CALL_STATUS", false);
    // localStorage.setItem("Video_chat_complete", true);
    // localStorage.removeItem("Open_video_conf_sia");  
  }
  toggleAudio = () => {
    this.room.localParticipant.audioTracks.forEach((publication) => {
      this.state.audioEnabled
        ? publication.track.disable()
        : publication.track.enable();
    });
    this.setState({
      audioEnabled: !this.state.audioEnabled,
    });
  };
  toggleVideo = () => {
    if (!this.state.videoEnabled) {
      Video.createLocalVideoTrack().then((track) => {
        const localMediaContainer = document.querySelector(
          `#participant-${this.room.localParticipant.identity}`
        );
        localMediaContainer.appendChild(track.attach());
        return this.room.localParticipant.publishTrack(track);
      });
    } else {
      const publication = [
        ...this.room.localParticipant.videoTracks.values(),
      ][0];
      const track = publication.track;
      track.stop();
      track.detach().forEach((element) => element.remove());
      this.room.localParticipant.unpublishTrack(track);
    }
    this.setState({
      videoEnabled: !this.state.videoEnabled,
    });
  };
  attach(track, container) {
    container.appendChild(track.attach());
  }
  detach(track) {
    if (!track) return;
    track.detach().forEach((element) => {
      // element.parentNode.remove();
      element.remove();
    });
  }
  detachParticipantTracks(participant) {
    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        this.detach(publication.videoTracks);
      }
    });
  }
  detachCurrentSpeakerTrackIfExists() {
    var el = this.refs.currentSpeaker.querySelector("video");
    if (this.currentSpeaker && this.getCurrentSpeakerTrack()) {
      this.getCurrentSpeakerTrack().detach(el).remove();
    }
  }
  getCurrentSpeakerTrack() {
    return this.currentSpeaker.videoTracks.values().next().value.track;
  }
  createElementForParticipant(participant) {
    const id = `participant-${participant.identity}`;
    var el = document.querySelector(`#${id}`);
    if (el) return el;
    el = this.refs.participantsPreview.appendChild(
      document.createElement("div")
    );
    el.id = id;

    el.classList.add("participant");
    el.classList.add("participantVideos");
    el.style.cssText =
      "position:relative;overflow:hidden; background-color:white;display:flex;margin: 0 .5rem;width: 120px;";

    return el;
  }

  roomJoined = (room) => {
    this.setState({
      callStarted: true,
      callStarting: false,
    });
    this.room = room;
    room.localParticipant.tracks.forEach((publication) => {
      this.attach(
        publication.track,
        this.createElementForParticipant(room.localParticipant)
      );
    });
    room.participants.forEach(this.participantConnected);
    room.on("participantConnected", this.participantConnected);
    room.on("dominantSpeakerChanged", this.dominantSpeakerChanged);
    room.on("participantDisconnected", this.participantDisconnected);
    room.on("disconnected", this.disconnected);
  };
  participantConnected = (participant) => {
    console.log("participant connected", participant.identity);
    const el = this.createElementForParticipant(participant);
    participant.on("trackSubscribed", (track) => {
      this.trackSubscribed(track, el);
    });
    participant.on("trackUnsubscribed", this.trackUnsubscribed);
    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        this.attach(publication.track, el);
      }
    });
  };
  trackSubscribed = (track, container) => {
    this.attach(track, container);
  };
  trackUnsubscribed = (track) => {
    this.detach(track);
  };
  dominantSpeakerChanged = (participant) => {
    if (!participant || participant.videoTracks.size < 1) return;
    this.detachCurrentSpeakerTrackIfExists();
    this.currentSpeaker = participant;
    this.attach(this.getCurrentSpeakerTrack(), this.refs.currentSpeaker);
  };
  participantDisconnected = (participant) => {
    this.detachParticipantTracks(participant);
  };
  disconnected = () => {
    this.room.participants.forEach((participant) => {
      this.detachParticipantTracks(participant);
    });
    this.detachCurrentSpeakerTrackIfExists();
    this.room.localParticipant.tracks.forEach((publication) => {
      this.detach(publication.track);
    });
    this.room = null;
    this.setState({
      videoEnabled: true,
      audioEnabled: true,
      callStarted: false,
    });
    this.currentSpeaker = null;
  };

  shareScreen = async () => {
    try {
      const { videoRoom, localVideoTrack, screenTrack } = this.state;

      if (!screenTrack) {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: true
        });
        const newScreenTrack = first(stream.getVideoTracks());

        this.setState({
          screenTrack: new Video.LocalVideoTrack(newScreenTrack)
        });

        this.room.localParticipant.publishTrack(newScreenTrack);
        this.room.localParticipant.unpublishTrack(localVideoTrack);
      } else {
        this.room.localParticipant.unpublishTrack(screenTrack);
        this.room.localParticipant.publishTrack(localVideoTrack);
        this.stopScreenTrack();
      }
    } catch (error) {
      this.stopScreenTrack();
      this.setState({
        errorMessage: error.message
      });
      console.log(error.message)
    }
  };
  stopTrack = trackName => {
    const track = this.state[trackName];

    if (track) {
      track.stop();
      this.setState({ [trackName]: null });
    }
  };

  stopScreenTrack = () => this.stopTrack("screenTrack");

  whiteBoard=()=>{
    this.setState({openWhiteBoard:true})
  }
  closeWhiteBoard=()=>{
    this.setState({openWhiteBoard:false})
  }

  render() {
    var panelDuration = ObjectChecker(this.props.intwData, ["PanelDuration"]);
    var statusInw=1 

    try{
      statusInw = this.props.inwStatus.status
    }
    catch{
      statusInw = 1
    }

    var intwStatus = true

    if (parseInt(statusInw) >= 2){
      intwStatus = false
    }

    var DISABLED = true;

    if (this.state.twilio_token !== null) {
      DISABLED = false;
    }
    let content;
    if (this.state.callStarted) {
      content = (
        <div className={classes.flexContainer}>
          <div className={classes.flexwrapper1} ref="participantsPreview" />
          <div className={classes.flexwrapper2} ref="currentSpeaker"></div>
          <div className={classes.flexwrapper3}>
            {this.state.callStarted && (
              <div className={classes.chatIntwTimer}>
                {/* <Timer intCount={panelDuration} hideDays={true}/> */}
                {/* <CountdownTimer
                  count={differnt}
                  border
                  backgroundColor="#00000000"
                  color="unset"
                  hideDay={true}
                ></CountdownTimer> */}
              </div>
            )}
            {this.state.callStarted && (
              <div className={classes.callButtons}>
                {this.state.callStarting ? (
                  <button
                    className={classes.BlueBtn1}
                    onClick={this.toggleCall}
                  >
                    Connecting...
                  </button>
                ) : this.state.callStarted ? (
                  <button
                    className={classes.BlueBtnCut}
                    onClick={this.toggleCall}                 
                  >
                    <i className="fas fa-phone-slash" />
                  </button>
                ) : this.props.intwData === null ? (
                  <button
                    className={classes.BlueBtn1}
                    onClick={this.toggleCall}
                  >
                    waiting
                  </button>
                ) : this.props.intwData !== null ? (
                  <button
                    className={classes.BlueBtn1}
                    onClick={this.toggleCall}
                  >
                    Start Call
                  </button>
                ) : null}
                <button
                  className={classes.audioBtnon}
                  onClick={this.toggleAudio}
                >
                  {this.state.audioEnabled ? (
                    <i class="fas fa-microphone"></i>
                  ) : (
                    <i class="fas fa-microphone-slash"></i>
                  )}
                </button>

                <button
                  className={classes.videoBtnon}
                  onClick={this.toggleVideo}
                >
                  {this.state.videoEnabled ? (
                    <FontAwesomeIcon icon={faVideo} />
                  ) : (
                    <FontAwesomeIcon icon={faVideoSlash} />
                  )}
                </button>
                 <button
                    className={classes.videoBtnon}
                    onClick={this.shareScreen}
                  >
                    <i class="fab fa-creative-commons-share"></i>
                  </button>
                <button
                  className={classes.videoBtnon}
                  onClick={this.whiteBoard}
                >
                  <i class="fas fa-user-edit"></i>
                </button>
                <div className="upload-review-cv-modal">
                  <Modal
                    isOpen={this.state.openWhiteBoard}
                    onRequestClose={this.closeWhiteBoard}
                    contentLabel="Example Modal"
                    className="cv-upload-modal-1 "
                  >
                   <WhiteBoard openModal={this.whiteBoard} token={this.state.twilio_token}></WhiteBoard>        
                    <button
                      className={classes.closeButtton}
                      onClick={this.closeWhiteBoard}
                    >
                      close
                  </button>
                  </Modal>
                </div>
                <div>
                  <Timer timeChange={this.state.state_timeChange} intCount={this.state.countTime} hideHours={false} hideDays={false} />
                </div>
              </div>
            )}
            {/* <button className={classes.BlueBtn1} onClick={this.toggleCall}>
              {this.state.callStarting
                ? "Connecting..."
                : this.state.callStarted
                ? "End call"
                : this.props.intwData === null
                ? "waiting"
                : this.props.intwData !== null
                ? "Start Call"
                : null}
            </button> */}
            {/* {this.state.callStarted && <CountDown />} */}
            {this.state.callStarted}
          </div>
        </div>
      );
    } else {
      content = (
        <div className={classes.flexContainer}>
          <button
            className={classes.StartCallButton}
            onClick={this.toggleCall}
            disabled={DISABLED}
          >
            {this.state.callStarting ? (
              "Connecting"
            ) : this.state.twilio_token === null ? (
              <Spinner />
            ) : (
              "Start Video Call"
            )}
          </button>
          <div style={{ marginTop: "10px", backgroundColor: "white" }}></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        {content}
        {/* <div className={classes.flexwrapper4}>
          <ChatQueue />
        </div> */}
        {/* {commentList != null ? (
          <div>
            <div>Feedback</div>
            {commentList}
          </div>
        ) : null} */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    intwData: state.CandidateData.interviewData,
    tokenData: state.CandidateData.twillioToken,
    inwStatus : state.chatnew.intwStatus
  };
};
const mapDispatchToProps = (dispatch) => {
  return{
    updateCandidateInterviewState : (data) => dispatch(actionCreater.updateInterviewState(data)),
    updateRoomData:(data)=>dispatch(actionCreater.updateRoomData(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoComponent);
