import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import classes from "../Atends.module.scss";
import atendee2 from "../../../../assets/image/imagetestatend1.png";
import { Button, Card, Image, Icon } from "semantic-ui-react";

class Participent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            attendes: null
        }
    }

    render() {
        console.log(this.props.data, "attendees individual")
        return (
            <div >
            <Card className={classes.sizeC}>
              <Image src={this.props.data.profile[0].picture_url==""?atendee2:this.props.data.profile[0].picture_url} className={classes.atendOne} wrapped ui={true} />
              <Card.Content className={classes.cusContent}>
                <Card.Header>{this.props.data.profile[0].first_name + " " + this.props.data.profile[0].last_name}</Card.Header>
                 </Card.Content>   
            </Card>
            </div> 

                

            // <div className={classes.atendOne}>
            //     <div className={classes.imgGrid}>
            //         <img src={atendee2} className={classes.imgPersonal} />
            //     </div>
            //     <div className={classes.content}>
            //         <div className={classes.LabelsFull}>
            //             <label>{this.props.data.profile[0].first_name+" "+this.props.data.profile[0].last_name}</label>
            //             <label className={classes.LabelsEadd}>{this.props.data.candidate[0].email}</label>
            //         </div>
            //         <div className={classes.footerContent}>
            //             <label className={classes.footerContent1}><i class="fa fa-phone-square" aria-hidden="true"></i></label>
            //             <label className={classes.footerContent2}>{this.props.data.profile[0].phone_number}</label>
            //         </div>
            //     </div>
            // </div>
        )
    }
}

export default (Participent)