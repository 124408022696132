import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import * as actionsCreator from "../../../store/actions/index";
import Stall from '../Stalls/Stall/Stall';
import Carousel, { consts } from "react-elastic-carousel";
import { Button, Card, Image, Icon } from "semantic-ui-react";
import classes from "./exhall.module.scss";
import Backpng from "../../../assets/image/ufairs.png";
import Hall from "./Hall"



class Exhibition extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentNum: 0,
            stallImage: "",
            stallId: "",
            stallName: "",
            abc: [1]
        }
    }
    componentDidMount() {
        this.props.onAccessStall(false);
    }

    viewStall=(id)=>{
        // alert(id)
       this.props.goStall(id,1)
    }

    // viewStall=(id)=>{
    //     // alert(id)
    //    this.props.goStall(id,2)
    // }

    myArrow({ type, onClick, isEdge }) {
        const pointer = type === consts.PREV ? (<i className="fa fa-chevron-left" aria-hidden="true"></i>) : (<i className="fa fa-chevron-right" aria-hidden="true"></i>);
        return (
          <button className={classes.back_btn1} onClick={onClick} disabled={isEdge}>
            {pointer}
          </button>
        );
      }
    render() {
        var breakPoints = [
            { width: 1, itemsToShow: 1, pagination: false },
            { width: 530, itemsToShow: 2, itemsToScroll: 1, pagination: false },
            { width: 550, itemsToShow: 3, itemsToScroll: 1, pagination: false },
            { width: 850, itemsToShow: 4, pagination: false },
            { width: 1150, itemsToShow: 4, itemsToScroll: 2, pagination: false },
            { width: 1450, itemsToShow: 5, pagination: false },
            { width: 1750, itemsToShow: 6, pagination: false },
        ];
        return (
            <>  
                {/* <div>
                         <button onClick={this.prev} className={classes.back_btn1} > <i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                </div>           */}
                {
                    this.props.eventData.exhibitionHalls.length > 0?
                <Carousel
                    renderArrow={this.myArrow}
                    enableAutoPlay={this.state.isAutoPlay}
                    autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                    focusOnSelect={true}
                    onPrevEnd={this.onPrevEnd}
                >
                    {this.props.eventData.exhibitionHalls.map((res, index) => {
                        return (
                            <div key={index}>
                                {/* <Card className={classes.nc_ui} >
                                    <Card.Content className={classes.contentNew} style={{textAlign:"center"}}>
                                        <Image src={ Backpng}/>
                                        <Card.Header className={classes.exhName}>{res.exibitionHalls.hallName}</Card.Header>
                                    </Card.Content>
                                </Card> */}
                                <Hall setStall={this.viewStall} data={res}></Hall>
                            </div>
                        );
                    })}
                </Carousel>:
                (<div className={classes.instructionWrapper}>
                  <div className={classes.instructions}>
                    <div className={classes.heading}>
                      <span>Exhibition Hall will be updated soon!</span>
                    </div>
                    {/* <div className={classes.descr}>
                      Wanna know more about the ufairs?
                    </div>
                    <a href="#">Yes</a> */}
                  </div>
                </div>)
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onAccessStall: (data) => dispatch(actionsCreator.AccessStall(data)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Exhibition)
);