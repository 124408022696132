import React, { Component } from "react";
import classes from "./QueueList.module.scss";
import { connect } from "react-redux";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import SpinnerGrey from "../../UI/SpinnerGrey/SpinnerGrey";
import QueueElement from "../../ChatQueue/QueueList/QueueElement/Element";
class QueueList extends Component {
  render() {
    var queueData = "";
    try { 
      queueData = this.props.data.map((res, index) => {
        return <QueueElement data={res} key={index} />;
      });
    } catch {
      queueData = "";
    }
    return (
      <div>
        {this.props.data === null ? (
          <div className="ui-loader-wrapper">
            <Loader active />
          </div>
        ) : (
          <ul className={classes.queueInConference}>{queueData}</ul>
        )}
      </div>
    );
  }
}

export default QueueList;
