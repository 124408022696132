import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionsCreator from "../../../src/store/actions/index";
import PhonePanel from "../../components/OnBoard/Phone";
import LocationPanel from "../../components/OnBoard/Location";
import CVPanel from "../../components/OnBoard/CvUploadOnboard";
import Layout from "../../../src/components/Layout/Layout";
import FilteredData from "../../components/OnBoard/cvFilteredData/FilteredDataOnboard/FilteredDataOnboard";
import Preferences from "../../components/OnBoard/CareerPreferences/Preferences";

class OnBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Phone: "",
      Cv: "",
      Country: "",
      Zone: "",
      Step: 0,
    };
    this.changeStep = this.changeStep.bind(this);
    this.PhoneUpdate = this.PhoneUpdate.bind(this);
    this.StateZoneUpdate = this.StateZoneUpdate.bind(this);
    this.CvUpdate = this.CvUpdate.bind(this);
  }

  componentWillMount() {
    var CandidateProfile = sessionStorage.getItem("CandidateProfile");
  }

  changeStep() {
    // if (this.state.Step != 4) {
      var newStep = this.state.Step + 1;
      this.setState({ Step: newStep });
    // } else {
    //   this.SaveDetails();
    // }
  }

  PhoneUpdate(value) {
    this.setState({ Phone: value });
  }

  StateZoneUpdate(Country, Zone) {
    this.setState({ Country, Zone });
  }

  CvUpdate(value) {
    this.setState({ Cv: value });
  }

  filteredData(value) {
    this.setState({ filtered: value });
  }
  preferences(value) {
    this.setState({ preferences: value });
  }

  SaveDetails() {
    var Id = sessionStorage.getItem("CandidateProfile_id");
    var CandidateId = sessionStorage.getItem("CandidateProfile_id02");

    var data = {
      phone_number: this.state.Phone,
      timeZone: this.state.Zone,
      current_location: this.state.Country,
      onBoard_state: "COMPLETE",
      uploaded_at: new Date().toISOString(),
      upload: "",
      userId: CandidateId,
    };
    this.props.onBoardUser(Id, data);
  }
  render() {
    return (
      <>
        {this.props.loading ? null : (
          <Layout>
            {this.state.Step == 0 ? (
              <PhonePanel
                onDataSave={this.PhoneUpdate}
                onSkip={this.changeStep}
                stepNo={this.state.Step}
              />
            ) : null}
            {this.state.Step == 1 ? (
              <LocationPanel
                onDataSave={this.StateZoneUpdate}
                onSkip={this.changeStep}
                stepNo={this.state.Step}
              />
            ) : null}
            {/* {this.state.Step == 2 ? (
              <CVPanel
                onDataSave={this.CvUpdate}
                onSkip={this.changeStep}
                stepNo={this.state.Step}
              />
            ) : null} */}
            {/* {this.state.Step == 3 ? (
              <FilteredData
                onDataSave={this.filteredData}
                onSkip={this.changeStep}
                stepNo={this.state.Step}
              />
            ) : null} */}

            {/* {this.state.Step == 2 ? (
              <Preferences
                country={this.state.Country}
                zone={this.state.Zone}
                onDataSave={this.preferences}
                onSkip={this.changeStep}
                stepNo={this.state.Step}
              />
            ) : null} */}
            
          </Layout>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    phoneNumber: state.auth.phoneNumber,
    location: state.auth.location,
    cvString: state.auth.cvString,
    cvDataExp: state.auth.cvDataExp,
    cvDataEdu: state.auth.cvDataEdu,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onBoardUser: (id, data) => dispatch(actionsCreator.onboard(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBoard);
