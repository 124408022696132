import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import App from "../src/App";
import axios from '../src/axios';
import SingleEvent from '../src/components/UI/SingleEvent/SingleEvent'

class PublicLanding extends Component{

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            returnCom : null,
            isAPICall : false,
            publicEvents: [],
            hasNameEvent : 0,
            eventUrl : null
        };
    }

    componentDidMount = () => {
        //setInterval(() => this.getPublicEvents(), 1000)
        this.hasNameEvent()
    }

    hasNameEvent = () => {
        var logStat = null
        var logRef = null 
        console.log(sessionStorage.getItem("LogingReferencePublic"), "has name event")

        if (sessionStorage.getItem("LogingReferencePublic") === null){
            this.setState({
                hasNameEvent : 2 //redirect to landing page
            })
        } 
        else if (sessionStorage.getItem("LogingReferencePublic") !== null){
            axios.get('access-event/?loginRef='+sessionStorage.getItem("LogingReferencePublic"),{
                headers: {
                    'Content-type': `application/json`,
                },
            }).then(res=>{
                console.log(res, "Result")
                // this.setState({
                //     hasNameEvent : 1, //redirect to landing page
                // })
                if (res.data.Data.hasEvent === true){
                    this.setState({
                        hasNameEvent : 1,
                        eventUrl: res.data.Data.results[0]["shareUrl"]
                    })
                }
            })
            
        }

    }


    getPublicEvents =()=>{
        if (this.props.subDomain !== null){
            if (this.state.isAPICall === false){
                axios.get('company-live-events/?subDomain='+this.props.subDomain+"&product=UFAIRS",{
                    headers: {
                        'Content-type': `application/json`,
                    },
                }).then(res=>{
                    console.log(res, "results company live events")
                    this.setState({
                        isAPICall: true,
                        publicEvents:res.data.events
                    })
                })

            }
        }
    }

    render(){

        var returnData = null

        if (this.state.publicEvents.length > 0){
            if(this.state.publicEvents.length > 1){
                returnData = this.state.publicEvents.map((res,index)=>{
                    return (
                    <>
                        <SingleEvent eventData={res}/>
                    </>
                    )
                })
            }
        }

        if (this.state.hasNameEvent === 1){
            window.location.replace(window.location.href+this.state.eventUrl)
        }

        return(
            <div>
                {/* <App/> */}
                public landing
                {returnData}
            </div>
        )  
    }
}

export default PublicLanding