import React, { useState,useEffect } from "react";
import Popup200px from "../../UI/Popups/Popup200px/Popup200px";
import classes from "./ChatTabs.module.scss";
import { Icon } from "semantic-ui-react";
import Chat from "../Chat/Chat";
import ChatInterview from "../ChatInterview/ChatInterview";

function ChatTabsVas(props) {
  const [groupChat, setGroupChat] = useState(true);
  const [interviewChat, setInterviewChat] = useState(false);
  const [intwChatNotification, setIntwChatNotification] = useState(false);
  const [groupChatNotification, setgroupChatNotification] = useState(false);

  const handleTabGroup = () => {
    setGroupChat(true);
    setInterviewChat(false);
    setgroupChatNotification(false)
  };
  const handleTabInterview = () => {
    setInterviewChat(true);
    setGroupChat(false);
    setIntwChatNotification(false)
  };

  const interviewChatNotfy=()=>{ 
    //setIntwChatNotification(true)
  }

  useEffect(() => {
    getNotificationInterviewChat()
    getNotificationGroupChat()
  });

 const  getNotificationInterviewChat=()=>{
    const that = this;
    var host =process.env.REACT_APP_WEB_SOCKET_URL_INTERVIEW;
    var wsocket =new WebSocket(host);        

    wsocket.onopen=function(evt){
        console.log("Server connected ,Success");
    };
    wsocket.onmessage=function(evt)
    {  
        const dataFromServer = evt.data.split("||");
        if(dataFromServer[0] == "True")
         {
           if(dataFromServer[1] !=localStorage.getItem('CandidateProfile_id02')){
            setIntwChatNotification(true)
            }
         }   
    };
  }

  const  getNotificationGroupChat=()=>{
    const that = this;
    var host =process.env.REACT_APP_WEB_SOCKET_URL_GROUP;
    var wsocket =new WebSocket(host);       

    wsocket.onopen=function(evt){
        console.log("Server connected ,Success");
    };
    wsocket.onmessage=function(evt)
    {  
        const dataFromServer = evt.data.split("||");
        if(dataFromServer[0] == "True")
          {
           if(dataFromServer[1] !=localStorage.getItem('CandidateProfile_id02'))
            {
              setgroupChatNotification(true)
            }          
          }    
    };
  }

  let componentItem = null;
  let interviewComponent = null;
  let label = "Interview chat";
  var flagStatus = props.flagStatus;
  let newMessage = "";

  newMessage = props.newMessage;


  // if (flagStatus == 0) {
  //   if (localStorage.getItem("quickInvite") !== null) {
  //     interviewComponent = (
  //       <ChatInterview notiy={interviewChatNotfy} intData={props.intData} appliedId={props.appliedId} />
  //     );
  //     label = "Interview chat";
  //   } else if (localStorage.getItem("quickInvite") === null) {
  //     interviewComponent = null;
  //     label = "No interviews";
  //   }
  // } else if (flagStatus == 1) {
  //   interviewComponent = (
  //     <ChatInterview notiy={interviewChatNotfy} intData={props.intData} appliedId={props.appliedId} />
  //   );
  //   //interviewComponent = null;
  //   label = "Interview chat";
  // }

  if (groupChat) {
    if (props.interviewData.groupChat === true) {
    if(flagStatus == 0){
      componentItem = <Chat intData={props.intData} />;
    }else{
      componentItem= interviewComponent   
    }
   }
  }
  if (interviewChat) {
    if (props.interviewData.interviewChat === true) {
      componentItem =   <ChatInterview notiy={interviewChatNotfy} intData={props.intData} appliedId={props.appliedId} />;
      label = "Interview chat";
    }
  }
  const NoInterview = (
    <div className={classes.noInterviewWrapper}>
      <div className={classes.row1}>No messages to show..</div>
      <div className={classes.row2}>
        This chat will be activated once your interview starts.
      </div>
    </div>
  );

  return (
    <div className={classes.ChatTabs}>
    {props.is_interview == true ?
    <div>
      <div className={classes.TabHeade}>
        {props.interviewData.groupChat === true && flagStatus===0 ? (
          <span
            onClick={handleTabGroup}
            className={groupChat ? classes.Active : null}
          >
            Group Chat
            {groupChatNotification ? (
              <Icon
                name="circle"
                className={classes.messageArrived}
                size="mini"
              />
            ) : (
              ""
            )}
          </span>
        ) : null}

        {props.interviewData.interviewChat === true ? (
          <span
            onClick={handleTabInterview}
            className={interviewChat ? classes.Active : null}
          >
            {label}
            {intwChatNotification ? (
              <Icon
                name="circle"
                className={classes.messageArrived}
                size="mini"
              />
            ) : (
              ""
            )}
          </span>
        ) : null}
      </div>
      <div className={classes.Container}>
        {componentItem === null ? NoInterview : componentItem}
        {/* {componentItem} */}
      </div>
     </div>
      : <div className={classes.TabHeade}>      
         Chat feature is not available. Please enjoy the webinar.    
        </div>
     }
    </div>
  );
}

export default ChatTabsVas;
