import React from "react";
import ReactTags from "react-tag-autocomplete";
import { connect } from "react-redux";
import * as actionsCreator from "../../../store/actions/index";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
class CVTagsOnboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      currentValues: [],
    };
  }
  componentDidMount() {
    if (this.props.cvSkilldata != null) {
      this.setState({
        currentValues: this.props.cvSkilldata.Data.skills.candidate,
      });
      this.props.onSendData(this.props.cvSkilldata.Data.skills.candidate);
    } else if (this.props.profile !== null) {
      this.setState({
        currentValues: this.props.profile.userData.profile[0].skills.split(","),
      });
    }
    this.getSkilsList();
  }
  componentWillReceiveProps(props){
    if (props.cvSkilldata !== null) {
      this.setState({
        currentValues: props.cvSkilldata.Data.skills.candidate,
      }); 
    this.props.onSendData(props.cvSkilldata.Data.skills.candidate);
    }
  }
  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
   // this.props.onSendData(value);
  };

  handleChange = (e, { value }) => {
    this.setState({ currentValues: value });
    this.props.onSendData(value);
    this.props.updateSkillsFlag();
  };

  getSkilsList = () => {
    var Skils = [];
    this.props.systemData.Data.skills.map((res, index) => {
      var obj = {
        text: res.skillName,
        value: res.skillName,
      };
      Skils.push(obj);
    });
    this.setState({ options: Skils });
  };

  render() {
    console.log(this.state.currentValues, "Current values");
    return (
      <div className="react--cv--tags">
        <Dropdown
          options={this.state.options}
          placeholder="Choose Skills"
          search
          selection
          fluid
          multiple
          allowAdditions
          value={this.state.currentValues}
          onAddItem={this.handleAddition}
          onChange={this.handleChange}
          className="cvTags_dropdown"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cvSkilldata: state.profile.cvFilterData,
    profile: state.CandidateData.userData,
    systemData: state.auth.systemData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSendData: (data) => dispatch(actionsCreator.skillTags(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CVTagsOnboard);
