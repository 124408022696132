import React from "react";
import Modal from "react-modal";
import {
  CSSTransition,
  Transition,
  TransitionGroup,
} from "react-transition-group";
import "./EachCampaignModal.scss";
import moment from "moment";
import CampaignSlide from "./CampaignSlide";

class EachCampaignModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      allCampaigns: [],
      modalData: "",
      numberX: 1,
      isIncrement: "",
    };
  }

  incrementNumber = () => {
    if (this.state.numberX !== 5) {
      this.setState(
        {
          isIncrement: true,
        },
        () => this.props.handleNextButton()
      );
    }
  };

  decrementNumber = () => {
    if (this.state.numberX !== 0) {
      this.setState(
        {
          isIncrement: false,
        },
        () => this.props.handlePrevButton()
      );
    }
  };

  render() {
    return (
      <div className="modalInChat">
        <Modal
          isOpen={this.props.eachModalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.props.handleCloseEachModal}
          contentLabel="Example Modal"
          className="full-screen-modal-1"
          ariaHideApp={false}
        >
          <div className="close-div">
            <button onClick={this.props.handleCloseEachModal}>
              <i className="fas fa-times" />
            </button>
          </div>
          {this.props.data.campaignsListLength > 0 && (
            <div className="nex-prev-row">
              <div className="next-prev">
                <div
                  className={
                    this.props.fadeLeftNav ? "nav-button-faded" : "nav-button"
                  }
                >
                  <button onClick={this.decrementNumber}><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                </div>
                <div
                  className={
                    this.props.fadeRightNav ? "nav-button-faded" : "nav-button"
                  }
                >
                  <button onClick={this.incrementNumber}><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                </div>
              </div>
            </div>
          )}
          <TransitionGroup>
            <CSSTransition
              key={this.props.currentNum}
              timeout={{ enter: 300, exit: 300 }}
              classNames={this.state.isIncrement ? "incr" : "decr"}
            >
              <CampaignSlide
                applyButton={this.props.applyButton}
                data={this.props.data}
                handleCloseEachModal={this.props.handleCloseEachModal}
                listModalIsOpen={this.props.listModalIsOpen}
                handleOpenListModal={this.props.handleOpenListModal}
                handleCloseListModal={this.props.handleCloseListModal}
                campaignsData={this.props.campaignsData}
              />
            </CSSTransition>
          </TransitionGroup>
        </Modal>
      </div>
    );
  }
}

export default EachCampaignModal;
