import React, { Component } from "react";
import Spinner from "../../Spinner/Spinner";
import { connect } from "react-redux";
import classes from "./VideoUploadProgress.module.scss";
import "./VideoUploadProgress.scss";

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true,
    };
  }
  render() {
    return (
      <div className={`${classes.uploadProgressWrap} replayThumb`}>
        {/* <h4 className={classes.videoUploadingText}>Video is being uploaded</h4> */}
        {/* {this.props.videoRecordState !== null ? ( */}
        {/* <VideoThumbnail videoUrl={this.props.videoRecordState} snapshotAt={1} /> */}
        <video>
          <source src={this.props.videoRecordState} type="video/mp4"></source>
        </video>
        <div className={classes.backdrop}></div>
        <div className={classes.submitingText}>
          <div className={classes.line1}>
            Your video pitch answers are being submitted.
          </div>
          <div className={classes.line2}>
            You can redo the video pitch later if required.
          </div>
        </div>
        <div className={classes.spinnerWrap}>
          <Spinner />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appliedSaveData: state.chatnew.appliedCampaignTemp,
    videoRecordState: state.chatnew.videoRecord,
  };
};
export default connect(mapStateToProps, null)(Progress);
