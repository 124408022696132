import React, { Component } from "react";
import { Route, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CandidateDashboard from "../Dashboard";
import classes from "./Console.module.scss";
import "./Console.scss";
import * as actionsCreator from "../../../store/actions/index";
import objectCreater from "../../../hoc/objectChecker";
import SiaChat from "../../../containers/SiaChat/Chat/Chat";
import SupportChat from "../../../components/SupportChat/SupportChat";
import siaWhite from "../../../assets/image/sia-logo-in-white.png";
import whatsapp from "../../../assets/image/whatsapp.png";
import close from "../../../assets/image/close.png";
import ChatModal from "../../../components/UI/ChatModal/ChatModal";
import ProfileWrapper from "../Profile/ProfileWrapper";
import ProfileContent from "../Settings/SettingsWrapper";
import VideoConference from "../../Interview/VideoConference/VideoConference";
import CampaignShare from "../../../components/CampaignShare/CampaignShare";
import moment from "moment";
import { Modal } from "semantic-ui-react";
import Logo from "../../../assets/image/ufairs.svg";
import LogOut from "../../../assets/image/logout.svg";
import Bell from "../../../assets/image/Icons/bell.svg";
import spa from "material-ui/svg-icons/places/spa";
import User from "../../../assets/image/u2.png";
import axios from "../../../axios";
import Man from "../../../assets/image/man.svg";
import Pusher from 'pusher-js';

class Console extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0, // changing this will change the tab (controlled) ^madusara
      toGoIndex: 0, // saving where the user tried to go
      token: localStorage.getItem("chat-token"),
      messageList: [],
      showChat: false,
      chatInitValue: false,
      showBurger: false,
      InterruptionAllowed: false,
      showExitModal: false, // unsaved changes modal
      completeProfile: false,
      showSupportChat: false,
      registeredStatus: 0,
      notification: false,
      profile: false,
      eventNotification:"",
      newNotification:false
    };
    this.getUserData = this.getUserData.bind(this);
    this.onMessageWasSent = this.onMessageWasSent.bind(this);
    this.getChatResponseMessages = this.getChatResponseMessages.bind(this);

  }

  handleDropdown = () => {
    this.setState({
      showBurger: !this.state.showBurger,
    });
  };
  toggleChat = () => {
    localStorage.setItem("chatClose", false)
    if (this.state.showChat === false) {
      localStorage.setItem("REFRESHSTATUS", true);
    }
    if (this.state.showChat === true) {
      localStorage.setItem("REFRESHSTATUS", false);
    }

    if (this.props.chatOpen === true) {
      localStorage.setItem("REFRESHSTATUS", false);
      this.setCloseState(true);
      this.props.OnsetChatClose(false)
    }
    this.setState({
      showChat: !this.state.showChat,
    });
  };

  toggleSupportChat = () => {
    this.setState({
      showSupportChat: !this.state.showSupportChat,
    });
  }

  exitThisPage = () => {
    this.props.setChanged(false);
    if (this.state.toGoIndex < 0) {
      //if the user was trying to logout
      this.props.OnhandleLogout();
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace("/");
    }
    //if the user was trying to navigate
    else {
      this.setState({
        showExitModal: false,
        tabIndex: this.state.toGoIndex,
      });
    }
    if (this.state.completeProfile == true) {
      this.setState({
        showChat: true,
        tabIndex: 1,
      });
      this.props.onGetChatResponseNew("not_save_profile_data", false);
      this.props.setChanged(true);
    }
  };

  changeTabCareer = () => {
    this.setState({ tabIndex: 1, showChat: false, completeProfile: true });
  };

  componentDidMount = () => {
    this.getUserData();
    this.getChatResponseMessages();
    this.handleCandidateLogin();
    var data = "";
    this.props.OnGetHollencodeQuestions(data);
    localStorage.setItem("OpenQuestionModal", false);

    if (localStorage.getItem("OPENCHATWINDOW") === "true") {
      this.setState({
        showChat: true,
      });
      localStorage.setItem("OPENCHATWINDOW", false);
    } else if (localStorage.getItem("REFRESHSTATUS") === "true") {
      this.setState({
        showChat: true,
      });
    }

    this.intrtviewTime = setInterval(() => this.props.getlatestAvailableInterview(), 10000);
    this.getavailableInterviews()
    this.props.getCandidateLoginTime();
    this.props.onResetUpdate()
    this.getNotification()
    this.props.getProfileData()
    this.initializationPusherNotifications()
   
  };

  initializationPusherNotifications = () => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    var channelName = process.env.REACT_APP_CHANEL_NAME+'_'+localStorage.getItem("eventId") + "_" + localStorage.getItem("CandidateProfile_id02") 
    // webinarId.toString()
    var channel = pusher.subscribe(channelName);
    channel.bind('is-message', (data) => {
      console.log(data, "dataY")
      this.state.webinarData = data.message
      if (data.message === true){
        this.getNotification()
        this.getUserData();
        this.state.newNotification= true
      }
    });
  }

  getNotification = () => {
    var id = localStorage.getItem("eventId")
    var candidateId = localStorage.getItem("CandidateProfile_id02")
    axios.get("get-notifications-event/?eventId=" + id + "&candidateId=" + candidateId, {
      headers: {
        "content-type": "application/json",
      },
    }).then((res) => {
      var data = res.data.Data.notifications.allNotifications
      this.state.eventNotification= data
      this.setState({data:true})

    })
      .catch((err) => {
      });
  }
  componentWillUnmount() {
    clearInterval(this.intrtviewTime)
    this.intrtviewTime = null;
  }
  getavailableInterviews = () => {
    var token = localStorage.getItem("chat-token");
    var host = process.env.REACT_APP_WEB_SOCKET_AVAILABLE_INTERVIEW + "/?senderId=" + token + "&userType=2"
    var wsocket = new WebSocket(host);

    wsocket.onopen = function (evt) {
      console.log("Server connected,avalable interview");
    };
    wsocket.onmessage = (evt) => {
      var token = localStorage.getItem("chat-token");
      console.log(evt.data,"Event data ######## available interview")
      if (evt.data != "DoNotMatch") {
        var obj = JSON.parse(evt.data)
        if (obj.token === token) {
          this.props.getlatestAvailableInterview(obj.data)
        }
      }
    };
  }

  handleCandidateLogin = () => {
    this.props.OnhandleLogin(localStorage.getItem("CandidateProfile_id02"));
  };

  getChatResponseMessages = () => {
    this.props.onGetChatHistoryNew();
  };

  getUserData = () => {
    var currentTime = moment.utc().toISOString();
    this.props.onGetCandidateData(this.state.token, currentTime);
  };

  onMessageWasSent = (message) => {
    this.setState({
      messageList: [...this.state.messageList, message],
    });
  };

  handleSignOut = () => {
    if (this.props.somethingChanged) {
      this.setState({
        showExitModal: true,
        toGoIndex: -1, // so that we know he tried to log out
      });
    } else {
      this.props.OnhandleLogout();
      var name = localStorage.getItem("eventName").toLocaleLowerCase().replace(/\s/g, '')
      window.location.replace("/" + name);
      sessionStorage.clear();
      localStorage.clear();
    }
  };

  setCloseState = (data) => {
    //this.props.OnsendCloseState(data)
    if (data === false) {
      localStorage.setItem("REFRESHSTATUS", true);
    }
    this.setState({
      showChat: !data,
    });
  };
  tabNavigate = (tabIndex) => {
    if (this.props.somethingChanged) {
      this.setState({
        showExitModal: true,
        toGoIndex: tabIndex,
      });
    } else
      this.setState({
        tabIndex: tabIndex,
        toGoIndex: tabIndex,
      });
  };

  changeTabProfile = () => {
    this.setState({ tabIndex: 2 });
  };

  goToCareerTab = () => {
    this.setState({ tabIndex: 1 });
  };
  openChat = () => {
    this.setState({
      showChat: true,
    });
  };

  resetChatState = () => {
    this.setState({
      showChat: false
    })
  }
  onNotfications = () => {
    this.setState({ notification: !this.state.notification, profile: false,newNotification:false })
   
  }
  onProfile = () => {
    this.setState({ profile: !this.state.profile, notification: false })
  }

  render() {
    var data = this.props.candidateDate;
    var dataExtracted = [
      objectCreater(this.props.candidateDate, ["userData", "profile"]),
    ];
    var firstName = [objectCreater(dataExtracted[0], ["0", "first_name"])];
    var lastName = [objectCreater(dataExtracted[0], ["0", "last_name"])];
    var picture = [objectCreater(dataExtracted[0], ["0", "picture_url"])];

    let Dashboard = (
      <CandidateDashboard
        changeTabProfile={this.changeTabProfile}
        changeTabCareer={this.goToCareerTab}
      />
    );
    let Settings = (
      <ProfileContent
        openChat={this.openChat}
        completeProfile={this.state.completeProfile}
        changeTabProfile={this.changeTabProfile}
      />
    );
    let Profile = (
      <ProfileWrapper
        changeTabProfile={this.changeTabProfile}
        changeTabCareer={this.goToCareerTab}
      />
    );
    let chatModal = null;
    let modalLable = "";

    let consoleCom = (
      <>
        <div className={classes.ConsloeNav}>
          <div className={classes.NContainer}>
            <div className={classes.LeftContent}>
              <Link to={""} className={classes.Brand}><img src={Logo} /></Link>
              <span className={classes.CareerfairName}>{localStorage.getItem("eventName")}</span>
            </div>
            <div className={classes.RightContent}>
              <div className={classes.Notfication}>
              {this.state.newNotification == true ?
                  <span className={`${classes.Indicator} ${classes.Pulse}`}></span>
                  : null}
               
                <img src={Bell} onClick={this.onNotfications} />
                {this.state.notification ?
                  <div className={classes.NotifiDropdown}>
                    <ul>
                      {this.state.eventNotification.length > 0 ?
                        this.state.eventNotification.map((res, index) => {
                          return (
                            <li>{res.notifications}</li>
                          )
                        })
                        : null}
                      {/* <li className={classes.Activ}>add multiple classes with class name react</li>
                      <li>add multiple classes with class name react</li>
                      <li>add multiple classes with class name react</li> */}
                    </ul>
                  </div>
                  : null}
              </div>
              <div className={classes.ProImg}>
                <img src={picture==""?Man:picture} onClick={this.onProfile} />
                {this.state.profile == true ?
                  <div className={classes.ProDropdown}>
                    <span className={classes.Uname}>{firstName} {lastName}</span>
                    <div onClick={this.handleSignOut} className={classes.LogOut} title="Sign out">
                      <img src={LogOut} /> Log Out
                    </div>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.ConsoleBodyContaniner}>
          <Tabs className={classes.ConsoleTab}>{Dashboard}</Tabs>
        </div>
      </>
    );

    if (localStorage.getItem("OpenSmartCV") === "true") {
      modalLable = "CV";
      chatModal = <ChatModal data={modalLable} />;
    }

    // else if (localStorage.getItem("NewCVUpload") === "true"){
    //   modalLable = "CV";
    //   chatModal = <ChatModal data={modalLable} />;
    // }
    else if (localStorage.getItem("OpenVideoModal") === "true") {
      modalLable = "VideoPitch";
      chatModal = <ChatModal data={modalLable} />;
    } else if (localStorage.getItem("OpenCoverLtrModal") === "true") {
      modalLable = "CoverLetter";
      chatModal = <ChatModal data={modalLable} />;
    } else if (
      localStorage.getItem("OpenSmartCV") === "true" &&
      localStorage.getItem("UPLOADING_STATUS") === "true"
    ) {
      modalLable = "splash";
      chatModal = <ChatModal data={modalLable} />;
    } else if (localStorage.getItem("Open_video_conf_sia") === "true") {
      //window.location.href.replace("/")
      consoleCom = <VideoConference />;
    } else if (localStorage.getItem("shareRef") !== null) {
      this.props.onGetCampaignByRef(localStorage.getItem("shareRef"));
      consoleCom = <CampaignShare openChat={this.openChat} />;
    } else if (localStorage.getItem("OpenCamTest") === "true") {
      modalLable = "CamearaTest";
      consoleCom = <ChatModal data={modalLable} />;
    } else if (localStorage.getItem("OpenMicTest") === "true") {
      modalLable = "MicTest";
      consoleCom = <ChatModal data={modalLable} />;
    }
    //  else if (sessionStorage.getItem("shareCom")!=null) {
    //   consoleCom = <CampaignShareGroup />;
    // }

    // else if(localStorage.getItem("OpenQuestionModal")=="true"){
    //   chatModal= <Profiling profilingModalIsOpen={true} />
    // }
    else if (localStorage.getItem("SupportAsEmail") === "true") {
      modalLable = "Support as email";
      consoleCom = <ChatModal data={modalLable} />;
    } else if (localStorage.getItem("faq_question_modal") === "true") {
      modalLable = "Faq question modal";
      consoleCom = <ChatModal data={modalLable} />;
    }

    var chatShow = this.state.showChat;
    if (localStorage.getItem("chatClose") === "true") {
      chatShow = false;
      //localStorage.setItem("chatClose", false);
    }
    if (this.props.chatOpen !== false) {
      chatShow = this.props.chatOpen
    }

    return (
      <>
        <div className={classes.ConsoleContaniner}>
          {consoleCom}
          <div className={classes.ConsleChatBot}>
            <div className={classes.navbarPage} id="candidate-main-nav">
              {/* <SiaChat show={chatShow} changeTabCareer={this.changeTabCareer} /> */}
              {/* <button title="Sia" onClick={this.toggleChat} className={classes.siaIconChat}>
                {chatShow ? <img src={close} /> : <img src={siaWhite} />}
              </button> */}
              
              <SupportChat show={this.state.showSupportChat} />
              <button title="Click here for live support" onClick={this.toggleSupportChat} className={classes.supportIconChat}>
                {this.state.showSupportChat ? <img src={close} /> : <img src={whatsapp} />}
              </button>
              {chatModal}
              <Modal
                open={this.state.showExitModal}
                size="tiny"
                className="noBootstrapModal"
              // exitThisPage - state.showExitModal
              >
                <div className={classes.insidePromptModal}>
                  <h2>
                    {/* <img src="https://image.flaticon.com/icons/svg/595/595067.svg" /> */}
                    Confirm <span>navigation</span>
                  </h2>
                  <div className={classes.secondary}>
                    This page is having unsaved changes. If you navigate away, the
                    changes that you may have made will be lost.
                  </div>
                  <div className={classes.secondary}>Do you want to exit?</div>
                  <div className={classes.decisionButton}>
                    <button className={classes.dismiss} onClick={this.exitThisPage}>
                      Yes, exit
                    </button>
                    <button
                      className={classes.stay}
                      onClick={() => {
                        this.setState({ showExitModal: false });
                        const rootElement = document.querySelector("#root");
                        //  window.scrollTo(0, rootElement.scrollHeight);
                      }}
                    >
                      No, stay on page
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    candidateDate: state.CandidateData.userData,
    responseMessages: state.chatnew.responseMessages,
    modalValue: state.chatnew.dataValue,
    loading: state.chatnew.loading,
    cvUploadStatus: state.chatnew.cvUploaded,
    chatComponentStatusRes: state.chatnew.closeChatComponent,
    processData: state.CampaignByRef.campaignProcessData,
    somethingChanged: state.auth.somethingChanged,
    dashboardCV: state.profile.cvDashboard,
    chatOpen: state.chatnew.chatOpen
    //onShowChat  :  state.chatnew.chatPopUp
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCandidateData: (data, time) =>
      dispatch(actionsCreator.getCandidateData(data, time)),
    onGetChatHistoryNew: () => dispatch(actionsCreator.getChatHistoryNew()),
    OnGetHollencodeQuestions: (data) =>
      dispatch(actionsCreator.getHollencodeQuestions(data)),
    onGetCampaignByRef: (data) =>
      dispatch(actionsCreator.getCampaignByRef(data)),
    OnsendCloseState: (data) =>
      dispatch(actionsCreator.CloseChatComponent(data)),
    OnhandleLogin: (data) => dispatch(actionsCreator.handleLogin(data)),
    OnhandleLogout: () => dispatch(actionsCreator.handleLogOut()),
    setChanged: (data) => dispatch(actionsCreator.setSomethingChanged(data)),
    getlatestAvailableInterview: (data) =>
      dispatch(actionsCreator.getAvailableInterview(data)),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
    getCandidateLoginTime: () => dispatch(actionsCreator.CandidateLoginTime()),
    OnsetChatClose: (vrb) => dispatch(actionsCreator.SetChatClose(vrb)),
    onResetUpdate: () => dispatch(actionsCreator.ResetSupportConnections()),
    getProfileData:()=>dispatch(actionsCreator.getProfileData())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Console)
);
