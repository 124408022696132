import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import { Button, Card, Image, Icon } from "semantic-ui-react";
import classes from "./exhall.module.scss";
import Backpng from "../../../assets/image/ufairs.png";

class Hall extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentNum: 0,
            stallImage: "",
            stallId: "",
            stallName: "",
            abc: [1]
        }
    }
    componentDidMount() {

    }
    viewStall = (id) => {
        this.props.setStall(id)
    }
    render() {
        return (
            <>
               <div onClick={() => this.viewStall(this.props.data.exibitionHalls.id)}>
                    <Card className={classes.nc_ui} >
                        <Image src={Backpng} />
                        <Card.Content className={classes.contentNew} style={{ textAlign: "center" }}>
                            <Card.Header className={classes.exhName}>{this.props.data.exibitionHalls.hallName}</Card.Header>
                        </Card.Content>
                    </Card>
                </div>
            </>
        )
    }
} 

const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Hall)
);