import React, { Component } from "react";
import classes from "./Onboard.module.scss";
import "./Onboard.scss";
import { CountryDropdown } from "react-country-region-selector";
import TimezonePicker from "react-timezone";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import * as actionsCreator from "../../../src/store/actions/index";
import axios from "../../axios";

class LocationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: "",
      zone: "",
      ErrorCountry: "",
      ErrorZone: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    this.getGeoInfo();
  }

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.setState({
          country: data.country_name,
          zone: data.timezone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  selectCountry = (value) => {
    this.setState({
      country: value,
    });
  };

  selectTimeZone = (zone) => {
    this.setState({
      zone: zone,
    });
  };

  btnSaveProceed = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
   
      var data = {
        location: this.state.country,
        zone: this.state.zone,
      };
   //   this.props.onSkip();
      this.props.onSendData(data);
      this.updateProfile()

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  updateProfile = () => {
    this.setState({
      submitted: true,
    });
    var idData = sessionStorage.getItem("CandidateProfile_id");
    var userID = sessionStorage.getItem("CandidateProfile_id02"); 

    var data = {
      phone_number: this.props.phoneNumber,
      timeZone: this.state.zone,
      current_location: this.state.country,
      onBoard_state: "COMPLETE",
      uploaded_at: "2019-09-20T00:00:00Z",
      upload: "",
      userId: userID,
      skills: this.props.skills ? this.props.skills.toString() : "",
      education: this.props.cvDataEdu == null ? []:this.props.cvDataEdu,
      experience: this.props.cvDataExp== null? []:this.props.cvDataExp,
      career_preferences:"",
      format_type: "COMPLETE",
    };
    this.props.onUpdateProfile(idData, data);
    localStorage.setItem("REFRESHSTATUS", true);
    // wind
  };

  btnSkipForNow = (e) => {
    e.preventDefault();
    this.props.onDataSave(this.state.country, this.state.zone);
    this.props.onSkip();
    //this.props.onSkipSteps(this.props.stepNo)
  };

  render() {
    return (
      <div className={classes.locationStepWrapper}>
        <div className={classes.locationStep}>
          <div className={classes.topRowWrapper}>
            <h3>
              Let's configure your{" "}
              <span className={classes.orange}>location</span> now
            </h3>
          </div>
          <div className={classes.sectionWrap}>
            <div className={classes.section}>
              <div className={classes.topInputArea}>
                <div className={classes.tipText1}>Current location</div>
                <CountryDropdown
                  className={classes.inputCountry}
                  value={this.state.country}
                  onChange={this.selectCountry}
                  name="country"
                  placeholder="Country"
                />
                <div className="validation-div-wrap">
                  {this.validator.message(
                    "country",
                    this.state.country,
                    "required"
                  )}
                </div>
                {/* <input
                  type="text"
                  name="country"
                  onChange={this.handleInputChange}
                  placeholder="e.g. Wales"
                ></input> */}

                <div className={classes.tipText2}>Current timezone</div>
                {/* <input
                  type="text"
                  name="zone"
                  onChange={this.handleInputChange}
                  placeholder="GMT"
                ></input> */}
                <TimezonePicker
                  value={this.state.zone}
                  className="timezone-input-wizard"
                  onChange={this.selectTimeZone}
                  inputProps={{
                    placeholder: "Select Timezone",
                    name: "zone",
                  }}
                />
                <div className="validation-div-wrap">
                  {this.validator.message("zone", this.state.zone, "required")}
                </div>
                <div className={classes.tipText2} style={{textAlign:"center"}}>
                 It is important that you <span className={classes.orange}>select your current time zone</span>  as the interview time will be displayed based on the timezone selected here
                </div>
                <button
                  className={classes.proceedButton}
                  // onClick={this.btnSkipForNow}
                  onClick={this.btnSaveProceed}
                >
                  Save &amp; Proceed
                </button>
              </div>
            </div>
          </div>

          {/* <button hidden={true} onClick={this.btnSkipForNow}>
            Skip for now >>
          </button> */}
        </div>
      </div>
    );
  }
}

// export default LocationPanel;

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    phoneNumber: state.auth.phoneNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendData: (data) => dispatch(actionsCreator.sendLocationData(data)),
    onUpdateProfile: (idData, data) =>
    dispatch(actionsCreator.updateProfile(idData, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationPanel);
