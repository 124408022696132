import React, { Component } from "react";
import { Route, withRouter,Link } from "react-router-dom";
import { connect } from "react-redux";
import NearbyData from "./NearbyData/Data";
import classes from "./NearbyCampaigns.module.scss";
import NearbyCarousel from "../NearbyCampaigns/NearbyCarousel";
import * as actions from "../../../store/actions/index";
import sia from "../../../assets/image/sia.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Campaigns from "../../../components/CandidateDashboard/CampaignData/CampaignData";


class Nearby extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    var id = localStorage.getItem("CandidateProfile_id02");
    var eventId = localStorage.getItem("eventId")
    this.props.getAvailableCampaignType(id,eventId);
  }
  render() {
    var campaign = [];
    if (this.props.compaign != null) {
      campaign = this.props.compaign.Data;
    }
    return (
      <div className={classes.dashboardNearby}>
        {/* <div className={classes.title}>Campaigns near you</div> */}
      
        <Tabs
          className={classes.tabs}
          selectedIndex={this.state.tabIndex}
          // onSelect={(tabIndex) => this.tabNavigate(tabIndex)}
        >
          <div className={classes.menubarWrapper}>
            <div className={classes.menubar}>         
              <TabList className={classes.tabList}>
                <Tab disabled={this.state.tabIndex === 0}>     
                  <div style={{borderBottom:"2px solid #ffffff"}}>Campaigns</div>           
                </Tab>
                <Tab disabled={this.state.tabIndex === 1}>
                  <div style={{borderBottom:"2px solid #ffffff"}}>Applied</div>       
                </Tab>
                <Tab disabled={this.state.tabIndex === 2}>    
                  {/* <div>Shortlisted</div>       */}
                </Tab>
              </TabList>            
            </div>         
          </div>
          <TabPanel>
          {campaign.length > 0 ?
          <div className={classes.slider_content}>
            <NearbyCarousel key={0} compaign={campaign} />
          </div>
          : <div className={classes.instructionWrapper}>
          <div className={classes.instructions}>
            <div className={classes.heading}>
              Welcome to <span>Ufairs</span>:
            </div>
            <div className={classes.descr}>
              {/* Click the <img src={sia} /> icon and chat with <b>Sia</b>  to */}
               You don't have any campaign right now
            </div>
          </div>
        </div>}
          </TabPanel>
          <TabPanel>
          <div style={{marginLeft:"30px",marginTop:"30px"}}>
          <Campaigns data={this.props.candidateDate} />
          </div>
          </TabPanel>
          <TabPanel></TabPanel>
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    compaign: state.CampaignByRef.availableCampaign,
    profile: state.profile.userProfile,
    event:state.auth.eventData,
    candidateDate: state.CandidateData.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAvailableCampaignType: (data,id) =>
      dispatch(actions.getAvailableCampaignSlider(data,id)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nearby));
