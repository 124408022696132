import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./Profile.module.scss";
import ObjectChecker from "../../../hoc/objectChecker";
import { convertColorToString } from "material-ui/utils/colorManipulator";
import * as actions from "../../../store/actions/index";
import { Modal } from "semantic-ui-react";
import Man from "../../../assets/image/man.svg";

class Profile extends Component {
  constructor(props) {
    super();
    this.state = {
      data: null,
      isViewModalOpen: false,
    };
  }

  getProfileData = (data) => {
    //console.log(./., "Profile data");
  };

  handleCVUpload = () => {
    var flag = true;
    this.props.onUploadCV(flag);
  };

  goProfile=()=>{
    this.props.profileTabChange()
  }

  render() {
    var data = [ObjectChecker(this.props.data, ["userData", "profile"])];
    var dataCV = [ObjectChecker(this.props.data, ["userData", "cv"])];
    var firstname = [ObjectChecker(data[0], ["0", "first_name"])];
    var lastName = [ObjectChecker(data[0], ["0", "last_name"])];
    var Prof_picture = [ObjectChecker(data[0], ["0", "picture_url"])];  
    var location = [ObjectChecker(data[0], ["0", "current_location"])];
    var cvUrl = [ObjectChecker(dataCV[0], ["0", "upload"])];
    if (typeof cvUrl[0] === "string") {
      var cvType = cvUrl[0].substr(cvUrl[0].length - 3, 3);
    }
    var userDetails = [ObjectChecker(this.props.data, ["Details", "data"])];
    var email = [ObjectChecker(userDetails[0], ["0", "email"])];

    var camp_count_live = [ObjectChecker(this.props.data, ["liveCamCount"])];
    var camp_count_past = [ObjectChecker(this.props.data, ["pastCamCount"])];
    var camp_count_upcoming = [
      ObjectChecker(this.props.data, ["upcomingCount"]),
    ];
    return (
      <div className={classes.profileinzide}>
        <div className={classes.imageAndName}>
          <img
            className={classes.profile}
            src={Prof_picture[0] ? Prof_picture : Man}
          />
          <h5 className={classes.web}>
            {firstname} {lastName}
          </h5>
          <h5 className={classes.tablet}>{firstname}</h5>
        </div>
        <div className={classes.statsRow}>
          <div className={classes.ongoing}>
            <div className={classes.heading}>ONGOING</div>
            <div className={classes.count}>{camp_count_live}</div>
            <div className={classes.text}>campaigns</div>
          </div>
          <div className={classes.upcoming}>
            <div className={classes.heading}>UPCOMING</div>
            <div className={classes.count}>{camp_count_upcoming}</div>
            <div className={classes.text}>campaigns</div>
          </div>
          <div className={classes.past}>
            <div className={classes.heading}>PAST</div>
            <div className={classes.count}>{camp_count_past}</div>
            <div className={classes.text}>campaigns</div>
          </div>
        </div>
        <div className={classes.statsRowTablet}>
          <div className={classes.ongoingTablet}>
            <div className={classes.count}>{camp_count_live}</div>
            <div className={classes.heading}>ONGOING</div>
            <div className={classes.text}>campaigns</div>
          </div>
          <div className={classes.upcomingTablet}>
            <div className={classes.count}>{camp_count_upcoming}</div>
            <div className={classes.heading}>UPCOMING</div>
            <div className={classes.text}>campaigns</div>
          </div>
          <div className={classes.pastTablet}>
            <div className={classes.count}>{camp_count_past}</div>
            <div className={classes.heading}>PAST</div>
            <div className={classes.text}>campaigns</div>
          </div>
        </div>
        <div className={classes.statsRowMobile}>
          <div className={classes.ongoing}>
            <div className={classes.count}>{camp_count_live}</div>
            <div className={classes.heading}>ONGOING</div>
            <div className={classes.text}>campaigns</div>
          </div>
          <div className={classes.upcoming}>
            <div className={classes.count}>{camp_count_upcoming}</div>
            <div className={classes.heading}>UPCOMING</div>
            <div className={classes.text}>campaigns</div>
          </div>
          <div className={classes.past}>
            <div className={classes.count}>{camp_count_past}</div>
            <div className={classes.heading}>PAST</div>
            <div className={classes.text}>campaigns</div>
          </div>
        </div>
        <div className={classes.contactDetails}>
          <div className={classes.field1}>
            <i className="far fa-envelope" />
            <div title={email}>{email}</div>
          </div>
          <div className={classes.field2}>
            <i className="fas fa-map-marker-alt"></i>
            <div>{location}</div>
          </div>
          <div className={classes.field3}>
            <i className="fab fa-linkedin-in"></i>
            <a disabled href="#" onClick={this.goProfile} >
              Go to profile
            </a>
          </div>
          <div className={classes.field4}>
            <i className="far fa-file-alt"></i>
            <div>CV</div>
            <div className={classes.cvButtons}>
              {cvType === "pdf" ? (
                <button
                  className={classes.view}
                  title="View file"
                  onClick={() => this.setState({ isViewModalOpen: true })}
                >
                  View
                </button>
              ) : cvType ? (
                <a
                  className={classes.view}
                  href={cvUrl}
                  type="button"
                  title="Download file"
                >
                  View
                </a>
              ) : (
                ""
              )}
              <a
                className={classes.upload}
                onClick={this.handleCVUpload}
                title="Upload file"
              >
                Upload
              </a>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.isViewModalOpen}
          size="small"
          className="noBootstrapModal"
          onClose={this.closeModal}
        >
          <div className={classes.insideViewModal}>
            <div className={classes.titlebar}>
              <h3>CV view</h3>
              <button
                onClick={() =>
                  this.setState({
                    isViewModalOpen: false,
                  })
                }
                className={classes.closeX}
              >
                <i className="fas fa-times" />
              </button>
            </div>
            <embed
              src={cvUrl}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { 
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUploadCV: (data) => dispatch(actions.UploadCV(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
