import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import * as actionsCreator from "../../../store/actions/index";
import classes from "./lobby.module.scss";
import { Button } from 'semantic-ui-react'
import lobbySound from "../../../assets/video/lobySound.mpeg"
// import EventI1 from "../../../assets/image/Jimma.jpg";
import lobby_middle from "../../../assets/image/dynmc/lobby_middle.png"
import lobby_side from "../../../assets/image/dynmc/lobby_side.png"

class Lobby extends Component {

    constructor(props) {
        super(props)
        this.state = {
            attendes: null,
            isSent: false,
            isSound:true
        }      
        this.audio = new Audio(lobbySound);
    }
    componentDidMount() {
      this.audio.play()  
      console.log("abc")
      this.props.onAccessStall(false);  
    }
    stopSound = () => {
        this.audio.pause()
        this.setState({isSound:false})
    }
    playSound = () => {
        this.audio.play()
        this.setState({isSound:true})
    }
    componentWillUnmount() {
        this.audio.pause()
    }
    render() {
        var centerImage = this.props.images.lobbyImages.find(x => x.imagePlace === "CENTER")
        var leftImage = this.props.images.lobbyImages.find(x => x.imagePlace === "LEFT")
        var rightImage = this.props.images.lobbyImages.find(x => x.imagePlace === "RIGHT")
        console.log(this.props.images.lobbyImages, "lobby images")
        console.log(leftImage, "Left image")

        var leftImage = this.props.images.lobbyImagesLeft
        var rightImage = this.props.images.lobbyImagesRight
        var centerImage = this.props.images.lobbyImagesCenter

        var leftImageShow = null
        var rightImageShow = null
        var centerImageShow = null

        if (leftImage !== null){
            
            leftImageShow = leftImage.image
        }
      
        if (rightImage !== null){

            rightImageShow = rightImage.image
        }
    
        if (centerImage !== null){

            centerImageShow = centerImage.image

        }

        return (
            <>
                <div className={classes.BannerContent}>
                    <div className={classes.Left}>
                        <img src={leftImageShow} />
                        {/* <img src={lobby_side}/> */}
                    </div>

                    <div className={classes.Center}>
                        <img src={centerImageShow} />
                        {/* <img src={lobby_middle}/> */}
                    </div>

                    <div className={classes.Right}>
                        <img src={rightImageShow} />
                        {/* <img src={lobby_side}/> */}
                    </div>                  
                </div>
                <div>
                    <Button.Group style={{position:"absolute",top:"0",left:"0",marginTop:"60px",marginLeft:"10px"}}>
                        {this.state.isSound == false ?
                            <Button style={{ backgroundColor: "#fa9d1c" }} onClick={this.playSound} icon='play' />
                            :
                            <Button style={{ backgroundColor: "#fa9d1c" }} onClick={this.stopSound} icon='pause' />
                        }
                    </Button.Group>
                    </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAccessStall: (data) => dispatch(actionsCreator.AccessStall(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Lobby)