import React, { Component } from "react";
import { Route, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import LiveCampaign from "../Campaign/LiveCampaign";
import ReactPaginate from "react-paginate";
import classes from "./Live.module.scss";
import sia from "../../../../assets/image/sia.png";

class Live extends Component {
  constructor(props){
    super(props)
    this.state={
      offset: 0,
      data: [],
      more: false,
      elements: [],
      perPage: 5,
      pageCount: 0,
      currentPage: 0,
    }
  }
  /* DO NOT DELETE THESE COMMENTS*/
  // generateCampaignsList = () => {
  //   let liveCampaign = "";
  //   try {
  //     liveCampaign = this.props.data[0]
  //       .slice(this.state.offset, this.state.offset + this.state.perPage)
  //       .map((res, index) => {
  //         console.log(res, "xsoo res");
  //         return <LiveCampaign data={res} key={index} />;
  //       });
  //     this.setState({
  //       elements: liveCampaign,
  //     });
  //   } catch {
  //     console.log("catch - Live.js - generateCampaignsList");
  //   }
  // };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({ currentPage: selectedPage, offset: offset }, () => {
      this.setElementsForCurrentPage();
    });
  };

  componentDidMount(){
    this.setElementsForCurrentPage();
    this.timer = setInterval(() => this.setElementsForCurrentPage(), 1000);
  }
  componentWillUnmount(){
    clearInterval(this.time)
    this.timer = null;
  }

  setElementsForCurrentPage = () => {
    let liveCampaign = "";
    this.setState({ pageCount: Math.ceil(this.props.data.length / this.state.perPage) })
    try {
      liveCampaign = this.props.data
        .slice(this.state.offset, this.state.offset + this.state.perPage)
        .map((res, index) => {
          return (
            <>
              <LiveCampaign data={res} key={index} />
            </>
          )
        });
      this.setState({ elements: liveCampaign });
    } catch {
    }
  }
  render() {
    // let liveCampaign = "";
    // try {
    //   liveCampaign = this.props.data.map((res, index) => {
    //     return (
    //       <>
    //         <LiveCampaign data={res} key={index} />
    //       </>
    //     )
    //   });
    // } catch {
    // }
    return (
      <div style={{ marginTop: "30px" }}>
        <div className={classes.LiveCampaignContet}>
          {this.state.elements.length > 0 ? (
            <>
            <ul>{this.state.elements}</ul>
             {this.state.pageCount > 1 ?
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={<span className="gap">...</span>}
                pageCount={this.state.pageCount}
                onPageChange={this.handlePageClick}
                forcePage={this.state.currentPage}
                containerClassName={"pagination manage-dash-pagination"}
                previousLinkClassName={"previous_page"}
                nextLinkClassName={"next_page"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
              />:null
             }
            </>
          ) : (
            <div className={classes.instructionWrapper}>
              <div className={classes.instructions}>
                <div className={classes.heading}>
                  Welcome to <span>Ufairs</span>:
                </div>
                <div className={classes.descr}>
                Use this tab to track the progress of the campaigns you apply for.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Live)
);

