import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Data.module.scss";
import objecChecker from "../../../../../hoc/objectChecker";
import * as actionsCreator from "../../../../../store/actions/index";

class EduData extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount = () => {};
  handleDeleteItem = () => {
    this.props.setChanged(true);
    this.props.deleteItemEdu(this.props.dataIndex);
  };

  handleEditItem=()=>{
   this.props.editEducation(this.props.dataIndex);
  }

  render() {
    var name = [objecChecker(this.props.data, ["institution"])];
    var type = [objecChecker(this.props.data, ["type_ins"])];
    var subject = [objecChecker(this.props.data, ["stream"])];
    var grade = [objecChecker(this.props.data, ["grade"])];
    return (
      <div className={classes.eachData}>
        <div className={classes.dataFields}>
          <div className={classes.institute}>{name}</div>
          <div>{type}</div>
          <div>{subject}</div>
          <div>{grade}</div>
        </div>
        <div className={classes.delete}>
          <button onClick={this.handleDeleteItem}>
            <i className="far fa-trash-alt" />
          </button>
          <button onClick={this.handleEditItem}>
             <i class="fas fa-edit"></i>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    phoneNumber: state.auth.phoneNumber,
    location: state.auth.location,
    cvString: state.auth.cvString,
    cvDataExp: state.auth.cvDataExp,
    cvDataEdu: state.auth.cvDataEdu,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteItem: (data) => dispatch(actionsCreator.deleteItem(data)),
    setChanged: (data) => dispatch(actionsCreator.setSomethingChanged(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EduData);
