import React, { Component, useState } from "react";
import { Route, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./Campaign.module.scss";
import CampaignDashboard from "../../../Modals/CampaignDashboard/CampaignDashboard";
import moment from "moment";
import quickCamp from "../../../../assets/image/quick2.png";
import * as actionsCreator from "../../../../store/actions/index";
import blackPhone from "../../../../assets/image/phoneblack.png";
import whitePhone from "../../../../assets/image/phonewhite.png";
import pending from "../../../../assets/image/pending.png";
import { Button, Icon, Modal } from "semantic-ui-react";
import axios from "../../../../axios";
import SchduleDate from "./SchduleDate";
import Spinner from "../../../UI/Spinner/Spinner";
import spa from "material-ui/svg-icons/places/spa";
import defaultCamImage from "../../../../assets/image/sia_default.png";
import LogoUfair from "../../../../assets/image/dynmc/stall_middle.png";
import CampaignByStaffDashBoard from "../../../CampaignAddByStaff/CampaignByStaffInternalDashBoard";

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eachModalIsOpen: false,
      modalData: {
        heading: this.props.data.campaignData.heading,
        campaignImage: this.props.data.campaignData.campaignImage,
        startDate: this.props.data.campaignData.startDate,
        location: this.props.data.campaignData.location,
        description: this.props.data.campaignData.description,
        listLength: false,
        company: this.props.data.companyData.name,
        companyLogo: this.props.data.companyData.companyLogo,
        id: this.props.data.campaignData.id,
        type: this.props.data.campaignData.types,
        campDuration: "",
        openModalProcessed:false,
        isLoding: false,      
      },
      schduleDate: [],
      showMessageModal:false,
      open: false,
      isAPICall : false,
      isUnsheduledInterview : false
    };
  }

  componentWillReceiveProps() {
    var momentStart = moment(this.props.data.campaignData.startDate);
    var momentEnd = moment(this.props.data.campaignData.endDate);
    var campDuration = momentEnd.diff(momentStart, "days");
  }

  componentDidMount = () =>{
    //setInterval(() => this.checkhasUnSheduledCampaigns(), 1000)
    //this.initializationPusherNotifications()
  }

  checkhasUnSheduledCampaigns = () =>{
    var token = localStorage.getItem("chat-token");
    if (this.props.candidate != null && this.props.data != null){
      if (this.state.isAPICall === false){
        axios.get('/?campaignId='+this.props.data.campaignData.id + "&candidateId="+this.props.candidate.userData.profile[0].user_id,{
          headers: {
            Authorization: `token ${token}`,
            "Content-Type": "application/json",
          },
        }).then(res=>{
          this.setState({
            isAPICall: true,
            isUnsheduledInterview:true
          })
        })
      }
      else{
        console.log("api called")
      }
    }
    else{
      console.log("getting data")
    }
  }

  handleOpenEachModal = () => {
    this.setState({ eachModalIsOpen: true });
    var campaignId = this.props.data.campaignData.id;
    var candidateId = this.props.candidate.userData.profile[0].user_id;
    this.props.getCampaignProcess(campaignId, candidateId);
  };
  handleCloseEachModal = () => {
    this.setState({ eachModalIsOpen: false });
  };

  // dateDifference = (res) => {
  //   var dateGap = moment(res.campaignData.startDate).diff(
  //     moment(Date.now()),
  //     "days"
  //   );

  //   // var momentStart = moment(this.props.data.campaignData.startDate);
  //   // var momentEnd = moment(this.props.data.campaignData.endDate);
  //   // var campDuration = momentEnd.diff(momentStart, "days");
  //   // this.setState({
  //   //   campDuration: campDuration,
  //   // });

  //   if (dateGap > 0) {
  //     return `Remaining days : ${dateGap + 1}`;
  //   } else {
  //     return "Ongoing campaign";
  //   }
  // };

  dateDifference = (res) => {
    var dateGap = moment(res.campaignData.startDate).diff(
      moment(Date.now()),
      "days"
    );
    var campaigDate = moment(res.campaignData.startDate).format("DD-MMM-YYYY");
    var nowTime = moment().format("DD-MMM-YYYY");

    if (campaigDate <= nowTime) {
      return "Ongoing campaign";
    } else {
      return `Remaining days : ${dateGap + 1}`;
    }
  };
  callInterview = (data) => { 
    console.log(data)
    var ref = data.ref;
    //  localStorage.setItem("Open_video_conf_sia", true);
    sessionStorage.setItem("ref", ref);
  //  this.props.history.push("/?ref=" + ref);
    var url = "/?ref=" + ref
    window.open(url,'_blank');
  };
  componentDidMount() {
    //  this.viewScheduleDate();
  }

  viewScheduleDate = () => {
    this.setState({ open: true, isLoding: true });
    var token = localStorage.getItem("chat-token");
    var ref = "";
    if (this.props.data.interviewData != null) {
      ref = this.props.data.interviewData.ref;
    }
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "chat/get-scheduledDates-campaign/?ref=" +
          ref,
        {
          headers: {
            Authorization: `token ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {   
        this.setState({ schduleDate: res.data.Data, isLoding: false });
      });
  };

  getSchduleDates=(ref)=> {
    var host =process.env.REACT_APP_WEB_SOCKET_TIME_SCHDULE+"/?ref="+ref+"&startTime=0"
    var wsocket = new WebSocket(host);     
    wsocket.onopen=function(evt){
        console.log("Server connected,avalable dates");
    };
    wsocket.onmessage = (evt) => { 
      if (evt.data != "DoNotMatch") {
        var obj = JSON.parse(evt.data)
        console.log(obj.Error, "dates")
        if (ref == obj.Data.ref) {
          this.setState({ schduleDate: obj.Data.times.Data, isLoding: false });
        }
      }
    };
  }

  close = () => {
    this.setState({ open: false });
  };
  openMessageModel=()=>{  
    this.setState({showMessageModal:true})
  }
  closeMessageModel=()=>{
    this.setState({showMessageModal:false})
  }

  onOpenProcessesed=(ref)=>{
    localStorage.setItem("ADDEDSTAFFREF", ref)
    this.setState({
      openModalProcessed: true
    })
  }

  closeProceedModel=()=>{
    this.setState({
      openModalProcessed:false
    })
  }

  render() {
    const { campDuration } = this.state;
    const stages = [
      "Applied",
      "Shortlisted",
      "Interviewed",
      "Selected",
      "Rejected",
      "Interviewing",
      "Cancelled",
    ];
    const { current_status } = this.props.data;
    const innerWidth = this.state;
    const { campaignImage, types } = this.props.data.campaignData;

    var momentStart = moment(this.props.data.campaignData.startDate);
    var momentEnd = moment(this.props.data.campaignData.endDate);
    var durationCamp = momentEnd.diff(momentStart, "days");

    var dateGapCampaign = moment(this.props.data.campaignData.startDate).diff(
      moment(Date.now()),
      "days"
    );
    var gapData = false;
    if (dateGapCampaign <= 0) {
      gapData = true;
    } else {
      gapData = false;
    }

    var ref = "";
    if (this.props.data.interviewData != null) {
      ref = this.props.data.interviewData.ref;
    }

    var campaignId = this.props.data.campaignData.id;
    var interviewData = null;

    if (this.props.availableInterview !== null) {
      // if (this.props.availableInterview.Data === null) {
      //   interviewData = null;
      // } else if (
      //   this.props.availableInterview.Data !== null &&
      //   this.props.availableInterview.Data.interview.campaignId === campaignId
      // ) {       
      //   interviewData = this.props.availableInterview.Data.interview;
      // } else {
      //   interviewData = null;
      // }
      if (this.props.availableInterview.Data === []) {
        interviewData = null;
      } 
      else if (this.props.availableInterview.Data !== [])    
      {   
        console.log(this.props.availableInterview.Data, "Interview data")
        console.log(campaignId, "Campaign id")
        
        var found = this.props.availableInterview.Data.filter(function(item) { return item.interview.campaignId === campaignId; });

        console.log(found[0],"Found 0")

        if (found[0] !== undefined){
          interviewData = found[0].interview
        }
        else{
          interviewData = null
        }

      } 
      else {
        interviewData = null;
      }
    }

    console.log("APi call")

    return (
      <div className={classes.eachCampaign}>
        <img
          src={types !== 5 ? campaignImage?campaignImage:LogoUfair : quickCamp}
          className={classes.camImage}
          onClick={this.handleOpenEachModal}
        />
        <div className={classes.description}>
          <div className={classes.title} onClick={this.handleOpenEachModal}>
            {this.props.data.campaignData.heading}
          </div>
          {/* <EachCampaignModal data={this.props.data} /> */}

          <CampaignDashboard
            data={this.state.modalData}
            handleOpenEachModal={this.handleOpenEachModal}
            handleCloseEachModal={this.handleCloseEachModal}
            eachModalIsOpen={this.state.eachModalIsOpen}
          />

          <div className={classes.datesTimes}>
            {/* <i className="far fa-calendar-alt"></i> */}
            <div className={classes.startDate}>
              {moment(this.props.data.campaignData.startDate).format(
                "Do MMM YYYY"
              )}
            </div>
            <div className={`${classes.to}`}>to</div>
            <div className={classes.to}>
              {moment(this.props.data.campaignData.endDate).format(
                "Do MMM YYYY"
              )}
            </div>
          </div>
          <div className={classes.remainingDays}>

            <div className={classes.joinbtnCandidate}>
              {this.props.data.addedByStaff != 0 && this.props.data.screeningEnable === true? (
                <button
                  className={classes.active}
                  onClick={() => this.onOpenProcessesed(this.props.data.addedStaffRef)}
                  title="Procees application"
                >
                  <span>Proceed</span>
                </button>
              ) : 
              null}
            </div>

            <div className={classes.joinbtnCandidate}>
              {interviewData !== null ? (
                <button
                  className={classes.active}
                  onClick={() => this.callInterview(interviewData)}
                  title="Join interview/webinar"
                >
                  <img src={whitePhone} />
                  <span>Join</span>
                </button>
              ) : // (
              //   <button
              //     className={classes.inactive}
              //     disabled={true}
              //     title="No ongoing interviews"
              //   >
              //     <img src={blackPhone} />
              //     <span>Join</span>
              //   </button>
              // )
              null}
            </div>
            <div className={classes.joinbtnCandidate}>
              {current_status.status == 1 && current_status.flag == false && interviewData == null ? (
                <button
                  className={classes.text_btn}
                  onClick={() => this.viewScheduleDate(interviewData)}
                  title="Pending Schedule"
                >
                  <img src={pending} />
                  <span>Schedule Interview</span>
                </button>
              ) : null}

              {/* {current_status.status == 1 && current_status.flag == true && current_status.time!=null ? (
                <button
                  className={classes.text_btn}
                  onClick={() => this.viewScheduleDate(interviewData)}
                  title="Pending Schedule"
                >
                  <img src={pending} />
                  <span>ReSchedule Interview</span>
                </button>
              ) : null} */}


               {current_status.status == 1 && current_status.flag == true && current_status.time==null && interviewData == null ? (
                <button
                  className={classes.text_btn}
                  onClick={this.openMessageModel}
                  title="Pending Schedule"
                >
                  <img src={pending} />
                  <span>Schedule Interview</span>
                </button>
              ) : null}

              {/* {current_status.status == 1 && current_status.flag == true && current_status.time==null ? (
                <button
                  className={classes.text_btn}
                  onClick={this.openMessageModel}
                  title="Pending Schedule"
                >
                  <img src={pending} />
                  <span>Schedule Interview</span>
                </button>
              ) : null}  */}

              <Modal size="tiny"
                     open={this.state.open}
                     onClose={this.close}>
                <span onClick={this.close} className={classes.modal_close_btn}>
                  <i className="fas fa-times"></i>
                </span>
                <Modal.Header>Schedule Your campaign</Modal.Header>
                <Modal.Content>
                  {this.isLoding ? (
                    <Spinner />
                  ) : (
                    <SchduleDate
                      schduleDate={this.state.schduleDate}
                      closeModal={this.close}
                      refData={ref}
                    ></SchduleDate>
                  )}
                </Modal.Content>
              </Modal>
              <Modal open={this.state.openModalProcessed} >
                    <CampaignByStaffDashBoard onClose={this.closeProceedModel}/>
              </Modal>
              <Modal
                open={this.state.showMessageModal}
                size="tiny"
                className="noBootstrapModal"
                onClose={this.closeMessageModel}
              >
                <div className={classes.insidePromptModal}>
                  <h2>
                    <img style={{width:"40px",height:"30px"}} src="https://image.flaticon.com/icons/svg/595/595067.svg" />
                    <div>Warning</div>
                  </h2>
                  <div className={classes.secondary}>
                    All the slots have expired, your campaign coordinator will contact you in future
                   </div>
                  {/* <div className={classes.secondary}>Do you want to exit?</div> */}
                  <div className={classes.decisionButton}>
                    <button className={classes.stay} onClick={this.closeMessageModel}>
                      Ok
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
            <span className={classes.rightBorder}>
              {stages[current_status.status]}
            </span>
             {this.props.data.interviewData !=null ?
                 this.props.data.interviewData.start_time !=null ?
                  <span style={{color:'#1697FA'}} className={classes.rightBorder} >{moment(this.props.data.interviewData.start_time).format("Do MMM YYYY hh:mm a")}</span> 
                  :null  
              :null
            }  
            {this.dateDifference(this.props.data)}
                
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    candidate: state.CandidateData.userData,
    availableInterview: state.auth.availableInterview,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCampaignProcess: (data, id) =>
      dispatch(actionsCreator.getCampaignProcess(data, id)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Campaign)
);
