import React, { Component } from "react";
import { connect } from "react-redux";
import Logo from "../../assets/image/ufairs.svg";
import email from "../../assets/image/email.png";
import classes from "./Login.module.scss";
import Styles from "./UfLogin.module.scss";
import * as actionsCreator from "../../store/actions/index";
import { Link } from "react-router-dom";
import { LinkedIn } from "../Login/LinkedIn";
import axios from "../../axios";
import GetStarted from "../../assets/image/Icons/GetStarted.svg";
import Login from "../../assets/image/Icons/Login.svg";
import Agenda from "../../assets/image/Icons/Agenda.svg";
import About from "../../assets/image/Icons/About.svg";
import front_desk from "../../assets/image/dynmc/front_desk.png"
import moment from "moment";
import AboutPage from "./About";
import LoginForm from "../../components/PasswordLessLogin/LoginForm";
import { PDFObject } from 'react-pdfobject'

class LinkedInPage extends Component {
  state = {
    code: "",
    errorMessage: "",
    loading: false,
    linkExpired: false,
    showLogin: false,
    agenda: false,
    aboutUs: false,
    infoImage: "",
    agendaPdf: "",
    ishide: true,
    events: [],
    isData: false,
    eventId: 0,
    eventName:"",
    eventDataSet: false,
    showPassLess: false
  };
  componentWillMount = () => {
    if (localStorage.getItem("linkExpired")) {
      this.setState({ linkExpired: true });
      localStorage.removeItem("linkExpired", false);
    }
    this.getEventData()
    //this.getGeoInfo()
  };
  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;     
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getEventData = () => {
    const search = this.props.location.pathname
    var name = (search.substring(1))
    axios.get("name-validate-event/?name=" + name, {
      headers: {
        "content-type": "application/json",
      },
    }).then((res) => {
      this.props.onGetEventDataBefroLgin(res.data.Data)    

      if (res.data.Data.eventData != null) {

        this.setState({
          infoImage: res.data.Data.campaignData[0].campaignImage,
          agendaPdf: res.data.Data.eventData.agenda+"#zoom=80",
          eventName: res.data.Data.eventData.eventName,
          eventDataSet : true
        })

        localStorage.setItem("eventName", res.data.Data.eventData.eventName)
        localStorage.setItem("eventId", res.data.Data.eventData.id)
        localStorage.setItem("eventImage", res.data.Data.campaignData[0].campaignImage)
      } else {
        localStorage.setItem("eventName", "")
        localStorage.removeItem("eventId", "")
        localStorage.setItem("eventImage", "")
      }

    })
    .catch((err) => {
    });
  }

  getEventLiveEvents = (timeZone) => {
    var date = moment.utc().toISOString();
    axios.get("get-live-events/?logDate=" + date + "&timeZone=" + timeZone, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        this.state.events = res.data.Data;
        this.setState({ isData: true })
      })
      .catch((err) => {
      });
  }
  handleSuccess = (data) => {
    this.setState({
      code: data.code,
      errorMessage: "",
      loading: true,
    });
    this.props.onGetAuthontication(data.code);
  };

  handleFailure = (error) => {
    this.setState({
      code: "",
      errorMessage: error.errorMessage,
    });
  };
  loginClick = () => {
    this.setState({ showLogin: true, agenda: false, aboutUs: false })
  }
  agendaClick = () => {
    this.setState({ agenda: true, showLogin: false, aboutUs: false })
  }
  aboutClick = () => {
    this.setState({ aboutUs: true, showLogin: false, agenda: false })
  }
  selectEvent = (id) => {
    this.setState({ ishide: false, eventId: id })
    this.getEventData(id)
  }
  close=()=>{
    this.setState({ showLogin: false, agenda: false, aboutUs: false, showPassLess:false })
  }
  // getEventData = (id) => {
  //   axios.get("pre-data/?eventId=" + id, {
  //     headers: {
  //       "content-type": "application/json",
  //     },
  //   }).then((res) => {
  //     this.props.onGetEventDataBefroLgin(res.data.Data)
  //     this.setState({
  //       infoImage: res.data.Data.eventCampaign.campaignImage,
  //       agendaPdf: res.data.Data.event.agenda
  //     })
  //     localStorage.setItem("eventName", res.data.Data.event.eventName)
  //     localStorage.setItem("eventId", res.data.Data.event.id)
  //   })
  //     .catch((err) => {

  //     });
  // }

  openPassLessModal = () =>{
    this.setState({ showLogin: false, agenda: false, aboutUs: false, showPassLess:true})
  }
  render() {
    var infoDeskImageShow = null
    if (this.state.eventDataSet === true){
      if(this.state.infoImage == ""){
        infoDeskImageShow = Logo
      }
      else if (this.state.infoImage != ""){
        infoDeskImageShow = this.state.infoImage
      }
    }
    else{
      infoDeskImageShow = null
    }
   
    const { code, errorMessage } = this.state;
    return (
      <div className={Styles.LoginContainer}>
        <div className={Styles.Nav}>
          <div className={Styles.NContainer}>
            <Link to={""} className={Styles.Brand}><img src={Logo}/></Link>
            <span className={Styles.CareerfairName}>{this.state.eventName}</span>
          </div>
        </div>
        <div className={Styles.LoginBodyContainer}>
          <div className={Styles.LBodyContent}>
            {/* LOGIN */}
            {this.state.showLogin == true ?
              <div className={classes.LoginBox}>
                <div className={classes.closeBtnAlign}><button onClick={this.close} className={classes.closebtnnew}>X</button></div>
                <div  className={classes.aboutTopd}>
                  <h3><span className={classes.orange}>LOGIN &amp; CONTINUE</span></h3> 
                </div>

                {this.state.linkExpired && (
                  <div style={{ color: "#db4526", marginBottom: "20px" }}>
                    Your link is expired. Please{" "}
                    <Link to={"/passwordless-login"} style={{ color: "#db4526", textDecoration: "underline" }}>try again</Link>
                  </div>
                )}

                <div className={classes.borderBox}>
                  <div className={classes.linkedInButtonDiv}>
                    <LinkedIn
                      clientId="77jo73g3lqdb06"
                      redirectUri={`${window.location.origin}/linkedin`}
                      scope="r_emailaddress r_liteprofile r_basicprofile rw_company_admin w_member_social w_share"
                      //scope="r_emailaddress r_liteprofile  rw_company_admin w_member_social"
                      state="34232423"
                      onFailure={this.handleFailure}
                      onSuccess={this.handleSuccess}
                    ></LinkedIn>
                  </div>

                  <div className={classes.siaLoginButtonDiv}>
                    {/* <Link to={"/passwordless-login"}>
                      <img src={email} className={classes.emailIcon} />
                      <span>Login with Email</span>
                    </Link> */}

                    {/* <Link> */}
                    <>
                      <img src={email} className={classes.emailIcon} />
                      <span onClick={this.openPassLessModal}>Login with Email</span>
                    </>
                    {/* </Link> */}
                  </div>

                  <div className={classes.termsSection}>
                    By signing up, I agree to Sia's{" "}
                    <a href="https://csuite-hr.com/terms-and-conditions">
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a href="https://csuite-hr.com/privacy-policy">Privacy Policy</a>. <br></br>                 
                      Sia {new Date().getFullYear()}&#169; All right reserved.               
                  </div>
                </div>           
              </div>
              : null
            }
            
            {/* LOGIN FORM MODEL */}
            {this.state.showPassLess === true?
              <div className={classes.LoginBox}>
                <div className={classes.closeBtnAlign}> <button onClick={this.close} className={classes.closebtnnew} >X</button> </div>
                <LoginForm/>
              </div>
              :null
            }

            {/* AGENDA */}
            {this.state.agenda == true ?
              <div className={classes.LoginBox}>
                <div className={classes.closeBtnAlign}><button onClick={this.close} className={classes.closebtnnew}>X</button></div>
                <div  className={classes.aboutTopd}>
                  <h3><span className={classes.orange}>AGENDA</span></h3> 
                </div>

                {/* <embed
                  type="application/pdf"
                  width="100%"
                  height="350px"
                  className="cv-embed-in-campaign-dash"
                  src={this.state.agendaPdf}
                /> */}

                <div className={classes.pdfLink}>
                  <PDFObject url={this.state.agendaPdf}
                    width='100%'
                    height="350px"
                    fallbackLink="<p>This browser does not support PDFs. Please download the PDF to view.<a href='[url]'>Download PDF</a></p>"
                  />
                </div>

                {/* <object data={this.state.agendaPdf} type="application/pdf" width="100%" height="350px">
                  <p>Your web browser doesn't have a PDF plugin.
                  Instead you can <a href={this.state.agendaPdf}>click here to
                  download the PDF file.</a></p>
                </object> */}
              </div>
              : null
            }
            
            {/* ABOUT */}
            {this.state.aboutUs == true ?
              <div className={classes.LoginBox}>
                <div className={classes.closeBtnAlign}><button onClick={this.close} className={classes.closebtnnew}>X</button></div>
                <AboutPage eventData={this.state.events}></AboutPage>
              </div>
              : null
            }

            <>
              {this.state.showLogin==false && this.state.agenda==false && this.state.aboutUs==false?      
                <div className={Styles.WallTv}>
                  <div className={Styles.ImgContent}>
                    {/* <img  src={this.state.infoImage== "" ? Logo :this.state.infoImage} /> */}
                    <img src={infoDeskImageShow}/>
                    {/* <img src={front_desk}/> */}
                  </div>   

                  {/* {this.state.events.map((res, index) => {
                    return (
                      <div className={classes.imgitem}>
                        <img className={classes.imgTv} onClick={() => this.selectEvent(res.event[0].id)} style={{  }} key={index} src={res.campaign.campaignImage} />
                      </div>
                    )
                  })}*/}
                </div>
                :null
              }

              {/* NAVIGATION BUTTONS */}
              <div className={Styles.BootomNav}>
                <ul>
                  <li>
                    <a href="#" onClick={this.loginClick}>
                      <img src={GetStarted}/>
                      <span>Get Started</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={this.loginClick}>
                      <img src={Login}/>
                      <span>Login</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={this.agendaClick}>
                      <img src={Agenda}/>
                      <span>Agenda</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={this.aboutClick}>
                      <img src={About}/>
                      <span>About</span>
                    </a>
                  </li>
                </ul>
              </div>
            </>
          </div>
        </div>
        {/*    
        <div style={{ position: "absolute", bottom: "10px", display: "flex", width: "50%" }}>
          <button disabled={this.state.ishide} >Login</button>
          <button disabled={this.state.ishide} onClick={this.aboutClick}>About Us</button>
          <button disabled={this.state.ishide} onClick={this.agendaClick}>Agenda</button>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetAuthontication: (response) =>
      dispatch(actionsCreator.getAuthontication(response)),
    onGetEventDataBefroLgin: (data) => dispatch(actionsCreator.GetEventDataBefroLgin(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LinkedInPage);
