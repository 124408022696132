import React, { Component } from "react";
import { connect } from "react-redux";
//import Webcam from "react-webcam";
import MediaCapturer from "react-multimedia-capture";
import classes from "./VideoTest.module.scss";
import swal from "sweetalert2";

class VideoTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      granted: false,
      rejectedReason: "",
      recording: false,
      paused: false,
      localCamera: null,
      hideAll: false,
      progress: null,
      videoPitchRecord: false,
      playVideo: null,
      editVideo: null,
      videPlayer: "",
      uploadFinish: false,
      videoSource: "",
      buttonState: false,
    };
    this.handleGranted = this.handleGranted.bind(this);
    this.handleDenied = this.handleDenied.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleResume = this.handleResume.bind(this);
    this.handleStreamClose = this.handleStreamClose.bind(this);
  }

  componentMount = () => {
    var video = document.querySelector("#videoElement");
    var that = this;
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          video.srcObject = stream;
          console.log("-----", stream);
          this.setState({ localCamera: stream, str: stream });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  handleGranted() {
    this.setState({ granted: true });
    console.log("Permission Granted!");
  }
  handleDenied(err) {
    // this.setState({ rejectedReason: err.name });
    console.log("Permission Denied!", err);
  }
  handleStart(stream) {
    this.componentMount();
    this.setState({
      recording: true,
    });
    console.log("Recording Started.");
  }

  removeLocalCam = () => {
    var video = document.querySelector("#videoElement");
    if (video != null) video.srcObject.stop();
  };

  handleStop(blob) {
    this.setState({
      recording: false,
    });
    console.log("Recording Stopped.", blob);

    this.removeLocalCam();
  }
  handlePause() {
    this.setState({
      paused: true,
    });
  }
  handleResume(stream) {
    this.setState({
      paused: false,
    });
  }
  handleStreamClose() {
    this.setState({
      granted: false,
    });
  }

  handleError(err) {
    console.log(err);
    swal.fire({
      toast: true,
      position: "top-end",
      title: err,
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: "swal-success-settings" },
      imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
      imageHeight: 20,
    });
  }

  render() {
    return (
      <div className={classes.videoTestWrapper}>
        <div className={classes.heading}>
          <img src="https://image.flaticon.com/icons/svg/2922/2922272.svg" />
          <h2>Camera test</h2>
        </div>
        <MediaCapturer
          constraints={{ audio: true, video: true }}
          timeSlice={10}
          onGranted={this.handleGranted}
          onDenied={this.handleDenied}
          onStart={this.handleStart}
          onStop={this.handleStop}
          onPause={this.handlePause}
          onResume={this.handleResume}
          onError={this.handleError}
          onStreamClosed={this.handleStreamClose}
          render={({ request, start, stop, pause, resume }) => (
            <div>
              {/* <p>Granted: {this.state.granted.toString()}</p>
                        <p>Rejected Reason: {this.state.rejectedReason}</p>
                        <p>Recording: {this.state.recording.toString()}</p>
                        <p>Paused: {this.state.paused.toString()}</p> */}
              <video autoPlay id="videoElement"></video>
              <div className={classes.startButtons}>
                {!this.state.granted && (
                  <button onClick={request}>Get Permission</button>
                )}
                <button onClick={this.state.recording ? stop : start}>
                  {this.state.recording ? "Stop test" : "Start test"}
                </button>
              </div>
              {/* <button onClick={stop}>Stop</button>
                        <button onClick={pause}>Pause</button>
                        <button onClick={resume}>Resume</button> */}

              {/* <p>Streaming test</p> */}
            </div>
          )}
        />
        <div onClick={this.handleStop} className={classes.absoluteExit}>
          <button onClick={this.props.closeModal}>
            <i className="fas fa-times" />
          </button>
        </div>
      </div>
    );
  }
}

export default VideoTest;
