import React, { Component } from "react";
import Classes from "./Index.module.scss";

class CandidateComment extends Component {
  //   constructor(){
  //       super()
  //       this.state={
  //           staateNew : "1"
  //       }
  //   }

  //   getCommentsUser=()=>{
  //       console.log("Get commnts from api")
  //   }

  render() {
    console.log("ffu");
    var comment = this.props.Comment;
    return (
      <section className={Classes.feedbackWrap}>
        <div className={Classes.friend}>
          <img src="http://media.dunkedcdn.com/assets/prod/13/700x0-5_p17o72pss9bbmvuspb1gl61ot23.jpg" />
          <div className={Classes.right}>
            <h4>{comment.feedback}</h4>
            <h5 className={Classes.header}>{comment.Name}</h5>
          </div>
        </div>
      </section>
    );
  }
}

export default CandidateComment;
