import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import * as actionsCreator from "../../../store/actions/index";
import classes from "./AllEvents.module.scss";
import atendee1 from "../../../assets/image/imagetestatend.jpg";
import Event from './Event/Event';
import black1 from "../../../assets/image/black.jpg";
import moment from "moment";
import axios from "../../../axios";

class AllEvents extends Component {

    constructor(props) {
        super(props)
        this.state = {
            events: null,
            isSent: false,
            isAPI: false,
            allCompanies: []
        }
    }
    componentDidMount() {
        var currentTime = moment.utc().toISOString();
        setInterval(() => this.getAllCompanies(), 1000)
        //this.props.onGetALLEventsData({currentTime})
    }

    getAllCompanies=()=>{
        //var token = localStorage.getItem("chat-token"); 
        if (this.state.isAPI === false){
            axios.get('companies-list-events/' ,{
                headers: {
                //   Authorization: `Token ${token}`,
                  "Content-Type": `application/json`,
                }
            }).then(res=>{     
                console.log(res.data.Data, "Result data events")
                this.setState({
                    allCompanies: res.data.Data.events,
                    isAPI: false
                })
            })
            .catch(err=>{
                //dispatch(ErrorGetEventParticipents(null))
            })
        }
        else{
            console.log("abc")
        } 
    }

    render() {
        let participents = (
            <div className={classes.instructionWrapper}>
                <div className={classes.instructions}>
                <div className={classes.heading}>
                    <span>Events will be updated soon!</span>
                </div>
                {/* <div className={classes.descr}>
                Wanna know more about Ufairs?
                </div>
                <a href="#">Yes</a> */}
                </div>
            </div>
        )

        console.log('this.props.events',this.state.allCompanies)
        if (this.state.allCompanies !== null) {
            console.log("not null ####")
            if (this.state.allCompanies.length > 0){
                console.log("all companies ####")
                participents = this.state.allCompanies.map((res, idx) => {
                    return (
                        <>
                            <Event data={res} key={idx} />
                        </>
                    )
                })
            }      
        }
        return (
            <div>
                <div className={
                    classes.atendFlexouter
                }>
                    {/* <div>
                        <button onClick={this.prev} className={classes.back_btn1} > <i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                    </div> */}
                
                    <div className={classes.atendFlex}>
                        {participents}
                    </div>

                    {/* <div>
                        <button onClick={this.next} className={classes.back_btn1}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                    </div> */}
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        events: state.auth.successAllEvts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetALLEventsData: (data) => dispatch(actionsCreator.GetAllEventsData(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllEvents)