import React, { Component } from "react";
import { connect } from "react-redux";
import objecChecker from "../../../../../hoc/objectChecker";
import classes from "./Data.module.scss";
import * as actionsCreator from "../../../../../store/actions/index";

class ExpData extends Component {
  constructor(props) {
    super();
  }

  deleteData = () => {
    this.props.deleteExp(this.props.dataIndex);
    this.props.setChanged(true);
  };

  editData=()=>{
    this.props.editExperience(this.props.dataIndex)
  }

  render() {
    var company = [objecChecker(this.props.data, ["company"])];
    var position = [objecChecker(this.props.data, ["position"])];
    var duration = [objecChecker(this.props.data, ["duration"])];
    return (
      <div className={classes.eachData}>
        <div className={classes.dataFields}>
          <div className={classes.institute}>{company}</div>
          <div>{position}</div>
          <div>{duration}</div>
        </div>
        <div className={classes.delete}>
          <button onClick={this.deleteData}>
            <i className="far fa-trash-alt" />
          </button>
          <button onClick={this.editData}>
          <i class="fas fa-edit"></i>
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setChanged: (data) => dispatch(actionsCreator.setSomethingChanged(data)),
  };
};
export default connect(null, mapDispatchToProps)(ExpData);
