import React, { Component } from "react";
import classes from "./Preferences.module.scss";
import Spinner from "../../UI/Spinner/Spinner";
import { connect } from "react-redux";
import * as actionsCreator from "../../../store/actions/index";
import swal from "sweetalert2";
import axios from "../../../axios";
import Question from "./Queston"
import moment from "moment";

class CareerPreferences extends Component {
  constructor(prop) {
    super();
    this.state = {
      submitted: false,
      options: [],
      isRegister: false,
      isEanableQuestion: false,
      eventCampaignId: 0,
      questions: []
    };
  }
  componentDidMount = () => {
    this.getEventData()
  };

  getEventData = () => {
    //this.props.event.event.id
    var id = localStorage.getItem("eventId")
    var candidateId = localStorage.getItem("CandidateProfile_id02")
    var  curr_time = moment().toISOString()
    axios.get("event/?eventId=" + id + "&inwRef=&candidateId=" + candidateId+"&logTime="+curr_time, {
      headers: {
        "content-type": "application/json",
      },
    }).then((res) => {
      var data = res.data.Data.content
      this.state.questions = data.registration
      this.setState({
        isRegister: data.isRegistered,
        isEanableQuestion: data.eventData.registerFormEnable,
        eventCampaignId: data.campaignEvent.id,    
      })
    })
      .catch((err) => {
      });
  }

  candidateRegisterEvent = () => {
    this.setState({
      submitted: true,
    });
    const token = localStorage.getItem("chat-token");
    var data = {
      answers: this.state.questions,
      eventId: localStorage.getItem("eventId"),
      eventCampaignId: this.state.eventCampaignId,
      candidateId: localStorage.getItem("CandidateProfile_id02")
    }
    axios.post('register-event/', data, {
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res => {
       this.updateProfile()
    })
  }

  updateProfile = () => {
    var idData = sessionStorage.getItem("CandidateProfile_id");
    var userID = sessionStorage.getItem("CandidateProfile_id02");
    var data = {
      phone_number: this.props.phoneNumber,
      timeZone: this.props.zone,
      current_location: this.props.country,
      onBoard_state: "COMPLETE",
      uploaded_at: "2019-09-20T00:00:00Z",
      upload: "",
      userId: userID,
      skills: this.props.skills ? this.props.skills.toString() : "",
      education: this.props.cvDataEdu == null ? [] : this.props.cvDataEdu,
      experience: this.props.cvDataExp == null ? [] : this.props.cvDataExp,
      career_preferences: "",
      format_type: "COMPLETE",
    };
    this.props.onsendData(idData, data);
    localStorage.setItem("REFRESHSTATUS", true);
  };
  
  colseReg=()=>{
    this.setState({submitted:false})
  };

  handleOnchange = (event) => {
    event.preventDefault();
    this.setState({
      eachItem: event.target.value,
    });
  };
  render() {
    return (
      
      <div className={classes.careeerPreferWrapper}>
        <div className={classes.careerPrefer}>        
          <div className={classes.whiteBox}>
            <div className={classes.textIntro}>
              <h3>Register Yourself , fill the <span className={classes.orange}> questions </span>
               to continue</h3>
              
            </div>
            {this.state.isRegister == false ?
              //this.state.isEanableQuestion == true ?
              <>
                {/* <div className={classes.textIntro1}>
                  Fill Register Qustion and Go</div> */}

                <div className={classes.wrapperRegister}>
                <div className={classes.row}>
                  {this.state.questions.map((res, index) => {
                    return (
                      <>
                        <div className={classes.column}>
                          <Question key={index}
                            question={res}
                          />
                        </div>
                      </>
                    )
                  })
                  }
                </div>
                </div>

                 <div className={classes.listAndInput}>
                  <div className={classes.okButtonDiv}>
                    <button onClick={this.candidateRegisterEvent}>
                      {this.state.submitted ? <Spinner /> : "OK, Let's dive in"}
                    </button>
                  </div>
                </div>
                </>
              : null }
          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authData: state.auth.auth,
    loading: state.auth.loading,
    phoneNumber: state.auth.phoneNumber,
    location: state.auth.location,
    cvString: state.auth.cvString,
    cvDataExp: state.auth.cvDataExp,
    cvDataEdu: state.auth.cvDataEdu,
    skills: state.auth.skills,
    event: state.auth.eventData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onsendData: (idData, data) =>
      dispatch(actionsCreator.updateProfile(idData, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CareerPreferences);
