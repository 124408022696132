import React, { Component, useState } from "react";
import classes from "./Onboard.module.scss";
import "./Onboard.scss";
import SimpleReactValidator from "simple-react-validator";
// import "react-phone-number-input/style.css";
import { connect } from "react-redux";
import * as actionsCreator from "../../../src/store/actions/index";
import { json } from "body-parser";
import { Button, Modal } from "semantic-ui-react";
import ReactCodeInput from "react-verification-code-input";
import axios from "../../axios";
import PhoneInput from "react-phone-input-2";
import Spinner from "../UI/Spinner/Spinner";
import "react-phone-input-2/lib/style.css";
import { invalid } from "moment";

class PhoneNumberPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Phone: "",
      ErrorPhone: "",
      codeSent: false,
      verifyCode: "",
      invalidMobie: "",
      invalidCode: "",
      loader: false,
      loaderInModal: false,
      countryCode: "gb",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        phone: "The phone number you entered is not valid",
      },
    });
  }

  componentDidMount() {
    this.getGeoInfo();
  }

  handleInputChange = (value) => {
    this.setState({
      Phone: value.replace(/-|\s/g, ""),
    });
  };

  handleInputChangeCode = (val) => {
    this.setState({
      verifyCode: val,
    });
  };

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.setState({
          countryCode: data.country_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  btnSaveProceed = (e) => {
    //this.props.onzSkip();
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        loader: true,
        invalidCode: "",
      });
      this.props.onDataSave(this.state.Phone);
      this.props.onSendData(JSON.stringify(this.state.Phone));
      const token = localStorage.getItem("chat-token");
      
      var number = this.state.Phone.trim()
      var test = number.charAt(0)
      if (test != "+") {
        number = "+" + number
      }
      var data = {
        phoneNumber: number,
      };
      axios
        .post("get-phone-number/", data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          this.setState({ codeSent: true, invalidMobie: "", loader: false });
        })
        .catch((err) => {
          this.setState({
            invalidMobie: err.response.data.Error,
            loader: false,
          });
          // this.setState({ codeSent: true, invalidMobie: "", loader: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  btnSkipForNow = (e) => {
    e.preventDefault();
    this.props.onSkip();
    //this.props.onSkipSteps(this.props.stepNo)
  };

  onSendVerifyCode = () => {
    const token = localStorage.getItem("chat-token");
    var mobile = this.state.Phone.replace(/ +/g, "");
    this.setState({
      loaderInModal: true,
    });
    axios
      .get(
        "get-phone-number/?phone_number=" +
          mobile +
          "&code=" +
          this.state.verifyCode,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loaderInModal: false,
        });
        var data = res.data.Data;
        console.log(data, "data");

        if (data == true) {
          this.props.onSkip();
        } else {
          this.setState({ invalidCode: "Code is invalid" });
        }
        // this.setState({ codeSent: false });
      })
      .catch((err) => {});
    // this.props.onSkip();
  };
  resendCode = () => {
    const token = localStorage.getItem("chat-token");
    var data = {
      phoneNumber: this.state.Phone.trim(),
    };
    axios
      .post("get-phone-number/", data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        this.setState({
          invalidMobie: err.response.data.Error,
          loader: false,
        });
      });
  };

  handleOpen = () => this.setState({ codeSent: true });

  handleClose = () => this.setState({ codeSent: false });

  render() {
    //const [value,handleInputChange] = useState()
    return (
      <div className={classes.phoneStepWrapper}>
        <div className={classes.phoneStep}>
          <div className={classes.topRowWrapper}>
            <h1>
              Hi{" "}
              <span className={classes.orange}>
                {sessionStorage.getItem("firstName")}
              </span>
              ,
            </h1>
            <h3>Welcome to Sia, Your digital companion</h3>
          </div>
          <div className={classes.sectionWrap}>
            {!this.state.codeSent ? (
              <div className={`${classes.section} phone-input-2`}>
                <div className={classes.topInputArea}>
                  <div className={classes.tipText}>
                  Please authenticate your phone number with us so that we can promptly coordinate your career engagements, We will never spam you.
                  </div>
                </div>
                <PhoneInput
                  country={this.state.countryCode.toLowerCase()}
                  // country=""
                  value={this.state.Phone}
                  onChange={this.handleInputChange}
                  name="Phone"
                  placeholder="Type your phone number"
                />
                <div className={classes.errorDiv}>
                  {this.validator.message(
                    "Phone",
                    this.state.Phone,
                    "required|phone"
                  )}
                  {this.state.invalidMobie}
                </div>
                 {/* <div style={{textAlign:"right",color:"#8a8a8a"}}> Eg. +94 777 777 777</div> */}
                <button
                  className={classes.proceedButton}
                  onClick={this.btnSaveProceed}
                  disabled={this.state.loader ? true : false}
                >
                  {this.state.loader ? <Spinner /> : "Save & Proceed"}
                </button>
              </div>
            ) : (
              <div className={`${classes.section} ${classes.phoneVerify}`}>
                <div className={classes.textHeader}>
                  Enter the verification code that was sent to your phone.
                </div>
                <div className={classes.codeCenterSection}>
                  <div className={`error-phone ${classes.invalid}`}>
                    {this.state.invalidCode}
                  </div>
                  <ReactCodeInput
                    type={"text|number"}
                    fields={4}
                    //onComplete={this.onSendVerifyCode}
                    onChange={this.handleInputChangeCode}
                    value={this.state.verifyCode}
                    className="reactVerificationCode"
                  />
                  <div className={classes.belowEnterCode}>
                    <button
                      onClick={() =>
                        this.setState({
                          codeSent: false,
                        })
                      }
                      className={classes.changePhone}
                    >
                      Change phone no.
                    </button>
                    <button
                      onClick={this.resendCode}
                      className={classes.resend}
                    >
                      Resend code
                    </button>
                  </div>
                </div>

                <button
                  positive
                  className={classes.proceedButton}
                  onClick={this.onSendVerifyCode}
                >
                  Verify
                </button>
              </div>
            )}
          </div>
        </div>
        {/*
        <Modal
          open={this.state.codeSent}
          onClose={this.handleClose}
          size={"tiny"}
          className="phone-verify-modal"
        >
          <Modal.Header>Verify moble number</Modal.Header>
          <Modal.Content>
            <p>Enter the verification code that is sent to your phone.</p>
            <div className="veriy-code-enter">
              <ReactCodeInput
                type={"text|number"}
                fields={4}
                //onComplete={this.onSendVerifyCode}
                onChange={this.handleInputChangeCode}
                value={this.state.verifyCode}
              />

              <div className="spans">
                <button onClick={this.resendCode} className="resend-button">
                  Resend code
                </button>
                <div className="error-phone">{this.state.invalidCode}</div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              icon={this.state.loaderInModal ? "spinner loading" : "checkmark"}
              labelPosition="right"
              content="Verify"
              onClick={this.onSendVerifyCode}
            />
          </Modal.Actions>
        </Modal>
      */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendData: (data) => dispatch(actionsCreator.sendData(data)),
    // onSavedSteps : data=> dispatch(actionsCreator.savedSteps(data)),
    // onSkipSteps :  data=> dispatch(actionsCreator.skipSteps(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberPanel);
