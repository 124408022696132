import React, { Component, Fragment } from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../UI/Spinner/Spinner";
import Errors from "../../hoc/objectChecker";
import classes from "./LoginForm.module.scss";
import { Link, Redirect } from "react-router-dom";
import axios from "../../axios";
import RegisterUser from "../PasswordLessLogin/RegisterUser";


const loaderStyles = {
  color: "red",
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translateX(-50%)",
};

const emailErrorStyles = {
  border: "1px dashed #906161",
  borderRadius: "6px",
  color: "#db4a27",
  padding: "8px",
  opacity: "0.7",
  textAlign: "center",
  marginTop: "-16px",
  marginBottom: "20px",
  // marginBottom: '20px'
};

export class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      error: false,
      isLogMailSuccess: false,
      emailAddressIsInDatabase: "",
      formSubmitted: false,
      showLoader: false,
      emailFormatError: true,
      isSignUp: true,
      events:[]
    };
    this.loginHandler = this.loginHandler.bind(this);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.errorMessages) {
      this.setState({ error: true });
    }
  };
  removeError = () => {
    this.setState({ error: false });
  };
  loginPressedHandler = () => {
    this.setState({
      formSubmitted: true,
      showLoader: false,
    });
  };

  onchangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  loginHandler = (e) => {
    e.preventDefault();
    console.log("login pressed");
    this.setState({
      showLoader: true,
      emailFormatError: "",
    });

    var hasSubDomain = this.hasSubDomain(window.location.href)
    var isSubdomain = null
    console.log(hasSubDomain, "Has sub domain")

    if (hasSubDomain === true){
      isSubdomain = window.location.hostname.split('.')[0]
    }
    else if(hasSubDomain === false){
      isSubdomain = null
    }

    const jsonString = {
      email: this.state.username,
      status: 0,
      eventId: localStorage.getItem("eventId"),
      isSubdomain: isSubdomain
    };

    const token1 = localStorage.getItem("chat-token");
    return axios
      .post(`password-less-login/`, jsonString, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res1) => {
        console.log("result came --  result.data:", res1.data);
        if (res1.Login !== "Invalid email") {
          this.setState({
            emailAddressIsInDatabase: res1.data.Login,
            error: true,
          });
        } else {
          this.setState({
            emailFormatError: res1.data.Login,
            error: false,
          });
        }
        this.loginPressedHandler();
      })
      .catch((err) => {
        this.setState({
          error: true,
        });
      });
  };

  hasSubDomain = (url) => {

    // IF THERE, REMOVE WHITE SPACE FROM BOTH ENDS
    url = url.replace(new RegExp(/^\s+/),""); // START
    url = url.replace(new RegExp(/\s+$/),""); // END

    console.log(url, "Url 1")

    // IF FOUND, CONVERT BACK SLASHES TO FORWARD SLASHES
    url = url.replace(new RegExp(/\\/g),"/");

    console.log(url, "Url 2")

    // IF THERE, REMOVES 'http://', 'https://' or 'ftp://' FROM THE START
    url = url.replace(new RegExp(/^http\:\/\/|^https\:\/\/|^ftp\:\/\//i),"");

    console.log(url, "Url 3")

    // IF THERE, REMOVES 'www.' FROM THE START OF THE STRING
    url = url.replace(new RegExp(/^www\./i),"");

    console.log(url, "Url 4")

    // REMOVE COMPLETE STRING FROM FIRST FORWARD SLASH ON
    url = url.replace(new RegExp(/\/(.*)/),"");

    console.log(url, "Url 5")

    // REMOVES '.??.??' OR '.???.??' FROM END - e.g. '.CO.UK', '.COM.AU'
    if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
        url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i),"");

    // REMOVES '.??' or '.???' or '.????' FROM END - e.g. '.US', '.COM', '.INFO'
    } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
        url = url.replace(new RegExp(/\.[a-z]{2,4}$/i),"");
    }

    console.log(url, "Url 6")

    // CHECK TO SEE IF THERE IS A DOT '.' LEFT IN THE STRING
    var subDomain = (url.match(new RegExp(/\./g))) ? true : false;

    return(subDomain);
}

  render() {
    let errorMessage = Errors(this.props.errorMessages, ["non_field_errors"]);
    let username = Errors(this.props.errorMessages, ["username"]);
    let password = Errors(this.props.errorMessages, ["password"]);
    return (
      <>
     
      <Fragment>
      {/* <div className={classes.closeBtnAlign}> <button onClick={this.close} className={classes.closebtnnew} >X</button> </div> */}
        {this.state.error && this.state.error && errorMessage ? (
          <div className={classes.errorMessage} onClick={this.removeError}>
            {this.props.errorMessages.non_field_errors}
          </div>
        ) : null}        
        {this.state.emailAddressIsInDatabase !== "False" &&
          this.state.emailAddressIsInDatabase !== "True" && (
            
            <form onSubmit={this.loginHandler} className={classes.LoginForm}>             
              <div className={classes.borderContainer}>
                {this.state.error && username ? (
                  <div className={classes.Error}>{username}</div>
                ) : null}
                <div className={classes.instructions}>
                  Enter your email ID and we'll send you the{" "}
                  {this.state.isSignUp ? "sign up" : "sign in"} link
                </div>

                {/*---- Email-id format is wrong ----*/}
                {this.state.error ? (
                  <div style={emailErrorStyles}>
                    <div>An error occured - Please try again.</div>
                  </div>
                ) : null}

                <input
                  className={
                    username && this.state.error ? classes.ErrorInput : null
                  }
                  value={this.state.username}
                  type="email"
                  placeholder="Email"
                  name="username"
                  onChange={this.onchangeHandler}
                  onKeyPress={this.removeError}
                  required
                />
              <button
                className={classes.btnEmail}
                  type="submit"
                  disabled={this.state.showLoader ? true : false}
                  style={{ fontSize: "16px" }}
                >
                  {this.state.showLoader ? (
                    <Spinner />
                  ) : this.state.isSignUp ? (
                    "Get started"
                  ) : (
                    "Sign in"
                  )}
                </button>
                <div className={classes.terms}>
                  By {this.state.isSignUp ? "signing up" : "signing in"}, I
                  agree to Sia's{" "}
                  <a href="https://csuite-hr.com/terms-and-conditions">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="https://csuite-hr.com/privacy-policy">
                    Privacy Policy
                  </a>
                </div>
              </div>
              {/* removed as adviced by c */}
              {/* <div className={classes.otherAuth}>
                {this.state.isSignUp ? (
                  <>
                    Already using Sia? <Link to="/">Sign in</Link>
                  </>
                ) : (
                  <>
                    New to Sia? <Link to="/">Get started</Link>
                  </>
                )}
              </div> */}
            </form>
          )}

        
      </Fragment>
      {/*----------------------------------------------------------------------------------
         form-submitted  +  email-address-is-NOT-in-database  +  email-format-is-correct */}
        {this.state.formSubmitted &&
          this.state.emailAddressIsInDatabase === "False" &&
          !this.state.emailFormatError && (
            <div className={classes.StatusBox}>
              <div className={classes.emailSentDiv}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="lg"
                  style={{ color: "green" }}
                />
                <div>
                  An activation link was sent to <br />
                  <span title={this.state.username}>{this.state.username}</span>
                </div>
              </div>
              <div className={classes.clickLinkDiv}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="lg"
                  style={{ color: "lightgrey" }}
                />
                <div>Click on the link in the email to get started.</div>
              </div>
              <div className={classes.clickLinkDiv}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="lg"
                  style={{ color: "lightgrey" }}
                />
                <div>If you have not received the email please check your 'Spam' or 'Junk Email' folder.</div>
              </div>
            </div>
          )}

        {/*----------------------------------------------------------------------------------
         form-submitted  +  email-address-is-in-database  +  email-format-is-correct */}
        {this.state.formSubmitted &&
          this.state.emailAddressIsInDatabase === "True" &&
          !this.state.emailFormatError && (
            <div className={classes.StatusBox}>
              <div className={classes.emailSentDiv}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="lg"
                  style={{ color: "green" }}
                />
                <div className={classes.nameStatus}>
                  An email with a log in link was sent to <br />
                  <span title={this.state.username}>{this.state.username}</span>
                </div>
              </div>
              <div className={classes.clickLinkDiv}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="lg"
                  style={{ color: "lightgrey" }}
                />
                <div className={classes.nameStatus}>Click on the link in the email to log in.</div>
              </div>
            </div>
      )
    }
    </>
    );
  }
}

export default LoginForm;
