import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./Data.module.scss";
import { Button, Card, Image, Icon } from "semantic-ui-react";
import FlowControl from "../Controller/FlowControl/FlowControl";
import defaultCompany from "../../../../assets/image/sia-in-box.png";
import defaultCamImage from "../../../../assets/image/sia_default.png";
import LogoUfair from "../../../../assets/image/dynmc/stall_middle.png";


class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  handleCloseListModal = () => {
    this.setState({ isOpen: false });
  };
  viewCampaign = () => {
    this.setState({ isOpen: true });
   // localStorage.setItem("OpenSmartCV", true);
  };
  closeCampaign = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <div className={classes.near_campaigns}>
        {this.props.data.campaign.isPublish ?
          <div className={classes.nc_content}>
            <Card className={classes.nc_ui} onClick={this.viewCampaign}>
              <Image
                className={classes.nc_ui_image}
                src={this.props.data.campaign.campaignImage?this.props.data.campaign.campaignImage:LogoUfair}
                wrapped
                ui={false}
              />
              <Card.Content className={classes.nc_ui_content}>    
                <Card.Header>{this.props.data.campaign.heading}</Card.Header>
                <Card.Description className={classes.nc_body}>
                  <span className={classes.c_images}>
                    <img
                      src={
                        this.props.data.company.companyLogo
                          ? this.props.data.company.companyLogo
                          : defaultCompany
                      }
                    />
                  </span>
                  <span className={classes.c_name}>
                    {this.props.data.company.name}
                  </span>
                </Card.Description>
                <Card.Meta>
                  <span className={classes.nc_ui_footer}>
                    {this.props.data.type}
                  </span>
                </Card.Meta>
              </Card.Content>
            </Card>
          </div>
           : 
          <div className={classes.nc_content}>      
            <Card className={classes.nc_ui}>
              <div style={{position:"relative"}}>
              <Image
                className={classes.nc_ui_image}
                src={this.props.data.campaign.campaignImage?this.props.data.campaign.campaignImage:defaultCamImage}
                wrapped
                ui={false}
              />
              <span className={classes.overlayText}>Applications closed</span>
              </div>
           
              <Card.Content className={classes.nc_ui_content}>
              <div className={classes.overlay}></div>

                <Card.Header>{this.props.data.campaign.heading}</Card.Header>
                <Card.Description className={classes.nc_body}>
                  <span className={classes.c_images}>
                    <img
                      src={
                        this.props.data.company.companyLogo
                          ? this.props.data.company.companyLogo
                          : defaultCompany
                      }
                    />
                  </span>
                  <span className={classes.c_name}>
                    {this.props.data.company.name}
                  </span>
                </Card.Description>
                <Card.Meta>
                  <span className={classes.nc_ui_footer}>
                    {this.props.data.type}
                  </span>
                </Card.Meta>
              </Card.Content>
            </Card>
          </div>
        }
        {this.state.isOpen ? (
          <FlowControl
            closeCampaign={this.closeCampaign}
            campaign={this.props.data.campaign}
            compaigns={this.props.compaigns}
            index={this.props.index}
          ></FlowControl>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Data));
