import React, { Component } from "react";
import classes from "./PreferenceListItem.module.scss";
import "./PreferenceListItem.scss";
import { Modal } from "semantic-ui-react";

class PreferenceListItem extends Component {
  // state = {
  //   DeleteModalIsOpen: false,
  // };
  // openPromptModal = () => {
  //   this.setState({ DeleteModalIsOpen: true });
  // };
  // closePromptModal = () => {
  //   this.setState({ DeleteModalIsOpen: false });
  // };
  render() {
    // console.log("331", this.props.item.id);
    return (
      <div className={classes.preferenceListItem}>
        <div className={classes.left}>
          <i className="fas fa-grip-vertical"></i>
          <div className={classes.title}>{this.props.title}</div>
        </div>
        <div className={classes.right}>
          <button onClick={() => this.props.deletePreference(this.props.numb)}>
            <i className="far fa-trash-alt"></i>
          </button>       
        </div>
      </div>
    );
  }
}

export default PreferenceListItem;
