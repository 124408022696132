import React from "react";
import ProfilingModal from "../../ProfilingModal/ProfilingModal";
import { connect } from "react-redux";
import * as actionCreater from "../../../store/actions/index";

class Profiling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testStatus: "ongoing",
      // profilingModalIsOpen: false,
      currentQuestion: 0,
      quoteNumber: 0,
      totalQuestions: 54,
      capacityOfEachLap: "",
      currentLap: 1,
      positionInLap: 1,
      progressPercentage: "",
      message: ""   
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    this.setState(
      {
        capacityOfEachLap: this.state.totalQuestions / 3
      },
      () => {
        this.setState({
          progressPercentage:
            (this.state.positionInLap / this.state.capacityOfEachLap) * 100
        });
      }
    );
  }

  handleNext = () => {
    var { capacityOfEachLap, positionInLap } = this.state;
    if (this.state.currentQuestion + 1 < this.state.totalQuestions) {
      this.setState(
        {
          currentQuestion: this.state.currentQuestion + 1
        },
        () => {
          console.log("t5", this.state.currentQuestion);
          this.setState({
            quoteNumber: Math.floor(this.state.currentQuestion / 9)
          });
          if (positionInLap < capacityOfEachLap) {
            this.setState({
              positionInLap: positionInLap + 1,
              progressPercentage:
                100 / capacityOfEachLap +
                (positionInLap / capacityOfEachLap) * 100
            });
          } else {
            this.setState(
              {
                positionInLap: 1,
                currentLap: this.state.currentLap + 1,
                progressPercentage: 100 / capacityOfEachLap
              },
              () => console.log("312", this.state)
            );
          }
        }
      );
    } else {
      this.setState({
        testStatus: "finished"
      });
    }
  };

  openProfilingModal = () => {
    this.setState({ profilingModalIsOpen: true });
  };
  handleStartTest = () => {
    this.setState({
      testStatus: "ongoing"
    });
  };
  render() {
    var question = [];
    try {
      question = this.props.question.Data;
    } catch {
      question = [];
    }
    return (
      <div>
        <ProfilingModal
          profilingModalIsOpen={this.props.profilingModalIsOpen}
          openProfilingModal={this.openProfilingModal}
          closeProfilingModal={this.props.closeProfilingModal}
          question={question[this.state.currentQuestion]}
          currentQuestionNo={this.state.currentQuestion}
          quoteNumber={this.state.quoteNumber}
          handleNext={this.handleNext}
          // handleNext={this.props.handleNextQuestion}
          progress={this.state.progressPercentage}
          currentLap={this.state.currentLap}
          testStatus={this.state.testStatus}
          handleStartTest={this.handleStartTest}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    question: state.chat.questions
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Profiling);
