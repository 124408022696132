import * as actions from "../actions/actionTypes";
import isPayload from "../../hoc/isPayload";
import { ActionNoteAdd } from "material-ui/svg-icons";
// import moment from 'moment'

const initialState = {
  loading: false,
  historyLoading: false,
  responseMessages: [],
  onDisabled: false,
  startNotification: false,
  dataLable: "",
  dataValue: false,
  openCVModal: false,
  chatModalStatus: false,
  cvUploaded: null,
  uploadProgress: false,
  videoQuestions: null,
  videoSubmitStatus: false,
  campaignFinder: false,
  uploadCoverLetter : null,
  cvlProgress : false,
  initialStartCnvsation : false,
  welcomeback : false ,
  interviewTime : null,
  chatPopUp : false,
  unscheduled_campaigns : false,
  exitVideoPitch: false,
  appliedCampaignTemp : null,
  closeChatComponent : null,
  sendSupport : null,
  exitSupport : false,
  videoPitchStatus : false,
  videoRecord : null,
  exitFAQ : false,
  submitFAQ :  null,
  filterCampaigns:null,
  loggedTime: false,
  available_campaign : null,
  roomData:null,
  chatOpen: false
};
const prototypeResponceMessage = (
  message,
  type,
  time,
  visibility,
  buttonStatus,
  videoStatus
) => {
  return {
    message,
    type,
    time,
    visibility,
    buttonStatus,
    videoStatus
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CHATNEW_INIT:
      return {
        ...state,
        loading: true,
        onDisabled: true,
        responseMessages: state.responseMessages.concat(
          prototypeResponceMessage(
            isPayload(action.query),
            "query",
            action.humanizedTime,
            action.queryVisibility,
            false
          )
        )
      };
    case actions.CHATNEW_FAIL:
      return {
        ...state,
        loading: false,
        onDisabled: true
      };
    case actions.HISTORYNEW_FAIL:
      return {
        ...state,
        historyLoading: false
      };
    case actions.HISTORYNEW_INIT:
      return {
        ...state,
        historyLoading: true
      };
    case actions.CHATNEW_SUCCESS:
      const responseMessages = [...state.responseMessages];
      const lastMessage = { ...responseMessages[responseMessages.length - 2] };
      lastMessage.buttonStatus = false;
      responseMessages[responseMessages.length - 2] = lastMessage;
      // const time = moment(new Date()).format("hh:mm a");
      return {
        ...state,
        loading: false,
        onDisabled: false,
        startNotification: true,
        responseMessages: responseMessages.concat(
          prototypeResponceMessage(
            action.response,
            "response",
            // "Just now",
            action.humanizedTime,
            true,
            true,
            true
          )
        )
      };
    case actions.HISTORYNEW_SUCCESS:
      return {
        ...state,
        historyLoading: false,
        responseMessages: state.responseMessages.concat(mergeArray(action.data))
      };
    case actions.OPEN_MODAL_SUCCESS:
      return {
        ...state,
        dataValue: action.data
      };
    case actions.OPEN_MODAL_LABLE:
      return {
        ...state,
        dataLable: action.data
      };
    case actions.OPEN_CV_MODAL:
      return {
        ...state,
        openCVModal: action.data
      };
    case actions.CLOSE_CHAT_MODAL:
      return {
        ...state,
        chatModalStatus: action.data
      };
    case actions.OPEN_CHAT_MODAL:
      return {
        ...state,
        chatModalStatus: action.data
      };
    case actions.SUCCESS_CV_DATA:
      return {
        ...state,
        cvUploaded: action.data
      };
    case actions.PROGRESS_UPLOAD:
      return {
        ...state,
        uploadProgress: action.data
      };
    case actions.GET_VIDEO_QUESTIONS:
      return {
        ...state,
        videoQuestions: action.data
      };
    case actions.SUBMIT_VIDEO_SUCCESS:
      return {
        ...state,
        videoSubmitStatus: action.data
      };
    case actions.OPEN_CAMPAIGN_FINDER:    
      return {
        ...state,
        campaignFinder: action.data
      };
    case actions.SUCCESS_COVER_LETTER:
      return {
        ...state,
        uploadCoverLetter : action.data
      };
    case actions.PROGRESS_UPLOAD_CVL:
      return{
        ...state,
        cvlProgress : action.data
      };
    case actions.SUCCESS_RESET_STATE:
      return {
        ...state,
        uploadCoverLetter : action.data
      }
    case actions.ON_INITIAL_CONVERSATION:
      return{
        ...state,
        initialStartCnvsation : action.data
      }
    case actions.ON_WELCOME_BACK:
      return{
        ...state,
        welcomeback : action.data
      }
    case actions.ON_SUCCESS_INTERVIEW_TIMES:
      return{
        ...state,
        interviewTime : action.data
      }
    case actions.ON_CHAT_POP_UP_OPEN:
      return{
        ...state,
        chatPopUp : action.data
      }
    case actions.ON_SUCCESS_UNSCHEDULED_INTERVIEWS:
      return{
        ...state,
        unscheduled_campaigns : action.data
      }
    case actions.EXIT_VIDEO_PITCH: 
      return{
        ...state,
        exitVideoPitch : action.data
      }
    case actions.SAVE_APPLIED_DATA_TEMP:
      return{
        ...state,
        appliedCampaignTemp: action.data
      }
    case actions.CLOSE_CHAT_COMPONENT_RESPONSE:
      return{
        ...state,
        closeChatComponent: action.data
      }
    case actions.SUCCESS_SEND_SUPPORT:
      return{
        ...state,
        sendSupport: action.data
      }
    case actions.SUCCESS_EXIT_SUPPORT:
      return{
        ...state,
        exitSupport : action.data
      }
    case actions.ON_SUCCESS_VIDEO_STATUS:
      return{
        ...state,
        videoPitchStatus : action.data
      }
    case actions.SUCCESS_VIDEO_RECORD:   
      return{
        ...state,
        videoRecord : action.data
      }
    case actions.EXIT_FAQ_SUCCESS:
      return{
        ...state,
        exitFAQ : action.data
      }
    case actions.SUCCESS_SUBMIT_FQA_SUCCESS:
      return{
        ...state,
        submitFAQ: action.data
      }
    case actions.GET_FILTER_DATA_SUCCESS:
        return{
          ...state,
          filterCampaigns: action.data
      } 
    case actions.GET_TIME_DATA_USER_LOGGED:
      return{
        ...state,
        loggedTime: action.data
      }   
    case actions.GET_AVAIALBLE_CAMPAIGNS:
      return{
        ...state,
        available_campaign: action.data
      }  
    case actions.SUCCESS_UPDATE_ROOM_DATA:
        return{
          ...state,
          roomData: action.data
        }  
    case actions.SET_CHAT_OPEN:
      return{
        ...state,
        chatOpen: action.data
      }
    default:
      return state;
  }
};
export default reducer;

const mergeArray = array => {
  const history = array.map(res => {
    return [
      prototypeResponceMessage(
        res.query,
        "query",
        //res.humanized_time,
        res.time,
        res.query_visibility,
        false,
        false
      ),
      prototypeResponceMessage(
        res.response,
        "response",
        //res.humanized_time,
        res.time,
        true,
        false,
        false
      )
    ];
  });
  return [].concat.apply([], history);
};
