import React, { Component } from "react";
import { connect } from "react-redux";
import placeholder from ".././../../../assets/image/man.svg";
import { Loader } from "semantic-ui-react";
import classes from "./Element.module.scss";
import moment from "moment";

class Element extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeStamp: (
        <div className="ui-small-loader-wrapper">
          <Loader size="tiny" active />
        </div>
      ),
    };
  }
  componentDidMount() {
    
  }

  render() {   
    const { userImage } = this.props.data;

    var utcStartTime = moment.utc(this.props.data.startTime).toDate();
    var localStartTime = moment(utcStartTime).local().format("HH:mm");

    return (
      <>
        {this.props.data.startTime != null ?
          <li className={classes.listItem}>
            <div className={classes.left}>
              <img src={userImage ? userImage : placeholder} />{" "}
              {this.props.data.fullName}
            </div>
            <div className={classes.right}>{localStartTime}</div>
          </li>
          : null}
      </>
    );
  }
}

export default Element;
