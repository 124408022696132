import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Preferences.module.scss";

class question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answerText: ""
        }
    }
    componentDidMount() {
        this.props.question.answer = "";
    }
    anserQuestion = (e) => {
        this.setState({ answerText: e.target.value })
        this.props.question.answer = e.target.value;
        // var data = {
        //     id: 1,
        //     answer: "abc"
        // }   
    }
    render() {
        return (
            <div className={classes.questionSet}>
                <div>
                    <label className={classes.questionSetLabel}>
                        {this.props.question.question}
                    </label>

                </div>
                <div>
                    <input onChange={this.anserQuestion} type="text" name="answerText" className={classes.registerInputs} value={this.state.answerText}></input>
    
                </div>
                {/* <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                        <label class="form-check-label" for="defaultCheck1">
                            Default checkbox
                        </label>
                    </div> */}
            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        intwData: state.CandidateData.interviewData,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(question);