import React, { Component, useCallback } from "react";
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";
import classNames from "./VideoPitch.module.scss";

class ViewVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    var userId = this.props.profile.id;
    let campaignId = localStorage.getItem("Campaign_ID");
    this.props.onVideoPitch(userId, campaignId);
  };

  render() {
    var videoPitch = "";

    try {
      videoPitch = this.props.videopitch.Data.upload;
    } catch {
      videoPitch = "";
    }
    console.log(videoPitch, "abc");

    return (
      <video
        className={classNames.vpitchvideo}
        width="160"
        height="auto"
        controls
        src={videoPitch}
      ></video>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    videopitch: state.CandidateData.videoPitch,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onVideoPitch: (data, id) => dispatch(actions.getVideoPitch(data, id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewVideo);
