import React, { Component } from "react";
import classes from "../CampaignShare/CampaignShare.module.scss";
import "react-sweet-progress/lib/style.css";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Axios from "axios";
import { Route, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsCreator from "../../store/actions/index";
import axios from "../../axios";
import Swal from "sweetalert2";
import { Redirect } from "react-router";
import Spinner from "../UI/Spinner/Spinner";
import defaultCompany from "../../assets/image/sia-in-box.png";
//import FlowControl from "../../components/CandidateDashboard/NearbyCampaigns/Controller/FlowControl/FlowControl";
//import FlowControl from "../CandidateDashboard/NearbyCampaigns/Controller/FlowControlByStaffAddDashBoard/FlowControlByStaffAddDashboard";
import FlowControl from "../CandidateDashboard/NearbyCampaigns/Controller/FlowControlByStaffAddDashBoard/FlowControlByStaffAddDashboard";
import moment from "moment";
import { Modal } from 'semantic-ui-react'
import defaultCamImage from "../../assets/image/sia_default.png";
//import Splash from "./components/UI/Splash/Splash";
import Splash from '../../components/UI/Splash/Splash';
import logo from '../../assets/image/sia-logo.png';
import classesSplash from '../../components/UI/Splash/Splash.module.scss'
//../../../assets/image/sia-logo.png

class CampaignShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: "",
      startTime: "",
      endTime: "",
      applied: "",
      timeElapsed: "",
      availableTimes: "",
      URL: process.env.REACT_APP_API_URL + "chat",
      chatToken: localStorage.getItem("chat-token"),
      isApplied: false,
      applyLoding: false,
      isZoom:false,
      isApplyClicked: 0,
      isApiCall : false,
      getCampaignDataAPI : false,
      campaignDataByStaff : null 
    };
    this.timeSlots = this.timeSlots.bind(this);
  }

  componentDidMount = () => {

    //this.campaignData();
    //this.timeSlots()
    // this.getCampaignData();
    //http://localhost:3001/?loginRef=89zqOFwUQt&loginStatus=True

    setInterval(() => this.setSkillSet(), 1000)
    setInterval(() => this.getCampaignData(), 1000)

    var id = localStorage.getItem("CandidateProfile_id02");
    //this.props.getAvailableCampaignType(id);
    //localStorage.removeItem("OPENCHATWINDOW");
    //localStorage.setItem("REFRESHSTATUS", false);
    //this.props.onGetCampaignByRef(localStorage.getItem("shareRef"))
  };

  getCampaignData =()=>{
    var refCamp = localStorage.getItem("ADDEDSTAFFREF")
    if (this.state.getCampaignDataAPI === false){
        axios.get(process.env.REACT_APP_API_URL + 'chat/get-campaign-data-by-staff-add/?campInviteRef='+refCamp,{
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res=>{
            console.log(res,"campaign data by staff")
            this.setState({
                getCampaignDataAPI:true,
                campaignDataByStaff: res.data
            })
        })
    }
    else{

    }
  }

  setSkillSet =()=>{

    console.log("set skill set share ref")

    var userId = localStorage.getItem("CandidateProfile_id02")
    var shareRef = localStorage.getItem("shareRef")

    console.log(userId, "userId")
    console.log(shareRef, "shareRef")

    console.log(this.props.auth,"Auth props")

    var token = localStorage.getItem("chat-token")

    if (this.props.auth === true){

      if (this.state.isApiCall === false){

        axios.get(process.env.REACT_APP_API_URL + "chat/share-apply-click/?userId="+userId+ "&shareRef="+shareRef,{
          headers: {
            Authorization: `token ${token}`,
            "Content-Type": "application/json",
          }
        }).then(res=>{
          console.log(res.data.Data.instance.isZero, "isZero")
          var result = res.data.Data.instance.isZero
          this.setState({
            isApiCall : true
          })
          if (result === false){
            console.log(result, "Result abc get click") 
            this.setState({
              isApplyClicked : res.data.Data.instance.data[0].isApplyClicked
            })
          }
          else if(result === true){
            this.setState({
              isApplyClicked : 2
            })
          }
        })
      }
    }

    else{
      this.setState({
        isApplyClicked : 2
      })
    }
  }

  timeSlots = () => {
    Axios.get({}).then((res) => {
      this.setState({
        availableTimes: res.data,
      });
    });
  };

  formatDate(string) {
    var options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  // getDaysCount(startDate, endDate) {
  //   var day1 = startDate;
  //   var day2 = endDate;
  //   var date1 = new Date(day1);
  //   var date2 = new Date(day2);
  //   // To calculate the time difference of two dates
  //   var Difference_In_Time = date2.getTime() - date1.getTime();
  //   // To calculate the no. of days between two dates
  //   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  //   return Difference_In_Days;
  // }

  getDaysCount(startDate, endDate) {  
      var day1 = startDate;
      var day2 = endDate;
      var date1 = new Date(day1);
      var date2 = new Date(day2);
      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();
      // To calculate the no. of days between two dates
      var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24)) + 1;
      var text=""
      if(Difference_In_Days > 1){
         text = Difference_In_Days +" Days"
      }else{
        text = Difference_In_Days +" Day"
      }
      return text;  
  }

  closeCampaign = () => {
    console.log("is apply loading false")
    this.props.onSetCloseModelValue(true)
    this.setState({ isOpen: false,applyLoding:false });
    this.props.onClose()

  };
  goDashoard=()=>{
    this.setState({ isApplied: true});
   // sessionStorage.removeItem("shareRef");
    localStorage.removeItem("shareRef");
    this.props.openChat()  
  }

  onButtonClick = () => {
    //localStorage.setItem("APPLYSHARECLICKED", "true")
    sessionStorage.setItem("PROCEEDCLICKED", "true")
    this.props.onSetCloseModelValue(false)

    if (this.props.auth == true) {
      this.setState({ applyLoding: true });
    }else{
      this.setState({isApplied:true})
    }
  };

  zoomImage=()=>{
    this.setState({isZoom:true})   
  }
  colseImage=()=>{
    this.setState({isZoom:false})  
  }

  render() {

    // ALREADY_APPLIED

    console.log("campaign share internal com")

    console.log(this.props.alreadyApplied, "campaign share")
    console.log(this.props.isModalClose, "MOdal close")
    console.log(this.state.applyLoding, "Apply loading")
    console.log(this.props.campaignData, "campaign data")
    console.log(this.props.showLoader, "Apply final step data")

    console.log(this.state.isApplyClicked, "Is applyclicked")

    if (this.props.alreadyApplied === true || this.props.applySuccess === true){
      console.log("already aplied true")
      localStorage.removeItem("APPLYSHARECLICKED");
      localStorage.removeItem("SHOWLOADER");
      window.location.replace("/")
    }

    console.log(this.props.campaignData, "campaign by apply via share")
    console.log(this.props.compaign, "campaign data")

    // const { isApplied } = this.state;
    // if (isApplied === true) {
    //   return <Redirect to={"/"} />;
    // }

    var campaignImage = "";
    var companyLogo = "";
    var heading = "";
    var startDate = "";
    var shareUrlFb = "";
    var shareUrlLinklind = "";
    var shareUrltiwiter = "";
    var description = "";
    var endDate = "";
    var companyName = "";
    var shareEditName=""
    var shareEditDescription=""
    var campaignInstructions=null
    var shareImage=""
    var ispublish=true
    var isDimentions = false
    var croppedImage = null
    var campaignshareImage = ""
    var cvUploaded = false
    var cvURl = ""

    if (this.state.campaignDataByStaff != null) {
      campaignImage = this.state.campaignDataByStaff.Data[0].Campaign.campaignImage;
      ispublish = this.state.campaignDataByStaff.Data[0].Campaign.isPublish;
      companyLogo = this.state.campaignDataByStaff.Data[0].Company.logo;
      heading = this.state.campaignDataByStaff.Data[0].Campaign.heading;
      startDate = this.state.campaignDataByStaff.Data[0].Campaign.startDate;
      endDate = this.state.campaignDataByStaff.Data[0].Campaign.endDate;
      shareUrlFb = this.state.campaignDataByStaff.Data[0].ShareCampaignUrl[0]
        .campaignShareUrl;
      shareUrlLinklind = this.state.campaignDataByStaff.Data[0].ShareCampaignUrl[1]
        .campaignShareUrl;
      shareUrltiwiter = this.state.campaignDataByStaff.Data[0].ShareCampaignUrl[2]
        .campaignShareUrl;
      description = this.state.campaignDataByStaff.Data[0].Campaign.description;
      companyName = this.state.campaignDataByStaff.Data[0].Company.CompanyName;
      shareEditName= this.state.campaignDataByStaff.Data[0].ShareEdit[0].campaign_heading
      shareEditDescription = this.state.campaignDataByStaff.Data[0].ShareEdit[0].campaign_Description
      campaignInstructions = this.state.campaignDataByStaff.Data[0].ShareEdit[0].campaignInstructions
      shareImage = this.state.campaignDataByStaff.Data[0].ShareEdit[0].campaignImage
      campaignshareImage = this.state.campaignDataByStaff.Data[0].campaignshareImage
      // if (this.props.campaignData.Data[0].isDimensions === true){
      //   croppedImage = this.props.campaignData.Data[0].dimensionValues.cropedImage 
      // }
      cvUploaded = this.state.campaignDataByStaff.Data[0].cvUploaded
      cvURl = this.state.campaignDataByStaff.Data[0].cvUrl

      console.log(this.state.campaignDataByStaff.Data[0].cvUrl , "Campaign data######## ")
    }

    console.log(this.state.applyLoding , "is applting #####")
    console.log(localStorage.getItem("APPLYSHARECLICKED"), "apply share clicked#########")
    
    var showDiv= null

    if (this.state.campaignDataByStaff != null){

        showDiv = 
        <>
            <FlowControl
                closeCampaign={this.closeCampaign}
                campaign={this.state.campaignDataByStaff.Data[0].Campaign}
                compaigns={this.state.campaignDataByStaff.Data}
                index={0}
                share={true}
                cvUploaded={this.state.campaignDataByStaff.Data[0].cvUploaded}
                cvURl={this.state.campaignDataByStaff.Data[0].cvUrl}
            ></FlowControl>
        </>
    }

    return (

      <div className={classes.outerWrapper}>
        {showDiv}
    </div>  

    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    campaignData: state.CampaignByRef.campaignData,
    candidateDate: state.CandidateData.userData,
    compaign: state.CampaignByRef.availableCampaign,
    alreadyApplied : state.CandidateData.alreadyApplied,
    isModalClose: state.profile.isModalClose,
    applySuccess: state.CandidateData.applySuccess,
    showLoader: state.CandidateData.showLoader
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onApplyClick: (data) => dispatch(actionsCreator.applyByShare(data)),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
      getAvailableCampaignType: (data) =>
      dispatch(actionsCreator.getAvailableCampaignSlider(data)),
      onGetCampaignByRef: data => dispatch(actionsCreator.getCampaignByRef(data)),
      onSetCloseModelValue : (data)=>dispatch(actionsCreator.CloseModalValue(data))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignShare)
);
