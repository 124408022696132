import React, { Component } from "react";
import { Route, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./Campaign.module.scss";
import axios from "../../../../axios";
import moment from 'moment'
import swal from "sweetalert2";
import * as actionsCreator from "../../../../store/actions/index";
import Spinner from "../../../UI/Spinner/Spinner";

class SchduleTime extends Component {
    constructor(props){
        super(props)
        this.state={
            schduleTime:[],
            isSchdule:false,
            isclickTime:false,
            timeColor:''
        }
    }

 updataTime=()=>{   
  this.setState({isSchdule:true})
  var date=this.props.data.start_time
  var ref =this.props.refData
  var reminder = 30
  var token = localStorage.getItem("chat-token");
  axios
  .get(process.env.REACT_APP_API_URL + "chat/update-interview-time/?ref="+ref+"&time="+date+"&reminder="+reminder, {
    headers: {
      Authorization: `token ${token}`,
      "Content-Type": "application/json",
    },
  })
  .then((res) => {  
    swal.fire({
      toast: true,
      position: "top-end",
      title: "Your interview schdule successfully",
      showConfirmButton: false,
      timer: 2500,
      customClass: { container: "swal-success-settings" },
      imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
      imageHeight: 20,
    });
    this.setState({isSchdule:false})
    this.props.closeModal()
    var currentTime = moment.utc().toISOString();
    this.props.onGetCandidateData(token, currentTime);
  })
  .catch((resCatch) => {
  });
 }
 selectTime=()=>{     
  this.setState({timeColor:'red'})
  this.props.selectTime(this.props.data.start_time)
 }
  render() {

    return (
      <div>    
          <div>
            <button style={{background:this.state.timeColor}} onClick={this.selectTime}>
              {this.state.isSchdule ? <Spinner /> :
                moment(this.props.data.start_time).format('hh:mm A')
              }
            </button>
          </div>  
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {  

  };
};
const mapDispatchToProps = (dispatch) => {
  return {  
    onGetCandidateData: (data, currentTime) =>
    dispatch(actionsCreator.getCandidateData(data, currentTime)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SchduleTime)
);

