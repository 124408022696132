import React, { Component } from "react";
import Video from "twilio-video";
import axios from "../../axios";
import classes from "./VideoChat.module.scss";
import "./videoChat.scss";
import ChatQueue from "../../components/ChatQueue/ChatQueue";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { Card, CardHeader, CardText } from "material-ui/Card";
import { identity } from "react-smooth/lib/util";
import Actions from "../../../src/store/actions/Auth";
import Comment from "../../components/InterviewerComment/Index";
import {
  faVideo,
  faVideoSlash,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountDown from "../VideoChat/CountDownTimer/Index/Count";
import { connect } from "react-redux";
import ObjectChecker from "../../hoc/objectChecker";
import * as actionCreater from "../../store/actions/index";
import Timer from "../../components/Webinnar/CloclTime/Time";
import Spinner from "../../components/UI/Loading/Spinner/Spinner";
import swal from "sweetalert2";
import moment from "moment";
import CountdownTimer from "react-component-countdown-timer";
import WifiIndicator from 'react-wifi-indicator'; 
import Swal from "sweetalert2"; 

class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.activeRoom = "";
    this.previewTracks = "";
    this.identity = "";
    this.roomName = "chatRoom";
    // this.roomJoined = this.roomJoined.bind(this);
    // this.detachTracks = this.detachTracks.bind(this);
    // this.getDomSpeaker = this.getDomSpeaker.bind(this);
    // this.detachParticipantTracks = this.detachParticipantTracks.bind(this);
    this.state = {
      twilio_token: null,
      callStarted: false,
      callStarting: false,
      videoEnabled: true,
      audioEnabled: true,
      URL: process.env.REACT_APP_API_URL + "manager",
      buttondisable: true,
      dominentSpeaker_id: "",
      participents_chanel: [],
      participentTrack: [],
      trackDOM: "",
      trackNew: "",
      tracksPart: "",
      idPart: [],
      domSID: "",
      chanelTracks: [],
      partTracks: [],
      partIds: [],
      comments: [],
      domienentParticipents: [],
      interviewData: this.props.intData,
      updateRequestSend: false,
      joinMember: false,
      waitStatusSent: false,
      networkQualityLevel:"OKAY",
      qualityMessage:"Average network"
    };
  }

  room = null;
  currentSpeaker = null;
  identity = "";

  componentDidMount() {
       this.setToken()
  }
   
  setToken = () => {
    console.log(this.props.intData, "Applied id set token");
    window.addEventListener("beforeunload", this.leaveRoomIfJoined);
    const token1 = localStorage.getItem("chat-token");

    // if (this.state.waitStatusSent === false) {
    //   this.props.updateCandidateInterviewState(this.props.intData, 9)
    //   this.setState({
    //     waitStatusSent: true
    //   })
    // }
    var data = {
      interview_id: parseInt(this.props.intData)
    }
    axios
      .post(this.state.URL + "/calls/", data,
        {
          headers: {
            Authorization: `Token ${token1}`,
          },
        }
      )
      .then((res) => {
        this.identity = res.data.id;
        this.setState({
          twilio_token: res.data.twilio_token,
        });
      });
  };

  getInterviewData = () => {
    this.setState(
      {
        interviewData: this.props.intwData,
      },
      () => console.log(this.props.intwData, "Interview id")
    );
  };

  getRealTime = () => {
    setInterval(() => this.getCandidateComments(), 10000);
  };

  leaveRoomIfJoined() {
    if (this.room) {
      this.room.disconnect();
    }
  }
  toggleCall = () => {
    this.state.callStarted ? this.endCall() : this.startCall();
  };
  startCall() {
    var connectOptions = {
      dominantSpeaker: true,
      audio: true,  
      video: true,
      bandwidthProfile: {
        video: {
          mode: 'collaboration',
          maxTracks: 10,
          dominantSpeakerPriority: 'standard',
          trackSwitchOffMode: 'predicted',
          renderDimensions: {
            high: {height:1080, width:1920},
            standard: {height:720, width:1280},
            low: {height:176, width:144}
          }
        }
      },
      maxAudioBitrate: 16000,
      preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
      networkQuality: {local:1, remote: 1}
    };    
    this.setState({ callStarting: true });
    if (this.state.waitStatusSent === false) {
      this.props.updateCandidateInterviewState(this.props.intData, 9)
      this.setState({
        waitStatusSent: true
      })
    }
    Video.connect(this.state.twilio_token, connectOptions).then(
      this.roomJoined,
      (error) => {
        // this.log("Could not connect to Twilio: " + error.message);
        this.setState({
          callStarting: false,
        });
        if (error.message === "Permission denied") {
          // alert(error.message);
          swal.fire({
            position: "top-end",
            type: "warning",
            title: "Error: Camera/Mic permission denied",
            text: "Please re-check your browser settings",
            showConfirmButton: false,
            timer: 7000,
            customClass: { container: "swal-container-flow-warnings" },
            imageHeight: 20,
          });
        }
      }
    );
  }
  endCall() {
    this.room.disconnect();
    localStorage.setItem("CALL_STATUS", false);
  //  localStorage.setItem("Video_chat_complete", true);
  //  localStorage.removeItem("Open_video_conf_sia");
    //sessionStorage.removeItem("ref");
    //window.location.reload(process.env.REACT_APP_URL);
  }
  toggleAudio = () => {
    this.room.localParticipant.audioTracks.forEach((publication) => {
      this.state.audioEnabled
        ? publication.track.disable()
        : publication.track.enable();
    });
    this.setState({
      audioEnabled: !this.state.audioEnabled,
    });
  };
  toggleVideo = () => {
    if (!this.state.videoEnabled) {
      Video.createLocalVideoTrack().then((track) => {
        const localMediaContainer = document.querySelector(
          `#participant-${this.room.localParticipant.identity}`
        );
        localMediaContainer.appendChild(track.attach());
        return this.room.localParticipant.publishTrack(track);
      });
    } else {
      const publication = [
        ...this.room.localParticipant.videoTracks.values(),
      ][0];
      const track = publication.track;
      track.stop();
      track.detach().forEach((element) => element.remove());
      this.room.localParticipant.unpublishTrack(track);
    }
    this.setState({
      videoEnabled: !this.state.videoEnabled,
    });
  };
  attach(track, container) {
    container.appendChild(track.attach());
  }
  detach(track) {
    if (!track) return;
    track.detach().forEach((element) => {
      // element.parentNode.remove();
      element.remove();
    });
  }
  detachParticipantTracks(participant) {
    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        this.detach(publication.videoTracks);
      }
    });
  }
  detachCurrentSpeakerTrackIfExists() {
    var el = this.refs.currentSpeaker.querySelector("video");
    if (this.currentSpeaker && this.getCurrentSpeakerTrack()) {
      this.getCurrentSpeakerTrack().detach(el).remove();
    }
  }
  getCurrentSpeakerTrack() {
    return this.currentSpeaker.videoTracks.values().next().value.track;
  }
  createElementForParticipant(participant, participantProfile = "") {
    const id = `participant-${participant.identity}`;
    console.log(id, "parId")
    var el = document.querySelector(`#${id}`);
    if (el) return el;
    el = this.refs.participantsPreview.appendChild(
      document.createElement("div")
    );
    el.id = id;
    el.classList.add("participant");

    participantProfile.status && el.classList.add("isCandidate");
    var titleEl = el.appendChild(document.createElement("div"));
    titleEl.classList.add("participant-name");
    titleEl.innerHTML = participantProfile
      ? participantProfile.Profile[0].first_name
      : "You";

    var blurEl = el.appendChild(document.createElement("div"));
    blurEl.classList.add("participant-blur");
    blurEl.innerHTML = participantProfile
      ? participantProfile.Profile[0].first_name
      : "You";

    if (id == "participant-" + localStorage.getItem('CandidateProfile_id02')) {
      el.classList.add("userVideos");
    } else {
      el.classList.add("participantVideos");
    }
    el.style.cssText =
      "position:relative;overflow:hidden; background-color:white;display:flex;margin: 0 .5rem;width: 120px;";
    return el;
  }

  roomJoined = (room) => {

    // var userID = localStorage.getItem('CandidateProfile_id')

    // var jsonData = {
    //   appliedId: parseInt(this.props.intData),
    //   interviewerId: 0,
    //   candidate: parseInt(userID)
    // }
    // this.props.onJoinCall(jsonData)

    console.log(room, "room")
    this.setState({
      callStarted: true,
      callStarting: false,
    });
    this.room = room;
    room.localParticipant.tracks.forEach((publication) => {
      this.attach(
        publication.track,
        this.createElementForParticipant(room.localParticipant)
      );
    });
    room.localParticipant.setNetworkQualityConfiguration({
      local: 2,
      remote: 1
    });
    room.localParticipant.on("networkQualityLevelChanged",this.printNetworkQualityStats)

    room.participants.forEach(this.participantConnected);
    room.on("participantConnected", this.participantConnected);
    room.on("dominantSpeakerChanged", this.dominantSpeakerChanged);
    room.on("participantDisconnected", this.participantDisconnected);
    room.on("disconnected", this.disconnected);
  };

  printNetworkQualityStats=(level)=>{   
    console.log(level,"NetworkQualityStats")
    if (level == 0) {
      this.warning()
      this.setState({ NetworkQualityLevel: "DISCONNECTED",qualityMessage:"Network broken (reconnecting)" })
    }
      else if (level == 1) {
        this.warning()
      this.setState({ networkQualityLevel: "UNUSABLE",qualityMessage:"Very bad network" })
    } else if (level == 2) {
      this.warning()
      this.setState({ networkQualityLevel: "WEAK", qualityMessage:"Bad network" })
    } else if (level == 3) {
      this.setState({ networkQualityLevel: "OKAY",qualityMessage:"Average network"})
    } else if (level == 4) {
      this.setState({ networkQualityLevel: "GREAT",qualityMessage:"Good network" })
    } else if (level == 5) { 
      this.setState({ networkQualityLevel: "EXCELLENT",qualityMessage:"Very good network" })
    }  
  }

  warning=()=>{
    Swal.fire({
      toast: true,
      position: 'top-start',
      title: "Unstable network detected",
      showConfirmButton: false,
      timer: 2500,
      customClass: { container: "swal-success-settings" },
      imageUrl: "https://image.flaticon.com/icons/svg/1672/1672451.svg",
      imageHeight: 20,
    });
  }

  updateInterviewStatus = ()=>{
    if (this.state.updateRequestSend === false) {
      this.props.updateCandidateInterviewState(this.props.intData, 5)
      this.setState({
        updateRequestSend: true
      })
    }
  }

  participantConnected = async (participant) => {
    console.log("participant connected", participant.identity);
    var participantProfile = "";
    const token = localStorage.getItem("chat-token");
    let callAPI = await axios
      .get(
        "get-video-conference-participents/?userId=" + participant.identity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      )
      .then((res) => {
        participantProfile = res.data.Data;
      })
      .catch((err) => {
        console.log("get participant data ERROR");
      });
    const el = this.createElementForParticipant(participant, participantProfile);
    this.setState({ joinMember: true })

    this.updateInterviewStatus()
    this.roomConnectionStatus()

    participant.on("trackSubscribed", (track) => {
      this.trackSubscribed(track, el); 
    });
    participant.on("trackUnsubscribed", this.trackUnsubscribed);
    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        this.attach(publication.track, el);
      }
    });
  };

  roomConnectionStatus = () => {  
    const token = localStorage.getItem("chat-token");
    var data = {
      interviewId:this.props.interviewData.interviewStepId,
      candidateId: parseInt(localStorage.getItem("CandidateProfile_id02")),
      staffId: 0,
      reference: this.props.interviewData.ref,
      connectStatus: true,
      roomId: parseInt(this.props.intData)
    }
    axios
      .post(
        "interview-connection-status/", data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      )
      .then((res) => {     
      })
      .catch((err) => {        
      });
  }
  setdominantSpeakerVideo = (participant, track) => {
    // video on/off chenge dominantSpeaker
    if (this.currentSpeaker != null) {
      this.currentSpeaker = participant;
      this.attach(track, this.refs.currentSpeaker);
    }
  }
  trackSubscribed = (track, container) => {
    this.attach(track, container);
  };
  trackUnsubscribed = (track) => {
    this.detach(track);
  };
  dominantSpeakerChanged = (participant) => {
    console.log(participant, "domini")
    if (!participant || participant.videoTracks.size < 1) return;
    this.detachCurrentSpeakerTrackIfExists();
    this.currentSpeaker = participant;
    this.attach(this.getCurrentSpeakerTrack(), this.refs.currentSpeaker);
  };
  participantDisconnected = (participant) => {
    this.detachParticipantTracks(participant);
  };
  disconnected = () => {
    this.room.participants.forEach((participant) => {
      this.detachParticipantTracks(participant);
    });
    this.detachCurrentSpeakerTrackIfExists();
    this.room.localParticipant.tracks.forEach((publication) => {
      this.detach(publication.track);
    });
    this.room = null;
    this.setState({
      videoEnabled: true,
      audioEnabled: true,
      callStarted: false,
    });
    this.currentSpeaker = null;
  };

  closeTab = () => {
    window.location.replace("/")
  };

  backToStall=()=>{
    console.log("back to stall")
    window.location.replace("/careereventrealtimecheck")
  }

  render() {
    console.log(this.props.intData, "Applied id");
    console.log(this.props.intwData, "Interview data props");
    var panelDuration = this.props.interviewData.PanelDuration;
    console.log(panelDuration, "Panel duration");

    var statusInw = 1

    console.log(this.props.inwStatus, "Interview status")
    
    if (this.props.intwData !== null) {
      statusInw = this.props.interviewData.InterviewData.status
    }

    else if (this.props.intwData === null) {
      statusInw = 1
    }

    // try{
    //   statusInw = this.props.inwStatus.status
    // }
    // catch{
    //   statusInw = 1
    // }

    var intwStatus = true

    if (parseInt(statusInw) >= 2) {
      intwStatus = false
    }

    var DISABLED = true;

    if (this.state.twilio_token !== null) {
      DISABLED = false;
    }
    var minutes = Math.floor(panelDuration / 60000);
    var currentTime = moment();
    var localTimeEnd = moment.utc(this.props.interviewData.InterviewData.startTime).toDate();
    localTimeEnd = moment(localTimeEnd).add(minutes, "minutes")
    var differnt = localTimeEnd.diff(currentTime, 'seconds')

    let content;
    if (this.state.callStarted) {
      content = (
        <div className={classes.flexContainer}> 
            {/* <div className={classes.closeBtnAlign}> <button onClick={this.closeTab}  className={classes.closebtnnew} >X</button> </div> */}
          <div className={classes.flexwrapper1} ref="participantsPreview" />
          {this.state.joinMember == false ?
            <div className={classes.message}>
              <span></span> Please wait...The recruiters will join shortly
           </div>
            : null
          }
          <div className={classes.flexwrapper2} ref="currentSpeaker"></div>
          <div className={classes.flexwrapper3}>
            {this.state.callStarted && (
              <div className={classes.chatIntwTimer}>
                 <div style={{marginRight:"10px"}}>
                  <WifiIndicator title={this.state.qualityMessage} strength={this.state.networkQualityLevel}
                    alt={this.state.qualityMessage}
                    style={{
                      height: 28,
                      width:26
                    }} />
                </div>
                <CountdownTimer
                  count={differnt}
                  border
                  backgroundColor="#00000000"
                  color="unset"
                  hideDay={true}
                ></CountdownTimer>
              </div>
            )}
            {this.state.callStarted && (
              <>
              <div className={classes.callButtons}>
                {this.state.callStarting ? (
                  <button
                    className={classes.BlueBtn1}
                    onClick={this.toggleCall}
                  >
                    Connecting...
                  </button>
                ) : this.state.callStarted ? (
                  <button
                    className={classes.BlueBtnCut}
                    onClick={this.toggleCall}
                   // disabled={intwStatus}
                  >
                    <i className="fas fa-phone-slash"
                      title="Disconnect" />
                  </button>
                ) : this.props.intwData === null ? (
                  <button
                    className={classes.BlueBtn1}
                    onClick={this.toggleCall}
                  >
                    waiting
                  </button>
                ) : this.props.intwData !== null ? (
                  <button
                    className={classes.BlueBtn1}
                    onClick={this.toggleCall}
                  >
                    Start Call
                  </button>
                ) : null}
                <button
                  className={classes.audioBtnon}
                  onClick={this.toggleAudio}
                >
                  {this.state.audioEnabled ? (
                    <i className="fas fa-microphone" title="Mute yourself"></i>
                  ) : (
                      <i className="fas fa-microphone-slash" title="Unmute yourself"></i>
                    )}
                </button>

                <button
                  className={classes.videoBtnon}
                  onClick={this.toggleVideo}
                >
                  {this.state.videoEnabled ? (
                    <FontAwesomeIcon icon={faVideo} title="Turn off video" />
                  ) : (
                      <FontAwesomeIcon icon={faVideoSlash} title="Turn on video" />
                    )}
                </button>            
              </div>         
                </>
            )}
            {this.state.callStarted}
          </div>
        </div>
      );
    } else {
      content = (
        <div className={classes.flexContainer1}>
                   {/* <div className={classes.closeBtnAlign}> <button onClick={this.closeTab} className={classes.closebtnnew} >X</button> </div> */}
          {/* {this.props.callStatus.Data.status == true ? */}
            <button
              className={classes.StartCallButton}
              onClick={this.toggleCall}
              disabled={DISABLED}
            >
              {this.state.callStarting ? (
                "Connecting"
              ) : this.state.twilio_token === null ? (
                <Spinner />
              ) : (
                    "Start Video Call"
                  )}
            </button>:
             {/* <button
               className={classes.StartCallMessage}       
                 >Please wait...The recruiters will join shortly
                <Spinner />
             </button>
          } */}
          {
            differnt <= 0?
            <button
              className={classes.StartCallButton}
              onClick={this.backToStall}
              disabled={false}
            >
            Back to event
            </button>:null
          }
          <div style={{ marginTop: "10px", backgroundColor: "white" }}></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        {content}   
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    intwData: state.CandidateData.interviewData,
    tokenData: state.CandidateData.twillioToken,
    inwStatus: state.chatnew.intwStatus,
    recruiterStatus: state.auth.recruiterState,
    callStatus: state.CandidateData.callStatus
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCandidateInterviewState: (data, inwStatus) => dispatch(actionCreater.updateInterviewState(data, inwStatus)),
    onJoinCall: (data) => dispatch(actionCreater.JoinCall(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoComponent);
