import React, { Component } from "react";
import Profile from "../../components/CandidateDashboard/Profile/Profile";
import Progress from "../../components/CandidateDashboard/Progress/Progress";
import Campaigns from "../../components/CandidateDashboard/CampaignData/CampaignData";
import FeedBack from "../../components/CandidateDashboard/FeedBack/FeedBack";
import NearbyCampaigns from "../../components/CandidateDashboard/NearbyCampaigns/NearbyCampaigns";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Animate from "react-smooth";
import * as actionsCreator from "../../store/actions/index";
import classes from "./Dashboard.module.scss";
import "./Dashboard.scss";
import CVPanel from "../../components/OnBoard/CvUpload";
import ChatModal from "../../components/UI/ChatModal/ChatModal";
import { Modal } from "semantic-ui-react";
import moment from "moment";
import axios from "../../axios";

import frontDesk from "../../assets/image/FrontDesk.png"
//import frontDesk from "https://csuite-hr-bucket.s3.ap-south-1.amazonaws.com/FrontDesk.png"

import lobby from "../../assets/image/Lobby.png"
//import lobby from "https://csuite-hr-bucket.s3.ap-south-1.amazonaws.com/Lobby.jpg"

import auditorium from "../../assets/image/Stall.jpg"

import stallsImage from "../../assets/image/Stall.jpg"
//import stallsImage from "../../assets/image/Stall.jpg"

import Attendees from "../../components/Event/Attendees/Attendees";
import Stalls from "../../components/Event/Stalls/Stalls";
import Lobby from "../../components/Event/Lobby/lobby";
import Auditoriums from "../../components/Event/Auditorium/Auditorium";
import { Dropdown, Button } from 'semantic-ui-react';
import Stall from '../../components/Event/Stalls/Stall/Stall';
import IndvStall from '../../components/Event/Stalls/IndvStall/Stall';
import Regiester from '../../components/Event/Register/Register';
import Exhibition from "../../components/Event/ExhibitionHall/Exhibition"
import About from "../../components/Event/About/About"
import Logo from "../../assets/image/ufairs.svg";
import JoinEvent from "../../assets/image/Icons/JoinEvent.svg";
import AboutI from "../../assets/image/Icons/About.svg";
import Agenda from "../../assets/image/Icons/Agenda.svg";
import Apply from "../../assets/image/Icons/Apply.svg";
import LobbyI from "../../assets/image/Icons/Lobby.svg";
// import TestBg from "../../assets/image/test-bg.png";
import TestBg from "../../assets/image/transparent.png";
import EHall from "../../assets/image/Icons/EHall.svg";
import Auditorium from "../../assets/image/Icons/Auditorium.svg";
import Companies from "../../assets/image/Icons/Companies.svg";
import AttendeesI from "../../assets/image/Icons/Attendees.svg";
import Feed from "../../assets/image/Icons/Feed.svg"
import Swal from 'sweetalert2'

import front_desk from "../../assets/image/dynmc/front_desk.png"
import Pusher from 'pusher-js';


class Dashboard extends Component {
  constructor(props) {
    super();
    this.state = {
      token: localStorage.getItem("chat-token"),
      profile: null,
      campaigns: null,
      progress: null,
      feedback: null,
      cvStatus: "",
      innerWidth: window.innerWidth,
      //backgroundImage: frontDesk,
      frontDestImage:"https://csuite-hr-bucket.s3.ap-south-1.amazonaws.com/FrontDesk.png",
      lobbyImage: "https://csuite-hr-bucket.s3.ap-south-1.amazonaws.com/Lobby.jpg",
      stallImage: "https://csuite-hr-bucket.s3.ap-south-1.amazonaws.com/Stall.jpg",
      backgroundImage: "https://csuite-hr-bucket.s3.ap-south-1.amazonaws.com/FrontDesk.png",
      isGoApply: false,
      isGoAbout: false,
      isGoAttendees: false,
      isGoAgend: false,
      agendaPdf: "",
      registeredStatus: true,
      exhibitionHalls: null,
      isGoCompanies: false,
      stalls: null,
      eventData: null,
      isGoAuditorium: false,
      isGoStall: false,
      isShowStall: false,
      isGoExbHall: false,
      isGoLobby: false,
      exbHallId: 0,
      stallStatus: 0,
      isJoinEvent: false,
      registrationStatus: [],
      isStarted: false,
      message: null,
      eventFeed: "",
      eventImage: "",
      frontImage: true,
      eventDataSet: false,
      eventFeedStatus: false,
      isAboutClick:false,
      isAgendaClick:false,
    };

    this.getCandidateData = this.getCandidateData.bind(this);
    this.sendDataToComponents = this.sendDataToComponents.bind(this);

  }
  componentDidMount() {
    this.getCandidateData();
    window.addEventListener("resize", this.updateWidth);
    var curr_time = moment().toISOString()
    this.props.onGetAvailableCampaigns(localStorage.getItem('chat-token'), curr_time)
    this.getEventData()
    const ref = new URLSearchParams(this.props.location.search.substring(1)).get("ref");
    if (ref) {
      this.checkRefStatus(ref)
    }
    this.getCandidateApproveStatus();
  };

  getCandidateApproveStatus = () => {
    console.log("get approve status")
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    var channelName = process.env.REACT_APP_CHANEL_NAME + '_' + localStorage.getItem("eventId") + "_" + localStorage.getItem("CandidateProfile_id02") + "register"
    // webinarId.toString()
    console.log(channelName, "Channel name")
    var channel = pusher.subscribe(channelName);
    channel.bind('is-approve', (data) => {
      // this.state.webinarData = data.message
      if (data.message === true) {
        this.getEventData()
        //this.joinEvent()
      }
    });
  }

  getEventData = () => {
    var id = localStorage.getItem("eventId")
    var candidateId = localStorage.getItem("CandidateProfile_id02")
    var curr_time = moment().toISOString()
    axios.get("event/?eventId=" + id + "&inwRef=&candidateId=" + candidateId + "&logTime=" + curr_time, {
      headers: {
        "content-type": "application/json",
      },
    }).then((res) => {
      var data = res.data.Data.content
      this.state.eventData = data
      this.setState({
        registeredStatus: data.isRegistered,
        //agendaPdf: data.eventData.agenda+"#zoom=80",
        agendaPdf: data.eventData.agenda,
        exhibitionHalls: data.exhibitionHalls,
        stalls: data.eventStalls,
        registrationStatus: data.registeredStatus,
        isStarted: data.isStarted,
        eventFeed: data.eventData.eventFeeds,
        eventImage: data.campaignEvent.campaignImage,
        eventDataSet: true
      })
      localStorage.setItem("eventName", data.eventData.eventName)
    })
      .catch((err) => {

      });
  }

  getEventDataBtn=()=>{
    var id = localStorage.getItem("eventId")
    var candidateId = localStorage.getItem("CandidateProfile_id02")
    var curr_time = moment().toISOString()
    axios.get("event/?eventId=" + id + "&inwRef=&candidateId=" + candidateId + "&logTime=" + curr_time, {
      headers: {
        "content-type": "application/json",
      },
    }).then((res) => {
      var data = res.data.Data.content
      this.state.eventData = data
      this.setState({
        agendaPdf: data.eventData.agenda,
        exhibitionHalls: data.exhibitionHalls,
        stalls: data.eventStalls,
        isStarted: data.isStarted,
        eventFeed: data.eventData.eventFeeds,
        eventImage: data.campaignEvent.campaignImage,
        eventDataSet: true
      })
      localStorage.setItem("eventName", data.eventData.eventName)
    })
      .catch((err) => {

      });
  }

  updateWidth = () => {
    this.setState({
      innerWidth: window.innerWidth,
    });
  };

  getCandidateData = () => {
    var currentTime = moment.utc().toISOString();
    this.props.onGetCandidateData(this.state.token, currentTime);
  };

  profileTab = () => {
    this.props.changeTabProfile();
  };

  sendDataToComponents = () => {
    let profile = (
      <Profile
        profileTabChange={this.profileTab}
        data={this.props.candidateDate}
      />
    );
    let campaigns = <Campaigns data={this.props.candidateDate} />;
    let feedback = <FeedBack data={this.props.candidateDate} />;
    let progress = <Progress data={this.props.candidateDate} />;

    this.setState({
      profile: profile,
      campaigns: campaigns,
      progress: progress,
      feedback: feedback,
    });
  };

  goLobby = () => {
    this.getEventData()
    this.setState({ isAboutClick:false,isAgendaClick:false,isGoLobby: true, backgroundImage: this.state.lobbyImage, isGoExbHall: false, isGoApply: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isShowStall: false, isJoinEvent: false, frontImage: false, isGoAuditorium: false, eventFeedStatus: false })
  }
  goAuditorium = () => {
    this.getEventData()
    this.setState({ isAboutClick:false,isAgendaClick:false,backgroundImage: auditorium, isGoAuditorium: true, isGoApply: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoLobby: false, isGoCompanies: false, isShowStall: false, isGoExbHall: false, isJoinEvent: false, frontImage: false, eventFeedStatus: false })
  }
  goExhibition = () => {
    if (this.props.accessStall === false){
      this.getEventData()
      this.setState({ isAboutClick:false,isAgendaClick:false,isGoExbHall: true, isGoApply: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: false })
    }
    else if (this.props.accessStall === true){
      this.props.onBtnDisable(true)
      this.getEventData()
      this.setState({ isAboutClick:false,isAgendaClick:false,isGoExbHall: true, isGoApply: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: true })
    }
  }

  goApply = () => {

    var data = {
      userId : localStorage.getItem("CandidateProfile_id02")
    }

    axios.post('update-applied-campaigns/', data,{
      headers: {
        "content-type": "application/json",
      },
    }).then(res=>{
      if (this.props.accessStall === false){
        this.getEventData()
        this.setState({isAboutClick:false,isAgendaClick:false, isGoApply: true, isGoExbHall: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: false })
      }
      else if (this.props.accessStall === true){
        this.props.onBtnDisable(true)
        this.getEventData()
        this.setState({isAboutClick:false,isAgendaClick:false, isGoApply: true, isGoExbHall: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: true })
      } 
    }).catch(error=>{
      if (this.props.accessStall === false){
        this.getEventData()
        this.setState({isAboutClick:false,isAgendaClick:false, isGoApply: true, isGoExbHall: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: false })
      }
      else if (this.props.accessStall === true){
        this.props.onBtnDisable(true)
        this.getEventData()
        this.setState({isAboutClick:false,isAgendaClick:false, isGoApply: true, isGoExbHall: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: true })
      } 
    })

    // if (this.props.accessStall === false){
    //   this.getEventData()
    //   this.setState({isAboutClick:false,isAgendaClick:false, isGoApply: true, isGoExbHall: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: false })
    // }
    // else if (this.props.accessStall === true){
    //   this.props.onBtnDisable(true)
    //   this.getEventData()
    //   this.setState({isAboutClick:false,isAgendaClick:false, isGoApply: true, isGoExbHall: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: true })
    // } 

  }
  goAbout = () => {
    if (this.props.accessStall === false){
      this.getEventDataBtn()
      this.setState({isAboutClick:true, isGoAbout: true, isGoApply: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, isGoExbHall: false, eventFeedStatus: false, isShowStall: false })
    }
    else if (this.props.accessStall === true){
      this.props.onBtnDisable(true)
      this.getEventDataBtn()
      this.setState({isAboutClick:true, isGoAbout: true, isGoApply: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, isGoExbHall: false, eventFeedStatus: false, isShowStall: true })
    }
  }
  goAttendees = () => {
    if (this.props.accessStall === false){
      this.getEventData()
      this.setState({ isAboutClick:false,isAgendaClick:false, isGoAttendees: true, isGoApply: false, isGoExbHall: false, isGoExbHall: false, isGoAbout: false, isGoCompanies: false, isGoAgend: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: false })
    }
    else if (this.props.accessStall === true){
      this.props.onBtnDisable(true)
      this.getEventData()
      this.setState({ isAboutClick:false,isAgendaClick:false, isGoAttendees: true, isGoApply: false, isGoExbHall: false, isGoExbHall: false, isGoAbout: false, isGoCompanies: false, isGoAgend: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: true })
    } 
  }
  goAgend = () => {
    if (this.props.accessStall === false){
      this.getEventDataBtn()
      this.setState({isAgendaClick:true, isGoAgend: true, isGoExbHall: false, isGoAttendees: false, isGoApply: false, isGoAbout: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: false })
    }
    else if (this.props.accessStall === true){
      this.props.onBtnDisable(true)
      this.getEventDataBtn()
      this.setState({isAgendaClick:true, isGoAgend: true, isGoExbHall: false, isGoAttendees: false, isGoApply: false, isGoAbout: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: true })
    }
  }
  goCompanies = () => {
    if (this.props.accessStall === false){
      this.getEventData()
      this.setState({ isAboutClick:false,isAgendaClick:false, isGoAbout: false, isGoAgend: false, isGoApply: false, isGoAttendees: false, isGoAbout: false, isGoCompanies: true, isGoExbHall: false, joinEvent: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: false })
    }
    else if (this.props.accessStall === true){
      this.props.onBtnDisable(true)
      this.getEventData()
      this.setState({ isAboutClick:false,isAgendaClick:false, isGoAbout: false, isGoAgend: false, isGoApply: false, isGoAttendees: false, isGoAbout: false, isGoCompanies: true, isGoExbHall: false, joinEvent: false, isJoinEvent: false, eventFeedStatus: false, isShowStall: true })
    }
    
  }
  showStall = (id, status) => {
    console.log("showStall", id, status)
    this.setState({ exbHallId: id, stallStatus: status, backgroundImage: this.state.stallImage ,isAboutClick:false,isAgendaClick:false, isGoExbHall: false, isGoCompanies: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoLobby: false, isGoApply: false, isGoAbout: false, isGoAuditorium: false, isShowStall: true, joinEvent: false, frontImage: false, eventFeedStatus: false })
  }

  goEventFeed = () => {
    this.getEventData()
    if (this.state.eventFeed === "") {
      if (this.props.accessStall === false){
        this.setState({ isAboutClick:false,isAgendaClick:false, isGoExbHall: false, isGoApply: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: true, isShowStall: false })
      }
      else if(this.props.accessStall === true){
        this.props.onBtnDisable(true)
        this.setState({ isAboutClick:false,isAgendaClick:false, isGoExbHall: false, isGoApply: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus: true, isShowStall: true })
      }
    }
    else {
      window.open(this.state.eventFeed, "__blank")
    }
    //this.setState({isGoExbHall: false, isGoApply: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoCompanies: false, isJoinEvent: false, eventFeedStatus:true})
  }

  goEvent = (lobbyStatus, frontDeskStaus) => {
    this.getEventData()
    var backgroundImage = this.state.frontDestImage
    if (lobbyStatus == true) {
      backgroundImage = this.state.lobbyImage
      this.setState({ frontImage: false })
    }
    this.setState({ isAboutClick:false,isAgendaClick:false, backgroundImage: backgroundImage, isGoExbHall: false, isGoCompanies: false, isGoAbout: false, isGoAgend: false, isGoAttendees: false, isGoApply: false, isGoAbout: false, isGoAuditorium: false, isShowStall: false, isGoLobby: lobbyStatus, isJoinEvent: frontDeskStaus })
  }
  joinEvent = () => {
    this.getEventData()
    var lobbyStatus = false
    var frontDeskStaus = false
    if (this.state.registrationStatus !== null) {

      console.log(this.state.registrationStatus, "Registrations status")

      if (this.state.registrationStatus.length > 0) {

        if (this.state.registrationStatus[0]["status"] === 0) {
          lobbyStatus = false
          frontDeskStaus = false
          this.setState({ message: "Your application is Pending" })
          this.goEvent(lobbyStatus, frontDeskStaus)
          Swal.fire({
            title: '',
            text: "Your application to participate the event is still under review. We will let you know as soon as you are approved. Thank you !",
            icon: 'warning',
            confirmButtonColor: '#fa9d1c',
          })

        }
        else if (this.state.registrationStatus[0]["status"] === 2) {
          lobbyStatus = false
          frontDeskStaus = false
          this.setState({ message: "Your application rejected" })
          this.goEvent(lobbyStatus, frontDeskStaus)

          Swal.fire({
            title: '',
            text: "Your application Rejected !",
            icon: 'warning',
            confirmButtonColor: '#fa9d1c',
          })
        }
        else if (this.state.registrationStatus[0]["status"] === 1) {
          console.log(this.state.isStarted, "iS started")
          if (this.state.isStarted == true) {
            lobbyStatus = true
            frontDeskStaus = false
            this.setState({ message: "Congratulations, Your application approved" })
            this.goEvent(lobbyStatus, frontDeskStaus)

            // Swal.fire({
            //   title: '',
            //   text: "Congratulations, Lobby !",
            //   icon: 'warning',
            //   confirmButtonColor: '#fa9d1c',
            // })
          }
          else {
            lobbyStatus = false
            frontDeskStaus = false
            this.setState({ message: "Your application approved , Event not started" })
            this.goEvent(lobbyStatus, frontDeskStaus)

            Swal.fire({
              title: '',
              text: "Your application approved , Event not started !",
              icon: 'warning',
              confirmButtonColor: '#fa9d1c',
            })
          }
        }
      }
      else {
        this.setState({
          registeredStatus: false
        })
      }
    }
  }
  checkRefStatus = (ref) => {
    var id = localStorage.getItem("eventId")
    axios
      .get("ref-validation-event/?ref=" + ref + "&eventId=" + id, {
        headers: {
          Authorization: `token ${localStorage.getItem("chat-token")}`
        }
      })
      .then(res => {
        var data = res.data.Data
        this.showStall(res.data.Data.stallId, 2)
        //this.setState({candidateId : res.data.Data.candidateId, eventId: res.data.Data.eventId, stallId:res.data.Data.stallId})
      });
  }

  callInterview = (data) => {
    var ref = data.interview.ref;
    localStorage.setItem("Open_video_conf_sia", true);
    sessionStorage.setItem("ref", ref);
  };
  colseAbout = () => {
    this.props.onBtnDisable(false)
    this.setState({ isGoAbout: false , isAboutClick: false,  isAgendaClick: false})
  }
  colseAgenda = () => {
    this.props.onBtnDisable(false)
    this.setState({ isGoAgend: false, isAgendaClick: false, isAboutClick: false})
  }
  colseAttendees = () => {
    this.props.onBtnDisable(false)
    this.setState({ isGoAttendees: false })
  }
  closeApply = () => {
    this.props.onBtnDisable(false)
    this.setState({ isGoApply: false })
  }
  closeRegisterForm = () => {
    this.setState({ isGoApply: false })
  }
  closeExhib = () => {
    this.props.onBtnDisable(false)
    this.setState({ isGoExbHall: false })
  }
  closeCompany = () => {
    this.props.onBtnDisable(false)
    this.setState({ isGoCompanies: false })
  }

  closeRegister = () => {
    console.log("close register")
    this.setState({ registeredStatus: true })
  }

  closeEventFeed = () => {
    this.props.onBtnDisable(false)
    this.setState({ eventFeedStatus: false })
  }

  render() {
    var interviewData = null;
    console.log(this.props.availableInterview, "Availinterview##########")
    if (this.props.availableInterview !== null) {
      if (this.props.availableInterview.Data === null) {
        interviewData = null;
      } else {
        interviewData = this.props.availableInterview.Data;
      }
    }

    let profile = (
      <Profile
        profileTabChange={this.profileTab}
        data={this.props.candidateDate}
      />
    );
    let campaigns = "";
    let chatModal = "";
    var progressValue;
    try {
      progressValue = this.props.candidateDate.Progress.value;
    } catch {
      progressValue = 0;
    }
    if (this.props.dashboardCV === true) {
      // campaigns = <CVPanel flag={this.props.dashboardCV} />; 
    }

    else {
      campaigns = <Campaigns data={this.props.candidateDate} />;
    }

    console.log(this.state.stalls, "Event stalls")
    let feedback = <FeedBack data={this.props.candidateDate} />;
    let progress = <Progress data={this.props.candidateDate} />;
    let nearby = <NearbyCampaigns data={this.props.candidateDate} />;
    let attendees = <Attendees />
    let stalls = <Stalls goStall={this.showStall} data={this.state.stalls} />
    const { innerWidth } = this.state;
    const modal = (
      <Modal
        open={this.props.dashboardCV}
        size="tiny"
        className="noBootstrapModal"
        onClose={this.closeModal}
      >
        <CVPanel
          flag={this.props.dashboardCV}
          changeTabCareer={this.props.changeTabCareer}
        />
      </Modal>
    );
    const options = [
      { text: 'Edit Post', value: 'edit' }
    ]

    var exhibitionHalls = []
    if (this.state.eventData != null) {
      this.state.eventData.exhibitionHalls.map((res, index) => {
        var item = { text: res.exibitionHalls.hallName, value: res.exibitionHalls.id }
        exhibitionHalls.push(item)
      })
    }
    let btn_join = this.state.isJoinEvent ? classes.active : "";
    let btn_apply = this.state.isGoApply ? classes.active : "";
    let btn_about = this.state.isGoAbout ? classes.active : "";
    let btn_attendees = this.state.isGoAttendees ? classes.active : "";
    let btn_agenda = this.state.isGoAgend ? classes.active : "";
    let btn_lobby = this.state.isGoLobby ? classes.active : "";
    let btn_audto = this.state.isGoAuditorium ? classes.active : "";
    let btn_company = this.state.isGoCompanies ? classes.active : "";
    let btn_stalls = this.state.isShowStall ? classes.active : "";
    let btn_ExbHall = this.state.isGoExbHall ? classes.active : "";

    let btn_joinEvent = <button className={btn_join} onClick={this.joinEvent}><img src={JoinEvent} /><span>Join Event</span></button>
    let btn_applyEvent = <button className={btn_apply} onClick={this.goApply}><img src={Apply} /><span>Apply</span></button>
    let btn_aboutEvent = <button className={btn_about} onClick={this.goAbout}><img src={AboutI} /><span>About</span></button>
    let btn_attendeesEvent = <button className={btn_attendees} onClick={this.goAttendees}><img src={AttendeesI} /><span>Attendees</span></button>
    let btn_agendaEvent = <button className={btn_agenda} onClick={this.goAgend}><img src={Agenda} /><span>Agenda</span></button>
    let btn_lobbyEvent = <button className={btn_lobby} onClick={this.goLobby}><img src={LobbyI} /><span>Lobby</span></button>
    let btn_audtoEvent = <button className={btn_audto} onClick={this.goAuditorium}><img src={Auditorium} /><span>Auditorium</span></button>
    let btn_companyEvent = <button className={btn_company} onClick={this.goCompanies}><img src={Companies} /><span>Companies</span></button>
    let btn_ExbHallEvent = <button className={btn_ExbHall} onClick={this.goExhibition}><img src={EHall} /><span>Exhibition Hall</span></button>
    //let btn_feedEvent = <button className={btn_company}><a href={this.state.eventFeed} target="_blank" className={classes.link}><img src={Feed} /><span>Event Feed</span></a></button>
    let btn_feedEvent = <button className={null} onClick={this.goEventFeed}><img src={Feed} /><span>Event Feed</span></button>

    var btnSet = null

    console.log(this.state.registrationStatus, "Registration status")
    console.log(this.state.registeredStatus, "Registered status ####")

    console.log(this.state.eventFeed, "Event feed###########")

    if (this.state.registrationStatus.length > 0) {
      if (this.state.registrationStatus[0]["status"] === 0) {
        btnSet = <>
          {btn_joinEvent}
          {/* {btn_applyEvent} */}
          {btn_aboutEvent}
          {btn_agendaEvent}
        </>
      }
      else if (this.state.registrationStatus[0]["status"] === 2) {
        btnSet = <>
          {btn_joinEvent}
          {btn_applyEvent}
          {btn_aboutEvent}
          {btn_agendaEvent}
          {btn_attendeesEvent}
        </>
      }
      else if (this.state.registrationStatus[0]["status"] === 1) {
        if (this.state.isStarted == true && this.state.isGoLobby == true || this.state.isGoAuditorium == true || this.state.isShowStall == true) {
          btnSet = <>
            {/* {btn_joinEvent} */}
            {this.state.isGoLobby == true || this.state.isGoAuditorium == true || this.state.isShowStall == true ? "" : btn_joinEvent}
            {btn_applyEvent}
            {btn_aboutEvent}
            {btn_agendaEvent}
            {btn_attendeesEvent}
            {btn_lobbyEvent}
            {/* {btn_audtoEvent} */}
            {btn_companyEvent}
            {btn_ExbHallEvent}
            {btn_feedEvent}
          </>
        }
        else {
          btnSet = <>
            {btn_joinEvent}
            {btn_applyEvent}
            {btn_aboutEvent}
            {btn_attendeesEvent}
            {btn_agendaEvent}
            {btn_feedEvent}
          </>
        }

      }
    }

    else if (this.state.registrationStatus.length <= 0) {
      console.log("btn set ######")
      btnSet = <>
        {btn_joinEvent}
        {/* {btn_applyEvent} */}
        {btn_aboutEvent}
        {btn_agendaEvent}
      </>
    }

    var infoDeskImageShow = null
    if (this.state.eventDataSet === true) {
      if (this.state.eventImage == "") {
        infoDeskImageShow = Logo
      }
      else if (this.state.eventImage != "") {
        infoDeskImageShow = this.state.eventImage
      }
    }
    else {
      infoDeskImageShow = null
    }

    console.log(this.state.isAboutClick, "isabout click")
    console.log(this.state.isAgendaClick, "agenda click")
    console.log(this.state.registeredStatus, "Registered click")
    console.log(this.props.accessStall,  "AccessStall")

    var  inlineStyleStall = null
    var  inlineStyleStall = null

    if (this.state.isShowStall === true){
      inlineStyleStall = {transform:'translateX(0%) translateY(0%)' }
    }
    
    return (
      <Animate to="1" from="0" attributeName="opacity" className={classes.DashboardContainer}>

        <div style={{ backgroundImage: `url(${this.state.backgroundImage})` }} className={classes.DashboardWrapper}>
  
          {
            this.state.isShowStall === true?
            <>
            <div style={{position:'absolute', backdropFilter:'blur(5px)', height:'calc(100vh - 125px)', width:'100vw', backgroundColor:'#171717d9' }}>.</div>
            </>:null 
          }

          <div className={classes.DashboardWrapperContent} style={inlineStyleStall} >

            {
              this.state.isShowStall === true?
              <>
              <img src={TestBg} className={classes.SingleStallImg}/>
              </>:null 
            }
            
            {this.state.frontImage == true ?
              <div className={classes.WallTv}>
                <div className={classes.ImgContent}>

                  {/* <img src={this.state.eventImage == "" ? Logo : this.state.eventImage} /> */}

                  {/* <img src={this.state.eventImage == "" ? Logo : this.state.eventImage} /> */}

                  <img src={infoDeskImageShow} />

                  {/* <img src={front_desk}/> */}
                </div>
              </div>
              : null
            }

            {this.state.registeredStatus === false?
              <div className={classes.regZ}>
                <div className={classes.closeBtnAlign}> <button onClick={this.closeRegister} className={classes.closebtnnew} >X</button> </div>
                <Regiester registrationCompalte={this.getEventData}></Regiester>
              </div>
              : null
            }

            {this.state.isGoApply == true ?
              <div className={classes.progressWrapper}>
                <div className={classes.closeBtnAlign}> <button onClick={this.closeApply} className={classes.closebtnnew} >X</button> </div>
                <div className={classes.aboutTopd}>
                  <h3><span className={classes.orange}>CAMPAIGNS FOR YOU</span></h3>
                </div>
                {nearby}
              </div>
              : null
            }

            {this.state.isGoAbout == true ?
              <div className={classes.progressWrapper}>
                <div className={classes.closeBtnAlign}> <button onClick={this.colseAbout} className={classes.closebtnnew} >X</button> </div>
                <About eventData={this.state.eventData}></About>
                </div>
              : null
            }

            {this.state.isGoAttendees == true ?
              <div className={classes.progressWrapper}>
                <div className={classes.closeBtnAlign}> <button onClick={this.colseAttendees} className={classes.closebtnnew} >X</button> </div>
                <div className={classes.aboutTopd}>
                  <h3><span className={classes.orange}>ATTENDEES</span></h3>
                </div>
                {attendees}
              </div>
              : null
            }

            {this.state.isGoAgend == true ?
              <div className={classes.progressWrapper}>
                <div className={classes.closeBtnAlign}> <button onClick={this.colseAgenda} className={classes.closebtnnew} >X</button> </div>
                <div className={classes.aboutTopd}>
                  <h3><span className={classes.orange}>AGENDA</span></h3>
                </div>
                { this.state.agendaPdf != null ?
                  <embed
                    type="application/pdf"
                    // width="100%"
                    // height="350px"
                    // className="cv-embed-in-campaign-dash"
                    className={classes.sizeEmd}
                    src={this.state.agendaPdf}
                  />
                  : 
                  <div className={classes.instructionWrapper}>
                    <div className={classes.instructions}>
                      <div className={classes.heading}>
                        <span>Agenda will be updated soon!</span>
                      </div>
                      {/* <div className={classes.descr}>
                        Wanna know more about the ufairs?
                      </div>
                      <a href="#">Yes</a> */}
                    </div>
                  </div>
                }
              </div>
              : null
            }

            {this.state.isGoLobby == true ?
              <div>
                <Lobby images={this.state.eventData}></Lobby>
              </div>
              : null
            }

            {this.state.isGoAuditorium == true ?
              <div>
                <Auditoriums webinar={this.state.eventData}></Auditoriums>
              </div>
              : null
            }

            {this.state.isGoCompanies == true ?
              <div className={classes.progressWrapper}>
                <button style={{ float: "right" }} onClick={this.closeCompany} className={classes.closebtnnew} >X</button>
                <div className={classes.aboutTopd}>
                <h3><span className={classes.orange}>STALLS</span></h3>
                </div>
                {stalls}
              </div>
              : null
            }

            {this.state.isShowStall == true ?
              <div>
                <IndvStall eventData={this.state.eventData}
                  exbHallId={this.state.exbHallId}
                  stallStatus={this.state.stallStatus}
                  data={this.state.stalls} />
              </div>
              : null
            }

            {this.state.isGoExbHall == true ?
              <div className={classes.progressWrapper}>
                <button style={{ float: "right" }} onClick={this.closeExhib} className={classes.closebtnnew} >X</button>
                <div className={classes.aboutTopd}>
                  <h3><span className={classes.orange}>EXHIBITION HALL</span></h3>
                </div>
                <Exhibition
                  goStall={this.showStall}
                  eventData={this.state.eventData}>
                </Exhibition>
              </div>
              : null
            }

            {this.state.isJoinEvent == true ?
              <div>
                <Lobby images={this.state.eventData}></Lobby>
              </div>
              : null
            }

            {this.state.eventFeedStatus == true && this.state.eventFeed === "" ?
              <div className={classes.progressWrapper}>
                <button style={{ float: "right" }} onClick={this.closeEventFeed} className={classes.closebtnnew} >X</button>
                <div className={classes.aboutTopd}>
                  <h3><span className={classes.orange}>EVENT FEED</span></h3>
                </div>
                <div className={classes.instructionWrapper}>
                  <div className={classes.instructions}>
                    <div className={classes.heading}>
                      <span>Event Feed will be updated soon!</span>
                    </div>
                    {/* <div className={classes.descr}>
                      Wanna know more about the ufairs?
                    </div>
                    <a href="#">Yes</a> */}
                  </div>
                </div>
                {/* <div>
                  <Lobby images={this.state.eventData}></Lobby>
                </div> */}
              </div>
              : null
            }

          </div>

          <div className={classes.BootomNav}>
            {this.state.registeredStatus == true ||  this.state.registeredStatus === false?
              <>
                {/* <button className={btn_join} onClick={this.joinEvent}><img src={JoinEvent} /><span>Join Event</span></button>
                <button className={btn_about} onClick={this.goAbout}><img src={AboutI} /><span>About</span></button>
                <button className={btn_agenda} onClick={this.goAgend}><img src={Agenda} /><span>Agenda</span></button>
                <button  className={btn_apply} onClick={this.goApply}><img src={Apply} /><span>Apply</span></button>
                <button  className={btn_lobby} onClick={this.goLobby}><img src={LobbyI} /><span>Lobby</span></button>
                <button className={btn_ExbHall} onClick={this.goExhibition}><img src={EHall} /><span>Exhibition Hall</span></button>
                <button className={btn_audto} onClick={this.goAuditorium}><img src={Auditorium} /><span>Auditorium</span></button>
                <button className={btn_company} onClick={this.goCompanies}><img src={Companies} /><span>Companies</span></button>
                <button  className={btn_attendees}onClick={this.goAttendees}><img src={AttendeesI} /><span>Attendees</span></button> */}
                {btnSet}
              </>
              : null}
          </div>
        </div>
      </Animate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    candidateDate: state.CandidateData.userData,
    dashboardCV: state.profile.cvDashboard,
    availableInterview: state.auth.availableInterview,
    accessStall: state.auth.accessStall
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetCandidateData: (data, currentTime) =>
      dispatch(actionsCreator.getCandidateData(data, currentTime)),
    onGetAvailableCampaigns: (token, time) => dispatch(actionsCreator.getAvailableCampaign(token, time)),
    onBtnDisable: (data) => dispatch(actionsCreator.BtnDisable(data))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
