import * as actions from "./actionTypes";
import axios from "../../axios";
import moment from "moment";


export const SuccessUpdate = data=>{
  return {
    type : actions.SUCCESS_UPDATE_REF_INVITE,
    data
  }
} 

export const successFullCVCheck = data=>{
  return{
    type : actions.GET_CV_ORIGINAL_STATUS,
    data
  }
}

export const SuccessData = data => {
  console.log(data, "successdata");
  return {
    type: actions.GET_CANDIDATE_DATA_SUCCESS,
    data
  };
};

export const SuccessDataStall = data => {
  return {
    type: actions.GET_CANDIDATE_DATA_SUCCESS_STALL,
    data
  }
}

export const SuccessGetIntData = data => {
  return {
    type: actions.GET_INTERVIEW_DATA,
    data
  };
};
export const successTwillioToken = data => {
  return {
    type: actions.GET_TWILLIO_TOKEN,
    data
  };
};
export const successGetQueue = data => {
  return {
    type: actions.GET_CANDIDATE_QUEUE,
    data
  };
};

export const successGetData = data => {
  return {
    type: actions.GET_CAMPAIGN_DATA,
    data
  };
};

export const UpdateRef  = (data, token)=> {
  return dispatch =>{
    axios.get('update-quick-interview-id/?ref='+ data+'&token='+token,{
      headers: {
        Authorization: `token ${token}`
      }
    }).then(res=>{
      dispatch(SuccessUpdate(res.data.Data))
    })
  }
}

export const getoriginlCVStatus = () =>{
  var token = localStorage.getItem("chat-token")
  return dispatch =>{
    axios.get('check-original-cv-status/?token='+token,{
      headers: {
        Authorization: `token ${token}`
      }
    }).then(res=>{
      dispatch(successFullCVCheck(res.data["Data"]))
    })
  }
}


export const getCandidateData = (data, currentTime) => {  
  return dispatch => {
    axios
      .get(
        "get-candidate-dashboard-data/?id=" +
          data +
          "&logDate="+ currentTime + "&eventId="+ localStorage.getItem("eventId"),
        {
          headers: {
            Authorization: `token ${data}`
          }
        }
      )
      .then(res => {      
        dispatch(SuccessData(res.data.Data));
      });
  };
};

export const getCandidateDataCampaign = (token, currentTime, stallId) => {  
  return dispatch => {
    axios
      .get(
        "get-candidate-dashboard-data-stall/?id=" +
          token +
          "&logDate="+ currentTime + "&stallId="+ stallId,
        {
          headers: {
            Authorization: `token ${token}`
          }
        }
      )
      .then(res => {      
        dispatch(SuccessDataStall(res.data.Data));
      });
  };
};

export const getInterviewData = data => {
  return dispatch => {
    axios
      .get("get-user-interview-data/?ref=" + data, {
        headers: {
          Authorization: `token ${localStorage.getItem("chat-token")}`
        }
      })
      .then(res => {
        dispatch(SuccessGetIntData(res.data.Data));
      });
  };
};

export const getTwillioToken = data => {
  var dataToken = {
    interview_id: data
  };
  return dispatch => {
    axios
      .post("calls/", dataToken, {
        headers: {
          Authorization: `token ${localStorage.getItem("chat-token")}`
        }
      })
      .then(res => {
        dispatch(successTwillioToken(res.data.Data));
      });
  };
};

export const getCandidatequeue = (currentTime, camId, intId) => {
  return dispatch => {
    axios
      .get(
        "candidate-queue/?start_time=" +
          currentTime +
          "&interviewId=" +
          intId +
          "&campaignId=" +
          camId,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("chat-token")}`
          }
        }
      )
      .then(res => {
        dispatch(successGetQueue(res.data.Data));
      });
  };
};
export const getCampaignData = (data,ref) => {
  return dispatch => {
    axios
      .get("get-campaign-data/?id=" + data+"&ref="+ref, {
        headers: {
          Authorization: `token ${localStorage.getItem("chat-token")}`
        }
      })
      .then(res => {
        dispatch(successGetData(res.data.Data));
      });
  };
};

export const updateCandidateSetting = (data, id,status=false) => {
  var token = localStorage.getItem("chat-token");

  return dispatch => {
    axios
      .put("update-candidate-profile/" + id + "/", data, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(res => {
        if(status){
        dispatch(getChatResponseNew("saved_information_profile_update",false));    
        }
        dispatch(getCandidateData(token,moment.utc().toISOString()))
      });
  };
};

export const getChatResponseNew = (
  query,
  visibility = true,
  notification = false
) => {

  return dispatch => {   
    const token = localStorage.getItem("chat-token");
    const ref = localStorage.getItem("chat-ref-id");
    if (notification) { 
    }
    if (!notification) {
      dispatch(chatInitNew(query, visibility));
    }

    axios
      .post(
        "conversations/",
        ref
          ? {
              query: query,
              ref: ref
            }
          : {
              query: query
            },
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      )
      .then(res => {
        if (ref) {
          localStorage.removeItem("chat-ref-id");
        }
        if (res.status !== 204) {
          const response = res.data.response;
          const queryVisibility = res.data.query_visibility;
          //const humanizedTime = res.data.humanized_time;
          const humanizedTime = res.data.time;
          dispatch(chatSuccessNew(response, queryVisibility, humanizedTime));
        }
      })
      .catch(err => {
        dispatch(chatFailNew());
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("chat-username");
            localStorage.removeItem("chat-userimage");
            //localStorage.removeItem("chat-token");

            //window.location.reload();
          }
        }
      });
  };
};
export const chatInitNew = (
  query = "query",
  queryVisibility,
  humanizedTime = "Just now"
) => {
  return {
    type: actions.CHATNEW_INIT,
    query,
    queryVisibility,
    humanizedTime
  };
};
export const chatSuccessNew = (response, queryVisibility, humanizedTime) => {
  return {
    type: actions.CHATNEW_SUCCESS,
    response,
    queryVisibility,
    humanizedTime
  };
};

export const chatFailNew = () => {
  return {
    type: actions.CHATNEW_FAIL
  };
};

export const successTimeUpdate = (data) => {
  return{
    type: actions.TIME_UPDATED_SUCCESS,
    data
  }
}
export const successGetVideoPitch = (data) => {
  return{
    type: actions.VIDEO_PITCH_SUCCESS,
    data
  }
}

export const successGetCoverLetter = (data) => {
  return{
    type: actions.COVER_PITCH_SUCCESS,
    data
  }
}
export const successValidationUrl = (data) => {
  return{
    type: actions.VALIDATION_URL_SUCCESS,
    data
  }
}
export const successCandidateFeedback = (data) => {
  return{
    type: actions.SUCCESS_CANDIDATE_FEEDBACK_SUCCESS,
    data
  }
}
export const successRecruiterCallStatus = (data) => {
  return{
    type: actions.SUCCESS_RECRUITER_CALL_STATUS,
    data
  }
}
export const successGetShareGroup = (data)=>{
  return {
    type : actions.GET_SHARE_GROUP_SUCCESS,
    data
  }
}


export const updateTimes = (ms, inwId) => {
  var token = localStorage.getItem("chat-token");
  return dispatch=>{
    axios.get('update-interview-times-queue-panel/?mls='+ms+'&inwId='+inwId,{
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res=>{
      dispatch(successTimeUpdate(res.data))
    })
  }
}

export const getVideoPitch = (userId,campaignId) => {
  var token = localStorage.getItem("chat-token");
  return dispatch=>{
    axios.get("videopitch/?candidate_user_id="+userId+"&campaignId="+campaignId,{
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res=>{
      dispatch(successGetVideoPitch(res.data))
    })
  }
}
export const getCoverLetter = (userId,campaignId) => {
  var token = localStorage.getItem("chat-token");
  return dispatch=>{
    axios.get("get-cover-letter-uploaded/?candidateid="+userId+"&campaignId="+campaignId,{
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res=>{
      dispatch(successGetCoverLetter(res.data))
    })
  }
}
export const validationUrl = (ref,id) => {
  var token = localStorage.getItem("chat-token");
  return dispatch=>{
    axios.get("check-ref-validation/?ref="+ref+"&candidateId="+id,{
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res=>{
      dispatch(successValidationUrl(res.data))
    })
  }
}
export const getCandidateFeedback = (data) => {
  var token = localStorage.getItem("chat-token");
  return dispatch=>{
    axios.get("get-candidate-feedback/?senderId="+data,{
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res=>{
      dispatch(successCandidateFeedback(res.data))
    })
  }
}

export const getRecruiterCallStatus = (ref) => {
  var token = localStorage.getItem("chat-token");
  return dispatch=>{
    axios.get("prev-can-status/?ref="+ref,{
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res=>{
      dispatch(successRecruiterCallStatus(res.data))
    })
  }
}

export const getCompanyShareGroup =(ref) => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .get("share-common-link/?shareCom="+ref, {
        headers: {
       //    Authorization: `token ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        dispatch(successGetShareGroup(res.data));
      });
  };
};

export const successChangeTime=(data)=>{
  return {
    type: actions.SUCCESS_CHANGE_TIME,
    data
  }
}

export const successChangeCount =(data)=>{
  console.log(data, "Change count timer")
  return {
    type: actions.SUCCESS_CHANGE_COUNT,
    data
  }
}

export const ChangeTimer =(count, data)=>{
  console.log(count, "####count")
  console.log(data, "#####data")
  return dispatch=>{
    dispatch(successChangeTime(data))
    dispatch(successChangeCount(count))
  }
}
