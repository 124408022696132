import React, { Fragment, useState, useEffect } from 'react'
import { withRouter, Prompt } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './VideoChat.module.scss'
import { faMicrophone, faVideoSlash, faPhoneSlash, faPhone, faVideo, faArrowsAlt, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../UI/Loading/Spinner/Spinner'
import SiaLogoVideo from '../../../assets/image/sia-logo-video.png'
import SiaLogo from '../../../assets/image/sia-logo.png'
import Video from 'twilio-video';
import Animate from 'react-smooth'
import axios from '../../../axios'

const videoChat = (props) => {
    const [loading, setLoading] = useState(false)
    const [callConnect, setCallConnect] = useState(false)
    const [callDisconnect, setCallDisconnect] = useState(false)
    const [activeRoom, setActiveRoom] = useState(null)
    const [camera, setCamera] = useState(true)
    const [mic, setMic] = useState(true)
    const [participent, setParticipent] = useState(false)
    const [activeCall, setActiveCall] = useState(true)
    const [haveCamera, setHaveCamera] = useState(false)
    const [haveMic, setHaveMic] = useState(false)
    const [alert, setAlert] = useState(false)
    const [localTrackVideo, setLocalTrackVideo] = useState(null)
    const [localTrackAudio, setLocalTrackAudio] = useState(null)


    useEffect(() => {
        setTimeout(() => {
            setCallDisconnect(false)
        }, 3000)
    }, [callDisconnect])

    useEffect(() => {
        return (() => {
            if (callConnect) {
                activeRoom.disconnect();
                setActiveCall(true)
                removeLocalPartisipent()
            }
        })
    }, [callConnect])
    const removeLocalPartisipent = () => {
        const localParticipant = activeRoom.localParticipant;

        if (camera) {
            localParticipant.videoTracks.forEach(function (videoTrack) {
                videoTrack.detach().forEach(element => element.remove());
            })
        }
    }
    useEffect(() => {
        getCall()
    }, [])
    /*
    useEffect(() => {
        if (haveMic) {
            setLoading(true)
            setActiveCall(false)
            setCallDisconnect(false)
            const videoToken = props.token
            if (videoToken) {
                Video.connect(videoToken, { name: 'room-name' }).then(room => {
                    setActiveRoom(room)
                    var previewContainer = document.getElementById('local-media');
                    if (!previewContainer.querySelector('video')) {
                        navigator.getMedia = (navigator.getUserMedia || // use the proper vendor prefix
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia ||
                            navigator.msGetUserMedia);

                        navigator.getMedia({ video: true }, function (vid) {
                            // webcam is available
                            setLocalTrackVideo(vid.getTracks())
                            setHaveCamera(true)
                        }, function () {
                            // webcam is not available
                            // console.log("No Camera");
                            setHaveCamera(false)
                            setAlert(true)
                        });
                        attachParticipantTracks(room.localParticipant, previewContainer);
                    }
                    setLoading(false)
                    setCallConnect(true)
                    room.participants.forEach(participantConnected);
                    room.on('participantConnected', participantConnected);

                    room.on('participantDisconnected', participantDisconnected);
                    room.once('disconnected', error => room.participants.forEach(participantDisconnected));
                });
            }
        }
    }, [haveMic])
    */

    const startVideo=(e)=>{
        if (haveMic) {
            setLoading(true)
            setActiveCall(false)
            setCallDisconnect(false)
            const videoToken = e
            if (videoToken) {
                Video.connect(videoToken, { name: 'room-name' }).then(room => {
                    setActiveRoom(room)
                    var previewContainer = document.getElementById('local-media');
                    if (!previewContainer.querySelector('video')) {
                        navigator.getMedia = (navigator.getUserMedia || // use the proper vendor prefix
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia ||
                            navigator.msGetUserMedia);

                        navigator.getMedia({ video: true }, function (vid) {
                            // webcam is available
                            setLocalTrackVideo(vid.getTracks())
                            setHaveCamera(true)
                        }, function () {
                            // webcam is not available
                            // console.log("No Camera");
                            setHaveCamera(false)
                            setAlert(true)
                        });
                        attachParticipantTracks(room.localParticipant, previewContainer);
                    }
                    setLoading(false)
                    setCallConnect(true)
                    room.participants.forEach(participantConnected);
                    room.on('participantConnected', participantConnected);

                    room.on('participantDisconnected', participantDisconnected);
                    room.once('disconnected', error => room.participants.forEach(participantDisconnected));
                });
            }
        }
    }

    const getCall=()=>{
        navigator.getMedia = (navigator.getUserMedia || // use the proper vendor prefix
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia);
        navigator.getMedia({ audio: true }, function (aud) {
            // webcam is available
            setLocalTrackAudio(aud.getTracks())
            console.log("have Mic");
            setHaveMic(true)
        }, function () {
            // webcam is not available
            console.log("No Mic");
            setHaveMic(false)
            setAlert(true)
        });
        const token = localStorage.getItem('chat-token')
        axios.get("users/me/", {
            headers: {
                Authorization: `token ${token}`
            }
            })
        .then(res => {              
                //console.log(res.data.id)
                //console.log("attempting to start")
                axios.post('http://127.0.0.1:8000/manager/calls/', {
                    interview_id: 4
                },
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    })
                    .then(res1 => {                      
                        console.log(res1.data)
                        var token = res1.data.twilio_token
                        console.log(token) 
                        startVideo(token) 
                    })            
        })

    }



    /*
    const getCall = () => {
        navigator.getMedia = (navigator.getUserMedia || // use the proper vendor prefix
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia);


        navigator.getMedia({ audio: true }, function (aud) {
            // webcam is available
            setLocalTrackAudio(aud.getTracks())
            console.log("have Mic");
            setHaveMic(true)
        }, function () {
            // webcam is not available
            console.log("No Mic");
            setHaveMic(false)
            setAlert(true)
        });
    }*/
    const attachParticipantTracks = (participant, container) => {
        var tracks = Array.from(participant.tracks.values());
        attachTracks(tracks, container);
    }
    const attachTracks = (tracks, container) => {
        tracks.forEach(function (track) {
            container.appendChild(track.attach());
        });
    }

    const participantConnected = (participant) => {

        const div = document.createElement('div');
        div.id = participant.sid;
        console.log("participantConnected", participant);

        document.getElementById('remote-media-div').appendChild(div)

        participant.on('trackSubscribed', track => trackSubscribed(div, track));
        participant.tracks.forEach(track => trackSubscribed(div, track));
        participant.on('trackUnsubscribed', trackUnsubscribed);
    }

    const participantDisconnected = (participant) => {
        // this.setState({ loading: false, callConnect: false })

        participant.tracks.forEach(trackUnsubscribed);
        document.getElementById(participant.sid).remove();
    }

    const trackSubscribed = (div, track) => {
        setParticipent(true)
        div.appendChild(track.attach());
    }


    const callHangup = () => {
        if (callConnect) {
            activeRoom.disconnect();
            if (localTrackVideo) {
                // console.log('---',localTrackVideo);

                localTrackVideo.forEach(track => {
                    track.stop()
                })
            }
            if (localTrackAudio) {
                // console.log('+++',localTrackAudio);
                localTrackAudio.forEach(track => {
                    track.stop()
                })

            }
            setActiveCall(true)
            setParticipent(false)
            setCallConnect(false)
            removeLocalPartisipent()
            setHaveCamera(false)
            setHaveMic(false)
            setAlert(false)
        }
    }
    const trackUnsubscribed = (track) => {
        setParticipent(false)
        setCallDisconnect(true)

        track.detach().forEach(element => element.remove());
    }

    const toggleMute = () => {
        const localParticipant = activeRoom.localParticipant;
        if (mic) {
            localParticipant.audioTracks.forEach(function (audioTrack) {
                audioTrack.disable();
            });
        }
        if (!mic) {
            localParticipant.audioTracks.forEach(function (audioTrack) {
                audioTrack.enable();
            });
        }
        setMic(!mic)
    }


    const cameraToggle = () => {
        const localParticipant = activeRoom.localParticipant;

        if (camera) {
            localParticipant.videoTracks.forEach(function (videoTrack) {
                videoTrack.disable()
            });
        }
        if (!camera) {
            localParticipant.videoTracks.forEach(function (videoTrack) {
                videoTrack.enable()
            });
        }
        setCamera(!camera)
    }

    const handleFullScreen = () => {
        let video = document.getElementById('remote-media-div').childNodes[1].childNodes[1]
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.mozRequestFullScreen) {
            video.mozRequestFullScreen(); // Firefox
        } else if (video.webkitRequestFullscreen) {
            video.webkitRequestFullscreen(); // Chrome and Safari
        }
    }
    let videoLoading = null
    if (loading) {
        videoLoading = (
            <div className={classes.Loading}>
                <img src={SiaLogo} alt="Sia" />
                <h3>Connecting</h3>
                <Spinner />
                <p>Waiting for Interviewer.</p>
            </div>
        )
    }
    let callButton = null
    if (activeCall) {
        callButton = (
            <div className={classes.CallButtons}>
                <span onClick={getCall} className={classes.tackCall + " " + classes.Tip}>
                    <FontAwesomeIcon icon={faPhone} />
                    <span className={classes.Tooltip + " " + classes.Left}>Start Call</span>
                </span>
            </div>
        )
    }
    if (callConnect) {
        callButton = (
            <Fragment>
                <div className={classes.TopControler}>
                    <span onClick={cameraToggle} className={classes.Tip}>
                        {
                            camera
                                ? (<><FontAwesomeIcon icon={faVideo} /><span className={classes.Tooltip + " " + classes.Left}>Camera on</span></>)
                                : (<><FontAwesomeIcon icon={faVideoSlash} /><span className={classes.Tooltip + " " + classes.Left}>Camera Off</span></>)
                        }
                    </span>
                    <span onClick={toggleMute} className={classes.Tip}>
                        {
                            mic
                                ? (<><FontAwesomeIcon icon={faMicrophone} /> <span className={classes.Tooltip + " " + classes.Left}>Mic on</span></>)
                                : (<><FontAwesomeIcon icon={faMicrophoneSlash} /> <span className={classes.Tooltip + " " + classes.Left}>Mic off</span></>)
                        }
                    </span>
                    {
                        participent
                            ? <span onClick={handleFullScreen} className={classes.Tip}>
                                <FontAwesomeIcon icon={faArrowsAlt} />
                                <span className={classes.Tooltip + " " + classes.Left}>Full screen</span>
                            </span>
                            : null
                    }
                </div>
                <div className={classes.CallButtons}>
                    <span onClick={callHangup} className={classes.hangupCall + " " + classes.Tip}>
                        <FontAwesomeIcon icon={faPhoneSlash} />
                        <span className={classes.Tooltip + " " + classes.Left}>Call hangout</span>
                    </span>
                </div>
            </Fragment>
        )
    }
    const classListLocalUser = [
        classes.LocalCamera,
        callConnect ? classes.On : null
    ]
    const classListParticipent = [
        classes.VideoCo,
        callConnect ? classes.On : null
    ]
    let disconnectMessage = null
    if (callDisconnect) {
        disconnectMessage = (
            <div className={classes.ParticipentDisconnect}>
                Disconnected
                        </div>
        )
    }
    let warningMedia = null
    if (alert) {
        if (!haveMic) {
            warningMedia = (
                <Animate to="1" from="0" attributeName="opacity">
                    <div className={classes.warningAlert + " " + classes.Error}>
                        <span>Can't connect call. Microphone is not found.</span>
                    </div>
                </Animate>
            )

        }
        else if (!haveCamera) {
            warningMedia = (
                <Animate to="1" from="0" attributeName="opacity">
                    <div className={classes.warningAlert}>
                        <span>Camera is not working. </span>
                    </div>
                </Animate>
            )
        }
    }
    return (
        <>
            <div className={classes.VideoChat} style={{ backgroundImage: `url(${SiaLogoVideo})` }}>
                {callConnect ? <Prompt message="Do you really want to leave from video chat?" /> : null}

                {videoLoading}

                <div id='remote-media-div' className={classListParticipent.join(' ')} >
                    {disconnectMessage}
                    {!callDisconnect && !participent && callConnect
                        ? (
                            <div className={classes.WatingFor}>
                                Waiting for Interviewer
                                <Spinner />
                            </div>
                        )
                        : null}
                    <div id="local-media" className={classListLocalUser.join(' ')}></div>
                </div>
                {callButton}
            </div >
            {warningMedia}
        </>
    )
}

export default withRouter(videoChat)