import React, { Component, isValidElement } from "react";
import classes from "./CVUpload.module.scss";
import SpinnerGrey from "../UI/SpinnerGrey/SpinnerGrey";
// import Spinner from "../UI/Spinner/Spinner";
import { Dimmer, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import * as actionsCreator from "../../../src/store/actions/index";
import moment from "moment";
import axios from "../../axios";
import swal from "sweetalert2";
import { Form } from "semantic-ui-react";
import ProgressBar from "@ramonak/react-progress-bar";

class CvUploadPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      imageString: null,
      flagData: this.props.flagData,
      isValidFile: "",
      fileUploadSpinner: false,
      percentage: 0
    };
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  btnSaveProceed = (e) => {
    e.preventDefault();
    this.props.onDataSave(this.state.imageString);
    this.props.onSkip();
    //this.props.onSavedSteps(this.props.stepNo)
  };

  btnSkipForNow = (e) => {
    e.preventDefault();
    localStorage.setItem("candidateCV", "");
    this.props.onSkip();
    //this.props.onSkipSteps(this.props.stepNo)
  };

  handleFile_dashboard_original = (e) => {
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      this.setState({
        fileUploadSpinner: true,
      });
      var file = e.target.files[0];
      var extensions = e.target.files[0].name.split(".").pop();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("candidateCV", preview);
        this.cvFiltering_dashboard(preview, 1, extensions);
      };
      this.setState({
        imageString: localStorage.getItem("candidateCV"),
      });
      // this.props.onDataSave(this.state.imageString);
      // this.setState({ isValidFile: "" });
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });
      swal.fire({
        position: "center",
        // icon: "warning",
        imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
        title: "Warning!",
        text: "Only PDF/DOC/DOCX formats allowed",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  handleFile_dashboard_linkedIn = (e) => {
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      this.setState(
        {
          fileUploadSpinner: true,
        },
     //   () => this.props.changeTabCareer()
      );
      var file = e.target.files[0];
      var extensions = e.target.files[0].name.split(".").pop();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("candidateCV", preview);
        this.cvFiltering_dashboard(preview, 0, extensions);
      };
      this.setState({
        imageString: localStorage.getItem("candidateCV"),
      });
      // this.props.onDataSave(this.state.imageString);
      // this.setState({ isValidFile: "" });
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });

      // swal.fire({
      //   position: "center",
      //   // icon: "warning",
      //   imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
      //   title: "Warning!",
      //   text: "Only PDF/DOC/DOCX formats allowed",
      //   showConfirmButton: false,
      //   timer: 3500,
      // });
    }
  };

  cvFiltering_dashboard = (fileString, value, extensions) => {
    const token = localStorage.getItem("chat-token");
    var id = localStorage.getItem("CandidateProfile_id02");
    var data = {
      uploaded_at: moment().toISOString(),
      upload: fileString,
      user_id: parseInt(id),
      cv_type: 1,
      format_type: extensions,
    };
    axios
      .post("cv/", data, {
        onUploadProgress: (pregresEvent) => {
          const {loaded, total} =  pregresEvent
                    let percent = Math.floor(loaded* 100/ total)
                    console.log(percent, "Percentage")
                    this.setState({
                        percentage : percent
            })
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        this.closeHandler();
        this.props.getSystemLevelData();
        var currentTime = moment.utc().toISOString();
        this.props.onGetCandidateData(token, currentTime);
        swal.fire({
          toast: true,
          position: "top-end",
          title: "Successfully uploaded",
          showConfirmButton: false,
          timer: 3000,
          customClass: { container: "swal-success-settings" },
          imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          imageHeight: 20,
        });
      })
      .catch((err) => {});
  };

  closeHandler = () => {
    var flag = false;
    this.props.onUploadCV(flag);
  };

  closeHandler_skip = () => {
    var flag = false;
    this.props.onUploadCV(flag);
  };

  handleFileChange_original = (e) => {
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      this.setState({
        fileUploadSpinner: true,
      });
      var file = e.target.files[0];
      var extensions = e.target.files[0].name.split(".").pop();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("candidateCV", preview);
        this.cvFiltering(preview, 1, extensions);
      };
      this.setState({
        imageString: localStorage.getItem("candidateCV"),
      });
      this.props.onDataSave(this.state.imageString);
      this.setState({ isValidFile: "" });
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });
      swal.fire({
        position: "center",
        // icon: "warning",
        imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
        title: "Warning!",
        text: "Only PDF/DOC/DOCX formats allowed",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  handleFileChange = (e) => {
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      this.setState({
        fileUploadSpinner: true,
      });
      this.props.changeTabCareer();
      var file = e.target.files[0];
      var extensions = e.target.files[0].name.split(".").pop();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("candidateCV", preview);
        this.cvFiltering(preview, 0, extensions);
      };
      this.setState({
        imageString: localStorage.getItem("candidateCV"),
      });
      this.props.onDataSave(this.state.imageString);
      this.setState({ isValidFile: "" });
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });
      swal.fire({
        position: "center",
        // icon: "warning",
        imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
        title: "Warning!",
        text: "Only PDF/DOC/DOCX formats allowed",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  cvFiltering = (fileString, value, extensions) => {
    const token = localStorage.getItem("chat-token");
    var id = sessionStorage.getItem("CandidateProfile_id02");
    var data = {
      uploaded_at: moment().toISOString(),
      upload: fileString,
      user_id: parseInt(id),
      cv_type: value,
      format_type: extensions,
    };
    axios
      .post("cv/", data, {
        onUploadProgress: (pregresEvent) => {
          const {loaded, total} =  pregresEvent
                    let percent = Math.floor(loaded* 100/ total)
                    console.log(percent, "Percentage")
                    this.setState({
                        percentage : percent
            })
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        axios
          .get("upload-cv-extract/?candidate_user_id=" + id, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((res) => {
            this.props.onCvFiltering(res.data);
            this.props.onSkip();
            this.props.getSystemLevelData();
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  render() {
    let text_one = "";
    let text_two = "";
    let defaultUpload = "";
    let defaultUpload_original = "";
    let defaultSkip = "";

    console.log(this.props.dashboardCV, "dashboard cv status");

    if (this.props.dashboardCV === true) {
      text_one = "";
      text_two = "";
      defaultUpload = this.handleFile_dashboard_linkedIn;
      defaultUpload_original = this.handleFile_dashboard_original;
      defaultSkip = this.closeHandler_skip;
      console.log("11111111");
    } else {
      text_one = "You are";
      text_two = "  almost there";
      defaultUpload = this.handleFileChange;
      defaultUpload_original = this.handleFileChange_original;
      defaultSkip = this.btnSkipForNow;
      console.log("11111122");
    }
    const { fileUploadSpinner } = this.state;
    return (
      <div className={classes.cvStepWrapper}>
        <div
          className={`${classes.cvStep} ${
            this.props.dashboardCV && classes.fillAvailable
          }`}
        >
          {!this.props.dashboardCV && (
            <div className={classes.topRowWrapper}>
              <h3>
                {text_one}
                <span className={classes.orange}>{text_two}</span>
              </h3>
            </div>
          )}
          <div className={classes.sectionWrap}>
            <div
              className={`${!this.props.dashboardCV && classes.sectionDash} ${
                classes.section
              }`}
            >
              <div className={classes.topInputArea}>
                <div className={classes.tipText1}>
                  Hundreds of companies are waiting for the right candidates CV
                </div>
                {this.state.isUploading ? (
                  <div className={classes.tipText2}>
                    Your CV is{" "}
                    <span className={classes.blue}>getting uploaded</span>
                  </div>
                ) : (
                  <div className={classes.tipText2}>
                    Why not <span className={classes.blue}>upload&nbsp;</span>
                    your Linkedin cv?
                  </div>
                )}

                <input
                  type="file"
                  name="Phone"
                  onChange={this.handleInputChange}
                  placeholder="Phone Number"
                ></input>
              </div>
              {this.state.isUploading ? (
                <div className={classes.spinnerWrapper}>
                  <SpinnerGrey />
                </div>
              ) : (
                ""
              )}
              {this.state.isUploading ? (
                <div className={classes.spinnerWrapper}>
                  <SpinnerGrey />
                </div>
              ) : (
                <div className={`${classes.buttonWrap} dimmer-in-pdf-upload`}>
                  <Dimmer active={fileUploadSpinner}>
                    <Loader size="small">Uploading File</Loader>
                  </Dimmer>
                  <button
                    style={{ marginTop: "20px" }}
                    onClick={defaultSkip}
                    className={classes.skipDashCVButton}
                  >
                    I'll upload later
                  </button>
                  <input
                    type="file"
                    name="uploadedCV"
                    id="uploadedCV"
                    title="Upload your Linkedin CV"
                    className={classes.inputFile1}
                    onChange={defaultUpload}
                  ></input>
                  {/* <input
                    type="file"
                    name="uploadedCV"
                    id="uploadedCV"
                    title="Upload your CV"
                    className={classes.inputFile2}
                    onChange={defaultUpload_original}
                  ></input> */}
                </div>
              )}
              {this.state.isValidFile && (
                <div className={classes.fileformatError}>
                  {this.state.isValidFile}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardCV: state.profile.cvDashboard,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUploadCV: (data) => dispatch(actionsCreator.UploadCV(data)),
    onCvFiltering: (data) => dispatch(actionsCreator.cvFiltering(data)),
    getSystemLevelData: () => dispatch(actionsCreator.getSystemLevelData()),
    onGetCandidateData: (data, time) =>
    dispatch(actionsCreator.getCandidateData(data, time)),
  };
};

//export default CvUploadPanel;
export default connect(mapStateToProps, mapDispatchToProps)(CvUploadPanel);
