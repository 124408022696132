import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionCreater from "../../store/actions/index";
import objectChecker from "../../hoc/objectChecker";
import classes from "./UserData.module.scss";

class UserData extends Component {
  constructor(props) {
    super(props);
    this.state={
      
    }
  }
  render() {  
    var heading = ""
    var description = ""
    var startDate = ""
    var endDate = ""
    if (this.props.campaignData !== null){
      var heading_1 = objectChecker(this.props.campaignData.campaign, ['0'])
      var heading_data = objectChecker(heading_1, ['0'])
      heading = objectChecker(heading_data, ['heading'])
      description = objectChecker(heading_data, ['description'])
      startDate = objectChecker(heading_data, ['startDate'])
      endDate = objectChecker(heading_data, ['endDate'])
    }   

    return (  
      <div className={classes.campaignInformationWrapper}>
        <div className={classes.campaignInformation}>

          {this.props.campaignData!=null?
            this.props.campaignData.campaign === null?
            <div>Loading campaign</div>:
            <div>
              <h3 style={{color:"white", textAlign:'left', margin:'0'}}>{heading}</h3>
              <div className={classes.description}>
                {/* {description} */}
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
              {/* <div className={classes.buttonWrapper}>
                <button>View campaign</button>
              </div> */}
            </div>
            :null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    campaignData: state.CandidateData.campaignData
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetCampaignData: (data,ref) => dispatch(actionCreater.getCampaignData(data,ref))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserData);
