import React, { Component } from "react";
import Chat from "twilio-chat";
import axios from "../../../axios";
import Classes from "./ChatApp.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinnerGrey from "../../UI/SpinnerGrey/SpinnerGrey";
import { faSeedling, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import ManagerPic from "../../../assets/image/manager.png";
import objectChecker from "../../../hoc/objectChecker";
import { connect } from "react-redux";
import * as actionCreater from "../../../store/actions/index";

class ChatApp extends Component {
  constructor(props) {
    super(props);
    const name = localStorage.getItem("name") || "";
    this.state = {
      token1: localStorage.getItem("chat-token"),
      name,
      loggedIn: true,
      token: "",
      chatReady: false,
      messages: [],
      newMessage: "",
      URL: process.env.REACT_APP_API_URL + "manager",
      interviewID: 1,
      channelName: null,
      messageSender: false,
      userName: "",
      profilePicUrl: "",
      userMessage: "",
      ref_id: "",
      fullName: "",
      mesgLengthCount: 0,
      channelUnique:process.env.REACT_APP_CHANEL_NAME
    };
  }

  componentDidMount = () => {
    this.getUser();
    this.getDifUser();
    this.getChannelName();
    this.getToken();
  };

  onNameChanged = (event) => {
    this.setState({ name: event.target.value });
  };

  getChannelName = () => {
    var ref_id_auth = localStorage.getItem("ref_id");
    console.log(this.props.appliedId, "Applied id for chat");
    if (ref_id_auth == "") {
      ref_id_auth = "null";
      this.setState({
        ref_id: ref_id_auth,
      });
    }
    // console.log(ref_id_auth, "Ref id")
    if (ref_id_auth != "null") {
      // console.log("Not null")
      axios
        .get(this.state.URL + "/interviews/", {
          headers: {
            Authorization: `token ${this.state.token1}`,
          },
        })
        .then((res) => {
          var row = res.data.results.find((ref) => ref.ref == ref_id_auth);
          //var chaName = "interview_"+row["id"]
          var chaName = this.state.channelUnique+"siaInterview_" + this.props.appliedId;
          chaName = JSON.stringify(chaName);
          console.log(chaName, "Channel name");
          this.setState({
            channelName: chaName,
            interviewID: chaName,
            ref_id: ref_id_auth,
          });
        });
    } else if (ref_id_auth == "null") {
      var chanName = this.state.channelUnique+"siaInterview_" + this.props.appliedId;
      this.setState({
        channelName: chanName,
      });
    }
  };

  getToken = () => {
    var defaultId = 1;
    axios
      .post(
        this.state.URL + "/chat/",
        {
          interview_id: defaultId,
        },
        {
          headers: {
            Authorization: `Token ${this.state.token1}`,
          },
        }
      )
      .then((res1) => {
        this.setState({ token: res1.data.twilio_token }, this.initChat);
      });
  };

  initChat = () => {
    this.chatClient = new Chat(this.state.token);
    this.chatClient.initialize().then(this.clientInitiated.bind(this));
  };

  clientInitiated = () => {
    console.log("Client initialized interview chat");
    console.log(this.props.appliedId, "Channel interview id");
    var channelName = this.state.channelUnique+"siaInterview_" + this.props.appliedId;
    console.log(channelName, "Channel chat");
    this.setState({ chatReady: true }, () => {
      this.chatClient
        .getChannelByUniqueName(channelName)
        .then((channel) => {
          if (channel) {
            return (this.channel = channel);
          }
        })
        .catch((err) => {
          if (err.body.code === 50300) {
            return this.chatClient.createChannel({
              uniqueName: channelName,
            });
          }
        })
        .then((channel) => {
          this.channel = channel;
          //window.channel = channel;
          return this.channel.join().catch(() => {});
        })
        .then(() => {
          this.channel.getMessages().then(this.messagesLoaded);
          this.channel.on("messageAdded", this.messageAdded);
        });
    });
  };

  messagesLoaded = (messagePage) => {
    this.setState({
      messages: messagePage.items,
      mesgLengthCount: messagePage.items.length,
    });
  };

  messageAdded = (message) => {
    this.setState((prevState, props) => ({
      messages: [...prevState.messages, message],
    }));
  };

  onMessageChanged = (event) => {
    this.setState({
      newMessage:
        event.target.value +
        "||_||" +
        this.state.profilePicUrl +
        "||_||" +
        this.state.fullName,
      userMessage: event.target.value,
    });
  };

  saveMessageDb = (msg) => {
    // console.log(msg, "Message")
    // console.log(this.message.body, "Body")
    // console.log(this.message.author, "Author")
    // axios.post(this.state.URL+'/chat-messages/', {
    //   in_id: this.state.interviewID,
    //   message: msg,
    //   author: 'test'
    //   //data_time: msg.tim
    // },
    //   {
    //     headers: {
    //       Authorization: `Token ${this.state.token1}`
    //     }
    //   })
  };

  newMessagePopup = () => {
    this.props.notiy();
  };

  sendMessage = (event) => {
    event.preventDefault();

    console.log(event.code,  "key code")

    const message = this.state.newMessage;

    if (message.length > 0) {
      this.setState({
        newMessage: "",
        messageSender: true,
        userMessage: "",
      });
      this.channel.sendMessage(message);
      this.saveMessageDb(message);
      // this.newMessagePopup()

      var data= {        
          notification: this.state.userMessage,
          campaignId :this.props.intwData.InterviewData.campaignId,
          interviewId:this.state.interviewID,
          userId: localStorage.getItem('CandidateProfile_id02'),
          status : 0,
          roomType: "INTERVIEW"
      }      
      this.props.getNotification(data)
      this.sendChatNotification()
    }
  };
  sendChatNotification = () => {
    var data ={
      message: this.state.userMessage,
      type: 2,
      senderId :parseInt(localStorage.getItem('CandidateProfile_id02')),
      channelName: this.state.channelName
    }
    axios
      .post("send-chat-msg-notify/",data, {
        headers: {
          Authorization: `token ${this.state.token1}`,
        },
      })
      .then((res) => {

      });
  };

  newMessageAdded = (li) => {
    if (li) {
      li.scrollIntoView();
    }
    this.setState({
      messageSender: false,
    });
    if (this.state.messages.length != this.state.mesgLengthCount) {
      this.newMessagePopup();
    }
  };

  getUser = () => {
    axios
      .get(this.state.URL + "/users/me/", {
        headers: {
          Authorization: `token ${this.state.token1}`,
        },
      })
      .then((res1) => {
        //  console.log(res1.data, "Auth data")
        var picUrl = res1.data["candidate_profile"]["picture_url"];
        var fullName_user =
          res1.data["candidate_profile"]["first_name"] +
          " " +
          res1.data["candidate_profile"]["last_name"];
        this.setState({
          userName: res1.data.email,
          profilePicUrl: picUrl,
          fullName: fullName_user,
        });
      });
  };

  getDifUser = () => {
    // console.log("get dif users")
    axios
      .get(this.state.URL + "/users/", {
        headers: {
          Authorization: `token ${this.state.token1}`,
        },
      })
      .then((res) => {
        // console.log(res.data, "Users")
      });
  };

  timeDifference = (previous) => {
    var newDate = new Date();
    var current = new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      newDate.getDate(),
      newDate.getHours(),
      newDate.getMinutes(),
      newDate.getSeconds(),
      newDate.getMilliseconds()
    );
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + " days ago";
    } else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + " months ago";
    } else {
      return Math.round(elapsed / msPerYear) + " years ago";
    }
  };
  getTime = (DTime) => {
    var timeDate = new Date(DTime);
    var localDate =
      timeDate.getFullYear() +
      "-" +
      (timeDate.getMonth() + 1) +
      "-" +
      timeDate.getDate();
    var localTime =
      timeDate.getHours() +
      ":" +
      timeDate.getMinutes() +
      ":" +
      timeDate.getSeconds();

    var date = new Date(
      timeDate.getFullYear(),
      timeDate.getMonth() + 1,
      timeDate.getDate(),
      timeDate.getHours(),
      timeDate.getMinutes(),
      timeDate.getSeconds(),
      0
    );
    return this.timeDifference(date);
  };

  render() {
    var comMessage = "";
    const messages = this.state.messages.map((message) => {
      var user = this.state.userName.split("@")[0]; 
      var messageBody = message.body.split("||_||")[0];
      let ProfUrl = message.body.split("||_||")[1];
      let authorName = message.body.split("||_||")[2];

      var msgTime = this.getTime(message.timestamp);
      var name = message.author;

      let scssClass = "";
      let imgClass = "";
      let textClass = "";
      let picUrl = "";
      let messageAuthor = "";

      if (ProfUrl == "Manager") {
        // console.log("Manager message")
        picUrl = ManagerPic;
      } else picUrl = ProfUrl;

      if (JSON.stringify(user) != JSON.stringify(message.author)) {
        scssClass = Classes.Wrapper;
        imgClass = Classes.ImgUser;
        textClass = Classes.TextClass;
        messageAuthor = authorName;
        //message.author
        name = authorName;
      } else if (JSON.stringify(user) == JSON.stringify(message.author)) {
        scssClass = Classes.WrapperSender;
        imgClass = Classes.ImgNewUser;
        textClass = Classes.TextClassLeft;
        messageAuthor = authorName;
        name = "";
      }

      return (
        <div className={Classes.RowStyle}>
          <table className={scssClass}>
            <tr>
              <td>
                <div className={imgClass}>
                  <img src={picUrl}></img>
                </div>
              </td>
              {/* <td>
                <div className={textClass}>
                  <ul key={message.sid} ref={this.newMessageAdded}>
                    <li className={Classes.UserListAuthor}>{name}</li>
                    <li>{messageBody}</li>
                    <li className={Classes.UserListAuthor}>{msgTime}</li>
                  </ul>
                </div>
              </td> */}
              <td>
                <div className={Classes.UserListAuthor}>{messageAuthor}</div>
                <div className={textClass}>
                  <ul key={message.sid} ref={this.newMessageAdded}>
                    <li>{messageBody}</li>
                    <li className={Classes.UserListAuthorTime}>{msgTime}</li>
                  </ul>
                </div>
              </td>
            </tr>
          </table>
        </div>
      );
    });
    if (this.state.ref_id == "null") {
      comMessage = "You have no interviews";
    } else if (this.state.ref_id != "null") {
      comMessage = messages;
    }
    return (
      <div className={Classes.SendInput}>
        <div className={Classes.ScroleControll}>
          {this.state.chatReady === false && (
            <div className={Classes.SpinnerOuterWrap}>
              <div className={Classes.SpinnerWrap}>
                <SpinnerGrey />
              </div>
            </div>
          )}
          <div className={Classes.ScroleStyle}>
            <ul>
              <li>{comMessage}</li>
            </ul>
          </div>
        </div>
        {this.state.chatReady === true ? (
          <form className={Classes.TypeMessage} onSubmit={this.sendMessage} style={{color:"#020202"}}>
            {/* <label htmlFor="message"></label> */}
            <input
              type="text"
              name="message"
              id="message"
              placeholder="Type your message"
              onChange={this.onMessageChanged}
              value={this.state.userMessage}
              autoComplete="off"
            />
            <button>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </form>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //intwData: state.CandidateData.interviewData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //   onGetNotification: () => dispatch(actionsCreater.getNotification())
    getNotification :(data)=> dispatch(actionCreater.getChatNotification(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatApp);
