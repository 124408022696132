import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import Modal from "react-modal";
import ChatCV from "../../../../../components/UI/ChatModal/ChatCV/ChatCVByAddDashBoard";
// import ChatVideo from "../../Modals/ChatvideoNearby";
import ChatVideo from "../../Modals/ChatVideoAddedByDashBoard";
//import CoverLetter from "../../Modals/CoverLetterNearby";
import CoverLetter from "../../Modals/CoverLetterAddedDashBoardByStaff";
import { Button, Card, Image, Icon } from "semantic-ui-react";
import axios from "../../../../../axios";
import * as actionsCreator from "../../../../../store/actions/index";
import Spinner from "../../../../UI/Spinner/Spinner";
import Swal from "sweetalert2";
import classes from "./FlowControl.module.scss";
import moment from "moment";
import defaultCompany from "../../../../../assets/image/sia-in-box.png";
import Progress from "../../../../UI/ChatModal/ChatCV/ChatCVProgress/Progress";
import description from "material-ui/svg-icons/action/description";
import { colors } from "material-ui/styles";
import defaultCamImage from "../../../../../assets/image/sia_default.png";
import logo from  "../../../../../assets/image/sia-logo.png";
import classesSplash from '../../../../UI/Splash/Splash.module.scss';

class FlowControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: false,
      isOpen: true,
      cvUpload: false,
      videoUpload: false,
      coverLetter: false,
      campaign: true,
      isApplyBtn: false,
      text: "aa",
      campaignName:"",
      campaignImage:"",
      currentNum:0,
      campaignId:0,
      campaignStartDate:"",
      company:"",
      campaignDes:"",
      location:"",
      companyLogo:"",
      isPublish:true,
      percentage:0,
      isApiCallSuccess : false,
      alreadyApplied: 0
    };
  }

  componentDidMount(){
    if(this.props.share==true){
     this.setState({ campaign: false });
     this.timer = setInterval(() => this.ApplyCampign(), 1000);
     //this.ApplyCampign()    
    }
  }
  componentWillMount(){
    this.loadCampaginData()
    // if (this.props.share === true){
    //   this.handleNoOptionsFeature()
    // }

  }
  closeModal = () => {
    console.log("close modal 3333")
    //localStorage.removeItem("APPLYMODALCLOSED")
    this.setState({ isOpen: false });
    this.props.closeCampaign();
    //this.props.onSetCloseModelValue(true)
  };
  handleTextArea = (e) => {
    this.setState({
      text: e.target.value,
    });
  };
  handleApplyCVModalClose = () => {
    this.setState({ isOpen: false });
    this.props.closeCampaign();
  };

  ApplyCampign = () => {

    localStorage.setItem("APPLYDASH", true)

    this.setState({ isApplyBtn: true });

    if (this.props.profile !== null){
      if (this.state.isApiCallSuccess === false)
      {
        var data = {
          candidateid: this.props.profile.id,
          campaignId: this.state.campaignId,
          ref: "",
          reminderDuration: 0,
          accessed: 0,
          status: "APPLIED",
          applied_method: "SIA",
        };
        var token = localStorage.getItem("chat-token");
        this.props.onSaveApplyData(data);
        axios
          .post(process.env.REACT_APP_API_URL + "chat/apply-stand-by/", data, {
            headers: {
              Authorization: `token ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            axios
              .get(
                "get-applied-campaign-fetures/?campaignId=" +
                  this.state.campaignId,
                {
                  headers: {
                    Authorization: `token ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                this.SetFeatures(res.data.Data);
                this.props.onSetCampaignFeatures(
                  res.data.Data.cvUpload,
                  res.data.Data.videoPitch,
                  res.data.Data.coverLetter
                );
                localStorage.setItem(
                  "CAMPAIGN_APPLIED_ID",
                  this.state.campaignId
                );
                this.setState({
                  isApiCallSuccess : true
                })

                if (this.props.share === true){
                  if (res.data.Data.cvUpload == false && res.data.Data.videoPitch === false && res.data.Data.coverLetter === false){
                    this.handleNoOptionsFeature()
                  }
                  else{
                    this.checkAlreadyApplied()
                  }
                }

              });
          })
          .catch((resCatch) => {});
        }
    }
    else{

    }
  };

  checkAlreadyApplied=()=>{
    var token = localStorage.getItem("chat-token");
    axios.get("is-apply-campaign-candidate/?campaignId="+this.state.campaignId +"&candidateId="+this.props.profile.id,{
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res)=>{
      
      if (parseInt(res.data.applied) === 0){

        // Swal.fire({
        //   toast: true,
        //   position: "top-end",
        //   title: "Already applied",
        //   showConfirmButton: false,
        //   timer: 2500,
        //   customClass: { container: "swal-success-settings" },
        //   imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        //   imageHeight: 20,
        // });

        // sessionStorage.removeItem("PROCEEDCLICKED");
        // sessionStorage.removeItem("CAMPINVITE");
        // window.location.replace("/")
        this.setState({
          alreadyApplied: 1
        })
      }
      else{
        this.setState({
          alreadyApplied: res.data.applied
        })
      }
    })
  }

  SetFeatures = (data) => {

    //this.setState({cvUpload:data.cvUpload, videoUpload:data.videoPitch, coverLetter: data.coverLetter})

    this.setState({ campaign: false, isApplyBtn: false });

    if (data.cvUpload == true && data.coverLetter == true && data.videoPitch == true) {
      this.setState({
        campaign: false,
        cvUpload: true,
        coverLetter: false,
        videoUpload: false,
      });
    }
    if (data.cvUpload == false && data.coverLetter == true && data.videoPitch == true
    ) {
      this.setState({
        cvUpload: false,
        coverLetter: true,
        videoUpload: false,
      });
    }
    if (data.cvUpload == true && data.coverLetter == true && data.videoPitch == false
      ) {
        this.setState({
          cvUpload: true,
          coverLetter: false,
          videoUpload: false,
        });
      }
    if (data.cvUpload == false && data.videoPitch == false && data.coverLetter == true) {
      this.setState({
        cvUpload: false,
        coverLetter: true,
        videoUpload: false,
      });
    }
    if (data.cvUpload == false && data.videoPitch == true && data.coverLetter == true) {
      this.setState({
        cvUpload: false,
        coverLetter: true,
        videoUpload: false,
      });
    }
    if (data.coverLetter == false){
      if(data.cvUpload == false && data.videoPitch == false){
        this.setState({
          cvUpload: false,
          coverLetter: false,
          videoUpload: false
        });
      }
      if(data.cvUpload == true && data.videoPitch == false){
        this.setState({
          cvUpload: true,
          coverLetter: false,
          videoUpload: false
        });
      }
      if(data.cvUpload == false && data.videoPitch == true){
        this.setState({
          cvUpload: false,
          coverLetter: false,
          videoUpload: true
        });
      }
      //worst case scenario cv/videopitch/coverletter = false 
      if(data.cvUpload == false && data.videoPitch == false){
        this.setState({
          cvUpload: false,
          coverLetter: false,
          videoUpload: false,
          campaign :true,
          isApplyBtn: true
        });

        var userId = localStorage.getItem("CandidateProfile_id02")

        var data = {
          campRef: sessionStorage.getItem("CAMPINVITE"),
          userId: userId
        }

      //uploadCv step
      axios.post('update-candidate-added-by-staff/',data, {
          headers: {
              Authorization: `Token ${localStorage.getItem('chat-token')}`
            }
      }).then(res=>{
          console.log(res,  "Results")
          if (res.data.Data.flag === true){
              Swal.fire({
                  toast: true,
                  position: "top-end",
                  title: "Process Completed",
                  showConfirmButton: false,
                  timer: 2500,
                  customClass: { container: "swal-success-settings" },
                  imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
                  imageHeight: 20,
              });
              sessionStorage.removeItem("PROCEEDCLICKED");
              sessionStorage.removeItem("CAMPINVITE");
              window.location.replace("/")
          }
      })


        // axios.post('apply-campaign-by-candidate/', this.props.appliedSaveData, {
        //   headers: {
        //     Authorization: `Token ${localStorage.getItem('chat-token')}`
        //   }
        // }).then(appliedRes => {
        //   var currentTime = moment.utc().toISOString()
        //   var status = "COMPLETED"
        //   axios.get('update-standBy-process/?candidateId=' + this.props.appliedSaveData.candidateid + "&campaignId=" + this.props.appliedSaveData.campaignId + "&step=" + status, {
        //     headers: {
        //       Authorization: `Token ${localStorage.getItem('chat-token')}`
        //     }
        //   }).then(resUpdates => {
        //     Swal.fire({
        //       toast: true,
        //       position: "top-end",
        //       title: "Apply process completed successfully",
        //       showConfirmButton: false,
        //       timer: 2500,
        //       customClass: { container: "swal-success-settings" },
        //       imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        //       imageHeight: 20,
        //     });
        //     this.setState({
        //       isApplyBtn: false
        //     })
        //     //this.props.onCVLProgress(false);
        //     sessionStorage.removeItem("shareRef");
        //     localStorage.removeItem("shareRef");
        //     sessionStorage.removeItem("CAMPINVITE");
        //     window.location.replace("/")
        //   })
        // })

      }
      if(data.cvUpload == true && data.videoPitch == true){
        this.setState({
          cvUpload: true,
          coverLetter: false,
          videoUpload: false
        });
      }
    }
  };
  confirmCvUpload = () => {

    if (this.props.originalCVStatus === true) {
      var status = "cv";
      this.props.onUpdateApplyState(this.props.appliedSaveData, status);
      localStorage.setItem("OpenSmartCV", false);
      var features = this.props.flowFeatures;
      if (features.coverLetter == true && features.video == true) {
        this.setState({
          campaign: false,
          cvUpload: false,
          coverLetter: true,
          videoUpload: false,
        });
      }
      if (features.coverLetter == true && features.video == false) {
        this.setState({
          campaign: false,
          cvUpload: false,
          coverLetter: true,
          videoUpload: false,
        });
      }

      if (features.coverLetter == false){
        if (features.video == false){
          this.setState({
            campaign: false,
            cvUpload: false,
            coverLetter: false,
            videoUpload: false,
          });

          // axios.post('apply-campaign-by-candidate/', this.props.appliedSaveData, {
          //   headers: {
          //     Authorization: `Token ${localStorage.getItem('chat-token')}`
          //   }
          // }).then(appliedRes => {
          //   var currentTime = moment.utc().toISOString()
          //   var status = "COMPLETED"
          //   axios.get('update-standBy-process/?candidateId=' + this.props.appliedSaveData.candidateid + "&campaignId=" + this.props.appliedSaveData.campaignId + "&step=" + status, {
          //     headers: {
          //       Authorization: `Token ${localStorage.getItem('chat-token')}`
          //     }
          //   }).then(resUpdates => {
          //     Swal.fire({
          //       toast: true,
          //       position: "top-end",
          //       title: "Apply process completed successfully",
          //       showConfirmButton: false,
          //       timer: 2500,
          //       customClass: { container: "swal-success-settings" },
          //       imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          //       imageHeight: 20,
          //     });
          //     //this.props.onCVLProgress(false);
          //     sessionStorage.removeItem("shareRef");
          //     localStorage.removeItem("shareRef");
          //     localStorage.removeItem("APPLYSHARECLICKED");
          //     window.location.replace("/")
          //   })
          // })

        }
        if (features.video == true){
          this.setState({
            campaign: false,
            cvUpload: false,
            coverLetter: false,
            videoUpload: true,
          });
        } 
      }

      console.log(this.props.skills, "Skills")
      console.log(this.props.cvDataEdu, "education")
      console.log(this.props.cvDataExp, "Exp")

      var data = {
        skills: this.props.skills ? this.props.skills.toString() : "",
        education: this.props.cvDataEdu == null ? [] :  this.props.cvDataEdu,
        experience: this.props.cvDataExp  == null ? [] : this.props.cvDataExp,
        onBoard_state : "UPDATE_SETTINGS_APPLY",
        upload : "",
        format_type : "",
        first_name : "sample",
        last_name  : "sample",
        career_preferences : ""
      };

      // phone_number: this.props.phoneNumber,
      // timeZone: zone[0],
      // current_location: lc[0],
      // onBoard_state: "COMPLETE",
      // uploaded_at: "2019-09-20T00:00:00Z",
      // upload: "",
      // userId: userID

      var id = this.props.profileCandidate.userData.profile[0].id;
      this.props.onUpdateCVProfileData(data, id)

    } else if (this.props.originalCVStatus === false) {
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Please upload your original CV",
        showConfirmButton: false,
        timer: 2500,
        customClass: { container: "swal-success-settings" },
        imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        imageHeight: 20,
      });
    }
  };
  uploadCoverLetter = () => {
    var features = this.props.flowFeatures;
    if (features.video == true) {
      this.setState({
        campaign: false,
        cvUpload: false,
        coverLetter: false,
        videoUpload: true,
      });
      this.props.onCVLProgress(false);
    } else {
      this.setState({ isOpen: false });
      this.props.getAvailableCampaignType(this.props.profile.id);
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Apply process completed successfully",
        showConfirmButton: false,
        timer: 2500,
        customClass: { container: "swal-success-settings" },
        imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        imageHeight: 20,
      });
      this.props.onCVLProgress(false);
      sessionStorage.removeItem("shareRef");
      localStorage.removeItem("shareRef");
      localStorage.removeItem("APPLYSHARECLICKED");
      //window.location.replace("/")
    }
  };
  handleNextButton = () => {
    if (this.state.currentNum + 1 < this.props.compaigns.length) {
      var data = this.props.compaigns[this.state.currentNum + 1]
      this.setState({
        campaignName: data.campaign.heading,
        campaignImage: data.campaign.campaignImage,
        campaignId: data.campaign.id,
        currentNum: this.state.currentNum + 1,
        campaignStartDate:data.campaign.startDate,
        company: data.company.name,
        location:data.campaign.location,
        campaignDes:data.campaign.description,
        companyLogo:data.company.companyLogo,
        isPublish:data.campaign.isPublish
    })
  }
  }

  handlePrevButton = () => {
    if (this.state.currentNum > 0) {
      var data = this.props.compaigns[this.state.currentNum - 1]
      this.setState({
        campaignName: data.campaign.heading,
        campaignImage: data.campaign.campaignImage,
        campaignId: data.campaign.id,
        currentNum: this.state.currentNum - 1,
        campaignStartDate:data.campaign.startDate,
        company: data.company.name,
        location:data.campaign.location,
        campaignDes:data.campaign.description,
        companyLogo:data.company.companyLogo,
        isPublish:data.campaign.isPublish
      })
    }
  }

  loadCampaginData = () => {

    console.log(this.props.campaign, "loading campaign data")
    console.log(this.props.compaigns, "loading campaigns")

    console.log("load campaign")

    var companyName = null
    var companyLogoPre = null

    // if (localStorage.getItem("APPLYSHARECLICKED")=== "true"){
    //   companyName = this.props.compaigns[this.props.index].Company.CompanyName
    //   companyLogoPre = this.props.compaigns[this.props.index].Company.logo
    // } 
    // else{
    //   companyName = this.props.compaigns[this.props.index].company.name
    //   companyLogoPre = this.props.compaigns[this.props.index].company.companyLogo
    // }

    if (this.props.share === true){
      companyName = this.props.compaigns[this.props.index].Company.CompanyName
      companyLogoPre = this.props.compaigns[this.props.index].Company.logo
    }
    else{
      companyName = this.props.compaigns[this.props.index].company.name
      companyLogoPre = this.props.compaigns[this.props.index].company.companyLogo
    }

    this.setState({
      campaignName: this.props.campaign.heading,
      campaignImage: this.props.campaign.campaignImage,
      campaignId: this.props.campaign.id,
      currentNum: this.props.index,
      campaignStartDate: this.props.campaign.startDate,
      //company: this.props.compaigns[this.props.index].company.name,
      company: companyName,
      location: this.props.campaign.location,
      campaignDes: this.props.campaign.description,
      //companyLogo:this.props.compaigns[this.props.index].company.companyLogo,
      companyLogo:companyLogoPre,
      isPublish:this.props.campaign.isPublish
    })
  }
  percentage=(value)=>{
    this.setState({percentage:value})
  }

  handleNoOptionsFeature=()=>{
    console.log('apply with out options')
    var data = {
      candidateid: this.props.profile.id,
      campaignId: this.state.campaignId,
      ref: "",
      reminderDuration: 0,
      accessed: 0,
      status: "APPLIED",
      applied_method: "SIA",
    };

    var token = localStorage.getItem("chat-token");

    axios.post(process.env.REACT_APP_API_URL + "chat/apply-stand-by/", data,{
        headers: {
          Authorization: `token ${token}`,
          "Content-Type": "application/json",
        },
    }).then((resA)=>{
      axios.post('apply-campaign-by-candidate/', data, {
        headers: {
          Authorization: `Token ${localStorage.getItem('chat-token')}`
        }
      }).then((res)=>{
        var status = "COMPLETED"
        axios.get('update-standBy-process/?candidateId=' + this.props.profile.id + "&campaignId=" + this.state.campaignId + "&step=" + status,{
          headers: {
            Authorization: `Token ${localStorage.getItem('chat-token')}`
          }
        }).then((resUpdates)=>{
            Swal.fire({
              toast: true,
              position: "top-end",
              title: "Apply process completed successfully",
              showConfirmButton: false,
              timer: 2500,
              customClass: { container: "swal-success-settings" },
              imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
              imageHeight: 20,
            });
            sessionStorage.removeItem("shareRef");
            localStorage.removeItem("shareRef");
            localStorage.removeItem("APPLYSHARECLICKED");
            window.location.replace("/")
        })
      }).catch((error)=>{
        Swal.fire({
          toast: true,
          position: "top-end",
          title: "Already applied",
          showConfirmButton: false,
          timer: 2500,
          customClass: { container: "swal-success-settings" },
          imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          imageHeight: 20,
        });
        sessionStorage.removeItem("shareRef");
        localStorage.removeItem("shareRef");
        localStorage.removeItem("APPLYSHARECLICKED");
        window.location.replace("/")
      })
    })

    // axios.post('apply-campaign-by-candidate/', data, {
    //   headers: {
    //     Authorization: `Token ${localStorage.getItem('chat-token')}`
    //   }
    // }).then((res)=>{

    // }).catch((error)=>{
    //   sessionStorage.removeItem("shareRef");
    //   localStorage.removeItem("shareRef");
    //   localStorage.removeItem("APPLYSHARECLICKED");
    //   window.location.replace("/")
    // })
   
    // axios
    //   .post(process.env.REACT_APP_API_URL + "chat/apply-stand-by/", data, {
    //     headers: {
    //       Authorization: `token ${token}`,
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     axios.post('apply-campaign-by-candidate/', data, {
    //       headers: {
    //         Authorization: `Token ${localStorage.getItem('chat-token')}`
    //       }
    //     }).then(appliedRes => {
    //       var currentTime = moment.utc().toISOString()
    //       var status = "COMPLETED"
    //       axios.get('update-standBy-process/?candidateId=' + this.props.appliedSaveData.candidateid + "&campaignId=" + this.props.appliedSaveData.campaignId + "&step=" + status, {
    //         headers: {
    //           Authorization: `Token ${localStorage.getItem('chat-token')}`
    //         }
    //       }).then(resUpdates => {
    //         Swal.fire({
    //           toast: true,
    //           position: "top-end",
    //           title: "Apply process completed successfully",
    //           showConfirmButton: false,
    //           timer: 2500,
    //           customClass: { container: "swal-success-settings" },
    //           imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
    //           imageHeight: 20,
    //         });
    //         // this.setState({
    //         //   isApplyBtn: false
    //         // })
    //         //this.props.onCVLProgress(false);
    //         sessionStorage.removeItem("shareRef");
    //         localStorage.removeItem("shareRef");
    //         localStorage.removeItem("APPLYSHARECLICKED");
    //         window.location.replace("/")
    //       })
    //     }).catch((errorApply)=>{
    //       console.log(errorApply, "Resultscatch")
    //       if(errorApply.response.status === 400){
    //         sessionStorage.removeItem("shareRef");
    //         localStorage.removeItem("shareRef");
    //         localStorage.removeItem("APPLYSHARECLICKED");
    //         window.location.replace("/")
    //       }
    //     })
    //   })
    //   .catch((resCatch) => {
    //     //console.log(resCatch, "Resultscatch")
    //   });
  }

  render() {

    if (this.state.videoUpload==true){
      localStorage.setItem('VIDEOUPLOAD', true)
    }
    else if(this.state.videoUpload ==  false){
      localStorage.setItem('VIDEOUPLOAD', false)
    }

    if (this.props.originalCVStatus !== true) {
      this.props.onGetOriginalCVState();
    }
    var modal = "";
      
    if (this.state.campaign == true){
      if (this.props.share === true){
        modal = (
          <div className={classes.Splash}>
            <img src={logo} alt="Sia bot" />  
            <div className={classesSplash.Loading}>Loading...</div>
          </div>
        )
      }
      else {
        modal = (
          <div>

            <button
              className={classes.fixedClose}
              onClick={this.closeModal}
            >
              <i className="fas fa-times"></i>
            </button>  

            <div className="each-campaign-wrapper">
            <span className={classes.back_btn}  onClick={this.handlePrevButton}>{"<"}</span>
              <div className="each-campaign">
                <div className="images">
                  <img
                    className="background"
                    src={
                      this.state.campaignImage
                        ? this.state.campaignImage
                        : defaultCamImage                      
                    }
                  />
                  <img
                    className="foreground"
                    src={
                      this.state.campaignImage
                      ? this.state.campaignImage
                        : defaultCamImage
                    }
                  />
                </div>
                <div className="campaign-main-info">
                  <div className="title">
                    {this.state.campaignName}
                  </div>
                  {/* <div className="category">{this.props.data.campaignType}</div> */}
                  <div className="date">
                    {moment(this.state.campaignStartDate).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="campaign-bottom-description">
                  <div className="company">
                    <img
                      alt="Company logo"
                      src={
                          this.state.companyLogo
                          ? this.state.companyLogo
                          : defaultCompany
                      }
                    />
                    <div>{this.state.company}</div>
                  </div>
                  <div className="job-location">
                    {this.state.location}
                  </div>
                  <div className="campaign-description-text nc-min-height">
                    {/* {this.state.campaignDes} */}
                    <div dangerouslySetInnerHTML={{ __html: this.state.campaignDes }} />
                  </div>
                  <div className="nc-btn-contnet nc-btn-contnet-center">
                    {this.state.isPublish == true?
                      <button onClick={this.ApplyCampign} className="sia-btn-modal">
                        <span>{this.state.isApplyBtn ? <Spinner /> : "Apply"}</span>
                      </button>
                      :
                      <button className="sia-btn-modal" style={{cursor:"not-allowed"}}>
                        <span>Applications closed</span>
                      </button>
                    }
                  </div>
                  <div className="nc-btn-contnet">
                    <div className="outer-text"> 
                      <button
                        onClick={this.closeModal}
                        className={classes.back_list_btn}
                      >
                        {this.props.share ?
                        "Back to share":"Back to the list"
                        }                      
                      </button>
                    </div>
                  </div>
                </div>             
              </div>
              <span className={classes.back_btn} onClick={this.handleNextButton}>{">"}</span>
            </div>    
          </div>
        );
      }
    }

    if(this.props.share === false){
      if (this.state.cvUpload == true) {
        if (this.props.progressUpload === true) {
          modal = <Progress percentage={this.state.percentage} />;
        } else if (this.props.progressUpload === false) {
          modal = (
            <ChatCV
              data={false}
              submitCV={true}
              closeCVmodal={this.handleApplyCVModalClose}
              next={this.confirmCvUpload}
              processPercentage={this.percentage}
              cvUploaded={this.props.cvUploaded}
              cvURl={this.props.cvURl}
            />
          );
        }
      }
      if (this.state.coverLetter == true) {
        if (this.props.progressUploadCVL === true) {
          modal = <Progress percentage={this.state.percentage} />;
        } else if (this.props.progressUploadCVL === false) {
          modal = (
            <CoverLetter
              uploadCover={this.uploadCoverLetter}
              closeModal={this.closeModal}
              videoUploadStatus={this.state.videoUpload} 
            />
          );
        }
      }
      if (this.state.videoUpload == true) {
        modal = <ChatVideo closeModal={this.closeModal} />;
      }
    }

    if (this.props.share === true){
      if(this.state.alreadyApplied === 1){
        if (this.state.cvUpload == true) {
          if (this.props.progressUpload === true) {
            modal = <Progress percentage={this.state.percentage} />;
          } else if (this.props.progressUpload === false) {
            modal = (
              <ChatCV
                data={false}
                submitCV={true}
                closeCVmodal={this.handleApplyCVModalClose}
                next={this.confirmCvUpload}
                processPercentage={this.percentage}
                cvUploaded={this.props.cvUploaded}
                cvURl={this.props.cvURl}
              />
            );
          }
        }
        if (this.state.coverLetter == true) {
          if (this.props.progressUploadCVL === true) {
            modal = <Progress percentage={this.state.percentage} />;
          } else if (this.props.progressUploadCVL === false) {
            modal = (
              <CoverLetter
                uploadCover={this.uploadCoverLetter}
                closeModal={this.closeModal}
                videoUploadStatus={this.state.videoUpload} 
              />
            );
          }
        }
        if (this.state.videoUpload == true) {
          modal = <ChatVideo closeModal={this.closeModal} />;
        }
      }

      else if(this.state.alreadyApplied === 0){
        modal = (
          <div className={classes.Splash}>
            <img src={logo} alt="Sia bot" />  
            <div className={classesSplash.Loading}>Loading...</div>
          </div>
        )
      }
    }

    return (
      <div
        className="upload-review-cv-modal"
        style={{
          textAlign: "center !important",
          textAlign: "-webkit-center !important",
        }}
      >
        <Modal
          isOpen={this.state.isOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
          className="cv-upload-modal-1 over"
          ariaHideApp={false}
        >
          <div className={
                this.state.cvUpload
                ? classes.borderedInnerWrap
                : classes.nonBorderedInnnerWrap
          }>         
          {modal}
            {/* <div className={classes.closeBtnWrapDiv}>
              <div className={classes.closeBtnDiv}>
                {this.state.cvUpload ? (
                  <button
                    className={classes.closeButtton}
                    onClick={this.confirmCvUpload}
                  >
                    Submit
                  </button>
                ) : null}
              </div>
            </div> */}
          </div>

          {/* <div className="campaign-apply-button">
                        {this.state.cvUpload ?
                            <button onClick={this.confirmCvUpload}>Submit</button> :
                            null
                        }
                    </div> */}
         
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignFeatures: state.CampaignByRef.campaignFeatures,
    profile: state.profile.userProfile,
    flowFeatures: state.CampaignByRef.campaignFlow,
    originalCVStatus: state.CandidateData.originaCVStatus,
    appliedSaveData: state.chatnew.appliedCampaignTemp,
    progressUpload: state.chatnew.uploadProgress,
    progressUploadCVL: state.chatnew.cvlProgress,
    cvDataExp : state.auth.cvDataExp,
    cvDataEdu : state.auth.cvDataEdu,
    skills : state.auth.skills,
    profileCandidate: state.CandidateData.userData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAvailableCampaignType: (data) =>
      dispatch(actions.getAvailableCampaignSlider(data)),
    getCampaignFeatures: (data) =>
      dispatch(actionsCreator.getCampaignFeatures(data)),
    onSaveApplyData: (data) => dispatch(actionsCreator.saveApplyData(data)),
    onSetCampaignFeatures: (cvUpload, videoPitch, coverLetter) =>
      dispatch(
        actionsCreator.SetCampaignFeatures(cvUpload, videoPitch, coverLetter)
      ),
    onUpdateApplyState: (data, status) =>
      dispatch(actionsCreator.updateApplySaveData(data, status)),
    onGetOriginalCVState: () => dispatch(actionsCreator.getoriginlCVStatus()),
    onUpdateCVProfileData : (data,id) => dispatch(actionsCreator.UpdateCVProfile(data,id)),
    onCVLProgress: (data) => dispatch(actionsCreator.CVLProgress(data)),
    onSetCloseModelValue : (data)=>dispatch(actionsCreator.CloseModalValue(data))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FlowControl)
);
