import React, { Component } from "react";
import axios from "../../axios";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import classes from "./Webinnar.module.scss";
import Timer from "../Webinnar/CloclTime/Time";
import ReactPlayer from "react-player";
import "./WebinnarPrew.scss";
import Jitsi from 'react-jitsi'
import Pusher from 'pusher-js';

class webinnar extends Component {
  constructor(props) {
    super();
    this.state = {
      token1: localStorage.getItem("chat-token"),
      channel: "",
      URL: process.env.REACT_APP_API_URL + "manager",
      ref_id: localStorage.getItem("ref_id"),
      videoURL: "https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1",
      videoFlag: localStorage.getItem("videoFlag"),
      collection2: [],
      collection: [],
      singleVid: false,
      singleBase64: false,
      URL: process.env.REACT_APP_API_URL + "chat",
      URLChat: process.env.REACT_APP_API_URL + "chat",
      urlStream: "https://youtu.be/tuYAvarrIs8",
      urlStreamCam: "https://youtu.be/tuYAvarrIs8",
      isLiveStream: true,
      videoIndex: 0,
    };    
    this.getLiveStreamURL = this.getLiveStreamURL.bind(this);
  }

  componentDidMount() {
    this.initializationPusherLiveStream()
    this.webinarConnetionStatus()
    this.intrtviewTime = setInterval(() => this.checkConnections(), 10000);
  }

  componentWillUnmount(){
    clearInterval(this.intrtviewTime)
    this.intrtviewTime = null;
  }

  webinarConnetionStatus = () => {
    var data = {
      interviewId: this.props.intData.InterviewData.interviewId,
      candidateId: parseInt(localStorage.getItem("CandidateProfile_id02")),
      staffId: 0,
      reference: this.props.intData.ref,
      connectStatus: 1,
      webinar: this.props.intData.webinarId,
      isPublicWebinar:0
    }
    axios
      .post("webinar-connetion-status/", data, {
        headers: {
          Authorization: `token ${this.state.token1}`,
        },
      })
      .then((res) => {
      });

  }

  initializationPusherLiveStream = () => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });  
    var channelName = this.props.intData.webinarId.toString()
    var channel = pusher.subscribe(channelName);
    channel.bind('is-message', (data) => {
      console.log(data,"dataY")
      this.props.showWebinarPusher(data.message)
      this.setState({setData:true})   
    });
  } 

  checkConnections = () => {
    var host = process.env.REACT_APP_WEB_SOCKET_URL_WEBINE_STATUS;
    var wsocket = new WebSocket(host);
    wsocket.onopen = (evt) => {
      console.log(evt, "Server connected, webinar");
      wsocket.send(JSON.stringify({
        ref: this.props.intData.ref,
        webinarId: this.props.intData.webinarId,
        status: 1,
        candidateId: parseInt(localStorage.getItem("CandidateProfile_id02"))
      }));
    };
    wsocket.onmessage = (evt) => {
    };
  }

  onPause = () => (e) => {
    e.preventDefault();
    if (this.state.collection.length > 0) {
      this.setState({ videoURL: null });
      var temp = this.state.collection[0];
      this.state.collection.splice(0, 1);
      this.setState({ videoURL: temp });
      return temp;
    } else {
      return null;
    }
  };
  getLiveStreamURL = () => {
    var campaignId = localStorage.getItem("CampaignId_ref");

    axios
      .get(this.state.URLChat + "/get-stream-url/?campaignId=" + campaignId, {
        headers: {
          Authorization: `Token ${this.state.token1}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({ isLiveStream: true });
        }
        for (var count = 0; count < res.data.length; count++) {
          if (res.data[count].streamType === "Cam")
            this.setState({ urlStreamCam: res.data[count].url });
          if (res.data[count].streamType === "Share")
            this.setState({ urlStream: res.data[count].url });
        }
      });
  };

  handleEnded = () => { 
    if (this.props.webinnar.Data.videoUrl.length-1 == this.state.videoIndex) {
      this.setState({ videoIndex: 0 });
    } else {
      this.setState({ videoIndex: this.state.videoIndex + 1 });
    }
  };
  onDashBoard =()=>{
    localStorage.removeItem("CALL_STATUS");
    localStorage.removeItem("Video_chat_complete");
    localStorage.removeItem("Open_video_conf_sia");
    //sessionStorage.removeItem("ref");
    var eventNamePrefix = "event1234"
    var eventId=localStorage.getItem("eventName")
    window.location.replace("/"+eventId);
    this.webinarConnetionStatusUpdate()
  }

  webinarConnetionStatusUpdate = () => {
    var data = {
      candidateId: parseInt(localStorage.getItem("CandidateProfile_id02")),  
      ref: this.props.intData.ref,
      status: 12,
      webinarId: this.props.intData.webinarId
    }
    var id = this.props.intData.webinarId
    axios
      .put("candidate-update-webinar-connection-status/" + id + "/", data, {
        headers: {
          Authorization: `token ${this.state.token1}`,
        },
      })
      .then((res) => {
      });
  }

  render() { 

    console.log("webianr component")

    console.log(this.props.intTime, "Interview time #######")

    var count = this.props.intTime; 
    var video = "";
    var webMessage = "Waiting for your interview, Check your remaining time to interview"
    var CLOCK_TURN = null;

    console.log(count,"Count webinar ######")

    if (count !== null) {
      if (count <= 300000) {
        CLOCK_TURN = count;
      }
    }

    if (localStorage.getItem("quickInvite") !== null) {
      webMessage = "This campaign has no webinar, Check your remaining time to interview"
    }
    if(this.props.intData.isWebinarPnl==true){
      webMessage="Playing webinar"
    }

    if (this.props.webinnar !== null) {
      //check priority of webinar
      if (this.props.webinnar.Data.priority === "PENDING") {   
        webMessage = "Webinar not started, Please wait"
      }

      else if (this.props.webinnar.Data.priority === "LIVESTREAM") { 

         video = <Jitsi
          containerStyle={{ width: '730px', height: '400px' }}
          config={{
            prejoinPageEnabled: false,
            disableDeepLinking:true,
            //  startAudioOnly: true,
            startWithAudioMuted: true,
            startWithVideoMuted: true,
            disableProfile: true,
            startScreenSharing: false
          }}
          interfaceConfig={{
            SHOW_JITSI_WATERMARK: false,
            SHOW_WATERMARK_FOR_GUESTS: false,
            filmStripOnly: true,
         // LANG_DETECTION: true,
            // TOOLBAR_BUTTONS: [
            //    'desktop', 'fullscreen',
            //   'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
            //   'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
            //   'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
            //   'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone',
            //   'e2ee', 'security'
            // ],
            TOOLBAR_BUTTONS: ['fullscreen','recording'],
    
          }} roomName={this.props.webinnar.Data.videoUrl[0].url} displayName={this.props.intData.InterviewData.fullName} />

        // if (this.props.webinnar.Data.method === "camShare") {
        //   if (this.props.webinnar.Data.stream == true) {          
        //     video = this.props.webinnar.Data.videoUrl.map((res, index) => {
        //       if (res.type === "Cam") {
        //         return (
        //           <ReactPlayer
        //             url={res.url.toString()}
        //             playing={true}
        //             width={100}
        //             height={75}
        //             controls={true}
        //             pip={true}
        //             onEnded={this.handleEnded}
        //             className="small-cam-video-c-webinar"
        //           />
        //         );
        //       } else if (res.type === "Share") {
        //         return (
        //           <ReactPlayer
        //             url={res.url.toString()}
        //             playing={true}
        //             width="100%"
        //             // width={620}
        //             height={400}
        //             controls={true}
        //             pip={true}
        //             onEnded={this.handleEnded}
        //             className="big-stream-video-c-webinar"
        //           />
        //         );
        //       }
        //     });
        //   }
        // }
        // else if (this.props.webinnar.Data.method === "camOnly") {
        //   if (this.props.webinnar.Data.stream == true) {      
        //     var res = this.props.webinnar.Data.videoUrl      
        //     video = <ReactPlayer
        //       url={res.url.toString()}
        //       playing={true}
        //       width="100%"
        //       // width={620}
        //       height={400}
        //       controls={true}
        //       pip={true}
        //       onEnded={this.handleEnded}
        //       className="big-stream-video-c-webinar"
        //     />
        //   }
        // }

        // else if (this.props.webinnar.Data.method === "screenOnly") {
        //   if (this.props.webinnar.Data.stream == true) {    
        //     var res = this.props.webinnar.Data.videoUrl  
        //     video = <ReactPlayer
        //       url={res.url.toString()}
        //       playing={true}
        //       width="100%"
        //       // width={620}
        //       height={400}
        //       controls={true}
        //       pip={true}
        //       onEnded={this.handleEnded}
        //       className="big-stream-video-c-webinar"
        //     />
        //   }
        // }
      }

      else if (this.props.webinnar.Data.priority === "PLAYLIST") {
        if (this.props.webinnar.Data.stream === false) {
          video =        
              <ReactPlayer
                url={this.props.webinnar.Data.videoUrl[this.state.videoIndex].url}
                playing={true}
                width="100%"
                height={400}
                controls={true}
                pip={true}
                onEnded={this.handleEnded}
                className="big-stream-video-c-webinar"
              />              
        }
      }
    }   

    console.log(this.props.webinnar, "Webinar data ############## video conference#####")

    return (
      <>
      <div className={classes.WebinarVedioContent}>
        <div className={classes.WebinarLeft}>
          <div className={classes.textAboveVideos}>
            <h2>{this.props.intData.isVideoScreen==false? "WEBINAR":""}</h2>
            <div className={classes.small}>
              <span></span>
              <div>{webMessage}</div>
            </div>
          </div>
          {video}      
        </div>
        {CLOCK_TURN !== null ? (
          <div className={classes.bigTimerComponent}>
            <div className="big-timer-in-chat-webinar">
              <Timer intCount={count} hideHours={true} />
            </div>
            {/* <div className={classes.forYourTurn}>FOR YOUR TURN</div> */}
          </div>
        ) : null}
        
        {this.props.intData.isWebinarPnl==false?
        <div className={classes.TimeDown}>
          {count && <div className={classes.Lebal}>Your interview in</div>}
          <div className={classes.timerDiv}>
            <div className={classes.timerWeb}>
              <Timer timeChange={this.props.timeChange} intCount={count} hideHours={false} hideDays={false} />
            </div>         
          </div>
        </div>
        :null}

        <div className={classes.BackHome}>
            <a onClick={this.onDashBoard}>Go to Home</a>
        </div>
      </div>     
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    list: state.getVideos,
    auth: state.auth.token !== null,
    webinnar: state.profile.webinar,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetVideoList: () => dispatch(actions.getVideos()),
    showWebinarPusher: (data) => dispatch(actions.showWebinarPusher(data)),
    onChangeTimer:(count, timeChange)=>dispatch(actions.ChangeTimer(count, timeChange))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(webinnar);
