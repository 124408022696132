import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import * as actionsCreator from "../../../store/actions/index";
import Carousel, { consts } from "react-elastic-carousel";
import { Button, Card, Image, Icon } from "semantic-ui-react";
import classes from "../../../components/CandidateDashboard/NearbyCampaigns/NearbyData/Data.module.scss";
import defaultCamImage from "../../../assets/image/sia_default.png";
import stall_middle from "../../../assets/image/dynmc/stall_middle.png";

class Stalls extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentNum: 0,
            stallImage: "",
            stallId: "",
            stallName: ""
        }
    }
    viewStall = (id) => {
        this.props.goStall(id, 2)
    }
    myArrow({ type, onClick, isEdge }) {
        const pointer = type === consts.PREV ? "<" : ">";
        if (!isEdge) {
            return (
                <button className={classes.back_btn} onClick={onClick}>
                    {pointer}
                </button>
            )
        } else {
            return (
                <div className={classes.back_btn_empty_div}></div>
            );
        }
    }

    render() {
        var breakPoints = [
            { width: 1, itemsToShow: 1, pagination: false },
            { width: 530, itemsToShow: 2, itemsToScroll: 1, pagination: false },
            { width: 550, itemsToShow: 3, itemsToScroll: 1, pagination: false },
            { width: 850, itemsToShow: 4, pagination: false },
            { width: 1150, itemsToShow: 3, itemsToScroll: 2, pagination: false },
            { width: 1450, itemsToShow: 5, pagination: false },
            { width: 1750, itemsToShow: 6, pagination: false },
        ];
        return (
            <div>
                {
                    this.props.data.length > 0?
                <Carousel
                    renderArrow={this.myArrow}
                    enableAutoPlay={this.state.isAutoPlay}
                    autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                    focusOnSelect={true}
                    onPrevEnd={this.onPrevEnd}>
                    {this.props.data.map((res, index) => {
                        return (
                            <div style={{marginBottom:'40px'}} key={index}>
                                <Card style={{margin:'auto'}} className={classes.nc_ui} onClick={() => this.viewStall(res.id)}>
                                    <Image
                                        className={classes.newImage}
                                        src={res.stallImage ? res.stallImage : stall_middle}
                                        wrapped
                                        ui={false}
                                    />
                                    <Card.Content style={{ textAlign: "center" }} className={classes.nc_ui_content}>
                                        <Card.Header>{res.stallName}</Card.Header>
                                    </Card.Content>
                                </Card>
                            </div>
                        );
                    })}
                </Carousel>: 
                (<div className={classes.instructionWrapper}>
                    <div className={classes.instructions}>
                      <div className={classes.heading}>
                        <span>Stalls will be updated soon!</span>
                      </div>
                      {/* <div className={classes.descr}>
                        Wanna know more about the ufairs?
                      </div>
                      <a href="#">Yes</a> */}
                    </div>
                  </div>)
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Stalls)
);