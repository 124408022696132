import React, { Component } from "react";
import { connect } from "react-redux";
import placeholder from ".././../../../assets/image/man.svg";
import { Loader } from "semantic-ui-react";
import classes from "./Element.module.scss";
import moment from "moment";

class ElementVas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeStamp: (
        <div className="ui-small-loader-wrapper">
          <Loader size="tiny" active />
        </div>
      ),
    };
  }
  render() {
     var groupList=[]
     var room=""   
     if(this.props.roomData !=null){
       groupList =this.props.roomData.candidates
       room= this.props.roomData.roomData
     }
    return (
      <>
        <div>
          <div className={classes.queueTitle}>Group - {room.roomName}</div>
          <ul className={classes.queueInConference}>
            {groupList.map((res, index) => {
              return (
                <li key={index} className={classes.listItem}>
                  <div className={classes.left}>
                    <img src={res.profilePic ? res.profilePic : placeholder} />{" "}
                    {res.fullName}
                  </div>
                </li>
              )
            })
            }
          </ul>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    roomData: state.chatnew.roomData
  };
};
const mapDispatchToProps = dispatch => {
  return {
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ElementVas);


