import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import E1 from "../../../assets/image/imagetestatend.jpg";
import E2 from "../../../assets/image/imagetestatend1.jpg";
import Logo from "../../../assets/image/ufairs.svg";
import classes from "./StallAbout.module.scss";

class About extends Component {

     constructor(props) {
          super(props);
          this.state = {
               organizer: true,
               partners: false,
               company: false,
               step: 0
          }
     }
     next = () => {
          if (this.state.step != 2) {
               this.setState({ step: this.state.step + 1 })
          }

     }
     prev = () => {
          if (this.state.step != 0) {
               this.setState({ step: this.state.step - 1 })
          }
     }
     render() {

          var stallDes = null

          console.log(this.props.stallData,"Stalldata ###########")

          if (this.props.stallData != null){
               if  (this.props.stallData.length > 0){
                    stallDes = this.props.stallData[0]["description"]
               }
          }
     
          return (
               <div className={classes.outerFlex} style={{justifyContent:'center', marginBottom:'40px'}}>
                    {/* <div>
                         <button onClick={this.prev} className={classes.back_btn1} > <i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                    </div> */}
                    <div>
                    {/* aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittisaliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci */}
                    {stallDes}
                    </div>
               </div>

          ) 
     }
}
export default (About)