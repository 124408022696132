import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./SupportEmail.module.scss";
import * as actions from "../../../../store/actions/index";
import Spinner from "../../../UI/SpinnerGrey/SpinnerGrey";
import { Button } from "semantic-ui-react";

class SupportEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagSend: false,
      firstName: "",
      lastName: "",
      subject: "",
      message: "",
      email: "",
    };
  }

  handleChange = (e) => {
    console.log(e.target.value, "text value");
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  };
  handleSubmit = () => {
    console.log(this.state.firstName, "First name");
    this.setState({
      flagSend: true,
    });
    var data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      subject: this.state.subject,
      message: this.state.message,
      email: this.state.email,
      userId: this.props.candidateDate.userData.profile[0].user_id,
    };
    this.props.onSendSupport(data);
  };

  exitSupprt = () => {
    this.props.OnexitSupport(true);
  };

  closeModal = () => {
    var data = null;
    this.props.resetSupportData(null);
    this.props.onGetChatResponseNew("/submit_support_email", false);
    localStorage.setItem("SupportAsEmail", false);
  };

  render() {
    console.log(this.props.supportSend, "support email send");
    if (this.props.supportSend !== null) {
      this.closeModal();
    }

    return (
      <React.Fragment>
        {
          // this.props.supportSend !== null?
          // <div>SuccessFully send support
          //     <div onClick={this.props.closeModal}><button onClick={this.closeModal}>Close</button></div>
          // </div>:
          <div className={classes.supportEmailWrap}>
            {this.state.flagSend === true ? (
              <div className={classes.spinnerWrap}>
                <div>
                  <h4>Sending</h4>
                  <Spinner />
                </div>
              </div>
            ) : (
              <form className={classes.contentWrap}>
                <h2>
                  <img src="https://image.flaticon.com/icons/svg/2937/2937035.svg" />
                  Sia Support
                </h2>
                <div className={classes.inputsDiv}>
                  <div className={classes.eachRowf}>
                    <div className={classes.left}>
                      <div className={classes.title}>First name</div>
                      <input
                        required
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        onChange={this.handleChange}
                      ></input>
                    </div>
                    <div className={classes.right}>
                      <div className={classes.title}>Last name</div>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                        onChange={this.handleChange}
                      ></input>
                    </div>
                  </div>
                  <div className={classes.eachRow}>
                    <div className={classes.title}>Email</div>
                    <input
                      required
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={this.handleChange}
                    ></input>
                  </div>
                  <div className={classes.eachRow}>
                    <div className={classes.title}>Subject</div>
                    <input
                      required
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      onChange={this.handleChange}
                    ></input>
                  </div>
                  <div className={classes.eachRow}>
                    <div className={classes.title}>Message</div>
                    <textarea
                      required
                      type="text"
                      name="message"
                      placeholder="Your message"
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className={classes.buttonWrap}>
                  <div onClick={this.props.closeModal}>
                    <button onClick={this.exitSupprt}>I'll send later</button>
                  </div>
                  <button
                    type="submit"
                    className={classes.submit}
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    candidateDate: state.CandidateData.userData,
    supportSend: state.chatnew.sendSupport,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSendSupport: (data) => dispatch(actions.sendSupport(data)),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(actions.getChatResponseNew(message, status, notification)),
    OnexitSupport: (data) => dispatch(actions.exitSupport(data)),
    resetSupportData: (data) => dispatch(actions.resetSupportData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportEmail);
