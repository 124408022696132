import React from 'react'
import {Link} from 'react-router-dom'
import classes from './Logo.module.scss'

const Logo = (props) => {
    return (
        <Link to="/" className={classes.Logo}>
            <img src={props.image} alt="Sia Logo" />
        </Link>
    )
}

export default Logo

