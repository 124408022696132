import React, { Component } from "react";
import { connect } from "react-redux";

class Questions extends Component {
    constructor(props){
        super(props)
        this.state={
            Questions_array : this.props.data
        }
    }

    render(){
        return(
            <div>
                <button></button>
            </div>
        )
    }
}

export default Questions;
