import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../store/actions/index";
import VideoConference from "../../../containers/Interview/VideoConference/VideoConference";
import Spinner from "../../../components/UI/Spinner/Spinner";
import CheckVideoConference from "../../../containers/Interview/VideoConference/CheckValidateVideo";

class LoadingCom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteRef: localStorage.getItem("quickInvite"),
      token: localStorage.getItem("chat-token"),
    };
  }

  componentDidMount = () => {
    this.props.onUpdateRef(this.state.inviteRef, this.state.token);
  };

  render() {
    console.log(this.props.updatedStatus, "update status");

    var component = <Spinner />;

    if (this.state.updatedStatus !== null) {
      console.log("loading to video conference");
      sessionStorage.setItem("ref", this.state.inviteRef);
      console.log(this.state.inviteRef, "ref1");
      component = <CheckVideoConference />;
    }

    return <div>{component}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    updatedStatus: state.CandidateData.updatedRefId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateRef: (ref, token) => dispatch(action.UpdateRef(ref, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoadingCom);
