import React from "react";
import classes from "./SpinnerGrey.module.scss";

const Spinners = (props) => {
  return (
    <div className={`${props.dark ? classes.dark : ""} ${classes.ldsEllipsis}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinners;
