import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "../../components/Login/Login";
import Register from "../../components/PasswordLessLogin/RegisterUser";
import LinkedInIndex from "../../components/Login/LinkedInPopUp";
import PassworlessLogin from "../../components/PasswordLessLogin/PaswrdLesLogin";
import Chat from "../../containers/SiaChat/Chat/Chat";
import Page404 from "../../containers/NotFound";
import Share from "../../components/CampaignShare/CampaignShare";
import APPJS from "../../App";
import Stall from "../../components/Event/Stalls/IndvStall/Stall";

export class Auth extends Component {
  constructor() {
    super();
    this.state = {
      process: false
    };
    this.upadateProcessState = this.upadateProcessState.bind(this);
  }


  
  componentWillMount = () => {
    this.upadateProcessState();
  };

  upadateProcessState = () => {
    var status = localStorage.getItem("passlesslogStatus");
    if (status === "True") {
      this.setState({
        process: false
      });
    }
  };

  render() {
    return (
      <Switch>   
        {this.props.auth ? (
          <Route exact path="/" component={Chat} />
        ) : (
          <Route exact path="/" component={Login} />
        )}
        {this.state.process ? (
          <Route exact path="/" />
        ) : (
          <Route exact path="/auth" component={Login} />
        )}
        <Route exact path="/passwordless-login" component={PassworlessLogin} />
        <Route exact path="/linkedin" component={LinkedInIndex} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/Share" component={Share} /> 
        <Route exact path="/stall" component={Stall} />
        <Route exact path="" component={Login} /> 
        <Route component={Page404} />     
      </Switch>
    );
  }
}

export default Auth;
