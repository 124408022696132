import React, { Component } from 'react';
import classes from './Chat.module.scss'
import ChatMessages from '../ChatApp'

class Chat extends Component{
    constructor(props){
      super(props)
    }
    render(){
        return(
          <div className={classes.Chat}>
                <ChatMessages intData={this.props.intData} intwData={this.props.intwData}/>
          </div>
        )
    }
}

export default Chat