import React from "react";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { connect } from "react-redux";
import * as actionsCreator from "../../../store/actions/index";

class CVTagsForSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValues: [],
      options: [],
    };
  }
  componentDidMount() {
    this.getSkilsList();
    this.setState({
      currentValues: this.props.profile.userData.profile[0].skills.split(",").filter(item => item),
    });
    this.props.data.skills = this.props.profile.userData.profile[0].skills.split(
      ","
    );
  }

  handleAddition = (e, { value }) => {
    console.log(value,"add");
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    })); 
    this.props.setChanged(true);
    //this.props.data.skills = this.state.currentValues;
  };

  handleChange = (e, { value }) => {
    console.log(value,"change");
    this.setState({ currentValues: value });
    this.props.setChanged(true);
    this.props.data.skills = value;
  };

  getSkilsList = () => {
    var Skils = [];
    this.props.systemData.Data.skills.map((res, index) => {
      var obj = {
        text: res.skillName,
        value: res.skillName,
      };
      Skils.push(obj);
    });
    this.setState({ options: Skils });
  };

  render() {
    return (
      <div className="react--cv--tags">
        <Dropdown
          options={this.state.options}
          placeholder="Choose Skills"
          search
          selection
          fluid
          multiple
          allowAdditions
          value={this.state.currentValues}
          onAddItem={this.handleAddition}
          onChange={this.handleChange}
          className="skillList_dropdown"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.CandidateData.userData,
    systemData: state.auth.systemData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setChanged: (data) => dispatch(actionsCreator.setSomethingChanged(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CVTagsForSettings);
