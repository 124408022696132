import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import * as actionsCreator from "../../../store/actions/index";
import ChatCV from "../ChatModal/ChatCV/ChatCV";
import ChatVideo from "../ChatModal/ChatVideo/Chatvideo";
import Splash from "../../../components/UI/Splash/Splash";
import Progress from "../ChatModal/ChatCV/ChatCVProgress/Progress";
import ProgressCVL from "../ChatModal/ChatCoverLetter/CoverLetterProgress/Progress";
import CoverLetter from "../../UI/ChatModal/ChatCoverLetter/CoverLetter";
import classes from "./ChatModal.module.scss";
import VideoTest from "../ChatModal/VideoTest/VideoTest";
import AudioTest from "../ChatModal/AudioTest/AudioTest";
import "./ChatModal.scss";
import Swal from "sweetalert2";
import SupportEmail from "../../UI/ChatModal/SupportEmail/SupportEmail";
import Faq from "../../UI/ChatModal/FAQ/Faq";

class ChatModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      dataLable: this.props.data,
      OpenSmartCV: localStorage.getItem("OpenSmartCV"),
      percentage:0
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }
  percentage=(value)=>{
    this.setState({percentage:value})
  }

  handleApplyCVModalClose = () => {  
    if (localStorage.getItem("OpenSmartCV") === "true"){
      this.props.onGetChatResponseNew("exit_from_campaign_apply_cv", false);
      localStorage.setItem("OpenSmartCV", false);
    } else if (localStorage.getItem("NewCVUpload") === "true") {
      this.props.onGetChatResponseNew("exit_from_campaign_apply_cv", false);
      localStorage.setItem("NewCVUpload", false);
    }
  };

  closeModal() { 
    this.setState({ modalIsOpen: false });
    if (localStorage.getItem("OpenSmartCV") === "true") {
      if (this.props.originalCVStatus === true) {
        this.props.onGetChatResponseNew("/Submit_cv_data", false);
        var status = "cv";
        this.props.onUpdateApplyState(this.props.appliedSaveData, status);
        localStorage.setItem("OpenSmartCV", false);
      } else if (this.props.originalCVStatus === false) {
        Swal.fire({
          toast: true,
          position: "top-end",
          title: "Please upload your original CV",
          showConfirmButton: false,
          timer: 2500,
          customClass: { container: "swal-success-settings" },
          imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          imageHeight: 20,
        });
      }
    } else if (localStorage.getItem("OpenVideoModal") === "true") {
      console.log(this.props.exitVideoPitch, "video modal close");
      //this.props.onApplyCampaign(this.props.appliedSaveData)
      if (this.props.exitVideoPitch === true) {
        console.log(this.props.appliedSaveData, "Exit video pitch true");
        this.props.onGetChatResponseNew("/exit_video_pitch", false);
        localStorage.setItem("OpenVideoModal", false);
        this.props.OnexitVideoPitch(false);
      } else if (this.props.exitVideoPitch === false) {
        console.log(this.props.appliedSaveData, "exit video pitch false");
        //this.props.onGetChatResponseNew("/Submit_video_stream", false);
        this.props.onGetChatResponseNew("/exit_video_pitch", false);
        localStorage.setItem("OpenVideoModal", false);
        this.props.onSubmitVideo(false);
      }
    } else if (localStorage.getItem("OpenCoverLtrModal") === "true") {
      this.props.onGetChatResponseNew("/Submit_cover_letter", false);
      var status = "coverLetter";
      this.props.onUpdateApplyState(this.props.appliedSaveData, status);
      localStorage.setItem("OpenCoverLtrModal", false);
    } else if (localStorage.getItem("OpenCamTest") === "true") {
      this.props.onGetChatResponseNew("/close_cam_test", false);
      localStorage.setItem("OpenCamTest", false);
    } else if (localStorage.getItem("OpenMicTest") === "true") {
      this.props.onGetChatResponseNew("/close_mic_test", false);
      localStorage.setItem("OpenMicTest", false);
    } else if (localStorage.getItem("SupportAsEmail") === "true") {
      console.log(this.props.exitSupport, "exit support");

      if (this.props.exitSupport === true) {
        this.props.onGetChatResponseNew("/exit_support", false);
        this.props.onReserExitSupport(false);
        localStorage.setItem("SupportAsEmail", false);
      } else if (this.props.exitSupport !== false) {
        this.props.onGetChatResponseNew("/submit_support_email", false);
        localStorage.setItem("SupportAsEmail", false);
      }
      //this.props.onGetChatResponseNew("/submit_support_email", false);
    }

    // else if (localStorage.getItem("faq_question_modal") === "true"){

    //   console.log(this.props.exitFAQ, "exit support")

    //   if (this.props.exitFAQ !== null && this.props.exitFAQ === true){
    //     console.log(this.props.exitFAQ, "exit support true")
    //     this.props.onGetChatResponseNew("/ask_faq_later", false);
    //     this.props.onReserExitSupport(null)
    //     localStorage.setItem("faq_question_modal", false);
    //   }

    //   else if(this.props.exitFAQ !== null && this.props.exitFAQ !== true){
    //     console.log(this.props.exitFAQ, "exit support false")
    //     this.props.onGetChatResponseNew("/send_faq_now", false);
    //     this.props.onReserExitSupport(null)
    //     localStorage.setItem("faq_question_modal", false);
    //   }
    // }

    //localStorage.setItem("submitCV", true);
  }

  render() { 
    if (this.props.originalCVStatus !== true) {
      this.props.onGetOriginalCVState();
    } 

    let lable = this.props.dataLable;
    let lableCom = null;

    let modalIsOpen = false;
    let closeBtn = "";

    let sendBtn = "";

    if (localStorage.getItem("OpenSmartCV") === "true") {
      modalIsOpen = true;
     // closeBtn = "Submit";
      if (this.props.progressUpload === true) {
        lableCom = <Progress percentage={this.state.percentage} />;
      } else if (this.props.progressUpload === false) {
        lableCom = (
          <ChatCV  processPercentage={this.percentage}  next={this.closeModal} submitCV={true} closeCVmodal={this.handleApplyCVModalClose} />
        );
      }
    } else if (localStorage.getItem("NewCVUpload") === "true") {
      modalIsOpen = true;
     // closeBtn = "Submit";
      if (this.props.progressUpload === true) {
        lableCom = <Progress percentage={this.state.percentage} />;
      } else if (this.props.progressUpload === false) {
        var dispalyPolicy = false;
        lableCom = (
          <ChatCV
            data={dispalyPolicy}
            submitCV={true}
            closeCVmodal={this.handleApplyCVModalClose}
            next={this.closeModal}
            processPercentage={this.percentage}
          />
        );
      }
    } else if (localStorage.getItem("OpenVideoModal") === "true") {
      console.log("video modal state true");

      if (this.props.videoUploadStatus === true) {
        // closeBtn = "Send video";
        closeBtn = "";
      }
      modalIsOpen = true;
      lableCom = <ChatVideo closeModal={this.closeModal} />;
    } else if (localStorage.getItem("OpenCoverLtrModal") === "true") {
      console.log(this.props.progressUploadCVL, "cover letter open");
      modalIsOpen = true;
      if (this.props.progressUploadCVL === true) {
        lableCom = <ProgressCVL />;
      } else if (this.props.progressUploadCVL === false) {
        lableCom = <CoverLetter closeModal={this.closeModal} />;
        // lableCom = <ProgressCVL />;
        // closeBtn = "Close";
      }
    } else if (localStorage.getItem("SupportAsEmail") === "true") {
      modalIsOpen = true;
      lableCom = <SupportEmail closeModal={this.closeModal} />;
    } else if (localStorage.getItem("OpenCamTest") === "true") {
      modalIsOpen = true;
      lableCom = <VideoTest closeModal={this.closeModal} />;
    } else if (localStorage.getItem("OpenMicTest") === "true") {
      modalIsOpen = true;
      lableCom = <AudioTest closeModal={this.closeModal} />;
    } else if (localStorage.getItem("faq_question_modal") === "true") {
      modalIsOpen = true;
      lableCom = <Faq closeModal={this.closeModal} />;
    }

    return (
      <div className="upload-review-cv-modal">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
          className="cv-upload-modal-1 "
        >
          {/* {(localStorage.getItem("OpenSmartCV") === "true" ||
            localStorage.getItem("NewCVUpload") === "true") && (
            <button
              className={classes.absoluteClose}
              onClick={this.handleApplyCVModalClose}
            >
              <i className="fas fa-times" style={{ color: "red" }}></i>
            </button>
          )} */}

          <div
            className={
              localStorage.getItem("OpenSmartCV") === "true" &&
              this.props.progressUpload !== true
                ? classes.borderedInnerWrap
                : classes.nonBorderedInnnerWrap
            }
          >
            {localStorage.getItem("OpenSmartCV") === "true" &&
              this.props.progressUpload !== true && (
                <div className={classes.confirmCVText}>
                  Confirm your profile information
                </div>
              )}
            {lableCom}
            {closeBtn && this.props.progressUpload !== true && (
              <div className={classes.closeBtnWrapDiv}>
                <div className={classes.closeBtnDiv}>
                  {/* <span>This section is under construction&nbsp;&nbsp;</span> */}
                  <button
                    className={classes.closeButtton}
                    onClick={this.closeModal}
                  >
                    {closeBtn}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chatStatus: state.chatnew.chatModalStatus,
    progressUpload: state.chatnew.uploadProgress,
    progressUploadCVL: state.chatnew.cvlProgress,
    uploadedStatus: state.chatnew.cvUploaded,
    candidateDate: state.CandidateData.userData,
    videoUploadStatus: state.chatnew.videoSubmitStatus,
    exitVideoPitch: state.chatnew.exitVideoPitch,
    originalCVStatus: state.CandidateData.originaCVStatus,
    exitSupport: state.chatnew.exitSupport,
    appliedSaveData: state.chatnew.appliedCampaignTemp,
    exitFAQ: state.chatnew.exitFAQ,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
    onSubmitVideo: (data) => dispatch(actionsCreator.SubmitVideo(data)),
    OnexitVideoPitch: (data) => dispatch(actionsCreator.exitVideoPitch(data)),
    onGetOriginalCVState: () => dispatch(actionsCreator.getoriginlCVStatus()),
    onReserExitSupport: (data) =>
      dispatch(actionsCreator.ResetExitSupport(data)),
    onApplyCampaign: (data) =>
      dispatch(actionsCreator.ApplycampaignAction(data)),
    onUpdateApplyState: (data, status) =>
      dispatch(actionsCreator.updateApplySaveData(data, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatModal);
