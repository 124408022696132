import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import * as actionsCreator from "../../../../store/actions/index";
import CheckValidateVideo from "../../../../containers/Interview/VideoConference/CheckValidateVideo";
import axios from "../../../../axios";
import Sidebar from "../IndvStall/Sidebar";
import classes from "./Inner.module.scss";
import stall_left from "../../../../assets/image/dynmc/stall_left.png";
import stall_middle from "../../../../assets/image/dynmc/stall_middle.png";
import stall_right from "../../../../assets/image/dynmc/stall_right.png";
import Apply from "../../../../assets/image/Icons/Apply.svg";
import About from "../../../../assets/image/Icons/About.svg";
import OChat from "../../../../assets/image/Icons/Chat-Icon.png";
import OInfo from "../../../../assets/image/Icons/Info-Icon.png";
import OInterview from "../../../../assets/image/Icons/Interview-Icon.png";
import OQueue from "../../../../assets/image/Icons/Queue-Icon.png";
import Join from "../../../../assets/image/Icons/JoinEvent.svg";
import moment from "moment";
import ReactPlayer from "react-player";
import Jitsi from "react-jitsi";
import { Image, List } from "semantic-ui-react";
import Links from "../../../../assets/image/Icons/Links.svg";
import Timer from "../../../../components/Webinnar/CloclTime/Time";
import NearByCampaignsStall from "../../../../components/CandidateDashboard/NearbyCampaigns/NearByCampaignsStall";
import StallAbout from "../../../Event/StallAbout/StallAbout";
import swal from "sweetalert2";

class Stall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stallData: null,
      inwData: [],
      stallImage: "",
      stallId: 0,
      stallName: "",
      stalls: [],
      currentNum: 0,
      apiCall: false,
      sideImages: [],
      webinarId: 0,
      webinarData: null,
      videoIndex: 0,
      linkone: "",
      linkTow: "",
      linkThree: "",
      selectId: 0,
      state_timeChange: false,
      countTime: null,
      interviewTime: null,
      interviewDataSocket: null,
      timeUpdated: false,
      flag: 0,
      inwStatus: 1,
      hasVideoInterview: false,
      openApplyModal: false,
      openInterviewModal: false,
      openAboutModal: false,
      openSideBarCom: false,
      sideComItem: 0,
      interviewWebinar: null,
      interviewDataGet: null,
      interviewExpired: false
    };
    this.getCampaignDataStall = this.getCampaignDataStall.bind(this);
    this.joinVideoCall = this.joinVideoCall.bind(this);
  }
  componentDidMount() {
    //this.findExbStalls()
    //  this.intrtviewTime = setInterval(() => this.findExbStalls(), 10000);
    this.setState({ selectId: this.props.exbHallId }, () => {
      this.findExbStalls();
    });
    //this.getInterviewData()
    this.getCampaignDataStall()
    this.intrtviewTime = setInterval(() => this.updateCurrentTime(), 10000);
    this.props.onAccessStall(true);
  }
  componentWillReceiveProps(props) {
    // console.log(props.exbHallId,"data")
    this.setState({ selectId: props.exbHallId }, () => {
      this.findExbStalls();
    });
  }

  getCampaignDataStall = () => {
    var stallId = this.state.stallId;
    var token = localStorage.getItem("chat-token");
    var currentTime = moment.utc().toISOString();
    this.props.ongetCandidateDataCampaign(token, currentTime, stallId);
  }

  findExbStalls = () => {
    console.log(this.props.stallStatus, "stall status");
    if (this.props.stallStatus == 1) {
      var data = this.props.eventData.exhibitionHalls.find(
        (x) => x.exibitionHalls.id === this.state.selectId
      );
      this.setState(
        {
          stallId: data.stalls[0].id,
          stallName: data.stalls[0].stallName,
          stallImage: data.stalls[0].stallImage,
          stalls: data.stalls,
        },
        () => {
          this.getInterViewref();
        }
      );
    }
    if (this.props.stallStatus == 2) {
      // if (this.props.data != null) {
      //     console.log(this.props.data,"Props data ####")
      //     var data = this.props.data.find(x => x.id === this.state.selectId)
      //     console.log(data, "data stalls")
      //     this.setState({
      //         stallId: data.id,
      //         stallName: data.stallName,
      //         stallImage: data.stallImage,
      //         stalls: this.props.data,
      //     }, () => {
      //         this.getInterViewref()
      //     })
      // }

      if (this.props.data != null) {
        if (this.props.data.length > 0) {
          console.log(this.props.data, "Props data ####");
          var data = this.props.data.find((x) => x.id === this.state.selectId);

          console.log("one data", data);
          console.log(data, "data stalls");
          this.setState(
            {
              stallId: data.id,
              stallName: data.stallName,
              stallImage: data.stallImage,
              stalls: this.props.data,
              currentNum: this.props.data.findIndex(
                (x) => x.id === this.state.selectId
              ),
            },
            () => {
              this.getInterViewref();
            }
          );
        }
      }
    }
  };
  next = () => {
    console.log("next")
    console.log(this.state.stalls.length, "Stalllenght")
    console.log(this.state.currentNum, "Curr num")
    if (this.state.currentNum + 1 < this.state.stalls.length) {
      var data = this.state.stalls[this.state.currentNum + 1];
      this.setState(
        {
          stallImage: data.stallImage,
          stallName: data.stallName,
          stallId: data.id,
          currentNum: this.state.currentNum + 1,
          apiCall :false
        },
        () => {
          this.getInterViewref();
        }
      );
    }
  };
  previous = () => {
    console.log("previous")
    if (this.state.currentNum > 0) {
      var data = this.state.stalls[this.state.currentNum - 1];
      this.setState(
        {
          stallImage: data.stallImage,
          stallName: data.stallName,
          stallId: data.id,
          currentNum: this.state.currentNum - 1,
          apiCall :false
        },
        () => {
          this.getInterViewref();
        }
      );
    }
  };

  getInterViewref = () => {
    var stallId = this.state.stallId;
    var eventId = localStorage.getItem("eventId");
    var senderId = localStorage.getItem("CandidateProfile_id02");
    var token = localStorage.getItem("chat-token");
    if (this.state.apiCall === false) {
      axios
        .get(
          "get-event-stall-data/?stallId=" +
            stallId +
            "&eventId=" +
            eventId +
            "&senderId=" +
            senderId,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": `application/json`,
            },
          }
        )
        .then((res) => {
          console.log(res.data.Data, "stall data indv");
          this.state.sideImages = res.data.Data.stallImages;
          this.setState({
            stallData: res.data.Data.stall.stallData,
            inwData: res.data.Data.avaialbleInterview,
            apiCall: true,
          });
          if (res.data.Data.avaialbleInterview.length > 0) {
            sessionStorage.setItem(
              "ref",
              res.data.Data.avaialbleInterview[0]["ref"]
            );
            this.getInterviewData(res.data.Data.avaialbleInterview[0]["ref"]);
            this.getWebinarDetails(res.data.Data.avaialbleInterview[0]["ref"]);
          }
          if (res.data.Data.publicWebinar.length > 0) {
            this.getWebinar(res.data.Data.publicWebinar[0].id);
          } else {
            this.setState({ webinarData: null });
          }

          this.setState({
            linkone: res.data.Data.stall.stallData[0].eventFeedone,
            linkTow: res.data.Data.stall.stallData[0].eventFeedTwo,
            linkThree: res.data.Data.stall.stallData[0].eventFeedThree,
          });
        })
        .catch((err) => {
          this.setStthate({
            apiCall: true,
          });
        });
    }
  };

  //get candiate interview data
  getInterviewData = (ref) => {
    console.log("get interview data stall js");

    if (this.state.interviewDataSocket === null) {
      axios
        .get("get-user-interview-data/?ref=" + ref, {
          headers: {
            Authorization: `token ${localStorage.getItem("chat-token")}`,
          },
        })
        .then((res) => {
          var data = res.data.Data;
          console.log(data, "Get user interview data");
          this.setState({ interviewTime: data.InterviewData.startTime, interviewDataGet: res.data.Data});
          this.updateCurrentTime();
          //   this.InitializationPusherGroupChat(res.data.Data);
          //   this.InitializationPusherInterviewChat(res.data.Data);

          //   if (data.isVideoScreen == true) {
          //     this.setState({ basicInfo: true, textChatCom: false });
          //   }
        });
    }
  };

  getWebinarDetails = (ref) => {
    var data = {
      time: moment.utc().format("YYYY-MM-DD H:mm:ss"),
      ref: ref,
    };

    this.props.onShowWebinar(data);
  };

  updateCurrentTime = () => {
    console.log("update curr time")
    if (this.state.interviewDataGet !== null) {
      var data = this.state.interviewDataGet
      console.log("update current time ########### stall js");

      this.setState({
        interviewDataSocket: data,
        // appliedId: data.AppliedID,
        // panelDuration: data.PanelDuration,
        // campaignId: data.InterviewData.campaignId,
        // interviewId: data.InterviewData.interviewId,
      });

      try {
        var intwStatus = data.InterviewData.status;
        var currentTime = moment().toISOString(true).split("+")[0];
        var intTime = moment(this.state.interviewTime)
          .toISOString(true)
          .split("+")[0];

        var ms = moment(intTime).diff(moment(currentTime));
        this.setState({ countTime: ms });
        console.log(ms, "try count time ####");
        // if (parseInt(ms) > parseInt(this.state.scheduled_interviewTime)) {
        //   console.log("ms####")
        //   this.setState({
        //     scheduled_interviewTime: ms,
        //     state_timeChange: true,
        //   });

        // } else {
        //   this.setState({ state_timeChange: false });
        // }

        var interviewId = data.AppliedID;
        if (moment(intTime)._i < moment(currentTime)._i) {
          localStorage.setItem("CALL_STATUS", true); //temparary
          localStorage.setItem("Flag status", 1);

          this.setState({ flag: 1, inwStatus: intwStatus });

          if (this.state.timeUpdated === false) {
            this.props.onUpdateTimes(ms, interviewId);
            this.setState({ timeUpdated: true });
            localStorage.setItem("TIME_UPDATED", true);
          }
        }
      } catch {
        // console.log("pending");
      }
    }
  };

  getWebinar = (webinarId) => {
    var time = moment().toISOString();
    var token = localStorage.getItem("chat-token");
    axios
      .get(
        "show-public-webinar/?ref=&time=" + time + "&webinarId=" + webinarId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        this.state.webinarData = res.data;
        this.setState({ data: true });
      });
  };
  componentWillUnmount() {
    clearInterval(this.intrtviewTime);
    this.intrtviewTime = null;
  }

  joinVideoCall = () => {
    var token = localStorage.getItem("chat-token");
    var ref = sessionStorage.getItem('ref')
    axios
    .get(
      "interview-status-candidate/?ref=" + ref,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
    .then((res) => {
    //   this.state.webinarData = res.data;
    //   this.setState({ data: true });
        console.log(res.data,"Result data ######")

        if(res.data.statusFlag === true){
            this.setState({
                hasVideoInterview: true,
            });
        }

        else if(res.data.statusFlag === false){
            this.setState({
                hasVideoInterview: false,
                interviewExpired: true
            });
            swal.fire({
              toast: true,
              position: "top-end",
              title:
                "Your interview is expired",
              showConfirmButton: false,
              timer: 2500,
              customClass: { container: "swal-success-settings" },
              imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
              imageHeight: 20,
            });
        }
    });
    
  };

  closeVideoCall = () => {
    this.setState({
      hasVideoInterview: false,
    });
  };

  closeApply = () => {
    this.setState({
      openApplyModal: false,
    });
  };

  closeInterview = () => {
    this.setState({
      openInterviewModal: false,
    });
  };

  closeAbout = () => {
    this.setState({
      openAboutModal: false,
    });
  };

  // openApplyModal: false,
  //         openInterviewModal: false,
  //         openAboutModal: false

  openApply = () => {

    var data = {
      userId : localStorage.getItem("CandidateProfile_id02")
    }
    
    axios.post('update-applied-campaigns/', data,{
      headers: {
        "content-type": "application/json",
      },
    }).then(res=>{
      var id = localStorage.getItem("CandidateProfile_id02");
      var eventId = localStorage.getItem("eventId");
      var stallId = this.state.stallId;
      var token = localStorage.getItem("chat-token");
      var currentTime = moment.utc().toISOString();
      this.props.ongetCandidateDataCampaign(token, currentTime, stallId);
      //this.props.ongetCandidateDataCampaign(id, eventId, stallId)
      this.setState({
        openApplyModal: true,
      });
    }).catch(error=>{
      var id = localStorage.getItem("CandidateProfile_id02");
      var eventId = localStorage.getItem("eventId");
      var stallId = this.state.stallId;
      var token = localStorage.getItem("chat-token");
      var currentTime = moment.utc().toISOString();
      this.props.ongetCandidateDataCampaign(token, currentTime, stallId);
      //this.props.ongetCandidateDataCampaign(id, eventId, stallId)
      this.setState({
        openApplyModal: true,
      });
    })

  };

  OpenInterview = () => {
    var id = localStorage.getItem("CandidateProfile_id02");
    var eventId = localStorage.getItem("eventId");
    var stallId = this.state.stallId;
    var token = localStorage.getItem("chat-token");
    var currentTime = moment.utc().toISOString();
    this.props.ongetCandidateDataCampaign(token, currentTime, stallId);
    this.setState({
      openInterviewModal: true,
    });
  };

  OpenAbout = () => {
    this.setState({
      openAboutModal: true,
    });
  };

  openSidebar = (id) => {
    this.setState({
      openSideBarCom: true,
      sideComItem: id,
    });
  };

  closeSideBar = () => {
    this.setState({
      openSideBarCom: false,
      sideComItem: 0,
    });
  };

  render() {
    console.log("show stall ####################");
    console.log("stall #####")

    let hasInterview = null;
    let stallData = null;
    let SideBar = null;
    let isInterview = false;

    console.log(
      this.state.inwData,
      "Interview data  inside stall ###############"
    );

    console.log(this.state.hasVideoInterview, "stall js");

    if (this.state.inwData.length > 0) {
      console.log("inside interw data lenght > 0  #########");
      hasInterview = (
        <CheckValidateVideo intRef={this.state.inwData[0].ref} isEvent={true} />
      );
      
      isInterview = true;
    
      SideBar = <Sidebar sideComItem={this.state.sideComItem} />;
    }

    if (this.state.interviewExpired === true){
        isInterview = false
        hasInterview = null
    }

    var webinar = "";
    var digital = "";
    var wbiImage = "";
    var digImage = "";
    if (this.state.sideImages.length > 0) {
      webinar = this.state.sideImages.filter((x) => x.imagePlace == "WEBINAR");
      digital = this.state.sideImages.filter((x) => x.imagePlace == "DIGITAL");
      wbiImage = webinar[webinar.length - 1];
      digImage = digital[digital.length - 1];
    }

    var webinnar = "";
    var webMessage = "";
    var video = "";
    if (this.state.webinarData !== null) {
      if (this.state.webinarData.Data.priority === "PENDING") {
        webMessage = (
          <div
            style={{
              fontSize: "22px",
              letterSpacing: "1px",
              fontWeight: "300",
              padding: "5rem 1rem",
            }}
          >
            Webinar will be starting{" "}
            <span
              style={{
                fontSize: "16px",
                letterSpacing: "1px",
                fontWeight: "300",
                display: "block",
              }}
            >
              Please wait a moment!
            </span>
          </div>
        );
      } else if (this.state.webinarData.Data.priority === "LIVESTREAM") {
        webinnar = (
          <Jitsi
            containerStyle={{ width: "250px", height: "250px" }}
            config={{
              prejoinPageEnabled: false,
              disableDeepLinking: true,
              //  startAudioOnly: true,
              startWithAudioMuted: true,
              startWithVideoMuted: true,
              disableProfile: true,
              startScreenSharing: false,
            }}
            interfaceConfig={{
              SHOW_JITSI_WATERMARK: false,
              SHOW_WATERMARK_FOR_GUESTS: false,
              filmStripOnly: true,
              // LANG_DETECTION: true,
              TOOLBAR_BUTTONS: ["fullscreen", "recording"],
            }}
            roomName={this.state.webinarData.Data.videoUrl[0].url}
            displayName={this.props.profile.candidate_profile.first_name}
          />
        );
      } else if (this.state.webinarData.Data.priority === "PLAYLIST") {
        if (this.state.webinarData.Data.stream === false) {
          webinnar = (
            <div className={classes.YtBox}>
              <ReactPlayer
                url={
                  this.state.webinarData.Data.videoUrl[this.state.videoIndex]
                    .url
                }
                playing={true}
                width="260px"
                height="200px"
                controls={true}
                pip={true}
                onEnded={this.handleEnded}
              />
            </div>
          );
        }
      }
    }

    console.log(this.props.webinnar, "has webinar");

    var webMsg =
      "Waiting for your interview, Check your remaining time to interview";

    if (this.props.webinnar !== null) {
      //check priority of webinar
      if (this.props.webinnar.Data.priority === "PENDING") {
        webMsg = "Webinar will start soon, Please wait";
      } else if (this.props.webinnar.Data.priority === "LIVESTREAM") {
        video = (
          <Jitsi
            containerStyle={{ width: "730px", height: "400px" }}
            config={{
              prejoinPageEnabled: false,
              disableDeepLinking: true,
              //  startAudioOnly: true,
              startWithAudioMuted: true,
              startWithVideoMuted: true,
              disableProfile: true,
              startScreenSharing: false,
            }}
            interfaceConfig={{
              SHOW_JITSI_WATERMARK: false,
              SHOW_WATERMARK_FOR_GUESTS: false,
              filmStripOnly: true,
              // LANG_DETECTION: true,
              // TOOLBAR_BUTTONS: [
              //    'desktop', 'fullscreen',
              //   'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
              //   'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
              //   'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
              //   'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone',
              //   'e2ee', 'security'
              // ],
              TOOLBAR_BUTTONS: ["fullscreen", "recording"],
            }}
            roomName={this.props.webinnar.Data.videoUrl[0].url}
            displayName={this.props.intData.InterviewData.fullName}
          />
        );
      } else if (this.props.webinnar.Data.priority === "PLAYLIST") {
        if (this.props.webinnar.Data.stream === false) {
          video = (
            <ReactPlayer
              url={this.props.webinnar.Data.videoUrl[this.state.videoIndex].url}
              playing={true}
              width="100%"
              height={400}
              controls={true}
              pip={true}
              onEnded={this.handleEnded}
              className="big-stream-video-c-webinar"
            />
          );
        }
      }
    }

    var dataTimer = null;

    if (this.props.checkValidate != null) {
      dataTimer = this.props.checkValidate.Data;
    }

    console.log(this.state.stallData, "Count ##########");

    var waitingTimer = null;
    var callBtn = null;
    let showInterview = null;
    var nearByData = (
      <NearByCampaignsStall data={this.props.candidateDate} tabStatus={1} stallData={this.state.stallData}/>
    );
    var interviewData = (
      <NearByCampaignsStall data={this.props.candidateDate} tabStatus={2} stallData={this.state.stallData}/>
    );
    var aboutData = <StallAbout stallData={this.state.stallData} />;

    if (this.state.countTime !== 0) {
      waitingTimer = (
        <Timer
          timeChange={false}
          intCount={this.state.countTime}
          hideHours={false}
          hideDays={false}
        />
      );
    }

    // if (this.state.flag === 0){
    //     callBtn = <button  className='btn btn-primary' style={{pointer_events: "cursor"}}  onClick={this.joinVideoCall}>Join call</button>
    // }

    if (this.state.flag === 1) {
      callBtn = (
        <button
          className="btn btn-primary"
          style={{ pointer_events: "cursor" }}
          onClick={this.joinVideoCall}
        >
          Join call
        </button>
      );
    }

    if (this.state.hasVideoInterview === true) {
      showInterview = (
        <CheckValidateVideo intRef={this.state.inwData[0].ref} isEvent={true} />
      );
    }

    return (
      <>
        <div className={classes.dynm}>
          <div className={classes.left}>
            {/* {webinar ?
                            <img src={wbiImage.image} />
                            :  */}
            {/* <img src={stall_left} /> */}

            {isInterview == false ? (
              <>
                {webinnar == "" && webMessage == "" ? (
                  <img
                    src={
                      wbiImage.image == undefined ? stall_left : wbiImage.image
                    }
                  />
                ) : (
                  webinnar
                )}
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "300",
                    padding: "10px",
                    margin: "0",
                  }}
                >
                  {webMessage}
                </h2>
                {/* <button  className='btn btn-primary' >ABC</button> */}
              </>
            ) : (
              <>
                {/* {video == "" && webMsg == "" ?  <img src={wbiImage.image == undefined ? stall_left : wbiImage.image} /> : video} */}
                {video == "" ? null : video}
                {video == "" ? (
                  <h2
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: "22px",
                      fontWeight: "300",
                      padding: "10px",
                      margin: "0",
                    }}
                  >
                    {webMsg}
                  </h2>
                ) : null}
                {waitingTimer} {callBtn}
              </>
            )}
          </div>

          <div className={classes.middle}>
            {this.state.stallImage != "" ? (
              <img src={this.state.stallImage} />
            ) : (
              <img src={stall_middle} />
            )}
          </div>

          {/* <div className={classes.btns}>
                        <ul>
                            <li>
                                <a href="#" >
                                    <img src={Apply} />
                                    <span>Apply</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" >
                                    <img src={Join} />
                                    <span>Interview</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" >
                                    <img src={About} />
                                    <span>About</span>
                                </a>
                            </li>
                        </ul>
                    </div> */}

          <div className={classes.right}>
            {digImage ? (
              <img
                src={digImage.image == undefined ? stall_right : digImage.image}
              />
            ) : (
              <img src={stall_right} />
            )}
          </div>
        </div>

        {/* {isInterview === true ?
                <div className={classes.flexOff}>
                    <div className={classes.insideStall2}>
                    </div>
                </div>
                : null} */}

        {this.state.hasVideoInterview === true ? (
          <div className={classes.flexOffInterview}>
            <div className={classes.insideStall2}>
              <div className={classes.closeBtnAlign}>
                {" "}
                <button
                  onClick={this.closeVideoCall}
                  className={classes.closebtnnew}
                >
                  X
                </button>{" "}
              </div>
              {showInterview}
              {/* {SideBar} */}
            </div>
          </div>
        ) : null}

        {/* {hasInterview !== null?
                <div className={classes.flexOff}>
                    <div className={classes.insideStall2}>
                    <div className={classes.closeBtnAlign}> <button onClick={this.closeVideoCall} className={classes.closebtnnew} >X</button> </div>
                        {hasInterview}
                        {SideBar}
                    </div>
                </div>
                :null
                }    */}

        {this.state.openSideBarCom === true ? (
          <div>
            <div className={classes.insideStall2}>
              <div className={classes.closeBtnAlign}>
                {" "}
                <button
                  onClick={this.closeSideBar}
                  className={classes.closebtnnew}
                >
                  X
                </button>{" "}
              </div>
              {SideBar}
            </div>
          </div>
        ) : null}

        {/* Apply */}
        {this.state.openApplyModal === true ? (
          <div className={classes.flexOff}>
            <div className={classes.closeBtnAlign}>
              {" "}
              <button onClick={this.closeApply} className={classes.closebtnnew}>
                X
              </button>{" "}
            </div>
            {/* <div className={classes.insideStall2}>
                        {nearByData}
                        </div> */}
            <div className={classes.aboutTopd}>
              <h3>
                <span className={classes.orange}>STALL CAMPAIGNS</span>
              </h3>
            </div>
            {nearByData}
          </div>
        ) : null}

        {/* Interview */}
        {this.state.openInterviewModal === true ? (
          <div className={classes.flexOff}>
            <div className={classes.closeBtnAlign}>
              {" "}
              <button
                onClick={this.closeInterview}
                className={classes.closebtnnew}
              >
                X
              </button>{" "}
            </div>
            {/* <div className={classes.insideStall2}>
                        {interviewData}
                        </div> */}
            <div className={classes.aboutTopd}>
              <h3>
                <span className={classes.orange}>Interview</span>
              </h3>
            </div>
            {interviewData}
          </div>
        ) : null}

        {/* About */}
        {this.state.openAboutModal === true ? (
          <div className={classes.flexOff}>
            <div className={classes.closeBtnAlign}>
              {" "}
              <button onClick={this.closeAbout} className={classes.closebtnnew}>
                X
              </button>{" "}
            </div>
            {/* <div className={classes.insideStall2}>
                        {aboutData}
                        </div> */}
            <div className={classes.aboutTopd}>
              <h3>
                <span className={classes.orange}>About</span>
              </h3>
            </div>
            {aboutData}
          </div>
        ) : null}

        <div className={classes.navbtn}>
          <div>
            {this.state.currentNum > 0 ? (
              <button className={classes.btn1} onClick={this.previous}>
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              </button>
            ) : null}
          </div>

          <div>
            {this.state.currentNum + 1 < this.state.stalls.length ? (
              <button className={classes.btn2} onClick={this.next}>
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </button>
            ) : null}
          </div>

          <div className={classes.links}>
            <List animated verticalAlign="middle">
              {this.state.linkone != "" ? (
                <List.Item>
                  <Image avatar src={Links} />
                  <List.Content>
                    <List.Header>
                      <a target="_blank" href={this.state.linkone}>
                        Activity 1
                      </a>
                    </List.Header>
                  </List.Content>
                </List.Item>
              ) : null}

              {this.state.linkTow != "" ? (
                <List.Item>
                  <Image avatar src={Links} />
                  <List.Content>
                    <List.Header>
                      <a target="_blank" href={this.state.linkTow}>
                        Activity 2
                      </a>
                    </List.Header>
                  </List.Content>
                </List.Item>
              ) : null}

              {this.state.linkThree != "" ? (
                <List.Item>
                  <Image avatar src={Links} />
                  <List.Content>
                    <List.Header>
                      <a target="_blank" href={this.state.linkThree}>
                        Activity 3
                      </a>
                    </List.Header>
                  </List.Content>
                </List.Item>
              ) : null}
            </List>
          </div>

          {hasInterview !== null &&  this.props.btnDisable ===false? (
            <div className={classes.SideNav}>
              <button
                className="btn btn-primary stall-btn-1"
                style={{ pointer_events: "cursor" }}
                onClick={() => this.openSidebar(1)}
              >
                <img src={OChat} />
                <span>Chat</span>
              </button>
              <button
                className="btn btn-primary stall-btn-1"
                style={{ pointer_events: "cursor" }}
                onClick={() => this.openSidebar(2)}
              >
                <img src={OQueue} />
                <span>Queue</span>
              </button>
              <button
                className="btn btn-primary stall-btn-1"
                style={{ pointer_events: "cursor" }}
                onClick={() => this.openSidebar(3)}
              >
                <img src={OInfo} />
                <span>Info</span>
              </button>
            </div>
          ) : null}

        {
            this.props.btnDisable ===false?

          <div className={classes.BootomNav}>
            <button
              className="btn btn-primary stall-btn-1"
              style={{ pointer_events: "cursor" }}
              onClick={this.openApply}
            >
              <img src={Apply} />
              <span>Apply</span>
            </button>
            <button
              className="btn btn-primary stall-btn-1"
              style={{ pointer_events: "cursor" }}
              onClick={this.OpenInterview}
            >
              <img src={OInterview} />
              <span>Interview</span>
            </button>
            <button
              className="btn btn-primary stall-btn-1"
              style={{ pointer_events: "cursor" }}
              onClick={this.OpenAbout}
            >
              <img src={About} />
              <span>About</span>
            </button>
          </div>:
          null
        }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stallData: state.auth.successEventStall,
    profile: state.profile.userProfile,
    checkValidate: state.CandidateData.validationUrl,
    candidateDate: state.CandidateData.userData,
    webinnar: state.profile.webinar,
    btnDisable: state.auth.btnDisable
    // countTime : state.CandidateData.countTime,
    // timeChange : state.CandidateData.changeTime
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetStallData: (stallId, eventId, senderId) =>
      dispatch(actionsCreator.GetEventStallData(stallId, eventId, senderId)),
    ongetCandidateDataCampaign: (token, currentTime, stallId) =>
      dispatch(
        actionsCreator.getCandidateDataCampaign(token, currentTime, stallId)
      ),
    onShowWebinar: (data) => dispatch(actionsCreator.showWebinar(data)),
    onAccessStall: (data) => dispatch(actionsCreator.AccessStall(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stall);
