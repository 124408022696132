import React from 'react'
import logo from '../../../assets/image/sia-logo.png'
import classes from './index.module.scss'

function mobile() {
    return (
        <div className={classes.Unsupport}>
            <img src={logo} alt="Sia bot" />
            <h2 style={{textAlign:"center"}}>The Current Version of the Sia platform works best on a desktop or a laptop using the latest google chrome browser</h2>
            {/* <h3>Stay tuned for more browser support.</h3> */}
        </div>
    )
}

export default mobile
