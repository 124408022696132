import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FeedbackData from "./EachFeedback/EachFeedback";
import classes from "./FeedBack.module.scss";
import ObjectChecker from "../../../hoc/objectChecker";
import FeedbackCarousel from "./FeedbackCarousel/FeedbackCarousel";
import * as actionsCreator from "../../../store/actions/index";

class FeedBack extends Component {
  componentWillMount() {
    var id = localStorage.getItem("CandidateProfile_id02");
    this.props.getCandidateFeedback(id);
  }
  render() {
    // try {
    //   console.log("opop", this.props.feedback.length);
    // } catch {}
    let feedBackData = [ObjectChecker(this.props.data, ["Feedback"])];
    let data = "";
    console.log("opop", feedBackData[0] && feedBackData[0].length);
    data = feedBackData.map((res, index) => {
      return <FeedbackData data={res} key={index} />;
    });
    return (
      <div className={classes.dashboardFeedback}>
        <div className={classes.title}>Feedback</div>
        {feedBackData[0] && feedBackData[0].length > 0 ? (
          <FeedbackCarousel feedback={this.props.feedback} />
        ) : (
          <div className={classes.feedbackPlaceholder}>
            <div>
              <img src="https://image.flaticon.com/icons/svg/2936/2936605.svg" />
              <div>Feedback you receive will appear here</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    profile: state.CandidateData.userData,
    feedback: state.CandidateData.feedbackData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateFeedback: (data) =>
      dispatch(actionsCreator.getCandidateFeedback(data)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedBack)
);
