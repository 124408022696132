import React, { Component } from "react";
import VideoChatLayout from "../../../components/Layout/VideoChatLayout/VideoChatLayout";
import Classes from "./VideoConference.module.scss";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { BrowserRouter } from "react-router-dom";
import VideoChatVac from "../../VideoChat/videoChatVac";
import Webinnar from "../../../components/Webinnar/webinnar";
import axios from "../../../axios";
import ChatCom from "../../../components/ChatTextNew/ChatTabs/ChatTabs";
import ChatQueue from "../../../components/ChatQueue/ChatQueue";
import Panal from "../../../components/UI/NotificationPanel/Index";
import { connect } from "react-redux";
import * as actionCreater from "../../../store/actions/index";
import objectChecker from "../../../hoc/objectChecker";
import moment from "moment";
import CountdownTimer from "react-component-countdown-timer";
import UserData from "../../../components/UserData/UserData";
import queueIcon from "../../../assets/image/queueIcon.png";
import infoIcon from "../../../assets/image/infoIcon.png";
import chatIcon from "../../../assets/image/chatIcon.png";
import SiaChat from "../../SiaChat/Chat/Chat";
import close from "../../../assets/image/close.png";
import siaWhite from "../../../assets/image/sia-logo-in-white.png";
import Spinner from "../../../components/UI/SpinnerGrey/SpinnerGrey";
import Splash from "../../../components/UI/Splash/Splash";
import { Icon } from "semantic-ui-react";
import GroupList from "../../../components/ChatQueue/QueueList/QueueElement/ElementVas"
import ChatVas from "../../../components/ChatTextNew/ChatTabs/ChatTabsVas";
class VideoConference extends Component {
  // webinarjs
  constructor() {
    super();
    this.state = {
      flag: 0,
      value: 0,
      ref: localStorage.getItem("chat-ref-id"),
      token: localStorage.getItem("chat-token"),
      videoURL: "",
      videoChatStatus: false,
      isModelShow: true,
      notifications: [],
      userId: "",
      textChatCom: false,
      candidatequeue: true,
      basicInfo: false,
      startTime: null,
      countTime: null,
      scheduled_interviewTime: 0,
      state_timeChange: false,
      showSidebar: false, //toggle sidebar in small devices
      lastPressed: null, //toggle sidebar in small devices
      showChat: false,
      loadChat: false,
      newMessage: 2,
      groupChatNotification: false,
      intwChatNotification: false,
      timeUpdated: false,
      inwStatus: 1,
      groupList:{
        list:[],
        room:""
      }
    };  
  }

  componentDidMount() {
    var ref = sessionStorage.getItem("vasRef"); 
    this.props.onGetCampaignData(null, ref); 
  } 
  toggleChat = () => {
    if (this.state.showChat === false) {
      localStorage.setItem("REFRESHSTATUS", true);
    }
    this.setState({
      showChat: !this.state.showChat,
    });
  };

  // updateCurrentTime = () => {
  //   console.log("update time");

  //   try {
  //     var startTime = [
  //       objectChecker(this.props.intwData.InterviewData, ["0", "startTime"]),
  //     ];
  //     var endTime = [objectChecker(this.props.intwData, ["0"])];
  //     var intwStatus = [objectChecker(this.props.intwData.InterviewData, ["0", "status"])]
  //     //moment.utc(this.state.from).format();

  //     var currentTime = moment().toISOString(true).split("+")[0];

  //     var intTime = moment(startTime[0]).toISOString(true).split("+")[0];

  //     console.log(currentTime, "current time pre");
  //     console.log(intTime, "Int time pre");

  //     console.log(moment(currentTime), "current time momen");
  //     console.log(moment(intTime), "interview time moments");

  //     var ms = moment(intTime).diff(moment(currentTime));
  //     var d = moment.duration(ms);
  //     var s = moment.duration(d);

  //     this.setState({
  //       countTime: ms,
  //     });

  //     if (parseInt(ms) > parseInt(this.state.scheduled_interviewTime)) {
  //       this.setState({
  //         scheduled_interviewTime: ms,
  //         state_timeChange: true,
  //       });
  //     } else {
  //       this.setState({
  //         state_timeChange: false,
  //       });
  //     }

  //     console.log(moment(currentTime)._i, "current time with out local");
  //     console.log(moment(currentTime)._i, "current time");
  //     console.log(moment(intTime)._i, "interivew time");

  //     var cu_time = moment();
  //     var localTime = moment(cu_time)._d;
  //     console.log(localTime, "local time browser");

  //     var interviewId = objectChecker(this.props.intwData, ["AppliedID"]);

  //     console.log(interviewId, "Interview id");

  //     //console.log(s, "Results");
  //     if (moment(intTime)._i < moment(currentTime)._i) {
  //       localStorage.setItem("CALL_STATUS", true); //temparary
  //       localStorage.setItem("Flag status", 1);
  //       this.setState({
  //         flag: 1,
  //         inwStatus: intwStatus
  //       });

  //       console.log(
  //         localStorage.getItem("Video_chat_complete"),
  //         "Video chatg complete flag"
  //       );

  //       //send interview log time
  //       // if (this.state.timeUpdated === false && localStorage.getItem("Video_chat_complete") === null) {
  //       //   this.props.onUpdateTimes(ms, interviewId);
  //       //   this.setState({
  //       //     timeUpdated : true
  //       //   })
  //       //   localStorage.setItem("TIME_UPDATED", true)
  //       // }

  //       // localStorage.getItem("Video_chat_complete") === null
  //       if (
  //         this.state.timeUpdated === false
  //       ) {
  //         this.props.onUpdateTimes(ms, interviewId);
  //         this.setState({
  //           timeUpdated: true,
  //         });
  //         localStorage.setItem("TIME_UPDATED", true);
  //       }
  //     }
  //   } catch {
  //     console.log("pending");
  //   }
  // };

  changeSubComFlag = (id) => {  
      if (id === 1) {
        this.setState({
          candidatequeue: true,
          textChatCom: false,
          basicInfo: false,
        });
      } else if (id === 2) {
        this.setState({
          candidatequeue: false,
          textChatCom: false,
          basicInfo: true,
        });
      } else if (id === 3) {
        this.setState({
          candidatequeue: false,
          textChatCom: true,
          basicInfo: false,
        });
      } 
  };

  render() {
    localStorage.setItem("Flag status", this.state.flag);
    var appliedId = objectChecker(this.props.intwData, ["AppliedID"]);
    var interviewAppliedId = objectChecker(this.props.intwData, ["AppliedID"]);
    var campaignId = objectChecker(this.props.intwData, ["InterviewData"]);
    var panelDuration = objectChecker(this.props.intwData, ["PanelDuration"]);
    appliedId = objectChecker(campaignId, ["0", "interviewId"]);
    campaignId = objectChecker(campaignId, ["0", "campaignId"]);
    localStorage.setItem("AppliedID", interviewAppliedId);
    localStorage.setItem("ChannelID", campaignId);
    localStorage.setItem("ChannelID_interview", interviewAppliedId);

    let showcomponent = "";
    let subComponent = "";

    showcomponent = (
      <VideoChatVac
        intData={interviewAppliedId}
        durationPanel={panelDuration}
        intwStatus={true}
        groupList={this.state.groupList}
      />
    );
    if (this.state.candidatequeue === true) {
      subComponent = (
        <GroupList
          intData={this.props.intwData}
          appliedId={appliedId}
          campaignId={campaignId}
          groupList={this.state.groupList}

        />
      );
    } else if (
      this.state.textChatCom === true
    ) {
      subComponent = (
        <ChatVas
          intData={campaignId}
          appliedId={interviewAppliedId}
          flagStatus={this.state.flag}
          newMessage={this.state.newMessage}
          interviewData={this.props.campaignData.interview}
          groupChatNotification={this.state.groupChatNotification}
          intwChatNotification={this.state.intwChatNotification}
          is_interview={true}
        />
      );
    } else if (this.state.basicInfo === true) {
      subComponent = (
        <UserData intData={this.props.intwData} campaignId={campaignId} />
      );
    }
    var chatShow = this.state.showChat;
    return (
      <VideoChatLayout title="Video Panel">
        {/* {this.props.intwData !== null ? ( */}
        <div className={Classes.vchatContainer}>
          <div className={Classes.vcol1}>{showcomponent}</div>
          <div className={Classes.vcol2}>
            <div className={Classes.buttonWrap} title="Chat">
            <a onClick={() => this.changeSubComFlag(1)}>
                <img src={queueIcon} />
              </a>
            </div>
            <div className={Classes.buttonWrap} title="">             
              <a onClick={() => this.changeSubComFlag(3)}>
                <img src={chatIcon} />
                <Icon
                  name="circle"
                  className={
                    this.state.intwChatNotification ||
                      this.state.groupChatNotification
                      ? Classes.messageArrived
                      : Classes.messageArrivedHide
                  }
                  size="mini"
                />
              </a>
            </div>
            <div className={Classes.buttonWrap} title="Campaign Info">
              <a onClick={() => this.changeSubComFlag(2)}>
                <img src={infoIcon} />
              </a>
            </div>
          </div>
          <div
            className={`${Classes.vcol3} ${this.state.showSidebar ? Classes.showSidebar : ""
              }`}
          >
            {subComponent}
          </div>
          <SiaChat show={chatShow} />
          <button onClick={this.toggleChat} className={Classes.siaIconChat}>
            {chatShow ? <img src={close} /> : <img src={siaWhite} />}
          </button>
        </div>
        {/* ) : (
          <div style={{ margin: "auto" }}>
            <Splash />
          </div> */}
        {/* )} */}
      </VideoChatLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    intwData: state.CandidateData.interviewData,
    inviteInteview: state.auth.interviewInvite,
    timeUpdate: state.CandidateData.updatedTime,
    campaignData: state.CandidateData.campaignData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateTimes: (ms, inwId) =>
      dispatch(actionCreater.updateTimes(ms, inwId)),
    onGetIntData: (data) => dispatch(actionCreater.getInterviewData(data)),
    onShowWebinar: (data) => dispatch(actionCreater.showWebinar(data)),
    //onsaveMiliSec : (data)=> dispatch(actionCreater.saveMilisec(data))
    onGetCampaignData: (data, ref) =>
      dispatch(actionCreater.getCampaignData(data, ref)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoConference);
