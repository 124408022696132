import React from 'react';
import classes from './NotFound.module.scss';
import wallImg from '../assets/image/wall.png';
import Logo from '../assets/image/sia-logo.png';
import { Link } from 'react-router-dom'

export default () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.Wall} style={{ backgroundImage: `url(${wallImg})` }}></div>
      <div className={classes.right}>
        <div className={classes.content}>
          <img src={Logo} alt="Sia Logo" />
          <h2>404 Page not found</h2>
          <div className={classes.linkBack}>
            <i className="fa fa-home" />
            <Link to="/">Take me to home</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
