import React, { Component } from "react";
import Chat from "twilio-chat";
import axios from "../../axios";
import Classes from "./ChatAppX.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import ManagerPic from "../../assets/image/manager.png";
import userPic from "../../assets/image/profile-user.png";
import { TransportUnavailableError } from "twilsock";
import SiaLogo from "../../assets/image/sia.png";
import { connect } from "react-redux";
import * as actionsCreater from "../../store/actions/index";
import Spinners from "../UI/SpinnerGrey/SpinnerGrey";
import objectChecker from "../../hoc/objectChecker";
import * as actionCreater from "../../store/actions/index";

class ChatApp extends Component {
  constructor(props) {
    super(props);
    const name = localStorage.getItem("name") || "";
    const loggedIn = name !== "";
    this.sendMessageFromSystem = this.sendMessageFromSystem.bind(this);
    this.state = {
      token1: localStorage.getItem("chat-token"),
      flagStatus: localStorage.getItem("Flag status"),
      interviewStartTime: null,
      name: "",
      loggedIn: true,
      token: "",
      chatReady: false,
      messages: [],
      newMessage: "",
      URL: process.env.REACT_APP_API_URL + "manager",
      interviewID: 1,
      channelName: null,
      messageSender: false,
      userName: "",
      profilePicUrl: "",
      userMessage: "",
      userName: "",
      ref_id: localStorage.getItem("ref_id"),
      userId: "",
      interviewData: [],
      notification_status: 0,
      notificationMessage: "",
      updateFunction: 0,
      notificationSend: false,
      fullName: "",
      channelUnique:process.env.REACT_APP_CHANEL_NAME
    };
  }

  componentDidUpdate = () => {
    //var note =this.getNotificationStatus()
  };

  componentDidMount = () => {
    this.getUser();
    //   this.getDifUser();

    setTimeout(() => {
      if (this.state.ref_id == "null") {
        this.getChannelName();
      } else if (this.state.ref_id != "null") {
        //   this.getChanelNameByRef();
      }
      this.getToken();
      //   this.getNotificationMessages();
    }, 1000);
  };

  getNotificationMessages = () => {
    setInterval(() => {
      axios
        .get(process.env.REACT_APP_API_URL + "chat/group_chat_notifications/", {
          headers: {
            Authorization: `token ${this.state.token1}`,
          },
        })
        .then((res) => {
          var result = res.data.message;
          // console.log(result, "Results data")
          if (result != undefined || result != null) {
            var url = "sia";
            var name = "Sia";
            result = result + "||_||" + "" + "||_||" + url;
            this.setState({
              newMessage: result,
            });
            this.sendMessageFromSystem();
          }
        });
    }, 60000);
  };

  onNameChanged = (event) => {
    this.setState({ name: event.target.value });
  };

  getChannelName = () => {
    ///const campaginID = window.location.href.split("/")[4];
    //var name = (campaginID).split('??')[1]
    var campaignId = this.props.intwData.InterviewData.campaignId;
    var campaginID = objectChecker(campaignId, ["0", "campaignId"]);
    var chanName = this.state.channelUnique+"siaCampaign_" + this.props.intData;
   // console.log(this.props.intData, "Interview Data");
  //  console.log(campaginID, "campaign id");
   // console.log(chanName, "chnnamegrpchat");
    this.setState({
      channelName: chanName,
    });
  };
  getChanelNameByRef = () => {
    axios
      .get(this.state.URL + "/interviews/", {
        headers: {
          Authorization: `token ${this.state.token1}`,
        },
      })
      .then((res) => {   
        const row = res.data.results.find(
          (ref) => ref.ref == this.state.ref_id
        );   
        var Name ="siaCampaign_" +this.props.intwData.InterviewData.campaignId;
        localStorage.setItem(Name, "Channel name chat");
        this.setState({
          channelName: Name,
        });
      });
  };

  getNotificationStatus = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "chat/group_chat_notifications/", {
        headers: {
          Authorization: `token ${this.state.token1}`,
        },
      })
      .then((res) => {
        var result = res.data.message;
        if (result) {
          var url = "sia";
          var name = "Sia";
          result = result + "||_||" + "" + "||_||" + url;
          this.setState({
            newMessage: result,
          });
          //this.sendMessageFromSystem()
        }
      });
    // var notifyMesag = ''
    // if(notification != ''){
    //   var url = "sia"
    //   var name = "Sia"
    //   var notification = notification +"||_||" + url + name
    //   this.setState({
    //     newMessage : notification
    //   })
    //   notifyMesag = notification
    // }
    // return notifyMesag
  };

  getToken = () => {
    var defaultId = 1;
    axios
      .post(
        this.state.URL + "/chat/",
        {
          interview_id: defaultId,
        },
        {
          headers: {
            Authorization: `Token ${this.state.token1}`,
          },
        }
      )
      .then((res1) => {
        // console.log(this.state.ref_id, "Ref id")
        // console.log(res1.data.twilio_token, "Twillio token")
        this.setState({ token: res1.data.twilio_token }, this.initChat);
      });
  };

  initChat = () => {
    console.log("Inii chat");
    this.chatClient = new Chat(this.state.token);
    console.log(this.chatClient, "Chat client");
    this.chatClient.initialize().then(this.clientInitiated.bind(this));
  };

  clientInitiated = () => {
    console.log("Client initialized");
    var campaginID = [objectChecker(this.props.intwData, ["0", "campaignId"])];
    var chanName = this.state.channelUnique+"siaCampaign_" + this.props.intData;
    console.log(chanName, "Channel name campaign");
    this.setState({ chatReady: true }, () => {
      this.chatClient
        .getChannelByUniqueName(chanName)
        .then((channel) => {
          if (channel) {
            return (this.channel = channel);
          }
        })
        .catch((err) => {
          if (err.body.code === 50300) {
            return this.chatClient.createChannel({
              uniqueName: chanName,
            });
          }
        })
        .then((channel) => {
          this.channel = channel;
          //window.channel = channel;
          return this.channel.join().catch(() => {});
        })
        .then(() => {
          this.channel.getMessages().then(this.messagesLoaded);
          this.channel.on("messageAdded", this.messageAdded);
        });
    });
  };

  messagesLoaded = (messagePage) => {
    this.setState({ messages: messagePage.items });
  };

  messageAdded = (message) => {
    this.setState((prevState, props) => ({
      messages: [...prevState.messages, message],
    }));
  };

  onMessageChanged = (event) => {
    this.setState({
      newMessage:
        event.target.value +
        "||_||" +
        this.state.profilePicUrl +
        "||_||" +
        this.state.fullName +
        "||_||" +
        "candiate",
      userMessage: event.target.value,
    });
  };

  saveMessageDb = (msg) => {
    // console.log(msg, "Message")
    // console.log(this.message.body, "Body")
    // console.log(this.message.author, "Author")
    // axios.post(this.state.URL+'/chat-messages/', {
    //   in_id: this.state.interviewID,
    //   message: msg,
    //   author: 'test'
    //   //data_time: msg.tim
    // },
    //   {
    //     headers: {
    //       Authorization: `Token ${this.state.token1}`
    //     }
    //   })
  };

  sendMessage = (event) => {
    event.preventDefault();
    try {
      if (this.state.userMessage != "") {
        var extravtedMessage = this.state.newMessage.substring(
          0,
          this.state.newMessage.indexOf("||_||")
        );
        if (extravtedMessage.trim() == "") {
          return;
        } else {
          const message = this.state.newMessage;
          // console.log(message, "Message")
          this.setState({
            newMessage: "",
            messageSender: true,
            userMessage: "",
            notification_status: 0,
          });
           console.log(this.channel, "Console channel")
          this.channel.sendMessage(message);
          this.saveMessageDb(message);

          var data= {        
            notification: this.state.userMessage,
            campaignId :this.props.intwData.InterviewData.campaignId,
            interviewId:this.state.interviewID,
            userId: this.props.intwData.InterviewData.userID,
            status : 0,
            roomType: "CAMPAIGN"
        }   
        this.props.getNotification(data)
        this.sendChatNotification()
        }
      }
    } catch {}
  };

  sendChatNotification = () => {
    var data ={
      message: this.state.userMessage,
      type: 1,
      senderId :this.props.intwData.InterviewData.userID,
      channelName: this.state.channelName
    }
    axios
      .post("send-chat-msg-notify/",data, {
        headers: {
          Authorization: `token ${this.state.token1}`,
        },
      })
      .then((res) => {

      });
  };

  sendMessageFromSystem = () => {
    try {
      const message = this.state.newMessage;
      this.setState({
        newMessage: "",
        messageSender: true,
      });
      // console.log(this.channel, "channel")
      this.channel.sendMessage(message).bind(this);
    } catch {}
  };

  newMessageAdded = (li) => {
    if (li) {
      li.scrollIntoView();
    }
    this.setState({
      messageSender: false,
    });
  };
  //Get user data
  getUser = () => {
    axios
      .get(this.state.URL + "/users/me/", {
        headers: {
          Authorization: `token ${this.state.token1}`,
        },
      })
      .then((res1) => {
        //  console.log(res1.data, "Auth data")
        var picUrl = res1.data["candidate_profile"]["picture_url"];
        var fullName_user =
          res1.data["candidate_profile"]["first_name"] +
          " " +
          res1.data["candidate_profile"]["last_name"];
        this.setState({
          userName: res1.data.email,
          profilePicUrl: picUrl,
          fullName: fullName_user,
        });
      });
  };

  //Get deferent user data
  // getDifUser = () => {
  //   // console.log("get dif users")
  //   axios
  //     .get(this.state.URL + "/users/", {
  //       headers: {
  //         Authorization: `token ${this.state.token1}`,
  //       },
  //     })
  //     .then((res) => {
  //       // console.log(res.data, "Users")
  //     });
  // };

  timeConverter = (datetime) => {
    var time = datetime.split("Z")[0].split("T")[1];
    var date = datetime.split("Z")[0].split("T")[0];
    const startTime = date + " " + time;
    var offset = new Date().getTimezoneOffset();
    var start_time_local = new Date(Date.parse(startTime) - offset * 60000);
    start_time_local =
      start_time_local.getHours() +
      ":" +
      start_time_local.getMinutes() +
      ":" +
      start_time_local.getSeconds();
    start_time_local = date + " " + start_time_local;
    return start_time_local;
  };

  getTime = (DTime) => {
    var timeDate = new Date(DTime);
    var localDate =
      timeDate.getFullYear() +
      "-" +
      (timeDate.getMonth() + 1) +
      "-" +
      timeDate.getDate();
    var localTime =
      timeDate.getHours() +
      ":" +
      timeDate.getMinutes() +
      ":" +
      timeDate.getSeconds();
    return localTime;
  };

  timeDifference = (previous) => {
    var newDate = new Date();
    var current = new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      newDate.getDate(),
      newDate.getHours(),
      newDate.getMinutes(),
      newDate.getSeconds(),
      newDate.getMilliseconds()
    );
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + " days ago";
    } else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + " months ago";
    } else {
      return Math.round(elapsed / msPerYear) + " years ago";
    }
  };

  getTime = (DTime) => {
    var timeDate = new Date(DTime);
    var localDate =
      timeDate.getFullYear() +
      "-" +
      (timeDate.getMonth() + 1) +
      "-" +
      timeDate.getDate();
    var localTime =
      timeDate.getHours() +
      ":" +
      timeDate.getMinutes() +
      ":" +
      timeDate.getSeconds();

    var date = new Date(
      timeDate.getFullYear(),
      timeDate.getMonth() + 1,
      timeDate.getDate(),
      timeDate.getHours(),
      timeDate.getMinutes(),
      timeDate.getSeconds(),
      0
    );
    return this.timeDifference(date);
  };

  render() {
    var timespan = require("readable-timespan");

    // console.log(this.props.intwData, "interview data")
    // console.log([objectChecker(this.props.intwData, ['0', 'campaignId'])], "campaign id")

    // console.log("Last seen " + timespan.parse(5 * 60 * 1000) + " ago");
    //setInterval(this.getNotificationStatus(), 60000)

    let notification = "";
    if (this.state.notificationMessage == "null") {
      notification = this.state.notificationMessage;
    }

    const messages = this.state.messages.map((message) => {
      var user = this.state.userName.split("@")[0];
      console.log("MESSAGEF", message);
      var messageBody = message.body.split("||_||")[0];
      let ProfUrl = message.body.split("||_||")[1];
      let authorName = message.body.split("||_||")[2];
      var userType = message.body.split("||_||")[3];
      var timespan = require("readable-timespan");
      var msgTime = this.getTime(message.timestamp);
      // var msgTime = timespan.parse(message.timestamp.getTime() |0)
      var name = message.author;

      let scssClass = "";
      let imgClass = "";
      let textClass = "";
      let picUrl = "";
      let placeholderUrl = "";
      let messageAuthor = "";

      if (userType == "sia") {
        picUrl = SiaLogo;
        name = "sia";
      } else if (userType == "Manager") {
        picUrl = ProfUrl;
        placeholderUrl = ManagerPic;
      } else {
        picUrl = ProfUrl;
        placeholderUrl = userPic;
      }

      if (JSON.stringify(user) != JSON.stringify(name)) {
        scssClass = Classes.Wrapper;
        imgClass = Classes.ImgUser;
        textClass = Classes.TextClass;
        messageAuthor = "xxxxxxxx";
        // messageAuthor = message.body.split("||_||")[2].substr(0, 4) + "xxxxxxx";
      } else if (JSON.stringify(user) == JSON.stringify(name)) {
        scssClass = Classes.WrapperSender;
        imgClass = Classes.ImgNewUser;
        textClass = Classes.TextClassLeft;
        messageAuthor = authorName;
      }

      return (
        <div className={Classes.RowStyle}>
          <table className={scssClass}>
            <tr>
              <td>
                <div className={imgClass}>
                  <img src={picUrl ? picUrl : placeholderUrl} val="ctn"></img>
                </div>
              </td>
              <td>
                <div className={Classes.UserListAuthor}>{messageAuthor}</div>
                <div className={textClass}>
                  <ul key={message.sid} ref={this.newMessageAdded}>
                    <li>{messageBody}</li>
                    <li className={Classes.UserListAuthorTime}>{msgTime}</li>
                  </ul>
                </div>
              </td>
            </tr>
          </table>
        </div>
      );
    });
    return (
      <div className={Classes.SendInput}>
        {console.log("chatready::::::", this.state.chatReady)}

        <div className={Classes.ScroleControll}>
          {this.state.chatReady === false ? (
            <div className={Classes.SpinnerOuterWrap}>
              <div className={Classes.SpinnerWrap}>
                <Spinners />
              </div>
            </div>
          ) : (
            <div className={Classes.ScroleStyle}>
              <ul>
                <li>{messages}</li>
                {/* <li>{notification}</li> */}
              </ul>
            </div>
          )}
        </div>
        {this.state.chatReady === true ? (
          <form className={Classes.TypeMessage} onSubmit={this.sendMessage} style={{color:"#020202"}}>
            {/* <label htmlFor="message"></label> */}
            <input
              type="text"
              name="message"
              id="message"
              placeholder="Type your message"
              onChange={this.onMessageChanged}
              value={this.state.userMessage}
              autocomplete="off"
            />
            <button>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </form>
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
  //  intwData: state.CandidateData.interviewData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetNotification: () => dispatch(actionsCreater.getNotification()),
    getNotification :(data)=> dispatch(actionCreater.getChatNotification(data))
  };
};

//export default ChatApp;
export default connect(mapStateToProps, mapDispatchToProps)(ChatApp);
