import * as actions from "../actions/actionTypes";
// import updatedObjecct from '../utiliti'

const initaialState = {
  auth: false,
  loading: false,
  redirectUrl: "/",
  message: null,
  OnboardUser: null,
  phoneNumber: null,
  location: null,
  skills: null,
  cvDataExp: null,
  cvDataEdu: null,
  cvString: null,
  preferences: null,
  itemListId: null,
  eduList: [],
  interviewInvite: null,
  skipppedCampaigns: null,
  systemData: null,
  somethingChanged: false,
  availableInterview: null,
  urlStateValues: null,
  recruiterState: null,
  eventData:null,
  successEvtParticipents: null,
  errorEvtParticipents:null,
  errorCandidateDashboard: null,
  successCandidateDashboard: null,
  errorEventTitleBar:null,
  successEventTitleBar:null,
  errorEventStall:null,
  successEventStall:null,
  errorEvtNotifications: null,
  successEvtNotifications: null,
  successAllEvts: null,
  errorAllEvts:null,
  accessStall: false,
  btnDisable: false
  // changeTime: false,
  // countTime: 0 
};

const reducer = (state = initaialState, action) => {
  switch (action.type) {
    case actions.AUTH_INIT:
      return {
        ...state,
        loading: true,
      };
    case actions.AUTH_SUCCESS:
      return {
        ...state,
        message: "Success fully loged",
        loading: false,
        auth: true,
      };
    case actions.AUTH_FAIL:
      return {
        ...state,
        message: "Not loging",
        loading: false,
      };
    case actions.AUTH_REDIRECT:
      return {
        ...state,
        auth: true,
      };
    case actions.ONBOARD_INIT:
      return {
        ...state,
        message: "Successfully Onboard",
        loading: true,
      };

    case actions.ONBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.ONBOARD_FAIL:
      return {
        ...state,
        auth: false,
        OnboardUser: action.data,
      };
    case actions.SEND_ONBOARD_DATA:
      return {
        ...state,
        phoneNumber: action.data,
      };
    case actions.SEND_ONBOARD_DATA_LOCATION: 
      return {
        ...state,
        location: action.data,
      };
    case actions.CV_STRING:
      return {
        ...state,
        cvString: action.data,
      };
    case actions.SKILLS_TAGS:
      return {
        ...state,
        skills: action.data,
      };
    case actions.SUCCESS_EXP:
      return {
        ...state,
        cvDataExp: action.data,
      };
    case actions.SUCCESS_EDU:
      return {
        ...state,
        cvDataEdu: action.data,
      };
    case actions.DELETE_ITEM_LIST:
      return {
        ...state,
        itemListId: action.data,
      };
    case actions.EXP_LIST:
      return {
        ...state,
        eduList: action.data,
      };
    case actions.ON_SUCCESS_INTERVIEW_INVITE:
      return {
        ...state,
        interviewInvite: action.data,
      };
    case actions.GET_CAMPAIGN_SKIPPED_DATA:
      return {
        ...state,
        skipppedCampaigns: action.data,
      };
    case actions.SUCCESS_SYSTEM_LEVEL_DATA:
      return {
        ...state,
        systemData: action.data,
      };
    case actions.SET_SOMETHING_CHANGED: 
      return {
        ...state,
        somethingChanged: action.data,
      };
    case actions.GET_AVAILABLE_INTERVIEW:
      return{
        ...state,
        availableInterview: action.data
      }
    case actions.GET_SUCCESS_URL_STATE:
      return{
        ...state,
        urlStateValues: action.data
      }
    case actions.GET_RECRUITER_STATUS_SUCCESS:
      return{
        ...state,
        recruiterState: action.data
      }
    case actions.GET_EVENT_DATA_SUCCESS:
      return{
        ...state,
        eventData: action.data
      }
    case actions.SUCCESS_EVENT_PARTICIPENTS:
      console.log(action.data, "Action data reducer")
      return{
        ...state,
        successEvtParticipents: action.data
      }
    case actions.ERROR_EVENT_PARTICIPENTS:
      return{
        ...state,
        errorEvtParticipents: action.data
      }
    case actions.SUCCESS_BTN_DISABLE:
      return{
        ...state,
        btnDisable: action.data
      }
    case actions.SUCCESS_EVENT_TITLE_BAR:
      return{
        successEventTitleBar: action.data
      }
    case action.ERROR_EVENT_TITLE_BAR:
      return{
        errorEventTitleBar: action.data
      }
    case action.ERROR_EVENT_STALL_DATA:
      return{
        errorEventStall: action.data
      }
    case action.SUCCESS_EVENT_STALL_DATA:
      return{
        successEventStall: action.data
      }
    case action.ERROR_EVENT_NOTIFICATIONS:
      return{
        errorEvtNotifications: action.data
      }
    case action.SUCCESS_EVENT_NOTIFICATIONS:
      return{
        successEvtNotifications: action.data
      }
    case actions.SUCCESS_GET_ALL_EVENT_DATA:
      console.log(action.data, "Action data reducer")
      return{
        ...state,
        successAllEvts: action.data
      }
    case actions.SUCCESS_ACCESS_STALL:
      return{
        ...state,
        accessStall: action.data
      }
    default:
      return state;
  }
};
export default reducer;
