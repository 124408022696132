import React from 'react'
import logo from '../../../assets/image/sia-logo.png'
import classes from './index.module.scss'

function index() {
    return (
        <div className={classes.Unsupport}>
            <img src={logo} alt="Sia bot" />
            <h2 style={{textAlign:"center"}}>You're using a browser we do not support. Please use Google Chrome for the best experience on the platform. If you would like to download it, please <a style={{textDecoration:"underline",color:"#4183c4"}} target="_blank" href="https://www.google.com/chrome/">follow this link.</a></h2>
            {/* <h3>Stay tuned for more browser support.</h3> */}
        </div>
    )
}

export default index
