import React, { Component } from "react";
import classes from "./FilteredDataforSettings.module.scss";
import CVTags from "./CVTagsForSettings";
import "./FilteredData.scss";
import { connect } from "react-redux";
import * as actionsCreator from "../../../store/actions/index";
import Document from "../../../assets/image/paper.svg";
import Education from "../cvFilteredData/EducationInfo/EduEachData";
import Experience from "../cvFilteredData/ExperienceInfo/ExperienceInfo";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert2";
import Preferences from "../CareerPreferences/PreferencesForSettings";
import { Modal, Dimmer, Loader } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";

class FilterData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputEducation: false,
      inputExperience: false,
      instututeName: "",
      instituteType: "",
      instituteSubject: "",
      instituteGrade: "",
      xprienceCompany: "",
      xpriencePosition: "",
      xperienceDuration: "",
      educationList: [],
      experienceList: [],
      arrayDataEdu: [],
      arrayDataExp: [],
      alert: false,
      text: "",
      uploadingstatus: false,
      showUploadLoader: false,
      cvURl: "",
      cvType: "",
      showCVButtons: true,
      isViewModalOpen: false,
      optionsInstitute: [],
      optionsGrade: [],
      optionsCrouse: [],
      optionsCompany: [],
      optionsDurations: [],
      optionsTitle: [],
      isEditEducation:false,
      eduIndex:0,
      isEditExperience:false,
      expIndex:0
    };
    this.handleNamechange = this.handleNamechange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleGrade = this.handleGrade.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handlePOsition = this.handlePOsition.bind(this);
    this.handleDuration = this.handleDuration.bind(this);
    this.handleEduBtn = this.handleEduBtn.bind(this);
    this.handleExpBtn = this.handleExpBtn.bind(this);
    this.validator = new SimpleReactValidator();
    this.validatorExp = new SimpleReactValidator();

    this.handleCloseEdu = this.handleCloseEdu.bind(this);
    this.handleCloseExp = this.handleCloseExp.bind(this);
  }

  handleEduBtn = () => {
    this.setState({
      inputEducation: !this.state.inputEducation,
    });
  };
  handleExpBtn = () => {
    this.setState({
      inputExperience: !this.state.inputExperience,
    });
  };

  handleCloseExp = () => {
    this.setState({
      inputExperience: false,
    });
    this.forceUpdate();
  };
  handleCloseEdu = () => {
    this.setState({
      inputEducation: false,
    });
    this.forceUpdate();
  };

  handleOpenViewModal = () => {
    this.setState({
      isViewModalOpen: true,
    });
  };

  handleCloseViewModal = () => {
    this.setState({
      isViewModalOpen: false,
    });
  };

  enterEducationDetails = () => {
    if (this.validator.allValid()) {
      this.editEducationDetails()
      this.props.setChanged(true);
      var newData = {
        institution: this.state.instututeName,
        type_ins: this.state.instituteType,
        stream: this.state.instituteSubject,
        grade: this.state.instituteGrade,
      };
      this.setState({
        educationList: [...this.state.educationList, ...[newData]],
      });
      this.props.settingData.education.push(newData);
      this.setState({
        instututeName: "",
        instituteType: "",
        instituteSubject: "",
        instituteGrade: "",
      });
      this.validator.hideMessages();

      var edulist = [...this.props.eduList, ...[newData]];

      var arrayData = (
        <Education
          deleteItemEduc={this.deleteEducation}
          data={this.props.settingData.education}
          editEducation={this.editEducation}
        />
      );
      this.setState({
        arrayDataEdu: arrayData,
      });

      this.props.onSendEdu(edulist);
      this.setState({
        inputEducation: !this.state.inputEducation,
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  enterExperienceDetails = () => {
    if (this.validatorExp.allValid()) {
      this.editExperienceDetails()
      this.props.setChanged(true);
      var newData = {
        company: this.state.xprienceCompany,
        position: this.state.xpriencePosition,
        duration: this.state.xperienceDuration,
      };
      this.setState({
        experienceList: [...this.state.experienceList, ...[newData]],
      });
      this.props.settingData.experience.push(newData);

      this.setState({
        xprienceCompany: "",
        xpriencePosition: "",
        xperienceDuration: "",
      });
      this.validatorExp.hideMessages();

      var expData = (
        <Experience
          deleteExpe={this.deleteExperience}
          data={this.props.settingData.experience}
          editExperience={this.editExperience}
        />
      );
      this.setState({
        arrayDataExp: expData,
      });
      this.props.onSendExp(this.state.experienceList);
      this.setState({
        inputExperience: !this.state.inputExperience,
      });
    } else {
      this.validatorExp.showMessages();
      this.forceUpdate();
    }
  };

  editEducation=(i)=>{
    this.setState({
      inputEducation: true,
    });
    var val = this.state.educationList[i]
    console.log(val)
    this.setState({
      instututeName: val.institution,
      instituteType: val.type_ins,
      instituteSubject: val.stream,
      instituteGrade: val.grade,
      isEditEducation:true,
      eduIndex:i
    })
  }
  editExperience=(i)=>{
    this.setState({inputExperience:true});
    var value = this.state.experienceList[i]
    console.log(value)

    this.setState({
      xprienceCompany:value.company,
      xpriencePosition:value.position,
      xperienceDuration:value.duration,
      isEditExperience:true,
      expIndex:i
    })
  }

  editEducationDetails=()=>{
    if(this.state.isEditEducation==true){
        this.deleteEducation(this.state.eduIndex)
    }
  }
  editExperienceDetails=()=>{
   if(this.state.isEditExperience==true){
     this.deleteExperience(this.state.expIndex)
   }
  }

  handleNamechange = (e, { value }) => {
    this.setState({
      instututeName: value,
    });
  };

  handleNameAddition = (e, { value }) => {
    this.state.optionsInstitute.push({ text: value, value: value });
  };

  handleTypeChange = (e) => {
    this.setState({
      instituteType: e.target.value,
    });
  };

  handleSubjectChange = (e, { value }) => {
    this.setState({
      instituteSubject: value,
    });
  };

  handleSubjectAddition = (e, { value }) => {
    this.state.optionsCrouse.push({ text: value, value: value });
  };
  handleGrade = (e, { value }) => {
    this.setState({
      instituteGrade: value,
    });
  };
  handleGradeAddition = (e, { value }) => {
    this.state.optionsGrade.push({ text: value, value: value });
  };

  handleCompany = (e, { value }) => {
    this.setState({
      xprienceCompany: value,
    });
  };

  handleCompanyAddition = (e, { value }) => {
    this.state.optionsCompany.push({ text: value, value: value });
  };

  handlePOsition = (e, { value }) => {
    this.setState({
      xpriencePosition: value,
    });
  };

  handlePositionAddition = (e, { value }) => {
    this.state.optionsTitle.push({ text: value, value: value });
  };

  handleDuration = (e, { value }) => {
    this.setState({
      xperienceDuration: value,
    });
  };

  handleDurationAddition = (e, { value }) => {
    this.state.optionsDurations.push({ text: value, value: value });
  };

  submitCVData = () => {
    this.props.onGetChatResponseNew("/Submit_cv_data", false);
    localStorage.setItem("ON_CLOSE_MODAL", true);
  };

  handleFileChangeSetting = (e) => {
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      var extensions = e.target.files[0].name.split(".").pop();
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      var b64String = "";
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("candidateCV", preview);
        this.props.settingData.upload = preview;
        this.props.settingData.format_type = extensions;
      };
      var candidateID = this.props.candidateDate.userData.profile[0].user_id;
      var data = {
        uploaded_at: new Date().toISOString(),
        upload: localStorage.getItem("candidateCV"),
        user_id: candidateID,
        cv_type: 1,
      };
      localStorage.setItem("UPLOADING_STATUS", true);
      var dataProgress = true;
      this.props.onCVProgress(dataProgress);
      // this.props.onUploadCV(data);
      this.setState(
        {
          uploadingstatus: true,
          showUploadLoader: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              showUploadLoader: false,
            });
            swal.fire({
              toast: true,
              position: "top-end",
              title: "Successfully uploaded",
              showConfirmButton: false,
              timer: 3000,
              customClass: { container: "swal-success-settings" },
              imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
              imageHeight: 20,
            });
            this.props.setChanged(true);
          }, 2000);
        }
      );
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });
      swal.fire({
        position: "center",
        // icon: "warning",
        imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
        title: "Warning!",
        text: "Only PDF/DOC/DOCX formats allowed",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  componentDidMount = () => {
    let upload = 0;
    upload =
      this.props.candidateDate.userData.cv.length > 0 &&
      this.props.candidateDate.userData.cv[0].upload;
    if (
      this.props.candidateDate !== null &&
      this.props.candidateDate.userData.cv.length > 0
    ) {
   
      this.setState({
        cvURl:
          this.props.candidateDate.userData.cv[0] &&
          this.props.candidateDate.userData.cv[0].upload,
        cvType: upload.substr(upload.length - 3, 3),
      });
    }
    
    this.getExistingData_exp();
    this.getExistingData_edu();

    this.getInstitutions();
    this.getGrades();
    this.getCrouse();
    this.getDuration();
    this.getPositions();
    this.getCompany();
  };

  getExistingData_exp = () => {
    var expData = (
      <Experience
        deleteExpe={this.deleteExperience}
        data={this.props.settingData.experience}
        editExperience={this.editExperience}
      ></Experience>
    );
    this.setState({
      arrayDataExp: expData,
    });
    this.props.settingData.experience.map((res) => {
      this.state.experienceList.push(res);
    });
  };

  getExistingData_edu = () => {
    var arrayData = (
      <Education
        deleteItemEduc={this.deleteEducation}
        data={this.props.settingData.education}
        editEducation={this.editEducation}
      />
    );
    this.setState({
      arrayDataEdu: arrayData,
    });
    this.props.settingData.education.map((res) => {
      this.state.educationList.push(res);
    });
  };

  deleteEducation = (i) => {
    this.props.settingData.education.splice(i, 1);
    this.state.educationList.splice(i, 1);
    this.setState({ educationList: [...this.state.educationList] }, () => {
      var arrayData = (
        <Education
          deleteItemEduc={this.deleteEducation}
          data={this.state.educationList}
          editEducation={this.editEducation}
        />
      );
      this.setState({
        arrayDataEdu: arrayData,
      });
    });
  };

  deleteExperience = (i) => {
    this.props.settingData.experience.splice(i, 1);
    this.state.experienceList.splice(i, 1);
    this.setState(
      {
        experienceList: [...this.state.experienceList],
      },
      () => {
        var expData = (
          <Experience
            deleteExpe={this.deleteExperience}
            data={this.state.experienceList}
            editExperience={this.editExperience}
          />
        );
        this.setState({
          arrayDataExp: expData,
        });
      }
    );
  };

  getInstitutions = () => {
    var institutions = [];
    this.props.systemData.Data.institutions.map((res, index) => {
      var obj = {
        text: res.instutution,
        value: res.instutution,
      };
      institutions.push(obj);
    });
    this.setState({ optionsInstitute: institutions });
  };

  getGrades = () => {
    var grades = [];
    this.props.systemData.Data.grades.map((res, index) => {
      var obj = {
        text: res.grades,
        value: res.grades,
      };
      grades.push(obj);
    });
    this.setState({ optionsGrade: grades });
  };

  getCrouse = () => {
    var courses = [];
    this.props.systemData.Data.courses.map((res, index) => {
      var obj = {
        text: res.courseName,
        value: res.courseName,
      };
      courses.push(obj);
    });
    this.setState({ optionsCrouse: courses });
  };

  getCompany = () => {
    var companies = [];
    this.props.systemData.Data.companies.map((res, index) => {
      var obj = {
        text: res.companyName,
        value: res.companyName,
      };
      companies.push(obj);
    });
    this.setState({ optionsCompany: companies });
  };

  getDuration = () => {
    var experience = [];
    this.props.systemData.Data.experience.map((res, index) => {
      var obj = {
        text: res.system_experience,
        value: res.system_experience,
      };
      experience.push(obj);
    });
    this.setState({ optionsDurations: experience });
  };
  getPositions = () => {
    var job_titles = [];
    this.props.systemData.Data.job_titles.map((res, index) => {
      var obj = {
        text: res.job_titles,
        value: res.job_titles,
      };
      job_titles.push(obj);
    });
    this.setState({ optionsTitle: job_titles });
  };

  render() {
    let viewBtn = "";
    let uploadBtn = "";
    let cvLable = "";
    let userName = "";


    var cv =""
    if(this.props.candidateDate.userData.cv[0]){
      cv =this.props.candidateDate.userData.cv[0].upload
    }

    if (this.state.showCVButtons === true) {
      if (this.state.cvType === "pdf") {
        viewBtn = (
          <button
            className={classes.viewButton}
            onClick={this.handleOpenViewModal}
          >
            {/* <a href={this.state.cvURl} type="button"> */}
            View
            {/* </a> */}
          </button>
        );
      } else {
        viewBtn = (
          // <button
          //   className={classes.viewButton}
          //   onClick={this.handleOpenViewModal}
          // >
          <a
            className={classes.viewButton}
            href={cv}
            type="button"
          >
            Download
          </a>
        );
      }

      userName = localStorage.getItem("chat-username");
      cvLable = "Curriculam vitae";
      uploadBtn = (
        <span className={classes.uploadButtonWrap}>
          {/* <button onClick={this.uploadNewCV}> */}
          <input
            type="file"
            name="uploadedCVSetting"
            id="uploadedCVSetting"
            onChange={this.handleFileChangeSetting}
            className="inputfilez"
          ></input>
          <label htmlFor="uploadedCVSetting">
            {/* <i className="fas fa-upload" /> */}
            Upload
          </label>
        </span>
      );
    }

    return (
      <div className={classes.cvFilteredWrapper}>
        <Modal
          open={this.state.isViewModalOpen}
          size="small"
          className="noBootstrapModal"
          onClose={this.closeModal}
        >
          <div className={classes.insideViewModal}>
            <div className={classes.titlebar}>
              <h3>CV view</h3>
              <button
                onClick={this.handleCloseViewModal}
                className={classes.closeX}
              >
                <i className="fas fa-times" />
              </button>
            </div>
            <embed
              src={cv}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </div>
        </Modal>
        <div className={classes.cvFilter}>
          <div className={classes.whiteBoxNoShadow}>
            <div>
              <div className={classes.cvActionsRow}>
                <div className={`${classes.left} ${classes.mleft}`}>
                  <div className={classes.title} style={{ width: "80px" }}>
                    {/* {cvLable} */}
                    &nbsp;
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    className={`${classes.rightCVTags} ${classes.cvNameAndButtons}`}
                  >
                    <div className={classes.pdfNameImage}>
                      <img src={Document} />
                      <span className={classes.name}>
                        {userName.replace(" ", "_")}.pdf
                      </span>
                    </div>
                    <div>
                      {this.props.candidateDate.userData.cv.length > 0
                        ? viewBtn
                        : null}
                      {this.state.showCVButtons === true &&
                        this.props.candidateDate.userData.cv.length > 0 && (
                          <span>Or</span>
                        )}
                      {uploadBtn}
                    </div>
                  </div>
                  {/* <div className={classes.cvActionsGuideText}>
                    This CV is used to generate the following sections
                  </div> */}
                </div>
              </div>
            </div>
            <div className={classes.dataRow}>
              <div className={classes.left80}>
                Career preference
                {/* <br />
                <span>(Max 5)</span> */}
              </div>
              <Preferences
                preferences={this.props.preferences}
                setPreferences={this.props.setPreferences}
                somethingChanged={this.state.somethingChanged}
                handleSomethingChanged={this.handleSomethingChanged}
              />
            </div>
            <div className={classes.skillsRow}>
              <div className={classes.left}>
                <div className={classes.title} style={{ width: "80px" }}>
                  Skills
                </div>
                <div className={classes.badge}>CV</div>
              </div>
              <div className={classes.rightCVTags}>
                <CVTags
                  data={this.props.settingData}
                  onDataSave={this.filteredData}
                />
              </div>
            </div>

            <div className={classes.EduRow}>
              <div className={classes.left}>
                <div className={classes.title}>Education</div>
                <div className={classes.badge}>CV</div>
              </div>
              <div className={classes.rightEduContent}>
                <div className={classes.eduContent}>
                  {this.state.arrayDataEdu}
                  {this.state.inputEducation ? (
                    <div className={classes.educationInputs}>
                      <div className={classes.title}>Enter details</div>

                      {/* <input
                        type="text"
                        name="instututeName"
                        placeholder="Institute name"
                        onChange={this.handleNamechange}
                      /> */}
                      <div className={classes.eduList}>
                        <Dropdown
                          options={this.state.optionsInstitute}
                          placeholder="Institute name"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.instututeName}
                          onAddItem={this.handleNameAddition}
                          onChange={this.handleNamechange}
                          className="eduInputsDropdown_settings"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />

                        <div className={classes.errorDiv}>
                          {this.validator.message(
                            "instituteName",
                            this.state.instututeName,
                            "required"
                          )}
                        </div>
                        <input
                          type="text"
                          name="instututeType"
                          placeholder="Graduation Year"
                          onChange={this.handleTypeChange}
                          value={this.state.instituteType}
                        />
                        <div className={classes.errorDiv}>
                          {this.validator.message(
                            "Graduation Year",
                            this.state.instituteType,
                            "required"
                          )}
                        </div>

                        {/* <input
                        type="text"
                        name="course"
                        placeholder="Course name"
                        onChange={this.handleSubjectChange}
                      /> */}

                        <Dropdown
                          options={this.state.optionsCrouse}
                          placeholder="Course name"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.instituteSubject}
                          onAddItem={this.handleSubjectAddition}
                          onChange={this.handleSubjectChange}
                          className="eduInputsDropdown_settings"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />

                        <div className={classes.errorDiv}>
                          {this.validator.message(
                            "course",
                            this.state.instituteSubject,
                            "required"
                          )}
                        </div>
                        {/* <input
                        type="text"
                        name="result"
                        placeholder="Grade achieved"
                        onChange={this.handleGrade}
                      /> */}

                        <Dropdown
                          options={this.state.optionsGrade}
                          placeholder="Grade achieved"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.instituteGrade}
                          onAddItem={this.handleGradeAddition}
                          onChange={this.handleGrade}
                          className="eduInputsDropdown_settings"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />
                        <div className={classes.errorDiv}>
                          {this.validator.message(
                            "result",
                            this.state.instituteGrade,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className={classes.submitDiv}>
                        <button
                          className={classes.delete}
                          onClick={this.enterEducationDetails}
                        >
                          Submit
                        </button>
                        <button
                          className={classes.submit}
                          onClick={this.handleCloseEdu}
                        >
                          Close
                        </button>
                      </div>
                      {/* {this.validator.message(
                          "education",
                          this.state.instituteType,
                          "required|education"
                      )} */}
                    </div>
                  ) : (
                    <div className={classes.addPlus}>
                      <button onClick={this.handleEduBtn}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.EduRow}>
              <div className={classes.left}>
                <div className={classes.title}>Experience</div>
                <div className={classes.badge}>CV</div>
              </div>
              <div className={classes.rightEduContent}>
                <div className={classes.eduContent}>
                  <div className={classes.eachData}>
                    {this.state.arrayDataExp}
                  </div>

                  {this.state.inputExperience ? (
                    <div className={classes.educationInputs}>
                      <div className={classes.title}>Enter details</div>
                      {/* <input
                        type="text"
                        name="companyName"
                        placeholder="Company name"
                        onChange={this.handleCompany}
                      /> */}
                      <Dropdown
                        options={this.state.optionsCompany}
                        placeholder="Company name"
                        search
                        selection
                        fluid
                        allowAdditions
                        value={this.state.xprienceCompany}
                        onAddItem={this.handleCompanyAddition}
                        onChange={this.handleCompany}
                        className="eduInputsDropdown_settings"
                        selectOnNavigation={false}
                        selectOnBlur={false}
                      />
                      <div className={classes.errorDiv}>
                        {this.validatorExp.message(
                          "companyName",
                          this.state.xprienceCompany,
                          "required"
                        )}
                      </div>
                      {/* <input
                        type="text"
                        name="jobTitle"
                        placeholder="Job title"
                        onChange={this.handlePOsition}
                      /> */}
                      <Dropdown
                        options={this.state.optionsTitle}
                        placeholder="Job title"
                        search
                        selection
                        fluid
                        allowAdditions
                        value={this.state.xpriencePosition}
                        onAddItem={this.handlePositionAddition}
                        onChange={this.handlePOsition}
                        className="eduInputsDropdown_settings"
                        selectOnNavigation={false}
                        selectOnBlur={false}
                      />
                      <div className={classes.errorDiv}>
                        {this.validatorExp.message(
                          "jobTitle",
                          this.state.xpriencePosition,
                          "required"
                        )}
                      </div>

                      {/* <input
                        type="text"
                        name="duration"
                        placeholder="Duration"
                        onChange={this.handleDuration}
                      /> */}
                      <Dropdown
                        options={this.state.optionsDurations}
                        placeholder="Duration"
                        search
                        selection
                        fluid
                        allowAdditions
                        value={this.state.xperienceDuration}
                        onAddItem={this.handleDurationAddition}
                        onChange={this.handleDuration}
                        className="eduInputsDropdown_settings"
                        selectOnNavigation={false}
                        selectOnBlur={false}
                      />
                      <div className={classes.errorDiv}>
                        {this.validatorExp.message(
                          "duration",
                          this.state.xperienceDuration,
                          "required"
                        )}
                      </div>

                      <div className={classes.submitDiv}>
                        <button
                          className={classes.delete}
                          onClick={this.enterExperienceDetails}
                        >
                          Submit
                        </button>
                        <button
                          className={classes.submit}
                          onClick={this.handleCloseExp}
                        >
                          Close
                        </button>
                      </div>
                      {/* {this.validator.message(
                          "experience",
                          this.state.instituteType,
                          "required|experience"
                      )} */}
                    </div>
                  ) : (
                    <div className={classes.addPlus}>
                      <button onClick={this.handleExpBtn}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showUploadLoader && (
          <Dimmer active>
            <Loader>Uploading</Loader>
          </Dimmer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    phoneNumber: state.auth.phoneNumber,
    location: state.auth.location,
    deleteID: state.auth.itemListId,
    eduList: state.auth.eduList,
    skills: state.auth.skills,
    openCVModal: state.chatnew.openCVModal,
    candidateDate: state.CandidateData.userData,
    systemData: state.auth.systemData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSendExp: (data) => dispatch(actionsCreator.sendExp(data)),
    onSendEdu: (data) => dispatch(actionsCreator.sendEdu(data)),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
    onCloseModal: (data) => dispatch(actionsCreator.CloseModal(data)),
    onUploadCV: (data) => dispatch(actionsCreator.UploadCVChat(data)),
    onCVProgress: (data) => dispatch(actionsCreator.CVProgress(data)),
    setChanged: (data) => dispatch(actionsCreator.setSomethingChanged(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterData);
