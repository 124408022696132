//madusara
import React from "react";
import Popup from "reactjs-popup";
import "./Popup200px.scss";

class Popup200px extends React.Component {
  render() {
    var { hover, click, position, children } = this.props;
    return (
      <React.Fragment>
        {hover ? (
          <Popup
            className="Popup200pxOnHover"
            trigger={click}
            position={position}
            on="hover"
          >
            {children}
          </Popup>
        ) : (
          <Popup
            className="Popup200pxOnClick"
            trigger={click}
            position={position}
          >
            {children}
          </Popup>
        )}
      </React.Fragment>
    );
  }
}

export default Popup200px;
