import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter ,Link} from "react-router-dom";

class Stall extends Component{
    constructor(props){
        super(props)
    }

    render(){
        
        let stallData = this.props.data

        console.log(stallData,"Stall data modal")

        let stallName = null
        let stallImage = null

        if (stallData != ""){
            stallName = stallData.stallName
            stallImage = stallData.stallImage
        }

        return(
            <div>
                <div>sample</div>
                <div>{stallName}</div>
                <div><img onClick={this.props.isGoStall} src={stallImage}/></div>
            </div>
        )
    }
}

const mapStateToProps=(state)=>{
    return{

    }
}

const mapDispatchToProps=(dispatch)=>{
    return{

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stall)