import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import E1 from "../../../assets/image/imagetestatend.jpg";
import E2 from "../../../assets/image/imagetestatend1.jpg";
import Logo from "../../../assets/image/ufairs.svg";
import classes from "./AboutUs.module.scss";


class About extends Component {

     constructor(props) {
          super(props);
          this.state = {
               organizer: true,
               partners: false,
               company: false,
               step: 0
          }
     }
     next = () => {
          if (this.state.step != 2) {
               this.setState({ step: this.state.step + 1 })
          }

     }
     prev = () => {
          if (this.state.step != 0) {
               this.setState({ step: this.state.step - 1 })
          }
     }
     render() {
          var orgImge = this.props.eventData.aboutUsImages.find(x => x.logoType == "ORGANIZER")
          var evtImage = this.props.eventData.aboutUsImages.find(x => x.logoType == "EVENT")
         // var evImage=evtImage[evtImage.length-1]
          //var oImg = orgImge[orgImge.length-1]

          console.log(evtImage, "Event image")

          return (
               <div className={classes.outerFlex}>
                    <div>
                         <button onClick={this.prev} className={classes.back_btn1} > <i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                    </div>

                    <div>

                         {this.state.step == 0 ?
                              <>
                                   <div className={classes.aboutTop}>
                                        <h3><span className={classes.orange}>EVENT &amp; ORGANIZER</span></h3>
                                   </div>

                                   <div className={classes.aboutEvent}>                                      
                                        <div className={classes.aboutE1}>
                                             <div className={classes.imgE1}>
                                                  {evtImage != undefined ?
                                                       <img src={evtImage.logo} />
                                                       : <img src={Logo} />
                                                  }
                                             </div>
                                             <div className={classes.disE1}>
                                                  {this.props.eventData.aboutUs[0].description}
                                             </div>
                                        </div>

                                        <div className={classes.aboutE1}>
                                             <div className={classes.imgE1}>
                                                  {orgImge != undefined ?
                                                       <img src={orgImge.logo} />
                                                       : <img src={Logo} />
                                                  }
                                             </div>
                                             <div className={classes.disE1}>
                                                  {this.props.eventData.aboutUs[0].titleCompanies}
                                             </div>
                                        </div>
                                   </div>
                              </>
                              : null}


                         {/* PARTNERS  */} 
                         {this.state.step == 1 ?
                              <div>
                                   <div className={classes.aboutTop}>
                                        <h3><span className={classes.orange}>PARTNERS</span> </h3>
                                   </div>

                                   {/* <div className={classes.aboutsecondpage}>
                                        {this.props.eventData.aboutUsImages.map((res, index) => {
                                             return (
                                                  res.logoType == "PARTNER" ?
                                                       <div key={index} className={classes.imgSecondpage}>
                                                            <img src={res.logo} />
                                                       </div>
                                                       : null 
                                             )
                                        })
                                        }
                                        <div className={classes.instructionWrapper}>
                                             <div className={classes.instructions}>
                                             <div className={classes.heading}>
                                                  <span>Partners will be updated soon!</span>
                                             </div>
                                             <div className={classes.descr}>
                                             Wanna know about our Agenda?
                                             </div>
                                             <a href="#">Yes</a>
                                             </div>
                                        </div>
                                   </div> */}
                                   {
                                     this.props.eventData.aboutUsImages.length > 0 ?
                                     <div className={classes.aboutsecondpage}>
                                          {
                                               this.props.eventData.aboutUsImages.map((res, index) => {
                                                  return (
                                                       res.logoType == "PARTNER" ?
                                                            <div key={index} className={classes.imgSecondpage}>
                                                                 <img src={res.logo} />
                                                            </div>
                                                            : null 
                                                  )
                                             })
                                          }
                                     </div>:
                                   <div className={classes.instructionWrapper}>
                                        <div className={classes.instructions}>
                                        <div className={classes.heading}>
                                             <span>Partners will be updated soon!</span>
                                        </div>
                                        {/* <div className={classes.descr}>
                                        Wanna know about our Agenda?
                                        </div>
                                        <a href="#">Yes</a> */}
                                        </div>
                                   </div>
                                   }
                              </div>
                              : null
                         }

                         {this.state.step == 2 ?
                              <div>
                                   <div className={classes.aboutTop}>
                                        <h3><span className={classes.orange}>COMPANIES</span> </h3>
                                   </div>
                                   <div className={classes.aboutsecondpage}>
                                        {/* <div className={classes.aboutsecondpage}>
                                             {this.props.eventData.aboutUsImages.map((res, index) => {
                                                  return (
                                                       res.logoType == "COMPANY" ?
                                                            <div key={index} className={classes.imgSecondpage}>
                                                                 <img src={res.logo} />
                                                            </div>
                                                            : null
                                                  )
                                             })
                                             }
                                             <div className={classes.instructionWrapper}>
                                                  <div className={classes.instructions}>
                                                  <div className={classes.heading}>
                                                       <span>Companies will be updated soon!</span>
                                                  </div>
                                                  <div className={classes.descr}>
                                                  Wanna know about our Agenda?
                                                  </div>
                                                  <a href="#">Yes</a>
                                                  </div>
                                             </div>
                                        </div> */}
                                        {
                                            this.props.eventData.aboutUsImages.length > 0 ?
                                            <div className={classes.aboutsecondpage}>
                                                 {
                                                      this.props.eventData.aboutUsImages.map((res, index) => {
                                                         return (
                                                              res.logoType == "COMPANY" ?
                                                                   <div key={index} className={classes.imgSecondpage}>
                                                                        <img src={res.logo} />
                                                                   </div>
                                                                   : null 
                                                         )
                                                    })
                                                 }
                                            </div>:
                                             <div className={classes.instructionWrapper}>
                                                  <div className={classes.instructions}>
                                                  <div className={classes.heading}>
                                                       <span>Companies will be updated soon!</span>
                                                  </div>
                                                       {/* <div className={classes.descr}>
                                                       Wanna know about our Agenda?
                                                       </div>
                                                       <a href="#">Yes</a> */}
                                                  </div>
                                             </div>
                                        }

                                   </div>
                              </div>
                              : null
                         }

                    </div>
                    <div>
                         <button onClick={this.next} className={classes.back_btn1}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                    </div>

               </div>

          )
     }
}
export default (About)