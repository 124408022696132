import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ChatMessage from "../../../components/SiaChat/ChatMessage/ChatMessage";
import Navbar from "../../Navbar/Navbar";
import * as actionsCreator from "../../../store/actions/index";
import Layout from "../../../components/Layout/Layout";
import classes from "./Chat.module.scss";
import axios from "axios";
import whiteSia from "../../../assets/image/sia-logo-in-white.png";
import Swal from "sweetalert2";
import XMLReader from "xml-reader";
import xmlQuery from "xml-query";

const chat = props => {
  const [progress, setProgress] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    props.onGetProfileData();
    props.onGetChatHistoryNew();
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    //   window.scrollBy({
    //     top: document.body.scrollHeight,
    //     left: 0
    //     // behavior: 'smooth'
    //   });
    // }, 100);
  });
  // useEffect(() => {
  //   if (props.startNotification) {
  //     const intervel = setInterval(() => {      
  //       props.onGetChatResponseNew("/notification", false, true);
  //     }, process.env.REACT_APP_NOTIFICATIONS_TIMEOUT);
  //     return () => clearInterval(intervel);
  //   }
  // }, [props.startNotification]);

  const handlePayload = (payload, lable) => {

    var dataLabel = "";
    var dataValue = "";
    var payloadLable = ""

    let xml = XMLReader.parseSync(payload);
    var payloadType = xmlQuery(xml).find("type").text();

    console.log(payload, "Payload values")
    console.log(lable, "Lable payload")

    if (payload === "View your smart CV") {
      localStorage.setItem("OpenSmartCV", true);
    }
     else if (payload === "upload_new_cv") {
      localStorage.setItem("OpenSmartCV", true);
    } 
    else if (payload === "Start video pitch") {
      dataLabel = "OpenVideoModal";
      dataValue = true;
      localStorage.setItem("OpenVideoModal", true);
    } 
    else if (payload === "Write cover letter") {
      dataLabel = "OpenCoverLtrModal";
      dataValue = true;
      localStorage.setItem("OpenCoverLtrModal", true);
    } 
    else if(payload === "file_upload_cover_letter"){

      console.log("file upload cover letter")

      dataLabel = "OpenCoverLtrModal";
      dataValue = true;
     // localStorage.setItem("OpenCoverLtrModal", true);
     //hiddenFileInput.
     hiddenFileInput.current.click();
    }

    else if (payload === "Yes") {
      localStorage.setItem("OpenQuestionModal", true);
    }
    else if (payload === "start_cam_test"){
      localStorage.setItem("OpenCamTest", true);
    } 
    else if(payload === "start_mic_test"){
      localStorage.setItem("OpenMicTest", true);
    }
    else if(payload === "closeChat"){
      //sendchatCompoentClose()
      localStorage.setItem("chatClose", true);       
    }

    // else if(payload === "Send as email"){
    //   // localStorage.setItem("SupportAsEmail", true)
    // }

    else if (payload === "Send_as_email"){
      localStorage.setItem("SupportAsEmail", true)
    }

    else if (payload === "Campaigns"){
      payloadLable = "Apply for campaigns"
    }

    else if (payload=== "Yes start conversation"){
      payloadLable = "Yes"
    }
    
    else if(payload === "question_ask"){
      localStorage.setItem("faq_question_modal", true)
    }

    else if(payload === "No exit"){
      localStorage.setItem("chatClose", true);   
    }

    else if(payload === "no_exit_from_chat"){
      localStorage.setItem("chatClose", true);   
    }
    else if(payload === "review_profile_welcome"){
         props.changeTabCareer()
    }

    else if(payload === "select_my_time"){
      console.log("Select my time")
    }

    if(payloadType === "campaign_id_ref"){
      localStorage.setItem("SCHEDULE_CAMPAIGN_NAME", lable)
    }

    // if (payloadType === "Started_video_interview"){
    //   var interviewRef = localStorage.getItem(
    //     "interview_ref"
    //   );
    //   var ref = xmlQuery(XMLReader.parseSync(payloadType)).find("key").text();
    //   dataValue = true;
    //   localStorage.setItem("Open_video_conf_sia", true);
    //   sessionStorage.setItem("ref", ref);
    // }

    props.onGetChatResponseNew(payload, true);

  };
  const closeFileOpen=(e)=>{
    window.addEventListener('focus', handleFocusBack);
  }
  const handleFocusBack=()=>{
    console.log('focus-back');
    window.removeEventListener('focus', handleFocusBack);
  }

  const handleChangeCoverLetter = (e) => { 
    
  console.log("handle file input change")

    //console.log(e.target,"vlu")
  var _validFileExtensions = ["application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ];
   var imgObj = _validFileExtensions.filter(
     (obj) => obj == e.target.files[0].type
   );
   if (imgObj.length > 0) {

     var fileSize = e.target.files[0].size / 1024 / 1024
     if (parseInt(fileSize) <=5){
      var file = e.target.files[0];
      var extensions= e.target.files[0].name.split('.').pop()
      localStorage.setItem("coverLetterName", file.name);
      var reader = new FileReader();
      reader.readAsDataURL(file);
      var b64String = "";
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("coverLetter", preview);
        updateCoverLetter(preview,extensions);
      };

     }
     else{
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Maximum file size is 5MB",
        showConfirmButton: false,
        timer: 2500,
        customClass: { container: "swal-success-settings" },
        imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        imageHeight: 20,
      });
      props.onGetChatResponseNew("Skip_cover_letter", false);
     }

   } else {
    props.onGetChatResponseNew("Skip_cover_letter", false);
     Swal.fire({
       toast: true,
       position: "top-end",
       title: "Plese upload pdf or doc",
       showConfirmButton: false,
       timer: 2500,
       customClass: { container: "swal-success-settings" },
       imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
       imageHeight: 20,
     });
   }  
  };

  const updateCoverLetter = (fileString,extensions) => {

    console.log("submit cover letter file upload")

    //var id_campaign = localStorage.getItem("Campaign_ID");

    var id_campaign = "";

    if (props.appliedSaveData === null) {
      id_campaign = localStorage.getItem("Campaign_ID");
    } else if (props.appliedSaveData !== null) {
      id_campaign = props.appliedSaveData.campaignId;
    }

    //var candidateID = localStorage.getItem("CandidateProfile_id");
    var candidateID = props.candidateDate.userData.profile[0].user_id

    var dataProgress = true;
    var dataLetter = {
      candidate_id: candidateID,
      coverLetter: "",
      coverLetter_url: fileString,
      campaign_id: parseInt(id_campaign),
      extention :extensions
    };
    props.onCVLProgress(dataProgress);
    props.onUploadLetter(
      dataLetter,
      props.appliedSaveData,
      id_campaign
    );
    props.onGetChatResponseNew("/Submit_cover_letter", false);
  };

  const sendMessage = message => {
    props.onGetChatResponseNew(message);
  };
  const finishVideo = intent => {
    props.onGetChatResponseNew(intent, false);
  };

  const handleFileChange = e => {
    let uploadCV = e.target.files[0];
    console.log(e.target.value);

    if (!/(\.pdf|\.docx)$/i.test(e.target.value)) {
      alert("Invalid file type.");
      e.target.value = "";
      // e.focus();
      return false;
    } else {
      var file = document.querySelector("input[type=file]").files[0];
      console.log(file, "file");
      var reader = new FileReader();

      let campaignID = localStorage.getItem("Campaign ID");
      const token = localStorage.getItem("chat-token");

      var b64String = "";
      reader.onloadend = function() {
        b64String = reader.result;
        console.log(b64String, "source");

        var jsonString = {
          upload: b64String,
          campaignId: parseInt(campaignID),
          uploaded_at: "2019-07-13T00:00:00Z"
        };
        axios({
          method: "POST",
          url: process.env.REACT_APP_API_URL + "chat/cv/",
          onUploadProgress: pregresEvent => {
            console.log(
              "on progress" +
                Math.round((pregresEvent.loaded / pregresEvent.total) * 100) +
                "%"
            );
            setProgress(
              Math.round((pregresEvent.loaded / pregresEvent.total) * 100)
            );
          },
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Token ${token}`
          },
          data: jsonString
        })
          .then(res => {
            console.log(res);
            setProgress("");
            setUploadSuccess(true);
          })
          .catch(err => {
            console.log(err, "Error upload cv");
            setProgress("");
            setUploadSuccess(true);
          });
      };
      if (file) {
        reader.readAsDataURL(file);
      }

      //let form = new FormData()
      //form.append('upload', uploadCV)

      console.log(b64String, "B64String");

      var jsonString = {
        upload: "",
        campaignId: parseInt(campaignID),
        uploaded_at: "2019-07-13T00:00:00Z"
      };

      console.log(jsonString, "Json string");
    }
  };
  const uploadSuccessAction = () => {
    props.onGetChatResponseNew("/cv_upload_success", false);
    setUploadSuccess(false);
  };
  const uploadCancelAction = () => {
    props.onGetChatResponseNew("/internship", false);
    setUploadSuccess(false);
  };

  const applyCampaing = () => {
    props.onGetChatResponseNew("successfully applied", false);
  };

  const responseNull =(value)=>{
    console.log(value, "actionValue")
    if (value === 1){
      console.log("action welcome back")
      props.onGetChatResponseNew("welcome_back", false)
    }
    else if (value === 0){
      console.log("action hi")
      props.onGetChatResponseNew("hi", false)
    }
    
  }

  const OpenChatPopUp = (value) =>{
    props.onOpenChatPopUp(value)
  }

  const initialChat=(value)=>{
    console.log(value, "actionvalue initial chat")
    if (value === 0){
      props.onResetInitialChatResponse(false)
      props.onGetChatResponseNew("Yes start conversation", false)
    }
    else if(value === 1){
      props.onResetInitialChatResponse(false)
      props.onGetChatResponseNew("No stop conversation", false)
    }
    // else if(value === 2){
    //   props.onResetInitialChatResponse(false)
    //   props.onGetChatResponseNew("select_time", false)
    // }
  }

  const scheduleInterview = (value) => {
    console.log(value, "action when inshceduled interviews")
    // if (value === 0){
    //   console.log("go t0 help exsiting campaigns")
    //   props.onGetChatResponseNew("help on campaigns", false)
    //   props.onResetInterviews(false)
    //   props.onResetWelcomeChat(false)
    // }
    if(value === 1){
      console.log("go to normal flow")
      //props.onGetChatResponseNew("Yes start conversation", false)
      props.onGetChatResponseNew("schedule_campaign_later", false)
      props.onResetInterviews(false)
      props.onResetWelcomeChat(false)
    }
    else{
      console.log("scheddule campaigns")
      var keyValue = value.id +"_"+ value.ref
      localStorage.setItem("SCHEDULE_CAMPAIGN_NAME", value.heading)
      var payload = "<payload><type>campaign_id_ref</type><data><key>"+keyValue+"</key><value>"+"campaign_reference"+"</value></data></payload>"
      props.onGetChatResponseNew(payload, false)
      props.onResetInterviews(false)
      props.onResetWelcomeChat(false)
    }
  }
  
  const SkippedApplycampaigns = (value)=>{
    localStorage.setItem("SKIPPER_CAMPAIGN", value.heading)
    console.log("Skipped campaigns check")
    console.log(value, "value campaign skipped")
    var keyValue = value.campaignId + "_" + value.step + "_" + "campaignheading_skipped_camp"  
    var payload =  "<payload><type>skipped_campaigns_continue</type><data><key>"+keyValue+"</key><value>"+"campaign_skipped"+"</value></data></payload>"
    //var payload = '/camapign_continue_skip{"skipped_campaigns_continue" : ' + payload_value +'}'
    console.log(payload, "Skipped campaign payload")
    
    var data = {
      candidateid: props.profile.id,
      campaignId: parseInt(value.campaignId),
      ref: "",
      reminderDuration: 0,
      accessed: 0,
      status: "APPLIED",
      applied_method: "SIA",
    };

    localStorage.setItem("CAMPAIGN_APPLIED_ID", value.campaignId);
    props.onSaveApplyData(data);

    props.onGetChatResponseNew(payload, false)
    props.onResetInterviews(false)
    props.onResetWelcomeChat(false)
  }

  const welcomeback = (value)=>{
    console.log(value, "actionvalue welcome back chat")
    if (value === 0){
      props.onResetWelcomeChat(false)
      props.onGetChatResponseNew("Yes start conversation", false)
    }
    else if(value === 1){
      props.onResetWelcomeChat(false)
      props.onGetChatResponseNew("No stop conversation", false)
    }
    else if(value === 2){
      props.onResetWelcomeChat(false)
      props.onGetChatResponseNew("I want to complete my profile", false)
    }
    else if(value === 3){
      props.onResetWelcomeChat(false)
      props.onGetChatResponseNew("I want to exit from profile", false)
    }
    else if(value === 4){
      props.onResetWelcomeChat(false)
      props.onGetChatResponseNew("check my skipped campaigns", false)
    }
    else if(value === 5){
      props.onResetWelcomeChat(false)
      props.onGetChatResponseNew("exit from skipped campaigns", false)
    }
    else if(value === 6){
      props.onResetWelcomeChat(false)
      props.onGetChatResponseNew("welcome_back_log_user", false)
    }
    else if(value === 7){
      props.onResetWelcomeChat(false)
      props.onGetChatResponseNew("No stop conversation", false)
    }
    else if(value === 8){
      props.onResetWelcomeChat(false)
      props.onGetChatResponseNew("check system", false)
    }
    else if(value === 9){
      props.onResetWelcomeChat(false)
      props.onGetChatResponseNew("system_check_completed_welcome", false)
    }
  }

  const startCareerTest = (value)=>{
    if (value === 0){
      props.onGetChatResponseNew("Yes,go for career test", false)
    }
    else if(value === 1){
      props.onGetChatResponseNew("exit_chat", false)
    }
  }

  const AvailableCampaign = (value)=>{
    if (value === 0){
      props.onGetChatResponseNew("apply_for_campaign", false)
    }
    else if(value === 1){
      props.onGetChatResponseNew("No exit", false)
    }
  }

  // const sendReference = () => {
  //   props.onSheduleInterview("/ref",false);
  //   setUploadSuccess(false)
  // }

  console.log(props.show, "Show props")

  return (
    // <div className={classes.Chat} id="chatComponent">
    //   <Navbar />

    // <Layout title="Sia Chat">
    <div
      className={
        props.show ? classes.siaChatWindow : classes.hiddenSiaChatWindow
      }
    >
       <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChangeCoverLetter}
        style={{display: 'none'}}
        //onClick={closeFileOpen}
        onClick={(event)=> { 
          event.currentTarget.value = null
     }}
      />
      <div className={classes.siaChatHeader}>
        <div className={classes.left}>
          {/* <img src="https://www.picsum.photos/60" /> */}
          <img src={whiteSia} />
        </div>
        <div className={classes.right}>Sia</div>
      </div>
      <ChatMessage
        // sendReference= {sendReference}
        uploadSuccess={uploadSuccess}
        finishVideo={finishVideo}
        progress={progress}
        onDisabled={props.onDisabled}
        hangupCall={props.onHangupCall}
        sendMessage={sendMessage}
        handlePayload={handlePayload}
        responseMessages={props.responseMessages}
        historyLoading={props.historyLoading}
        handleFileChange={handleFileChange}
        uploadSuccessAction={uploadSuccessAction}
        uploadCancelAction={uploadCancelAction}
        loading={props.loading}
        applyCampaing={applyCampaing}
        responseNull={responseNull}
        initialStartCnvsation={props.initialStartCnvsation}
        welcomeback={props.welcomeback}
        initialChat={initialChat}
        welcomeBack={welcomeback}
        OpenChatPopUp = {OpenChatPopUp}
        scheduleInterview={scheduleInterview}
        SkippedApplycampaigns = {SkippedApplycampaigns}
        startCareerTest = {startCareerTest}
        AvailableCampaign = {AvailableCampaign}
        // campaignFinder={props.campaignFinder}
      />
    </div>
    // </Layout>
  );
};

const mapStateToProps = state => {
  return {
    responseMessages: state.chatnew.responseMessages,
    loading: state.chatnew.loading,
    historyLoading: state.chatnew.historyLoading,
    onDisabled: state.chatnew.onDisabled,
    startNotification: state.chatnew.startNotification,
    welcomeback: state.chatnew.welcomeback,
    initialStartCnvsation: state.chatnew.initialStartCnvsation,
    profile: state.profile.userProfile,
    candidateDate: state.CandidateData.userData,
    appliedSaveData: state.chatnew.appliedCampaignTemp
    //campaignFinder: state.chataNew.campaignFinder
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetProfileData: () => dispatch(actionsCreator.getProfileData()),
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
    onResetInitialChatResponse : (data) => dispatch(actionsCreator.resetInitialChatResponse(data)),
    onResetWelcomeChat : (data) => dispatch(actionsCreator.resetWelcomeChat(data)),
    onGetChatHistoryNew: () => dispatch(actionsCreator.getChatHistoryNew()),
    onHangupCall: (recipientId, interviewId) =>
      dispatch(actionsCreator.hangupCall(recipientId, interviewId)),
    onOpenChatPopUp : data => dispatch(actionsCreator.OpenChatPopUp(data)),
    onResetInterviews :  data=> dispatch(actionsCreator.ResetInterviews(data)),
    onSaveApplyData: (data) => dispatch(actionsCreator.saveApplyData(data)),
    onCVLProgress: (data) => dispatch(actionsCreator.CVLProgress(data)),
    onUploadLetter: (data, appliedData, campaignId) =>
    dispatch(actionsCreator.UploadLetter(data, appliedData, campaignId)),
    onGetChatResponseNew: (message, status, notification) =>
    dispatch(
      actionsCreator.getChatResponseNew(message, status, notification)
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(chat);
