import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionCreater from "../../../store/actions/index";
import VideoConference from "../../../containers/Interview/VideoConference/VideoConference";
import classes from "./CheckValidateVideo.module.scss";
import medical from "../../../assets/image/medical.svg";
import sia from "../../../assets/image/sia-logo-ori.png";
import Splash from "../../../components/UI/Splash/Splash";

class CheckValidateVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textChatCom: true
    };
  }
  componentWillMount() {   
    var candidateId=localStorage.getItem("CandidateProfile_id02")
    console.log(this.props.intRef, "interviwe ref ###############")
    console.log(candidateId, "Candidate id ##################")
    this.props.validationUrl(this.props.intRef,candidateId);
  }
  goHome = (e) => {
   // sessionStorage.removeItem("ref");
   var eventId=localStorage.getItem("eventName")
    localStorage.removeItem("quickInvite");
    window.location.replace("/"+eventId);
  };
  render() {
    var data = null;
    try {
      data = this.props.checkValidate.Data;
    } catch {
      data = null;
    }

    console.log(data,"validation URl ###############")

    return (
      <div>
        {data != null ?
          data.hasInterview ? (
            <VideoConference isEvent={this.props.isEvent} is_interview={data.hasInterview} />
          ) : (
              <div className={classes.brokenWrapper}>
                <div className={classes.brokenInner}>
                  <img src={medical} />
                  <div>
                    <h2>Ug oh..!</h2>
                    <h4>This doesn't seem to be a valid url</h4>
                  </div>
                </div>
                
                <div className={classes.fixFooterWrap}>
                  <div className={classes.fixFooter}>
                    <img src={sia} />
                    <div className={classes.linkWrapper}>
                      <div className={classes.linkdiv} onClick={this.goHome}>
                        Go to <b>Home</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          : null
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    checkValidate: state.CandidateData.validationUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validationUrl: (data,id) => dispatch(actionCreater.validationUrl(data,id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckValidateVideo);
