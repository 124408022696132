import React, { Component } from "react";
import { Route, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import quickCamp from "../../../../assets/image/quick2.png";
import classes from "./Campaign.module.scss";
import moment from "moment";
import CampaignDashboard from "../../../Modals/CampaignDashboard/CampaignDashboard";
import * as actionsCreator from "../../../../store/actions/index";
import defaultCamImage from "../../../../assets/image/sia_default.png";
import LogoUfair from "../../../../assets/image/dynmc/stall_middle.png";

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eachModalIsOpen: false,
      modalData: {
        heading: this.props.data.campaignData.heading,
        campaignImage: this.props.data.campaignData.campaignImage,
        startDate: this.props.data.campaignData.startDate,
        location: this.props.data.campaignData.location,
        description: this.props.data.campaignData.description,
        listLength: false,
        company: this.props.data.companyData.name,
        companyLogo: this.props.data.companyData.companyLogo,
        id: this.props.data.campaignData.id,
        type: this.props.data.campaignData.types,
      },
    };
  }

  handleOpenEachModal = () => {
    this.setState({ eachModalIsOpen: true });
    var campaignId = this.props.data.campaignData.id;
    var candidateId = this.props.candidate.userData.profile[0].user_id;
    this.props.getCampaignProcess(campaignId, candidateId);
  };
  handleCloseEachModal = () => {
    this.setState({ eachModalIsOpen: false });
  };

  render() {
    const stages = [
      "Applied",
      "Shortlisted",
      "Interviewed",
      "Selected",
      "Rejected",
      "Interviewing",
      "Cancelled",
    ];

    var momentStart = moment(this.props.data.campaignData.startDate);
    var momentEnd = moment(this.props.data.campaignData.endDate);
    var campDuration = momentEnd.diff(momentStart, "days");

    const { current_status } = this.props.data;
    const { campaignImage, types } = this.props.data.campaignData;
    return (
      <div className={classes.eachCampaign}>
        <img
          src={types !== 5 ? campaignImage?campaignImage:LogoUfair : quickCamp}
          className={classes.camImage}
          onClick={this.handleOpenEachModal}
        />
        <div className={classes.description}>
          <div className={classes.title} onClick={this.handleOpenEachModal}>
            {this.props.data.campaignData.heading}
          </div>
          {/* <EachCampaignModal data={this.props.data} /> */}
          <CampaignDashboard
            data={this.state.modalData}
            handleOpenEachModal={this.handleOpenEachModal}
            handleCloseEachModal={this.handleCloseEachModal}
            eachModalIsOpen={this.state.eachModalIsOpen}
          />

          <div className={classes.datesTimes}>
            {/* <i className="far fa-calendar-alt"></i> */}
            <div className={classes.startDate}>
              {moment(this.props.data.campaignData.startDate).format(
                "Do MMM YYYY"
              )}
            </div>
            {campDuration ? <div className={`${classes.to}`}>to</div> : ""}
            {campDuration ? (
              <div className={classes.to}>
                {moment(this.props.data.campaignData.endDate).format(
                  "Do MMM YYYY"
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={classes.remainingDays}>
            <span>{stages[current_status]}</span>
            {/* {this.dateDifference(this.props.data)} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    candidate: state.CandidateData.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCampaignProcess: (data, id) =>
      dispatch(actionsCreator.getCampaignProcess(data, id)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Campaign)
);
