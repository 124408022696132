import React, { Component } from "react";
import { connect } from "react-redux";
import VideoPitch from "../../../../UI/VideoPitch/VideoPitch";

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true
    };
  }

  handleVideo = () => {
    console.log("Video blob");
  };

  render() {
    return (
      <div>
        <VideoPitch closeModal={this.props.closeModal} />
        {/* <VideoRecorder 
          onRecordingComplete={this.handleVideo} 
        /> */}
      </div>
    );
  }
}
export default Video;
