import React, { Component } from "react";
import classes from "./RegisterForm.module.scss";
import Errors from "../../hoc/objectChecker";
import swal from "sweetalert2";
import Logo from "../../assets/image/sia-logo.png";
import Spinner from "../UI/Spinner/Spinner";
import axios from "../../axios";
import "./RegisterForm.scss";
import { StyledDropZone } from "react-drop-zone";
import SimpleReactValidator from "simple-react-validator";
import Splash from "../../components/UI/Splash/Splash";
import moment from "moment";


export class RegisterUser extends Component {
  constructor() {
    super();
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      company_name: "Test",
      image: "",
      is_staff_admin: false,
      staffMode: false,
      CHATURL: process.env.REACT_APP_API_URL + "chat/",
      tokenStatus: false,
      emailValidated: true,
      errorFirstName: "",
      errorLastName: "",
      errorImage: "",
      btnClicked: false,
      FlagStat:true,
      events:[]
    };
    this.registerUserHandeler = this.registerUserHandeler.bind(this);
    this.validator = new SimpleReactValidator();
  }
  componentDidMount = () => {
    localStorage.setItem("OPENCHATWINDOW", true);
    const token = localStorage.getItem("chat-token");
    if (token) {
      this.setState({ staffMode: false });
    }
    this.validatorLink()  
   // this.getGeoInfo()  
  };

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.getEventLiveEvents(data.timezone)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  getEventLiveEvents = (timeZone) => {
    var date = moment.utc().toISOString();
    axios.get("get-live-events/?logDate=" + date + "&timeZone=" + timeZone, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        this.state.events = res.data.Data.events;
        this.setState({ isData: true })
      })
      .catch((err) => {
      });
  }

  validatorLink=()=>{
    this.setState({FlagStat:false})    
    var reference = sessionStorage.getItem("LogingReferecne");
    axios
    .get(this.state.CHATURL + "check-ref-validation-ls/?ref="+reference, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((res) => {    
    //  this.setState({FlagStat:true})     
      if(res.data.FlagStat== true && res.data.Key !=""){       
            localStorage.setItem("chat-token", res.data.Key);
            sessionStorage.setItem("chat-token", res.data.Key);
            sessionStorage.setItem("CandidateProfile_id",res.data.CandidateProfile.id);
            sessionStorage.setItem("CandidateProfile_id02",res.data.CandidateProfile.user_id);
            sessionStorage.setItem("firstName",res.data.CandidateProfile.first_name);
            sessionStorage.setItem("OnBoard_State",res.data.CandidateProfile.onBoard_state);
            localStorage.setItem("OnBoard_State",res.data.CandidateProfile.onBoard_state);
            localStorage.setItem("CandidateProfile_id",res.data.CandidateProfile.id);
            localStorage.setItem("CandidateProfile_id02",res.data.CandidateProfile.user_id);
            this.setState({tokenStatus: true,});
            sessionStorage.removeItem("loginStatus");
            window.location.replace("/");            
      }
      else if(res.data.FlagStat == false){
            localStorage.clear()
            sessionStorage.clear()
            window.location.replace("/");     
      }else{
        this.setState({FlagStat:true})   
      }
    });
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.errorMessages) {
      this.setState({ error: true });
    }
  };

  removeError = () => {
    this.setState({ error: false });
  };

  previewFile = (file, event) => {
    console.log("filexx", file);
    const fileSizeMB = file.size / 1000 / 1000;
    var _validFileExtensions = [
      "image/png",
      "image/jpeg",
      "image/bmp",
      "image/webp",
    ];
    var imgObj = _validFileExtensions.filter((obj) => obj == file.type);
    if (!_validFileExtensions.includes(file.type)) {
      swal.fire({
        toast: true,
        position: "top-end",
        title: "Image type not supported!",
        showConfirmButton: false,
        timer: 4000,
        customClass: { container: "swal-success-settings" },
        imageUrl: "https://image.flaticon.com/icons/svg/463/463612.svg",
        imageHeight: 20,
      });
    } else if (fileSizeMB > 5) {
      console.log("LARGE", fileSizeMB);
      swal.fire({
        toast: true,
        position: "top-end",
        title: "Image size shouldn't exceed 5MB",
        showConfirmButton: false,
        timer: 4000,
        customClass: { container: "swal-success-settings" },
        imageUrl: "https://image.flaticon.com/icons/svg/463/463612.svg",
        imageHeight: 20,
      });
    } else if (imgObj.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var preview = reader.result;
        localStorage.setItem("IndividualImage", preview);
        this.setState({
          image: preview,
        });
      };
    } else {
      this.setState({
        image: null,
        previewFile: false,
      });
    }
  };
  textChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  fileChangeHandler = (e) => {
    var base64;
    var that = this;
    console.log("base64", e.target.files);
    if (e.target.files && e.target.files[0]) {
      var FR = new FileReader();
      FR.addEventListener("load", function (e) {
        base64 = e.target.result;
        document.getElementById("img").src = e.target.result;
        that.setState({
          image: e.target.result,
        });
        // document.getElementById("b64").innerHTML = e.target.result;
      });
      FR.readAsDataURL(e.target.files[0]);
    }
  };

  registerUserHandeler = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      var reference = sessionStorage.getItem("LogingReferecne");
      console.log(reference, "Reference");
      let userData = null;
      this.setState({
        btnClicked: true,
      });
      var data = {
        ref: reference,
        firstName: this.state.first_name,
        lastName: this.state.last_name,
        email: this.state.email,
        profile_pic: this.state.image,
        status: "False",
      };
      console.log(typeof data, "Data json");
      console.log(data, "Data json object");
      axios
        .post(this.state.CHATURL + "passless-get-token/", data, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => {
          console.log("Results data", res.data);
          console.log("Result data modification");
          if (res.data.Key.trim().length > 0 && res.data.FlagStat == "True") {
            console.log("flagstatus true");
            localStorage.setItem("chat-token", res.data.Key);
            sessionStorage.setItem("chat-token", res.data.Key);
            sessionStorage.setItem(
              "CandidateProfile_id",
              res.data.CandidateProfile.id
            );
            sessionStorage.setItem(
              "CandidateProfile_id02",
              res.data.CandidateProfile.user_id
            );
            sessionStorage.setItem(
              "firstName",
              res.data.CandidateProfile.first_name
            );
            sessionStorage.setItem(
              "OnBoard_State",
              res.data.CandidateProfile.onBoard_state
            );
            localStorage.setItem(
              "OnBoard_State",
              res.data.CandidateProfile.onBoard_state
            );
            localStorage.setItem(
              "CandidateProfile_id",
              res.data.CandidateProfile.id
            );
            localStorage.setItem(
              "CandidateProfile_id02",
              res.data.CandidateProfile.user_id
            );
            this.setState({
              tokenStatus: true,
            });
            sessionStorage.removeItem("loginStatus");
            window.location.replace("/");
          } else if (res.data.FlagStat == "False") {
            console.log("flag status false");
            localStorage.removeItem("chat-token");
            this.setState({
              tokenStatus: false,
            });
            window.location.replace("/auth");
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }  
  };

  render() {
    console.log(process.env.REACT_APP_URL, "React app url");
    let email = Errors(this.props.errorMessages, ["email"]);
    let password = Errors(this.props.errorMessages, ["password"]);
    let company = !this.state.staffMode
      ? Errors(this.props.errorMessages, ["staff_profile", "company", "name"])
      : null;
    let fistname = Errors(this.props.errorMessages, [
      "staff_profile",
      "first_name",
    ]);
    let lastname = Errors(this.props.errorMessages, [
      "staff_profile",
      "last_name",
    ]);
    let isStaffAdmin = Errors(this.props.errorMessages, ["is_staff_admin"]);

    return (
      <>
      {this.state.FlagStat ==true  ?

      <div className={classes.Login}>

          <div className={classes.tvBox} >
          <img className={classes.imgTv}  src={localStorage.getItem("eventImage")} />

          {/* {this.state.events.map((res, index) => {
            return (
              <div className={classes.imgitem}>
                 <img className={classes.imgTv} onClick={() => this.selectEvent(res.event[0].id)} style={{ cursor: "pointer" }} key={index} src={res.campaign.campaignImage} />
              </div>             
            )
          })} */}
        </div>

        <div className={classes.LoginBox}>
          {/* <img src={Logo} alt="Sia Logo" className={classes.logo} /> */}
          <div className={classes.textBelowLogo}>
            LET&apos;S GET YOU STARTED
          </div>
          <div className={classes.RegFormWrapper}>
            <form
              onSubmit={this.registerUserHandeler}
              className={classes.RegisterForm}
            >
              {this.state.staffMode ? (
                <select
                  className={isStaffAdmin}
                  name="is_staff_admin"
                  onChange={this.textChangeHandler}
                >
                  <option value="false">Staff Member</option>
                  <option value="true">Staff Admin</option>
                </select>
              ) : null}

              {/* {this.state.error && fistname ? ( */}
              {/* ) : null} */}
              <input
                className={classes.firstname}
                type="text"
                placeholder="First name"
                name="first_name"
                onChange={this.textChangeHandler}
                // onKeyPress={this.removeError}
              />
              {/* {this.state.error && lastname ? ( */}
              <div className={classes.nameError}>
                <span>
                  {this.validator.message(
                    "First name",
                    this.state.first_name,
                    "required"
                  )}
                </span>
              </div>
              {/* ) : null} */}
              <input
                className={classes.lastname}
                type="text"
                placeholder="Last name"
                name="last_name"
                onChange={this.textChangeHandler}
                // onKeyPress={this.removeError}
              />
              <div className={classes.nameError}>
                <span>
                  {this.validator.message(
                    "Last name",
                    this.state.last_name,
                    "required"
                  )}
                </span>
              </div>

              <div className="candidate-register-filedrop-wrapper dropZoneDesktop">
                    <div
                      style={{ width: "55%" , margin:"2px auto"}}
                  className={
                    this.state.image
                      ? "drop-zone-register-c"
                      : "drop-zone-register-c-default"
                  }
                >
                 <StyledDropZone
                    onDrop={this.previewFile}
                    label="Drop here or click to upload a profile image. (PNG/JPG accepted)"
                  />
                  {this.state.image ? <img src={this.state.image} /> : ""}
                </div>
                <div className={classes.ErrorImg}>
                  <span>
                    {/* {this.validator.message(
                      "Image",
                      this.state.image,
                      "required"
                    )} */}
                  </span>
                </div>
              </div>
              <div className="candidate-register-filedrop-wrapper dropZoneMobile">
                <div
                  className={
                    this.state.image
                      ? "drop-zone-register-c"
                      : "drop-zone-register-c-default"
                  }
                >
                  <StyledDropZone
                    onDrop={this.previewFile}
                    label="click to upload a profile image. (PNG/JPG accepted)"
                  />

                  {this.state.image ? <img src={this.state.image} /> : ""}
                </div>
                <div className={classes.ErrorImg}>
                  <span>
                    {/* {this.validator.message(
                      "Image",
                      this.state.image,
                      "required"
                    )} */}
                  </span>
                </div>
              </div>
              
              <button
                disabled={this.props.loading ? true : false}
                type="submit"
              >
                {this.state.btnClicked ? <Spinner /> : "Create an Account"}
              </button>
            </form>
          </div>
        </div>
      </div>: <Splash />
       }
      </>
    );
  }
}

export default RegisterUser;
