import React, { Component } from "react";
import { callHangupSuccess } from "../../../store/actions/Call";
import classes from "./FilteredData.module.scss";
import CVTags from "./CVTags";
import "./FilteredData.scss";
import { convertColorToString } from "material-ui/utils/colorManipulator";
import { json } from "body-parser";
import { connect } from "react-redux";
import * as actionsCreator from "../../../store/actions/index";
import Document from "../../../assets/image/paper.svg";
import Education from "../cvFilteredData/EducationInfo/EduEachData";
import Experience from "../cvFilteredData/ExperienceInfo/ExperienceInfo";
import SimpleReactValidator from "simple-react-validator";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { Dropdown, Modal } from "semantic-ui-react";
import moment from "moment";
import axios from "../../../axios";
import Preferences from "../CareerPreferences/PreferencesForSettings";
import Spinner from '../../../../src/components/UI/Spinner/Spinner';

class FilterData extends Component {
  constructor(props) {
    super();
    this.state = {
      isPDFModalOpen: false,
      inputEducation: false,
      inputExperience: false,
      insName: "",
      insType: "",
      inssubject: "",
      insGrade: "",
      exCompany: "",
      exPosition: "",
      exDuration: "",
      educationList: [],
      experienceList: [],
      arrayDataEdu: "",
      arrayDataExp: "",
      alert: false,
      text: "",
      uploadingstatus: false,
      cvURl: "",
      optionsInstitute: [],
      optionsGrade: [],
      optionsCrouse: [],
      optionsCompany: [],
      optionsDurations: [],
      optionsTitle: [],
      is_functionCall:false,
      settingData: {    
        career_preferences: [],           
      },
      isEditEducation:false,
      eduIndex:0,
      isEditExperience:false,
      expIndex:0,
      submitBtnClicked: false
    };
    
    this.handleNamechange = this.handleNamechange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleGrade = this.handleGrade.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handlePOsition = this.handlePOsition.bind(this);
    this.handleDuration = this.handleDuration.bind(this);
    this.btnSaveProceed = this.btnSaveProceed.bind(this);
    this.handleEduBtn = this.handleEduBtn.bind(this);
    this.handleExpBtn = this.handleExpBtn.bind(this);
    this.eduValidator = new SimpleReactValidator();
    this.xpValidator = new SimpleReactValidator();
    this.handleCloseEdu = this.handleCloseEdu.bind(this);
    this.handleCloseExp = this.handleCloseExp.bind(this);
  }

  // componentDidMount=()=>{
  //   setInterval(() => this.getCampaignCVDataByStaff(), 1000)
  // }

  // getCampaignCVDataByStaff=()=>{
  //   var campRef = sessionStorage.getItem("CAMPINVITE")
  //   axios.get('/?campref='+campRef,{
  //     headers: {
  //       Authorization: `Token ${localStorage.getItem('chat-token')}`
  //     }
  //   }).then(res=>{
  //     console.log("filtered data")
  //   })
  // }

  btnSaveProceed = () => {

    var features = this.props.flowFeatures;

    if (features.coverLetter == false){
      // this.setState({submitBtnClicked:true})
      if (features.video == false){

        var userId = localStorage.getItem("CandidateProfile_id02")
        window.location.replace("/")

        // var data = {
        //     campRef: sessionStorage.getItem("CAMPINVITE"),
        //     userId: userId
        // }

        // axios.post('update-candidate-added-by-staff/',data, {
        //     headers: {
        //         Authorization: `Token ${localStorage.getItem('chat-token')}`
        //     }
        // }).then(res=>{
        //     console.log(res,  "Results")
        //     if (res.data.Data.flag === true){
        //         Swal.fire({
        //             toast: true,
        //             position: "top-end",
        //             title: "Process Completed",
        //             showConfirmButton: false,
        //             timer: 2500,
        //             customClass: { container: "swal-success-settings" },
        //             imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        //             imageHeight: 20,
        //         });
        //         sessionStorage.removeItem("PROCEEDCLICKED");
        //         sessionStorage.removeItem("CAMPINVITE");
        //         window.location.replace("/")
        //     }
        // })
      }
      else if(features.video == true){
        if (
          this.state.educationList.length !== 0 &&
        // this.state.experienceList.length !== 0 &&
          this.props.skills !== ""
        ) {
          this.props.next()    
          this.saveSettingData()
        } else if(this.props.originalCVStatus === false){
          Swal.fire({
            toast: true,
            position: "top-end",
            title: "Please upload your original CV",
            showConfirmButton: false,
            timer: 2500,
            customClass: { container: "swal-success-settings" },
            imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
            imageHeight: 20,
          });
        }
        else if (this.props.skills === "") { 
          Swal.fire({
            toast: true,
            position: "top-end",
            title: "Skills field cannot be blank..!",
            showConfirmButton: false,
            timer: 2500,
            customClass: { container: "swal-success-settings" },
            imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
            imageHeight: 20,
          });
        } else if (this.state.educationList.length === 0) {  
          Swal.fire({
            toast: true,
            position: "top-end",
            title: "Education field cannot be blank..!",
            showConfirmButton: false,
            timer: 2500,
            customClass: { container: "swal-success-settings" },
            imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
            imageHeight: 20,
          });
        }
        // // yohan: experience not compulsory
        // else if (this.state.experienceList.length === 0) {
        //   Swal.fire({
        //     toast: true,
        //     position: "top-end",
        //     title: "Experience field cannot be blank..!",
        //     showConfirmButton: false,
        //     timer: 2500,
        //     customClass: { container: "swal-success-settings" },
        //     imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        //     imageHeight: 20,
        //   });     
        // }
      }
    }

    else if(features.coverLetter == true){
      if (
        this.state.educationList.length !== 0 &&
      // this.state.experienceList.length !== 0 &&
        this.props.skills !== ""
      ) {
        this.props.next()    
        this.saveSettingData()
      } else if(this.props.originalCVStatus === false){
        Swal.fire({
          toast: true,
          position: "top-end",
          title: "Please upload your original CV",
          showConfirmButton: false,
          timer: 2500,
          customClass: { container: "swal-success-settings" },
          imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          imageHeight: 20,
        });
      }
      else if (this.props.skills === "") { 
        Swal.fire({
          toast: true,
          position: "top-end",
          title: "Skills field cannot be blank..!",
          showConfirmButton: false,
          timer: 2500,
          customClass: { container: "swal-success-settings" },
          imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          imageHeight: 20,
        });
      } else if (this.state.educationList.length === 0) {  
        Swal.fire({
          toast: true,
          position: "top-end",
          title: "Education field cannot be blank..!",
          showConfirmButton: false,
          timer: 2500,
          customClass: { container: "swal-success-settings" },
          imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
          imageHeight: 20,
        });
      }
      // // yohan: experience not compulsory
      // else if (this.state.experienceList.length === 0) {
      //   Swal.fire({
      //     toast: true,
      //     position: "top-end",
      //     title: "Experience field cannot be blank..!",
      //     showConfirmButton: false,
      //     timer: 2500,
      //     customClass: { container: "swal-success-settings" },
      //     imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
      //     imageHeight: 20,
      //   });     
      // }
    } 
  };

  saveSettingData = () => { 
    var data = {
      phone_number: "",
      timeZone: "",
      current_location: "",
      onBoard_state: "UPDATE_SETTINGS",
      uploaded_at: "2019-09-20T00:00:00Z",
      upload: "",
      userId: this.props.profile.userData.profile[0].user_id,
      skills:  this.props.skills == null ? "":this.props.skills.toString(),
      profile_pic: "",
      first_name: "new",
      last_name: "new",
      education: this.state.educationList,
      experience: this.state.experienceList,
      career_preferences: this.state.settingData.career_preferences.toString(),
      format_type:""
    };
    var id = this.props.profile.userData.profile[0].id;
    this.props.onUpdateCandidateSetting(data, id,false);
     var currentTime = moment.utc().toISOString();
     this.props.onGetCandidateData(localStorage.getItem("chat-token"), currentTime);

  }

  handleEduBtn = () => {
    this.setState({
      inputEducation: !this.state.inputEducation,
    });
  };
  handleExpBtn = () => {
    this.setState({
      inputExperience: !this.state.inputExperience,
    });
  };

  handleCloseExp = () => {
    this.setState({
      inputExperience: false,
    });
    this.forceUpdate();
  };
  handleCloseEdu = () => {
    this.setState({
      inputEducation: false,
    });
    this.forceUpdate();
  };

  enterEducationDetails = () => {
    if (this.eduValidator.allValid()) {
      this.editEducationDetails()
      var newData = {
        institution: this.state.insName,
        type_ins: this.state.insType,
        stream: this.state.inssubject,
        grade: this.state.insGrade,
      };
      this.setState(
        {
          educationList: [...this.state.educationList, newData],
        },
        () => {
          var arrayData = (
            <Education
              deleteItemEduc={this.deleteEducation}
              data={this.state.educationList}
              editEducation={this.editEducation}
            />
          );
          this.setState({
            arrayDataEdu: arrayData,
          });
        }
      );
      // this.state.educationList.push(newData);
      this.setState({ insName: "", insType: "", inssubject: "", insGrade: "" });
      this.eduValidator.hideMessages();

      var edulist = [...this.props.eduList, ...[newData]];
    
      // var arrayData = <Education data={this.state.educationList} />;
      // this.setState({
      //   arrayDataEdu: arrayData,
      // });
      this.props.onSendEdu(edulist);
      this.setState({
        inputEducation: !this.state.inputEducation,isEditEducation:false
      });
    } else {
      this.eduValidator.showMessages();
      this.forceUpdate();
    }
  };
  editEducationDetails=()=>{
    if(this.state.isEditEducation==true){
        this.deleteEducation(this.state.eduIndex)
    }
  }

  enterExperienceDetails = () => {
    if (this.xpValidator.allValid()) {
      this.editExperienceDetails()
      var newData = {
        company: this.state.exCompany,
        position: this.state.exPosition,
        duration: this.state.exDuration,
      };

      this.setState(
        {
          experienceList: [...this.state.experienceList, ...[newData]],
        },
        () => {
          var expData = (
            <Experience
              deleteExpe={this.deleteExperience}
              data={this.state.experienceList}
              editExperience={this.editExperience}
            />
          );
          this.setState({
            arrayDataExp: expData,
          });
        }
      );

      this.state.experienceList.push(newData);
      this.setState({ exCompany: "", exPosition: "", exDuration: "" });
      this.xpValidator.hideMessages();

      // var explist = [...this.props.expList, ...[newData]]

      // var expData = <Experience data={this.state.experienceList} />;
      // this.setState({
      //   arrayDataExp: expData,
      // });
      console.log(this.state.experienceList, "experience list");
      this.props.onSendExp(this.state.experienceList);
      this.setState({
        inputExperience: !this.state.inputExperience,isEditExperience:false
      });
    } else {
      this.xpValidator.showMessages();
      this.forceUpdate();
    }
  };

  editExperienceDetails=()=>{
    if(this.state.isEditExperience==true){
      this.deleteExperience(this.state.expIndex)
    }
   }

  handleNamechange = (e, { value }) => {
    this.setState({
      insName: value,
    });
  };

  handleNameAddition = (e, { value }) => {
    this.state.optionsInstitute.push({ text: value, value: value });
  };

  handleTypeChange = (e) => {
    this.setState({
      insType: e.target.value,
    });
  };

  handleSubjectChange = (e, { value }) => {
    this.setState({
      inssubject: value,
    });
  };

  handleSubjectAddition = (e, { value }) => {
    this.state.optionsCrouse.push({ text: value, value: value });
  };
  handleGrade = (e, { value }) => {
    this.setState({
      insGrade: value,
    });
  };
  handleGradeAddition = (e, { value }) => {
    this.state.optionsGrade.push({ text: value, value: value });
  };

  handleCompany = (e, { value }) => {
    this.setState({
      exCompany: value,
    });
  };

  handleCompanyAddition = (e, { value }) => {
    this.state.optionsCompany.push({ text: value, value: value });
  };

  handlePOsition = (e, { value }) => {
    this.setState({
      exPosition: value,
    });
  };

  handlePositionAddition = (e, { value }) => {
    this.state.optionsTitle.push({ text: value, value: value });
  };

  handleDuration = (e, { value }) => {
    this.setState({
      exDuration: value,
    });
  };

  handleDurationAddition = (e, { value }) => {
    this.state.optionsDurations.push({ text: value, value: value });
  };

  submitCVData = () => {
    this.props.onGetChatResponseNew("/Submit_cv_data", false);
    localStorage.setItem("ON_CLOSE_MODAL", true);
  };

  handleFileChange = (e) => {
    console.log(e.target.files[0].type, "zxc");
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      var fileSize = e.target.files[0].size / 1024 / 1024;

      console.log(fileSize, "File size mb");

      if (parseInt(fileSize) <= 5) {
        var file = e.target.files[0];
        var extensions = e.target.files[0].name.split(".").pop();
        localStorage.setItem("CVName", file.name);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var preview = reader.result;
          localStorage.setItem("candidateCV", preview);
          this.uploadcvFile(preview, extensions);
        };
        localStorage.setItem("UPLOADING_STATUS", true);
        var dataProgress = true;
        this.props.onCVProgress(dataProgress);
        this.setState({
          uploadingstatus: true,
        });
      } else {
        Swal.fire({
          position: "center",
          // icon: "warning",
          imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
          title: "Warning!",
          text: "Maximum file size is 5MB",
          showConfirmButton: false,
          timer: 3500,
        });
      }
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });
      Swal.fire({
        position: "center",
        // icon: "warning",
        imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
        title: "Warning!",
        text: "Only PDF/DOC/DOCX formats allowed",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  uploadcvFile = (fileString, extensions) => {
    var candidateID = this.props.candidateDate.userData.profile[0].user_id;
    var data = {
      uploaded_at: new Date().toISOString(),
      upload: fileString,
      user_id: candidateID,
      cv_type: 1,
      format_type: extensions,
    };
   // this.props.onUploadCV(data);
    this.cvFiltering(data)
  };
  cvFiltering = (data) => {
    const token = localStorage.getItem("chat-token"); 
    axios
      .post("cv/", data, { 
        onUploadProgress: (pregresEvent) => {
          const {loaded, total} =  pregresEvent
                    let percent = Math.floor(loaded* 100/ total)                
                    this.setState({percentage : percent})
                    this.props.processPercentage(percent)
        },    
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        this.setState({ cvFile: res.data.upload });
        var id =this.props.candidateDate.userData.profile[0].user_id;
        axios
          .get("upload-cv-extract/?candidate_user_id=" + id, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((res) => {
            
            // var dataSample = {"Data":{
            //   "experience": [{'company': 'Insharp Technologies (Private)Limited', 'position': 'Project Manager', 'duration': 'February 2019 - Present (1 year 5 months)'}, {'company': 'ABC Company', 'position': 'Engineering Consultant', 'duration': 'March 2012 - January 2019 (6 years 11 months)'}], 
            //   "education": [{'institution': 'XYZ University', 'stream': " Bachelor's degree, Engineering · (2008 - 2012)"}], 
            //   "skills": {'company': ['Management', 'Electronic Engineering', 'Microsoft Excel'], 'candidate': ['Management', 'Electronic Engineering', 'Microsoft Excel']}}}
            
            this.props.onCVProgress(false);
            this.props.processPercentage(0)

            this.props.onCvFiltering(res.data);    
            //this.props.onCvFiltering(dataSample);    

          //  this.getCvFilterdData() 
        //    this.setState({ linkedinUploaded: true, fileUploadSpinner: false });
            this.props.getSystemLevelData();
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  editEducation=(i)=>{
    this.setState({
      inputEducation: true,
    });
    var val = this.state.educationList[i]
    this.setState({
      insName: val.institution,
      insType: val.type_ins,
      inssubject: val.stream,
      insGrade: val.grade,
      isEditEducation:true,
      eduIndex:i
    })
  }

  editExperience=(i)=>{
    this.setState({inputExperience:true});
    var value = this.state.experienceList[i]
    this.setState({
      exCompany:value.company,
      exPosition:value.position,
      exDuration:value.duration,
      isEditExperience:true,
      expIndex:i
    })
  }
  componentWillReceiveProps(props){
    console.log(props.cvSkilldata, "cv skills data ##########")
    if (props.cvSkilldata !== this.props.cvSkilldata) { 
    // if (this.state.is_functionCall === false){ 

        this.getCvFilterdData(props.cvSkilldata);

    // }      
    }
   }
  getCvFilterdData = (data) => {   
    var education = [];
   // if (this.props.cvSkilldata !== null) {
      education = data.Data.education;
      console.log(education,"cvdata")
      education.map((res, index) => {
        this.setState(
          {        
            educationList: [...this.state.educationList, ...[res]],
            
          },
          () => {
            var arrayData = (
              <Education
                deleteItemEduc={this.deleteEducation}
                data={this.state.educationList}
                editEducation={this.editEducation}
              />
            );
            this.setState({
              arrayDataEdu: arrayData,
            });
          }
        );
       this.state.educationList.push(res)
       // var edulist = [...this.props.eduList, ...[res]];
     //   var edulist = [...this.props.eduList, ...[res]];
      //  this.props.onSendEdu(this.state.educationList);
      });
   // }

    var experience = [];
   // if (this.props.cvSkilldata !== null) {   
      experience = data.Data.experience;
      experience.map((res, index) => {
        this.setState(
          {
            experienceList: [...this.state.experienceList, ...[res]],
          },
          () => {
            var expData = (
              <Experience
                deleteExpe={this.deleteExperience}
                data={this.state.experienceList}
                editExperience={this.editExperience}
              />
            );
            this.setState({
              arrayDataExp: expData,
            });
          }
        );
       this.state.experienceList.push(res);
      //  this.props.onSendExp(this.state.experienceList);
        this.setState({
          is_functionCall : true
        })
      });
  //  }
  };

  componentDidMount = () => {
    if (this.props.candidateDate !== null) {    
      this.getExistingData_exp();
      this.getExistingData_edu();
    }   
    this.getInstitutions();
    this.getGrades();
    this.getCrouse();
    this.getDuration();
    this.getPositions();
    this.getCompany();
  };

  deleteEducation = (i) => {
    this.state.educationList.splice(i, 1);
    this.setState({ educationList: [...this.state.educationList] }, () => {
      var arrayData = (
        <Education
          deleteItemEduc={this.deleteEducation}
          data={this.state.educationList}
          editEducation={this.editEducation}
        />
      );
      this.setState({
        arrayDataEdu: arrayData,
      });
    });
  };

  deleteExperience = (i) => {
    this.state.experienceList.splice(i, 1);
    this.setState(
      {
        experienceList: [...this.state.experienceList],
      },
      () => {
        var expData = (
          <Experience
            deleteExpe={this.deleteExperience}
            data={this.state.experienceList}
            editExperience={this.editExperience}
          />
        );
        this.setState({
          arrayDataExp: expData,
        });
      }
    );
  };

  getExistingData_exp = () => {

    var cv_data = "";
    try {
      cv_data = this.props.candidateDate.userData.cv[0].upload;
    } catch {
      cv_data = "";
    }

    this.setState({
      experienceList: [...this.props.candidateDate.userData.experience],
    }, () => {
      var expData = (
        <Experience data={this.state.experienceList}
          deleteExpe={this.deleteExperience} 
          editExperience={this.editExperience}
          />
      );
      this.setState({
        arrayDataExp: expData,
      });
    }
    )  

    this.setState({
      cvURl: cv_data,
    });
  };
  getExistingData_edu = () => {

    var cv_data = "";  
    try {
      cv_data = this.props.candidateDate.userData.cv[0].upload;
    } catch {
      cv_data = "";
    }

    this.setState(
      {
        educationList: [...this.props.candidateDate.userData.education],
      },
      () => {
        var arrayData = (
          <Education
            deleteItemEduc={this.deleteEducation}
            data={this.state.educationList}
            editEducation={this.editEducation}
          />
        );
        this.setState({
          arrayDataEdu: arrayData,
        });
      }
    );      
    this.setState({
      cvURl: cv_data,
    });
  };

  getInstitutions = () => {
    var institutions = [];
    this.props.systemData.Data.institutions.map((res, index) => {
      var obj = {
        text: res.instutution,
        value: res.instutution,
      };
      institutions.push(obj);
    });
    this.setState({ optionsInstitute: institutions });
  };

  getGrades = () => {
    var grades = [];
    this.props.systemData.Data.grades.map((res, index) => {
      var obj = {
        text: res.grades,
        value: res.grades,
      };
      grades.push(obj);
    });
    this.setState({ optionsGrade: grades });
  };

  getCrouse = () => {
    var courses = [];
    this.props.systemData.Data.courses.map((res, index) => {
      var obj = {
        text: res.courseName,
        value: res.courseName,
      };
      courses.push(obj);
    });
    this.setState({ optionsCrouse: courses });
  };

  getCompany = () => {
    var companies = [];
    this.props.systemData.Data.companies.map((res, index) => {
      var obj = {
        text: res.companyName,
        value: res.companyName,
      };
      companies.push(obj);
    });
    this.setState({ optionsCompany: companies });
  };

  getDuration = () => {
    var experience = [];
    this.props.systemData.Data.experience.map((res, index) => {
      var obj = {
        text: res.system_experience,
        value: res.system_experience,
      };
      experience.push(obj);
    });
    this.setState({ optionsDurations: experience });
  };
  getPositions = () => {
    var job_titles = [];
    this.props.systemData.Data.job_titles.map((res, index) => {
      var obj = {
        text: res.job_titles,
        value: res.job_titles,
      };
      job_titles.push(obj);
    });
    this.setState({ optionsTitle: job_titles });
  };

  setPreferences = (val) => {
    var settingData = { ...this.state.settingData };
    settingData.career_preferences = val.split(",");
    this.setState({ settingData });
  };

  render() {

    console.log(this.props.cvURl, "filter data #######")

    var cv = "";
    var cvFileType;

    try {
      cv = this.props.candidateDate.userData.cv[0].upload;
      cvFileType = cv.substr(cv.length - 3, 3);
    } catch {
      cv = "";
    }

    // if(this.props.cvUploaded === false){
    //   try{
    //     cv = this.props.candidateDate.userData.cv[0].upload;
    //     cvFileType = cv.substr(cv.length - 3, 3);
    //   }
    //   catch{
    //     cv = ""
    //   }
      
    // }

    if(this.props.cvUploaded === true){
      cv = this.props.cvURl;
      cvFileType = cv.substr(cv.length - 3, 3);
    }

    let educationListData = this.state.arrayDataEdu;
    let expListData = this.state.arrayDataExp;
    let viewBtn = "";
    let uploadBtn = "";
    let cvLable = "";
    let nextBtn = <button onClick={this.btnSaveProceed}>Next</button>;
    let userName = "";

    console.log(this.props.cvUploaded, "Cvuploaded")
    console.log(this.props.cvURl,"cv url")

    if (this.props.openCVModal === true) {
      // viewBtn = (
      //   <button
      //     className={classes.viewButton}
      //     onClick={() =>
      //       this.setState({
      //         isPDFModalOpen: true,
      //       })
      //     }
      //   >
      //     View
      //   </button>
      // );
      if (cvFileType === "pdf") {
        viewBtn = (
          <button
            className={classes.viewButton}
            onClick={() =>
              this.setState({
                isPDFModalOpen: true,
              })
            }
          >
            View
          </button>
        );
      } else {
        viewBtn = (
          <a
            className={classes.viewButton}
            href={this.state.cvURl}
            type="button"
          >
            Download
          </a>
        );
      }
      nextBtn = "";
      userName = localStorage.getItem("chat-username");
      cvLable = "Curriculam vitae";
      uploadBtn = (
        <span className={classes.uploadButtonWrap}>
          {/* <button onClick={this.uploadNewCV}> */}
          <input
            type="file"
            name="uploadedCV"
            id="uploadedCV"
            onChange={this.handleFileChange}
            className="inputfilez"
          ></input>
          <label for="uploadedCV">
            {/* <i className="fas fa-upload" /> */}
            Upload
          </label>
        </span>
      );
    }

    return (
      <div className={classes.cvFilteredWrapper}>
        <div className={classes.cvFilter}>
          {localStorage.getItem("OpenSmartCV") !== "true" && (
            <div className={classes.outerText}>
              Confirm your profile information
            </div>
          )}
          <div
            className={
              localStorage.getItem("OpenSmartCV") === "true"
                ? classes.whiteBoxNoShadow
                : classes.whiteBox
            }
          >
            <div>
              {/* {this.props.openCVModal === true && ( */}
              <div className={classes.cvActionsRow}>
                {userName && (
                  <div className={classes.left}>
                    <div className={classes.title} style={{ width: "80px" }}>
                      {cvLable}
                    </div>
                  </div>
                )}
                <div className={classes.rightCVTags}>
                  {userName && (
                    <div className={classes.pdfNameImage}>
                      <img src={Document} />
                      <span className={classes.name}>
                        {this.state.fileName
                          ? this.state.fileName
                          : `${userName.replace(" ", "_")}.pdf`}
                      </span>
                    </div>
                  )}
                  {cv == "" ? null : viewBtn}

                  {/* {this.props.openCVModal && (
                    <span>{cv == "" ? null : "Or"}</span>
                  )} */}

                  {
                    this.props.cvUploaded === false && cv != ""?
                    "or":null
                  }

                  {
                    this.props.cvUploaded === false?
                    uploadBtn:
                    null
                  }
                  {/* {uploadBtn} */}
                </div>
              </div>
              {/* )} */}
            </div>

            <div className={classes.skillsRow}>
              <div className={classes.left}>
                <div className={classes.title}>Career preference</div>
                {/* <div className={classes.badge}>CV</div> */}
              </div>
              <div className={classes.rightCVTags}>
              <Preferences
                preferences={this.state.settingData.career_preferences}
                setPreferences={this.setPreferences}
                somethingChanged={this.state.somethingChanged}
                handleSomethingChanged={this.handleSomethingChanged}
              />
              </div>
              
            </div>


            <div className={classes.skillsRow}>
              <div className={classes.left}>
                <div className={classes.title}>Skills</div>
                {/* <div className={classes.badge}>CV</div> */}
              </div>
              <div className={classes.rightCVTags}>
                <CVTags onDataSave={this.filteredData} cvData={this.props.cvSkilldata}/>
              </div>
            </div>

            <div className={classes.EduRow}>
              <div className={classes.left}>
                <div className={classes.title}>Education</div>
                <div className={classes.badge}>CV</div>
              </div>
              <div className={classes.rightEduContent}>
                <div className={classes.eduContent}>
                  {this.state.arrayDataEdu}
                  {this.state.inputEducation ? (
                    <div className={classes.educationInputs}>
                      <div className={classes.title}>Enter details</div>
                      <div className={classes.inputswrap}>
                        <Dropdown
                          options={this.state.optionsInstitute}
                          placeholder="Institute name"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.insName}
                          onAddItem={this.handleNameAddition}
                          onChange={this.handleNamechange}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />
                        <div className={classes.errorDiv}>
                          {this.eduValidator.message(
                            "Institute name",
                            this.state.insName,
                            "required"
                          )}
                        </div>
                        <input
                          type="text"
                          name="instututeType"
                          placeholder="Graduation Year"
                          onChange={this.handleTypeChange}
                          value={this.state.insType}
                        />
                        {/* <div className={classes.errorDiv}>
                          {this.eduValidator.message(
                            "Institute type",
                            this.state.insType,
                            "required"
                          )}
                        </div> */}
                        <Dropdown
                          options={this.state.optionsCrouse}
                          placeholder="Course name"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.inssubject}
                          onAddItem={this.handleSubjectAddition}
                          onChange={this.handleSubjectChange}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />
                        <div className={classes.errorDiv}>
                          {this.eduValidator.message(
                            "course",
                            this.state.inssubject,
                            "required"
                          )}
                        </div>
                        <Dropdown
                          options={this.state.optionsGrade}
                          placeholder="Grade achieved"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.insGrade}
                          onAddItem={this.handleGradeAddition}
                          onChange={this.handleGrade}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />
                        <div className={classes.errorDiv}>
                          {this.eduValidator.message(
                            "result",
                            this.state.insGrade,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className={classes.submitDiv}>
                        <button
                          id="deldeldel"
                          className={classes.delete}
                          onClick={this.handleCloseEdu}
                        >
                          Close
                        </button>
                        <button
                          className={classes.submit}
                          onClick={this.enterEducationDetails}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  ) : (
                      <div className={classes.addPlus}>
                        <button onClick={this.handleEduBtn}>
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className={classes.EduRow}>
              <div className={classes.left}>
                <div className={classes.title}>Experience</div>
                <div className={classes.badge}>CV</div>
              </div>
              <div className={classes.rightEduContent}>
                <div className={classes.eduContent}>
                <div style={{marginBottom:"10px",color:"#E48825"}}>If you don't have work experiences please add project experiences</div>
                  <div className={classes.eachData}>
                    {this.state.arrayDataExp}
                  </div>

                  {this.state.inputExperience ? (
                    <div className={classes.educationInputs}>
                      <div className={classes.title}>Enter details</div>
                      <div className={classes.inputswrap}>
                        <Dropdown
                          options={this.state.optionsCompany}
                          placeholder="Company name"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.exCompany}
                          onAddItem={this.handleCompanyAddition}
                          onChange={this.handleCompany}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />

                        <div className={classes.errorDiv}>
                          {this.xpValidator.message(
                            "companyName",
                            this.state.exCompany,
                            "required"
                          )}
                        </div>
                        <Dropdown
                          options={this.state.optionsTitle}
                          placeholder="Job title"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.exPosition}
                          onAddItem={this.handlePositionAddition}
                          onChange={this.handlePOsition}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />

                        <div className={classes.errorDiv}>
                          {this.xpValidator.message(
                            "jobTitle",
                            this.state.exPosition,
                            "required"
                          )}
                        </div>
                        <Dropdown
                          options={this.state.optionsDurations}
                          placeholder="Duration"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.exDuration}
                          onAddItem={this.handleDurationAddition}
                          onChange={this.handleDuration}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />
                        <div className={classes.errorDiv}>
                          {this.xpValidator.message(
                            "duration",
                            this.state.exDuration,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className={classes.submitDiv}>
                        <button
                          className={classes.delete}
                          onClick={this.handleCloseExp}
                        >
                          Close
                        </button>
                        <button
                          className={classes.submit}
                          onClick={this.enterExperienceDetails}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  ) : (
                      <div className={classes.addPlus}>
                        <button onClick={this.handleExpBtn}>
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className={classes.closeBtnWrapDiv}>
              <div className={classes.closeBtnDiv}>
            <button
              className={classes.closeButtton}
              onClick={this.btnSaveProceed}>Submit</button>
             </div>
             </div>
            <div
              className={classes.saveButtonWrapper}
              style={this.props.submitCV && { display: "none" }}
            >
              {/* <button onClick={this.btnSaveProceed}>Next</button> */}
              {nextBtn}
            </div>
            <SweetAlert
              show={this.state.alert}
              title="Warning"
              text={this.state.text}
              onConfirm={() => this.setState({ alert: false })}
            />
          </div>
        </div>
        <div style={{zIndex:"1001"}}>
        <Modal
          open={this.state.isPDFModalOpen}
          size="small"
          className="noBootstrapModal"
          onClose={this.closeModal}        
        >
          <div className={classes.insideViewModal}>
            <div className={classes.titlebar}>
              <h3>CV view</h3>
              <button
                onClick={() =>
                  this.setState({
                    isPDFModalOpen: false,
                  })
                }
                className={classes.closeX}
              >
                <i className="fas fa-times" />
              </button>
            </div>
            <embed
              src={cv}
              // src={this.props.cvURl}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </div>
        </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    phoneNumber: state.auth.phoneNumber,
    location: state.auth.location,
    deleteID: state.auth.itemListId,
    eduList: state.auth.eduList,
    skills: state.auth.skills,
    openCVModal: state.chatnew.openCVModal,
    candidateDate: state.CandidateData.userData,
    cvSkilldata: state.profile.cvFilterData,
    systemData: state.auth.systemData,
    originalCVStatus: state.CandidateData.originaCVStatus,
    profile: state.CandidateData.userData,
    flowFeatures: state.CampaignByRef.campaignFlow,
    appliedSaveData: state.chatnew.appliedCampaignTemp
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSendExp: (data) => dispatch(actionsCreator.sendExp(data)),
    onSendEdu: (data) => dispatch(actionsCreator.sendEdu(data)),
    // deleteItem: (data) => dispatch(actionsCreator.deleteItem(data))
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
    onCloseModal: (data) => dispatch(actionsCreator.CloseModal(data)),
    onUploadCV: (data) => dispatch(actionsCreator.UploadCVChat(data)),
    onCVProgress: (data) => dispatch(actionsCreator.CVProgress(data)),
    //  onUploadCvExtract:id =>dispatch(actionsCreator.UploadCvExtract(id))
    onUpdateCandidateSetting: (data, id,status) =>
    dispatch(actionsCreator.updateCandidateSetting(data, id,status)),
    onGetCandidateData: (data, time) =>
    dispatch(actionsCreator.getCandidateData(data, time)),
    onCvFiltering: (data) => dispatch(actionsCreator.cvFiltering(data)),
    getSystemLevelData: () => dispatch(actionsCreator.getSystemLevelData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterData);
