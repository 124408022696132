import * as actions from './actionTypes'
import axios from '../../axios';

//Get notifications
export const initGetVideoList = () => {
    return {
        type: actions.GET_VIDEOLIST_INIT
    }
}
export const successGetVideoList = (data) => {
    return {
        type: actions.GET_VIDEOLIST_SUCCESS,
        data
    }
}
export const failGetVideoList = () => {
    return {
        type: actions.GET_VIDEOLIST_FAIL
    }
}

export const getVideos =() =>{
    console.log("getVideos");
    const token = localStorage.getItem('chat-token')
    const campaign = localStorage.getItem('CampaignId_ref')
    return dispatch => {
        dispatch(initGetVideoList())
        axios.get('get-video-by-id/?campaignId='+campaign, {
            headers: {
                Authorization: `token ${token}`
            }
        }).then(res=>{
            console.log(res.data["Data"],"result0");
            dispatch(successGetVideoList(res.data["Data"]))
           
            
        }).catch(err=>{
            console.log("error",err);
            dispatch(failGetVideoList())
           
        })
    }
}