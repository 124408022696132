import React, { useState, useEffect } from "react";
import ChatComponent from "../../UI/ChatComponent/ChatComponent";
import SendInput from "../../UI/SendInput/SendInput";
import Loading from "../../UI/Loading/LoadingChatComponent/LoadingChatComponent";
import classes from "./ChatMessage.module.scss";
import ScrollableFeed from "react-scrollable-feed";
import { json } from "body-parser";
import Profiling from "../../../components/OnBoard/CareerPreferences/Profiling";

const chatMessage = props => {
  const [profilingModalIsOpen, setProfilingModalIsOpen] = useState(true);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [progressPercent, setProgressPercent] = useState(100 / 18);
  const [currentLap, setCurrentLap] = useState(1);
  const [positionInLap, setPositionInLap] = useState(0);

  const closeProfilingModal = () => {
    setProfilingModalIsOpen(false);
    localStorage.setItem("OpenQuestionModal", false);
    //props.onGetChatResponseNew("complete_career_test", false)
  };

  useEffect(() => {
    // if (!profilingModalIsOpen) window.location.reload();
    console.log("eeeeeee", profilingModalIsOpen);
  }, [profilingModalIsOpen]);

  const handleNextQuestion = () => {
    setQuestionNumber(questionNumber + 1);
    // setProgressPercent(100 / 54 + progressPercent);
  };

  useEffect(() => {
    // console.log("7----2----2", questionNumber, positionInLap);
    if (positionInLap < 18) {
      setPositionInLap(positionInLap + 1);
    } else {
      setPositionInLap(1);
    }
  }, [questionNumber]);

  useEffect(() => {
    // console.log("7----2----3", questionNumber, positionInLap);
    setProgressPercent((positionInLap / 18) * 100);
  }, [positionInLap]);

  let messages = <Loading />;
  if (!props.historyLoading) {
    console.log(props.responseMessages.length, "Response messages");
    console.log(typeof props.responseMessages.length, "response type");
    if (props.responseMessages.length === 0) {
      console.log("intitial response message null");
      var init_message = [];
      var init_response = "response";
      var isJson = false;
      messages =(
        <ChatComponent
          finishVideo={props.finishVideo}
          uploadSuccess={props.uploadSuccess}
          progress={props.progress}
          onDisabled={props.onDisabled}
          // key={i}
          message={init_message}
          message_response={isJson}
          type={init_response}
          // time={res.time}
          // videoStatus={res.videoStatus}
          // buttonStatus={res.buttonStatus}
          handlePayload={props.handlePayload}
          handleFileChange={props.handleFileChange}
          disabled={props.disabled}
          hangupCall={props.hangupCall}
          uploadSuccessAction={props.uploadSuccessAction}
          uploadCancelAction={props.uploadCancelAction}
          applyCampaing={props.applyCampaing}
          responseNull={props.responseNull}
          initialChat={props.initialChat}
          welcomeBack={props.welcomeBack}
          OpenChatPopUp = {props.OpenChatPopUp}
          scheduleInterview={props.scheduleInterview}
          SkippedApplycampaigns = {props.SkippedApplycampaigns}
          startCareerTest = {props.startCareerTest}
          AvailableCampaign = {props.AvailableCampaign}
        />
      );
    } else if (props.responseMessages.length > 0) {

      var isJson = true;

      console.log("Not an initial message")
      console.log(props.responseMessages ,"Props messages")

      messages = props.responseMessages.map((res, i) => {
        console.log(res.message,"resMsg")
      if (localStorage.getItem("OpenQuestionModal") == "true") {
          return (
            <Profiling
              key={i}            
              profilingModalIsOpen={profilingModalIsOpen}
              closeProfilingModal={closeProfilingModal}
              handleNextQuestion={handleNextQuestion}
              progressPercent={progressPercent}
            />
          );
        } else {
          return (
            <ChatComponent
              finishVideo={props.finishVideo}
              uploadSuccess={props.uploadSuccess}
              progress={props.progress}
              onDisabled={props.onDisabled}
              key={i}
              message={res.message}
              message_response={isJson}
              type={res.type}
              time={res.time}
              videoStatus={res.videoStatus}
              buttonStatus={res.buttonStatus}
              handlePayload={props.handlePayload}
              handleFileChange={props.handleFileChange}
              disabled={props.disabled}
              hangupCall={props.hangupCall}
              uploadSuccessAction={props.uploadSuccessAction}
              uploadCancelAction={props.uploadCancelAction}
              applyCampaing={props.applyCampaing}
              responseNull={props.responseNull}
              initialChat={props.initialChat}
              welcomeBack={props.welcomeBack}
              OpenChatPopUp = {props.OpenChatPopUp}
              scheduleInterview={props.scheduleInterview}
              SkippedApplycampaigns = {props.SkippedApplycampaigns}
              startCareerTest = {props.startCareerTest}
              AvailableCampaign = {props.AvailableCampaign}
            />
          );
        }
      });
    }
    console.log(messages, "Messages")
  }
  return (
    <div className={classes.ChatMessage}>
      <ScrollableFeed forceScroll={true} className={classes.messagesTogether}>
        {messages}
      </ScrollableFeed>

      {props.loading ? <Loading /> : null}

      <SendInput sendMessage={props.sendMessage} />
    </div>
  );
};

export default chatMessage;
