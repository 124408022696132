import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from '../../../../src/store/actions/index'

class GetToken extends Component{

    constructor(props){
        super(props);
    }

    componentDidMount=()=>{
        this.props.onGetAuth(sessionStorage.getItem("ref"))
    }

    render(){
        return(
            <div></div>
        )
    }
}

const mapDispatchToProps= (dispatch) => {
    return{
        onGetAuth : (ref)=> dispatch(actions.GetAuthRef(ref))
    }
}

export default connect(null, mapDispatchToProps)(GetToken)