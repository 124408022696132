import React, { Component } from "react";
import classes from "./ExperienceInfo.module.scss";

class XPEachData extends Component {
  render() {
    return (
      <div className={classes.eachData}>
        <div className={classes.dataFields}>
          <div className={classes.institute}>Microsoft Tech Associate</div>
          <div>Trainee Software Engineer</div>
          <div>Feb 2013 to Jul 2013</div>
        </div>
        <div className={classes.delete}>
          <button>
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      </div>
    );
  }
}

export default XPEachData;
