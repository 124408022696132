import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import moment from "moment";
import "./CampaignsListModal.scss";
import { CountryDropdown } from "react-country-region-selector";
import EachCampaignModal from "../EachCampaignModal/EachCampaignModal";
import searchImg from "../../../assets/image/searchColor.svg";

import { connect } from "react-redux";
import * as actionsCreator from "../../../store/actions/index";
import { onCloseChatSuccess } from "../../../store/actions/ChatNew";
import defaultCamImage from "../../../assets/image/sia_default.png";


class CampaignsListModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      country: "",
      modalData: {
        id: "",
        heading: "",
        campaignImage: "",
        startDate: "",
        location: "",
        description: "",
      },
      nowFiltering: "All time",
      isfilter: false,
    };
  }

  selectCountry = (value) => {
    this.setState({
      country: value,
      isfilter: true,
    });
    var data = [
      {
        filterName: "country",
        filterValues: value,
      },
      {
        filterName: "time",
        filterValues: this.state.nowFiltering,
      },
    ];
    var type = 0;
    this.props.campaignData.map((res, index) => {
      var data = res.split("_");
      type = parseInt(data[10]);
    });

    var filters = JSON.stringify(data);
    var currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    var candidateId = this.props.candidateData.userData.profile[0].user_id;
    this.props.onFilterCampaigns(type, filters, currentTime, candidateId);
  };

  filterCampaigns = (timeVal) => {
    this.setState(
      {
        isfilter: true,
        nowFiltering: timeVal,
      },
      () => console.log("newww", this.state.nowFiltering)
    );
    var data = [
      {
        filterName: "country",
        filterValues: this.state.country,
      },
      {
        filterName: "time",
        filterValues: timeVal,
      },
    ];
    var type = 0;
    this.props.campaignData.map((res, index) => {
      var data = res.split("_");
      type = parseInt(data[10]);
    });
    var filters = JSON.stringify(data);
    var currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    var candidateId = this.props.candidateData.userData.profile[0].user_id;
    this.props.onFilterCampaigns(type, filters, currentTime, candidateId);
  };

  render() {
    var filterData = (
      <div className="when-no-results">
        <img src={searchImg} />
        <div className="rightT">
          <div className="headerT">No results found..</div>
          <div className="bodyT">
            We don't have campaigns matching your search criteria
          </div>
        </div>
      </div>
    );
    var count = "";
    if (
      this.props.campaignFilter != null &&
      this.props.campaignFilter.Data.Campaigns.length > 0
    ) {
      count = this.props.campaignFilter.Data.count;
      filterData = this.props.campaignFilter.Data.Campaigns.map(
        (res, index) => {
          return (
            <li key={res.id}>

              <div
                className="left"
                onClick={() => this.props.handleOpenEachModal(index)}
              >
                <img src={res.campaignImage?res.campaignImage:defaultCamImage} />
              </div>
              <div className="right">
                <h4
                  className="title"
                  onClick={() => this.props.handleOpenEachModal(index)}
                >
                  {res.heading}
                </h4>
                <EachCampaignModal
                  data={this.props.data}
                  applyButton={this.props.applyButton}
                  handleNextButton={this.props.handleNextButton}
                  handlePrevButton={this.props.handlePrevButton}
                  fadeLeftNav={this.props.fadeLeftNav}
                  fadeRightNav={this.props.fadeRightNav}
                  handleOpenEachModal={this.props.handleOpenEachModal}
                  handleCloseEachModal={this.props.handleCloseEachModal}
                  eachModalIsOpen={this.props.eachModalIsOpen}
                  campaignsData={this.props.allCampaignsData}
                  currentNum={this.props.currentNum}
                />
                <div className="data">
                  <div className="date">
                    <i className="far fa-calendar-alt"></i>
                    <span>{moment(res.startDate).format("YYYY-MM-DD")}</span>
                  </div>
                  {/* <div className="time">
                 <i className="far fa-clock"></i>
                 <span>09:00am</span>
                 <span>to</span>
                 <span>04:00pm</span>
               </div> */}
                </div>
              </div>
            </li>
          );
        }
      );
    }
    const nowFiltering = this.state;
    return (
      <div className="modalInChat">
        <Modal
          isOpen={this.props.isOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.props.handleCloseListModal}
          contentLabel="Example Modal"
          className="full-screen-modal-list"
          ariaHideApp={false}
        >
          <div className="close-div">
            <button onClick={this.props.handleCloseListModal}>
              <i className="fas fa-times" />
            </button>
          </div>
          <div className="campaign-list-wrapper">
            <div className="campaign-list-inner">
              <div className="leftFilters">
                <div className="filter-by-date">
                  <div className="date-title">FILTER BY DATE</div>
                  <div className="date-options">
                    <button
                      className={
                        this.state.nowFiltering === "Today" ? "active" : ""
                      }
                      onClick={() => this.filterCampaigns("Today")}
                    >
                      Today
                    </button>
                    <button
                      className={
                        this.state.nowFiltering === "Next 7 days"
                          ? "active"
                          : ""
                      }
                      onClick={() => this.filterCampaigns("Next 7 days")}
                    >
                      Next 7 days
                    </button>
                    <button
                      className={
                        this.state.nowFiltering === "Next 30 days"
                          ? "active"
                          : ""
                      }
                      onClick={() => this.filterCampaigns("Next 30 days")}
                    >
                      Next 30 days
                    </button>
                    <button
                      className={
                        this.state.nowFiltering === "Next 6 months"
                          ? "active"
                          : ""
                      }
                      onClick={() => this.filterCampaigns("Next 6 months")}
                    >
                      Next 6 months
                    </button>
                    <button
                      className={
                        this.state.nowFiltering === "All time" ? "active" : ""
                      }
                      onClick={() => this.filterCampaigns("All time")}
                    >
                      All time
                    </button>
                  </div>
                </div>
                <div className="filter-by-location">
                  <div className="location-title">FILTER BY LOCATION</div>
                  <div className="location-options">
                    <CountryDropdown
                      className="inputCountry"
                      value={this.state.country}
                      onChange={this.selectCountry}
                      name="country"
                      placeholder="Country"
                    />
                  </div>
                </div>
                <div className="campaign-count">
                  {this.state.isfilter
                    ? count + " Campaigns found"
                    : this.props.campaignData.length + " Campaigns found"}
                </div>
              </div>
              <ul>
                {this.state.isfilter
                  ? filterData
                  : this.props.campaignData.map((res, index) => {
                      var data = res.split("_");
                      var eachCampaign = {
                        heading: data[6],
                        campaignImage: data[4],
                        startDate: moment(data[1]).format("YYYY-MM-DD"),
                        location: data[5],
                        description: data[3],
                        type: data[10],
                      };
                      return (
                        <li key={index}>
                          <div
                            className="left"
                            onClick={() =>
                              this.props.handleOpenEachModal(index)
                            }
                          >
                            <img src={eachCampaign.campaignImage?eachCampaign.campaignImage:defaultCamImage} />
                          </div>
                          <div className="right">
                            <h4
                              className="title"
                              onClick={() =>
                                this.props.handleOpenEachModal(index)
                              }
                            >
                              {eachCampaign.heading}
                            </h4>
                            <EachCampaignModal
                              data={this.props.data}
                              applyButton={this.props.applyButton}
                              handleNextButton={this.props.handleNextButton}
                              handlePrevButton={this.props.handlePrevButton}
                              fadeLeftNav={this.props.fadeLeftNav}
                              fadeRightNav={this.props.fadeRightNav}
                              handleOpenEachModal={
                                this.props.handleOpenEachModal
                              }
                              handleCloseEachModal={
                                this.props.handleCloseEachModal
                              }
                              eachModalIsOpen={this.props.eachModalIsOpen}
                              campaignsData={this.props.allCampaignsData}
                              currentNum={this.props.currentNum}
                            />
                            <div className="data">
                              <div className="date">
                                <i className="far fa-calendar-alt"></i>
                                <span>{eachCampaign.startDate}</span>
                              </div>
                              {/* <div className="time">
                            <i className="far fa-clock"></i>
                            <span>09:00am</span>
                            <span>to</span>
                            <span>04:00pm</span>
                          </div> */}
                            </div>
                          </div>
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    candidateData: state.CandidateData.userData,
    campaignFilter: state.chatnew.filterCampaigns,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFilterCampaigns: (id, data, currentTime, candidateId) =>
      dispatch(
        actionsCreator.getFilterCampaigns(id, data, currentTime, candidateId)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsListModal);
