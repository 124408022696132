import React, { Component, useCallback } from "react";
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";
import classNames from "./ViewCoverLetter.module.scss";
import paper from "../../../assets/image/paper.svg";

class ViewCoverLetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    localStorage.removeItem("coverLetterName");
  }

  componentDidMount = () => {
    var userId = this.props.profile.id;
    let campaignId = localStorage.getItem("Campaign_ID");
    this.props.getCoverLetter(userId, campaignId);
    try {
      const elementx = document.querySelector("#candidate-cvrletter-view");
      elementx.style.height = "10px";
      const styleHeight =
        elementx.scrollHeight < 300 ? elementx.scrollHeight : 300;
      elementx.style.height = styleHeight + "px";
    } catch {}
  };

  render() {
    var letter = "";
    var isFile = "";
    var file = "";

    try {
      letter = this.props.coverLetter.Data.coverLetter;
      isFile = this.props.coverLetter.Data.is_letter;
      file = this.props.coverLetter.Data.coverLetter_url;
    } catch {
      letter = "";
      isFile = "";
      file = "";
    }
    return (
      <div>
        {isFile == true ? (
          <textarea
            className={classNames.cvrText}
            id="candidate-cvrletter-view"
          >
            {letter}
          </textarea>
        ) : (
          <div className={classNames.cvrFile}>
            <img src={paper} />
            <span>{localStorage.getItem("coverLetterName")}</span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    coverLetter: state.CandidateData.coverLetter,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCoverLetter: (data, id) => dispatch(actions.getCoverLetter(data, id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewCoverLetter);
