import React, { Component } from "react";
import classes from "../CampaignShare/CampaignShareGroup.module.scss";
import { Route, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsCreator from "../../store/actions/index";
import { Redirect } from "react-router";
import defaultCompany from "../../assets/image/sia-in-box.png";
import uconect from "../../assets/image/uconect.svg";
import sialogo from "../../assets/image/sia-logo-ori.png";
import kalaniya from "../../assets/image/Kelaniya.png";
import kalaniyaUcon from "../../assets/image/uconnect.png";
import { Card, Image } from "semantic-ui-react";
import kalaniyaNew from "../../assets/image/kelaniyaNew.png";
import UconICBS from "../../assets/image/UconnecICBS.png";
import icbs from "../../assets/image/icbs.png";
import medical from "../../assets/image/medical.svg";
import sia from "../../assets/image/sia-logo-ori.png";
import defaultCamImage from "../../assets/image/sia_default.png";
import jimma from "../../assets/image/Jimma.jpg"
import giz from "../../assets/image/giz.png"
import afr from "../../assets/image/afr.png"
import bizmo from "../../assets/image/bizmo.png"



class CampaignShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      URL: process.env.REACT_APP_API_URL + "chat",
      chatToken: localStorage.getItem("chat-token"),
      isApplied: false,
      applyLoding: false,
      shareComRef:""
    };
  }
  componentDidMount = () => {
    const shareComRef = new URLSearchParams(
      this.props.location.search.substring(1)
    ).get("shareCom");
    this.setState({shareComRef:shareComRef})
    this.props.ongetShareGroup(shareComRef)

  };
  goDashoard = () => {
    this.setState({ isApplied: true });
    sessionStorage.removeItem("shareCom");
  }
  onButtonClick = () => {

  };

  render() {
    const { isApplied } = this.state;
    if (isApplied === true) {
      return <Redirect to={"/"} />;
    }
    var companyLogo = "";
    var companyName = "";
    var shareEditName = ""
    var groupName = ""

    var campaign = []
    if (this.props.shareGroup != null) {
      campaign = this.props.shareGroup.Data.campaignData
      companyLogo = this.props.shareGroup.Data.companyData.companyLogo
      companyName = this.props.shareGroup.Data.companyData.name
      groupName = this.props.shareGroup.Data.commonData.groupName
    }
    return (
      <>
      {this.state.shareComRef != "06pSHY2vMT" ?
      <div className={classes.ShareGroupChatWrapper}>
        <div className={classes.ShareGroupChatContent}>
          <div className={classes.ShareGroupChatContainer}>
            <div className={classes.ShareGroupCampainLogoContent}>
              <img src={jimma} style={{width:'200px'}}/>
              <span className={classes.TextHedding}>Jimma TVET Graduates Annual Digital Career fair</span>
            </div>
            <div className={classes.OrganizedByContent}>
              <div className={classes.OrganizedByText}>In Partnership with</div>
              <div className={classes.OrganizedByLogoContent}>
                <div className={classes.OrganizedByLogo1}>
                  <img src={giz} style={{width:'80px'}} />
                </div>
                <span className={classes.Line}></span>
                <div className={classes.OrganizedByLogo2}>
                  <img src={afr} />
                </div>
                <span className={classes.Line}></span>
                <div className={classes.OrganizedByLogo2}>
                  <img src={bizmo} />
                </div>
                <span className={classes.Line}></span>
                <div className={classes.OrganizedByLogo2}>
                  <img src={sialogo} />
                </div>
              </div>
            </div>
            {/* <h1 className={classes.MainHeader}>wellcome to {groupName} !</h1> */}
            {/* <div className={classes.OrganizedContenta}>
            <div className={classes.OrganizedLogo}>
            <img src={companyLogo ? companyLogo : defaultCompany} />
            </div>
            <div className={classes.TextContnet}>
              <p>Organized by</p>
              <h3>
              <div className={classes.title}>{shareEditName}</div>
              <div className={classes.type}>{companyName}</div>
              </h3>
            </div>
          </div> */}
            <div className={classes.SubHeader}>
              <span style={{ lineHeight: "150%" }}>
              Jimma University  In Partnership with  AFROVALLEY, GIZ , and SIA has launched Digital career fair platform with the first pilot to support Jimma TVET Graduates Annual Career fair that will be starting from 14th August 2021.<br></br>

              The digital platform will improve the coordination between academia and industry while improving youth employability and entrepreneurship.<br></br>

              This is also inline with Jimma university community service vision and engagement  to bring Jimma and it's surrounding to the digital economy for sustainable development.<br></br>
              The event is aimed at Tourisum, Hotel Management & Construction Graduates and they will be considered for current and future opportunities in employment, innovation and upskilling engagements
              </span>         
             <span style={{color:"#de2929",fontSize:"14px",lineHeight:"3.4"}}>All the Applications should be submitted on or before 22nd August 2021, 9 PM local time</span>
            </div>

            <h2>Please click the company of your choice to apply</h2>
            <div className={classes.CampainesContent}>
              <div className={classes.CampainesContentRow}>
                {campaign.map((res, index) => {
                  return (
                    <>
                      {res.campaign.isPublish ?
                        <div className={classes.CampainesContentCol} key={index} onClick={this.viewCampaign}>
                          <a target="_blank" href={"/?ShareRef=" + res.shareRef.ref + "&share=True"} className={classes.CampainesContentImg}>
                            <Image
                              src={res.campaign.campaignImage? res.campaign.campaignImage: defaultCamImage}
                              size='medium'
                              wrapped
                              ui={false}
                              href
                            />
                          </a>
                        </div> 
                        :
                         <div className={classes.CampainesContentCol} key={index} onClick={this.viewCampaign} style={{opacity:"0.4"}} title="Applications closed">
                         <a href="#" className={classes.CampainesContentImg}>
                           <Image
                             src={res.campaign.campaignImage? res.campaign.campaignImage: defaultCamImage}
                             size='medium'
                             wrapped
                             ui={false}
                             href
                           />
                         </a>
                       </div>                        
                      }
                    </>
                  )
                })
                }
              </div>
              {/* <div className={classes.closeText}>Applications closed</div> */}
            </div>
            <h4>Once applied for the first company please use the chatbot functionality to find and apply for other companies in this list</h4>
            <div className={classes.GroupAlertDanger}>
              * Please note that the Current Version of the Sia platform works best on a desktop or a laptop using the latest google chrome browser
             </div>
            <div className={classes.bottomDiv}>         
              <div className={classes.row5}>
              </div>
            </div>
          </div>
        </div>
          </div> :
          <div className={classes.brokenWrapper}>
            <div className={classes.brokenInner}>
              <img src={medical} />
              <div>
                <h2>Ug oh..!</h2>
                <h4>This doesn't seem to be a valid url</h4>
              </div>
            </div>
            <div className={classes.fixFooterWrap}>
              <div className={classes.fixFooter}>
                <img src={sia} />
                <div className={classes.linkWrapper}>
                  <div className={classes.linkdiv} onClick={this.goHome}>
                    {/* Go to <b>Home</b> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shareGroup: state.CandidateData.shareGroup,
    auth: state.auth.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ongetShareGroup: (data) => dispatch(actionsCreator.getCompanyShareGroup(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignShare)
);
