import React from "react";
import CampaignsListModal from "../CampaignsListModal/CampaignsListModal";
import axios from "../../../../src/axios";
import { connect } from "react-redux";
import swal from "sweetalert2";
import * as actionsCreator from "../../../store/actions/index";
import Spinner from "../../UI/Spinner/Spinner";
import moment from "moment";
import defaultCompany from "../../../assets/image/sia-in-box.png";
import defaultCamImage from "../../../assets/image/sia_default.png";

class CampaignSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      islistModalOpen: false,
      allCampaignsX: [],
      showSpinner: false,
    };
  }

  componentDidMount() {
    try {
      const elementx = document.querySelector("#candidate-descr-textarea");
      elementx.style.height = "10px";
      const styleHeight =
        elementx.scrollHeight < 200 ? elementx.scrollHeight : 200;
      elementx.style.height = styleHeight + "px";
    } catch {}
  }
  handleOpenListModal = () => {
    this.setState({ islistModalOpen: true });
  };

  handleCloseListModal = () => {
    this.setState({ islistModalOpen: false });
  };

  handleCloseBothModals = () => {
    this.setState({ islistModalOpen: false });
    this.props.closeEachModal && this.props.closeEachModal();
  };

  onApply = (value) => {
    this.setState({
      showSpinner: true,
    });
    var data = {
      candidateid: this.props.profile.id,
      campaignId: parseInt(value),
      ref: "",
      reminderDuration: 0,
      accessed: 0,
      status: "APPLIED",
      applied_method: "SIA",
    };
    //props.onApplyCampaign(data);
    var token = localStorage.getItem("chat-token");
    localStorage.setItem("CAMPAIGN_APPLIED_ID", value);
    this.props.onSaveApplyData(data);
    axios
      .post(process.env.REACT_APP_API_URL + "chat/apply-stand-by/", data, {
        headers: {
          Authorization: `token ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.props.onGetChatResponseNew("successfully applied", false);
        this.props.listModalIsOpen = false;

        // swal.fire({
        //   toast: true,
        //   position: "top-end",
        //   title: "Successfully applied",
        //   showConfirmButton: false,
        //   timer: 2500,
        //   customClass: { container: "swal-success-settings" },
        //   imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        //   imageHeight: 20,
        // });
      })
      .catch((resCatch) => {
        // swal.fire({
        //   toast: true,
        //   position: "top-end",
        //   title: "Already applied , pleaese try another campaign",
        //   showConfirmButton: false,
        //   timer: 2500,
        //   customClass: { container: "swal-success-settings" },
        //   imageUrl: "https://image.flaticon.com/icons/svg/845/845646.svg",
        //   imageHeight: 20,
        // });
        // this.setState({
        //   showSpinner: false,
        // });
      });
  };

  render() {
    return (
      <div className="each-campaign-wrapper">
        <div className="each-campaign">
          <div className="images">
            <img
              className="background"
              src={
                this.props.data.campaignImage
                  ? this.props.data.campaignImage
                  : defaultCamImage
              }
            />
            <img
              className="foreground"
              src={
                this.props.data.campaignImage
                  ? this.props.data.campaignImage
                  : defaultCamImage
              }
            />
          </div>
          <div className="campaign-main-info">
            <div className="title">{this.props.data.heading}</div>
            <div className="category">{this.props.data.campaignType}</div>
            <div className="date">
              {moment(this.props.data.startDate).format("DD-MM-YYYY")}
            </div>
          </div>
          <div className="campaign-bottom-description">
            <div className="company">
              <img
                alt="Company logo"
                src={
                  this.props.data.companyLogo
                    ? this.props.data.companyLogo
                    : defaultCompany
                }
              />
              <div>{this.props.data.company}</div>
            </div>
            <div className="job-location">{this.props.data.location}</div>

            <div className="campaign-description-text">
              {/* {this.state.campaignDes} */}
              <div dangerouslySetInnerHTML={{ __html: this.props.data.description }} />
            </div>

            {/* <textarea
              id="candidate-descr-textarea"
              readOnly
              className="campaign-description-text"
              defaultValue={this.props.data.description}
            /> */}

            <div
              className="campaign-apply-button"
              style={{ textAlign: "center" }}
            >
              {this.props.applyButton && (
                <button onClick={(val) => this.onApply(this.props.data.id)}>
                  {this.state.showSpinner ? <Spinner /> : "Apply"}
                </button>
              )}
            </div>
          </div>
          {this.props.data.campaignsListLength && (
            <div className="outer-text">
              <span>
                {this.props.data.campaignsListLength}
                &nbsp;campaigns found
              </span>
              <span style={{ marginLeft: "6px" }}>|</span>
              <button onClick={this.props.handleCloseEachModal}>
                Back to the list
              </button>
              {/* <button onClick={this.props.handleOpenListModal}>Show all</button> */}
              {/* <CampaignsListModal
                isOpen={this.props.listModalIsOpen}
                handleOpenListModal={this.props.handleOpenListModal}
                handleCloseListModal={this.props.handleCloseListModal}
                handleCloseBothModals={this.handleCloseBothModals}
                _handleCloseEachModal={this.props.handleCloseEachModal}
                classes="tt"
                title="Take me to the list"
                allCampaignsData={this.props.campaignsData}
                campaignData={this.props.campaignsData}
              /> */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignFinder: state,
    profile: state.profile.userProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
    onSaveApplyData: (data) => dispatch(actionsCreator.saveApplyData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSlide);
