import React, { useEffect, useState } from "react";
import classes from "./Quetion.module.scss";
import axios from "../../../../../axios";
import RenderToLayer from "material-ui/internal/RenderToLayer";
import Questions from "../../../ChatModal/ChatVideo/Questions/Questions";

function Quetion(props) {
  const [quetions, setQuetions] = useState(null);
  const [qNo, setQNo] = useState(0);
  const [noOfQuetions, setNoOfQuetions] = useState(null);

  const handleNext = () => {
    console.log(qNo, "Qnumber");
    let no = qNo;
    setQNo((no += 1));
  };
  useEffect(() => {
    console.log("PAUSEd", props.paused);
    const token = localStorage.getItem("chat-token");
    var campaignID = localStorage.getItem("CAMPAIGN_APPLIED_ID");
    console.log(campaignID, "Campaign ID");
    campaignID = campaignID;
    console.log("test");
    console.log(
      process.env.REACT_APP_API_URL +
        `chat/get-hr-quesions/?campaignId=` +
        campaignID,
      "camapign ID url"
    );

    axios
      .get(
        process.env.REACT_APP_API_URL +
          `chat/get-hr-quesions/?campaignId=` +
          campaignID,
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setQuetions(res.data.Data.filter((val) => val !== ""));
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log(quetions.results[qNo].id, "Quesitons number")
  }, [console.log(quetions, "Questions array")]);
  return (
    <>
      <div className={classes.Cqate}>
        {props.recodeinStart ? (
          quetions ? (
            <>
              <div className={classes.Quetion} title={quetions[qNo]}>
                {/* {[qNo + 1]}/{quetions.length} - Our first question to you? */}
                {[qNo + 1]}/{quetions.length} - {quetions[qNo]}
              </div>
              <button
                className={classes.pauseBtn}
                onClick={!props.paused ? props.handlePause : props.handlePlay}
                title={!props.paused ? "Pause" : "Resume"}
              >
                {!props.paused ? (
                  <i class="fas fa-pause" style={{ color: "#1697fa" }} />
                ) : (
                  <i class="fas fa-circle" style={{ color: "red" }} />
                )}
              </button>
              {!props.paused && (
                <React.Fragment>
                  {quetions.length - 1 === qNo ? (
                    <div className={classes.buttonWrap}>
                      <button onClick={props.handleFinish}>
                        <span className={classes.arrows}>
                          <i class="fas fa-flag"></i>
                        </span>
                        <span className={classes.nextText}>Finish</span>
                      </button>
                    </div>
                  ) : (
                    <div className={classes.buttonWrap}>
                      <button onClick={handleNext}>
                        <span className={classes.arrows}>&gt;&gt;</span>
                        <span className={classes.nextText}>NEXT</span>
                      </button>
                    </div>
                  )}
                </React.Fragment>
              )}
            </>
          ) : null
        ) : null}
      </div>
      {props.recodeinStart ? <div className={classes.backdrop}></div> : ""}
    </>
  );
}

export default Quetion;
