import * as actions from "./actionTypes";
import axios from "../../axios";
import moment from "moment";
import { json } from "body-parser";

export const chatInitNew = (
  query = "query",
  queryVisibility,
  humanizedTime = moment().format()
  //humanizedTime = "Just now 123"
  ///humanizedTime = "Just now"
) => {
  return {
    type: actions.CHATNEW_INIT,
    query,
    queryVisibility,
    humanizedTime
  };
};
export const chatSuccessNew = (response, queryVisibility, humanizedTime) => {
  return {
    type: actions.CHATNEW_SUCCESS,
    response,
    queryVisibility,
    humanizedTime
  };
};
export const chatFailNew = () => {
  return {
    type: actions.CHATNEW_FAIL
  };
};

// export const testWebSocket = (query) => {
//  var host = "ws://localhost:4010/"
//   var wsocket = new WebSocket(host);
//   wsocket.onopen =  (evt)=> {
//     console.log(evt,"Server connected,rasa");

//     wsocket.send(JSON.stringify({
//     id: "client1"
//     }));
//   };
//   wsocket.onmessage =(evt) => {
//     console.log(evt, "Socket messsage");    
//   }; 
// }

export const getChatResponseNew = (query, visibility = true, notification = false) => {

  return dispatch => {
   // console.log(query, "query");

    var host = process.env.REACT_APP_WEB_SOCKET_SIA_CHAT
    var wsocket = new WebSocket(host);

    wsocket.onopen = (evt) => {
      console.log(evt, "Server connected,rasa");
      wsocket.send(JSON.stringify({
        id: parseInt(localStorage.getItem("CandidateProfile_id02")),
        query: query
      }));
    };
    wsocket.onmessage = (evt) => {
      console.log(evt, "Socket messsage");
      var data = JSON.parse(evt.data)
      if (data.response != null) {
       // console.log(data.response, "respanse")
        var response = JSON.stringify(data.response)
        var queryVisibility = data.query_visibility
        var humanizedTime = data.time
        
      //  wsocket.close();
        dispatch(chatSuccessNew(response, queryVisibility, humanizedTime));
      }
    };
    // wsocket.onclose = (evt) => {
    //   //   alert("a")   
    // };
    const token = localStorage.getItem("chat-token");
    const ref = localStorage.getItem("chat-ref-id");

    if (notification) {
    }

    if (!notification) {
      dispatch(chatInitNew(query, visibility));
    }
    // axios
    //   .post(
    //     "conversations/",
    //     ref
    //       ? {
    //           query: query,
    //           ref: ref
    //         }
    //       : {
    //           query: query
    //         },
    //     {
    //       headers: {
    //         Authorization: `Token ${token}`
    //       }
    //     }
    //   )
    //   .then(res => {
    //     if (ref) {
    //       localStorage.removeItem("chat-ref-id");
    //     }
    //     if (res.status !== 204) {

    //       console.log(res, "Result response")

    //       const response = res.data.response;
    //       const queryVisibility = res.data.query_visibility;
    //       //const humanizedTime = res.data.humanized_time;

    //       //console.log(res.data,  "Res data time")
    //       const humanizedTime = res.data.time

    //       //const humanizedTime = res.data.time
    //       dispatch(chatSuccessNew(response, queryVisibility, humanizedTime));
    //     }
    //   })
    //   .catch(err => {
    //     dispatch(chatFailNew());
    //     if (err.response) {
    //       if (err.response.status === 401) {
    //         localStorage.removeItem("chat-username");
    //         localStorage.removeItem("chat-userimage");
    //         //localStorage.removeItem("chat-token");

    //         //window.location.reload();
    //       }
    //     }
    //   });
  };
};

// ======================================================
export const historyInit = () => {
  return {
    type: actions.HISTORYNEW_INIT
  };
};
export const historyFail = () => {
  return {
    type: actions.HISTORYNEW_FAIL
  };
};
export const historySuccess = data => {
  return {
    type: actions.HISTORYNEW_SUCCESS,
    data
  };
};

export const OpenModal = data => {
  return {
    type: actions.OPEN_MODAL_SUCCESS,
    data
  };
};

export const OpenModalComponent = data => {
  return {
    type: actions.OPEN_MODAL_LABLE,
    data
  };
};

export const SuccessLoggedTime = data => {
  return {
    type: actions.GET_TIME_DATA_USER_LOGGED,
    data
  }
}

export const SuccessUpdateRoomData = data => {
  return {
    type: actions.SUCCESS_UPDATE_ROOM_DATA,
    data
  }
}

export const SuccessAvailableCampaign = data => {
  return {
    type: actions.GET_AVAIALBLE_CAMPAIGNS,
    data
  }
}

export const SuccessSetChatOpen = data => {
  return {
    type: actions.SET_CHAT_OPEN,
    data
  }
}

export const cvModalSuccess = data => {
  return {
    type: actions.OPEN_CV_MODAL,
    data
  };
};

export const closeChatModal = data => {
  return {
    type: actions.CLOSE_CHAT_MODAL,
    data
  };
};
export const openChatModal = data => {
  return {
    type: actions.OPEN_CHAT_MODAL,
    data
  };
};

export const onSuccessCV = data => {
  return {
    type: actions.SUCCESS_CV_DATA,
    data
  };
};

export const onVideoPitchStatus = data => {
  return {
    type: actions.ON_SUCCESS_VIDEO_STATUS,
    data
  }
}

export const onSuccessVideoRecord = (data) => {
  return {
    type: actions.SUCCESS_VIDEO_RECORD,
    data
  }
}

export const onSuccessCovertLetter = data => {
  return {
    type: actions.SUCCESS_COVER_LETTER,
    data
  };
};

export const onResetStatus = data => {
  console.log(data, "Reset state data");
  return {
    type: actions.SUCCESS_RESET_STATE,
    data
  };
};

export const onProgressSuccess = data => {
  return {
    type: actions.PROGRESS_UPLOAD,
    data
  };
};

export const onCVLSuccess = data => {
  console.log(data, "Data true status");
  return {
    type: actions.PROGRESS_UPLOAD_CVL,
    data
  };
};

export const onUploadComplete = data => {
  return {
    type: actions.PROGRESS_UPLOAD,
    data
  };
};

export const onProgressSuccessComplete = data => {
  return {
    type: actions.PROGRESS_UPLOAD,
    data
  };
};

export const onInitialStartConversation = data => {
  return {
    type: actions.ON_INITIAL_CONVERSATION,
    data
  }
}

export const onWelcomeBack = data => {
  return {
    type: actions.ON_WELCOME_BACK,
    data
  }
}


export const onChatPopUpSuccess = data => {
  return {
    type: actions.ON_CHAT_POP_UP_OPEN,
    data
  }
}

export const onSuccessIntervewiTimes = data => {
  return {
    type: actions.ON_SUCCESS_INTERVIEW_TIMES,
    data
  }
}

export const onSuccessUnScheduled = data => {
  return {
    type: actions.ON_SUCCESS_UNSCHEDULED_INTERVIEWS,
    data
  }
}

export const onCheckInterviews = (data, token) => {
  return dispatch => {
    // axios.get('get-latest-available-interview/?senderId='+data,{
    //   headers: {
    //     Authorization: `Token ${token}`
    //   }
    // }).then(res=>{
    //     //res.data["Data"]
    //     dispatch(onSuccessIntervewiTimes(res.data["Data"]))
    // })

    //console.log("ok")
  }
}

export const onCheckUnScheduled = (data, token) => {
  return dispatch => {
    axios.get('get-unscheduled-campaigns/?senderId=' + data, {
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res => {
      dispatch(onSuccessUnScheduled(res.data["Data"]))
    })
  }
}

export const ResetInterviews = (data) => {
  return dispatch => {
    dispatch(onSuccessUnScheduled(data))
  }
}

export const resetInitialChatResponse = data => {
  return dispatch => {
    dispatch(onInitialStartConversation(data))
  }
}

export const resetWelcomeChat = data => {
  return dispatch => {
    dispatch(onWelcomeBack(data))
  }
}

export const OpenChatPopUp = data => {
  return dispatch => {
    dispatch(onChatPopUpSuccess(data))
  }
}

export const getChatHistoryNew = () => {
  return dispatch => {
    const token = localStorage.getItem('chat-token')
    dispatch(historyInit());
    axios
      .get(`conversations/`, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(res => {
        console.log(res, "Result from conversation");
        if (res.data.length === 0) {
          //dispatch(getChatResponseNew("/start", false));

          //var data = true
          //dispatch(onInitialStartConversation(data))

          axios.get('get-login-status/?token=' + token, {
            headers: {
              Authorization: `Token ${token}`
            }
          }).then(resLog => {

            if (resLog.data["Data"] === "SIGNUP") {
            //  console.log("start new conversation");
              var data = true
              dispatch(onInitialStartConversation(data))
            }
            else if (resLog.data["Data"] === "ALREADYLOGGED") {
             // console.log("welcome back");
              var data_welcome = true
              dispatch(onWelcomeBack(data_welcome))
              var senderId = localStorage.getItem('CandidateProfile_id02')
              senderId = localStorage.getItem('chat-token')
              dispatch(onCheckInterviews(senderId, token))
              dispatch(onCheckUnScheduled(senderId, token))
              // var data = true
              // dispatch(onInitialStartConversation(data))
            }
          })

        }
        //  else {
        //   console.log("welcome back");
        //   var data_welcome = true
        //   dispatch(onWelcomeBack(data_welcome))
        //   var senderId = sessionStorage.getItem('CandidateProfile_id02')
        //   dispatch(onCheckInterviews(senderId,token))
        //   dispatch(onCheckUnScheduled(senderId, token))
        // }
        dispatch(historySuccess(res.data));
      })
      .catch(err => {
        dispatch(historyFail());

        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("chat-username");
            localStorage.removeItem("chat-userimage");
            //localStorage.removeItem("chat-token");

            //window.location.reload();
          }
        }
      });
  };
};

export const OpenModalChat = data => {
  return dispatch => {
    dispatch(OpenModal(data));
  };
};
export const OpenModalCom = data => {
  return dispatch => {
    dispatch(OpenModalComponent(data));
  };
};

export const OpenCVModal = data => {
  return dispatch => {
    dispatch(cvModalSuccess(data));
  };
};
export const CloseModal = data => {
  return dispatch => {
    dispatch(closeChatModal(data));
  };
};
export const openModalCompoenent = data => {
  return dispatch => {
    dispatch(openChatModal(data));
  };
};

export const onGetVideoQuestion = data => {
  return {
    type: actions.GET_VIDEO_QUESTIONS,
    data
  };
};

export const onSubmitSuccess = data => {
  return {
    type: actions.SUBMIT_VIDEO_SUCCESS,
    data
  };
};

export const onExitVideoSuccess = data => {

  return {
    type: actions.EXIT_VIDEO_PITCH,
    data
  }
}

export const onCampaignFinderSuccess = data => {
  return {
    type: actions.OPEN_CAMPAIGN_FINDER,
    data
  };
};

export const UploadCVChat = data => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .post("cv/", data, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(res => {
        dispatch(onSuccessCV(res.data));
        // var data = true
        // dispatch(onProgressSuccessComplete(data))
      });
  };
};

export const ApplycampaignAction = (appliedData) => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios.post('apply-campaign-by-candidate/', appliedData, {
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res => {
      var currentTime = moment.utc().toISOString()
      dispatch(getCandidateData(token, currentTime))
    })
  }
}

export const saveVideoRecord = (data) => {
  return dispatch => {
    dispatch(onSuccessVideoRecord(data))
  }
}

export const UploadLetter = (data, appliedData, campaignId) => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .post("upload-cover-letter/", data, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(res => {
        axios.get('check-apply-campaign-video/?id=' + campaignId, {
          headers: {
            Authorization: `Token ${token}`
          }
        }).then(resCheck => {
          var result = resCheck.data["Data"]
          console.log(result, "Videoo pitch status campaign apply")
          if (result === false) {
            localStorage.setItem("NO_VIDEO", true)
            axios.post('apply-campaign-by-candidate/', appliedData, {
              headers: {
                Authorization: `Token ${token}`
              }
            }).then(appliedRes => {
              var currentTime = moment.utc().toISOString()
              dispatch(getCandidateData(token, currentTime))
              var status = "COMPLETED"
              axios.get('update-standBy-process/?candidateId=' + appliedData.candidateid + "&campaignId=" + appliedData.campaignId + "&step=" + status, {
                headers: {
                  Authorization: `Token ${token}`
                }
              }).then(resUpdates => {
                dispatch(onSuccessCovertLetter(res.data));
              })
            })
          }
          else if (result === true) {
            dispatch(onVideoPitchStatus(true))
            dispatch(onSuccessCovertLetter(res.data));
          }
        })
      });
  };
};

export const ResetState = data => {
  return dispatch => {
    dispatch(onResetStatus(data));
  };
};

export const CVProgress = data => {
  return dispatch => {
    dispatch(onProgressSuccess(data));
  };
};

export const CVLProgress = data => {
  return dispatch => {
    dispatch(onCVLSuccess(data));
  };
};

export const updateProgress = data => {
  return dispatch => {
    dispatch(onUploadComplete(data));
  };
};

export const getVideoQuestions = data => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .get("get-hr-quesions/?campaignId=" + data, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(res => {
        dispatch(onGetVideoQuestion(res.data.Data));
      });
  };
};

export const SubmitVideo = data => {
  return dispatch => {
    dispatch(onSubmitSuccess(data));
  };
};

export const exitVideoPitch = data => { 
  return dispatch => {
    dispatch(onExitVideoSuccess(data))
  }
}

export const OpenCampaignFinder = data => {
  return dispatch => {
    dispatch(onCampaignFinderSuccess(data));
  };
};

export const onSuccessSupport = (data) => {
  return {
    type: actions.SUCCESS_SEND_SUPPORT,
    data
  }
}

export const submitQASuccess = (data) => {
  return {
    type: actions.SUCCESS_SUBMIT_FQA_SUCCESS,
    data
  }
}

export const successUpdateSavedData = (data) => {
  return {
    type: actions.SUCCESS_UPDATE_CAMPAIGN_APPLY_PROCESS,
    data
  }
}


export const exitSupportsuccess = (data) => {
  return {
    type: actions.SUCCESS_EXIT_SUPPORT,
    data
  }
}

export const exitFAQsuccess = (data) => {
  return {
    type: actions.EXIT_FAQ_SUCCESS,
    data
  }
}

export const onCloseChatSuccess = (data) => {
  return {
    type: actions.CLOSE_CHAT_COMPONENT_RESPONSE,
    data
  }
}

export const onSuccessSaveAppliedData = (data) => {
  return {
    type: actions.SAVE_APPLIED_DATA_TEMP,
    data
  }
}

export const saveApplyData = data => {
  return dispatch => {
    dispatch(onSuccessSaveAppliedData(data))
  }
}

export const CloseChatComponent = data => {
  return dispatch => {
    dispatch(onCloseChatSuccess(data))
  }
}

export const sendSupport = data => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios.post("send-contact-supprt/", data, {
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res => {
      dispatch(onSuccessSupport(res.data))
    })
  }
}

export const resetSupportData = data => {
  return dispatch => {
    dispatch(onSuccessSupport(data))
  }
}

export const exitSupport = data => {
  return dispatch => {
    dispatch(exitSupportsuccess(data))
  }
}

export const cloaseModalFAQ = data => {
  return dispatch => {
    dispatch(exitFAQsuccess(data))
  }
}

export const ResetExitSupport = data => {
  return dispatch => {
    dispatch(exitSupportsuccess(data))
  }
}

export const SubmitFQA = data => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios.post('faq-from-candidate/', data, {
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res => {
      dispatch(submitQASuccess(data))
    })
  }
}

export const updateApplySaveData = (data, status) => {
  const token = localStorage.getItem("chat-token");
  //candidateid
  //campaignId
  return dispatch => {
    axios.get('update-standBy-process/?candidateId=' + data.candidateid + "&campaignId=" + data.campaignId + "&step=" + status, {
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res => {
      
    })

  }
}

export const handleLogin = (data) => {
  const token = localStorage.getItem("chat-token");
  var jsonString = {
    "candidate_id": data
  }
  return dispatch => {
    axios.post('login-status-candidate/', jsonString, {
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res => {
      localStorage.setItem("Logged_id", res.data.id)
    })
  }
}

export const handleLogOut = () => {
  var loggedaId = localStorage.getItem("Logged_id")
  var jsonString = {

  }
  return dispatch => {
    var token = localStorage.getItem("chat-token");

    axios.put('login-status-candidate/' + loggedaId + "/", jsonString, {
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res => {
   
    })
  }
}

export const updateInterviewState = (data, status) => {
  var token = localStorage.getItem("chat-token");
  return dispatch => {
    axios.get('update-interview-status-candidate/?ref=' + data + "&status=" + status, {
      headers: {
        Authorization: `Token ${token}`
      }
    }).then(res => {
  
    })
  }
}

export const SuccessData = data => {
  return {
    type: actions.GET_CANDIDATE_DATA_SUCCESS,
    data
  };
};
export const SuccessDataFilter = data => {
  return {
    type: actions.GET_FILTER_DATA_SUCCESS,
    data
  };
};
export const getCandidateData = (data, currentTime) => {
  return dispatch => {
    axios
      .get(
        "get-candidate-dashboard-data/?id=" +
        data +
        "&logDate=" + currentTime,
        {
          headers: {
            Authorization: `token ${data}`
          }
        }
      )
      .then(res => {
        dispatch(SuccessData(res.data.Data));
      });
  };
};
export const getFilterCampaigns = (id, data, currentTime, candidateId) => {
  var token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .get(
        "campaign-filters/?campaignType=" + id + "&filters=" + data + "&candidateTime=" + currentTime + "&candidateId=" + candidateId,
        {
          headers: {
            Authorization: `token ${token}`
          }
        }
      )
      .then(res => {
        dispatch(SuccessDataFilter(res.data));
      });
  };
};

export const CandidateLoginTime = () => {
  var token = localStorage.getItem("chat-token");
  return dispatch => {
    axios.get('get-logged-time/?token=' + token, {
      headers: {
        Authorization: `token ${token}`
      }
    }).then(res => {
      dispatch(SuccessLoggedTime(res.data["Data"]))
    })
  }
}
export const getAvailableCampaign = (token, time) => {
  var token = localStorage.getItem("chat-token");
  //console.log(token_get, "Token get")

  // return dispatch =>{
  //   axios.get('get-available-campaign-candidate/?token='+token,'&time='+time,{
  //     headers: {
  //       Authorization: `token ${token_get}`
  //     }
  //   }).then(res=>{
  //     dispatch(SuccessAvailableCampaign(res.data["Data"]))
  //   })
  // }

  return dispatch => {
    axios.get('get-available-campaign-candidate/?token=' + token + '&time=' + time, {
      headers: {
        Authorization: `token ${token}`
      }
    }).then(res => {
      dispatch(SuccessAvailableCampaign(res.data["Data"]))
    })
  }
}

export const updateRoomData = (data) => {
  return dispatch => {
    dispatch(SuccessUpdateRoomData(data))
  }
}

export const SetChatOpen = (vrb) => {
  return dispatch => {
    dispatch(SuccessSetChatOpen(vrb))
  }
}

export const SetChatClose = (vrb) => {
  return dispatch => {
    dispatch(SuccessSetChatOpen(vrb))
  }
}

export const ResetSupportConnections = () => {
  var token = localStorage.getItem('chat-token')
  return dispatch => {
    axios.get('support-reset-connections/?token=' + token, {
      headers: {
        Authorization: `token ${token}`
      }
    }).then(res => {
      console.log("Updated")
    })
  }
}

export const ResetConnectionWithGoBack = (connectionId) => {
  var token = localStorage.getItem('chat-token')
  return dispatch => {
    axios.put('support-candidate-start-connection/' + connectionId + '/', {
      headers: {
        Authorization: `token ${token}`
      }
    }).then(res => {
      console.log("Updated")
    })
  }
}

export const UploadLetterAddByStaffDashBoard =(data, appliedData, campaignId, candidateId)=>{
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .post("upload-cover-letter/", data, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(res => {
        axios.get('check-apply-campaign-video/?id=' + campaignId, {
          headers: {
            Authorization: `Token ${token}`
          }
        }).then(resCheck => {
          var result = resCheck.data["Data"]
          console.log(result, "Videoo pitch status campaign apply")
          if (result === false) {
            // dispatch(ShowLoaderData(true))
            localStorage.setItem("NO_VIDEO", true)
            window.location.reload("/")
          }
          else if (result === true) {
            dispatch(onVideoPitchStatus(true))
            dispatch(onSuccessCovertLetter(res.data));
          }
        })
      });
  };
}