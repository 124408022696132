import React, { Component } from "react";
import VideoChatLayout from "../../../components/Layout/VideoChatLayout/VideoChatLayout";
import Classes from "./VideoConference.module.scss";
import VideoChatNew from "../../VideoChat/videoChat";
import Webinnar from "../../../components/Webinnar/webinnar";
import axios from "../../../axios";
import ChatCom from "../../../components/ChatTextNew/ChatTabs/ChatTabs";
import ChatQueue from "../../../components/ChatQueue/ChatQueue";
import { connect } from "react-redux";
import * as actionCreater from "../../../store/actions/index";
import moment from "moment";
import CountdownTimer from "react-component-countdown-timer";
import UserData from "../../../components/UserData/UserData";
import queueIcon from "../../../assets/image/queueIcon.png";
import infoIcon from "../../../assets/image/infoIcon.png";
import chatIcon from "../../../assets/image/chatIcon.png";
import SiaChat from "../../SiaChat/Chat/Chat";
import close from "../../../assets/image/close.png";
import siaWhite from "../../../assets/image/sia-logo-in-white.png";
import Spinner from "../../../components/UI/SpinnerGrey/SpinnerGrey";
import Splash from "../../../components/UI/Splash/Splash";
import { Icon } from "semantic-ui-react";
import Pusher from "pusher-js";

import VideoIntrtview from "../VideoInterview/ChatvideoIntrview";

class VideoConference extends Component {
  // webinarjs
  constructor() {
    super();
    this.state = {
      flag: 0,
      value: 0,
      ref: localStorage.getItem("chat-ref-id"),
      token: localStorage.getItem("chat-token"),
      videoURL: "",
      videoChatStatus: false,
      isModelShow: true,
      notifications: [],
      userId: "",
      textChatCom: true,
      candidatequeue: false,
      basicInfo: false,
      startTime: null,
      countTime: null,
      scheduled_interviewTime: 0,
      state_timeChange: false,
      showSidebar: false, //toggle sidebar in small devices
      lastPressed: null, //toggle sidebar in small devices
      showChat: false,
      loadChat: false,
      newMessage: 2,
      groupChatNotification: false,
      intwChatNotification: false,
      timeUpdated: false,
      inwStatus: 1,
      interviewDataSocket: null,
      appliedId: 0,
      campaignId: 0,
      PanelDuration: 0,
      interviewId: 0,
      interviewTime: null,
      updateTimeData: null
    };
  }

  componentDidMount() {
    var ref = sessionStorage.getItem("ref");
    this.props.onGetCampaignData(null, ref);

    var data = {
      time: moment.utc().format("YYYY-MM-DD H:mm:ss"),
      //time: moment.format("YYYY-MM-DD H:mm:ss"),
      ref: ref,
    };

    console.log(
      data,
      "Show webinar data ######################### abc###################"
    );

    this.props.onShowWebinar(data);

    // setInterval(() => this.props.onGetIntData(ref), 2000);
    // setInterval(() => this.updateCurrentTime(), 2000);
    // setInterval(()=> this.getRecuiterStatus(), 2000);

    this.getInterviewData(ref);
    
    this.getCandidateInterviewData();

    //setInterval(() => this.getInterviewData(ref), 2000);
    this.intrtviewTime = setInterval(
      () => this.updateCurrentTime(this.state.updateTimeData),
      2000
    );

    // this.getNotificationGroupChat()
    //  this.getNotificationInterviewChat()

    //this.getCandidateInterviewData();
  }

  getInterviewData = (ref) => {

    if (this.state.interviewDataSocket === null){
      axios
        .get("get-user-interview-data/?ref=" + ref, {
          headers: {
            Authorization: `token ${localStorage.getItem("chat-token")}`,
          },
        })
        .then((res) => {
          var data = res.data.Data;
          console.log(data, "Get user interview data");
          this.updateCurrentTime(res.data.Data);
          this.setState({ interviewTime: data.InterviewData.startTime, updateTimeData: res.data.Data });

          // this.intrtviewTime = setInterval(
          //   () => this.updateCurrentTime(res.data.Data),
          //   2000
          // );

          this.InitializationPusherGroupChat(res.data.Data);
          this.InitializationPusherInterviewChat(res.data.Data);

          if (data.isVideoScreen == true) {
            this.setState({ basicInfo: true, textChatCom: false });
          }
        });
    }
  };
  componentWillUnmount() {
    clearInterval(this.intrtviewTime);
    this.intrtviewTime = null;
  }
  getCandidateInterviewData = () => {
    console.log("get candidate inteview data socket")
    var ref = sessionStorage.getItem("ref");
    var host = process.env.REACT_APP_WEB_SOCKET_CANDIDATE_INTERVIEW;
    var wsocket = new WebSocket(host);
    wsocket.onopen = function (evt) {
      console.log("Server connected,Interview Data");
    };

    wsocket.onmessage = (evt) => {
      console.log("socket on message")
      if (evt.data != "DoNotMatch") {
        //  console.log(evt,"Qdata")
        var obj = JSON.parse(evt.data);
        var ref = sessionStorage.getItem("ref");
        if (ref == obj.ref) {
          this.setState({ interviewTime: obj.time });
        }
      }
    };

    // wsocket.onmessage = function (evt) {
    //   console.log("socket on message")
    //   if (evt.data != "DoNotMatch") {
    //     //  console.log(evt,"Qdata")
    //     var obj = JSON.parse(evt.data);
    //     var ref = sessionStorage.getItem("ref");
    //     if (ref == obj.ref) {
    //       this.setState({ interviewTime: obj.time });
    //     }
    //   }
    // };

  };
  // getNotificationInterviewChat=()=>{
  //   var host =process.env.REACT_APP_WEB_SOCKET_URL_INTERVIEW;
  //   var wsocket =new WebSocket(host);
  //   wsocket.onopen=function(evt){
  //       console.log("Server connected ,interview");
  //   };
  //   wsocket.onmessage=(evt)=>{
  //       const dataFromServer = evt.data.split("||");
  //       if(dataFromServer[0] == "True")
  //        {
  //          if(dataFromServer[1] !=localStorage.getItem('CandidateProfile_id02')){
  //             this.setIntwChatNotification()
  //             console.log(dataFromServer[2],"chatA")
  //           }
  //        }
  //   };
  // }
  setIntwChatNotification = () => {
    this.setState({
      intwChatNotification: true,
    });
  };
  interviewNotificationClear = () => {
    this.setState({
      intwChatNotification: false,
    });
  };

  InitializationPusherGroupChat = (data) => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    var channelName =
      process.env.REACT_APP_CHANEL_NAME +
      "siaCampaign_" +
      data.InterviewData.campaignId;
    var channel = pusher.subscribe(channelName);
    channel.bind("is-message", (data) => {
      if (
        data.message.senderId !=
        parseInt(localStorage.getItem("CandidateProfile_id02"))
      ) {
        this.setState({
          groupChatNotification: true,
        });
      }
    });
  };

  InitializationPusherInterviewChat = (data) => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    var channelName =
      process.env.REACT_APP_CHANEL_NAME + "siaInterview_" + data.AppliedID;
    // console.log(channelName,"abc123")
    var channel = pusher.subscribe(channelName);
    channel.bind("is-message", (data) => {
      if (
        data.message.senderId !=
        parseInt(localStorage.getItem("CandidateProfile_id02"))
      ) {
        this.setIntwChatNotification();
      }
    });
  };

  groupChatNotificationClear = () => {
    this.setState({
      groupChatNotification: false,
    });
  };
  toggleChat = () => {
    if (this.state.showChat === false) {
      localStorage.setItem("REFRESHSTATUS", true);
    }
    this.setState({
      showChat: !this.state.showChat,
    });
  };
  getRecuiterStatus = () => {
    var ref = sessionStorage.getItem("ref");
    this.props.onGetRecruiterStatus(ref);
  };

  updateCurrentTime = (data) => {

    if (data !== null){

      console.log("update current time ###########")
      this.setState({
        interviewDataSocket: data,
        appliedId: data.AppliedID,
        panelDuration: data.PanelDuration,
        campaignId: data.InterviewData.campaignId,
        interviewId: data.InterviewData.interviewId,
      });
      // var ref = sessionStorage.getItem("ref");
      // this.props.getRecruiterCallStatus(ref)

      try {
        var intwStatus = data.InterviewData.status;
        var currentTime = moment().toISOString(true).split("+")[0];
        var intTime = moment(this.state.interviewTime)
          .toISOString(true)
          .split("+")[0];

        var ms = moment(intTime).diff(moment(currentTime));
        this.setState({ countTime: ms });
        console.log("try count time ####")
        if (parseInt(ms) > parseInt(this.state.scheduled_interviewTime)) {
          console.log("ms####")
          this.setState({
            scheduled_interviewTime: ms,
            state_timeChange: true,
          });
         
        } else {
          this.setState({ state_timeChange: false });
        }

        // console.log(moment(currentTime)._i, "current time with out local");
        // console.log(moment(currentTime)._i, "current time");
        // console.log(moment(intTime)._i, "interivew time");

        var interviewId = data.AppliedID;
        if (moment(intTime)._i < moment(currentTime)._i) {
          localStorage.setItem("CALL_STATUS", true); //temparary
          localStorage.setItem("Flag status", 1);

          this.setState({ flag: 1, inwStatus: intwStatus });

          if (this.state.timeUpdated === false) {
            this.props.onUpdateTimes(ms, interviewId);
            this.setState({ timeUpdated: true });
            localStorage.setItem("TIME_UPDATED", true);
          }
        }
        //this.props.onUpdateTime(ms, true)
      } catch {
        // console.log("pending");
      }
    }
  };
  changeSubComFlag = (id) => {
    // functions to show/hide sidebar in small devices
    if (this.state.lastPressed === id) {
      this.setState({
        showSidebar: !this.state.showSidebar,
      });
    } else {
      this.setState(
        {
          showSidebar: true,
        },
        () => {
          this.setState({ lastPressed: id });
        }
      );
    }
    if (this.state.interviewDataSocket === null) {
    } else {
      if (id === 1) {
        this.setState({
          candidatequeue: true,
          textChatCom: false,
          basicInfo: false,
        });
      } else if (id === 2) {
        this.setState({
          candidatequeue: false,
          textChatCom: false,
          basicInfo: true,
        });
      } else if (id === 3) {
        this.setState({
          candidatequeue: false,
          textChatCom: true,
          basicInfo: false,
        });
      }
    }
  };

  backToStall = () => {
    console.log("back to stall");
  };

  render() {
    localStorage.setItem("Flag status", this.state.flag);
    var appliedId = this.state.appliedId;
    var interviewAppliedId = this.state.appliedId;
    var campaignId = this.state.campaignId;
    var panelDuration = this.state.panelDuration;

    appliedId = this.state.interviewId;
    // campaignId = objectChecker(campaignId, ["0", "campaignId"]);

    localStorage.setItem("AppliedID", interviewAppliedId);
    localStorage.setItem("ChannelID", campaignId);
    localStorage.setItem("ChannelID_interview", interviewAppliedId);

    var campaignData = "";
    if (this.props.campaignData != null) {
      campaignData = this.props.campaignData.interview;
    }

    var interviewStatus = this.state.inwStatus;

    let showcomponent;
    let subComponent;

    console.log(this.state.flag, "State flag ###########")
    console.log(localStorage.getItem("CALL_STATUS"),"Call status########")
    console.log(this.props.is_interview, "Is interview ########")
    console.log(localStorage.getItem("Video_chat_complete"), "Video chat complete ###########")

    if (
      this.state.flag === 0 ||
      localStorage.getItem("CALL_STATUS") === false
    ) {
      if (
        localStorage.getItem("quickInvite") !== null &&
        this.state.interviewDataSocket !== null
      ) {
        showcomponent = (
          <Webinnar
            intTime={this.state.countTime}
            intData={this.state.interviewDataSocket}
            timeChange={this.state.state_timeChange}
          />
        );
      } else if (
        this.props.inviteInteview !== null &&
        this.state.interviewDataSocket !== null &&
        this.props.is_interview !== false
      ) {
        showcomponent = (
          <VideoChatNew
            intData={interviewAppliedId}
            durationPanel={panelDuration}
            intwStatus={interviewStatus}
          />
        );
      } else if (localStorage.getItem("quickInvite") === null) {
        console.log("show webinar ######################")
        // if (this.props.is_interview != true && this.state.flag==0) {
        showcomponent = (
          <Webinnar
            intTime={this.state.countTime}
            intData={this.state.interviewDataSocket}
            timeChange={this.state.state_timeChange}
          />
        );
        // } else {
        //   showcomponent =  <Splash />
        // }
        //showcomponent = <VideoChatNew intData={interviewAppliedId} />;
      }
    } else if (
      this.state.flag === 1 &&
      this.state.interviewDataSocket != null
    ) {
      if (
        localStorage.getItem("Video_chat_complete") === null &&
        this.props.is_interview !== false
      ) {
        localStorage.setItem("CALL_STATUS", true);
        console.log(this.state.interviewDataSocket.isWebinarPnl , "Is Webinar panel ####################")
        console.log(this.state.interviewDataSocket.isVideoScreen, "Is video screen #####################")
        if (this.state.interviewDataSocket.isWebinarPnl == false) {
          if (this.state.interviewDataSocket.isVideoScreen == false) {
            showcomponent = (
              <VideoChatNew
                interviewData={this.state.interviewDataSocket}
                intData={interviewAppliedId}
                intwStatus={interviewStatus}
              />
            );
          } else {
            showcomponent = (
              <VideoIntrtview
                interviewData={this.state.interviewDataSocket}
                intData={interviewAppliedId}
                intwStatus={interviewStatus}
              />
            );
          }
        } else {
          showcomponent = (
            // <Webinnar
            //   intTime={this.state.countTime}
            //   intData={this.state.interviewDataSocket}
            //   timeChange={this.state.state_timeChange}
            // />
            <VideoChatNew
                interviewData={this.state.interviewDataSocket}
                intData={interviewAppliedId}
                intwStatus={interviewStatus}
              />
          );
        }
      } else {
        showcomponent = (
          // <Webinnar
          //   intTime={this.state.countTime}
          //   intData={this.state.interviewDataSocket}
          //   timeChange={this.state.state_timeChange}
          // />
          <VideoChatNew
                interviewData={this.state.interviewDataSocket}
                intData={interviewAppliedId}
                intwStatus={interviewStatus}
              />
        );
      }
    }
    if (
      this.state.candidatequeue === true &&
      this.state.interviewDataSocket !== null
    ) {
      subComponent = (
        <ChatQueue
          intData={this.state.interviewDataSocket}
          appliedId={appliedId}
          campaignId={campaignId}
        />
      );
    } else if (
      this.state.textChatCom === true &&
      this.state.interviewDataSocket !== null
    ) {
      subComponent = (
        <ChatCom
          intData={campaignId}
          appliedId={interviewAppliedId}
          flagStatus={this.state.flag}
          newMessage={this.state.newMessage}
          interviewData={campaignData}
          groupChatNotification={this.state.groupChatNotification}
          intwChatNotification={this.state.intwChatNotification}
          is_interview={this.props.is_interview}
          interviewNotification={this.interviewNotificationClear}
          groupNotification={this.groupChatNotificationClear}
          intwData={this.state.interviewDataSocket}
        />
      );
    } else if (
      this.state.basicInfo === true &&
      this.state.interviewDataSocket !== null
    ) {
      subComponent = (
        <UserData intData={this.props.intwData} campaignId={campaignId} />
      );
    }
    var chatShow = this.state.showChat;

    console.log(this.state.interviewDataSocket, "Interview data socket #333333333333333333")

    console.log(subComponent, "Show component ###################")

    return (
      <VideoChatLayout title="Video Panel">
        {this.state.interviewDataSocket != null ? (
          <>

            <div className={Classes.vcol1}>{showcomponent}</div>

            {/* <button
              // className={classes.StartCallButton}
              onClick={this.backToStall}
              disabled={false}
            >
              Back to stall
            </button> */}

            {/* <div className={Classes.vcol2}>
              {this.state.interviewDataSocket.isVideoScreen == false ?
                <>{this.props.isEvent == false?
                  <>
                  <div className={Classes.buttonWrap} title="Chat">
                    <a onClick={() => this.changeSubComFlag(3)}>
                      <img src={chatIcon} />
                      <Icon
                        name="circle"
                        className={
                          this.state.intwChatNotification ||
                            this.state.groupChatNotification
                            ? Classes.messageArrived
                            : Classes.messageArrivedHide
                        }
                        size="mini"
                      />
                    </a>
                  </div>


                  <div className={Classes.buttonWrap} title="Candidate Queue">
                    <a onClick={() => this.changeSubComFlag(1)}>
                      <img src={queueIcon} />
                    </a>
                  </div></>:null}
                </>
                : null}
              {this.props.isEvent == false?
              <div className={Classes.buttonWrap} title="Campaign Info">
                <a onClick={() => this.changeSubComFlag(2)}>
                  <img src={infoIcon} />
                </a>
              </div>
              :null}
            </div> */}
            {/* <div
              className={`${Classes.vcol3} ${
                this.state.showSidebar ? Classes.showSidebar : ""
              }`}
            > {this.props.isEvent == false?

              subComponent:subComponent
              }
            </div> */}
            {/* <SiaChat show={chatShow} />
            <button onClick={this.toggleChat} className={Classes.siaIconChat}>
              {chatShow ? <img src={close} /> : <img src={siaWhite} />}
            </button> */}
            {/* </div> */}
          </>
        ) : (
          <div style={{ margin: "auto" }}>
            <Splash />
          </div>
        )}
      </VideoChatLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    intwData: state.CandidateData.interviewData,
    inviteInteview: state.auth.interviewInvite,
    timeUpdate: state.CandidateData.updatedTime,
    campaignData: state.CandidateData.campaignData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateTimes: (ms, inwId) =>
      dispatch(actionCreater.updateTimes(ms, inwId)),
    onGetIntData: (data) => dispatch(actionCreater.getInterviewData(data)),
    onShowWebinar: (data) => dispatch(actionCreater.showWebinar(data)),
    //onsaveMiliSec : (data)=> dispatch(actionCreater.saveMilisec(data))
    onGetCampaignData: (data, ref) =>
      dispatch(actionCreater.getCampaignData(data, ref)),
    onGetRecruiterStatus: (ref) =>
      dispatch(actionCreater.GetRecruiterStatus(ref)),
    getRecruiterCallStatus: (ref) =>
      dispatch(actionCreater.getRecruiterCallStatus(ref)),
    onUpdateTime : (count, isTimeChange)=>  dispatch(actionCreater.ChangeTimer(count, isTimeChange)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoConference);
