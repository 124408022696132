import React from "react";
import classes from "./Layout.module.scss";
import Header from "../Header/Header";

function Layout(props) {
  return (
    <div className={classes.Layout}>
      {/* <Header /> */}
      <div className={classes.Container}>
        {/* <h1 className={classes.Title}>{props.title}</h1> */}
        {props.children}
      </div>
    </div>
  );
}

export default Layout;
