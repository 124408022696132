import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import classes from './SendInput.module.scss'

const Notebox = props => {
    const [message, setMessage] = useState('')

    const handleMesageOnchange = e => {
        setMessage(e.target.value)
    }
    const handleSubmitMessage = e => {
        e.preventDefault()
        setMessage('')
        if (message.trim() !== "") {
            props.sendMessage(message)
        }
    }
    return (
        <form onSubmit={handleSubmitMessage} >
            <div className={classes.Notebox}>
                <input type="text" value={message} placeholder="Type your message" onChange={handleMesageOnchange} />
                <button>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </div>
        </form>
    )
}

export default Notebox
