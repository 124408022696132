import React, { Component } from "react";
import ReactTimeAgo from "react-time-ago";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import man from "../../../assets/image/man.svg";

export default class EachActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    JavascriptTimeAgo.addLocale(en);
  }

  render() {
    var username = "";
    var image = "";
    var action = "";
    var comment = "";

    if (this.props.data.message == "applied") {
      username = "You";
      image = this.props.data.userData.picture_url;
      action = this.props.data.content;
    }
    if (this.props.data.message == "shortlisted") {
      username = "You";
      image = this.props.data.userData.picture_url;
      action = this.props.data.content;
    }
    if (this.props.data.message == "scheduled") {
      username = "You";
      image = this.props.data.userData.picture_url;
      action = "Intervew scheduled";
    }
    if (this.props.data.message == "feedback") {
      username = this.props.data.userData.first_name;
      image = this.props.data.userData.profileImage;
      action = "gave a feedback";
      comment = this.props.data.content;
    }
    if (this.props.data.message == "rate") {
    //  username = this.props.data.userData.first_name;
    //  image = this.props.data.userData.profileImage;
     // action = "added a rating";
    }
    if (this.props.data.message == "rejected") {
      username = "You";
      image = this.props.data.userData.picture_url;
      action = this.props.data.content;
    }
    if (this.props.data.message == "selected") {
      username = "You";
      image = this.props.data.userData.picture_url;
      action = this.props.data.content;
    }
    if (this.props.data.message == "rescheduled") {
      username = "You";
      image = this.props.data.userData.picture_url;
      action = this.props.data.content;
    }

    return (
      <div className="eachActivity">
        {this.props.data.message != "rate"?
        <div className="rowForActivity">
          <div className="topline">
            <div className="topLeft">
              <img src={image ? image : man} />
              <div className="userName">{username}</div>
              <div className="theDot"></div>
              <div className="actionName">{action}</div>
            </div>
            <div className="topRight">
              <ReactTimeAgo date={this.props.data.time} timeStyle="twitter" />
            </div>
          </div>
          <div className="bottomline">{comment}</div>
        </div>
       :null}       
      </div>
    );
  }
}
