import axios from '../../axios'
import * as actions from './actionTypes'

export const callHangupInit = () => {
    return {
        type: actions.CALL_HANGUP_INIT
    }
}
export const callHangupFail = () => {
    return {
        type: actions.CALL_HANGUP_FAIL
    }
}
export const callHangupSuccess = (ids) => {
    return {
        type: actions.CALL_HANGUP_SUCCESS,
        ids
    }
}

export const hangupCall = (recipientId, interviewId) => {
    return dispatch => {
        dispatch(callHangupInit())
        dispatch(callHangupFail())
        dispatch(callHangupSuccess())
    }
}