import React from "react";
import classes from "./Campaign.module.scss";
import { Link } from "react-router-dom";
import Animate from "react-smooth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import _ from "lodash";

function Main(props) {
  let classList = [classes.Campaing, props.grid ? classes.Grids : null];
  return (
    <Animate to="1" from="0" attributeName="opacity">
      {props.campaign ? (
        <ul className={classList.join(" ")}>
          {props.campaign.results.map(res => {
            return (
              <li key={res.id}>
                <Link to={`/campaign/${res.id}`}>
                  <span className={classes.BlueShadeBox}>
                    <FontAwesomeIcon icon={faUsers} />
                    <b>123</b>
                    <span>APPLIED</span>
                  </span>
                  <span className={classes.campainShortBox}>
                    <h4>{res.heading}</h4>
                    <p>
                      {_.truncate(res.description, {
                        length: 200,
                        omission: " ..."
                      })}
                    </p>
                    <b>
                      Start Date:{" "}
                      {moment(res.start_time).format("DD-MM-YY hh:mm A")} -
                      Closing Date:{" "}
                      {moment(res.end_time).format("DD-MM-YY hh:mm A")}
                    </b>
                    <span>{res.company.name}</span>
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      ) : null}
    </Animate>
  );
}

export default Main;
