import React, { Component } from "react";
import NearbyData from "./NearbyData/Data";
// import "../FeedBack/FeedbackCarousel/FeedbackCarousel.scss";
import classes from "./NearbyCampaigns.module.scss";

import Carousel, { consts } from "react-elastic-carousel";

export default class NearbyCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAutoPlay:true
    };

  }
  myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? (<i className="fa fa-chevron-left" aria-hidden="true"></i>) : (<i className="fa fa-chevron-right" aria-hidden="true"></i>); 
    return (
      <button className={classes.back_btn} onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  }
  onPrevEnd=()=>{
    this.setState({isAutoPlay:false})
  }

  render() {
    var breakPoints = [
      { width: 1, itemsToShow: 1 ,pagination: false},
      { width: 530, itemsToShow: 2, itemsToScroll: 1, pagination: false },
      { width: 550, itemsToShow: 3, itemsToScroll: 1, pagination: false },
      { width: 850, itemsToShow: 4, pagination: false },
      { width: 1150, itemsToShow: 4, itemsToScroll: 2,pagination: false},
      { width: 1450, itemsToShow: 5,pagination: false },
      { width: 1750, itemsToShow: 6,pagination: false },
    ];
    return (
      <Carousel
        renderArrow={this.myArrow}
        enableAutoPlay={this.state.isAutoPlay}
        autoPlaySpeed={1500}
        breakPoints={breakPoints}
        focusOnSelect={true}
        onPrevEnd={this.onPrevEnd}
        
      >
        {this.props.compaign.map((res, index) => {        
          return (
            <div key={index}>     
              <NearbyData key={index} index={index} data={res} compaigns={this.props.compaign} />   
            </div>
          );
        })}
      </Carousel>     
    );
  }
}
