import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { detect } from "detect-browser";
import App from "./App";
import Unsupport from "./components/UI/Unsupport";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import Auth from "./store/reducers/Auth";
import Profile from "./store/reducers/Profile";
import Chat from "./store/reducers/Chat";
import ChatNew from "./store/reducers/ChatNew";
import CampaignByRef from "./store/reducers/GetCampaignNew";
import CandidateData from "./store/reducers/GetCandidateData";
import { BrowserView, MobileView,isBrowser,isMobile,isEdge,isFirefox,isSafari,isOpera,isIE} from "react-device-detect";
import UnsupportMobile from "./components/UI/Unsupport/mobile";
import { faTruckMonster } from "@fortawesome/free-solid-svg-icons";
import Middleware from '../src/Middleware';

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const rootReducer = combineReducers({
  auth: Auth,
  profile: Profile,
  chat: Chat,
  chatnew: ChatNew,
  CampaignByRef: CampaignByRef,
  CandidateData: CandidateData,
});
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
const browser = detect();

// handle the case where we don't detect the browser
let app = null;

 app = <Middleware />;

//  app = <App />;

// if(browser.name =='edge'){
//   app = <Unsupport />;
// }

// if(isMobile==true ){
//   app = <UnsupportMobile />;
// }
if(isEdge==true || isFirefox==true  || isOpera==true || isIE==true){
  app = <Unsupport />;
}
// switch (browser && browser.name) {
//   case "chrome":
//     app = <App />;
//     break;
//   default:
//     app = <Unsupport />;
// }

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>{app}</BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
