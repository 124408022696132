import React, { Component } from "react";
import classes from "./Login.module.scss";
import E1 from "../../assets/image/imagetestatend.jpg";
import E2 from "../../assets/image/imagetestatend1.jpg";
import { connect } from "react-redux";
import Logo from "../../assets/image/ufairs.svg";
import zIndex from "material-ui/styles/zIndex";

export class About extends Component {

     constructor(props) {
          super(props);
          this.state = {
               organizer: true,
               partners: false,
               company: false,
               step: 0
          }
     }
     next = () => {
          if (this.state.step != 2) {
               this.setState({ step: this.state.step + 1 })
          }

     }
     prev = () => {
          if (this.state.step != 0) {
               this.setState({ step: this.state.step - 1 })
          }
     }
     render() {

          var evtImage = ""
          var orgImge = ""
          if (this.props.eventData.aboutImages != null) {
               orgImge = this.props.eventData.aboutImages.find(x => x.logoType == "ORGANIZER")
               evtImage = this.props.eventData.aboutImages.find(x => x.logoType == "EVENT")
               // var evImage=evtImage[evtImage.length-1]
               //  var oImg = orgImge[orgImge.length-1]
          }

          console.log(this.props.eventData.aboutUsImages, "About images ######")

          return (
               <div className={classes.outerFlex}>
                    <div>
                         {this.props.eventData.aboutImages ?
                              <button onClick={this.prev} className={classes.back_btn1} > <i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                              : null
                         }
                    </div>

                    <div>
                         {/* EVENT and ORGANIZER */}
                         {this.state.step == 0 ?
                              <>
                                   <div className={classes.aboutTopd}>
                                        <h3 style={{ marginTop:'0 !important' }} ><span className={classes.orange}>EVENT &amp; ORGANIZER</span></h3>
                                   </div>

                                   <div className={classes.aboutEvent}>
                                        <div className={classes.aboutE1}>
                                             <div className={classes.imgE1}>
                                                  {evtImage != undefined ?
                                                       <img src={evtImage.logo} />
                                                       : <img src={Logo} />
                                                  }
                                             </div>
                                             <div className={classes.disE1}>
                                                  {this.props.eventData.aboutImages != null ? this.props.eventData.aboutUs[0].description : ""}
                                             </div>
                                        </div>
                                        <div className={classes.aboutE1}>
                                             <div className={classes.imgE1}>
                                                  {orgImge != undefined ?
                                                       <img src={orgImge.logo} />
                                                       : <img src={Logo} />
                                                  }
                                             </div>
                                             <div className={classes.disE1}>
                                                  {this.props.eventData.aboutImages != null ? this.props.eventData.aboutUs[0].titleCompanies : ""}
                                             </div>
                                        </div>
                                   </div>
                              </>
                              : null
                         }


                         {/* PARTNERS */}
                         {this.state.step == 1 ?
                              <div>
                                   <div className={classes.aboutTopd}>
                                        <h3 style={{ marginTop:'0 !important' }} ><span className={classes.orange}>PARTNERS</span></h3>
                                   </div>
                                   <div className={classes.aboutsecondpage}>
                                        {/* {this.props.eventData.aboutImages.map((res, index) => {
                                             return (
                                                  res.logoType == "PARTNER" ?
                                                       <div key={index} className={classes.imgSecondpage}>
                                                            <img src={res.logo} />
                                                       </div>
                                                       : null
                                             )
                                        })
                                        }
                                        <div className={classes.instructionWrapper}>
                                             <div className={classes.instructions}>
                                                  <div className={classes.heading}>
                                                       <span>Partners will be updated soon!</span>
                                                  </div>
                                                  <div className={classes.descr}>
                                                       Wanna know about our Agenda?
                                                  </div>
                                                  <a href="#">Yes</a>
                                             </div>
                                        </div> */}
                                        {
                                             this.props.eventData.aboutImages.length > 0 ?
                                             <div className={classes.aboutsecondpage}>
                                                  {
                                                       this.props.eventData.aboutImages.map((res, index) => {
                                                            return (
                                                                 res.logoType == "PARTNER" ?
                                                                      <div key={index} className={classes.imgSecondpage}>
                                                                           <img src={res.logo} />
                                                                      </div>
                                                                      : null 
                                                            )
                                                       })
                                                  }
                                             </div>:
                                             <div className={classes.instructionWrapper}>
                                                  <div className={classes.instructions}>
                                                  <div className={classes.heading}>
                                                       <span>Partners will be updated soon!</span>
                                                  </div>
                                                  {/* <div className={classes.descr}>
                                                  Wanna know about our Agenda?
                                                  </div>
                                                  <a href="#">Yes</a> */}
                                                  </div>
                                             </div>
                                             }
                                   </div>
                              </div>
                              : null
                         }

                         {/* COMPANIES */}
                         {this.state.step == 2 ?
                              <div>
                                   <div className={classes.aboutTopd}>
                                        <h3 style={{ marginTop:'0 !important' }} ><span className={classes.orange}>COMPANIES</span></h3>
                                   </div>
                                   <div className={classes.aboutsecondpage}>
                                        {/* <div className={classes.aboutsecondpage}>
                                             {this.props.eventData.aboutImages.map((res, index) => {
                                                  return (
                                                       res.logoType == "COMPANY" ?
                                                            <div key={index} className={classes.imgSecondpage}>
                                                                 <img src={res.logo} />
                                                            </div>
                                                            : null
                                                  )
                                             })
                                             }
                                             <div className={classes.instructionWrapper}>
                                                  <div className={classes.instructions}>
                                                       <div className={classes.heading}>
                                                            <span>Companies will be updated soon!</span>
                                                       </div>
                                                       <div className={classes.descr}>
                                                            Wanna know about our Agenda?
                                                       </div>
                                                       <a href="#">Yes</a>
                                                  </div>
                                             </div>
                                        </div> */}
                                        {
                                            this.props.eventData.aboutImages.length > 0 ?
                                            <div className={classes.aboutsecondpage}>
                                                 {
                                                      this.props.eventData.aboutImages.map((res, index) => {
                                                         return (
                                                              res.logoType == "COMPANY" ?
                                                                   <div key={index} className={classes.imgSecondpage}>
                                                                        <img src={res.logo} />
                                                                   </div>
                                                                   : null 
                                                         )
                                                    })
                                                 }
                                            </div>:
                                             <div className={classes.instructionWrapper}>
                                                  <div className={classes.instructions}>
                                                  <div className={classes.heading}>
                                                       <span>Companies will be updated soon!</span>
                                                  </div>
                                                  </div>
                                             </div>
                                        }
                                   </div>
                              </div>
                              : null
                         }
                    </div>
                    <div>
                         {this.props.eventData.aboutImages != null ?
                              <button onClick={this.next} className={classes.back_btn1}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                              : null
                         }
                    </div>
               </div>
          );
     }
}

const mapStateToProps = (state) => {
     return {
          eventData: state.auth.eventData,
     };
};
const mapDispatchToProps = (dispatch) => {
     return {

     };
};
export default connect(mapStateToProps, mapDispatchToProps)(About);

