import * as actions from '../actions/actionTypes'
import { validationUrl } from '../actions'
import isPayload from "../../hoc/isPayload";

const initialState ={
    loading: false,
    userData : null,
    interviewData : null,
    twillioToken : null,
    candidateQueue : null,
    campaignData : null,
    updatedRefId : null,
    updatedTime : null,
    originaCVStatus: false,
    videoPitch:null,
    coverLetter:null,
    validationUrl:null,
    onDisabled: false,
    responseMessages: [],
    startNotification: false,
    feedbackData:null,
    callStatus:null,
    shareGroup:null,
    changeTime: false,
    countTime: 0,
    userDataStall: null
}
const prototypeResponceMessage = (
    message,
    type,
    time,
    visibility,
    buttonStatus,
    videoStatus
  ) => {
    return {
      message,
      type,
      time,
      visibility,
      buttonStatus,
      videoStatus
    };
  };

const reducer = (state = initialState, action) =>{
    switch (action.type){
        case actions.GET_CANDIDATE_DATA_SUCCESS:
            console.log(action.data, "action")
            return {
                ...state,
                loading : false,
                userData : action.data
            }
        case actions.GET_INTERVIEW_DATA:
            return {
                ...state,
                loading : false,
                interviewData : action.data
            }
        case actions.GET_TWILLIO_TOKEN:
            return {
                ...state,
                loading : false,
                twillioToken : action.data
            }
        case actions.GET_CANDIDATE_QUEUE:
            return{
                ...state,
                loading : false,
                candidateQueue : action.data
            }
        case actions.GET_CAMPAIGN_DATA:
            return{
                ...state,
                loading : false,
                campaignData : action.data
            }
        case actions.SUCCESS_UPDATE_REF_INVITE:
            return{
                ...state,
                loading : false,
                updatedRefId : action.data
            }
        case actions.TIME_UPDATED_SUCCESS:
            return{
                ...state,
                loading: false,
                updatedTime : action.data
            }
        case actions.GET_CV_ORIGINAL_STATUS:
            return{
                ...state,
                originaCVStatus : action.data
            }
        case actions.VIDEO_PITCH_SUCCESS:
            return{
              ...state,
              videoPitch : action.data
            }  
        case actions.COVER_PITCH_SUCCESS:
            return{
              ...state,
              coverLetter : action.data
            } 
        case actions.SUCCESS_CHANGE_TIME:
          return{
            changeTime: action.data
          }
        case actions.SUCCESS_CHANGE_COUNT:
          console.log(action.data,"success count timereducer")
          return{
            countTime: action.data
          }
        case actions.GET_CANDIDATE_DATA_SUCCESS_STALL:
          return{
            ...state,
            loading : false,
            userDataStall: action.data
          }
        case actions.VALIDATION_URL_SUCCESS:
             return{
                ...state,
                validationUrl : action.data
            } 
            case actions.CHATNEW_INIT:
            return {
              ...state,
              loading: true,
              onDisabled: true,
              responseMessages: state.responseMessages.concat(
                prototypeResponceMessage(
                  isPayload(action.query),
                  "query",
                  action.humanizedTime,
                  action.queryVisibility,
                  false
                )
              )
            };
          case actions.CHATNEW_FAIL:
            return {
              ...state,
              loading: false,
              onDisabled: true
            };
            case actions.CHATNEW_SUCCESS:
            const responseMessages = [...state.responseMessages];
            const lastMessage = { ...responseMessages[responseMessages.length - 2] };
            lastMessage.buttonStatus = false;
            responseMessages[responseMessages.length - 2] = lastMessage;
            // const time = moment(new Date()).format("hh:mm a");
            return {
              ...state,
              loading: false,
              onDisabled: false,
              startNotification: true,
              responseMessages: responseMessages.concat(
                prototypeResponceMessage(
                  action.response,
                  "response",
                  "Just now",
                  true,
                  true,
                  true
                )
              )
            };
            case actions.SUCCESS_CANDIDATE_FEEDBACK_SUCCESS:          
            return {
              ...state,       
              feedbackData: action.data,            
            };
            case actions.SUCCESS_RECRUITER_CALL_STATUS:          
            return {
              ...state,       
              callStatus : action.data,            
            };
            case actions.GET_SHARE_GROUP_SUCCESS:          
            return {
              ...state,       
              shareGroup : action.data,            
            };            
        default:
            return state
    }
}
export default reducer