import React, { useEffect, useState } from "react";
import classes from "./HrQuetions.module.scss";

import Quetion from "./Quetion/QuetionVideoIntrview";

function HrQuetions(props) {
  return (
    <div className={classes.videoAndButton}>
      {props.children}
      {props.recodeinStart && !props.paused ? (
        <div className={classes.recLabel}>
          <span className={classes.red} />
          <div className={classes.text}>Rec</div>
        </div>
      ) : (
        ""
      )}
      {props.granted && (
        <Quetion
          handleFinish={props.handleFinish}
          startRecode={props.startRecode}
          recodeinStart={props.recodeinStart}
          handlePause={props.handlePause}
          handlePlay={props.handlePlay}
          paused={props.paused}
          interviewData={props.interviewData}
        />
      )}
    </div>
  );
}

export default HrQuetions;
