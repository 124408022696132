import React, { Component } from "react";
import classes from "./PreferencesForSettings.module.scss";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import PreferenceListItem from "./PreferenceListItem/PreferenceListItem";
import { connect } from "react-redux";
import Profiling from "./Profiling";
import * as actionsCreator from "../../../store/actions/index";
import { Dropdown } from "semantic-ui-react";

class CareerPreferences extends Component {
  constructor(prop) {
    super();
    this.state = {
      items: [],
      preference: [],
      value: 4,
      inputValue: "",
      eachItem: "",
      skill: [],
      submitted: false,
      options: [],
    };
    this.handleOnchange = this.handleOnchange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentDidMount() {
    this.preferences();
    this.getPreferences();
  }

  getPreferences = () => {
    var suggesstions = [];
    this.props.systemData.Data.suggesstions.map((res, index) => {
      var obj = {
        text: res.suggestions,
        value: res.suggestions,
      };
      suggesstions.push(obj);
    });
    this.setState({ options: suggesstions });
  };

  preferences = () => {

    var preferences = [];
    
    console.log(this.props.profile, "Profile data #######")

    if (this.props.profile != null){
      if (this.props.profile.userData.profile[0].career_preferences != "") {
        preferences = this.props.profile.userData.profile[0].career_preferences.split(
          ","
        );
      }
    }

    // if (this.props.profile.userData.profile[0].career_preferences != "") {
    //   preferences = this.props.profile.userData.profile[0].career_preferences.split(
    //     ","
    //   );
    // }
    
    var data = [];
    preferences.map((res, index) => {
      data.push({ id: index, title: res });
      this.props.preferences.push(res);
    });
    this.setState({
      items: data,
      value: preferences.length,
    });
  };

  handleRLDDChange = (reorderedItems) => {
    this.setState({ items: reorderedItems }, () => {
      var newList = "";
      newList = this.state.items.map((res, index) => {
        return res.title;
      });
      this.props.setPreferences(newList.join());
    });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const { onAccept } = this.props;
      onAccept && onAccept(e.target.value);
    }
  };

  handleOnSubmit = (e, { value }) => {
    e.preventDefault();
    this.props.setChanged(true);
    if (value.length > 0) {
      var item = { id: this.state.value, title: value };
      this.setState(
        {
          items: [...this.state.items, item],
          inputValue: "",
          value: this.state.value + 1,
        },
        () => {
          this.props.preferences.push(item.title);
        }
      );
    }
  };
  handleAddition = (e, { value }) => {
    this.state.options.push({ text: value, value: value });
  };
  handleOnchange = (e, { value }) => {
    e.preventDefault();
    // var pref = [...this.state.preference, ...[event.target.value]];
    this.setState({
      inputValue: value,
    });
  };

  deletePreference = (val) => {
    var location = this.state.items.findIndex((x) => x.id === val);
    this.state.items.splice(location, 1);
    var newList = this.state.items.map((res, index) => {
      return res.title;
    });
    this.props.setChanged(true);
    this.props.setPreferences(newList.join());
  };
  render() { 
    return (
      <div className={classes.careeerPreferWrapper}>
        <div className={classes.DraggableList}>
          <RLDD
            items={this.state.items}
            itemRenderer={(item) => (
              <PreferenceListItem
                title={item.title}
                deletePreference={(val) => this.deletePreference(val)}
                numb={item.id}
              />
            )}
            onChange={this.handleRLDDChange}
          />
        </div>
        {/* <form onSubmit={this.handleOnSubmit}> */}

        <Dropdown
          options={this.state.options}
          placeholder={
            this.state.items.length < 5
              ? "Type in your preferred career choices"
              : "Delete items from the list to add more"
          }
          search
          selection
          fluid
          // multiple
          disabled={this.state.items.length > 4}
          allowAdditions
          value={this.state.inputValue}
          onAddItem={this.handleAddition}
          onChange={this.handleOnSubmit}
          className="careerPreferences_dropdown"
          selectOnNavigation={false}
          selectOnBlur={false}
        />

        {/* </form> */}
        <Profiling />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.CandidateData.userData,
    systemData: state.auth.systemData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setChanged: (data) => dispatch(actionsCreator.setSomethingChanged(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CareerPreferences);
