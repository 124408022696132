import React, { Component } from "react";
import { callHangupSuccess } from "../../../../store/actions/Call";
import classes from "../FilteredDataOnboard/FilteredDataOnboard.module.scss";
import CVTags from "../CVTagsOnboard";
// import "./FilteredData.scss";
import { convertColorToString } from "material-ui/utils/colorManipulator";
import { json } from "body-parser";
import { connect } from "react-redux";
import * as actionsCreator from "../../../../store/actions/index";
import Document from "../../../../assets/image/paper.svg";
import Education from "../../cvFilteredData/EducationInfo/EduEachData";
import Experience from "../../cvFilteredData/ExperienceInfo/ExperienceInfo";
import SimpleReactValidator from "simple-react-validator";
import SweetAlert from "sweetalert2-react";
import swal from "sweetalert2";
import { Dropdown, Modal } from "semantic-ui-react";

class FilterDataOnboard extends Component {
  constructor(props) {
    super();
    this.state = {
      isPDFModalOpen: false,
      inputEducation: false,
      inputExperience: false,
      insName: "",
      insType: "",
      inssubject: "",
      insGrade: "",
      exCompany: "",
      exPosition: "",
      exDuration: "",
      educationList: [],
      experienceList: [],
      arrayDataEdu: "",
      arrayDataExp: "",
      alert: false,
      text: "",
      uploadingstatus: false,
      cvURl: "",
      optionsInstitute: [],
      optionsGrade: [],
      optionsCrouse: [],
      optionsCompany: [],
      optionsDurations: [],
      optionsTitle: [],
      is_functionCall : false,
      isEditEducation:false,
      eduIndex:0,
      isEditExperience:false,
      expIndex:0
    };
    this.handleNamechange = this.handleNamechange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleGrade = this.handleGrade.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handlePOsition = this.handlePOsition.bind(this);
    this.handleDuration = this.handleDuration.bind(this);
    this.btnSaveProceed = this.btnSaveProceed.bind(this);
    this.handleEduBtn = this.handleEduBtn.bind(this);
    this.handleExpBtn = this.handleExpBtn.bind(this);
    this.eduValidator = new SimpleReactValidator();
    this.xpValidator = new SimpleReactValidator();
    this.handleCloseEdu = this.handleCloseEdu.bind(this);
    this.handleCloseExp = this.handleCloseExp.bind(this);
  }

  btnSaveProceed = () => {
    this.props.onskip();
  };

  handleEduBtn = () => {
    this.setState({
      inputEducation: !this.state.inputEducation,
      isEditEducation:false
    });
  };
  handleExpBtn = () => {
    this.setState({
      inputExperience: !this.state.inputExperience,
      isEditExperience:false
    });
  };

  handleCloseExp = () => {
    this.setState({
      inputExperience: false,
    });
    this.forceUpdate();
  };
  handleCloseEdu = () => {
    this.setState({
      inputEducation: false,
    });
    this.forceUpdate();
  };

  enterEducationDetails = () => {
    if (this.eduValidator.allValid()) {
      this.editEducationDetails()
      var newData = {
        institution: this.state.insName,
        type_ins: this.state.insType,
        stream: this.state.inssubject,
        grade: this.state.insGrade,
      };
      this.setState(
        {
          educationList: [...this.state.educationList, newData],
        },
        () => {
          this.props.handleManuallyFilled();
          var arrayData = (
            <Education
              deleteItemEduc={this.deleteEducation}
              data={this.state.educationList}
              editEducation={this.editEducation}
            />
          );
          this.setState({
            arrayDataEdu: arrayData,
          });
        }
      );
      // this.state.educationList.push(newData);
      this.setState({ insName: "", insType: "", inssubject: "", insGrade: "",isEditEducation:false });
      this.eduValidator.hideMessages();

      var edulist = [...this.props.eduList, ...[newData]];

      console.log(this.state.edulist, "Edulist");
      // var arrayData = <Education data={this.state.educationList} />;
      // this.setState({
      //   arrayDataEdu: arrayData,
      // });
      this.props.onSendEdu(edulist);
      this.setState({
        inputEducation: !this.state.inputEducation,
      });
    } else {
      this.eduValidator.showMessages();
      this.forceUpdate();
    }
  };

  editEducationDetails=()=>{
    if(this.state.isEditEducation==true){
        this.deleteEducation(this.state.eduIndex)
    }
  }
  editExperienceDetails=()=>{
   if(this.state.isEditExperience==true){
     this.deleteExperience(this.state.expIndex)
   }
  }

  enterExperienceDetails = () => {
    if (this.xpValidator.allValid()) {
      this.editExperienceDetails()
      var newData = {
        company: this.state.exCompany,
        position: this.state.exPosition,
        duration: this.state.exDuration,
      };

      this.setState(
        {
          experienceList: [...this.state.experienceList, ...[newData]],
        },
        () => {
          this.props.handleManuallyFilled();
          var expData = (
            <Experience
              deleteExpe={this.deleteExperience}
              data={this.state.experienceList}
              editExperience={this.editExperience}
            />
          );
          this.setState({
            arrayDataExp: expData,
          });
        }
      );

      this.state.experienceList.push(newData);
      this.setState({ exCompany: "", exPosition: "", exDuration: "",isEditExperience:false });
      this.xpValidator.hideMessages();

      // var explist = [...this.props.expList, ...[newData]]

      // var expData = <Experience data={this.state.experienceList} />;
      // this.setState({
      //   arrayDataExp: expData,
      // });
      console.log(this.state.experienceList, "experience list");
      this.props.onSendExp(this.state.experienceList);
      this.setState({
        inputExperience: !this.state.inputExperience,
      });
    } else {
      this.xpValidator.showMessages();
      this.forceUpdate();
    }
  };

  handleNamechange = (e, { value }) => {
    this.setState({
      insName: value,
    });
  };

  handleNameAddition = (e, { value }) => {
    this.state.optionsInstitute.push({ text: value, value: value });
  };

  handleTypeChange = (e) => {
    this.setState({
      insType: e.target.value,
    });
  };

  handleSubjectChange = (e, { value }) => {
    this.setState({
      inssubject: value,
    });
  };

  handleSubjectAddition = (e, { value }) => {
    this.state.optionsCrouse.push({ text: value, value: value });
  };
  handleGrade = (e, { value }) => {
    this.setState({
      insGrade: value,
    });
  };
  handleGradeAddition = (e, { value }) => {
    this.state.optionsGrade.push({ text: value, value: value });
  };

  handleCompany = (e, { value }) => {
    this.setState({
      exCompany: value,
    });
  };

  handleCompanyAddition = (e, { value }) => {
    this.state.optionsCompany.push({ text: value, value: value });
  };

  handlePOsition = (e, { value }) => {
    this.setState({
      exPosition: value,
    });
  };

  handlePositionAddition = (e, { value }) => {
    this.state.optionsTitle.push({ text: value, value: value });
  };

  handleDuration = (e, { value }) => {
    this.setState({
      exDuration: value,
    });
  };

  handleDurationAddition = (e, { value }) => {
    this.state.optionsDurations.push({ text: value, value: value });
  };

  submitCVData = () => {
    this.props.onGetChatResponseNew("/Submit_cv_data", false);
    localStorage.setItem("ON_CLOSE_MODAL", true);
  };

  handleFileChange = (e) => {
    console.log(e.target.files[0].type, "zxc");
    var _validFileExtensions = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    var imgObj = _validFileExtensions.filter(
      (obj) => obj == e.target.files[0].type
    );
    if (imgObj.length > 0) {
      var fileSize = e.target.files[0].size / 1024 / 1024;

      console.log(fileSize, "File size mb");

      if (parseInt(fileSize) <= 5) {
        var file = e.target.files[0];
        var extensions = e.target.files[0].name.split(".").pop();
        localStorage.setItem("CVName", file.name);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var preview = reader.result;
          localStorage.setItem("candidateCV", preview);
          this.uploadcvFile(preview, extensions);
        };
        localStorage.setItem("UPLOADING_STATUS", true);
        var dataProgress = true;
        this.props.onCVProgress(dataProgress);
        this.setState({
          uploadingstatus: true,
        });
      } else {
        swal.fire({
          position: "center",
          // icon: "warning",
          imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
          title: "Warning!",
          text: "Maximum file size is 5MB",
          showConfirmButton: false,
          timer: 3500,
        });
      }
    } else {
      this.setState({
        isValidFile: "file format is not valid (pdf/doc/docx allowed)",
      });
      swal.fire({
        position: "center",
        // icon: "warning",
        imageUrl: "https://image.flaticon.com/icons/svg/1636/1636066.svg",
        title: "Warning!",
        text: "Only PDF/DOC/DOCX formats allowed",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  uploadcvFile = (fileString, extensions) => {
    var candidateID = this.props.candidateDate.userData.profile[0].user_id;
    var data = {
      uploaded_at: new Date().toISOString(),
      upload: fileString,
      user_id: candidateID,
      cv_type: 1,
      format_type: extensions,
    };
    this.props.onUploadCV(data);
  };

  componentDidMount = () => {
    console.log(this.props.cvSkilldata, "Get cv skills data");
    if (this.props.candidateDate !== null) {
      try {
        console.log(
          this.props.candidateDate.userData.cv[0].upload,
          "CV URL did mount"
        );
      } catch {
        console.log("CV not uploaded yet");
      }

      this.getExistingData_exp();
      this.getExistingData_edu();
    }

    // if (this.props.cvSkilldata !== null) {
    //   this.getCvFilterdData();
    // }

    this.getInstitutions();
    this.getGrades();
    this.getCrouse();
    this.getDuration();
    this.getPositions();
    this.getCompany();
  };

  componentWillReceiveProps(props){
   console.log(props.cvSkilldata, "data")
   if (props.cvSkilldata !== null) {
     console.log(this.state.is_functionCall, "Is function call")
     if (this.state.is_functionCall === false){
        console.log("Get cv filtered data")
        this.getCvFilterdData();
     }      
   }
  }

  deleteEducation = (i) => {
    this.state.educationList.splice(i, 1);
    this.setState({ educationList: [...this.state.educationList] }, () => {
      var arrayData = (
        <Education
          deleteItemEduc={this.deleteEducation}
          data={this.state.educationList}
          editEducation={this.editEducation}
        />
      );
      this.setState({
        arrayDataEdu: arrayData,
      });
      this.props.onSendEdu(this.state.educationList);
    });
  };

  editEducation=(i)=>{
    this.setState({
      inputEducation: true,
    });
    var val = this.state.educationList[i]
    console.log(val)
    this.setState({
      insName: val.institution,
      insType: val.type_ins,
      inssubject: val.stream,
      insGrade: val.grade,
      isEditEducation:true,
      eduIndex:i
    })
  }
  editExperience=(i)=>{
    this.setState({inputExperience:true});
    var value = this.state.experienceList[i]
    console.log(value)

    this.setState({
      exCompany:value.company,
      exPosition:value.position,
      exDuration:value.duration,
      isEditExperience:true,
      expIndex:i
    })
  }

  deleteExperience = (i) => {
    this.state.experienceList.splice(i, 1);
    this.setState(
      {
        experienceList: [...this.state.experienceList],
      },
      () => {
        var expData = (
          <Experience
            deleteExpe={this.deleteExperience}
            data={this.state.experienceList}
            editExperience={this.editExperience}
          />
        );
        this.setState({
          arrayDataExp: expData,
        });
        this.props.onSendExp(this.state.experienceList);
      }
    );
  };

  getCvFilterdData = () => {
    var education = [];
    if (this.props.cvSkilldata !== null) {
      education = this.props.cvSkilldata.Data.education;
      console.log(education,"cvdata")

      education.map((res, index) => {
        this.setState(
          {
            // educationList: [...this.state.educationList,...[res]],
            educationList: [...this.state.educationList, ...[res]],
          },
          () => {
            var arrayData = (
              <Education
                deleteItemEduc={this.deleteEducation}
                data={this.state.educationList}
                editEducation={this.editEducation}
              />
            );
            this.setState({
              arrayDataEdu: arrayData,
            });
          }
        );
        this.state.educationList.push(res)
       // var edulist = [...this.props.eduList, ...[res]];
     //   var edulist = [...this.props.eduList, ...[res]];
        this.props.onSendEdu(this.state.educationList);
      });
    }

    var experience = [];
    if (this.props.cvSkilldata !== null) {
      experience = this.props.cvSkilldata.Data.experience;
      experience.map((res, index) => {
        this.setState(
          {
            experienceList: [...this.state.experienceList, ...[res]],
          },
          () => {
            var expData = (
              <Experience
                deleteExpe={this.deleteExperience}
                data={this.state.experienceList}
                editExperience={this.editExperience}
              />
            );
            this.setState({
              arrayDataExp: expData,
            });
          }
        );
        this.state.experienceList.push(res);
        this.props.onSendExp(this.state.experienceList);
        this.setState({
          is_functionCall : true
        })
      });
    }
  };

  updateSkillsFlag = () => {
    this.props.handleManuallyFilled();
  };

  getExistingData_exp = () => {
    console.log("get existing experience data");
    var cv_data = "";
    var exp = "";
    var edu = "";

    try {
      cv_data = this.props.candidateDate.userData.cv[0].upload;
    } catch {
      cv_data = "";
    }

    var expData = (
      <Experience data={this.props.candidateDate.userData.experience} />
    );
    this.setState({
      arrayDataExp: expData,
    });
    this.setState({
      cvURl: cv_data,
    });
  };
  getExistingData_edu = () => {
    console.log("get existinf education data");
    var cv_data = "";
    var exp = "";
    var edu = "";
    try {
      cv_data = this.props.candidateDate.userData.cv[0].upload;
    } catch {
      cv_data = "";
    }
    var arrayData = (
      <Education data={this.props.candidateDate.userData.education} />
    );
    this.setState({
      arrayDataEdu: arrayData,
    });
    this.setState({
      cvURl: cv_data,
    });
  };

  getInstitutions = () => {
    var institutions = [];
    this.props.systemData.Data.institutions.map((res, index) => {
      var obj = {
        text: res.instutution,
        value: res.instutution,
      };
      institutions.push(obj);
    });
    this.setState({ optionsInstitute: institutions });
  };

  getGrades = () => {
    var grades = [];
    this.props.systemData.Data.grades.map((res, index) => {
      var obj = {
        text: res.grades,
        value: res.grades,
      };
      grades.push(obj);
    });
    this.setState({ optionsGrade: grades });
  };

  getCrouse = () => {
    var courses = [];
    this.props.systemData.Data.courses.map((res, index) => {
      var obj = {
        text: res.courseName,
        value: res.courseName,
      };
      courses.push(obj);
    });
    this.setState({ optionsCrouse: courses });
  };

  getCompany = () => {
    var companies = [];
    this.props.systemData.Data.companies.map((res, index) => {
      var obj = {
        text: res.companyName,
        value: res.companyName,
      };
      companies.push(obj);
    });
    this.setState({ optionsCompany: companies });
  };

  getDuration = () => {
    var experience = [];
    this.props.systemData.Data.experience.map((res, index) => {
      var obj = {
        text: res.system_experience,
        value: res.system_experience,
      };
      experience.push(obj);
    });
    this.setState({ optionsDurations: experience });
  };
  getPositions = () => {
    var job_titles = [];
    this.props.systemData.Data.job_titles.map((res, index) => {
      var obj = {
        text: res.job_titles,
        value: res.job_titles,
      };
      job_titles.push(obj);
    });
    this.setState({ optionsTitle: job_titles });
  };

  render() {


    // if (this.props.cvFilterData !== null){
    //   this.getCvFilterdData();
    // }


    var cv = "";
    var cvFileType;
    try {
      cv = this.props.candidateDate.userData.cv[0].upload;
      cvFileType = cv.substr(cv.length - 3, 3);
    } catch {
      cv = "";
    }

    let educationListData = this.state.arrayDataEdu;
    let expListData = this.state.arrayDataExp;
    let viewBtn = "";
    let uploadBtn = "";
    let cvLable = "";
    let nextBtn = (
      <button className={classes.bNext} onClick={this.btnSaveProceed}>
        Next
      </button>
    );
    let userName = "";
    if (this.props.openCVModal === true) {
      // viewBtn = (
      //   <button
      //     className={classes.viewButton}
      //     onClick={() =>
      //       this.setState({
      //         isPDFModalOpen: true,
      //       })
      //     }
      //   >
      //     View
      //   </button>
      // );
      if (cvFileType === "pdf") {
        viewBtn = (
          <button
            className={classes.viewButton}
            onClick={() =>
              this.setState({
                isPDFModalOpen: true,
              })
            }
          >
            View
          </button>
        );
      } else {
        viewBtn = (
          <a
            className={classes.viewButton}
            href={this.state.cvURl}
            type="button"
          >
            Download
          </a>
        );
      }
      nextBtn = "";
      userName = localStorage.getItem("chat-username");
      cvLable = "Curriculam vitae";
      uploadBtn = (
        <span className={classes.uploadButtonWrap}>
          {/* <button onClick={this.uploadNewCV}> */}
          <input
            type="file"
            name="uploadedCV"
            id="uploadedCV"
            onChange={this.handleFileChange}
            className="inputfilez"
          ></input>
          <label for="uploadedCV">
            {/* <i className="fas fa-upload" /> */}
            Upload
          </label>
        </span>
      );
    }

    return (
      <div className={classes.cvFilteredWrapper}>
        <div className={classes.cvFilter}>
          {/* {localStorage.getItem("OpenSmartCV") !== "true" && (
            <div className={classes.outerText}>
              Confirm your profile information
            </div>
          )} */}
          <div
            className={
              localStorage.getItem("OpenSmartCV") === "true"
                ? classes.whiteBoxNoShadow
                : classes.whiteBox
            }
          >
            <div>
              {/* {this.props.openCVModal === true && ( */}
              <div className={classes.cvActionsRow}>
                {userName && (
                  <div className={classes.left}>
                    <div className={classes.title} style={{ width: "80px" }}>
                      {cvLable}
                    </div>
                  </div>
                )}
                <div className={classes.rightCVTags}>
                  {userName && (
                    <div className={classes.pdfNameImage}>
                      <img src={Document} />
                      <span className={classes.name}>
                        {this.state.fileName
                          ? this.state.fileName
                          : `${userName.replace(" ", "_")}.pdf`}
                      </span>
                    </div>
                  )}
                  {cv == "" ? null : viewBtn}
                  {this.props.openCVModal && (
                    <span>{cv == "" ? null : "Or"}</span>
                  )}
                  {uploadBtn}
                </div>
              </div>
              {/* )} */}
            </div>

            <div className={classes.skillsRow}>
              <div className={classes.left}>
                <div className={classes.title}>Skills</div>
                {/* <div className={classes.badge}>CV</div> */}
              </div>
              <div className={classes.rightCVTags}>
                <CVTags
                  onDataSave={this.filteredData}
                  updateSkillsFlag={this.updateSkillsFlag}
                />
                {/* <CVTags onDataSave={console.log("huis")} /> */}
              </div>
            </div>

            <div className={classes.EduRow}>
              <div className={classes.left}>
                <div className={classes.title}>Education</div>
                <div className={classes.badge}>CV</div>
              </div>
              <div className={classes.rightEduContent}>
                <div className={classes.eduContent}>
                  {this.state.arrayDataEdu}
                  {this.state.inputEducation ? (
                    <div className={classes.educationInputs}>
                      <div className={classes.title}>Enter details</div>
                      <div className={classes.inputswrap}>
                        {/* <input
                          type="text"
                          name="instututeName"
                          placeholder="Institute name"
                          onChange={this.handleNamechange}
                        /> */}
                        <Dropdown
                          options={this.state.optionsInstitute}
                          placeholder="Institute name"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.insName}
                          onAddItem={this.handleNameAddition}
                          onChange={this.handleNamechange}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />
                        <div className={classes.errorDiv}>
                          {this.eduValidator.message(
                            "Institute name",
                            this.state.insName,
                            "required"
                          )}
                        </div>
                        <input
                          type="text"
                          name="instututeType"
                          placeholder="Graduation Year"
                          onChange={this.handleTypeChange}
                          value={this.state.insType}
                        />
                        <div className={classes.errorDiv}>
                          {this.eduValidator.message(
                            "Graduation Year",
                            this.state.insType,
                            "required"
                          )}
                        </div>

                        {/* <input
                          type="text"
                          name="course"
                          placeholder="Course name"
                          onChange={this.handleSubjectChange}
                        />
                         */}
                        <Dropdown
                          options={this.state.optionsCrouse}
                          placeholder="Course name"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.inssubject}
                          onAddItem={this.handleSubjectAddition}
                          onChange={this.handleSubjectChange}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />
                        <div className={classes.errorDiv}>
                          {this.eduValidator.message(
                            "course",
                            this.state.inssubject,
                            "required"
                          )}
                        </div>
                        {/* <input
                          type="text"
                          name="result"
                          placeholder="Grade achieved"
                          onChange={this.handleGrade}
                        /> */}
                        <Dropdown
                          options={this.state.optionsGrade}
                          placeholder="Grade achieved"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.insGrade}
                          onAddItem={this.handleGradeAddition}
                          onChange={this.handleGrade}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />
                        <div className={classes.errorDiv}>
                          {this.eduValidator.message(
                            "result",
                            this.state.insGrade,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className={classes.submitDiv}>
                        <button
                          id="deldeldel"
                          className={classes.delete}
                          onClick={this.handleCloseEdu}
                        >
                          Close
                        </button>
                        <button
                          className={classes.submit}
                          onClick={this.enterEducationDetails}
                        >
                          Submit
                        </button>
                      </div>
                      {/* {this.validator.message(
                          "education",
                          this.state.insType,
                          "required|education"
                      )} */}
                    </div>
                  ) : (
                    <div className={classes.addPlus}>
                      <button onClick={this.handleEduBtn}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.EduRow}>
              <div className={classes.left}>
                <div className={classes.title}>Experience</div>
                <div className={classes.badge}>CV</div>
              </div>
              <div className={classes.rightEduContent}>
                <div className={classes.eduContent}>
                <div style={{marginBottom:"10px",color:"#E48825"}}>If you don't have work experiences please add project experiences</div>
                  <div className={classes.eachData}>
                    {this.state.arrayDataExp}
                  </div>
                  {this.state.inputExperience ? (
                    <div className={classes.educationInputs}>
                      <div className={classes.title}>Enter details</div>
                      <div className={classes.inputswrap}>
                        {/* <input
                          type="text"
                          name="companyName"
                          placeholder="Company name"
                          onChange={this.handleCompany}
                        /> */}
                        <Dropdown
                          options={this.state.optionsCompany}
                          placeholder="Company name"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.exCompany}
                          onAddItem={this.handleCompanyAddition}
                          onChange={this.handleCompany}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />

                        <div className={classes.errorDiv}>
                          {this.xpValidator.message(
                            "companyName",
                            this.state.exCompany,
                            "required"
                          )}
                        </div>

                        {/* <input
                          type="text"
                          name="jobTitle"
                          placeholder="Job title"
                          onChange={this.handlePOsition}
                        /> */}
                        <Dropdown
                          options={this.state.optionsTitle}
                          placeholder="Job title"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.exPosition}
                          onAddItem={this.handlePositionAddition}
                          onChange={this.handlePOsition}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />

                        <div className={classes.errorDiv}>
                          {this.xpValidator.message(
                            "jobTitle",
                            this.state.exPosition,
                            "required"
                          )}
                        </div>

                        {/* <input
                          type="text"
                          name="duration"
                          placeholder="Duration"
                          onChange={this.handleDuration}
                        /> */}
                        <Dropdown
                          options={this.state.optionsDurations}
                          placeholder="Duration"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={this.state.exDuration}
                          onAddItem={this.handleDurationAddition}
                          onChange={this.handleDuration}
                          className="eduInputsDropdown_onboard"
                          selectOnNavigation={false}
                          selectOnBlur={false}
                        />
                        <div className={classes.errorDiv}>
                          {this.xpValidator.message(
                            "duration",
                            this.state.exDuration,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className={classes.submitDiv}>
                        <button
                          className={classes.delete}
                          onClick={this.handleCloseExp}
                        >
                          Close
                        </button>
                        <button
                          className={classes.submit}
                          onClick={this.enterExperienceDetails}
                        >
                          Submit
                        </button>
                      </div>
                      {/* {this.validator.message(
                          "experience",
                          this.state.insType,
                          "required|experience"
                      )} */}
                    </div>
                  ) : (
                    <div className={classes.addPlus}>
                      <button onClick={this.handleExpBtn}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={classes.saveButtonWrapper}
              style={this.props.submitCV && { display: "none" }}
            >
              {/* <button onClick={this.btnSaveProceed}>Next</button> */}
              {!this.props.cvUploaded && !this.props.isManuallyFilled && (
                <button onClick={this.props.defaultSkip}>Skip</button>
              )}
              {(this.props.cvUploaded || this.props.isManuallyFilled) &&
                nextBtn}
            </div>
            <SweetAlert
              show={this.state.alert}
              title="Warning"
              text={this.state.text}
              onConfirm={() => this.setState({ alert: false })}
            />
          </div>
        </div>
        <Modal
          open={this.state.isPDFModalOpen}
          size="small"
          className="noBootstrapModal"
          onClose={this.closeModal}
        >
          <div className={classes.insideViewModal}>
            <div className={classes.titlebar}>
              <h3>CV view</h3>
              <button
                onClick={() =>
                  this.setState({
                    isPDFModalOpen: false,
                  })
                }
                className={classes.closeX}
              >
                <i className="fas fa-times" />
              </button>
            </div>
            <embed
              src={cv}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    phoneNumber: state.auth.phoneNumber,
    location: state.auth.location,
    deleteID: state.auth.itemListId,
    eduList: state.auth.eduList,
    skills: state.auth.skills,
    openCVModal: state.chatnew.openCVModal,
    candidateDate: state.CandidateData.userData,
    cvSkilldata: state.profile.cvFilterData,
    systemData: state.auth.systemData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSendExp: (data) => dispatch(actionsCreator.sendExp(data)),
    onSendEdu: (data) => dispatch(actionsCreator.sendEdu(data)),
    // deleteItem: (data) => dispatch(actionsCreator.deleteItem(data))
    onGetChatResponseNew: (message, status, notification) =>
      dispatch(
        actionsCreator.getChatResponseNew(message, status, notification)
      ),
    onCloseModal: (data) => dispatch(actionsCreator.CloseModal(data)),
    onUploadCV: (data) => dispatch(actionsCreator.UploadCVChat(data)),
    onCVProgress: (data) => dispatch(actionsCreator.CVProgress(data)),
    //  onUploadCvExtract:id =>dispatch(actionsCreator.UploadCvExtract(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDataOnboard);
