import React, { Component } from "react";
import classes from "./ChatQueue.module.scss";
import "./ChatQueue.scss";
import axios from "../../axios";
import User from "../../assets/image/user.png";
import { connect } from "react-redux";
import * as actionCreater from "../../store/actions/index";
import moment from "moment";
import QueueList from "../../components/ChatQueue/QueueList/QueuList";

class ChatQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queue: [],
      chatToken: localStorage.getItem("chat-token"),
      startdate: localStorage.getItem("Start time local"),
      interviewid: localStorage.getItem("interview-id"),
      campaignid: localStorage.getItem("campaign-id"),
      URL: process.env.REACT_APP_API_URL + "chat",
      URlManage: process.env.REACT_APP_API_URL + "manager",
      totalCount: 0,
      yourPosition: 0,
      queueData:null
    };
  }
  componentDidMount() {
    if (
      this.props.campaignId !== undefined &&
      this.props.appliedId !== undefined
    ) {
      var currentTime = moment().toISOString();
      this.getCandidateQueue()
      this.props.onGetCandidatequeue(currentTime,this.props.campaignId,this.props.appliedId)
    }
  }

  getCandidateQueue=()=>{
    var campaignId= this.props.campaignId
    var currentTime = moment().toISOString();
    var interviewId =  this.props.appliedId

    var host =process.env.REACT_APP_WEB_SOCKET_CANDIDATE_QUEUE+"/?campId="+campaignId+"&startTime="+currentTime+"&status=pqr&intwId="+interviewId+"&pnlMemId=0&userType=2&panelId="+this.props.intData.InterviewData.panelId
    var wsocket =new WebSocket(host); 

    wsocket.onopen=function(evt){
        console.log("Server connected,chat queue");
    };
    wsocket.onmessage=(evt)=>{  
      if (evt.data != "DoNotMatch") {     
      var obj = JSON.parse(evt.data) 
      console.log(obj,"tttt")     
      if (this.props.intData.InterviewData.panelId == obj.panelId) {
        if(obj.queue==true){
         this.props.onGetCandidatequeue(currentTime,campaignId,interviewId) 
        }    
      }    
    }
    };
  }

  render() {
    let queueList = "No Queue";
    var currentTime = moment().toISOString();
    var camId = "";
    var intId = "";  
    try {
      if (this.props.campaignId !== undefined && this.props.appliedId !== undefined) {   
          
      }
    } catch {
      currentTime = moment().toISOString();
      camId = "";
      intId = "";
    }
    let queuList = <QueueList data={this.props.candidateQueue} />;
    //let queuList = <QueueList data={this.state.queueData} />;
    return (
      <div>
        <div className={classes.queueTitle}>{this.props.intData.isWebinarPnl==false ?"Candidate Queue":"Participants"}</div>
        {queuList}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    intwData: state.CandidateData.interviewData,
    candidateQueue: state.CandidateData.candidateQueue,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetCandidatequeue: (currentTime, camId, intId) =>
      dispatch(actionCreater.getCandidatequeue(currentTime, camId, intId)),     
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatQueue);
