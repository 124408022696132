import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "./Progress.module.scss";
import * as actions from "../../../../../store/actions/index";
import Spinner from "../../../../UI/SpinnerGrey/SpinnerGrey";

class Progress extends Component {
  render() {
    console.log(this.props.uploadStatusCoverLetter, "uploading status letter");
    var data = "";
    if (this.props.uploadStatusCoverLetter !== null) {
      data = false;
      this.props.onUpdateProgress(data);
    }

    return (
      <div className={classNames.progressInner}>
        <button className={classNames.closeCoverButton}>
          <i className="fas fa-times" />
        </button>
        <div className={classNames.text}>Uploading your cover letter</div>
        <Spinner />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadedStatus: state.chatnew.cvUploaded,
    uploadStatusCoverLetter: state.chatnew.uploadCoverLetter,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateProgress: (data) => dispatch(actions.CVLProgress(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
