import * as actions from "./actionTypes";
import axios from "../../axios";
import objectChecker from "../../hoc/objectChecker";

export const profileInit = () => {
  return {
    type: actions.PROFILE_INIT
  };
};
export const profileSuccess = data => {
  return {
    type: actions.PROFILE_SUCCESS,
    userProfile: data
  };
};
export const profileFail = () => {
  return {
    type: actions.PROFILE_FAIL
  };
};
export const uploadCVDashBoard = data => {
  return {
    type: actions.UPLOAD_CV_DASHBOARD,
    data
  };
};

export const UploadCV = data => {
  return dispatch => {
    dispatch(uploadCVDashBoard(data));
  };
};

export const profileUpdateSuccess = data => {
  return {
    type: actions.PROFILE_UPDATE_SUCCESS
  };
};

export const cvFilteringSuccess = data => {
  return {
    type: actions.CV_FILTERING_SUCCESS,
    data
  };
}
export const UploadCvExtractSuccess = data => {
  return {
    type: actions.UPLOAD_CV_EXTRACT_SUCCESS,
    data
  };
}

export const showWebinarSuccess = data => {
  return {
    type: actions.SHOW_WEBINAR_SUCCESS,
    data
  };
}
export const downloadUserDataSuccess = data => {
  return {
    type: actions.DOWNLOAD_USER_DATA_SUCCESS,
    data
  };
}
export const getProfileData = () => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .get("users/me/", {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(res => {
        const image = objectChecker(res.data, [
          "candidate_profile",
          "picture_url"
        ]);
        const firstName = objectChecker(res.data, [
          "candidate_profile",
          "first_name"
        ]);
        const lastName = objectChecker(res.data, [
          "candidate_profile",
          "last_name"
        ]);
        const fullName = [firstName, lastName];

        localStorage.setItem("chat-userimage", image);
        localStorage.setItem("chat-username", fullName.join(" "));

        dispatch(profileSuccess(res.data));
      })
      .catch(err => {
        dispatch(profileFail());

        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("chat-username");
            localStorage.removeItem("chat-userimage");
            //localStorage.removeItem("chat-token");

            //window.location.reload();
          }
        }
      });
  };
};

export const onUpdateProfile = (data, id) => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .put("update-candidate-profile/" + id + "/", data, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(res => {       
        dispatch(profileUpdateSuccess(res.data));
      })
      .catch(err => {
        // dispatch(profileFail());
      });
  };
};

export const showWebinar = (data) => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .get("show-webinar/?ref="+data.ref+"&time="+data.time, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${token}`
        }
      })
      .then(res => {
        dispatch(showWebinarSuccess(res.data));
      });
  };
};

export const showWebinarPusher = (data) => {
  return dispatch => {
   dispatch(showWebinarSuccess(data));
  }
};

export const cvFiltering = (data) => { 
  return dispatch => {
  dispatch(UploadCvExtractSuccess(data));  
  }     
};

export const downloadUserData = (id) => {
  const token = localStorage.getItem("chat-token");
  return dispatch => {
    axios
      .get("download-user-data/?userId=" + id, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(res => {       
        dispatch(downloadUserDataSuccess(res.data));
      })
      .catch(err => {
        // dispatch(profileFail());
      });
  };
};

export const resetValuesModal= data=>{
  return {
    type: actions.RESET_MODAL_VALUE,
    data
  };
}

export const CloseModalValue=(data)=>{
  console.log(data,"modal close redux")
  return dispatch=>{
    dispatch(resetValuesModal(data))
  }
}