export const AUTH_INIT = "AUTH_INIT",
  AUTH_SUCCESS = "AUTH_SUCCESS",
  AUTH_FAIL = "AUTH_FAIL",
  AUTH_REDIRECT = "AUTH_REDIRECT",
  PROFILE_INIT = "PROFILE_INIT",
  PROFILE_SUCCESS = "PROFILE_SUCCESS",
  PROFILE_FAIL = "PROFILE_FAIL",
  GET_NOTIFICATION_INIT = "GET_NOTIFICATION_INIT",
  GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL",
  GET_NOTIFICATION_SUCESS = "GET_NOTIFICATION_SUCESS",
  CHAT_INIT = "CHAT_INIT",
  CHAT_SUCCESS = "CHAT_SUCCESS",
  CHAT_FAIL = "CHAT_FAIL",
  HISTORY_INIT = "HISTORY_INIT",
  HISTORY_SUCCESS = "HISTORY_SUCCESS",
  HISTORY_FAIL = "HISTORY_FAIL",
  CHATNEW_INIT = "CHATNEW_INIT",
  CHATNEW_SUCCESS = "CHATNEW_SUCCESS",
  CHATNEW_FAIL = "CHATNEW_FAIL",
  HISTORYNEW_INIT = "HISTORYNEW_INIT",
  HISTORYNEW_SUCCESS = "HISTORYNEW_SUCCESS",
  HISTORYNEW_FAIL = "HISTORYNEW_FAIL",
  CALL_HANGUP_INIT = "CALL_HANGUP_INIT",
  CALL_HANGUP_SUCCESS = "CALL_HANGUP_SUCCESS",
  CALL_HANGUP_FAIL = "CALL_HANGUP_FAIL",
  VIDEO_UPLOAD_INIT = "VIDEO_UPLOAD_INIT",
  VIDEO_UPLOAD_SUCCESS = "VIDEO_UPLOAD_SUCCESS",
  VIDEO_UPLOAD_FAIL = "VIDEO_UPLOAD_FAIL",
  USER_LOGIN = "USER_LOGIN",
  GET_ME_SUCCESS = "GET_ME_SUCCESS",
  GET_ME_FAIL = "GET_ME_FAIL",
  USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS",
  GET_CAMPAIGN_NEW_INIT = "GET_CAMPAIGN_NEW_INIT",
  GET_CAMPAIGN_NEW_FAIL = "GET_CAMPAIGN_NEW_FAIL",
  GET_CAMPAIGN_NEW_SUCCESS = "GET_CAMPAIGN_NEW_SUCCESS",
  // get video List
  GET_VIDEOLIST_INIT = "GET_VIDEOLIST_INIT",
  GET_VIDEOLIST_FAIL = "GET_VIDEOLIST_FAIL",
  GET_VIDEOLIST_SUCCESS = "GET_VIDEOLIST_SUCCESS",
  //get campaign by ref
  GET_CAMPAIGN_REF = "GET_CAMPAIGN_REF",
  GET_APPLY_CLICKED = "GET_APPLY_CLICKED",
  // onboard user
  ONBOARD_INIT = "ONBOARD_INIT",
  ONBOARD_SUCCESS = "ONBOARD_SUCCESS",
  ONBOARD_FAIL = "ONBOARD_FAIL",
  GET_CANDIDATE_DATA_SUCCESS = "GET_CANDIDATE_DATA_SUCCESS",
  SEND_ONBOARD_DATA = "SEND_ONBOARD_DATA",
  SEND_ONBOARD_DATA_LOCATION = "SEND_ONBOARD_DATA_LOCATION",
  CV_STRING = "CV_STRING",
  SKILLS_TAGS = "SKILLS_TAGS",
  SUCCESS_EXP = "SUCCESS_EXP",
  SUCCESS_EDU = "SUCCESS_EDU",
  DELETE_ITEM_LIST = "DELETE_ITEM_LIST",
  EXP_LIST = "EXP_LIST",
  GET_INTERVIEW_DATA = "GET_INTERVIEW_DATA",
  GET_TWILLIO_TOKEN = "GET_TWILLIO_TOKEN",
  GET_CANDIDATE_QUEUE = "GET_CANDIDATE_QUEUE",
  GET_CAMPAIGN_DATA = "GET_CAMPAIGN_DATA",
  UPLOAD_CV_DASHBOARD = "UPLOAD_CV_DASHBOARD",
  OPEN_MODAL_SUCCESS = "OPEN_MODAL_SUCCESS",
  OPEN_MODAL_LABLE = "OPEN_MODAL_LABLE",
  OPEN_CV_MODAL = "OPEN_CV_MODAL",
  CLOSE_CHAT_MODAL = "CLOSE_CHAT_MODAL",
  OPEN_CHAT_MODAL = "OPEN_CHAT_MODAL",
  SUCCESS_CV_DATA = "SUCCESS_CV_DATA",
  PROGRESS_UPLOAD = "PROGRESS_UPLOAD",
  GET_VIDEO_QUESTIONS = "GET_VIDEO_QUESTIONS",
  SUBMIT_VIDEO_SUCCESS = "SUBMIT_VIDEO_SUCCESS",
  OPEN_CAMPAIGN_FINDER = "OPEN_CAMPAIGN_FINDER",
  SUCCESS_COVER_LETTER = "SUCCESS_COVER_LETTER",
  PROGRESS_UPLOAD_CVL = "PROGRESS_UPLOAD_CVL",
  SUCCESS_RESET_STATE = "SUCCESS_RESET_STATE",
  PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS",
  CV_FILTERING_SUCCESS = "CV_FILTERING_SUCCESS",
  UPLOAD_CV_EXTRACT_SUCCESS = "UPLOAD_CV_EXTRACT_SUCCESS",
  SHOW_WEBINAR_SUCCESS = "SHOW_WEBINAR_SUCCESS",
  ON_INITIAL_CONVERSATION = "ON_INITIAL_CONVERSATION",
  ON_WELCOME_BACK = "ON_WELCOME_BACK",
  HOLLENCODE_QUESTIONS_SUCCESS = "HOLLENCODE_QUESTIONS_SUCCESS",
  CAREER_SUGGETION_SUCCESS = "CAREER_SUGGETION_SUCCESS",
  ON_SUCCESS_INTERVIEW_TIMES = "ON_SUCCESS_INTERVIEW_TIMES",
  SUCCESS_SAVE_CAREER_SUGGETION = "SUCCESS_SAVE_CAREER_SUGGETION",
  ON_CHAT_POP_UP_OPEN = "ON_CHAT_POP_UP_OPEN",
  SUCCESS_UPDATE_REF_INVITE = "SUCCESS_UPDATE_REF_INVITE",
  ON_SUCCESS_INTERVIEW_INVITE = "ON_SUCCESS_INTERVIEW_INVITE",
  TIME_UPDATED_SUCCESS = "TIME_UPDATED_SUCCESS",
  ON_SUCCESS_UNSCHEDULED_INTERVIEWS = "ON_SUCCESS_UNSCHEDULED_INTERVIEWS",
  EXIT_VIDEO_PITCH = "EXIT_VIDEO_PITCH",
  GET_CV_ORIGINAL_STATUS = "GET_CV_ORIGINAL_STATUS",
  SAVE_APPLIED_DATA_TEMP = "SAVE_APPLIED_DATA_TEMP",
  CLOSE_CHAT_COMPONENT_RESPONSE = "CLOSE_CHAT_COMPONENT_RESPONSE",
  SUCCESS_SEND_SUPPORT = "SUCCESS_SEND_SUPPORT",
  SUCCESS_EXIT_SUPPORT = "SUCCESS_EXIT_SUPPORT",
  ON_SUCCESS_VIDEO_STATUS = "ON_SUCCESS_VIDEO_STATUS",
  SUCCESS_VIDEO_RECORD = "SUCCESS_VIDEO_RECORD",
  EXIT_FAQ_SUCCESS = "EXIT_FAQ_SUCCESS",
  SUCCESS_SUBMIT_FQA_SUCCESS = "SUCCESS_SUBMIT_FQA_SUCCESS",
  SUCCESS_UPDATE_CAMPAIGN_APPLY_PROCESS =
    "SUCCESS_UPDATE_CAMPAIGN_APPLY_PROCESS",
  GET_CAMPAIGN_SKIPPED_DATA = "GET_CAMPAIGN_SKIPPED_DATA",
  GET_CAMPAIGN_PROCESS = "GET_CAMPAIGN_PROCESS",
  VIDEO_PITCH_SUCCESS = "VIDEO_PITCH_SUCCESS",
  SUCCESS_SYSTEM_LEVEL_DATA = "SUCCESS_SYSTEM_LEVEL_DATA",
  SET_SOMETHING_CHANGED = "SET_SOMETHING_CHANGED",
  COVER_PITCH_SUCCESS="COVER_PITCH_SUCCESS",
  VALIDATION_URL_SUCCESS="VALIDATION_URL_SUCCESS",
  GET_FILTER_DATA_SUCCESS="GET_FILTER_DATA_SUCCESS",
  GET_AVAILABLE_INTERVIEW="GET_AVAILABLE_INTERVIEW",
  SUCCESS_CANDIDATE_FEEDBACK_SUCCESS="SUCCESS_CANDIDATE_FEEDBACK_SUCCESS",
  GET_TIME_DATA_USER_LOGGED="GET_TIME_DATA_USER_LOGGED",
  GET_AVAIALBLE_CAMPAIGNS="GET_AVAIALBLE_CAMPAIGNS",
  SUCCESS_AVAILABLE_CAMPAIGN_TYPE="SUCCESS_AVAILABLE_CAMPAIGN_TYPE",
  GET_SUCCESS_AVAILABLE_CAMPAIGN="GET_SUCCESS_AVAILABLE_CAMPAIGN",
  SET_CAMPAIGN_FEATURES_SUCCESS="SET_CAMPAIGN_FEATURES_SUCCESS",
  GET_SUCCESS_CAMPAIGN_FEATURES="GET_SUCCESS_CAMPAIGN_FEATURES",
  DOWNLOAD_USER_DATA_SUCCESS="DOWNLOAD_USER_DATA_SUCCESS",
  SUCCESS_UPDATE_ROOM_DATA="SUCCESS_UPDATE_ROOM_DATA",
  SET_CHAT_OPEN="SET_CHAT_OPEN",
  GET_SUCCESS_URL_STATE="GET_SUCCESS_URL_STATE",
  GET_RECRUITER_STATUS_SUCCESS="GET_RECRUITER_STATUS_SUCCESS",
  SUCCESS_RECRUITER_CALL_STATUS="SUCCESS_RECRUITER_CALL_STATUS",
  GET_SHARE_GROUP_SUCCESS="GET_SHARE_GROUP_SUCCESS",
  SUCCESS_WHATSAPP_SUPPORT_USERS="SUCCESS_WHATSAPP_SUPPORT_USERS",
  SUCCESS_WEBSOCKET="SUCCESS_WEBSOCKET",
  GET_EVENT_DATA_SUCCESS="GET_EVENT_DATA_SUCCESS",
  SUCCESS_EVENT_PARTICIPENTS="SUCCESS_EVENT_PARTICIPENTS",
  ERROR_EVENT_PARTICIPENTS="ERROR_EVENT_PARTICIPENTS",
  ERROR_CANDIDATE_DASHBOARD_DATA="ERROR_CANDIDATE_DASHBOARD_DATA",
  SUCCESS_CANDIDATE_DASHBOARD_DATA="SUCCESS_CANDIDATE_DASHBOARD_DATA",
  ERROR_EVENT_TITLE_BAR="ERROR_EVENT_TITLE_BAR",
  SUCCESS_EVENT_TITLE_BAR="SUCCESS_EVENT_TITLE_BAR",
  ERROR_EVENT_STALL_DATA="ERROR_EVENT_STALL_DATA",
  SUCCESS_EVENT_STALL_DATA="SUCCESS_EVENT_STALL_DATA",
  ERROR_EVENT_NOTIFICATIONS="ERROR_EVENT_NOTIFICATIONS",
  SUCCESS_EVENT_NOTIFICATIONS="SUCCESS_EVENT_NOTIFICATIONS",
  RESET_MODAL_VALUE="RESET_MODAL_VALUE",
  SUCCESS_GET_ALL_EVENT_DATA="SUCCESS_GET_ALL_EVENT_DATA",
  ERROR_GET_ALL_EVENT_DATA="ERROR_GET_ALL_EVENT_DATA",
  SUCCESS_CHANGE_TIME="SUCCESS_CHANGE_TIME",
  SUCCESS_CHANGE_COUNT="SUCCESS_CHANGE_COUNT",
  GET_CANDIDATE_DATA_SUCCESS_STALL="GET_CANDIDATE_DATA_SUCCESS_STALL",
  SUCCESS_ACCESS_STALL="SUCCESS_ACCESS_STALL",
  SUCCESS_BTN_DISABLE="SUCCESS_BTN_DISABLE"

