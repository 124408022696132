import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "../../../components/Event/AllEvents/AllEvents.module.scss";
import { Button, Card, Image, Icon } from "semantic-ui-react";

class SingleEvent extends Component{

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            // returnCom : null,
            // isAPICall : false,
            // companyEvents: []
        };
    }

    componentDidMount = () => {
    
    }

    handleOnClick =(eventName)=>{
        var URL= window.location.href+eventName
        console.log("handle click")
        window.open(URL, '_blank');
    }

    render(){

        console.log(this.props.eventData, "Event data")

        var eventName = null
        var heading = null

        if (this.props.eventData !== null){
            eventName = this.props.eventData.shareUrl
            heading = this.props.eventData.campaign.heading
        }

        return(
            <div>
                {/* {
                    this.props.eventData !== null?
                    <>
                    {heading}
                    <button onClick={()=>this.handleOnClick(eventName)}></button></>:
                    null
                } */}
            {
                    this.props.eventData !== null?
                <Card className={classes.sizeC} onClick={()=>this.handleOnClick(eventName)}>
                {/* <Image src={this.props.data.event[0].picture_url==""?atendee2:this.props.data.event[0].picture_url} className={classes.atendOne} wrapped ui={true} /> */}
                <Card.Content className={classes.cusContent}>
                    <Card.Header className={classes.ComTitle}>{heading + " "}</Card.Header>
                    </Card.Content>   
                </Card>:
            null
            }
            </div>
        )  
    }
}

export default SingleEvent 