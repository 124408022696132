import React from "react";
import classes from "./Spinner.module.scss";

const SpinnerGrey = () => {
  return (
    <div className={classes.ldsEllipsis}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default SpinnerGrey;
