import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import App from "../src/App";
import axios from "../src/axios";
import SingleEvent from "../src/components/UI/SingleEvent/SingleEvent";
import classes from "../src/components/Event/AllEvents/AllEvents.module.scss";
import { Link } from "react-router-dom";
//./../assets/image/ufairs.svg

class Companylanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      returnCom: null,
      isAPICall: false,
      companyEvents: [],
    };
  }

  componentDidMount = () => {
    let intervel = setInterval(() => this.getCompanyEvents(), 1000);
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  getCompanyEvents = () => {
    if (this.props.subDomain !== null) {
      if (this.state.isAPICall === false) {
        axios
          .get(
            "company-live-events/?subDomain=" +
              this.props.subDomain +
              "&product=UFAIRS",
            {
              headers: {
                "Content-type": `application/json`,
              },
            }
          )
          .then((res) => {
            console.log(res, "results company live events");
            this.setState({
              isAPICall: true,
              companyEvents: res.data.events,
            });
          });
      }
    }
  };

  render() {

    var returnData = (
        <div className={classes.instructionWrapper}>
            <div className={classes.instructions}>
            <div className={classes.heading}>
                <span>Events will be updated soon!</span>
            </div>
            <div className={classes.descr}>
            Wanna know more about Ufairs?
            </div>
            <a href="#">Yes</a>
            </div>
        </div>
    );

    if (this.state.companyEvents.length > 0) {
      if (this.state.companyEvents.length > 1) {
        returnData = this.state.companyEvents.map((res, index) => {
          return (
            <>
              <SingleEvent eventData={res} />
            </>
          );
        });
      }
    }

    return (
    //   <div>
    //       {returnData}
    //   </div>
      <div>
      <div className={
          classes.atendFlexouter
      }>
          {/* <div>
              <button onClick={this.prev} className={classes.back_btn1} > <i className="fa fa-chevron-left" aria-hidden="true"></i></button>
          </div> */}
      
          <div className={classes.atendFlex}>
              {returnData}
          </div>

          {/* <div>
              <button onClick={this.next} className={classes.back_btn1}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
          </div> */}
      </div>
  </div>
    );
  }
}

export default Companylanding;
