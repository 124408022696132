import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./EachFeedback.module.scss";
import moment from 'moment'
import Man from "../../../../assets/image/man.svg";

class Data extends Component {
  render() {   
    const {
      title,
      body,
      interviewerName,
      interviewerImage,
      timeStamp,
    } = this.props.data;
    return (
      <div className={classes.eachFeedback}>
        <div className={classes.title}>{this.props.data.campaign.heading}</div>
        <div className={classes.body}>{this.props.data.feedBack}</div>
        <div className={classes.avatarInfo}>
          <img src={this.props.data.assignee.profileImage=="" ? Man:this.props.data.assignee.profileImage} />
          <div className={classes.right}>
            <div className={classes.name}>{this.props.data.assignee.first_name+" "+this.props.data.assignee.last_name}</div>
            <div className={classes.dateTime}>{ moment(this.props.data.time).format("YYYY-MM-DD hh:mm A")}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default Data;
